import React from "react";
//Customizable Area Start
import { Box, Link, Typography, styled } from "@material-ui/core";
import MyJobActiveContractController, { Props } from './MyJobActiveContractController.web'
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { shap } from "./assets";
export const webConfigJSON = require("./config");
// Customizable Area End

export default class MyJobActiveContract extends MyJobActiveContractController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    renderSelect = () => {
        return (
            <Box style={{ display: "flex" }}>
                <CurUpperText>{webConfigJSON.myJobs}</CurUpperText>
                <ArrowIcon>{">"}</ArrowIcon>
                <CurUpperText>{webConfigJSON.allcontract}</CurUpperText>
                <ArrowIcon>{">"}</ArrowIcon>
                <FontActiveText>{webConfigJSON.activecontract}</FontActiveText>
            </Box>
        );
    };

    renderContract = () => {
        return (
            <Box style={{ marginTop: "30px" }}>
                <HeaderTxt>{webConfigJSON.activecontract}</HeaderTxt>
                <TitleTxt>
                    {webConfigJSON.textMain}
                </TitleTxt>
                <TitleTxt>
                    {webConfigJSON.textreme}
                    <LinkText>{webConfigJSON.startlink}</LinkText>
                </TitleTxt>
            </Box>
        );
    };

    renderProject = () => {
    
        return (
            <ProjectOuterMainBox>
                {this.state.demoData.map((item: any,index:number) => (
                    <ProjectInnerMainBox>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <PriceBox>
                                <PriceTxt>${item.price}</PriceTxt>
                                <PriceTxt>per/Hour</PriceTxt>
                            </PriceBox>
                            <DateBox>
                                <img src={shap} style={webStyle.calenderStyle} />
                                <DateTxt>{item.date}</DateTxt>
                            </DateBox>
                        </Box>
                        <Box style={webStyle.fixedfiled}>
                            <ProjectNameTxt>{item.projectTitle}</ProjectNameTxt>
                            <ProjectOwnerNameTxt>{item.projectOwner}</ProjectOwnerNameTxt>
                        </Box>
                        <ButtonBox onClick={this.goToActiveContract} data-test-id={"goToActiveFiled"+index}>
                            <ProjectTxt>{webConfigJSON.goToProject}</ProjectTxt>
                            <ArrowForwardIcon style={webStyle.arrowIcone} />
                        </ButtonBox>
                    </ProjectInnerMainBox>
                ))
                }
            </ProjectOuterMainBox>
        );
    };

    // Customizable Area End

    render() {
        //Customizable Area Start
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                <BoxMain>
                    {this.renderSelect()}
                    {this.renderContract()}
                    {this.renderProject()}
                </BoxMain>
                <FooterWeb />
            </>
        );
        //Customizable Area End
    }
}

//Customizable Area Start
const webStyle = {
    arrowIcone: {
        color: 'white'
    },

    fixedfiled: {
        margin: "22px 0px"
    },

    calenderStyle: {
        width: "14px",
        height: "15px",
        marginRight: "5px"
    }
};

const BoxMain = styled(Box)({
    margin: "60px"
});

const FontActiveText = styled(Typography)({
    color: "#206FC4",
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left"
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const CurUpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const HeaderTxt = styled(Typography)({
    fontWeight: "bold",
    fontSize: "30px"
});

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#353535"
});

const ProjectOuterMainBox = styled(Box)({
    display: "flex",
    rowGap: "70px",
    columnGap: "20px",
    flexWrap: "wrap"
});
const PriceTxt = styled(Typography)({
    fontSize: "12px",
    fontWeight: "normal",
    color: "#216FC4"
});

const ProjectInnerMainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "300px",
    minWidth: "300px",
    maxHeight: "200px",
    minHeight: "200px",
    position: "relative",
    background: "#F5F8FC",
    borderRadius: "10px",
    padding: "20px",
    justifyContent: "space- between"
});

const PriceBox = styled(Box)({
    width: "70px",
    background: "#E2ECF6",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "5px"
});

const DateTxt = styled(Typography)({
    color: "#6F6F6F",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500
});

const ButtonBox = styled(Box)({
    background: "#216FC4",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px",
    cursor: "pointer"
});

const ProjectTxt = styled(Typography)({
    color: "#fff",
    marginRight: "10px"
});

const ProjectNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "8px",
    width: "70%"
});

const ProjectOwnerNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "12px"
});

const DateBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
});

const LinkText = styled(Link)({
    color:"#353535",
    fontFamily: "Inter",
fontSize: "14px",
fontWeight: 600,
lineHeight: "17px",
letterSpacing: "0px",
textAlign: "left",
textDecoration:'underLine'
})

//Customizable Area End
