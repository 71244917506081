import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import { getStorageData} from "../../../framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}
interface Experience {
  id: number;
  account_id: number;
  company: string;
  job_title: string;
  location: string;
  start_date: string;
  end_date: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface InvalidResponseType
 {
  errors: Array<string>;
}

//interface Experience {[key:string]: string | number | boolean }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userName: string;
  location: string;
  profilePic: any;
  status: string;
  open: boolean;
  rating: string;
  skills: string;
  ratingNumber: number | null;
  details: {
    title: string;
    lawyerPrice: string;
    titleDetails: string;
    workHistory: string;
    skills: string[];
  };
  addExperience: boolean;
  experience: Experience[];
  award: {
    title: string;
    company: string;
    location: string;
    date: string;
    description: string;
  };
  validationError: boolean;
  priceError: boolean;
  detailTitleError: boolean;
  workHistoryError: boolean;
  userId: number;
  buttonType: string;
  // Customizable Area End
}
interface SS { }

export default class FreelancerProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSkillCallId: string = "";
  editPersonalDetailsApiCallId: string = '';
  getProfileApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      userName: 'Rahul Sharma',
      profilePic: null,
      location: 'Latvia in Europe',
      status: 'ONLINE',
      open: false,
      rating: '4.91/5',
      skills: '',
      ratingNumber: 3,
      details: {
        title: 'IP Lawyer',
        lawyerPrice: '14.00',
        titleDetails: 'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best.solutions to the same.this will help users solve their problems quicker and help inventors solve practical problems facing humankind',
        workHistory: 'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best.solutions to the same.this will help users solve their problems quicker and help inventors solve practical problems facing humankind',
        skills: ['PHP', 'Android', 'SQL']
      },
      addExperience: false,
      experience: [],
      award: {
        title: 'Best Employee of the Year',
        company: 'Google Inc.',
        location: 'Bangalore',
        date: 'January 2021',
        description: 'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best. solutions to the same.this will help users solve their problems quicker and help inventors solve practical problems facing humankind'
      },
      validationError: false,
      priceError: false,
      detailTitleError: false,
      workHistoryError: false,
      userId: NaN,
      buttonType: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getSkillApi();
    this.getProfileApi();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
      if (responseJson && responseJson.errors) {
        this.getSkillApiFailureCallBack(responseJson);
      }

      if (apiRequestCallId === this.editPersonalDetailsApiCallId) {
        if (responseJson.data) {
          this.invertModalState();
          this.getProfileApi();
        }
      }
      else if (apiRequestCallId === this.getProfileApiCallId) {
        if (responseJson.data) {
          this.setState({
            userName: responseJson.data.attributes.first_name,
            profilePic: responseJson.data.attributes.profile_image,
            location: responseJson.data.attributes.country,
            details: {
              title: responseJson.data.attributes.job_title,
              lawyerPrice: '',
              workHistory: responseJson.data.attributes.work_history,
              titleDetails: '',
              skills: responseJson.data.attributes.skills
            }
          })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  isValidResponse = (responseJson: Experience) => {
    return (responseJson);
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: Array<Experience>) => {
    if (apiRequestCallId === this.getSkillCallId) {
      this.getSkillApiSuccessCallBack(responseJson);
    }
  };

  getSkillApiFailureCallBack = (responseJson: InvalidResponseType) => {
    alert("Record not found")
  }

  getSkillApiSuccessCallBack = (responseJson: Experience[]) => {
    this.setState({ experience: responseJson })
  }

  invertModalState = () => {
    this.setState({ open: !this.state.open })
  }
  handleDelete = (chipToDelete: string) => {
    this.setState({ details: { ...this.state.details, skills: this.state.details.skills.filter(data => data !== chipToDelete) } })
  }
  handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ details: { ...this.state.details, [event.target.name]: value } })
    this.handleValidation(event)
  }

  handleSkillChange = (event: any, value: any) => {
    this.setState({
      details: {
        ...this.state.details,
        skills: value
      },
    })

  }

  apiCallService = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: await storage.get("authToken")
    };
    const requestMessageService = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageService.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessageService.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageService.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageService.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageService.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessageService.id, requestMessageService);
    return requestMessageService.messageId;

  };

  handleSave = async () => {
    const { details } = this.state;

    const httpBody = {
      "account": {
        "job_title": details.title,
        "job_description": details.titleDetails,
        "hour_rate": Number(details.lawyerPrice),
        "work_history": details.workHistory,
        "skills": details.skills
      }
    }

    const accountId = await getStorageData("accountId")

    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editPersonalDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/${accountId}/update_freelancer`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAddExperience = (type: string) => {
    this.setState({ addExperience: !this.state.addExperience, buttonType: type })
  }
  handleValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const titleReg = /^[^!@#$%^&*_\-+=~`123456789]*$/;
    const priceReg = /^\d+$/;
    const firstChar = value.charAt(0);
    
    switch (name) {
        case 'title':
            if (/\s/.test(firstChar) || value.length > 100 || (value.length !== 0 && /\s/.test(firstChar)) || (!titleReg.test(value) && value.length !== 0)) {
                this.setState({ validationError: true });
            } else {
                this.setState({ validationError: false });
            }
            break;
        case 'lawyerPrice':
            if (!priceReg.test(value) || value.length > 6) {
                this.setState({ priceError: true });
            } else {
                this.setState({ priceError: false });
            }
            break;
        case 'titleDetails':
            if (/\s/.test(firstChar) || value.length > 500 || (value.length !== 0 && !titleReg.test(value)) || (/\s/.test(firstChar) && value.length !== 0)) {
                this.setState({ detailTitleError: true });
            } else {
                this.setState({ detailTitleError: false });
            }
            break;
        case 'workHistory':
            if (/\s/.test(firstChar) || value.length > 1000 || (value.length !== 0 && !titleReg.test(value)) || (value.length !== 0 && /\s/.test(firstChar))) {
                this.setState({ workHistoryError: true });
            } else {
                this.setState({ workHistoryError: false });
            }
            break;
        default:
            break;
    }
}


  handleRedirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  getSkillApi = async () => {
    this.getSkillCallId = await this.apiCallService({
      contentType: configJSON.skillApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.skillEndPoint
    });
  };
  
  getProfileApi = async () => {
    const accountId = await getStorageData("accountId")
    this.getProfileApiCallId = await this.apiCallService({
      contentType: configJSON.skillApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `account_block/accounts/${accountId}/freelancer_profile`
    });
  };
  // Customizable Area End
}
