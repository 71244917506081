import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import moment from 'moment';
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showMore: boolean;
  skillArray: string[];
  numberOfMileStone: number;
  startDate: Date | null;
  checked: boolean;
  feedBack: {
    details: string;
    userName: string;
    role: string;
    background: string;
  }[];
  userName: string;
  experienceDetails: string;
  proposalHours: string;
  frontendTechnology: string;
  backendTechnology: string;
  landingPage: string;
  fullTimeWork: string;
  quality: string;
  messageReply: string;
  paymentType: string;
  milestoneList: {
    sNo:string,
    milestone: string,
    noOfWeeks: number
  }[]
  // Customizable Area End
}
interface SS { }

export default class HireController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      showMore: false,
      skillArray: ['PHP', 'SQL', 'Android', 'Java', 'MEARN', 'Android'],
      numberOfMileStone: 4,
      startDate: null,
      checked: true,
      feedBack: [
        {
          details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          userName: "Rahul Sharma",
          role: "Owner",
          background: '#B07CF0'
        },
        {
          details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          userName: "Rahul Sharma",
          role: "Owner",
          background: '#C46E20'
        },
        {
          details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          userName: "Rahul Sharma",
          role: "Owner",
          background: '#FF6060'
        }
      ],
      userName: 'User Name',
      experienceDetails: 'More than 22 year experiences in software development industry. Involved in many client software development projects applied both technology and management skills. Gained great deals of knowledge, tactics and skills to the problem solving, management solving and technical solving. Find below for the main technologies and knowledge areas breakdown with more details.',
      proposalHours: '10.00/Day',
      frontendTechnology: 'Html5/SASS/Bootstrap/Tailwind/Javascript/Responsive web design friendly',
      backendTechnology: 'WordPress, React, Next, Vue, PHP, Laravel, Node.js.',
      landingPage: 'Figma, PSD to HTML, landing page, jQuery.',
      fullTimeWork: 'As for me, now I am ready to start your job and work full-time if possible.',
      quality: "It won't make you disappointed with the lack of quality and punctuality.",
      messageReply: 'I wish to have a message from you.',
      paymentType: 'hours',
      milestoneList: [
        {
          sNo:'1st',
          milestone: 'Flow Of Project',
          noOfWeeks: 1
        },
        {
          sNo:'2nd',
          milestone: 'Low Fidelity Designs',
          noOfWeeks: 3
        },
        {
          sNo:'3rd',
          milestone: 'Visual Designs',
          noOfWeeks: 8
        },
        {
          sNo:'4th',
          milestone: 'Launch App',
          noOfWeeks: 4
        }
      ]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    // Customizable Area End
  }
  // Customizable Area Start
  handleShowText = () => {
    this.setState({ showMore: !this.state.showMore })
  }
  
  handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked })
  }
  handleSelectPaymentType = (payBy: string) => {
    this.setState({ paymentType: payBy })
  }
  handleNext = () => {
    const nextmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    nextmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PaymentStepper'
    );
    nextmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(nextmessage);
    runEngine.sendMessage(nextmessage.id, nextmessage)
  }
  // Customizable Area End
}
