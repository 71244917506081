import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";

import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import { AvatarImg, firstStep } from './assets'
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// Customizable Area End

import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data
} from "./CustomFormController.web";

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dateOfBirthFreelancerError = () => {
    return (
      this.state.dateOfBirthFreelancerError && (
        <div style={webStyle.formError}>
          {this.state.dateOfBirthFreelancerError}
        </div>
      )
    )
  }

  streetAddressError = () => {
    return (
      this.state.streetAddressError && (
        <div style={webStyle.formError}>
          {this.state.streetAddressError}
        </div>
      )
    );
  };

  countryFreelancerError = () => {
    return (
      this.state.countryFreelancerErr && (
        <div style={webStyle.formError}>
          {this.state.countryFreelancerErr}
        </div>
      )
    );
  };

  cityError = () => {
    return (
      this.state.cityError && (
        <div style={webStyle.formError}>
          {this.state.cityError}
        </div>
      )
    );
  };

  phoneError = () => {
    return (
      this.state.phoneError && (
        <div style={webStyle.formError}>
          {this.state.phoneError}
        </div>
      )
    );
  };

  jobTitleError = () => {
    return (
      this.state.jobTitleError && (
        <div style={webStyle.formError}>
          {this.state.jobTitleError}
        </div>
      )
    );
  };

  skillsError = () => {
    return (
      this.state.skillsError && (
        <div style={webStyle.formError}>
          {this.state.skillsError}
        </div>
      )
    );
  };

  descriptionError = () => {
    return (
      this.state.descriptionError && (
        <div style={webStyle.formError}>
          {this.state.descriptionError}
        </div>
      )
    );
  };

  stateError = () => {
    return (
      this.state.stateError && (
        <div style={webStyle.formError}>
          {this.state.stateError}
        </div>
      )
    );
  };

  zipCodeError = () => {
    return (
      this.state.zipCodeError && (
        <div style={webStyle.formError}>
          {this.state.zipCodeError}
        </div>
      )
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const basicDetailsData = this.state.basicDetailsData.data.attributes
    // Customizable Area End
    return (
      // Customizable Area Start

      <>
         <FreelancerNavBar navigation={undefined}/>
     <Box style={{padding:"50px 20px 26px 20px"}}>
      <img src={firstStep}
       style={webStyle.stepperStyle} />
      </Box>
     <Box style={webStyle.mainBox}>
      <Typography style={webStyle.basicDetailsHead}>Add your Basic details</Typography>
      <Box style={webStyle.BoxTwoStyle}>
        <Grid item xs={12} sm={6} style={webStyle.imageBoxStyle}>
            <div>
            <img src={AvatarImg} style={{width:"150px"}}/>
            </div>
            <div style={{marginTop:"10px"}}>
            <Typography style={webStyle.nameStyle}>
                  {basicDetailsData.first_name}{" "}
                  {basicDetailsData.last_name}
                </Typography>
            <Typography style={webStyle.emailStyle} >
              {basicDetailsData.email}
            </Typography>
            <button
             style={webStyle.updateProfileBtn}
             onClick={this.handleEditClick}
             data-testid="profile-pic-edit"
            >
              Upload profile picture
            </button>
            <input
                  type="file"
                  data-testid="profileImageInput"
                  ref={this.state.fileInputRef}
                  style={{ display: "none" }}
                  onChange={this.handleFileChange} 
                  accept="image/*"
                />
            </div>
        </Grid>

            <Grid container >
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>

             <Grid item xs={12}>
              <Grid item xs={4} style={{marginTop:"24px"}}>
                <Typography style={webStyle.labelStyle}>Date of birth*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      type="date"
                      name="date"
                      value={this.state.dateOfBirth}
                      onChange={this.handleDateChange}
                      error={!!this.state.dateOfBirthFreelancerError}
                    />
                  </FormControl>
                 { this.dateOfBirthFreelancerError()}
              </Grid>
              </Grid>

              <Grid item xs={12} sm={8}>
              <Typography style={webStyle.labelStyle}>Street address*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                    name="streetAddress"
                    placeholder="Enter street address"
                    value={this.state.streetAddress}
                    onChange={this.handleStreetAdressChange}
                    error={!!this.state.streetAddressError}
                    />
                  </FormControl>
                 { this.streetAddressError()}
              </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>Apt/Suite</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Apt/Suite"
                        name="aptSuite"
                        value={this.state.suite}
                        onChange={this.handleSuiteChange}
                      />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={8}>
                    {console.log(this.state.countryList, "oiuytretyui")}
                    <Typography style={webStyle.labelStyle}>Additional address 2</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        name="additionalAddress2"
                        placeholder="Enter street address"
                        value={this.state.additionalAddress}
                        onChange={this.handleAddressChange}
                      />
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>Country*</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <Select
                        defaultValue=""
                        displayEmpty
                        name="country"
                        value={this.state.country}
                        error={!!this.state.countryFreelancerErr}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        onChange={(event) => this.handleCountryChange(event)}                >
                        <MenuItem value="" disabled>
                          Select Country
                        </MenuItem>
                        {this.state.countryList && this.state.countryList.map((country) => (
                          <MenuItem key={country.id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.countryFreelancerError()}
                  </Grid>


              <Grid item xs={12} sm={4}>
                <Typography style={webStyle.labelStyle}>City*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                    placeholder="Enter city"
                    error={!!this.state.cityError}
                    name="city"
                    value={this.state.city}
                    onChange={this.handleCityChange}
                    />
                  </FormControl>
                 { this.cityError()}
              </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>State*</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <Select
                        defaultValue=""
                        displayEmpty
                        name="state"
                        error={!!this.state.stateError}
                        value={this.state.state}
                        onChange={(event) => this.handleStateChange(event)}
                      >
                        <MenuItem value="" disabled>
                          Select state
                        </MenuItem>
                        {this.state.stateList.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.stateError()}
                  </Grid>

              <Grid item xs={12} sm={4}>
                <Typography style={webStyle.labelStyle}>ZIP/Postal code</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                    placeholder="Enter ZIP/Postal code"
                    name="zipCode"
                    value={this.state.zipCode}
                    onChange={this.handleZipCodeChange}
                    error={!!this.state.zipCodeError}
                    />
                  </FormControl>
                  {this.zipCodeError()}
              </Grid>

                <Grid item xs={12} sm={4}>
                <Typography style={webStyle.labelStyle}>Phone</Typography>
                   <PhoneInput
                      inputStyle={{ width: "100%", height: 55 }}
                      country={'in'}
                      value={this.state.phone}
                      data-test-id="phone"
                      onChange={phone => this.setState({ phone })}
                    />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

          <Box style={webStyle.mainSubBox}>
            <Typography style={webStyle.basicDetailsHead}>Add your Job Title and Skills</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Job Title*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <Select
                      name="jobTitle"
                      defaultValue=""
                      value={this.state.jobTitle}
                      error={!!this.state.jobTitleError}
                      displayEmpty
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      onChange={this.handleJobTitleChange}
                    >
                      <MenuItem value="" disabled >
                        Select Job
                      </MenuItem>
                      <MenuItem value="Software Engineer">Software Engineer</MenuItem>
                      <MenuItem value="Product Manager">Product Manager</MenuItem>
                      <MenuItem value="Designer">Designer</MenuItem>
                      <MenuItem value="Data Scientist">Data Scientist</MenuItem>
                      <MenuItem value="Marketing Specialist">Marketing Specialist</MenuItem>
                    </Select>
                  </FormControl>
                  {this.jobTitleError()}
                </Grid>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Your Skills*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      value={this.state.skills}
                      name="skills"
                      placeholder="Enter your skills"
                      onChange={this.handleSkillsChange}
                      onKeyPress={this.handleSkillsKeyPress}
                      error={!!this.state.skillsError}
                       />
                  </FormControl>
                  {this.skillsError()}
                </Grid>
                {this.state.skillsArray.map((skill, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{skill}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteSkill(skill)}>
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}


                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Description*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput placeholder="Tell us about yourself"
                      fullWidth
                      multiline
                      minRows={4}
                      name="description"
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                      error={!!this.state.descriptionError}
                      />
                  </FormControl>
                  {this.descriptionError()}
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "26px" }}>
            <Grid item>
              <Button variant="outlined" style={webStyle.backButton}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="text"
                    style={webStyle.laterText}
                  >
                    I'll do it later
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" style={webStyle.nextButton} onClick={this.handleNext}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>


        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    padding: "18px 56px"
  },
  stepperStyle: {
    width: "100%",
    height: "auto",
    maxWidth: "1500px",
    margin: "0 auto",
    display: "block",
  },
  mainSubBox: {
    marginTop: "28px"
  },
  basicDetailsHead: {
    fontFamily: "Rubik",
    fontSize: "36px",
    fontWeight: 600,
  },
  BoxTwoStyle: {
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius: "12px",
    marginTop: "20px"
  },
  imageBoxStyle: {
    display: "flex",
    gap: "48px"
  },
  nameStyle: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontweight: 500,
    color: "#0A0A0A"
  },
  emailStyle: {
    fontFamily: "Rubik",
    fontSize: "18px",
    color: "#0A0A0A"
  },
  updateProfileBtn: {
    color: "#364BA0",
    border: "1px solid #364BA0",
    borderRadius: "26px",
    marginTop: "16px",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    fontSize: "20px",
    fontFamily: "Rubik",
    fontWeight: 500
  },
  labelStyle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#0A0A0A",
    fontWeight: 500,
    marginBottom: "8px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important"
    }
  },

  chipStyle: {
    background: "#E4F5FF",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  buttonStyle: {
    margin: '10px 0',
    fontWeight: 500,
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  laterText: {
    color: '#364BA0',
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  formError: {
    color: "red",
    fontSize: 14,
    fontFamily: "'Inter', sans-serif",
    marginTop: "4px"
  },
  skillStyle: {
    color: '#0A0A0A',
    padding: "2px 8px"
  },
  iconStyle: {
    color: '#0A0A0A',
    fontSize: '20px',
  },
};
// Customizable Area End
