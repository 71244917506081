import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// import VideocamIcon from "@material-ui/icons/Videocam";

import ChattingBoxController, { Props } from "./ChattingBoxController";

export default class ChattingBox extends ChattingBoxController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


// Customizable Area End
