// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider.web';
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  id: string;
}
interface S {
  aboutUsRes:Attributes;
  userType:string;
  
}
interface Image {
  url: string ;
}

interface Attributes {
  id: number|null;
  title: string;
  name: string;
  designation: string;
  description: string;
  image: Image;
}
// Customizable Area End
interface SS {
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
export default class AboutController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start
 AboutUsId:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.AboutUsId=""

    this.state = {
      userType:"",
      aboutUsRes:{
        id: null,
        title: '',
        name: '',
        designation: '',
        description: '',
        image: {
          url: ""
        }
      }
    
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchAboutUs();
    this.setState({
      userType: await storage.get('userType'),
    });
     
    // Customizable Area End
  }
  // Customizable Area Start
  handleRedirection = (componentName: string) => {
    if (componentName) {
      this.props.navigation.navigate(componentName);
    }
  };

  getRedirectPath = (userType:string)=>{
    switch (userType) {
      case 'customer':
        return "SearchTalent";
      case 'freelancer':
        return "SearchJob"
  
      default:
        return "FullWidthTab"
    }
  }
  
  fetchAboutUs =() => {
    const AboutUsDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.validationApiContentType
    };
    

    this.AboutUsId = AboutUsDetailsRequest.messageId;
    AboutUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.AboutUsDetailsRequestEndPoint
    );
    AboutUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    AboutUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getMethod
    );

    runEngine.sendMessage(
      AboutUsDetailsRequest.id,
      AboutUsDetailsRequest
    );
  };
   // Customizable Area End
  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.AboutUsId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {                   
          this.setState({ aboutUsRes: responseJson.data.attributes});        
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End
