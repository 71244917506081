import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {blankchat} from "./assets"

import ChattingBoxController, { Props } from "./ChattingBoxController";

export default class BlankChatBox extends ChattingBoxController {
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
      // Customizable Area Start
      // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <div style={styles.container}>
      <Box style={styles.boxStyle as React.CSSProperties}>
        <img src={blankchat} alt="" style={styles.imgStyle} />
        <Typography style={styles.textStyle as React.CSSProperties}>
          Once you connect with a client, you’ll be able to chat and
          collaborate here.
        </Typography>
      </Box>
    </div>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      height: "50vh",
      paddingTop:"10rem",
      fontFamily: "Inter",
      justifyContent:"center",
      alignContent:"center"
    },
    boxStyle: {
      width: "631px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center", 
    },
    imgStyle: {
      width: "360px",
    },
    textStyle: {
      fontFamily: "Inter",
      fontSize: "25px",
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "1.75px",
      textAlign: "center",
      color: "#878787",
      marginTop: "20px",
    },
  };
  
// Customizable Area End