import React from 'react';
// Customizable Area Start
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  withStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ReactCodeInput from 'react-code-input';
import { uploadIcon } from '../../blocks/dashboard/src/assets';
import FreelancerIpValuationController, {
  Props
} from './FreelancerIpValuationController.web ';
import { FileUploadArea, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';
import { login, successImage } from '../../blocks/email-account-registration/src/assets';
import { importantField } from './AppHeader.web';

const GreenCheckbox1 = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: '#263E51'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
const svgIcon = `
 <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding-right:9px; padding-top:4px; cursor: pointer;">
 <path d="M11.6883 11.5325C11.5325 11.5325 20.4156 2.64935 20.4156 2.64935C21.039 2.02597 21.039 1.09091 20.4156 0.467531C19.7922 -0.155846 18.8571 -0.155846 18.2338 0.467531L10.4416 8.1039L2.64935 0.467532C2.02597 -0.155845 1.09091 -0.155845 0.467532 0.467532C0.155843 0.779221 -4.76851e-07 1.09091 -4.56415e-07 1.55844C-4.35979e-07 2.02597 0.155843 2.33766 0.467532 2.64935L9.1948 11.5325C9.97402 12.1558 11.5325 11.5325 11.6883 11.5325Z" fill="black"/>
 </svg>
 `;

// Customizable Area End
// Customizable Area Start
const props = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '70px',
    borderRadius: '10px',
    fontSize: '30px',
    height: '55px',
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}
export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#263E51' : '#D6DBDF',
          color: active || completed ? '#FFF' : '#A9AEB2'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
// Customizable Area End
export default class FreelancerIpValuation extends FreelancerIpValuationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div style={{ display: 'inline-block' }}>
      <Popper
        anchorEl={this.state.anchorElement}
        open={this.state.openInfoPopper}
        placement={'bottom'}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: this.state.anchorElement
          }
        }}
        style={{
          width: '95%',
          background: '#FFF',
          borderRadius: '10px',
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography
                style={{
                  ...webStyle.header,
                  padding: '10px 20px',
                  color: '#4C4C4C',
                  fontWeight: 400,
                  letterSpacing: '0.27',
                  fontSize: '18px',
                  lineHeight: '35px'
                }}
              >
                {content}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );

  step1 = () => {
    return (
      <>
        <Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            data-testid="IPI1-infoButton"
          >
            <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
              Qualification
            </Typography>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                  <Typography style={webStyle.header}>Legal{importantField()}</Typography>
                  <TextField
                    data-testid="IPV1-legal"
                    placeholder="Name of School"
                    variant="outlined"
                    value={this.state.legal}
                    inputProps={{
                      style: {
                        ...webStyle.outlinedInput
                      },
                      maxLength:200
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '13px'
                      }
                    }}
                    style={webStyle.inputField}
                    name="legal"
                    onChange={this.handletextChange}
                    error={this.state.error['legal']}
                    helperText={this.state.error['legal']}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                  <Typography style={webStyle.header}>
                    LinkedIn Profile Link:
                  </Typography>
                  <TextField
                    data-testid="IPV1-linkedInProfileLink"
                    placeholder="https://www.google.com/intl/en_in/business/"
                    variant="outlined"
                    error={this.state.error['linkedInProfileLink']}
                    helperText={this.state.error['linkedInProfileLink']}
                    value={this.state.linkedInProfileLink}
                    InputProps={{
                      style: {
                        ...webStyle.outlinedInput,
                        borderRadius: '13px'
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachFileIcon
                            style={{ transform: 'rotate(45deg)', fill: 'blue' }}
                          />
                        </InputAdornment>
                      )
                    }}
                    style={webStyle.inputField}
                    name="linkedInProfileLink"
                    onChange={this.handleLinkValidChange}
                  />
                </Box>
              </Box>
              <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                <Typography style={webStyle.header}>Other{importantField()}</Typography>
                <TextField
                  data-testid="IPV1-other"
                  placeholder="Name of College"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px'
                    }
                  }}
                  variant="outlined"
                  value={this.state.other}
                  name="other"
                  onChange={(e: any) => this.handletextChange(e)}
                  error={this.state.error['other']}
                  helperText={this.state.error['other']}
                  style={webStyle.inputField}
                />
                <Typography style={webStyle.header}>
                  No. of experience in IP Valuation?{importantField()}
                </Typography>
                <TextField
                  data-testid="IPV1-experienceInIpValuation"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px'
                    }
                  }}
                  placeholder="Total Experience"
                  style={webStyle.inputField}
                  variant="outlined"
                  name="experienceInIpValuation"
                  value={this.state.experienceInIpValuation}
                  onChange={this.handleExpFieldChange}
                  error={this.state.error['experienceInIpValuation']}
                  helperText={this.state.error['experienceInIpValuation']}
                />
                <Typography style={webStyle.header}>Select Your type:{importantField()}</Typography>
                <RadioGroup
                  style={webStyle.RadioGroupBox}
                  aria-label="type"
                  name="type"
                  value={this.state.type}
                  onChange={this.handleChange}
                  data-testid="IPV1-type"
                >
                  <FormControlLabel
                    value="Individual"
                    control={
                      <Radio
                        style={
                          this.state.type === 'Individual'
                            ? { color: '#206FC4' }
                            : { color: '#979797' }
                        }
                      />
                    }
                    style={
                      this.state.type === 'Individual'
                        ? { ...webStyle.SelectedRadioButton }
                        : { ...webStyle.UnSelectedRadioButton }
                    }
                    label="Individual"
                  />
                  <FormControlLabel
                    value="Firm"
                    control={
                      <Radio
                        style={
                          this.state.type === 'Firm'
                            ? { color: '#206FC4' }
                            : { color: '#979797' }
                        }
                      />
                    }
                    style={
                      this.state.type === 'Firm'
                        ? { ...webStyle.SelectedRadioButton }
                        : { ...webStyle.UnSelectedRadioButton }
                    }
                    label="Firm"
                  />
                </RadioGroup>
                {!this.state.type && <Typography style={{ color: 'red' }}>{this.state.typeError}</Typography>}
              </Box>

              <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                <Typography style={webStyle.header}>Focus Area:{importantField()}</Typography>
                <RadioGroup
                  style={webStyle.RadioGroupBox}
                  aria-label="focusArea2"
                  name="focusArea2"
                  value={this.state.focusArea2}
                  onChange={this.handleChange}
                  data-testid="IPV1-focusArea2"
                >
                  <FormControlLabel
                    value="industry_specific"
                    control={
                      <Radio
                        style={
                          this.state.focusArea2 === 'industry_specific'
                            ? { color: '#206FC4' }
                            : { color: '#979797' }
                        }
                      />
                    }
                    style={
                      this.state.focusArea2 === 'industry_specific'
                        ? { ...webStyle.SelectedRadioButton }
                        : { ...webStyle.UnSelectedRadioButton }
                    }
                    label="Industry Specific"
                  />
                  <FormControlLabel
                    value="all"
                    control={
                      <Radio
                        style={
                          this.state.focusArea2 === 'all'
                            ? { color: '#206FC4' }
                            : { color: '#979797' }
                        }
                      />
                    }
                    style={
                      this.state.focusArea2 === 'all'
                        ? { ...webStyle.SelectedRadioButton }
                        : { ...webStyle.UnSelectedRadioButton }
                    }
                    label="All"
                  />
                </RadioGroup>
                {!this.state.focusArea2 && <Typography style={{ color: 'red' }}>{this.state.focusArea2Error}</Typography>}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            color="default"
            data-testid="ipValuation-back"
            onClick={() =>
              this.handleredirection('ProfessionalAccountRegistration')}>
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext1}
            data-testid="ipValuation-next"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step2 = () => {
    const countryPatentLawGuidelines = [
      'USPTO',
      'European Union',
      'Indian',
      'Japanese',
      'Chinese',
      'Other'
    ];
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'column'} width={'45%'}>
              <Typography style={webStyle.header}>City Of Operation{importantField()}</Typography>
              <TextField
                data-testid="IPV2-cityOfOperation"
                placeholder="Upto 20 Cities "
                inputProps={{
                  style: {
                    ...webStyle.outlinedInput
                  }
                }}
                InputProps={{
                  style: {
                    borderRadius: '13px'
                  }
                }}
                variant="outlined"
                value={this.state.cityOfOperation}
                name="cityOfOperation"
                onChange={this.handletextChange2}
                style={webStyle.inputField}
                error={this.state.error['cityOfOperation']}
                helperText={this.state.error['cityOfOperation']}
              />
              <Typography style={webStyle.header}>
                Country Of Operation{importantField()}
              </Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="countryOfOperation"
                name="countryOfOperation"
                value={this.state.countryOfOperation}
                data-testid="IPV2-countryOfOperation"
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="country_wide"
                  control={
                    <Radio
                      style={
                        this.state.countryOfOperation === 'country_wide'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.countryOfOperation === 'country_wide'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="CountryWide"
                />
                <FormControlLabel
                  value="world_wide"
                  control={
                    <Radio
                      style={
                        this.state.countryOfOperation === 'world_wide'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.countryOfOperation === 'world_wide'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="WorldWide"
                />
              </RadioGroup>
              {!this.state.countryOfOperation && <Typography style={{ color: 'red' }}>{this.state.countryOfOperationError}</Typography>}
              <Typography style={webStyle.header}>
              Which country's patent law guidelines are you familiar with?{importantField()}
              </Typography>

              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Box style={webStyle.selectMainBox}>
                  <div
                    id="SelectMainBox"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingTop: '18px',
                      fontFamily: "'Inter', sans-serif",
                      cursor: 'pointer'
                    }}
                    onClick={() => this.handleSelectBoxClick()}
                  >
                    {this.state.patentLawOffice.length === 0 ? (
                      <span
                        style={{
                          marginLeft: '10px',
                          color: '#000000',
                          fontFamily: "'Inter', sans-serif",
                          textAlign: 'center'
                        }}
                      >
                        Select Patent Office
                      </span>
                    ) : (
                      <span
                        style={{
                          flex: 1,
                          marginLeft: '10px',
                          flexWrap: 'wrap',
                          fontFamily: "'Inter', sans-serif",
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {this.state.patentLawOffice.join(', ')}
                      </span>
                    )}
                    <div
                      data-test-id="main-box-click"
                      style={{ paddingRight: 13, borderRadius: '10px' }}
                      dangerouslySetInnerHTML={{ __html: svgIcon }}
                    />
                  </div>
                </Box>
              </ClickAwayListener>
              {this.state.showpatentLawOffice && (
                <Grid style={webStyle.selectBox} container>
                  {countryPatentLawGuidelines.map((item, index) => (
                    <Grid key={`${index}job`} item xs={6}>
                      <FormControlLabel
                        control={<GreenCheckbox1 />}
                        checked={this.state.patentLawOffice.includes(item)}
                        label={
                          <Typography style={webStyle.labelStyle3}>
                            {item}
                          </Typography>
                        }
                        onChange={() => this.handlePatentLawOfficeSelection(item)}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {this.state.patentLawOfficeError && <Typography style={{ color: 'red' }}>{this.state.patentLawOfficeError}</Typography>}
            </Box>
            <Box display={'flex'} flexDirection={'column'} width={'60%'}>
              <Typography style={webStyle.header}>Focus{importantField()}</Typography>
              <RadioGroup
                style={{
                  ...webStyle.RadioGroupBox,
                  gridTemplateColumns: 'repeat(3) 1fr',
                  gridAutoFlow: 'column'
                }}
                aria-label="focusArea3"
                name="focusArea3"
                value={this.state.focusArea3}
                onChange={this.handleChange}
                data-testid="IPV2-focusArea3"
              >
                <FormControlLabel
                  value="utility"
                  control={
                    <Radio
                      style={
                        this.state.focusArea3 === 'utility'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea3 === 'utility'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Utility Patent Illustration"
                />
                <FormControlLabel
                  value="design"
                  control={
                    <Radio
                      style={
                        this.state.focusArea3 === 'design'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea3 === 'design'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Design Patent Illustration"
                />
                <FormControlLabel
                  value="both"
                  control={
                    <Radio
                      style={
                        this.state.focusArea3 === 'both'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea3 === 'both'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Both"
                />
              </RadioGroup>
              {!this.state.focusArea3 && <Typography style={{ color: 'red' }}>{this.state.focusArea3Error}</Typography>}

            </Box>
            <Box display={'flex'} flexDirection={'column'} width={'45%'}>
              <Typography style={webStyle.header}>Fees{importantField()}</Typography>
              <TextField
                data-testid="IPV2-feesAmount"
                placeholder="Enter Fees Per Assignment"
                inputProps={{
                  style: {
                    ...webStyle.outlinedInput
                  }
                }}
                InputProps={{
                  style: {
                    borderRadius: '13px'
                  }
                }}
                variant="outlined"
                value={this.state.feesAmount}
                name="feesAmount"
                onChange={this.handleFeesFieldChange}
                error={this.state.error['feesAmount']}
                helperText={this.state.error['feesAmount']}
                style={webStyle.inputField}
              />
            </Box>
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            color="default"
            data-testid="ipValuation-back"
            onClick={this.handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext2}
            data-testid="ipValuation-next"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step3 = () => {
    const currentStateData: any = this.state;
    return (
      <>
        <Box>
          <Box display={'flex'} alignItems={'center'}>
            <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
              Success Stories
            </Typography>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box flex={0.6}>
              <Typography style={{ ...webStyle.header, marginTop: '10px' }}>
                Have you been featured in the media?{importantField()}
              </Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="featured in the mediaegory"
                name="featuredInTheMedia"
                value={this.state.featuredInTheMedia}
                data-testid="IPV3-featuredInTheMedia"
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      style={
                        this.state.featuredInTheMedia === 'Yes'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.featuredInTheMedia === 'Yes'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      style={
                        this.state.featuredInTheMedia === 'No'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.featuredInTheMedia === 'No'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="No"
                />
              </RadioGroup>
              {!this.state.featuredInTheMedia && <Typography style={{ color: 'red' }}>{this.state.featuredInTheMediaError}</Typography>}
              <Typography style={webStyle.header}>
               Have you spoken at an IP conference?{importantField()}
              </Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="IpConference"
                name="IpConference"
                value={this.state.IpConference}
                data-testid="IPV3-IpConference"
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      style={
                        this.state.IpConference === 'Yes'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.IpConference === 'Yes'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      style={
                        this.state.IpConference === 'No'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.IpConference === 'No'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="No"
                />
              </RadioGroup>
              {!this.state.IpConference && <Typography style={{ color: 'red' }}>{this.state.IpConferenceError}</Typography>}
              <Typography style={webStyle.header}>
               Have you published any articles?{importantField()}
              </Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="article"
                name="article"
                value={this.state.article}
                data-testid="IPV3-article"
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      style={
                        this.state.article === 'Yes'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.article === 'Yes'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      style={
                        this.state.article === 'No'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.article === 'No'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="No"
                />
              </RadioGroup>
              {!this.state.article && <Typography style={{ color: 'red' }}>{this.state.articleError}</Typography>}

            </Box>
            <Box
              style={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateRows: '1 1fr',
                rowGap: '20px',
                flex: 0.3,
                marginTop:"-24px"
              }}
            >

              {[{ title: "Attach Image or Video(max-5MB)", state: "step3FileUplod1", error: 'step3FileUplod1' },
              { title: "Attach Image or Video(max-5MB)", state: "step3FileUplod2", error: 'step3FileUplod2' },
              { title: "Attach link", state: "step3FileUplod3", error: 'step3FileUplod3' },
              ].map((item: any, index: any) => (
                <>
                  <FileUploadArea
                    testId={`FreelancerIpValuation-step-3-fileInputindustry${index +
                      1}`}
                    id={`FreelancerIpValuation-step-3-fileInputindustry${index +
                      1}`}
                    uploadIcon={uploadIcon}
                    btnContent={
                      currentStateData[item.state]
                        ? currentStateData[item.state]?.name
                        : item.title}
                    onChange={this.handleFileUpload}
                    style={{marginTop:"0px"}}
                    stateKeyRef={item.state}
                    disabled={(this.state.IpConference === 'No' && index == 1) || (this.state.featuredInTheMedia === 'No' && index == 0) || (this.state.article === 'No' && index == 2)}
                  />
                  {this.state.featuredInTheMedia === 'Yes' && index == 0 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                  {this.state.IpConference === 'Yes' && index == 1 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                  {this.state.article === 'Yes' && index == 2 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                </>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            color="default"
            data-testid="ipValuation-back"
            onClick={this.handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext3}
            data-testid="ipValuation-next"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step4 = () => {
    const currentStateData: any = this.state;
    return (
      <>
        <Box
          style={{
            display: 'grid',
            gridAutoFlow: 'row',
            rowGap: '40px'
          }}
        >
          <Box
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateRows: '1 0.35fr',
              rowGap: '20px',
              justifyContent: 'space-around',
              marginTop:"100px"

            }}
          >
            <Box>
              <FileUploadArea
                id="FreelancerIpValuation-step-4-upload-1"
                btnContent={
                  currentStateData['step4FileUplod1']
                    ? currentStateData['step4FileUplod1']?.name
                    : 'Certificate and link of website'}
                testId={'FreelancerIpValuation-step-4-upload-1'}
                uploadIcon={uploadIcon}
                onChange={this.handleFileUpload}
                stateKeyRef={'step4FileUplod1'}
              />
              {!this.state.step4FileUplod1 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUplod1']}</Typography>}
            </Box>
            <Box>
              <FileUploadArea
                id="FreelancerIpValuation-step-4-upload-2"
                btnContent={
                  currentStateData['step4FileUplod2']
                    ? currentStateData['step4FileUplod2']?.name
                    : 'Academic certificate and link of website'}
                testId={'FreelancerIpValuation-step-4-upload-2'}
                uploadIcon={uploadIcon}
                onChange={this.handleFileUpload}
                stateKeyRef={'step4FileUplod2'}
              />
              {!this.state.step4FileUplod2 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUplod2']}</Typography>}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FileUploadArea
              id="FreelancerIpValuation-step-4-upload-3"
              btnContent={
                currentStateData['step4FileUplod3']
                  ? currentStateData['step4FileUplod3']?.name
                  : 'Industry certificate and link of website'}
              testId={'FreelancerIpValuation-step-4-upload-3'}
              uploadIcon={uploadIcon}
              onChange={this.handleFileUpload}
              stateKeyRef={'step4FileUplod3'}
            />
            {!this.state.step4FileUplod3 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUplod3']}</Typography>}
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            color="default"
            data-testid="ipValuation-back"
            onClick={this.handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext4}
            data-testid="ipValuation-next"
          >
            Finish
          </Button>
        </Box>
      </>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        {!this.state.verfication && !this.state.successPage &&
          <Box padding={'3%'}>
            <Box style={webStyle.outerContainer}>
              <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} />

              <Box style={webStyle.root}>
                {/* stepper component starts */}
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                  {steps.map(label => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </Box>
            {this.getStepComponent()}
          </Box>
        }

        {this.state.verfication === true && !this.state.successPage &&
          <>
            <Grid container spacing={0}>
              <Grid container item xs={12} md={5} justifyContent="center">
                <Grid item xs={9}>
                  <img src={Horizontalinventohub} style={webStyle.invento} onClick={this.handleLogoClick}/>
                  <div style={webStyle.verifyStyle}>
                    <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
                    <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
                    <ReactCodeInput
                      {...props}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue}
                      name={"VerifyOtp"} inputMode={"tel"} />
                    <Box component={"div"} style={{ marginTop: '16px' }}>
                      <FormControl style={{ width: '98%', display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          disabled={this.state.VerificationError}
                          onClick={this.handleVerifybox}
                        >
                          VERIFY
                        </Button>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          onClick={this.handleResendOtp}
                          disabled={this.state.disabledResendOtp}
                          data-testid="resend_emailotp"
                        >
                          Resend OTP

                        </Button>
                      </FormControl>
                    </Box>
                    {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
                    {this.state.VerificationError && <Typography style={webStyle.timer}> Please Enter OTP</Typography>}
                    <Box component={"div"} margin={"30px 0"}>
                      <span style={webStyle.haveAccount}>Have an account? </span>
                      <span style={webStyle.signIn} onClick={this.handleSignIn}>Sign in</span>
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <img src={login} style={webStyle.image} alt="peopleImg" />
              </Grid>
            </Grid>
          </>
        }{this.state.successPage === true && <>
          <Grid container spacing={0} style={{ display: "block" }}>
            <Grid container item xs={12} md={5} justifyContent="center">
              <Grid item xs={9}>
              <img src={Horizontalinventohub} style={webStyle.invento} onClick={this.handleLogoClick}/>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={webStyle.successImage}>
              <img src={successImage} alt="successImage" />
            </Grid>
            <Grid item>
              <Typography style={webStyle.suceesText}>Congratulations! {this.state.profileName} your profile has been created</Typography>
            </Grid>
            <Grid container style={webStyle.backNextBtn}>
              <Grid item>
                <Button
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" style={{ padding: "10px 40px", fontSize: " 18px" }} onClick={this.handleSignIn}>
                  NEXT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '50%',
    flex:"0.7"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  timer: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "red",
    marginTop: "10px"
  },
  haveAccount: {
    color: "#6F6E6E",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px"
  },
  otpTime: {
    marginBottom: "20px",
    color: "#6F6E6E",
    fontWeight: 600,
    fontSize: " 18px",
  },
  backNextBtn: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
    marginTop: "84px",
    "@media (min-width: 425px) and (max-width:759px)": {
      marginTop: "20px",
      position: "unset"
    }
  },
  suceesText: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "40px",
    textAlign: "center" as "center"
  },
  successImage: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  signIn: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "40px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  nxtBtn: {
    padding: "12px", borderRadius: "8px"
  },
  otpStyle: {
    margin: "76px 0px",
    fontSize: "32px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "'Inter', sans-serif"
  },
  verifyStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginTop: '200px',
    "@media (max-width: 768px)": {
      marginTop: 0
    }
  },
  backBtn: {
    border: '1px solid #FFA42F',
    color: '#FFA42F',
    width: '130px',
    height: '40px',
    flexShrink: 0,
    backgroundColor: 'white',
    borderRadius: '13px'
  },
  image: {
    width: "100%",
    height: "auto",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  invento: {
    width: "300px",
    height: "61px",
    objectFit: "cover"as const,
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '20px',
    marginTop: '30px',
    display:"flex"
  },
  RadioGroupBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr ', // Two equal columns
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },

  UploadButton: {
    height: '100%',
    width: '100%',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    // backgroundColor: '#D6DBDF',
    border: '1px dashed #979797',
    borderRadius: '12px'
  },

  SelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: '#263343',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  UnSelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: 'transparent',
    color: '#626262',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '110px',
    height: '45px',
    borderRadius: '10px',
    flexShrink: 0,
    color: '#FFF',
    backgroundColor: '#150875'
  },
  selectMainBox: {
    minHeight: '52px',
    border: '1px solid silver',
    marginBottom: '15px',
    borderRadius: '10px'
  },
  selectBox: {
    padding: '12px',
    borderRadius: 15,
    background: '#283543'
  },
  labelStyle3: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif"
  }
};
// Customizable Area End
