import React from 'react';
// Customizable Area Start
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { Props } from './FreelancerIpEnforcementController.web';

import { infoIcon, uploadIcon2 } from '../../blocks/dashboard/src/assets';
import FreelancerLawyerController from './FreelancerLawyerController.web';
import { FileUploadArea, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';
import Alert from '@material-ui/lab/Alert';
import { login, successImage } from '../../blocks/email-account-registration/src/assets';
import ReactCodeInput from 'react-code-input';

export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#F2A630' : '#FBE3BC',
          color: active || completed ? '#FFF' : '#DF994A'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}

const props = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '70px',
    borderRadius: '10px',
    fontSize: '30px',
    height: '55px',
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}
// Customizable Area End
export default class FreelancerLawyer extends FreelancerLawyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div
      style={{
        display: this.state.openInfoPopper ? 'inline-block' : 'none',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        width: 'inherit',
        background: '#FFF',
        borderRadius: '10px',
      }}
    >
      <Typography
        style={{
          ...webStyle.header,
          padding: '10px 20px',
          fontSize: '15px',
          fontWeight: 400,
          lineHeight: '25px',
          letterSpacing: '0.27',
          color: '#4C4C4C',
        }}
      >
        {content}
      </Typography>
    </div>
  );

  step1 = () => {
    const currentStateData: any = this.state;

    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          data-testid="LYR1-infoButton"
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Qualifications
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '25px',width:"25px",cursor:"pointer"
          }}
          />
        </Box>
        {this
          .popper(`As you join the marketplace for inventors, it's important to highlight your education and skills in a specific IP field or locality.
          This will attract inventors seeking IP lawyers to patent or defend their IP and create a new client base for you.
          Your qualifications will differentiate you from competitors and increase the likelihood of securing clients who value your expertise and experience.`)}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.7}>
            <Typography style={webStyle.header}>LLB College Name<span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-testid="LYR1-llbCollegeName"
              placeholder="Name of College"
              variant="outlined"
              value={this.state.llbCollegeName}
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              style={webStyle.inputField}
              name="llbCollegeName"
              onChange={this.handletextChange}
              error={this.state.error['llbCollegeName']}
              helperText={this.state.error['llbCollegeName']}
            />
            <Typography style={webStyle.header}>IIM College Name<span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-testid="LYR1-iimCollegeName"
              placeholder="Name of College"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              variant="outlined"
              value={this.state.iimCollegeName}
              name="iimCollegeName"
              onChange={this.handletextChange}
              style={webStyle.inputField}
              error={this.state.error['iimCollegeName']}
              helperText={this.state.error['iimCollegeName']}
            />
            <Typography style={webStyle.header}>Are you done PHD?</Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="PHD"
              name="phd"
              value={this.state.phd}
              onChange={this.handlePHDChange}
              data-testid="LYR1-phd"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.phd === 'Yes'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.phd === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.phd === 'No'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.phd === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.phd && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px"  }}>{this.state.radioError['phd']}</Typography>}
            <Typography style={webStyle.header}>
              Enter Name Of College<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              data-testid="LYR1-nameOfCollege"
              placeholder="Name of College"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              variant="outlined"
              value={this.state.nameOfCollege}
              disabled={this.state.phd === 'No'}
              name="nameOfCollege"
              onChange={this.handletextChange}
              style={webStyle.inputField}
              error={this.state.error['nameOfCollege']}
              helperText={this.state.error['nameOfCollege']}
            />

            <Typography style={webStyle.header}>
              No.of years of experience in IP law<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              data-testid="LYR1-experienceInIPLaw"
              placeholder="Experience"
              variant="outlined"
              value={this.state.experienceInIPLaw}
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              style={webStyle.inputField}
              name="experienceInIPLaw"
              onChange={this.handleExpFieldChange}
              error={this.state.numberError['experienceInIPLaw']}
              helperText={this.state.numberError['experienceInIPLaw']}
            />
            <Typography style={webStyle.header}>Focus Area<span style={{ color: "red" }}>*</span></Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="focusArea"
              name="focusArea"
              value={this.state.focusArea}
              data-testid="LYR1-focusArea"
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value="physical"
                control={
                  <Radio
                    style={
                      this.state.focusArea === 'physical'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea === 'physical'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Physical"
              />
              <FormControlLabel
                value="digital"
                control={
                  <Radio
                    style={
                      this.state.focusArea === 'digital'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea === 'digital'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Digital"
              />

              <FormControlLabel
                value="both"
                control={
                  <Radio
                    style={
                      this.state.focusArea === 'both'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea === 'both'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Both"
              />
            </RadioGroup>
            {!this.state.focusArea && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.error['focusArea']}</Typography>}
            <Typography style={webStyle.header}>
              Do you have own law firm?<span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="ownLawFirm"
              name="ownLawFirm"
              value={this.state.ownLawFirm}
              data-testid="LYR1-ownLawFirm"
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.ownLawFirm === 'Yes'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.ownLawFirm === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.ownLawFirm === 'No'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.ownLawFirm === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.ownLawFirm && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['ownLawFirm']}</Typography>}
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            {' '}
            <Typography style={{...webStyle.header,marginBottom:"-15px"}}>
              Attach Bar Carousel Certificate
            </Typography>
            <FileUploadArea
              id="FreelancerLawer-step-1-upload-1"
              style={webStyle.UploadButton}
              btnContent={
                currentStateData['step1FileUpload']
                  ? currentStateData['step1FileUpload']?.name
                  : 'Upload your image/video'}
              testId={'FreelancerLawer-step-1-upload-1'}
              uploadIcon={uploadIcon2}
              onChange={this.handleFileUpload}
              stateKeyRef={'step1FileUpload'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}

            />
            {!this.state.step1FileUpload && <Typography style={{ color: 'red' }}>{this.state.error['step1FileUpload']}</Typography>}
          </Box>
        </Box>
      </Box>
    );
  };

  step2 = () => {
    const {step2FileUpload1}  = this.state;
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          onClick={this.handleClickInfo}
          data-testid="LYR2-infoButton"
          style={{marginBottom: '-15px'}}
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Area of operation
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.7}>
            <Typography style={webStyle.header}>City of operation<span style={{ color: "red" }}>*</span></Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="City of operation"
              name="cityOfOperation"
              value={this.state.cityOfOperation}
              onChange={this.handleRadioChange}
              data-testid="LYR2-cityOfOperation"
            >
              <FormControlLabel
                value="country_wide"
                control={
                  <Radio
                    style={
                      this.state.cityOfOperation === 'country_wide'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.cityOfOperation === 'country_wide'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Country Wide"
              />
              <FormControlLabel
                value="world_wide"
                control={
                  <Radio
                    style={
                      this.state.cityOfOperation === 'world_wide'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.cityOfOperation === 'world_wide'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="World Wide"
              />
            </RadioGroup>
            {!this.state.cityOfOperation && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['cityOfOperation']}</Typography>}

            <Typography style={webStyle.header}>
              Country of operation<span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="Country of operation"
              name="countryOfOperation"
              value={this.state.countryOfOperation}
              onChange={this.handleRadioChange}
              data-testid="LYR2-countryOfOperation"
            >
              <FormControlLabel
                value="civil_lawyer"
                control={
                  <Radio
                    style={
                      this.state.countryOfOperation === 'civil_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.countryOfOperation === 'civil_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Civil Lawyer"
              />
              <FormControlLabel
                value="criminal_lawyer"
                control={
                  <Radio
                    style={
                      this.state.countryOfOperation === 'criminal_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.countryOfOperation === 'criminal_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Criminal Lawyer"
              />
              <FormControlLabel
                value="both"
                control={
                  <Radio
                    style={
                      this.state.countryOfOperation === 'both'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.countryOfOperation === 'both'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Both"
              />
            </RadioGroup>
            {!this.state.countryOfOperation && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['countryOfOperation']}</Typography>}

            <Typography style={webStyle.header}>Area of operation<span style={{ color: "red" }}>*</span></Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="Area of operation"
              name="areaOfOperation"
              value={this.state.areaOfOperation}
              onChange={this.handleRadioChange}
              data-testid="LYR2-areaOfOperation"
            >
              <FormControlLabel
                value="civil_lawyer"
                control={
                  <Radio
                    style={
                      this.state.areaOfOperation === 'civil_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.areaOfOperation === 'civil_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Civil Lawyer"
              />
              <FormControlLabel
                value="criminal_lawyer"
                control={
                  <Radio
                    style={
                      this.state.areaOfOperation === 'criminal_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.areaOfOperation === 'criminal_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Criminal Lawyer"
              />
              <FormControlLabel
                value="both"
                control={
                  <Radio
                    style={
                      this.state.areaOfOperation === 'both'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.areaOfOperation === 'both'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Both"
              />
            </RadioGroup>
            {!this.state.areaOfOperation && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['areaOfOperation']}</Typography>}

            <Typography style={webStyle.header}>
              Is your practice as a?<span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="Is your practise as a"
              name="practise"
              value={this.state.practise}
              onChange={this.handleRadioChange}
              data-testid="LYR2-practise"
            >
              <FormControlLabel
                value="civil_lawyer"
                control={
                  <Radio
                    style={
                      this.state.practise === 'civil_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.practise === 'civil_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Civil Lawyer"
              />
              <FormControlLabel
                value="criminal_lawyer"
                control={
                  <Radio
                    style={
                      this.state.practise === 'criminal_lawyer'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.practise === 'criminal_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Criminal Lawyer"
              />
              <FormControlLabel
                value="both"
                control={
                  <Radio
                    style={
                      this.state.practise === 'both'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.practise === 'both'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Both"
              />
            </RadioGroup>
            {!this.state.practise && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['practise']}</Typography>}

            <Typography style={webStyle.header}>
              Are you individual or law Firm?<span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="Firm"
              name="firm"
              value={this.state.firm}
              onChange={this.handleRadioChange}
              data-testid="LYR2-firm"
            >
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    style={
                      this.state.firm === 'individual'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.firm === 'individual'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Individual"
              />
              <FormControlLabel
                value="law_firm"
                control={
                  <Radio
                    style={
                      this.state.firm === 'law_firm'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.firm === 'law_firm'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Law Firm"
              />
            </RadioGroup>
            {!this.state.firm && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['firm']}</Typography>}

            <Typography style={webStyle.header}>
              Enter the name of your colleagues<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              data-testid="LYR2-yourCollegues"
              variant="outlined"
              value={this.state.yourCollegues}
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              style={{...webStyle.inputField,width:"28%"}}
              name="yourCollegues"
              onChange={this.handleNameChange}
              error={this.state.error['yourCollegues']}
              helperText={this.state.error['yourCollegues']}
            />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={0.25} marginTop={"100px"}>
          <Typography style={{...webStyle.header,marginBottom:"-15px"}}>
              Attach Bar Carousel Certificate
            </Typography>
          <FileUploadArea
              id="FreelancerLawer-step-4-upload-1"
              btnContent={
                step2FileUpload1?.name
                  ? step2FileUpload1?.name
                  : 'Upload your image/video'}
              testId={'FreelancerLawer-step-2-upload-1'}
              uploadIcon={uploadIcon2}
              onChange={this.handleFileUpload}
              stateKeyRef={'step2FileUpload1'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
              width={'350px'}
            />
            {!this.state.step4FileUpload1 && <Typography style={{ color: 'red' }}>{this.state.error['step2FileUpload1']}</Typography>}
          </Box>
        </Box>
      </Box>
    );
  };
  step3 = () => {
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          data-testid="LYR3-infoButton"
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Fees Assignment
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '24px',width:"30px",cursor: 'pointer'}}  
          />
        </Box>
        {this
          .popper(`As you join the marketplace for inventors, it's important to make your fee structure transparent for the different kinds of services you offer.
          This will enable inventors seeking IP lawyers to patent or defend their IP to easily assess whether your services align with their budget and needs.
          By being upfront about your fees, you increase the likelihood of attracting clients who value transparency and honesty in business relationships.`)}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          marginTop={"30px"}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.7}>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="feesAssignment"
              name="feesAssignment"
              value={this.state.feesAssignment}
              onChange={this.handleFeesAssignment}
              data-testid="LYR3-feesAssignment"
            >
              <FormControlLabel
                value="assignment"
                control={
                  <Radio
                    style={
                      this.state.feesAssignment === 'assignment'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesAssignment === 'assignment'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Per Assignment"
              />
            </RadioGroup>

            <Typography style={webStyle.header}>Document</Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="feesAssignment"
              name="feesAssignment"
              value={this.state.feesAssignment}
              onChange={this.handleFeesAssignment}
              data-testid="LYR3-document"
            >
              <FormControlLabel
                value="per_document"
                control={
                  <Radio
                    style={
                      this.state.feesAssignment === 'per_document'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesAssignment === 'per_document'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Per Document"
              />
            </RadioGroup>
            <Typography style={webStyle.header}>Civil Case</Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="feesAssignment"
              name="feesAssignment"
              value={this.state.feesAssignment}
              onChange={this.handleFeesAssignment}
              data-testid="LYR3-feesAssignment"
            >
              <FormControlLabel
                value="Per Case"
                control={
                  <Radio
                    style={
                      this.state.feesAssignment === 'Per Case'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesAssignment === 'Per Case'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Per Case"
              />
            </RadioGroup>

            <Typography style={webStyle.header}>Criminal Case</Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="feesAssignment"
              name="feesAssignment"
              value={this.state.feesAssignment}
              onChange={this.handleFeesAssignment}
              data-testid="LYR3-feesAssignment"
            >
              <FormControlLabel
                value="Per Criminal"
                control={
                  <Radio
                    style={
                      this.state.feesAssignment === 'Per Criminal'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesAssignment === 'Per Criminal'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Per Case"
              />
            </RadioGroup>
            {!this.state.feesAssignment && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.error['feesAssignment']}</Typography>}
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={0.25}>
            {' '}
          </Box>
        </Box>
      </Box>
    );
  };
  step4 = () => {
    const currentStateData: any = this.state;
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          marginBottom={"-15px"}
          data-testid="LYR4-infoButton"
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px',marginBottom:"10px"}}>
            Success Stories
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}

            style={{ marginLeft: '8px', marginTop: '17px',width:"30px",cursor:"pointer" }}
          />
        </Box>
        {this
          .popper(`Inventors around the world are in search of legal experts who can assist them in patenting and safeguarding their intellectual property.
          Your expertise in this field can make a significant difference in their lives and businesses.
          As an inventor's trusted legal representative, it is crucial to demonstrate your past successes and achievements to build trust and confidence.
          Share your documented success stories and case studies with potential clients to showcase your skills and experience in IP law.
          Don't miss out on the opportunity to help inventors protect their valuable ideas and innovations.`)}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.4}>
            <Typography style={webStyle.header}>
              Have you been featured in the media?
            </Typography>
            <FileUploadArea
              id="FreelancerLawer-step-4-upload-1"
              btnContent={
                currentStateData['step4FileUpload1']
                  ? currentStateData['step4FileUpload1']?.name
                  : 'Upload your image/video'}
              testId={'FreelancerLawer-step-4-upload-1'}
              uploadIcon={uploadIcon2}
              onChange={this.handleFileUpload}
              stateKeyRef={'step4FileUpload1'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
            />
            {!this.state.step4FileUpload1 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUpload1']}</Typography>}

            <Typography style={webStyle.header}>
              Have you spoken at an IP conference?
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}
              aria-label="IP conference"
              name="iPConference"
              value={this.state.iPConference}
              onChange={this.handleIPChange}
              data-testid="LYR4-iPConference"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.iPConference === 'Yes'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.iPConference === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.iPConference === 'No'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.iPConference === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.iPConference && <Typography style={{ color: 'red' }}>{this.state.radioError['iPConference']}</Typography>}

            <FileUploadArea
              id="FreelancerLawer-step-4-upload-2"
              disabled={this.state.iPConference === 'No'}
              btnContent={
                currentStateData['step4FileUpload2']
                  ? currentStateData['step4FileUpload2']?.name
                  : 'Upload your image/video'}
              testId={'FreelancerLawer-step-4-upload-2'}
              uploadIcon={uploadIcon2}
              onChange={this.handleFileUpload}
              stateKeyRef={'step4FileUpload2'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
            />

            {this.state.iPConference === 'Yes' &&
              <Typography style={{ color: 'red' }}>{this.state.error['step4FileUpload2']}</Typography>}

            <Typography style={webStyle.header}>
              Have you published any article?
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}
              aria-label="article"
              name="article"
              value={this.state.article}
              onChange={this.handleArticleChange}
              data-testid="LYR4-article"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.article === 'Yes'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.article === 'No'
                        ? { color: '#F2A630' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.article && <Typography style={{ color: 'red' }}>{this.state.radioError['article']}</Typography>}
            <FileUploadArea
              id="FreelancerLawer-step-4-upload-3"
              btnContent={
                currentStateData['step4FileUpload3']
                  ? currentStateData['step4FileUpload3']?.name
                  : 'Upload your image/video'}
              testId={'FreelancerLawer-step-4-upload-3'}
              disabled={this.state.article === 'No'}
              uploadIcon={uploadIcon2}
              onChange={this.handleFileUpload}
              stateKeyRef={'step4FileUpload3'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
            />
            {this.state.article === 'Yes' &&
              <Typography style={{ color: 'red' }}>{this.state.error['step4FileUpload3']}</Typography>}

            <Typography style={webStyle.header}>Awards</Typography>
            <Box display={'grid'} gridAutoFlow={'row'} gridRowGap={'15px'}>
              <FileUploadArea
                id="FreelancerLawer-step-4-upload-4"
                btnContent={
                  currentStateData['step4FileUploadAwards1']
                    ? currentStateData['step4FileUploadAwards1']?.name
                    : 'Government: Certificate and link of website'}
                testId={'FreelancerLawer-step-4-upload-4'}
                uploadIcon={uploadIcon2}
                onChange={this.handleFileUpload}
                stateKeyRef={'step4FileUploadAwards1'}
                backgroundColor= {'#FFF7E9'}
                border={'1px dashed #979797'}
              />
              {!this.state.step4FileUploadAwards1 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUploadAwards1']}</Typography>}

              <FileUploadArea
                id="FreelancerLawer-step-4-upload-5"
                btnContent={
                  currentStateData['step4FileUploadAwards2']
                    ? currentStateData['step4FileUploadAwards2']?.name
                    : 'Academic certificate and link of website'}
                testId={'FreelancerLawer-step-4-upload-5'}
                uploadIcon={uploadIcon2}
                onChange={this.handleFileUpload}
                stateKeyRef={'step4FileUploadAwards2'}
                backgroundColor= {'#FFF7E9'}
                border={'1px dashed #979797'}
              />
              {!this.state.step4FileUploadAwards2 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUploadAwards2']}</Typography>}
              <FileUploadArea
                id="FreelancerLawer-step-4-upload-7"
                btnContent={
                  currentStateData['step4FileUploadAwards3']
                    ? currentStateData['step4FileUploadAwards3']?.name
                    : 'Industry certificate and link of website'}
                testId={'FreelancerLawer-step-4-upload-7'}
                uploadIcon={uploadIcon2}
                onChange={this.handleFileUpload}
                stateKeyRef={'step4FileUploadAwards3'}
                backgroundColor= {'#FFF7E9'}
                border={'1px dashed #979797'}
              />
              {!this.state.step4FileUploadAwards3 && <Typography style={{ color: 'red' }}>{this.state.error['step4FileUploadAwards3']}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      default:
        return <Box>{'Unkown Step'}</Box>;
    }
  };

  displayTime() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        {this.state.emailValid && <Typography style={{ color: 'red' }}>{this.state.emailValid}</Typography>}

        {!this.state.verfication && !this.state.successPage &&
          <Box padding={'3%'}>
            <Box style={webStyle.outerContainer}>
              <img
                src={Horizontalinventohub}
                style={webStyle.invento as React.CSSProperties}
                onClick={this.handleLogoClick} data-test-id="handleLogoClick"
              />

              <Box style={webStyle.root}>
                {/* stepper component starts */}
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel StepIconComponent={StepLabelIcon} />
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </Box>
            {this.getStepComponent()}
            {this.state.showPageError &&
              <Box mt={4}>
                <Alert severity="error">
                  Cannot proceed to the next step due to validation errors.!
                </Alert>
              </Box>
            }
            <Box
              justifyContent={'space-between'}
              display={'flex'}
              marginTop={'60px'}
            >
              <Button
                color="default"
                data-testid="LYR-back"
                onClick={() =>
                  this.state.activeStep === 0
                    ? this.handleredirection(
                      'ProfessionalAccountRegistration'
                    )
                    : this.handleBack()
                }
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F2A630',
                  color: '#FFF'
                }}
                disabled={this.state.disableBtn}
                onClick={() => this.handleNext()}
                data-testid="LYR-next"
              >
                Next
              </Button>
            </Box>
          </Box>}
        {this.state.verfication === true && !this.state.successPage &&
          <>
            <Grid container spacing={0}>
              <Grid container item xs={12} md={5} justifyContent="center">
                <Grid item xs={9}>
                  <img src={Horizontalinventohub} style={webStyle.invento} />
                  <div style={webStyle.verifyStyle}>
                    <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
                    <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
                    <ReactCodeInput
                      {...props}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue} name={"VerifyOtp"} inputMode={"tel"} />
                    <Box component={"div"} style={{ marginTop: '16px' }}>
                      <FormControl style={{ width: '98%', display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          disabled={this.state.VerificationError}
                          onClick={this.handleVerifybox}
                        >
                          VERIFY
                        </Button>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          onClick={this.handleResendOtp}
                          disabled={this.state.disabledResendOtp}
                          data-testid="resend_emailotp"
                        >
                          Resend OTP

                        </Button>
                      </FormControl>
                    </Box>
                    {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
                    {this.state.VerificationError && <Typography style={webStyle.timer}> Please Enter OTP</Typography>}
                    <Box component={"div"} margin={"30px 0"}>
                      <span style={webStyle.haveAccount}>Have an account? </span>
                      <span style={webStyle.signIn} onClick={this.handleSignIn}>Sign in</span>
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <img src={login} style={webStyle.image} alt="peopleImg" />
              </Grid>
            </Grid>
          </>
        }{this.state.successPage === true && <>
          <Grid container spacing={0} style={{ display: "block" }}>
            <Grid container item xs={12} md={5} justifyContent="center">
              <Grid item xs={9}>
                <img src={Horizontalinventohub} style={webStyle.invento} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={webStyle.successImage}>
              <img src={successImage} alt="successImage" />
            </Grid>
            <Grid item>
              <Typography style={webStyle.suceesText}>Congratulations! {this.state.profileName} your profile has been created</Typography>
            </Grid>
            <Grid container style={webStyle.backNextBtn}>
              <Grid item>
                <Button
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" style={{ padding: "10px 40px", fontSize: " 18px" }} onClick={this.handleSignIn}>
                  NEXT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '40%',
    flex:"0.5"
  },
  suceesText: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "40px",
    textAlign: "center" as "center"
  },
  verifyStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginTop: '200px',
    "@media (max-width: 768px)": {
      marginTop: 0
    }
  },
  nxtBtn: {
    padding: "12px", borderRadius: "8px"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom:"40px",
    gap:"14rem"
  },
  otpTime: {
    marginBottom: "20px",
    color: "#6F6E6E",
    fontWeight: 600,
    fontSize: " 18px",
  },
  otpStyle: {
    margin: "76px 0px",
    fontSize: "32px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "'Inter', sans-serif"
  },
  successImage: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  haveAccount: {
    color: "#6F6E6E",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px"
  },
  signIn: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "40px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  timer: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "red",
    marginTop: "10px"
  },
  invento: {
    width: "250px",
    height: "61px",
    objectFit: "cover" as const,
    marginTop: "25px",
  },
  backNextBtn: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
    marginTop: "84px",
    "@media (min-width: 425px) and (max-width:759px)": {
      marginTop: "20px",
      position: "unset"
    }
  },
  image: {
    width: "100%",
    height: "auto",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    },
    width: '64%'
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '5px',
    marginTop: '23px'
  },
  RadioGroupBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  UploadButton: {
    height: '100%',
    width: '100%',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#FFF7E9',
    border: '1px dashed #979797',
    borderRadius: '12px'
  },
  SelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: '#21212B',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  UnSelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: 'transparent',
    color: '#626262',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  }
};
// Customizable Area End
