import React from "react";
// Customizable Area Start
import { createStyles, createTheme, styled, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Chip, Grid, InputAdornment, LinearProgress,
MenuItem, TextField, Typography, Avatar, Select, FormControl, Dialog, DialogActions, DialogContent, DialogContentText, 
DialogTitle, Paper, Menu } from "@material-ui/core";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ClientNavBar from "../../dashboard/src/Components/ClientNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from "@material-ui/lab/Pagination";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CancelIcon from '@material-ui/icons/Cancel';


const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "#696A6A",
        }
    },
    typography: {
        fontFamily: 'Inter,Rubik',
    }
});
const CustomCancelButton = styled(CancelIcon)({
    color: "#206FC4",
    cursor:"pointer"
  });

  const CustomSelect = styled(Select)({
    width: "600px",
    height: "50px",
    borderRadius:"10px",
   });
const CustomFormControl = styled(FormControl)({
      '& .MuiList-root':{
          height: "100px",
        }
  })
  const AutoCompleteSvgIcon = (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
        id="Path"
        d="M14.56 0.430739C14.42 0.294665 14.26 0.186001 14.07 0.11258C13.89 0.0381791 13.7 0 13.5 0C13.3 0 13.11 0.0381791 12.93 0.11258C12.74 0.186001 12.58 0.294665 12.44 0.430739L7.85 4.91924C7.76 5.01126 7.63 5.06216 7.5 5.06216C7.37 5.06216 7.24 5.01126 7.15 4.91924L2.56 0.430739C2.28 0.155653 1.9 0 1.5 0C1.1 0 0.72 0.154675 0.44 0.42976C0.16 0.705825 0 1.07881 0 1.46843C0 1.85805 0.16 2.23201 0.44 2.5071L5.03 6.99657C5.35 7.31473 5.74 7.5673 6.16 7.7396C6.59 7.91189 7.04 8 7.5 8C7.96 8 8.42 7.91189 8.84 7.7396C9.26 7.5673 9.65 7.31473 9.98 6.99657L14.56 2.5071C14.84 2.23201 15 1.85805 15 1.46843C15 1.07978 14.84 0.705825 14.56 0.430739Z"
        fill="white"
      />
    </svg>
  );
// Customizable Area End
import SearchTalentController, {
    Props,
    configJSON
} from "./SearchTalentController";

export default class SearchTalent extends SearchTalentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <ClientNavBar />
                <ThemeProvider theme={theme}>
                    <Grid container justifyContent="space-between" style={webStyles.outerContainer}>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                        <Typography style={webStyles.searchText}>{configJSON.search}</Typography>
                                        <Box style={webStyles.searchContainer}>
                                            <TextField
                                                id="search"
                                                variant="outlined"
                                                data-testid="search"
                                                size="small"
                                                fullWidth
                                                value={this.state.searchedValue}
                                                onChange={this.handleSearch}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon color="disabled" />
                                                        </InputAdornment>
                                                    ),
                                                    style: { borderRadius: '13px' }
                                                }}
                                            />
                                             <Box
                display={'grid'}
                gridTemplateColumns={'1fr 0.2fr'}
                gridAutoFlow={'column'}
                gridColumnGap={'1.5rem'}
              >
        <Button
          aria-controls="customized-menu"
          data-testid="Most Recent"
          variant="contained"
          aria-haspopup="true"
          endIcon={AutoCompleteSvgIcon}
          color="primary"
          onClick={this.handleMostRecentClick}
          style={{
              textTransform: 'none',
              width: '200px',
              borderRadius: '13px',
              color: '#FFF',
              backgroundColor: '#2275CD',
          }}
        >
          {this.state.selectedOption}
        </Button>
        <Paper >
        <Menu
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          id="customized-menu"
        >
          {['Most Recent','Older'].filter((option:string) => option != this.state.selectedOption).map((item:string) => 
             <MenuItem onClick={() => this.handleMostRecentCloseMenu(item)} style={{minWidth: 200}}>{item}</MenuItem>
            )}        
        </Menu>
        </Paper>
        </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2} style={webStyles.chipOuterContainer} >
                                            {this.state.minRange &&
                                                <Grid item xs={4} sm={4} md={3} lg={4}>
                                                    <Box style={webStyles.searchedItem}>
                                                        <Typography style={webStyles.searchedText}>{this.state.minRange} {configJSON.to} {this.state.maxRange} {configJSON.proposal}</Typography>
                                                        <CloseIcon style={webStyles.closeIcon} fontSize="small" onClick={this.handleClearProposalFilter} data-testid="clear-proposal-filter"/>
                                                    </Box>
                                                </Grid>
                                            }
                                            {this.state.minHours &&
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <Box style={webStyles.searchedItem}>
                                                        <Typography style={webStyles.searchedText}>{this.state.minHours} {configJSON.to} {this.state.maxHours} {configJSON.hourlyRate}</Typography>
                                                        <CloseIcon data-testid="clear-hour-filter" style={webStyles.closeIcon} fontSize="small" onClick={this.handleClearHoursFilter}/>
                                                    </Box>
                                                </Grid>
                                            }
                                            {(this.state.minHours || this.state.minRange) &&
                                                <Grid item xs={4} sm={3} md={3} lg={3}>
                                                    <Typography data-testid="clear-all-filter" style={webStyles.clearFilter} color="secondary" onClick={this.handleClearAllFilter}>{configJSON.clearFilter}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                    {this.state.totalFreelancer.map(data =>
                                        <Box style={webStyles.boxContainer} key={data.id}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12} md={12} lg={2} style={webStyles.profileContainer}>
                                                            <Avatar src={data.attributes.profile_image} style={webStyles.userProfileImg} />
                                                            {data.attributes.freelancers.user_status &&
                                                                <FiberManualRecordIcon style={webStyles.onlineIcon} />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={5}>
                                                            <Box>
                                                                <Typography style={webStyles.userName}>{data.attributes.freelancers.full_name}</Typography>
                                                                <Typography style={webStyles.subText} color="secondary">{data.attributes.freelancers.job_title}</Typography>
                                                                <Box style={webStyles.locationContainer}>
                                                                    <LocationOnRoundedIcon fontSize="small" style={webStyles.locationIcon} />
                                                                    <Typography style={webStyles.subText} color="secondary">{data.attributes.freelancers.country}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={5}>
                                                            {data.attributes.freelancers.user_status ?
                                                                <Chip label={configJSON.availableStatus} style={webStyles.chipContainer} /> :
                                                                <Chip label={configJSON.notAvailable} style={webStyles.chipNotAvailable} />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                                    <Box style={webStyles.menuBox}>
                                                        <Box style={webStyles.countBox}>
                                                            <Typography style={webStyles.dayText}>{this.state.perDayRate}<span style={webStyles.spanText}>{configJSON.day}</span></Typography>
                                                            <Typography style={webStyles.dayText}>{this.state.earnedPoint}<span style={webStyles.spanText}>{configJSON.earned}</span></Typography>
                                                        </Box>
                                                        <Box style={webStyles.progressContainer}>
                                                            <Typography style={webStyles.dayText}>{this.state.progressValue}<span style={webStyles.spanText}>{configJSON.jobSuccess}</span></Typography>
                                                            <BorderLinearProgress variant="determinate" data-testid={"linearprogress-" + data.id} value={this.state.progressValue} />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box style={webStyles.summeryBox}>
                                            <Typography color="secondary" style={webStyles.profileSummery}>{this.state.profileSummery}</Typography>
                                            <Typography color="secondary" style={webStyles.profileSummery}>{this.state.profileDetail}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography style={webStyles.websiteHead}>{configJSON.websiteDevelop}</Typography>
                                                <Box style={webStyles.languageBox}>
                                                    {this.state.languages.map((language, index) =>
                                                        <Typography color="primary" style={webStyles.language} key={index}>{language}</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography style={webStyles.websiteHead}>{configJSON.websiteSkills}</Typography>
                                                <Box style={webStyles.languageBox}>
                                                    {data.attributes.freelancers.skills.slice(0,3).map((skill, index) =>
                                                        <Typography color="primary" style={webStyles.language} key={index}>{skill}</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box style={webStyles.linkDisplay}>
                                                {data.attributes.freelancers.skills.length > 3 && 
                                                     <Button href="#" data-testid="showMore" style={webStyles.linkText} onClick={this.handleShowMoreSkills}>
                                                     {configJSON.fourMore}
                                                 </Button>
                                                
                                                }
                                                 {this.state.showMore && 
                                                 <>
                                                 {data.attributes.freelancers.skills.slice(3,data.attributes.freelancers.skills.length).map((skill, index) =>
                                                         <Typography color="primary" style={webStyles.language} key={index}>{skill}</Typography>
                                                     )}
                                                 </>
                                                 }
                                            </Box>
                                            <Box style={webStyles.buttonContainer}>
                                                    <Button variant="outlined" style={webStyles.buttonOutlined} data-testid={"save-" + data.id} onClick={() => this.addTalentInSave(data.id)} startIcon={data.attributes.freelancers.talent_saved ? <FavoriteIcon/> :<FavoriteBorderOutlinedIcon color="primary" />}>
                                                        {data.attributes.freelancers.talent_saved ? configJSON.removeFromSave : configJSON.saveLater}
                                                    </Button>
                                                    <Button variant="contained" style={webStyles.buttonContained} data-testid={"inviteBtn-" + data.id} onClick={() => this.handleInviteToJob(data.id)}>
                                                    {configJSON.inviteToJob}
                                                    </Button>

                                                </Box>
                                        </Box>
                                    )}
                                    {this.state.totalFreelancer.length > 0 ?
                                        <Box style={webStyles.paginationContainer}>
                                             <Pagination 
                                              count={this.state.totalPages} 
                                              page={this.state.currentPage} 
                                              onChange={this.handlePageChange}
                                              />
                                        </Box> :
                                        <Typography style={webStyles.noProfile}>{configJSON.noProfile}</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container spacing={3} style={webStyles.filterOuterContainer}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.filterBox}>
                                        <Typography color="secondary" style={webStyles.filterText}>{configJSON.filterBy}</Typography>
                                        <Typography color="secondary" style={webStyles.clearText}>{configJSON.clearAll}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.experienceLevel}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                    {
                                                        this.state.experienceList.map(experience =>
                                                            <Box key={experience.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(experience.value)}
                                                                        onChange={() => this.handleCheckboxChange(experience.value)}
                                                                        data-testid={"experienceCheck-" + experience.id}
                                                                        value={experience.value}
                                                                        color="primary"
                                                                    />
                                                                    <Typography color="secondary">{experience.label}</Typography>
                                                                </Box>
                                                                <Typography color="secondary">{experience.count}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.noOfProposals}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                    {
                                                        this.state.proposalList.map(proposal =>
                                                            <Box key={proposal.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.proposalSelected.includes(proposal.value)}
                                                                        onChange={() => this.handleProposalCheckbox(proposal.value)}
                                                                        value={proposal.value}
                                                                        color="primary"
                                                                        data-testid={"proposalCheck-" + proposal.id}
                                                                    />
                                                                    <Typography color="secondary">{proposal.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.clearInfo}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                    {
                                                        this.state.clearInfoList.map(info =>
                                                            <Box key={info.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography color="secondary">{info.label}</Typography>
                                                                </Box>
                                                                <Typography color="secondary">{info.count}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
                <FooterWeb />
                <div>  
    <Dialog
      open={this.state.inviteNotification}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Invite to a job</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
       {this.state.invitationSent||this.state.invitationUnsent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  data-testid='handleCloseDialog' onClick={()=>this.handleCloseDialog()} color="primary" autoFocus>
         Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>

                {this.state.isModalOpen && (
  <Modal
    open={this.state.isModalOpen}
    data-test-id="handleCloseModal"
    onClose={this.handleCloseModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={this.state.isModalOpen}>
      <div style={webStyles.modalStyles}>
        <div style={webStyles.inviteBox}>
        <Typography style={webStyles.inviteText}>Invite to job</Typography>
        <CustomCancelButton fontSize="medium" onClick={this.handleCloseModal} />
        </div>
        <div  style={webStyles.introBox}>
        <Avatar src={this.state.clientProfileData.data?.attributes?.profile_image} style={webStyles.imageStyle}/>
        <div style={webStyles.introBox2}> 
        <Typography style={webStyles.introHeading}>{this.state.clientProfileData.data?.attributes?.full_name}</Typography>
        <Typography style={webStyles.introHeading}>{this.state.clientProfileData.data?.attributes?.job_title}</Typography>
        </div>
        </div>
        <div style={{marginTop:"12px"}}>
        <Typography style={webStyles.MessageHead}>Message</Typography>
        <Box style={webStyles.textArea}>
        <Typography style={{...webStyles.Message, marginBottom:"8px"}}>Hello!</Typography>
        <Typography style={{...webStyles.Message, marginBottom:"10px"}}>I’d like to invite you to take a look at the job I’ve posted. Please submit a proposal if you’re available and interested.</Typography>
        <Typography style={webStyles.Message}>{this.state.accountHolderName}.</Typography>
        </Box>
        </div>

        <div>
        <Typography style={{...webStyles.MessageHead,marginTop:"12px"}}>Choose a job</Typography>
        <CustomFormControl variant="outlined" >
        <CustomSelect
          data-test-id="handleJobChange"
          labelId="label"
          id="select"
          value={this.state.selectedJob}
          onChange={this.handleJobChange}
          MenuProps={{
          PaperProps: {
          style: { 
          maxHeight: "80px", 
          overflow: 'auto' ,
          overflowX:"hidden"
          }
         },
         anchorOrigin: {
         vertical: "bottom",
         horizontal: "left"
         },
        transformOrigin: {
        vertical: "top",
        horizontal: "left"
        },
        getContentAnchorEl: null
        }}
        >
                    {this.state.jobListData.map((item) => (
                      <MenuItem key={item.attributes.name} value={item.attributes.id}>
                        {item.attributes.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
        </CustomFormControl>
        {this.state.errorMessage && (
                  <Typography style={{color: 'red', marginTop: '6px',fontSize:"13px"}}>
                    {this.state.errorMessage}
                  </Typography>
                )}
        </div>
        <div style={{display:"flex",justifyContent:"end",marginTop:"20px"}}>
        <Button onClick={this.handleSendInvitation} data-test-id="handleSendInvitation" style={webStyles.btnStyle}>Send Invitation</Button>
        </div>
       
      </div>
    </Fade>
  </Modal>

)}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    outerContainer: {
        padding: '3% 5%'
    },
    outlinedInput: {
        borderRadius: '10px',
        '&::placeholder': {
          color: '#000000',
          fontSize: '18px',
          lineHeight: '30px'
        }
      },
    searchText: {
        fontSize: '2rem',
        fontWeight: 500,
        fontFamily:'Rubik'
    },
    btnStyle:{
        width: "239px",
        background: "#2378D1",
        color: "white",
        padding: "10px",
        borderRadius: "10px",
        textTransform: 'capitalize' as 'capitalize'
    },
    imageStyle:{
      width:"60px",
      height:"60px"
    },
    introBox:{
        display: "flex",
        gap: "10px",
        margin:"14px"
    },
    introBox2:{
        display: "flex",
        flexDirection: "column" as "column",
        gap: "6px"
    },
    MessageHead:{
        fontSize: "18px",
        color: "#2B2B2B",
        fontWeight: 600,
        fontFamily:"Inter",
        marginBottom:"12px"
    },
    Message:{
        fontSize: "14px",
        color: "#2B2B2B",
        fontFamily:"Inter"
    },
    introHeading:{
        fontSize: "15px",
        color: "#2B2B2B",
        fontWeight: 500,
        fontFamily:"Inter"
    },
    textArea:{
        border: "1px solid #979797",
        borderRadius: "10px",
        padding: "20px"
    },
     modalStyles : {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 500,
        backgroundColor: 'white',
        boxShadow: "24",
        padding: '26px 36px',
        borderRadius:"30px"
      },
      inviteBox:{
        display:"flex",
        justifyContent : "space-between"
      },
      inviteText:{
        fontSize: "28px",
        fontWeight: 600 ,
        fontFamily: "inter",
        color: "#2B2B2B"
      },
    searchContainer: {
        display: 'flex',
        gap: '10px',
        margin: '10px 0'
    },
    recentField: {
        width: '40%',
    },
    searchedItem: {
        display: 'flex',
        background: '#206FC4',
        borderRadius: '20px',
        padding: '5px 0',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '2%'
    },
    searchedText: {
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 500
    },
    closeIcon: {
        color: '#fff',
        cursor: 'pointer'
    },
    clearFilter: {
        textDecoration: 'underline',
        fontSize: '0.9rem',
        fontWeight: 300,
        cursor: 'pointer'
    },
    chipContainer: {
        color: '#fff',
        background: '#66AC3D',
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    chipNotAvailable: {
        color: '#fff',
        background: '#C0C0C0',
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    profileContainer: {
        position: 'relative' as 'relative'
    },
    userProfileImg: {
        width: '50px',
        height: '50px',
    },
    onlineIcon: {
        position: 'absolute' as 'absolute',
        color: 'green',
        fontSize: '12px',
        left: '35px',
        top: '37px'
    },
    locationContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    boxContainer: {
        marginTop: '2%',
        background: '#F5F7F9',
        borderRadius: '15px',
        padding: '15px'
    },
    userName: {
        fontSize: '1rem',
        fontWeight: 600
    },
    subText: {
        fontSize: '0.9rem'
    },
    menuBox:{
    marginLeft:'30%'
    },
    countBox: {
        display: 'flex',
        gap:'20px',
        "@media(max-width:964px)": {
            gap:'5px',
        },
    },
    dayText: {
        fontSize: '12px',
        fontWeight: 600,
    },
    spanText: {
        fontSize: '12px',
        fontWeight: 400
    },
    progressContainer: {
        margin: '4% 0 0 4%'
    },
    locationIcon: {
        width: '16px',
        height: '15px'
    },
    profileSummery: {
        fontSize: '0.9rem'
    },
    summeryBox: {
        margin: '3% 0'
    },
    languageBox: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        gap: '5px',
        margin: '1% 0 2%'
    },
    language: {
        background: '#E1ECFB',
        borderRadius: '19px',
        textAlign: 'center' as 'center',
        width: 'max-content',
        padding: '5px 17px'
    },
    websiteHead: {
        fontSize: '1rem',
        fontWeight: 500
    },
    buttonContainer: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'end'
    },
    linkDisplay: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonContained: {
        background: '#206FC4',
        color: '#fff'
    },
    buttonOutlined: {
        borderColor: '#206FC4',
        color: '#206FC4'
    },
    linkText: {
        fontFamily: 'Inter',
        textDecoration: 'underline'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
       marginTop:"14px"
    },
    filterText: {
        fontSize: '1.1rem',
        fontWeight: 500
    },
    clearText: {
        fontSize: '1.1rem',
        fontWeight: 400
    },
    filterBox: {
        display: 'flex',
        background: '#F5F7F9',
        borderRadius: '15px',
        padding: '15px',
        justifyContent: 'space-between'
    },
    accordinBox: {
        background: '#F5F7F9',
        borderRadius: '15px',
    },
    filterOuterContainer: {
        marginTop: '8%'
    },
    accordion: {
        background: 'none',
        boxShadow: 'none'
    },
    checkBoxOuter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    checkBoxInner: {
        display: 'flex',
        alignItems: 'center'
    },
    accordDetail: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'baseline',
        width: '100%'
    },
    disableBtn: {
        fontSize: '1rem',
        fontWeight: 600,
        marginRight: '5px',
        cursor: 'not-allowed',
        fontFamily: 'Inter',
        color: 'rgb(96, 96, 96)'
    },
    enableBtn: {
        fontSize: '1rem',
        fontWeight: 600,
        marginRight: '5px',
        cursor: 'pointer',
        fontFamily: 'Inter',
        color: '#225DD1'
    },
    selectedPageNumber: {
        background: '#225DD1',
        borderRadius: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        padding: '5px 9px',
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 600
    },
    pageButton: {
        background: '#E4EBF4',
        borderRadius: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        padding: '5px 9px',
        color: '#000',
        fontSize: '0.8rem',
        fontWeight: 600
    },
    chipOuterContainer: {
        width: '100%'
    },
    noProfile:{
        fontSize:'1.5rem',
        paddingTop:'5%',
        textAlign:'center' as 'center',
        color:'grey'
    }
};

export const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
            width: 150,
            marginTop: '2%'
        },
        colorPrimary: {
            backgroundColor: '#DAE9D5'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#66AC3D"
        },

    })
)(LinearProgress);
// Customizable Area End
