import React from 'react';
// Customizable Area Start
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { Props, webConfigJSON } from './ViewJobController.web';
import TalentController from './TalentController.web';
import OnlineAvatar from './OnlineAvatar';
import RoomIcon from '@material-ui/icons/Room';
import FooterWeb from './Components/Footer.web';
import ClientNavBar from './Components/ClientNavBar.web';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// Customizable Area End

export default class Talent extends TalentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    let webStyles = webConfigJSON.cardStyles;
    const createCards = (data: any[]) => {
      return data.map((item: any, index: any) => {
        return (
          <Box
            sx={{ display: 'grid' }}
            style={webStyles.cardContainer}
            key={index}
            className="card"
          >
            {/* {left side} */}
            <div style={webStyles.FlexContainer}>
              <div style={webStyles.profileDetails}>
                <OnlineAvatar online={true} src={item.attributes?.talents_attributes.profile_image.url} alt="Avatar" />

                <div>
                  <Typography style={webStyles.titleText}>
                    {item.attributes?.talents_attributes.user_name}
                  </Typography>
                  <Typography
                    style={webStyles.jobTitleText}
                    className="jobTitleText"
                  >
                    {item.attributes?.talents_attributes.designation}
                  </Typography>
                  <Typography style={webStyles.location} className="location">
                    <RoomIcon style={{ fill: '#3CC261' }} /> {item.attributes?.talents_attributes.location}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography style={webStyles.relevantSkills}>
                  {`Has ${item.attributes?.talents_attributes.skills.length ||
                    0} relevant skills to your job`}
                </Typography>
                <Box style={webStyles.skills}>
                  {item.attributes?.talents_attributes.skills.map((skill: any, index: number) => {
                    return (
                      <Button
                        key={index}
                        style={{ padding: '4px 8px', background: '#166489', color: '#FFFFFF', width: 'fit-content' }}
                      >
                        {skill}
                      </Button>
                    );
                  })}
                </Box>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', minWidth: '220px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                  <Typography style={webStyles.amount}>${item.attributes?.talents_attributes.hour_rate || 0}<span style={{ fontSize: "12px" }}>/hr</span></Typography>
                  <Typography style={webStyles.hourlyBasis}>
                    Hourly Price
                  </Typography>
                </div>
                {this.state.isSavedTalent && (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ maxWidth: '220px', width: '100%' }}
                      data-testid={'HIRE_CANDIDATE'}
                    >
                      {'Hire'}
                    </Button>
                  </div>
                )}
              </div>
              <Box
                borderRadius={'50%'}
                bgcolor={'#fff'}
                padding={'5px'}
                marginTop={'-15px'}
                boxShadow={'0px 2px 11px 0px rgba(162, 188, 193, 0.24)'}
                style={{ cursor: 'pointer' }}
              >
                <FavoriteIcon
                  style={{ color: '#206FC4' }}
                  data-testid={`favorite-${item.id}`}
                  onClick={() => { this.removeSavedTalent(item.id); }}
                />
              </Box>
            </Box >
          </Box>
        );
      });
    };
    return (
      //Merge Engine DefaultContainerj
      <>
        <ClientNavBar />
        <Box padding={'1rem  5rem 1rem 3rem'} className="cards-container">

          <Box style={{ display: 'flex', alignItems: 'center', margin: '40px 0px' }}>
            <Typography
              style={{
                fontSize: '24px',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                fontFamily: 'Inter'
              }}
            >
              Talent
            </Typography>
            <ChevronRightIcon />
            <Typography
              style={{
                fontSize: '24px',
                fontWeight: 500,
                color: '#206FC4',
                whiteSpace: 'nowrap',
                fontFamily: 'Inter'
              }}
            >
              Saved Talent
            </Typography>
          </Box>
          {createCards(
            this.state.isSavedTalent
              ? this.state.savedTalentData
              : this.state.yourHiresData
          )}
        </Box>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
