import React from "react";
// Customizable Area Start
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, MenuItem, Modal, TextField, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { avtara, editIcon } from './assets';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import ProfileController, {
    Props,
    configJSON
} from "./ProfileController";

export default class Profile extends ProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderModal = () => {
        return (
            <ThemeProvider theme={theme}>
                <Modal
                    open={this.state.editModalOpen}
                    onClose={this.handleModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={webStyles.modalContainer}
                    data-testid="edit-modal"
                >
                    <Box style={webStyles.modalView}>
                        <Grid container>
                            <Grid item sm={12} md={12}>
                                <Box style={webStyles.editContainer}>
                                    <Typography style={webStyles.editText}>{configJSON.editDetails}</Typography>
                                    <IconButton aria-label="close" onClick={this.handleModal} >
                                        <CancelIcon fontSize="small" color="primary" />
                                    </IconButton>

                                </Box>
                                <hr style={webStyles.Hr} />
                                <Box style={webStyles.modalField}>
                                    <Grid container>
                                        <Grid item sm={12} md={12}>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.name}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    name="user_name"
                                                    data-testid="name-field"
                                                    placeholder="enter name"
                                                    size="small"
                                                    error={!this.nameReg.test(this.state.userDetails.user_name)}
                                                    helperText={!this.nameReg.test(this.state.userDetails.user_name) && configJSON.nameFailed}
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    fullWidth
                                                    inputProps={{ maxLength: 36 }}
                                                    value={this.state.userDetails.user_name = ""? this.state.accountHolderName : this.state.userDetails.user_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.email}</Typography>
                                                <TextField
                                                    type="email"
                                                    name="email"
                                                    data-testid="email-field"
                                                    value={this.state.userDetails.email}
                                                    variant="outlined"
                                                    placeholder="enter email address"
                                                    size="small"
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    fullWidth
                                                    onChange={this.handleChange}
                                                    error={!this.emailReg.test(this.state.userDetails.email)}
                                                    helperText={!this.emailReg.test(this.state.userDetails.email) && 'Invalid Email'}
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.address}</Typography>
                                                <TextField
                                                    name="address"
                                                    value={this.state.userDetails.address}
                                                    data-testid="address-field"
                                                    variant="outlined"
                                                    placeholder="enter address"
                                                    size="small"
                                                    maxRows={4}
                                                    onChange={this.handleChange}
                                                    inputProps={{ maxLength: 300 }}
                                                    fullWidth
                                                    error={!this.addessReg.test(this.state.userDetails.address)}
                                                    helperText={!this.addessReg.test(this.state.userDetails.address) && 'Invalid address'}
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C'} }}
                                                />
                                            </Box>
                                            <Box style={webStyles.countryPhone}>
                                                <Box style={webStyles.fieldBox}>
                                                    <Typography style={webStyles.label}>{configJSON.country}</Typography>
                                                    <TextField
                                                        name="country"
                                                        data-testid="country-code"
                                                        value={this.state.userDetails.country}
                                                        variant="outlined"
                                                        select
                                                        size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        style={webStyles.countryField}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    >
                                                        {this.allCountries.map((country) =>
                                                            <MenuItem value={country.name} key={country.name}>{country.name}(+{country.phone})</MenuItem>
                                                        )}
                                                    </TextField>
                                                </Box>
                                                <Box style={webStyles.fieldBox}>
                                                    <Typography style={webStyles.label}>{configJSON.phone}</Typography>
                                                    <TextField
                                                        name="full_phone_number"
                                                        onKeyDown={this.preventETypeNumber}
                                                        data-testid="phone-number"
                                                        value={this.state.userDetails.full_phone_number}
                                                        variant="outlined"
                                                        placeholder="Phone Number"
                                                        size="small"
                                                        fullWidth
                                                        inputProps={{ maxLength: 15 }}
                                                        onChange={this.handleChange}
                                                        error={!this.phoneReg.test(this.state.userDetails.full_phone_number)}
                                                        helperText={!this.phoneReg.test(this.state.userDetails.full_phone_number) && 'Invalid Phone Number'}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                    
                                                </Box>
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.aboutMe}</Typography>
                                                <TextField
                                                    name="about_me"
                                                    data-testid="about-me"
                                                    value={this.state.userDetails.about_me}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    multiline
                                                    minRows={4}
                                                    inputProps={{ maxLength: 150 }}
                                                    onChange={this.handleChange}
                                                />
                                            </Box>
                                            <Box style={webStyles.btnSaveBox}>
                                                <Button color="primary" variant="contained" onClick={this.handleUpdate} data-testid="update-btn">{configJSON.save}</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </ThemeProvider>
        )
    }

    renderResponseDialog = () => {
        return (
            <Dialog
                open={this.state.popUpMessage}
                onClose={this.handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={webStyles.dialogOuterContainer}
                data-testid="response-modal"
            >
                <DialogContent>
                    {this.state.responseMessage ?
                        <DialogContentText id="alert-dialog-description" style={webStyles.successMessage}>
                            {configJSON.successMessage}
                        </DialogContentText> :
                        <DialogContentText id="alert-dialog-description2" style={webStyles.failedMessage}>
                            {configJSON.failedMessage}
                        </DialogContentText>
                    }
                    <DialogActions style={webStyles.btnOuterContainer}>
                        <Button onClick={this.handleDialog} data-testid="ok-btn" color="primary" variant="contained">
                            {configJSON.okBtnText}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Box>
                    <Grid container>
                        <Grid item sm={12} md={12}>
                           <Box display={"flex"} justifyContent={"space-between"}><Typography variant="h2" style={webStyles.profileText}>{configJSON.clientProfile}</Typography>
                            <Box style={{...webStyles.editBox,marginTop:"-2%"}}>
                                    <Typography style={webStyles.editText}>{configJSON.edit}</Typography>
                                    <IconButton aria-label="edit" onClick={()=>this.handleRedirection("EditClientProfile")} data-testid="edit">
                                        <img src={editIcon} alt="edit" />
                                    </IconButton>
                                </Box>
                                </Box> 
                            <Box style={webStyles.profileBox}>
                                <Box style={webStyles.nameInfo}>
                                    <Box style={webStyles.profileContainer}>
                                        <img src={avtara} alt="avtara" style={webStyles.avtar} />
                                        <FiberManualRecordIcon data-testid="user-status" style={{ ...webStyles.dotIcon, color: this.state.userDetails?.user_status ? 'green' : 'grey' }} />
                                    </Box>
                                    <Box>
                                        <Typography style={webStyles.userName}>{this.state?.userData1 && this.state?.userData1?.full_name}</Typography>
                                        <Box style={webStyles.locationBox}>
                                            <LocationOnRoundedIcon fontSize="small" />
                                            <Typography>{this.state?.userData1 && this.state?.userData1?.country}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    {this.state.userDetails.user_status ?
                                        <Typography style={webStyles.onlineStatusIcon}>{configJSON.onlineStatus}</Typography>
                                        :
                                        <Typography style={webStyles.offlineStatusIcon}>{configJSON.offlineStatus}</Typography>

                                    }
                                </Box>
                            </Box>
                            
                            <Box style={webStyles.detalisBox}>
                                <Typography style={webStyles.detailText}>{configJSON.details}</Typography>
                              
                            </Box>
                            <Box style={webStyles.aboutBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.aboutMe}</Typography>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{this.state.userData1?.about_me}</Typography>
                            </Box>
                            <Box style={webStyles.aboutBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.contactInfo}</Typography>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.userId}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.userData1?.id}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.name}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.userData1?.user_name}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.email}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.userData1?.email}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" 
                                    style={webStyles.infoTextHead}
                                    >{configJSON.address}</Typography>
                                    <AddressContainer >{this.state.userData1?.address}</AddressContainer>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.phone}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.userData1?.full_phone_number}</Typography>
                                </Box>
                             
                            </Box>
                            <Box style={webStyles.aboutBox}>
                            <Box style={{...webStyles.infoBox,display:"grid"}}>
                                <Typography style={{...webStyles.aboutMeHead,fontFamily: "Rubik",fontWeight: 700,fontSize:"1.12rem"}}>Additional Account</Typography>
                                <Typography color="secondary" style={webStyles.month}>Creating a new account allow you to use invento hub in a different way, while still having just one login.</Typography>
                                <Typography style={{...webStyles.aboutMeHead,marginTop:"2%",fontFamily: "Rubik",fontWeight: 400}}>Freelancer Account</Typography>
                                <Typography color="secondary" style={webStyles.month}>Create a freelancer account to access different projects and boost your career opportunities.</Typography>
                                <Box>
                                <Button variant="outlined" color="primary"style={{ borderRadius:"8px",textTransform:"capitalize",width:"fitContent",marginTop:"4%",fontFamily:"Rubik"}}>
                                 Create Freelancer Account
                                </Button>
                                </Box>
                            </Box>
                        </Box>
                        </Grid>
                    </Grid>
                </Box>
                {this.renderResponseDialog()}
                {this.renderModal()}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const AddressContainer =styled(Typography)({
    width:"85%",
    fontSize: '0.9rem',
    wordBreak:"break-all",
    fontWeight: 600
})
const webStyles = {
    profileBox: {
        background: '#F2F2F2',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 25px'
    },
    avtar: {
        width: '40px',
        height: '40px'
    },
    locationBox: {
        display: 'flex'
    },
    detalisBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '2%',
        alignItems: 'center'
    },
    editBox: {
        display: 'flex',
        alignItems: 'center'
    },
    aboutMeHead: {
        fontSize: '1.1rem',
        fontWeight: 600,
        marginBottom: '1%'
    },
    aboutMeDetail: {
        fontSize: '0.8rem'
    },
    infoBox: {
        display: 'flex',
        marginTop: '1%'
    },
    infoText: {
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    infoTextHead: {
        fontSize: '0.9rem',
        width: '15%'
    },

    infoTextHeadAddress:{
      color:"red",
      width:"85%",
      fontSize: '0.9rem',
      wordBreak:"break-all"
    },
    modalContainer: {
        width: '100px',
        height: '100px',
        margin: 'auto',
        background: '#fff'
    },
    modalView: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: 390,
        background: "#fff",
        borderRadius: '10px',
        overflowY: 'scroll' as 'scroll'
    },
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 4px 0px 15px'
    },
    editText: {
        fontWeight: 600
    },
    Hr: {
        height: '2px',
        background: '#DADADA',
        border: 'none',
        margin: '0'
    },
    modalField: {
        padding: '15px'
    },
    label: {
        fontSize: '0.9rem',
        fontWeight: 600,
        marginBottom: '2%'
    },
    fieldBox: {
        marginBottom: '3%'
    },
    countryPhone: {
        display: 'flex',
        gap: '15px'
    },
    btnSaveBox: {
        textAlign: 'end' as 'end'
    },
    countryField: {
        width: '162px'
    },
    profileText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        marginBottom: '2%'
    },
    nameInfo: {
        display: 'flex',
        gap: '6px',
        alignItems: 'center'
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: 600
    },
    onlineStatusIcon: {
        color: '#fff',
        border: '20px',
        borderRadius: '20px',
        padding: '1px 12px',
        fontWeight: 600,
        fontSize: '0.9rem',
        background: '#66AC3D'
    },
    offlineStatusIcon: {
        color: '#fff',
        border: '20px',
        borderRadius: '20px',
        padding: '1px 12px',
        fontWeight: 600,
        fontSize: '0.9rem',
        background: 'grey'
    },
    detailText: {
        fontSize: '1.2rem',
        fontWeight: 600
    },
    aboutBox: {
        background: '#F2F2F2',
        borderRadius: '20px',
        padding: '15px 25px 30px',
        marginTop: '2%'
    },
    dotIcon: {
        position: 'absolute' as 'absolute',
        fontSize: '12px',
        left: '26px',
        top: '30px'
    },
    profileContainer: {
        position: 'relative' as 'relative'
    },
    successMessage: {
        color: 'green',
    },
    failedMessage: {
        color: 'red'
    },
    btnOuterContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogOuterContainer: {
        boxShadow: 'none'
    },
    experienceBox: {
        padding: '15px 25px 0 25px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    month: {
        fontSize: '0.87rem'
    },
};
// Customizable Area End
