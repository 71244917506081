// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
export const webConfigJSON = require('./config.js');
import { ChangeEvent } from 'react';
import storage from 'framework/src/StorageProvider.web';


export interface Props {
  navigation: any;
  id: string;
}
interface S {
  search: string;
  expanded:string|boolean|null;
  currentPage:number;
  totalPages:number;
  faqListRes:any[];
  userType:string;
  isNoResults:boolean
  message:string ;
  
}
// Customizable Area End
interface SS {
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
export default class FAQController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start
  FAQlistId:string
  getFAQlistId:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.FAQlistId=""
    this.getFAQlistId=""

    this.state = {
      search:"",
      expanded: "",
      currentPage:1,
      totalPages:0,
      faqListRes:[],
      userType:"",
      isNoResults:false,
      message:""
    
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
   
    this.getFAQlist()
    this.setState({
      userType: await storage.get('userType'),
    });
     
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if the state data has been updated
    if ((prevState.search !== this.state.search)||prevState.currentPage !== this.state.currentPage) {
      // State search has been updated, you can perform actions here
     if(this.state.search !== ""){
      this.searchFAQlist();
     }else{
      this.getFAQlist();
     }
    }
  }
  searchFAQlist = async() => {
    const FAQlistDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.validationApiContentType
    };
    

    this.FAQlistId = FAQlistDetailsRequest.messageId;
    FAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.FAQlistDetailsRequestEndPoint +
        `?search=${this.state.search}&page=${this.state.currentPage}&per_page=5`
    );
    FAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    FAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getMethod
    );

    runEngine.sendMessage(
      FAQlistDetailsRequest.id,
      FAQlistDetailsRequest
    );
  };
  getFAQlist = async() => {
    const getFAQlistDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.validationApiContentType
    };
    

    this.getFAQlistId = getFAQlistDetailsRequest.messageId;
    getFAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getFAQlistDetailsRequestEndPoint+
      `?page=${this.state.currentPage}&per_page=5`
    );
    getFAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getFAQlistDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getMethod
    );

    runEngine.sendMessage(
      getFAQlistDetailsRequest.id,
      getFAQlistDetailsRequest
    );
  };

  
  handleExpandChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      currentPage:1,
      [name]: value,
    
    } as unknown) as Pick<S, keyof S>);
  };
  getExpandIconStyle = (panel:string) => ({
    transform: this.state.expanded === panel ? 'rotate(180deg)' : 'rotate(270deg)',
    transition: 'transform 0.3s',
  });
  handlePageChange = (_event: object, page: number) => {
    this.setState({currentPage: page});  
  };
 
  
   handleToggle = (id:string) => {
    let expandedId = this.state.expanded
    this.setState({ expanded:expandedId === id ? null : id});
   
  };
   // Customizable Area End
  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.FAQlistId === requestCallId || this.getFAQlistId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) { 
          if(responseJson.faq_list){        
          this.setState({ faqListRes: responseJson.faq_list.data,isNoResults:false}); 
          this.setState({totalPages: Math.ceil(responseJson.total_count/5)});  
          }else{
            this.setState({ faqListRes: [],isNoResults:true,message:responseJson.message||""}); 
          }        
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );     
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End
