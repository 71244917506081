//Customizable Area Start
import React from "react";
import {Grid, styled,Typography,TextField,Chip,Button, Box} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DashboardController, { webConfigJSON } from "../DashboardController.web";
import { ellipse } from "./assets";
const CustomTypography = styled(Typography)({
  fontFamily:"'Inter', sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  padding: "15px",
  textDecoration: "none",
  cursor:"pointer",
  background: '#206FC4',
  borderRadius: 10,
  textAlign:"center",
  width:"100%",
  maxWidth: "161px",
  maxHeight: "55px",
  '&:hover': {
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
const CustomAutocomplete = styled(Autocomplete)({
  '& input': {
    fontWeight: 900
  }
})
export default class MainSkills extends DashboardController {
  constructor(props: any) {
    super(props);
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <><div style={styles.root as React.CSSProperties}>
        <Grid container spacing={3} style={styles.boxMain}>
          <Grid item xs={12} md={6}>
            <Typography style={styles.skillsHeading}>
              What are the main skills required for your work?
            </Typography>
            <div style={styles.skillsTextDiv}>
              <Typography style={styles.skillsText}>
                When searching for an IP professional, It’s important to clearly
                identify the skills required for the job. This will help you
                attract the right candidates who have the expertise and
                experience necessary to meet your needs. Make a list of the
                specific skills you need. such as patent prosecution, trademark
                law. licensing, or litigation. Be sure to prioritize the skills
                that are most important to your project o invention. By clearly
                identifying the required skills, you can save time and avoid
                potential miscommunication with candidates who may not have the
                right exepertise.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={styles.box2}>
            <div style={styles.searchSkillsTextDiv}>
              <Typography style={styles.searchSkillsText as React.CSSProperties}>
                Search skills or add your own
              </Typography>
            </div>
            <CustomAutocomplete
              data-testid="skillset"
              options={this.props.skillsOption}
              onChange={this.props.handleSkillMenuInputChange}
              value={this.props.skillValue}
              style={styles.autoCompleteField as React.CSSProperties}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" InputProps={{ ...params.InputProps, endAdornment: null }}
                  error={this.state.skillError}
                  helperText={this.state.skillError && `${webConfigJSON.errorMessage}`} />
              )} />
            <div style={styles.examplesDiv1}>
              <Typography style={styles.examplesText}>
                Examples:
              </Typography>
            </div>
            {/* Examples div 2 */}

            <div style={styles.examplesDiv2 as React.CSSProperties}>
              {this.props.skillsOption&& this.props.skillsOption.map((label:any, index:number) => (
                //@ts-ignore
                <Button data-test-id="handleSkillMenuInputChange" onClick={() => { this.props.handleSkillMenuInputChange("", label)}}>
                  <Chip
                    key={index}
                    variant="outlined"
                    size="medium"
                    label={label}
                    style={styles.examplesItems} />
                </Button>
              ))}
            </div>
          </Grid>
        </Grid>
        {/* Lower design */}
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg} />
      </div>
      <Box style={styles.lowerSectionDiv as React.CSSProperties} >
         <Box style={styles.lowerSectionDiv2}>
            <Typography
              style={{
                ...styles.lowerSectionItem1Text,
                ...(this.state.isHovered && styles.lowerSectionItem1TextHovered),
              }}
              onClick={() => this.handleBack()}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleLeave}
              data-test-id="backbtn">
              BACK
            </Typography>
            <CustomTypography onClick={() => this.handleContinue()} data-test-id="contbtn">CONTINUE</CustomTypography>
            </Box>
        </Box>
        </>
    );
    //Customizable Area End
  }
}
//Customizable Area Start
const styles = {
  root: {
    flexGrow: 1,
    maxWidth: "1440px",
    margin: "0 auto",
  },
  autoCompleteField:{
    width:500,
    fontWeight:700,

    "& .MuiAutocomplete-input":{
      fontWeight: 700
    }
  },
  heading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "40px",
    marginLeft: "20px",
    fontFamily: "'Inter', sans-serif",
  },
  boxMain: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    marginTop: "40px",
    marginLeft: "80px",
    marginBottom: "100px",
  },

  box2: {
    width:"100%"
  },
  skillsHeading: {
    fontSize: "38px",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    color: "#000000",
  },
  skillsTextDiv: {
    marginTop: "20px",
  },
  skillsText: {
    fontSize: "14px",
    color: "#868686",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "30px",
  },
  searchSkillsTextDiv: {
    marginTop: "11px",
  },
  searchSkillsText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
    marginBottom: "5px",
    fontWeight: "700"
  },
  searchTextFiled: {
    maxWidth: "500px",
    maxHeight: "56px",
    borderRadius: "10px",
    marginTop: "5px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-input": {
      width: "432px",
    },
  },

  outlinedInput: {
    borderRadius: "10px",
    "&::placeholder": {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
  examplesDiv1: {
    marginTop: "30px",
  },
  examplesText: {
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },

  examplesDiv2: {
    marginTop: "5px",
    display: "contents",
    flexWrap: "wrap",
  },

  examplesItems: {
    backgroundColor: "#D2E2F3",
    borderRadius: "23px",
    marginTop: "10px",
    marginRight: "15px",
    fontSize: "14px",
    color: "#206FC4",
    border: "none",
    padding: "5px",
    fontFamily: "'Inter', sans-serif",
  },

  lowerSectionDivMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "20px",
    alignItems: "center",
  },
  lowerSectionDiv: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
    position: 'absolute', 
    bottom: 20, 
    left: 0, 
    width: '100%'
  },
  lowerSectionDiv2:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 20,
    width: '100%'
  },
  lowerSectionItem1Text: {
    fontSize: "16px",
    color: "#535353",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontFamily: "'Inter', sans-serif" as "'Inter', sans-serif",
    fontWeight: "bold" as "bold",
    position:'relative' as 'relative',
    zIndex : 1
  },
  lowerSectionItem1TextHovered:{
    color :""
  },

  lowerSectionBtn: {
    maxWidth: "161px",
    maxHeight: "55px",
    backgroundColor: "#206FC4",
    borderRadius: "10px",
  },
  btnText: {
    fontSize: "16px",
    fontWeight:"bold",
    color: "#FFFFFF",
    padding: "15px",
    fontFamily: "'Inter', sans-serif",
    cursor:"pointer",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    left:'0',
    width:'370px',
    zIndex: 0
  }
};
//Customizable Area End
