import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  rating: any;
  feedBackText: any;
  search: any;
  mostRecent: any;
  progressValue: any;
  open: any;
  authToken: string;
  clientJobPostData: any[];
  favouritesJobIds:any[]
  anchorEl: null,
  selectedOption: string;
  currentPage:number;
  totalPages:number;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchJobController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  clientJobPostId: string;
  saveforLaterJobsId:string;
  showFavouritesId:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.clientJobPostId = '';
    this.saveforLaterJobsId="";
    this.showFavouritesId="";

    this.state = {
      rating: 2,
      feedBackText: '',
      search: '',
      mostRecent: '',
      progressValue: '10',
      open: false,
      authToken: '',
      clientJobPostData: [],
      favouritesJobIds:[],
      anchorEl: null,
      selectedOption: 'Most Recent',
      currentPage:1,
      totalPages:0
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')
    });
    this.fetchClientJobPostDetails();
    this.fetchShowFavourites();
    

    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if the state data has been updated
    if ((prevState.search !== this.state.search)||(prevState.currentPage !== this.state.currentPage)|| (prevState.selectedOption!=this.state.selectedOption)){
      // State search has been updated, you can perform actions here
      this.fetchClientJobPostDetails();
    }
  }
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClick = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = (option: string) => {
    this.setState({ anchorEl: null, selectedOption: option });
    // You can perform any action based on the selected option here
  };
formatDate(utcDateString: string): { date: string, time: string } {
    // Convert UTC date string to Date object
    const utcDate: Date = new Date(utcDateString);
  
    // Define options for formatting date
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    // Define options for formatting time
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    // Format the date
    const formattedDate: string = utcDate.toLocaleDateString('en-US', dateOptions);

    // Format the time
    const formattedTime: string = utcDate.toLocaleTimeString('en-US', timeOptions);
    // Return the formatted date and time
    return { date: formattedDate, time: formattedTime };
}
  
 

  
  fetchClientJobPostDetails = () => {
    const ClientJobPostDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      token: this.state.authToken
    };
    

    this.clientJobPostId = ClientJobPostDetailsRequest.messageId;
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.clientJobPostDetailsRequestEndPoint +
        `?query=${this.state.search}&page=${this.state.currentPage}&per_page=10&most_relevant=${this.state.selectedOption==="Most Recent"?true:false}`
    );
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(
      ClientJobPostDetailsRequest.id,
      ClientJobPostDetailsRequest
    );
  };
  

  fetchShowFavourites = () => {
    
    const showFavouritesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      "token": this.state.authToken
     
    };
    

    this.showFavouritesId = showFavouritesRequest.messageId;
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.showFavouritesRequestEndPoint 
    );
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(showFavouritesRequest.id, showFavouritesRequest);
  };

  handleSaveforLaterJobs = (jobId:string|number) => {
    const saveforLaterJobs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type":  webConfigJSON.dashboarContentType,
    };

    const body = {
      "favourites": {
         "favourable_type":"BxBlockPosts::Post",
          "favourable_id":jobId
      },
            "token": this.state.authToken
    }
    this.saveforLaterJobsId = saveforLaterJobs.messageId;
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.saveLaterEndPoint
    );
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.addMilestoneMethodeType
    );
    runEngine.sendMessage(saveforLaterJobs.id, saveforLaterJobs);
    
    
  }
  handleSendProposalClick=async(jobId:number|string) => {
    await storage.set("currentProposalJobId",jobId)
    this.props.navigation.navigate('SendProposals')  
}
handlePageChange = (_event: object, page: number) => {
  this.setState({currentPage: page});  
};
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorBlock = ()=>{
        const errorMessage = _message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
      }
      if (this.clientJobPostId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        

        if (responseJson) {
          //calculate no of pages
          this.setState({totalPages: Math.ceil(responseJson.total_count/10)});          
          this.setState({ clientJobPostData: responseJson.jobs_list.data });
        } else {
          errorBlock();
        }
      }
      if (this.showFavouritesId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({ favouritesJobIds: responseJson.data.map((item: { attributes: { favourable_id: any; }; })=>item.attributes.favourable_id) });
          
        } else {
          errorBlock();
        }
      }
      if(this.saveforLaterJobsId == requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {
          this.setState(prevState => ({
            favouritesJobIds: [...prevState.favouritesJobIds, responseJson.favourable_id]
          }));
          
        } else {
          errorBlock();
        }

      }
    }

    // Customizable Area End
  }
}
