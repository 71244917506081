import React from "react";
//Customizable Area Start
import { Box, Grid, Typography,styled,Button } from "@material-ui/core";
import { monitor, angleleft } from "../assets";
import Footer from "./Footer.web";
import DashboardController, { Props } from "../DashboardController.web";
import ClientNavBar from "./ClientNavBar.web";
const CustomButton = styled(Button)({
  background: "#206FC4",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0px",
  color: "white",
  borderRadius: "10px",
  width: " 173px",
  height: " 56px",
  marginRight:"1rem",
  fontFamily:"'Inter', sans-serif",
  fontWeight:"bold",
  cursor:"pointer",
  marginTop: "20px",
  '&:hover': {
    width: " 173px",
    height: " 56px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
//Customizable Area End
export default class PostJob extends DashboardController {
  //Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <div>
        <ClientNavBar  />
        <div>
        <Grid style={styles.mainContainer1} container>
          <Grid item xs={6} sm={6} style={{marginLeft:"1rem"}}>
            <Typography style={styles.dashBoard as React.CSSProperties}>
              Your Dashboard
            </Typography>
            <Typography style={styles.leftTyp as React.CSSProperties}>{this.state.firstName}</Typography>
          </Grid>
          <Grid item xs={6} sm={5} style={styles.rightGrid1}>
            <CustomButton onClick={this.handleAddBillingMethod}>
              Post a Job
            </CustomButton>
          </Grid>
        </Grid>
      </div>
        <Box style={styles.bigCont}>
          <div style={styles.mainContainer}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div style={styles.leftBox}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        component={"span"}
                        style={styles.leftHead as React.CSSProperties}
                      >
                        Your Posting
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component={"span"}
                        style={styles.rightHead as React.CSSProperties}
                      >
                        See all posting
                      </Typography>
                    </Grid>
                  </Grid>
                  <div style={styles.imgBox as React.CSSProperties}>
                    <Typography component={"div"} style={styles.imageWr}>
                      <img src={monitor} alt="" style={styles.image} />
                    </Typography>

                    <Typography style={styles.inImgHead}>
                      No active job posts
                    </Typography>

                    <Typography
                      style={styles.jobPostDescription as React.CSSProperties}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </Typography>
                    <CustomButton
                      onClick={this.handleAddBillingMethod}
                    >    
                        Post a Job
                    </CustomButton>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Grid container direction="column" style={styles.thirdBox}>
                  <div style={styles.rightGrid as React.CSSProperties}>
                    <Grid item style={styles.exploMoreGrid}>
                      <div>
                        <div style={styles.gridBoxone}>
                          <Typography
                            style={
                              styles.exploreMoreHeading as React.CSSProperties
                            }
                          >
                            Explore More Talent
                          </Typography>
                          <Typography>
                            <img
                              src={angleleft}
                              alt=""
                              style={{ width: "1rem" }}
                              onClick={this.handleRedirect}
                            />
                          </Typography>
                        </div>
                        <Typography
                          style={styles.exploreMoreTxt as React.CSSProperties}
                        >
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item style={styles.exploMoreGrid}>
                      <div style={styles.gridBoxTwo}>
                        <div>
                          <Typography
                            style={
                              styles.exploreMoreHeading as React.CSSProperties
                            }
                          >
                            Add Billing Method
                          </Typography>
                        </div>
                        <div>
                          <img
                            src={angleleft}
                            alt=""
                            style={{ width: "1rem" }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Box style={styles.lastBox as React.CSSProperties}>
              <div>
                <Typography style={styles.heading as React.CSSProperties}>
                  How To Work With Talent
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Typography>
              </div>
              <div>
                <Typography style={styles.subHeading as React.CSSProperties}>
                  1.Post a job to the marketplace
                </Typography>

                <Typography style={styles.para as React.CSSProperties}>
                  Provide enough detail for great talent to figure out if the
                  work is right for them.(You can always edit your post, or send
                  an invite to reach out to people directly.)
                </Typography>
              </div>
              <div>
                <Typography style={styles.subHeading as React.CSSProperties}>
                  2.Get proposals from talent
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                  A strong working relationship starts with open communication.
                  Here’s your chance to ask about experience, set expectations
                  for what you need, and discuss terms of the work.
                </Typography>
              </div>
              <div>
                <Typography style={styles.subHeading as React.CSSProperties}>
                  3.Start working together
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                  Once you both agree on terms, collaborate with simple and
                  secure tools like chat, file sharing, and time tracking.
                </Typography>
              </div>
              <div>
                <Typography style={styles.subHeading as React.CSSProperties}>
                  4.Pay for work you approve
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                  Reports are useful for keeping track of payments and reviewing
                  work. As you complete jobs, you can build trusting
                  relationships with talent in a way that helps you both grow.
                </Typography>
              </div>
            </Box>
          </div>
        </Box>
        <Footer />
      </div>
      //Customizable Area End
    );
  }
}

//Customizable Area Start
const theme = {
  spacing: {
    2: "8px",
  },
  breakpoints: {
    down: (size: any) => `@media (max-width: ${size}px)`,
  },
  breakpoints2:{
    down: (size: any) => `@media (max-width: ${size}px)`
  }
};

const styles = {
  mainContainer: {},
  topBox: {
    marginBottom: theme.spacing[2],
  },
  leftBox: {
    minHeight: "398px",
    padding: "29px 18px",
    background: "#FAFBFC",
  },
  leftHead: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "27px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  rightHead: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "right",
  },
  imageWr: {
    marginBottom: "10px",
  },
  image: {
    maxWidth: "100%",
  },
  imgBox: {
    textAlign: "center",
  },
  heading: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "27px",
    letterSpacing: "0px",
  },
  subHeading: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: "27px",
    letterSpacing: "0px",
    textAlign: "left",
    height: "27px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "18px",
    },
  },
  para: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#767677",
  },
  inImgHead: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
  },
  postBtn: {
    width: "173px",
    height: "56px",
    background: "#206FC4",
    color: "#FFFFFF",
    marginTop: "20px",
    fontSize: "16px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: "18.75px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  exploreHead: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0px",
  },
  exploreSubH: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },

  thirdBox: {
    background: "#FAFBFC",
    marginLeft: "14rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "20px",
    },
  },
  jobPostDescription: {
    marginTop: "10px",
    color: "#767677",
    fontSize: "14px",
    lineHeight: "17px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "bold",
  },
  bigCont: {},
  lastBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "44%",
    marginLeft: "2rem",
    gap: "20px",
  },
  exploMoreGrid: {
    height: " 101px",
  },
  gridBoxone: {
    display: "flex",
    justifyContent: "space-between",
  },
  gridBoxTwo: {
    display: "flex",
    justifyContent: "space-between",
  },
  exploreMoreHeading: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
  },
  exploreMoreTxt: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#767677",
  },
  rightGrid: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  imgStyle: {
    Width: "1rem",
  },
  leftTyp: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "54px",
    letterSpacing: "0px",
    textAlign: "left",
  },

  btn: {
    background: "#206FC4",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0px",
    color: "white",
    borderRadius: "10px",
    width: " 173px",
    height: " 56px",
    marginRight:"1rem",
    fontFamily:"'Inter', sans-serif",
    fontWeight:"bold",
    cursor:"pointer"
  },
  mainContainer1: {
    margin: "0 auto",
    marginTop: "2rem",
  },
  dashBoard: {
    fontFamily: "Inter",
    fontSize: "26px",
    fontWeight: "bold",
    lineHeight: "27px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  rightGrid1: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft:"6rem",
    [theme.breakpoints2.down('sm')]:{
    }
  },
  //Customizable Area End
};
