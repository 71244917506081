import React from 'react';
// Customizable Area Start
import { Props } from './ViewJobController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box, Chip, Typography, Button, Divider } from '@material-ui/core';
import { calendar } from './assets';
import FreelancerSavedJobController from './FreelancerSavedJobController.web';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';

// Customizable Area End
export default class FreelancerSavedJob extends FreelancerSavedJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainerj
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          gridRowGap={'1rem'}
          marginTop={'35px'}
          marginLeft={'50px'}
        >
          <Box display={'grid'} gridAutoFlow={'column'} width={'17%'}>
            <Typography
              style={{
                ...webStyle.text,

                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px'
              }}
            >
              Find Work
            </Typography>
            <ChevronRightIcon style={{ marginTop: '4px' }} />
            <Typography
              style={{
                ...webStyle.text,

                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px',
                color: '#206FC4'
              }}
            >
              Saved Jobs
            </Typography>
          </Box>
          <Typography
            style={{
              ...webStyle.text,

              fontSize: '36px',
              fontWeight: 700,
              marginBottom: '15px'
            }}
          >
            Saved Jobs
          </Typography>
        </Box>
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          gridRowGap={'3rem'}
          width={'50%'}
          marginLeft={'50px'}
          marginRight={'50px'}
        >
          {this.state.favJobsList
            .filter(
              (fItem: any) => !this.state.removedIDS.includes(fItem.favId)
            )
            .map(item => (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                style={{
                  borderRadius: '24px',
                  backgroundColor: '#E6EBEF',
                  padding: '18px'
                }}
              >
                <Box
                  style={{
                    marginLeft: '5px',
                    width: '60%'
                  }}
                >
                  <Typography
                    style={{
                      ...webStyle.text,
                      fontSize: '18px',
                      fontWeight: 100
                    }}
                  >
                    {item.attributes.name}
                  </Typography>
                  <Box display={'flex'} style={{ marginTop: '10px' }}>
                    <Box display={'flex'} style={{ alignItems: 'center' }}>
                      <LocationOnIcon
                        style={{ marginLeft: '-5px', marginRight: '10px' }}
                      />{' '}
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginTop: '10px',
                          display: 'contents'
                        }}
                      >
                        {item.attributes.location}
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                    </Box>
                    <Box display={'flex'} style={{ alignItems: 'center' }}>
                      <img
                        src={calendar}
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                      />

                      <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                        Posted {item.attributes.created_at}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography style={webStyle.text}>
                      {item.attributes.description}
                    </Typography>
                  </Box>
                  <Box>
                    {item.attributes.skills.map(
                      (
                        item:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined
                      ) => (
                        <Chip
                          label={item}
                          style={{
                            backgroundColor: '#bed8eb',
                            color: '#2378D1',
                            marginRight: '10px'
                          }}
                        />
                      )
                    )}
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  style={{ marginTop: '30px', width: '30%' }}
                >
                  <Box
                    display={'grid'}
                    gridAutoFlow={'column'}
                    justifyContent={'end'}
                    alignItems="center"
                  >
                    <Box
                      borderRadius={'50%'}
                      bgcolor={'#fff'}
                      padding={'5px'}
                      marginTop={'-15px'}
                      boxShadow={'0px 2px 11px 0px rgba(162, 188, 193, 0.24)'}
                      style={{ cursor: 'pointer' }}
                    >
                      <FavoriteIcon
                        style={{ color: '#206FC4' }}
                        data-testid={`favorite-${item.attributes.id}`}
                        onClick={() => {
                          this.removeJobFromFavourite(item.favId);
                          this.setState(prev => ({
                            removedIDS: [...prev.removedIDS, item.favId]
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#206FC4', color: 'FFF' }}
                    data-testid='sendProposalButton'
                    onClick={() => this.handleSendProposalClick(item.attributes.id)}
                  >
                    send proposals
                  </Button>
                </Box>
              </Box>
            ))}
             {this.state.favJobsList
            .filter(
              (fItem: {favId:number|string}) => !this.state.removedIDS.includes(fItem.favId)
            ).length==0 && <Typography>No saved jobs</Typography>
  }
        </Box>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  }
};

// Customizable Area End
