import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const getSteps = [1, 2, 3, 4, 5, 6, 7, 8];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  check: string;
  check1:boolean
  check2: boolean;
  check3: boolean;
  activeStep: number;
  allAboveCheck: boolean;
  checkboxes: any[];
  inventionRadio: string;
  imagesRadio: string;
  prototypeRadio: string;
  do1Radio: string;
  lawyerRadio: string;
  do2Radio: string;
  awardRadio: string;
  wipoClassification:string;
  schoolAwardRadio: string;
  patenting: string;
  option: string;
  businessRadio: string;
  priceRadio: string;
  countryRadioDropDown: string;
  countryRadioDropDownOpen: boolean;
  images1: null;
  prototypeFile: null;
  legalFile: null;
  uploadedImageName: string;
  uploadedPrototypeName: string;
  uploadedLegalName: string;
  toolTip:boolean;
  selectedOption:string;
  multipleFiles1:any;
  multipleFiles2:any;
  multipleFiles3:any;
  multipleFiles4:any;
  multipleFiles1error:any;
  multipleFiles2error:any;
  multipleFiles3error:any;
  multipleFiles4error:any;
  errorMessage:any;
  anchorElement: null | HTMLElement;
  validationError:string;
  showStep3ValidationErrors: boolean;
  showStep4ValidationErrors:boolean;
  showStep5ValidationErrors:boolean;
  showStep6ValidationErrors:boolean;
  uploadErrorMessage:string
  uploadflie2ErrorMessage:string
  uploadflie3ErrorMessage:string;
  attachLink:string;
  linkError: string;
  collageName:string;
  schoolName:string;
  addWorkExp:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class InventorFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      check: "",
      check1: false,
      check2: false,
      check3: false,
      activeStep: 0,
      allAboveCheck: false,
      checkboxes: [
        { id: "consumerCheck", label: "Direct to consumer", checked: false },
        {
          id: "below5Check",
          label: "Company below 5 million$",
          checked: false
        },
        { id: "upto10Million", label: "5$-10 million$", checked: false },
        { id: "up100Million", label: "10$-100 million$", checked: false },
        {
          id: "moreThan100Check",
          label: "More than 100 million$",
          checked: false
        },
        { id: "ngoCheck", label: "Ngo", checked: false },
        { id: "governmentCheck", label: "Government", checked: false },
        {id: "All of The above",label:"All of the above",checked:false}
      ],
      inventionRadio: "",
      imagesRadio: "",
      prototypeRadio: "",
      do1Radio: "",
      lawyerRadio: "",
      do2Radio: "",
      awardRadio: "",
      wipoClassification:"",
      schoolAwardRadio: "",
      patenting: "",
      option: "",
      businessRadio: "",
      priceRadio: "",
      countryRadioDropDown: "",
      countryRadioDropDownOpen: false,
      images1: null,
      uploadedImageName: "",
      prototypeFile: null,
      uploadedPrototypeName: "",
      legalFile: null,
      uploadedLegalName: "",
      toolTip: false,
      selectedOption:"",
      multipleFiles1:[],
      multipleFiles2:[],
      multipleFiles3:[],
      multipleFiles4:[],
      multipleFiles1error:"",
      multipleFiles2error:"",
      multipleFiles3error:"",
      multipleFiles4error:"",
      errorMessage:"",
      anchorElement: null,
      validationError:"",
      showStep3ValidationErrors:false,
      showStep4ValidationErrors:false,
      showStep5ValidationErrors:false,
      showStep6ValidationErrors:false,
      uploadErrorMessage:"",
      uploadflie2ErrorMessage:"",
      uploadflie3ErrorMessage:"",
      attachLink:"",
      linkError:"",
      collageName:"",
      schoolName:"",
      addWorkExp:"",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  toggleCountryDropDown = () => {
    this.setState(prevState => ({
      countryRadioDropDownOpen: !prevState.countryRadioDropDownOpen
    }));
  };

  handleCheckChange = (event:any) => {
    const { name } = event.target;
    this.setState({ selectedOption: name });
  };

  handleBack = () => {
    this.setState({ validationError: '' });
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleMouseEnterInfo = (event:any) => {
    const { currentTarget } = event;
    this.setState({
      anchorElement: currentTarget,
      toolTip: true
    });
  };

  handleMouseLeaveInfo = () => {
    this.setState({
      toolTip: false
    });
  };

  handleLinkedInLinkChange = (event: any) => {
    const websiteRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const { value } = event.target;
    
    if (!websiteRegex.test(value)) {
      this.setState({
        attachLink: value,
        linkError: "Invalid Website Link Format"
      });
    } else {
      this.setState({
        attachLink: value,
        linkError: ''
      });
    }
  };
  handleNext1 = () => {
    const { selectedOption } = this.state;
  
    if (!selectedOption) {
      console.log({selectedOption},"selectedOptionselectedOptionselectedOption")
      this.setState({ validationError: '* Please select a category before proceeding.' });
      return; 
    }
    this.setState({ validationError: '' });
  
    if (this.state.activeStep === getSteps.length - 1) {
      this.props.navigation.navigate('ProfessionalAccountRegistration');
    } else {
      this.setState({ activeStep: this.state.activeStep + 1, toolTip: false });
    }
  };

  handleNext2 = () => {
    const { checkboxes } = this.state;
    const isAnyCheckboxChecked = checkboxes.some((checkbox) => checkbox.checked);
    if (!isAnyCheckboxChecked) {
      this.setState({ validationError: '* Please select at least one target audience before proceeding.' });
      return; 
    }
    this.setState({ validationError: '' });
    if (this.state.activeStep === getSteps.length - 1) {
      this.props.navigation.navigate('ProfessionalAccountRegistration');
    } else {
      this.setState({ activeStep: this.state.activeStep + 1, toolTip: false });
    }
  };
  handleNext3 = () => {
    const { inventionRadio, imagesRadio, prototypeRadio, do1Radio,lawyerRadio,uploadedImageName, uploadedPrototypeName,uploadedLegalName } = this.state;
    if (inventionRadio && imagesRadio && prototypeRadio && do1Radio) {
      if (imagesRadio === 'true' && !uploadedImageName) {
        this.setState({
          showStep3ValidationErrors: true,
        });
      } else if (prototypeRadio === 'true' && !uploadedPrototypeName) {
        this.setState({
          showStep3ValidationErrors: true,
        });
      }
      else if (lawyerRadio === 'true' && !uploadedLegalName) {
        this.setState({
          showStep3ValidationErrors: true,
        });
      } else {
        if (this.state.activeStep === getSteps.length - 1) {
          this.props.navigation.navigate('ProfessionalAccountRegistration');
        } else {
          this.setState({
            activeStep: this.state.activeStep + 1,
            toolTip: false,
            showStep3ValidationErrors: false, 
          });
        }
      }
    } else {
      this.setState({
        showStep3ValidationErrors: true,
      });
    }
  };

  handleNext4 = () => {
    const { wipoClassification,awardRadio,multipleFiles1,multipleFiles4 } = this.state;
    if (wipoClassification && awardRadio ) {
      if (wipoClassification === 'true' && multipleFiles1.length==0) {
        this.setState({
          showStep4ValidationErrors: true,
        });
      } else if (awardRadio === 'true' && multipleFiles4.length==0) {
        this.setState({
          showStep4ValidationErrors: true,
        });
      }
     else {
        if (this.state.activeStep === getSteps.length - 1) {
          this.props.navigation.navigate('ProfessionalAccountRegistration');
        } else {
          this.setState({
            activeStep: this.state.activeStep + 1,
            toolTip: false,
            showStep4ValidationErrors: false, 
          });
        }
      }
    } else {
      this.setState({
        showStep4ValidationErrors: true,
      });
    }
  };
  handleNext5 = () => {
    const { option,patenting,multipleFiles3,attachLink } = this.state;
    if (option && patenting ) {
      if (option === 'true' && attachLink=="") {
        this.setState({
          showStep5ValidationErrors: true,
        });
      } else if (patenting === 'true' && multipleFiles3.length==0) {
        this.setState({
          showStep5ValidationErrors: true,
        });
      }
     else {
        if (this.state.activeStep === getSteps.length - 1) {
          this.props.navigation.navigate('ProfessionalAccountRegistration');
        } else {
          this.setState({
            activeStep: this.state.activeStep + 1,
            toolTip: false,
            showStep5ValidationErrors: false, 
          });
        }
      }
    } else {
      this.setState({
        showStep5ValidationErrors: true,
      });
    }
  };

  handleNext6 = () => {
    const { priceRadio, countryRadioDropDown } = this.state;
    if (priceRadio && countryRadioDropDown) {
      if (this.state.activeStep === getSteps.length - 1) {
        this.props.navigation.navigate('ProfessionalAccountRegistration');
      } else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          toolTip: false,
          showStep6ValidationErrors: false,
        });
      }
    } else {
      this.setState({
        showStep6ValidationErrors: true,
      });
    }
  };

  handleNext7 = () => {
    const { businessRadio } = this.state;
    if (businessRadio ) {
      if (this.state.activeStep === getSteps.length - 1) {
        this.props.navigation.navigate('ProfessionalAccountRegistration');
      } else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          toolTip: false,
          showStep6ValidationErrors: false,
        });
      }
    } else {
      this.setState({
        showStep6ValidationErrors: true,
      });
    }
  };
  handleNext8 = () => {
    const { addWorkExp, schoolName, collageName } = this.state;
    if (addWorkExp === "" && schoolName === "" && collageName === "") {
      this.setState({
        showStep6ValidationErrors: true,
      });
    } else {
      if (this.state.activeStep === getSteps.length - 1) {
        this.props.navigation.navigate('ProfessionalAccountRegistration');
      } else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          toolTip: false,
          showStep6ValidationErrors: false,
        });
      }
    }
  }; 
  
  
  handleCheckboxChange = (id: any) => () => {
    this.setState(prevState => ({
      checkboxes: prevState.checkboxes.map(checkbox =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    }));
  };

  handleRadio1Change = (event: any) => {
    const value = event.target.value;
    this.setState({
      inventionRadio: value,
    });
  };

  handleRadio2Change = (event: any) => {
    this.setState({ imagesRadio: event.target.value });
  };

  handleRadio3Change = (event: any) => {
    this.setState({ prototypeRadio: event.target.value });
  };

  handleRadio4Change = (event: any) => {
    this.setState({ do1Radio: event.target.value });
  };

  handleRadio5Change = (event: any) => {
    this.setState({ lawyerRadio: event.target.value });
  };

  handleRadio6Change = (event: any) => {
    this.setState({ do2Radio: event.target.value });
  };

  handleAwardRadioChange = (event: any) => {
    this.setState({ awardRadio: event.target.value });
  };

  handleWipoClassificationChange = (event: any) => {
    this.setState({ wipoClassification: event.target.value });
  };

  handleSchoolAwardRadioChange = (event: any) => {
    this.setState({ schoolAwardRadio: event.target.value });
  };

  handlePatentingRadioChange = (event: any) => {
    this.setState({ patenting: event.target.value });
  };

  handleOptionRadioChange = (event: any) => {
    this.setState({ option: event.target.value });
  };

  handleBusinessRadioChange = (event: any) => {
    this.setState({ businessRadio: event.target.value });
  };

  handlePriceRadioChange = (event: any) => {
    this.setState({ priceRadio: event.target.value });
  };

  handleCountryRadioDropDownChange = (event: any) => {
    this.setState({ countryRadioDropDown: event.target.value });
  };

  handleFileChange1 = (event: any) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      // Check file type
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'video/mp4'];
      if (!allowedTypes.includes(selectedFile.type)) {
        this.setState({
          images1: null,
          uploadedImageName: '',
          uploadErrorMessage: 'Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.'
        });
        return;
      }
  
      // Check file size (max size: 5MB)
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (selectedFile.size > maxSize) {
        this.setState({
          images1: null,
          uploadedImageName: '',
          uploadErrorMessage: 'File size exceeds the maximum limit of 5 MB.'
        });
        return;
      }
  
      // Reset error message and update state with selected file
      this.setState({
        images1: selectedFile,
        uploadedImageName: selectedFile.name,
        uploadErrorMessage: ''
      });
    } else {
      // Reset state if no file selected
      this.setState({
        images1: null,
        uploadedImageName: '',
        uploadErrorMessage: ''
      });
    }
  };
  
  handleFileChange2 = (event: any) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'video/mp4'];
      if (!allowedTypes.includes(selectedFile.type)) {
        this.setState({
          images1: null,
          uploadedPrototypeName: '',
          uploadflie2ErrorMessage: 'Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.'
        });
        return;
      }
  
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (selectedFile.size > maxSize) {
        this.setState({
          images1: null,
          uploadedPrototypeName: '',
          uploadflie2ErrorMessage: 'File size exceeds the maximum limit of 5 MB.'
        });
        return;
      }
  
      this.setState({
        images1: selectedFile,
        uploadedPrototypeName: selectedFile.name,
        uploadflie2ErrorMessage: ''
      });
    } else {
      // Reset state if no file selected
      this.setState({
        images1: null,
        uploadedPrototypeName: '',
        uploadflie2ErrorMessage: ''
      });
    }
  };
  handleFileChange3 = (event: any) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'video/mp4'];
      if (!allowedTypes.includes(selectedFile.type)) {
        this.setState({
          images1: null,
          uploadedLegalName: '',
          uploadflie3ErrorMessage: 'Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.'
        });
        return;
      }
  
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (selectedFile.size > maxSize) {
        this.setState({
          images1: null,
          uploadedLegalName: '',
          uploadflie3ErrorMessage: 'File size exceeds the maximum limit of 5 MB.'
        });
        return;
      }
  
      this.setState({
        images1: selectedFile,
        uploadedLegalName: selectedFile.name,
        uploadflie3ErrorMessage: ''
      });
    } else {
      // Reset state if no file selected
      this.setState({
        images1: null,
        uploadedLegalName: '',
        uploadflie3ErrorMessage: ''
      });
    }
  };

  handleFileChange4 =(event:any)=>{
    const selectedFiles = Array.from(event.target.files);
     if (selectedFiles.length + this.state.multipleFiles1.length > 5) {
      this.setState({ multipleFiles1error: 'Total files should not exceed 5.',multipleFiles1:[]});
    } else {
      this.setState({ multipleFiles1error: null });
      // Convert the FileList to an array and add it to the existing array
      const newFilesArray = [...this.state.multipleFiles1];
      for (let i = 0; i < selectedFiles.length; i++) {
        newFilesArray.push(selectedFiles[i]);
      }
      this.setState({ multipleFiles1: newFilesArray });
    }
  }


  handleFileChange42 =(event:any)=>{
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length + this.state.multipleFiles4.length > 5) {
     this.setState({multipleFiles4error: 'Total files should not exceed 5.',multipleFiles4:[]});
   } else {
     this.setState({ multipleFiles4error: null });
     const newFilesArray = [...this.state.multipleFiles4];
     for (let i = 0; i < selectedFiles.length; i++) {
       newFilesArray.push(selectedFiles[i]);
     }
     this.setState({ multipleFiles4: newFilesArray });
   }
  }
  handleFileChange5 =(event:any)=>{
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length + this.state.multipleFiles2.length > 5) {
      this.setState({ multipleFiles2error: 'Total files should not exceed 5.',multipleFiles2:[]});
    } else {
      this.setState({ multipleFiles2error: null });
      // Convert the FileList to an array and add it to the existing array
      const newFilesArray = [...this.state.multipleFiles2];
      for (let i = 0; i < selectedFiles.length; i++) {
        newFilesArray.push(selectedFiles[i]);
      }
      this.setState({ multipleFiles2: newFilesArray });
    }
  }

  handleFileChange6 =(event:any)=>{
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length + this.state.multipleFiles3.length > 5) {
      this.setState({ multipleFiles3error: 'Total files should not exceed 5.',multipleFiles3:[]});
    } else {
      this.setState({ multipleFiles3error: null });
      // Convert the FileList to an array and add it to the existing array
      const newFilesArray = [...this.state.multipleFiles3];
      for (let i = 0; i < selectedFiles.length; i++) {
        newFilesArray.push(selectedFiles[i]);
      }
      this.setState({ multipleFiles3: newFilesArray });
    }
  }
  // Customizable Area End

  async receive() {
    // Customizable Area Start
    // Customizable Area End
  }
}
