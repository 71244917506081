import React from 'react';
// Customizable Area Start
import { Props } from './ViewJobController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import {  homepage4,oval,dotted,ovalright } from './assets';
import Avatar from '@material-ui/core/Avatar';
import FreelancerHomePageController from './FreelancerHomePageController.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});
import {
  greeting,
  wrappedFormattedStringIST
} from '../../../components/src/ActiveStep0.web';
// Customizable Area End
export default class FreelancerHomePage extends FreelancerHomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { freelancerhomepageData } = this.state;
    const getSectionData = (sectionId: any) => {
      return freelancerhomepageData.find(
        (sectionData: { attributes: { section: any } }) =>
          Number(sectionData.attributes.section) == sectionId
      )?.attributes;
    };
    const openLinkInNewTab = (url:any) => {
      window.open(url, '_blank');
    };

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <FreelancerNavBar
          navigation={this.props.navigation}
          isFromHomePage={true}
        />
        <Box>
          <Box
            sx={webStyle.flexColumn}
            alignItems={'baseline'}
            gridColumnGap={'8px'}
            ml={'5%'}
            mb={'2rem'}
            mt={'48px'}
          >
            <Box
              sx={{ ...webStyle.flexRow, marginBottom: '0' }}
              marginBottom={0}
              alignItems={'baseline'}
            >
              <Typography
                style={{
                  ...webStyle.text,

                  fontSize: '36px',
                  lineHeight: '62px' /* 172.222% */,
                  letterSpacing: '-0.5px',
                  marginBottom: '8px'
                }}
              >
                {greeting}, {this.state.firstName}!
              </Typography>
              <Typography
                style={{
                  ...webStyle.description,
                  marginBottom: '0',
                  marginLeft: '1rem'
                }}
              >
                {wrappedFormattedStringIST}
              </Typography>
            </Box>

            <Typography
              style={{
                ...webStyle.text,
                fontSize: '18px',
                fontWeight: 400,
                marginBottom: '15px'
              }}
            >
              Have a nice day ahead
            </Typography>
          </Box>
          {/* section 1 */}

          <Box sx={webStyle.flexRow}>
            <img
              src={getSectionData(1)?.image}
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,borderRadius:"0px 55px 55px 0px",objectFit:"cover"}}
            />
            <Box sx={webStyle.CenterBgImage}>
              <img src={homepage4} />
              <img src={homepage4} />
            </Box>
            <img
              src={getSectionData(1)?.secondary_image}
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,borderRadius:"55px 0px 0px 55px",objectFit:"cover"}}
            />
          </Box>
          {/* section 2 */}
          <Box sx={webStyle.flexRow} ml={'5%'} marginTop={"110px"}>
            <Box flex={0.45}>
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:  getSectionData(2)?.title}}  />
               
              
              <div style={webStyle.description} dangerouslySetInnerHTML={{__html:
                getSectionData(2)?.sub_title}}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  openLinkInNewTab(getSectionData(2)?.button_url)
                }
                data-testid={'joinMeeting'}
              >
                {getSectionData(2)?.button_text}
              </Button>
            </Box>

            <img
              src={getSectionData(2)?.image}
              height="100%"
              style={{ display: 'flex', flex: '0.45',width:"684",zIndex:11, height:"434" ,borderRadius:"55px 0px 0px 55px",objectFit:"cover"}}
            />
          </Box>
          {/* section 3 */}      
          <img src={dotted} style={{position:"relative",bottom:"-69px",zIndex:10}}/>  
          <img src={ovalright} style={{position:"relative",bottom:"-120px",zIndex:10,right:"-62%",marginTop:"-320px",opacity:0.75,}}/>       
          <Box
            sx={webStyle.flexRow}
            style={{ backgroundColor: '#E6EBEF', padding: '4rem 0' ,}}
          >
          
            <img
              src={getSectionData(3)?.image}
              height="100%"
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,borderRadius:"0px 55px 55px 0px",objectFit:"cover",zIndex:11}}
            />
            <Box flex={0.45}>
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:  getSectionData(3)?.title}}/>
                
              <div style={webStyle.description} dangerouslySetInnerHTML={{__html:
                getSectionData(3)?.sub_title}}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleMyJobs}
                data-testid={'FindWork'}
              >
                {getSectionData(3)?.button_text}
              </Button>
            </Box>
          </Box>
          {/* section 4 */}

          <Box sx={webStyle.flexRow} ml={'5%'} marginTop= {"110px"}>       
            <div
              style={{
                ...webStyle.text, 
                marginBottom: 0,
                display: 'flex',
                flex: 0.5
              }}
              dangerouslySetInnerHTML={{__html:
            
              getSectionData(4)?.title}}
            />
            <div
              style={{
                ...webStyle.description,
                marginBottom: '0',
                display: 'flex',
                flex: 0.5
              }}
              dangerouslySetInnerHTML={{__html:
              getSectionData(4)?.sub_title}}
            />
          </Box>
          <Box sx={webStyle.flexRow} ml={'5%'} mr={'2%'}>
            {getSectionData(4)?.content_cards.data.map(
              (item: any, index: number) => (
                <Box
                  sx={{
                    ...webStyle.infoCard,
                    ...webStyle.flexColumn,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 25px'
                  }}
                >
                  <Avatar style={webStyle.avatar}>{index + 1}</Avatar>
                  <div
                    style={{
                      ...webStyle.description,
                      marginBottom: '0',
                      color: '#fff',
                      opacity: 1
                    }}
                    dangerouslySetInnerHTML={{__html:
                    item?.attributes?.sub_title}}
                  />
                </Box>
              )
            )}
          </Box>
          {/* section 5 */}

          <Box sx={webStyle.flexRow} marginTop={"110px"}>
            <img
              src={getSectionData(5)?.image}
              height="100%"
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,borderRadius:"0px 55px 55px 0px",objectFit:"cover",zIndex:10}}
            />
            <Box flex={0.45}>
            
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:
                getSectionData(5)?.title}}
              />
              <div style={webStyle.description} dangerouslySetInnerHTML={{__html:
                getSectionData(5)?.sub_title}}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  openLinkInNewTab(getSectionData(5)?.button_url)
                }
                data-testid="UpdateJobPreference"
              >
                {getSectionData(5)?.button_text}
              </Button>
            </Box>
          </Box>
          {/* section 6 */}
     
          <img src={oval} style={{bottom:"-124px",position:"relative",opacity:"0.75",marginTop:"-360px"}}/>
     
          <Box
            sx={webStyle.flexRow}
            style={{ backgroundColor: '#E6EBEF', padding: '4rem 0'}}
          >         
            <Box flex={0.4} ml={'5%'}>
           
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:
                getSectionData(6)?.title}}
              />
              <div style={webStyle.description}  dangerouslySetInnerHTML={{__html:
                getSectionData(6)?.sub_title}}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  openLinkInNewTab(getSectionData(6)?.button_url)
                }
                data-testid="contact"
              >
                {getSectionData(6)?.button_text}
              </Button>
              {/* section 7 */}
            </Box>

            <img
              src={getSectionData(6)?.image}
              height="100%"
              alt=''
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,borderRadius:"55px 0px 0px 55px",objectFit:"cover"}}
            />
          </Box>
          <Box
            sx={webStyle.flexColumn}
            alignItems={'baseline'}
            gridColumnGap={'8px'}
            ml={'5%'}
            mb={'2rem'}
            marginTop={"110px"}
          >
            <div
              style={{
                ...webStyle.text,
                marginBottom: '-30px',
                fontSize: '36px',
                lineHeight: '62px' /* 172.222% */,
                letterSpacing: '-0.5px',
              
              }}
              dangerouslySetInnerHTML={{__html:
            
              getSectionData(7)?.title}}
            />

            <div style={{ ...webStyle.description, marginBottom: '0' }}
            dangerouslySetInnerHTML={{__html:
              getSectionData(7)?.sub_title}}
            />
          </Box>
          <Box sx={webStyle.flexRow}>
            <img
              src={getSectionData(7)?.image}
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434" ,objectFit:"cover",borderRadius:"0px 55px 55px 0px"}}
            />
            <Box sx={webStyle.CenterBgImage}>
              <img src={homepage4} />
              <img src={homepage4} />
            </Box>
            <img
              src={getSectionData(7)?.secondary_image}
              style={{ display: 'flex', flex: '0.45',width:"684", height:"434",objectFit:"cover",borderRadius:"55px 0px 0px 55px" }}
            />
          </Box>
        </Box>

        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 100,
    color: '#0A0A0A',
    marginBottom: '1rem',
    opacity: 0.9,
    fontStyle: 'normal'
  },
  description: {
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    opacity: 0.6,
    fontSize: '18px',
    marginBottom: '2rem',
    color: '#0A0A0A'
  },

  flexRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'center'
  },
  infoCard: {
    borderRadius: '24px',
    background: '#206FC4',
    color: '#fff',
    height: '434px',
    flex: 0.25
  },
  avatar: {
    width: '100px',
    height: '100px',
    color: '#0A0A0A',
    background: '#FFF',
    fontSize: '28px',
    fontWeight: 100,
    marginBottom: '1.5rem'
  },
  CenterBgImage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
    margin: '0 -120px',
    justifyContent: 'center'
  },
  GridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: '2rem'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  }
};
// Customizable Area End
