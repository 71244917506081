import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
import { getStorageData, removeStorageData, setStorageData } from 'framework/src/Utilities';
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  targetAudience: any;
  market: any;
  imagesvideoPrototype: any;
  publicOrPrivate: any;
  imagesvideoPrototype_publicOrPrivate: any;
  legalDocuments_publicOrPrivate: any;
  legalDocuments: any;
  awards: any;
  funding: any;
  government: any;
  Amount: any;
  price: any;
  otherInfo: string;
  fees: any;
  bankDetails: any;
  accountNumber: any;
  emailValid: string;
  attachLink: any;
  researchPapers: any;
  patent: any;
  companyOruniversity: any;
  authToken: string;
  otpError: any;
  otpValue: any;
  category: string;
  industry: any;
  researchPapersError:string;
  academic: any;
  successfulPatent: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  countryLawGuidelines: any[];
  verfication: boolean;
  legalDocumentsPrototypeError: string;
  imagesvideoPrototypeError: string;
  patentError: string;
  successPage: boolean;
  seconds: any;
  timerExpired: boolean;
  disabledResendOtp: boolean;
  profileName: string;
  error: any;
  categoryError: string;
  showPageError: boolean;
  legalDocumentsFile: any;
  imagesvideoPrototypeFile: any;
  governmentFile: any;
  industryFile: any;
  academicFile: any;
  document: any;
  awardsRadioError: string;
  fundingError: string;
  accountDoc: any;
  publicStep5Error: string;
  VerificationError: boolean;
  disableBtn: boolean;
  awardsError: string;
  qualification: string;
  targetAudienceError: string;
  marketError: string;
  qualificationError: string;
  imagesvideoError: string;
  legalDocumentsError: string;
  awardsLinkError:string;
  bankDetailsError: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PatentHolderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  patentHolderById: string = '';
  sendEmailOtpCallId: string = '';
  otpVerificationForEmailCallId: string = '';
  myInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      qualification: '',
      qualificationError: '',
      targetAudience: '',
      fundingError: '',
      market: "",
      category: '',
      imagesvideoPrototype: '',
      publicOrPrivate: '',
      imagesvideoPrototype_publicOrPrivate: '',
      legalDocuments_publicOrPrivate: '',
      awards: '',
      funding: '',
      researchPapersError:'',
      patentError: '',
      legalDocuments: '',
      government: '',
      Amount: '',
      seconds: 60,
      awardsError: '',
      targetAudienceError: '',
      marketError: '',
      categoryError: '',
      error: false,
      price: '',
      showPageError: false,
      emailValid: "",
      fees: '',
      bankDetails: 'Now',
      accountNumber: '',
      attachLink: '',
      researchPapers: '',
      patent: '',
      awardsRadioError: '',
      imagesvideoError: '',
      legalDocumentsPrototypeError: '',
      imagesvideoPrototypeError: '',
      legalDocumentsError: '',
      companyOruniversity: '',
      industry: '',
      accountDoc: '',
      awardsLinkError:'',
      academic: '',
      timerExpired: false,
      bankDetailsError: '',
      successfulPatent: '',
      otpError: "",
      otpValue: '',
      authToken: "",
      otherInfo: '',
      anchorElement: null,
      openInfoPopper: false,
      countryLawGuidelines: [],
      verfication: false,
      successPage: false,
      governmentFile: '',
      publicStep5Error: '',
      industryFile: '',
      academicFile: '',
      disabledResendOtp: true,
      profileName: '',
      imagesvideoPrototypeFile: '',
      legalDocumentsFile: '',
      document: '',
      VerificationError: true,
      disableBtn: false,
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      profileName: await getStorageData("firstName")
    })
    // Customizable Area End
  }
  // Customizable Area Start

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  handleNext1 = () => {
    const { category } = this.state;
    if (!category) {
      this.setState({
        categoryError: '* Please select any one category.'
      })
    }
    else {
      this.setState({
        categoryError: '',
        activeStep: this.state.activeStep + 1,
      })
    }
  }

  handleNext2 = () => {
    const { targetAudience, market } = this.state;
    if (!targetAudience) {
      this.setState({
        targetAudienceError: '* Please select any one target audience.'
      })
    } else if (!market) {
      this.setState({
        marketError: '* Please select any one target Market.'
      })
    }
    else {
      this.setState({
        marketError: '',
        targetAudienceError: '',
        activeStep: this.state.activeStep + 1
      })
    }
  }

  handleNext3 = () => {
    const { imagesvideoPrototype, legalDocuments, imagesvideoPrototypeFile, legalDocumentsFile, imagesvideoPrototype_publicOrPrivate, legalDocuments_publicOrPrivate} = this.state;
    if (!imagesvideoPrototype) {
      this.setState({
        imagesvideoPrototypeError: '* This is required'
      })
    }
    else if (imagesvideoPrototype === 'Yes' && !imagesvideoPrototypeFile) {
      this.setState({
        error: {
          imagesvideoPrototypeFile: '* Please upload any File.'
        }
      })
    }
    else if (!imagesvideoPrototype_publicOrPrivate) {
      this.setState({
        imagesvideoError: '* This is required'
      })
    }
    else if (!legalDocuments) {
      this.setState({
        legalDocumentsPrototypeError: '* This is required'
      })
    }
    else if (legalDocuments === 'Yes' && !legalDocumentsFile) {
      this.setState({
        error: {
          legalDocumentsFile: '* Please upload any File.'
        }
      })
    }
    else if (!legalDocuments_publicOrPrivate) {
      this.setState({
        legalDocumentsError: '* This is required'
      })
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          legalDocumentsFile: '',
          imagesvideoPrototypeFile: ''
        }
      })
    }
  }

  handleNext4 = () => {
    const { awards, governmentFile, government,industryFile,industry, academic,academicFile } = this.state;
    if (!awards) {
      this.setState({
        awardsRadioError: '* This field is required.'
      });
    }
    else if (awards === 'Yes') {
      if (!governmentFile && !industryFile && !academicFile) {
        this.setState({
          awardsError: '* Please upload any File.'
        });
      } if (!government && !industry && !academic) {
        this.setState({
          awardsLinkError: '* Please enter any Award Link.'
        });
      } else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          awardsError: '',
          awardsLinkError:''
        });
      }
    }
     else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        awardsRadioError: '',
        awardsError: ''
      });
    }
  }

  handleNext5 = () => {
    const { funding, document, Amount, publicOrPrivate } = this.state;
    if (!funding) {
      this.setState({
        fundingError: '* This field is required.'
      })
    }
    else if (funding === 'Yes') {
      if (!document) {
        this.setState({
          error: {
            document: '* Please Upload Document File.'
          }
        })
      }
      else if (!Amount) {
        this.setState({
          error: {
            Amount: '* Please Enter Amount.',
          }
        })
      }
      else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          error: {
            ...this.state.error,
            Amount: '',
            document: ''
          }
        })
      }
    }
    else if (!publicOrPrivate) {
      this.setState({
        publicStep5Error: '* This field is required'
      })
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          Amount: '',
          document: ''
        }
      })
    }
  }

  handleNext6 = () => {
    const { price, fees, } = this.state;
    if (!price) {
      this.setState({
        error: {
          price: '* Please Enter Price.',
        }
      })
    }
    else if (!fees) {
      this.setState({
        error: {
          fees: '* Please Enter Fees.'
        }
      })
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          price: '',
          fees: ''
        }
      })
    }
  }

  handleNext7 = () => {
    const { bankDetails, accountNumber, accountDoc } = this.state;
    if (!bankDetails) {
      this.setState({
        bankDetailsError: '* This is required.'
      })
    }
    else if (bankDetails === 'Now') {
      if (!accountNumber) {
        this.setState({
          error: {
            accountNumber: '* Please Enter AccountNumber'
          }
        })
      }
      else if (!accountDoc) {
        this.setState({
          error: {
            accountDoc: '* Please upload any File.',
          }
        })
      }
      else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          error: {
            ...this.state.error,
            accountDoc: '',
            accountNumber: ''
          }
        })
      }
    }
    else if (bankDetails === 'Later') {
      this.setState({
        accountDoc: '',
        accountNumber: '',
        activeStep: this.state.activeStep + 1,
        error: {
          accountDoc: '',
          accountNumber: ''
        }
      })
    }
  }

  handleNext8 = () => {
    const { qualification, researchPapers, attachLink } = this.state;
    if (!qualification) {
      this.setState({
        qualificationError: '* Please select Qualification'
      })
    }
    else if(!researchPapers){
      this.setState({
        researchPapersError: 'This field is required'
      })
    }
    else if (researchPapers === 'Yes' && !attachLink) {
      this.setState({
        error: {
          attachLink: '* Please Add Research Paper Link.',
        }
      })
    }
    else  if(this.state.error.attachLink){
      this.setState({
        error: {
          attachLink: '* Invalid Website Link Formate.',
        }
      })
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          attachLink: '',
        }
      })
    }
  }

  handleNext9 = () => {
    const { companyOruniversity, otherInfo,patent } = this.state;
   if(!patent){
    this.setState({
      patentError:'* This field is required'
    })
   }
    else if (!companyOruniversity) {
      this.setState({
        error: {
          companyOruniversity: '* Please Enter company Or university.',
        }
      })
    }
    else if (!otherInfo) {
      this.setState({
        error: {
          otherInfo: '* Please Enter other Information.'
        }
      })
    }
    else {
      this.setState({
        error: {
          ...this.state.error,
          companyOruniversity: '',
          otherInfo: ''
        }
      })
      this.patentHolderDetails();
    }
  }

  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } else {
      this.otpVerificationForEmail()
    }
  }

  displayTime() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }

  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    this.sendEmailOtp();
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000);
  };

  handleLinkChange = (event: any) => {
    const websiteRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!websiteRegex.test(event.target.value)) {
      this.setState({
        attachLink: event.target.value,
        error: {
          ...this.state.error,
          attachLink: "Invalid Website Link Formate"
        }
      });
    }
    else {
      this.setState({
        attachLink: event.target.value,
        error: {
          ...this.state.error,
          attachLink: ''
        }
      });
    }
  };
  
  handleExpFieldChange = (event: any) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
  
    const numberFields = ["fees", "price", "Amount"]; 
  
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,7}(\.\d{0,2})?$/; 
      if (regexExp.test(value) || value === "") { 
        delete updatedError[name];
        this.setState({
          [name]: value,
          error: updatedError
        } as Pick<S, keyof S>);
      } else {
        updatedError[name] = "* Allowed up to 7 digits with an optional decimal (up to 2 places)";
        this.setState({ error: updatedError });
      }
    }
  };
  
  handleAccountNumberChange = (event: any) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    
    if (name === "accountNumber") {
      const isValidAccountNumber = /^\d{0,16}$/.test(value);
    
      if (isValidAccountNumber || value === "") {
        delete updatedError[name];
        this.setState({
          [name]: value,
          error: updatedError
        } as Pick<S, keyof S>);
      } else {
        updatedError[name] = "* Account number must be up to 16 digits.";
        this.setState({ error: updatedError });
      }
    }
  };
  
  
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value,
      error: {},
      awardsError: ''
    } as unknown) as Pick<S, keyof S>);
  };
  

  handleChange2 = (event: any) => {
    const { name, value } = event.target;
    const alphaRegex = /^[A-Za-z\s]*$/; 
  
    if (alphaRegex.test(value)) {
      this.setState({
        [name]: value,
        error: {}, 
        awardsError: '' 
      } as Pick<S, keyof S>);
    }
  };
  

  handleChangeAutocomplete = (event: any, value: any) => {
    this.setState({
      category: value,
      categoryError: ''
    });
  }

  handleChangeQualification = (event: any, value: any) => {
    this.setState({
      qualification: value,
      qualificationError: ''
    });
  }

  handleFileUpload = (event: any) => {
    const { files } = event.target;
    const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
    const stateKeyRef = event.target.name;
    const selectedFile = files[0];
    const maxSizeMB = 5;
    let error = "";

    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);

      if (!allowedExtensions.test(selectedFile.name)) {
        error = '* Invalid file type. Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.';
      } else if (fileSizeMB > maxSizeMB) {
        error = "* File size exceeds the maximum limit of 5 MB.";
      }

      // Set state only if there is no error
      if (!error) {
        this.setState(({
          [stateKeyRef]: selectedFile,
          error: {
            ...this.state.error,
            [stateKeyRef]: ""
          }
        } as unknown) as Pick<S, keyof S>);
      } else {
        this.setState(({
          error: {
            ...this.state.error,
            [stateKeyRef]: error
          }
        } as unknown) as Pick<S, keyof S>);
      }
    } else {
      // Reset file and error if no file selected
      this.setState(({
        [stateKeyRef]: null,
        error: {
          ...this.state.error,
          [stateKeyRef]: error
        }
      } as unknown) as Pick<S, keyof S>);
    }
  };

  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
    removeStorageData("firstName");
    removeStorageData("lastName");
    removeStorageData("password");
    removeStorageData("email");
    removeStorageData("country");
    removeStorageData("job");
  }

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.patentHolderById) {
        this.setState({ disableBtn: false })
        if (responseJson.data) {
          this.sendEmailOtp();
        }
        else if (responseJson.errors) {
          this.setState({ emailValid: responseJson.errors[0].account })
        }
      }
      else if (apiRequestCallId === this.sendEmailOtpCallId) {
        if (responseJson) {
          this.setState({ authToken: responseJson?.meta?.token, verfication: true });
          this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
              this.setState(({ seconds }) => ({
                seconds: seconds - 1
              }));
            } else {
              clearInterval(this.myInterval);
              this.setState({ timerExpired: true, disabledResendOtp: false, seconds: 60 })
            }
          }, 1000);
        }
      } else if (apiRequestCallId === this.otpVerificationForEmailCallId) {
        if (responseJson.errors) {
          this.setState({ otpError: responseJson.errors[0].otp })
        } else {
          this.setState({ successPage: true })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  patentHolderDetails = async () => {
    this.setState({ disableBtn: true })
    const header = {
      "Content-Type": "application/json"
    };
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": await getStorageData("email"),
          "password": await getStorageData("password"),
          "first_name": await getStorageData("firstName"),
          "last_name": await getStorageData("lastName"),
          "role_id": "freelancer"
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patentHolderById = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/sign_up");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendEmailOtp = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    let emailData = {
      "data": {
        "attributes": {
          "email": await getStorageData("email")
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sendEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/otps/email_otp");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(emailData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otpVerificationForEmail = () => {
    const header = {
      "Content-Type": "application/json"
    };

    let OtpData = {
      "data": {
        "token": this.state.authToken,
        "otp_code": this.state.otpValue
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otpVerificationForEmailCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/account_confirm");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(OtpData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
