export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteIcon = require("../assets/delete.png");



export const blankchat = require("../assets/blankchat.png");
export const img1 = require("../assets/img1.png");
export const img2 = require("../assets/img2.png");
export const sendmsg = require("../assets/sendmsg.png");
export const searchIcon = require("../assets/searchIcon.png")