// Customizable Area Start
import { Message } from 'framework/src/Message';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
// Customizable Area End

// Customizable Area Start
export const callApi = (payload: any, runEngine: any) => {
  const { contentType, method, endPoint, body, headers } = payload;
  const header = {
    'Content-Type': contentType,
    ...headers
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

// Customizable Area End
