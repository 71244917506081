// Customizable Area Start
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FullWidthTabController from "./FullWidthTabController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavBar from "../../blocks/landingpage/src/NavBar.web";
import FooterWeb from "../../blocks/dashboard/src/Components/Footer.web";
import GlobalSearchTalent from "./GlobalSearchTalent.web";
import GlobalSeachJobs from "./GlobalSearchJobs.web";
import {Box,InputAdornment, TextField, Typography,styled } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2570AD"
    }
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: '4px', 
        width:'104px !important'
      }
    }
  }
});
const CustomTabs = styled(Tabs)({
  '& .MuiTab-textColorPrimary.Mui-selected': {
     fontWeight:"700",
     fontSize:"18px",
     textTransform: "capitalize"
  },
  '& .MuiTab-textColorPrimary': {
    fontWeight:"700",
    fontSize:"18px",
    textTransform: "capitalize"
 },
 '& .MuiTab-wrapper':{
  width:"100%",
  display:"inline-flex",
  flexDirection: "unset",
  justifyContent: "unset"
 }
  
})

// Customizable Area End

export default class FullWidthTab extends FullWidthTabController {
// Customizable Area Start
  render() {
    const {talentResults, jobResults } = this.state;

    return (
      <>
         <NavBar {...this.props} />
        <Box style={{width:"50%", padding:"3% 3%"}}>
          <Typography style={webStyles.searchText}>
            Search
          </Typography>
          <Box style={webStyles.searchContainer}>
            <TextField
              id="search"
              variant="outlined"
              data-testid="search"
              size="small"
              fullWidth
              autoComplete="off"
              value={this.state.search}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
                style: { borderRadius: "13px" },
              }}
            />
          </Box>
        </Box>
        
        <Box style={{padding:"0% 3%"}}>
        <ThemeProvider theme={theme}>
          <CustomTabs
            value={this.state.searchSelect}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            style={{borderBottom:"1px solid 1px solid rgba(0, 0, 0, 0.23)"}}
          >
            <Tab label="IP Talent" />
            <Tab label="IP Jobs" />
          </CustomTabs>
        </ThemeProvider>
        {this.state.searchSelect === 0 && <GlobalSearchTalent navigation={this.props.navigation} results={talentResults} handlePageChange={this.handlePageChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} />}
          {this.state.searchSelect === 1 && <GlobalSeachJobs navigation={this.props.navigation} results={jobResults} handlePageChange={this.handlePageChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages}  />}
        </Box>
        <FooterWeb />
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const webStyles = {
    searchContainer: {
        display: 'flex',
        gap: '10px',
        margin: '10px 0'
    },
    searchText: {
        fontSize: '2rem',
        fontWeight: 500,
        fontFamily:'Rubik'
    },
    recentField: {
        width: '40%',
    },
};
// Customizable Area End