import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  numberOfMileStone: number;
  startDate: string;
  checked: boolean;
  error: boolean;
  count: number;
  addField: boolean;
  inputList: {
    description: string,
    amount: string,
    date: string,
    status: string,
    post_id: number,
  }[];
  webDesign: string;
  backendTech: string;
  figma: string;
  fullTimeWork: string;
  quality: string;
  greeting: string;
  postId:string;
  Id:string;
  freelancerDetails:{
    description: string,
    hour_rate: number
    post_attributes: {
        id: number,
        location: string,
        skills: string[]
    },
    account_attributes: {
      id: number,
      description: string,
      user_name: string,
      freelancer_status: boolean,
      designation: string,
      location: string,
      education: string,
      aadhar_img: {
          url: null
      },
      skills: string[],
  }
  },
  authToken:string;
  acountHolderName:string;
  statusOption:{
    value:number,
    option:string
  }[];
  responseMessage: boolean;
  popUpMessage: boolean;
  // Customizable Area End
}
interface SS {}

export default class HireController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  displayFreelancerDetailsID : string;
  addMilestoneID : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.displayFreelancerDetailsID = "";
    this.addMilestoneID = "";
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      numberOfMileStone:0,
      startDate: "",
      checked: false,
      error: false,
      count: 1,
      addField: false,
      inputList: [],
      webDesign:'Html5/SASS/Bootstrap/Tailwind/Javascript/Responsive web design friendly',
      backendTech:'WordPress, React, Next, Vue, PHP, Laravel, Node.js.',
      figma:'Figma, PSD to HTML, landing page, jQuery.',
      fullTimeWork: 'As for me, now I am ready to start your job and work full-time if possible.',
      quality:'It wont make you disappointed with the lack of quality and punctuality.',
      greeting:'I wish to have a message from you.',
      postId: '',
      Id: '',
      freelancerDetails:{
        description: '',
        hour_rate: 0,
        post_attributes: {
            id: 0,
            location: '',
            skills: [],
        },
        account_attributes: {
          id: 0,
          description: '',
          user_name: '',
          freelancer_status: false,
          designation: '',
          education: '',
          aadhar_img: {
              url: null
          },
          skills: [],
          location:''
      }
      },
      authToken:'',
      acountHolderName:'',
      statusOption:[
        {
          value:0,
          option:"Ongoing"
        },
        {
          value:1,
          option:"Completed",
          
        },
        {
          value:2,
          option:"Pending"
        },
        {
          value:3,
          option:"Accept"
        },
        {
          value:4,
          option:"Rejected"
        }
      ],
      responseMessage: false,
      popUpMessage: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
      acountHolderName: await storage.get("accountHolderName"),
      postId: await storage.get('post_id'),
      Id : await storage.get('proposal_id'),
    })
    this.handleAdd();
    this.getFreelancerDetails();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.displayFreelancerDetailsID === requestCallId ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
          this.setState({
            freelancerDetails: responseJson.data?.attributes
          })
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      if(this.addMilestoneID === requestCallId){
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          this.setState({
            responseMessage: true,
            popUpMessage: true
          })
        } else {
          this.setState({
            responseMessage: false,
            popUpMessage: true
          })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getFreelancerDetails = () => {
     const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.contentType,
      "token": this.state.authToken
    };
    this.displayFreelancerDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      webConfigJSON.apiEndPointGetFreelancerData + `${this.state.postId}` + webConfigJSON.remainingEndPoint + `${this.state.Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getDetailsMethodeType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddMilstone = () => {
    const milestoneMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   const header = {
     "Content-Type": webConfigJSON.contentType,
     "token": this.state.authToken
   };
   const body = {
    "term": {
      "proposal_id": this.state.Id,
      "start_date": this.state.startDate, 
      "milestone": this.state.numberOfMileStone,
      "milestones_attributes": this.state.inputList
  }
  };
   this.addMilestoneID = milestoneMessage.messageId;
   milestoneMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage), 
     webConfigJSON.apiEndPointAddMilestone
   );
   milestoneMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   milestoneMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  )
  milestoneMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     webConfigJSON.addMilestoneMethodeType
   );
   runEngine.sendMessage(milestoneMessage.id, milestoneMessage);
 }

  handleNumberMilestone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value); 
    this.setState({
      numberOfMileStone: value,
      error: false
    });
  }
  handleStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startDate: event.target.value })
  }
  handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked })
  }
  handleNext = () => {
    if (!this.state.inputList || !this.state.numberOfMileStone) {
      this.setState({ error: true })
    } else {
      this.setState({ error: false })
      this.handleAddMilstone()
    }
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list })
  };
  handleAdd = async() => {
    this.setState({ inputList: [...this.state.inputList, { description: "", amount: "", date: "", status: "", post_id: await storage.get('post_id')}] })
  };
  handleDialog = async() => {
    this.setState({
      popUpMessage: !this.state.popUpMessage,
      inputList: [{ description: "", amount: "", date: "", status: "", post_id: await storage.get('post_id')}] 
    })
  }
  handleHireBtn = () =>{
      window.location.href=`/chatInbox/${this.state.freelancerDetails.account_attributes.id}/${this.state.freelancerDetails.account_attributes.user_name}`
  }
  handleSavedBtn=()=>{
    this.props.navigation.navigate("SavedTalent");

  }
  // Customizable Area End
}
