import React from "react";
//Customizable Area Start
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import { TableContainer,RightBox } from "../../../components/src/ActiveStep0.web";
import Pagination from "@material-ui/lab/Pagination";

//Customizable Area End
export default class FreelanceActive extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }
  render() {
//Customizable Area Start
 interface ProposalData
  {
      id: string,
      type: string,
      attributes: {
          id: number,
          post_id: number,
          created_at: string,
          updated_at: string,
          accepted_at: null,
          rejected_at: null,
          post: {
              data: {
                  id: string,
                  type: string,
                  attributes: {
                      id: number,
                      name: string,
                      description: string,
                      created_at:string,
                      updated_at: string,
                      posted_job: number
                  }
              }
          },
          days_ago: string,
      }
  }

const { activeProposalData } = this.state;
const activeProposals :ProposalData[]= activeProposalData?.data?.attributes?.active_proposals?.active_proposals?.data ?? [];
const { activeProposalCurrentPage, activeProposalTotalPages } = this.state;
if (!activeProposalData) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}
//Customizable Area End
    return (
//Customizable Area Start
<>
<RightBox>
        {activeProposals.length > 0 ? (
          activeProposals.length>0&&activeProposals.map((milestoneData: any, index: number) => (
          <TableContainer key={index}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <div>
                    <Typography style={{ ...webStyle.date, fontWeight: 700 }}>
                      Received {milestoneData.attributes.post.data.attributes.created_at}
                    </Typography>
                    <Typography style={webStyle.date}>{milestoneData?.attributes?.created_on}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} style={webStyle.jobname}>
                  {milestoneData.attributes.post.data.attributes.name}
                </Grid>
                <Grid item xs={12} sm={4} style={webStyle.tableContent}>
                  <Typography style={webStyle.startDateText}>
                    Visit Page
                  </Typography>
                </Grid>
              </Grid>
            </TableContainer>
          ))
        ) : (
          <Typography style={webStyle.noProfile}>
            No active data found
          </Typography>
        )}
      </RightBox>
      {activeProposals.length > 0 && (
      <Box style={webStyle.paginationContainer}>
        <Pagination
          count={activeProposalTotalPages}
          page={activeProposalCurrentPage}
          onChange={this.handleActiveProposalPageChange}
          color="primary"
        />
        </Box>
      )}
                  </>
//Customizable Area End
    );
  }
}

//Customizable Area Start
const webStyle = {
  tableContent: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  date: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex" as "flex",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
    textAlign: "center" as "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
noProfile:{
  fontSize:'1.5rem',
  paddingTop:'5%',
  textAlign:'center' as 'center',
  color:'grey'
}
};

//Customizable Area End