import React from 'react';
// Customizable Area Start
import {
  Box,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  TextareaAutosize,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { uploadIcon5 } from '../../blocks/dashboard/src/assets';
import FormGroup from '@material-ui/core/FormGroup';
import PrototypeDeveloperController, {
  Props
} from './PrototypeDeveloperController.web';
import { FileUploadArea, autoCompleteSvgIcon, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';

// Customizable Area End
// Customizable Area Start
export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>,
      '5': <Box>5</Box>,
      '6': <Box>6</Box>,
      '7': <Box>7</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#FF2F50' : '#FFE6EA',
          color: active || completed ? '#FFF' : '#FF2F50'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
// Customizable Area End
export default class PrortotypeDeveloper extends PrototypeDeveloperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div style={{ display: 'inline-block' }}>
      <Popper
        anchorEl={this.state.anchorElement}
        placement={'bottom'}
        open={this.state.openInfoPopper}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: this.state.anchorElement
          }
        }}
        style={{
          background: '#FFF',
          width: '95%',
          borderRadius: '10px',
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography
                style={{
                  ...webStyle.header,
                  fontSize: '18px',
                  fontWeight: 400,
                  color: '#4C4C4C',
                  padding: '10px 20px',
                  lineHeight: '35px',
                  letterSpacing: '0.27',
                }}
              >
                {content}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );

  step1 = () => {
    const options = ['3Years', '5Years'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What are your qualifications?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="qualifications"
            name="qualifications"
            value={this.state.qualifications}
            onChange={this.handleChange}
            data-testid="PD1-qualifications"          
          >
            <FormControlLabel
              value="Law"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Law"
            />
            <FormControlLabel
              value="Engineering"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Engineering"
            />
            <FormControlLabel
              value="Marketing"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Marketing"
            />
            <FormControlLabel
              value="Other"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Other"
            />
          </RadioGroup>
          {!this.state.qualifications && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['qualifications']}</Typography>}

          <Typography style={webStyle.header}>
            No.of years as a patent licensing expert?
          </Typography>
          <Autocomplete
            key="Select Years"
            data-testid="PD1-patentLicensing"
            options={options}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Years"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Are you an individual or a firm?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="type"
            name="type"
            value={this.state.type}
            onChange={this.handleChange}
            data-testid="PLE1-type"
          >
            <FormControlLabel
              value="Individual"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Individual"
            />

            <FormControlLabel
              value="Firm"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Firm"
            />

          </RadioGroup>
          {!this.state.type && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['type']}</Typography>}

        </Box>
      </Box>
    );
  };
  step2 = () => {
    const country = ['India', 'America'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What is your target market?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="market"
            name="market"
            value={this.state.market}
            onChange={this.handleChange}
            data-testid="PLE2-market"
          >
            <FormControlLabel
              value="CountryWide"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="CountryWide"
            />
            <FormControlLabel
              value="WorldWide"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="WorldWide"
            />
          </RadioGroup>
          {!this.state.market && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['market']}</Typography>}

          <Typography style={webStyle.header}>Country of Operation</Typography>
          <Autocomplete
            key="Select Country"
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Country"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>City of Operation</Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select City"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Which country's patent law guidelines are your familiar with?
          </Typography>
          <FormGroup
            data-testid="PD-Other"
            onChange={this.handleChangecountryLawGuidelines}
          >
            {[
              'USPTO',
              'Indian',
              'Chinese',
              'European Union',
              'Japanese',
              'Other'
            ].map((value: any,index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={value}
                    key={index}
                    style={
                      this.state.countryLawGuidelines.indexOf(value) > -1
                        ? { color: '#FF2F50' }
                        : { color: '#FFE6EA' }
                    }
                  />
                }
                label={value}
              />
            ))}
          </FormGroup>
          {!this.state.countryLawGuidelines && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['countryLawGuidelines']}</Typography>}

          <Typography style={webStyle.header}>
            Focus area or industry of expertise as a patent licensing agent
          </Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Area"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Expertise of material using for prototyping?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="materialUsingPrototyping"
            name="materialUsingPrototyping"
            value={this.state.materialUsingPrototyping}
            onChange={this.handleChange}
            data-testid="PLE2-materialUsingPrototyping"
          >
            <FormControlLabel
              value="Wood"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Wood"
            />
            <FormControlLabel
              value="Metal"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Metal"
            />
            <FormControlLabel
              value="Other"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Other"
            />
          </RadioGroup>
          {!this.state.materialUsingPrototyping && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['materialUsingPrototyping']}</Typography>}

          <Typography style={webStyle.header}>
            What are the prototyping equipment you are familiar with?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="prototypingEquipment"
            name="prototypingEquipment"
            value={this.state.prototypingEquipment}
            onChange={this.handleChange}
            data-testid="PLE2-prototypingEquipment"
          >
            {['3D Developer', 'CNC Machines', 'Other'].map((item: any) => (
              <FormControlLabel
                value={item}
                control={<Radio style={{ color: '#FF2F50' }} />}
                label={item}
              />
            ))}
          </RadioGroup>
          {!this.state.prototypingEquipment && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['prototypingEquipment']}</Typography>}
          <Typography style={webStyle.header}>
            What design software are you familiar with?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="designSoftware"
            name="designSoftware"
            value={this.state.designSoftware}
            onChange={this.handleChange}
            data-testid="PLE2-market"
          >
            {['Solidworks', 'Autodesk', 'Other'].map((value: any) => (
              <FormControlLabel
                value={value}
                control={<Radio style={{ color: '#FF2F50' }} />}
                label={value}
              />
            ))}
          </RadioGroup>
          {!this.state.designSoftware && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['designSoftware']}</Typography>}
        </Box>
      </Box>
    );
  };
  step3 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        style={{ marginBottom: '450px' }}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Fees</Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400,marginLeft: '5px' }}
            >
              (Per day/per assignment)<span style={webStyle.importantField}>*</span>
            </Typography>
          </Box>
          <TextField
            data-testid="PLE3-fees"
            placeholder="Enter fees"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.fees}
            name="fees"
            onChange={this.handleFeesFieldChange}
            style={webStyle.inputField}
            error={this.state.numberError['fees']?true:false}
            helperText={this.state.numberError['fees']}
          />
        </Box>
      </Box>
    );
  };
  step4 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            Have you been featured in the media?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="media"
            name="media"
            value={this.state.media}
            onChange={this.handleRadioChange}
            data-testid="PLE4-media"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.media && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['media']}</Typography>}

          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link</Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400,marginLeft: '5px' }}
            >
              (5 each){this.state.media == 'Yes'&& <span style={webStyle.importantField}>*</span>}
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link1"
            placeholder="Enter link"
            disabled={this.state.media === 'No'}
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link1}
            name="link1"
            style={webStyle.inputField}
            onChange={this.handleLinkField}
            error={this.state.error['link1']}
            helperText={this.state.error['link1']}
          />
           <Typography style={webStyle.header}>
            Have you spoken at IP conferences?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label=" ipConferences"
            name="ipConferences"
            value={this.state.ipConferences}
            onChange={this.handleRadioChange}
            data-testid="PLE4-ipConferences"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.ipConferences && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['ipConferences']}</Typography>}

          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link </Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400 ,marginLeft: '5px'}}
            >
              (5 each){this.state.ipConferences == 'Yes'&& <span style={webStyle.importantField}>*</span>}
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link2"
            placeholder="Enter link"
            disabled={this.state.ipConferences === 'No'}
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link2}
            name="link2"
            onChange={this.handleLinkField}
            error={this.state.error['link2']}
            helperText={this.state.error['link2']}
            style={webStyle.inputField}
          />
              <Typography style={webStyle.header}>
            Have you published any articles?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="artcles"
            name="artcles"
            value={this.state.artcles}
            onChange={this.handleRadioChange}
            data-testid="PLE4-artcles"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.artcles && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['artcles']}</Typography>}
          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link  </Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400 ,marginLeft: '5px' }}
            >
              (5 each){this.state.artcles == 'Yes'&& <span style={webStyle.importantField}>*</span>}
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link3"
            placeholder="Enter link"
            disabled={this.state.artcles === 'No'}
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link3}
            name="link3"
            onChange={this.handleLinkField}
            error={this.state.error['link3']}
            helperText={this.state.error['link3']}
            style={webStyle.inputField}
          />
                   </Box>
      </Box>
    );
  };
  step5 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>Awards<span style={webStyle.importantField}>*</span></Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="awards"
            name="awards"
            value={this.state.awards}
            onChange={this.handleRadioChange}
            data-testid="PLE5-awards"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#FF2F50' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.awards && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['awards']}</Typography>}
          <Typography style={webStyle.header}>Government{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-government"
            placeholder="Enter link"
            disabled={(this.state.awards === 'No')}
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.government}
            name="government"
            onChange={this.handleLinkField}
            error={this.state.error['government']}
            helperText={this.state.error['government']}
            style={webStyle.inputField}
          />
          <FileUploadArea
            id="ProrotypeDeveloper-step-5-fileInputgovernment"
            disabled={(this.state.awards === 'No')}
            btnContent={
              currentStateData['step5GovernmentFileUplod']
                ? currentStateData['step5GovernmentFileUplod']?.name
                :'Upload certificate'}
                onChange={this.handleFileUpload}
                stateKeyRef={"step5GovernmentFileUplod"}
            testId={'ProrotypeDeveloper-step-5-fileInputgovernment'}
            uploadIcon={uploadIcon5}
            uploadTextColor={'#FF2F50'}
            border={'1px dashed #FF2F50'}
            backgroundColor={"lightGray"}
          />
          {!this.state.step5GovernmentFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5GovernmentFileUplod']}</Typography>}

          <Typography style={webStyle.header}>Industry{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-Industry"
            placeholder="Enter link"
            disabled={(this.state.awards === 'No')}
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.industry}
            name="industry"
            onChange={this.handleLinkField}
            error={this.state.error['industry']}
            helperText={this.state.error['industry']}
            style={webStyle.inputField}
          />
          <FileUploadArea
            id="ProrotypeDeveloper-step-5-fileInputindustry"
            disabled={(this.state.awards === 'No')}
            btnContent={
              currentStateData['step5IndustryFileUplod']
                ? currentStateData['step5IndustryFileUplod']?.name
                :'Upload certificate'}
            testId={'ProrotypeDeveloper-step-5-fileInputindustry'}
            uploadIcon={uploadIcon5}
            uploadTextColor={'#FF2F50'}
            onChange={this.handleFileUpload}
            stateKeyRef={"step5IndustryFileUplod"}
            border={'1px dashed #FF2F50'}
            backgroundColor={"lightGray"}
          />
           {!this.state.step5IndustryFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5IndustryFileUplod']}</Typography>}
          <Typography style={webStyle.header}>Academic{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-academic"
            disabled={(this.state.awards === 'No')}
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.academic}
            name="academic"
            onChange={this.handleLinkField}
            error={this.state.error['academic']}
            helperText={this.state.error['academic']}
            style={webStyle.inputField}
          />
          <FileUploadArea
            id="ProrotypeDeveloper-step-5-fileInputacademic"
            disabled={(this.state.awards === 'No')}
            btnContent={
              currentStateData['step5AcademicFileUplod']
                ? currentStateData['step5AcademicFileUplod']?.name
                :'Upload certificate'}
            testId={'ProrotypeDeveloper-step-5-fileInputacademic'}
            uploadIcon={uploadIcon5}
            uploadTextColor={'#FF2F50'}
            onChange={this.handleFileUpload}
            stateKeyRef={"step5AcademicFileUplod"}
            border={'1px dashed #FF2F50'}
            backgroundColor={"lightGray"}
          />
         {!this.state.step5AcademicFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5AcademicFileUplod']}</Typography>}
        </Box>
      </Box>
    );
  };
  step6 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          flex={0.6}
          gridRowGap={'30px'}
        >
          <Typography style={webStyle.header}>
            Prototypes created for companies
          </Typography>
          {this.state.prototypesCreatedForCompanies.map(
            (item: any, index: any) => {
              return (
                <TextField
                  data-testid={`PLE6-CompanyPrototype${index}`}
                  placeholder={`Prototype ${index + 1}`}
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '60%'
                    }
                  }}
                  variant="outlined"
                  value={item}
                  name={'CompanyPrototype'}
                  onChange={(event: any) =>
                    this.handleChangeCompanyPrototypes(
                      event.target.value,
                      index
                    )
                  }
                  style={webStyle.inputField}
                />
              );
            }
          )}
          <Button
            variant="contained"
            style={webStyle.addMoreButton}
            onClick={() => this.handleAddMorePrototypeCompanies()}
            data-testid="PA-next"
          >
            {'+ Add more'}
          </Button>
        </Box>
      </Box>
    );
  };
  step7 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            Any other information?
          </Typography>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={10}
            placeholder="Add any other information you wish to convey here"
            style={{
              borderColor: '#979797',
              borderRadius: '10px',
              fontFamily: 'Inter',
              paddingLeft: '20px',
              paddingTop: '20px',
              height: '130px',
              width: '550px',
              marginBottom: '90px'
            }}
            value={this.state.otherInformation}
            name="otherInformation"
            onChange={this.handleOtherInfoField}
          />
        </Box>
      </Box>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      case 4:
        return <Box>{this.step5()}</Box>;
      case 5:
        return <Box>{this.step6()}</Box>;
      case 6:
        return <Box>{this.step7()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3, 4, 5, 6];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Box padding={'3%'}>
          <Box style={webStyle.outerContainer}>
          <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>

            <Box style={webStyle.root}>
              {/* stepper component starts */}
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: React.ReactNode } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
          {this.getStepComponent()}
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              variant="contained"
              color="default"
              data-testid="PD-back"
              onClick={() =>
                this.state.activeStep === 0
                    ? this.handleredirection(
                      'ProfessionalAccountRegistration'
                    )
                    : this.handleBack()
              }
              style={{
                border: '1px solid #FF2F50',
                color: '#FF2F50',
                width: '130px',
                height: '40px',
                flexShrink: 0,
                backgroundColor: 'white',
                borderRadius: '13px'
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              onClick={() =>
                this.state.activeStep === 6
                  ? this.handleredirection('ProfessionalAccountRegistration')
                  : this.handleNext()
              }
              data-testid="PD-next"
            >
              {this.state.activeStep === 6 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '40%',
    flex:"0.7"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  invento: {
    width:"250px",
    height:"61px",
    objectFit:"cover",
     marginTop: "25px",
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '20px',
    marginTop: '30px'
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },

  UploadButton: {
    height: '165px',
    width: '751px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFE6EA',
    border: '1px dashed #FF2F50',
    borderRadius: '12px'
  },

  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '130px',
    height: '40px',
    flexShrink: 0,
    borderRadius: '13px',
    color: '#FFF',
    backgroundColor: '#FF2F50'
  },
  addMoreButton: {
    flexShrink: 0,
    borderRadius: '13px',
    color: '#FF2F50',
    backgroundColor: 'lightgrey',
    border: '1px solid #FF2F50',
    justifyContent: 'flex-start',
    width: '60%'
  },
  importantField:{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FF5E5B"
  }
};
// Customizable Area End
