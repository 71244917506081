import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "framework/src/StorageProvider";

interface JobListData
{
  
            "id": string,
            "type": string,
            "attributes": {
                "id": string,
                "name": string,
                "description": string,
                "body": string,
                "job_title": string,
                "job_description": string,
                "per_day_charge": number,
                "budget": string,
                "duration": string,
                "employment_term": string,
                "estimate": string,
                "skills": [
                  string
                ],
                "location": string,
                "file": {
                    "url": string | null
                },
                "account_id": number,
                "created_at": string,
                "updated_at":string,
                "proposals": number,
                "messaged": number,
                "hired": number,
                "is_drafted": boolean,
                "model_name": string,
                "images_and_videos": any[]
            }
      
}
interface DraftListData
{
  "id": string,
  "type": string,
  "attributes": {
    "id": string,
    "name": string,
    "description": string,
    "job_title": string,
    "body": string,
    "job_description": string,
    "budget": string,
    "duration": string,
    "per_day_charge": number,
    "estimate": string,
    "location": string,
    "skills": [
      string
    ],
    "employment_term": string,
    "file": {
        "url": string | null
    },
    "images_and_videos": any[]
    "is_drafted": boolean,
    "account_id": number,
    "hired": number,
    "proposals": number,
    "created_at": string,
    "updated_at":string,
      "messaged": number,
      "model_name": string,
      "drafted_at": string
  }

}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  firstName: string,
  anchorEl: null | HTMLElement;
  loading: boolean;
  jobTitle: string, 
  errorMsg: string;
  savedDays: string;
  dayCharge: string,
  skillLable:any[],
  authToken: string,
  jobListData: JobListData []|[]
  draftListData :DraftListData []|[]
  // Customizable Area End
}
interface SS { }

export default class JobPostingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSearchSkillDataId: string="";
  listOfJobId : string="";
  listOfDraftId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      loading: false,
      firstName:String(localStorage.getItem("accountHolderName")),
      savedDays: 'Saved 9 Days ago',
      anchorEl: null,
      skillLable:[],
      dayCharge: "",
      errorMsg: "",
      jobTitle: localStorage.getItem("jobTitle") || "", 
      authToken: "",
      jobListData: [],
      draftListData:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.getJobsAppliedExecutiveData()
    this.getAllDraftedData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
        );
       
       if (webResponseJson ) {
         if (webApiRequestCallId===this.apiSearchSkillDataId){          
          this.setState({skillLable:webResponseJson.skills})
        }
        else  if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
       else if(this.listOfJobId === webApiRequestCallId){
          this.setState({jobListData:webResponseJson.posts.data})
        }
        else if(this.listOfDraftId === webApiRequestCallId){
          this.setState({draftListData:webResponseJson.drafts.data})
        }
        
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  StoreDataClear() {
    localStorage.removeItem("dayCharge");
    localStorage.removeItem("jobTitle");
    this.setState({
      dayCharge:"",
        jobTitle: ""
    });
}
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handlePostJob = () => {
      this.StoreDataClear();
      this.props?.navigation?.navigate("CustomizedSteppers");
    };
  
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleAllPost = ()=>{
    this.props.navigation.navigate("FullWidthJobsTab");
  }
  
  getJobsAppliedExecutiveData = async () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listOfJobId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/search_jobs"
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getAllDraftedData = async () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listOfDraftId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/search_draft_jobs"
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
