// Customizable Area Start
import React from 'react';
import { Props } from '../../dashboard/src/ViewJobController.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Typography,
  createTheme
} from '@material-ui/core';
import FreelancerProfileDetailsSettingsController from './FreelancerProfileDetailsSettingsController.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';
import { profileSettingImg } from './assets';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
export default class FreelancerProfileDetailsSettings extends FreelancerProfileDetailsSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
console.log("this.state",this.state);

    return (
      //Merge Engine DefaultContainerj
      <Box>
        <FreelancerNavBar navigation={this.props.navigation} profileImg={this.state.profileImage}/>
        <Typography style={{ ...webStyle.heading, margin: '20px' }}>
          Profile
        </Typography>
        <Box
          display={'grid'}
          gridAutoFlow={'column'}
          gridTemplateColumns={'0.2fr 0.95fr'}
          gridColumnGap={'3rem'}
          style={{ margin: '20px' }}
        >
          <Box display={'grid'} style={{ gap: '30px', height: ' max-content' }}>
            <Box>
             
        <Box style={{ position: 'relative', display: 'inline-block' }}>
          <img
            src={this?.state?.profileImage} // Use profileSettingImg as a placeholder if image is null
            style={{ width: '300px', height: '300px' }}
          />
          <IconButton
          data-testid='toggleOptions'
            style={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'white' }}
            onClick={this.toggleOptions}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Snackbar open={this.state.open} autoHideDuration={3000} onClose={this.handleClose} >
        <Alert onClose={this.handleClose} severity="success" elevation={6} variant='filled'>
       {this.state.message}
        </Alert>
      </Snackbar>
        {this.state.showOptions && (
          <div>
           <input
              type="file"
              accept="image/*"
              ref={this.state.fileInputRef}
              style={{ display: 'none' }}
              onChange={this.handleFileChange}
              data-testid='fileUpload'
              
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '5px', backgroundColor: '#206FC4' }}
              onClick={() => this.state.fileInputRef.current.click()}
               data-testid='profileUpdate'
               fullWidth
              >
              Update Profile Image
            </Button>
    

            <Button
              variant="contained"
              data-testid='removeProfile'
              color="primary"
              style={{ marginTop: '5px', backgroundColor: '#FF0000' }}
              onClick={this.handleRemoveImage}
              fullWidth
              disabled={!this?.state?.profileImage}
            >
              Remove Profile Image
            </Button>
            {this.state.errorMessage && (
          <div style={{ color: '#ff0000cf',fontFamily:"inter",fontSize:"15px" }}>{this.state.errorMessage}</div>
        )}
          </div>
          
        )}
        
     
            </Box>
            <Box display={'grid'} style={{ gap: '15px' }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: '8px',
                  height: '38px',
                  backgroundColor: '#206FC4'
                }}
              >
                <span style={webStyle.buttonText}> Edit IP Lawyer</span>
              </Button>
              <Button
                variant="contained"
                data-test-id="switchAccountButton"
                style={{
                  color: '#206FC4',
                  backgroundColor: '#FFF',
                  border: '1px solid #206FC4 ',
                  borderRadius: '8px',
                  height: '38px'
                }}
               
                onClick={()=>this.handleRedirection('ClientHomePage')}
              >
                <span style={webStyle.buttonText}>Switch account</span>
              </Button>
              <Button
                variant="contained"
                style={{
                  color: '#F00',
                  backgroundColor: '#FFF',
                  border: '1px solid #F00 ',
                  borderRadius: '8px',
                  height: '38px'
                }}
                data-test-id="handleLogout"
                onClick={() => this.handleLogout('EmailAccountLoginBlock')}
              >
                <span style={webStyle.buttonText}>LogOut</span>
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography style={{ ...webStyle.heading, marginBottom: '15px' }}>
              Contact Info
            </Typography>
            <Box display={'grid'} style={{ gap: '25px' }}>
              <Box
                display={'grid'}
                border={'1px solid #DCD2D2'}
                borderRadius={'10px'}
                style={{ background: '#FFF', padding: '20px', gap: '25px' }}
              >
                <Box
                  display={'grid'}
                  gridAutoFlow={'column'}
                  justifyContent={'space-between'}
                >
                  <Typography style={webStyle.heading}>Account</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: '10px 45px',
                      height: '36px',
                      fontSize: '18px',
                      borderRadius: '8px',
                      backgroundColor: '#206FC4'
                    }}
                  >
                    <span style={webStyle.buttonText}>Edit</span>
                  </Button>
                </Box>
                <Box>
                  <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                    User ID
                  </Typography>
                  <Typography style={webStyle.text}>
                  {this.state.freelancerProfileInfo?.attributes?.email}

                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                    Name
                  </Typography>
                  <Typography style={webStyle.text}> {this.state.freelancerProfileInfo?.attributes?.first_name}</Typography>
                </Box>
              </Box>
              <Box
                display={'grid'}
                border={'1px solid #DCD2D2'}
                borderRadius={'10px'}
                style={{ background: '#FFF', padding: '20px', gap: '25px' }}
              >
                <Typography style={webStyle.heading}>Location</Typography>
                <Box>
                  <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                    Time Zone
                  </Typography>
                  <Typography style={webStyle.text}> {this.state.freelancerProfileInfo?.attributes?.country}
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                    Address
                  </Typography>
                  <Typography style={webStyle.text}>{this.state.freelancerProfileInfo?.attributes?.address}</Typography>
                </Box>
                <Box>
                  <Typography style={{ ...webStyle.text, fontWeight: 500 }}>
                    Phone
                  </Typography>
                  <Typography style={webStyle.text}>{this.state.freelancerProfileInfo?.attributes?.full_phone_number}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#3E3E3E',
    fontSize: '20px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  heading: {
    color: '#3E3E3E',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  buttonText: {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  }
};
// Customizable Area End
