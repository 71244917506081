import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import storage from "framework/src/StorageProvider";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  toggleDrawer: ()=> void
  clientProfileData:{data:{
    id: string,
    type: string,
  attributes: {
    id: number,
    email: string,
    country: string,
    user_name: null,
    first_name: string,
    job_title: string,
    phone_number: null,
    full_phone_number: null,
    total_experience: null,
    activated: boolean,
    degree_img: {
      url: null
    },
    aadhar_img: {
        url: null
    },
    passport_img: {
        url: null
    },
    profile_image: null,
    work_history: null,
    skills: any[],
    unique_auth_id: string,
    address: null,
    created_at: string,
    updated_at: string,
    status: string,
    stripe_account_id: null,
    stripe_account_onboard_url: null,
    stripe_is_charges_enabled: boolean,
    stripe_is_onboard_completed: boolean,
    stripe_is_details_submitted: boolean,
    work_experiences: any[]
}}}
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userName: string;
  designation: string;
  location: string;
  hourPerWeek: string;
  varification: string;
  langauge: string;
  rating: number | null;
  university: string;
  degree: string;
  projectCount: string;
  hoursCompleted: string;
  slotDay: string;
  slotsArray: string[];
  showMore: boolean;
  price: string;
  projectDetails: {
    id: number,
    projectName: string,
    projectRating: string,
    rate: number | null,
    startDate: string;
    endDate: string;
    earnings: string;
  }[];
  portfolio: string[];
  skills: string[];
  currentPage: number;
  profileRationg:string;
  freelancerId:string;
  authToken:string;
  anchorEl:string;
  drawerOpen:boolean;

  // Customizable Area End
}
interface SS { }

export default class GlobalClientProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  clientProfileId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      userName: 'Rahul Sharma',
      designation: 'Website Designer',
      location: 'Latvia in Europe',
      hourPerWeek: 'As needed open to hours',
      varification: 'ID Verified',
      langauge: 'English',
      rating: 4,
      university: 'Ho Chi Minh City University of Information Technology',
      degree: 'Engineers Degree, Information Technology 1990-1995',
      projectCount: '1440',
      hoursCompleted: '5042',
      slotDay: 'Today(3 Slots)',
      slotsArray: ['04:00 PM', '05:00 PM', '06:00PM'],
      showMore: false,
      price: '$37.50',
      projectDetails: [
        {
          id: 1,
          projectName: 'Top Momentum T2',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 2,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 3,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 4,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 5,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        }
      ],
      portfolio: [
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
      ],
      skills: ['PHP', 'Android', 'SQL', 'Java', 'Kotlin'],
      currentPage: 1,
      profileRationg:'4.25/5',
      freelancerId:'',
      authToken:'',
      anchorEl:"",
      drawerOpen:false,
     
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      freelancerId: await storage.get('freelancerId'),
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }
  // Customizable Area Start
  handleShowText = () => {
    this.setState({ showMore: !this.state.showMore })
  }
  handleBackClick = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  }
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page })
  }
  handleNextClick = () => {
    if (this.state.currentPage < 10) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  }
  handleText = (type:string,page:number) => {
    if(type === 'previous'){
      return 'Back'
    }else if(type === 'next'){
      return 'Next'
    }else{
      return page
    }
  }

  handleRedirect = (path: any) => {
    this.setState({ anchorEl: "" });
    if (path)  {
      window.location.pathname = path;
    }
  };
  toggleDrawer = () => {
    this.props.toggleDrawer()
  };

  // Customizable Area End
}
