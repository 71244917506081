import React from "react";
//Customizable Area Start

import { Box, Button, Grid, TextField, Typography, styled } from "@material-ui/core";
import GiveRefundController,{Props} from "./GiveRefundController.web";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
export const webConfigJSON = require('./config.js');
// Customizable Area End

export default class GiveRefund extends GiveRefundController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    renderMail = () => {
        return (
            <Grid container spacing={2} lg={12} style={webStyle.gridBox}>
                <Grid item lg={12} xs={12} style={webStyle.myJobsContainer}>
                    <Box style={{ display: "flex" }}>
                        <UpperText>{webConfigJSON.myJobs}</UpperText>
                        <ArrowIcon>{">"}</ArrowIcon>
                        <UpperText>{webConfigJSON.allcontract}</UpperText>
                        <ArrowIcon>{">"}</ArrowIcon>
                        <ActiveText>{webConfigJSON.activecontract}</ActiveText>
                    </Box>
                </Grid>
                <Grid item lg={12} xs={12} style={webStyle.myJobsContainer}>
                    <Typography style={webStyle.acitveFont}>{webConfigJSON.giveRefund}</Typography>
                </Grid>

                <Box style={webStyle.boxCotainer}>
                    <Box style={webStyle.innerBox}>
                        <Typography style={webStyle.filedText}>{webConfigJSON.contract}</Typography>
                        <Typography style={webStyle.accountHolderText}>{webConfigJSON.guptaShuriya}</Typography>
                        <Typography style={webStyle.filedText}>{webConfigJSON.invoice}</Typography>
                        <SelectInvoiceButton variant="contained">
                            {webConfigJSON.selectInvc}
                        </SelectInvoiceButton>
                        <Box style={webStyle.borderline}></Box>
                        <Box style={webStyle.ammountBox}>
                            <Typography style={webStyle.filedText}>{webConfigJSON.RefundA}</Typography>
                            <Typography style={webStyle.ammount}>$0.00</Typography>
                        </Box>
                        <Box style={webStyle.ammountBox}>
                            <Typography style={webStyle.filedText}>{webConfigJSON.currenB}</Typography>
                            <Typography style={webStyle.ammount}>$21.36</Typography>
                        </Box>
                        <Box style={webStyle.ammountBox}>
                            <Typography style={webStyle.filedText}>{webConfigJSON.yourB}<Typography style={webStyle.serviceFeeText}>{webConfigJSON.upwordfree}</Typography></Typography>
                            <Typography style={webStyle.ammount}>$0.00</Typography>
                        </Box>
                        <Typography style={webStyle.filedText}>{webConfigJSON.note}</Typography>
                        <InputMultiField
                            placeholder={webConfigJSON.issueResfund}
                            multiline
                        />
                        <Typography style={webStyle.filedText1}>{webConfigJSON.noteSelf}<Typography style={webStyle.optionText}>{webConfigJSON.option}</Typography></Typography>
                        <InputMultiField
                            placeholder={webConfigJSON.incluedNote}
                            multiline
                        />

                        <Box>
                            <Button variant="contained" style={webStyle.cancelButton}>
                                {webConfigJSON.cancle}
                            </Button>
                            <Button variant="contained" style={webStyle.refundButton}>
                                {webConfigJSON.giveRefund}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        //Customizable Area Start

        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                {this.renderMail()}
                <FooterWeb />
            </>
        );

        //Customizable Area End
    }
}

//Customizable Area Start

const webStyle = {
    myJobsContainer: {
        display: "flex",
        width: '1250px'
    },

    ammountBox: {
        display: "flex",
        gap: "5%"
    },

    acitveFont: {
        color: "#3F3F3F",
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "44px",
        letterSpacing: "0px"
    },

    gridBox: {
        display: 'flex',
        margin: "45px 35px 45px 35px"
    },
   
    boxCotainer: {
        width: "95%",
        height: "100%",
        top: "307px",
        left: "80px",
        borderRadius: "14px",
        border: "0px solid #D9D9D9",
        borderWidth: "1px"
    },

    innerBox: {
        margin: "12px"
    },

    filedText: {
        fontSize: "17px",
        lineHeight: "45px",
        width: '20%',
        "@media(max-width:768px)": {
            fontSize: "13px",
            lineHeight: "30px",
            width: '25%'
        }
    },

    ammount: {
        fontSize: "17px",
        lineHeight: "45px",
        width: '20%',
        color: "#414141"
    },

    filedText1: {
        fontSize: "17px",
        lineHeight: "45px",
        display: 'flex',
        alignItems: "center",
        fontFamily: "Inter",
        fontWize: "26px",
        fontWeight: 500,
        letterSpacing: "0px",
        color: '#0A0A0A'

    },

    optionText: {
        color: '#D9D9D9',
        margin: '9px 1%',
        "@media(max-width:768px)": {
            margin: "3px 1%"
        }
    },

    serviceFeeText: {
        fontSize: "15px",
        fontFamily: 'Roboto',
        margin: "-8px 0px 7px 0px",
        "@media(max-width:768px)": {
            fontSize: "10px"
        }
    },

    ammountDetailText: {
        fontFamily: "Inter",
        fontSize: "26px",
        fontWeight: 500,
        lineHeight: '31px',
        letterSpacing: "0px",
        textAlign: "left"

    },

    accountHolderText: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'inter'
    
    },

    textFiledInput: {
        width: "732px",
        height: "56px",
        margin: "10px 0px"
    },
    
    borderline: {
        height: "1px",
        background: "#ECECEC",
        width: "102%",
        marginLeft: '-1%'
    },

    refundButton: {
        background: "#2378D1",
        color: "#ffff",
        width: '11%',
        margin: '12px'
    },

    cancelButton: {
        background: '#E5F1FF',
        width: '10%',
        color: "#2378D1"
    },
}

const SelectInvoiceButton = styled(Button)({
    background: '#ffff',
    width: '15%',
    color: "#206FC4",
    border: "1px solid #206FC4",
    borderRadius: "11px",
    fontSize: "12px",
    fontWeight: 600,
    margin: '12px 0px',
    "@media(max-width:768px)": {
        fontSize: "9px"
    }
});

const InputMultiField = styled(TextField)({
    border: "1px solid #D9D9D9",
    padding: "10px",
    marginBottom: "20px",
    height: "100px",
    boxSizing: "border-box",
    borderRadius: "5px",
    overflowY: "auto",
    width: "85%",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none",
        },
        "&::before": {
            content: "none",
        },
        "&::after": {
            content: "none",
        },
    },
});

const UpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});
const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const ActiveText = styled(Typography)({
    color: "#206FC4",
cursor: "pointer",
fontFamily: "Inter",
fontSize: "17px",
fontWeight: 500,
lineHeight: "24px",
letterSpacing: "0px",
textAlign: "left"
});

//Customizable Area End