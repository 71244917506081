import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { avtar, serachedImg } from './assets';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchIp:string
  recentSearch:string;
  option:string;
  selectedResult:string[];
  projectCount:string;
  searchedResult: {
    id: number,
    searchedImg: string,
    userProfile: string,
    userName: string,
    designation: string,
    rating: number,
    ratingCount: string,
    userDetail: string,
    price: string,
    duration: string
  }[];
  ipValuer: string;
  ipLawyer: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SearchIpController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      searchIp:'',
      recentSearch:'mostRecent',
      option:'Most Recent',
      selectedResult:['Category','Project Attributes','Price','Delivery Time','Talent Details','Country'],
      projectCount:'50,319',
      searchedResult: [
        {
          id: 1,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:2,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:3,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:4,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:5,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:6,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:7,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:8,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:9,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:10,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:11,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        },
        {
          id:12,
          searchedImg: serachedImg,
          userProfile: avtar,
          userName: 'Rahul Sharma',
          designation: 'Legend',
          rating: 3,
          ratingCount: '4.8',
          userDetail: 'You will get a standard NDA contract for all your business dealing.',
          price: '$100',
          duration: '2 Days Delivery'
        }
      ],
      ipValuer: 'Ip Valuer',
      ipLawyer: 'Ip Lawyer'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearchIp = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({searchIp:event.target.value})
  }
  handleRecent = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({recentSearch: event.target.value})
  }
  // Customizable Area End
}