import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
// Customizable Area End
}
interface S {
  // Customizable Area Start

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
// Customizable Area End
}

export default class FreelancerStripeAccountController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.state = {
    
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
    
    });
    // Customizable Area End
  }

  

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
    });
  };

 
  // Customizable Area End
}
