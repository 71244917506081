import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Chip,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { FileUploadArea } from '../../../components/src/PatentAgent.web';
import {  uploadIcon2 } from '../../dashboard/src/assets';

import EditFreelancerProfileController, {
  Props,
  configJSON,
} from "./EditFreelancerProfileController";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

// Customizable Area End

export default class EditFreelancerProfile extends EditFreelancerProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log(this.state)
    return (
      //Merge Engine DefaultContainer
      <>
        <FreelancerNavBar navigation={this.props.navigation} profileImg={this.state.profileImage}/>
        <ThemeProvider theme={theme}>
          <ArrowBackIcon style={webStyles.arrowStyle} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={webStyles.profileDiv}>
              <img src={this.state?.profileImage} style={{width:"315",height:"315",borderRadius:"15px"}} />
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Typography style={webStyles.profileText}>
                  Change profile picture{" "}
                </Typography>               
                <EditIcon
                  data-testid="profile-pic-edit"
                  style={{
                    background: "#2378D1",
                    color: "white",
                    borderRadius: "100%",
                    padding: "5px",
                    marginTop: "8px",
                    cursor: "pointer"
                  }}
                  onClick={this.handleEditClick}
                />
                <input
                 type="file" 
                 data-testid="profileImageInput"
                 style={{ display: 'none' }}
                 ref={this.state.fileInputRef}
                 accept="image/*"
                 onChange={this.handleFileChange}
      />
              </Box>
            </div>
            <Box style={webStyles.pageContainer}>
              <Grid container justifyContent="center">
                <Grid >
                  <Box style={webStyles.Header}>Edit Details</Box>
                  <Box>
                    <Grid container style={webStyles.mainBox}>
                      <Grid item sm={12} md={12} style={webStyles.editFieldBox}>
                        <Box style={webStyles.editModalContainer}>
                          <Box>
                            <Typography style={webStyles.label}>
                              {configJSON.title}
                            </Typography>
                            <TextField
                              data-testid="title"
                              variant="outlined"
                              name="job_title"
                              placeholder={configJSON.titlePlaceholder}
                              size="small"
                              InputProps={{ style: { borderRadius: "10px" } }}
                              style={{ width: "273px" }}
                              value={this.state.details.job_title}
                              onChange={this.handleDetailsChange}
                            />
                          </Box>
                          <Box>
                            <Typography style={webStyles.label}>
                              {configJSON.price}
                            </Typography>
                            <TextField
                              data-testid="price"
                              variant="outlined"
                              name="price"
                              size="small"
                              placeholder="enter price"
                              style={{ width: "273px" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                                style: { borderRadius: "10px" },
                              }}
                              type="number"
                              value={this.state.details.price}
                              onChange={this.handleDetailsChange}
                            />
                          </Box>
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.title}
                          </Typography>
                          <TextField
                            data-testid="title_description"
                            variant="outlined"
                            name="title_description"
                            fullWidth
                            multiline
                            minRows={4}
                            InputProps={{
                              style: { borderRadius: "10px" },
                            }}
                            value={this.state.details.title_description}
                            onChange={this.handleDetailsChange}
                          />
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.workHistory}
                          </Typography>
                          <TextField
                            data-testid="work_history"
                            variant="outlined"
                            name="work_history"
                            fullWidth
                            multiline
                            minRows={4}
                            InputProps={{
                              style: { borderRadius: "10px" },
                            }}
                            value={this.state.details.work_history}
                            onChange={this.handleDetailsChange}
                          />
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.skills}
                          </Typography>
                          <Autocomplete
                            multiple
                            data-testid="skills"
                            id="skills"
                            open={false}
                            value={this.state.details.skills}
                            options={this.top100Films.map(
                              (option) => option.title
                            )}
                            onChange={this.handleSkillChange}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  style={webStyles.chip}
                                  label={option}
                                  deleteIcon={
                                    <ClearIcon
                                      fontSize="small"
                                      style={webStyles.clearIcon}
                                    />
                                  }
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                style={{ borderRadius: 10 }}
                                {...params}
                                variant="outlined"
                                placeholder="Favorites"
                              />
                            )}
                          />
                        </Box>
                      </Grid>

                      <Grid container>
                        <Grid item sm={12} md={12}>
                          <Box style={webStyles.addContainer}>
                            <Typography style={webStyles.addText}>
                              {configJSON.addExperience}
                            </Typography>
                            <AddCircleRoundedIcon
                              data-testid="add-experience"
                              color="primary"
                              style={webStyles.editIcon}
                              onClick={() => this.handleAddExperience("create")}
                            />
                          </Box>
                          <hr style={webStyles.Hr} />
                         <Box>
                           {this.state.details.work_experiences?.map((expeienceData,index)=> <Grid container key={index}>
                              <Grid
                                item
                                sm={12}
                                md={12}
                                style={{...webStyles.addFieldBox,border:"2px solid lightgray",borderRadius:"5px",marginBottom:"5px"}}
                              >
                                <Box style={webStyles.textFieldContainer}>
                                  <Typography style={webStyles.label}>
                                    {configJSON.companyLabel}
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    data-testid="company"
                                    name="company"
                                    placeholder={configJSON.companyPlaceholder}
                                    size="small"
                                    InputProps={{
                                      style: { borderRadius: "10px" },
                                    }}
                                    value={expeienceData.company}
                                     onChange={(event)=>this.arrayItemsChange('work_experiences',index,'company',event.target.value)}
                                  />
                                </Box>
                                <Box style={webStyles.textFieldContainer}>
                                  <Typography style={webStyles.label}>
                                    {configJSON.jobTitleLabel}
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    name="job_title"
                                    data-testid="job_title"
                                    fullWidth
                                    InputProps={{
                                      style: { borderRadius: "10px" },
                                    }}
                                    value={expeienceData.job_title}
                                     onChange={(event)=>this.arrayItemsChange('work_experiences',index,'job_title',event.target.value)}
                                    placeholder={configJSON.jobTitlePlaceholder}
                                    error={this.state.validationError}
                                    helperText={
                                      this.state.validationError &&
                                      configJSON.titleErrorMessage
                                    }
                                  />
                                </Box>
                                <Box style={webStyles.textFieldContainer}>
                                  <Typography style={webStyles.label}>
                                    {configJSON.locationLabel}
                                  </Typography>
                                  <TextField
                                    data-testid="location"
                                    variant="outlined"
                                    name="location"
                                    fullWidth
                                    InputProps={{
                                      style: { borderRadius: "10px" },
                                    }}
                                    value={expeienceData.location}
                                     onChange={(event)=>this.arrayItemsChange('work_experiences',index,'location',event.target.value)}
                                    placeholder={configJSON.locationPlaceholder}
                                  />
                                </Box>
                                <Box style={webStyles.addModalFields}>
                                  <Box>
                                    <Typography style={webStyles.label}>
                                      {configJSON.startDateLabel}
                                    </Typography>
                                    <TextField
                                      variant="outlined"
                                      data-testid="start_date"
                                      type="date"
                                      style={webStyles.dateField}
                                      name="start_date"
                                      value={expeienceData.start_date}
                                       onChange={(event)=>this.arrayItemsChange('work_experiences',index,'start_date',event.target.value)}
                                      InputProps={{
                                        style: { borderRadius: "10px" },
                                      }}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography style={webStyles.label}>
                                      {configJSON.endDateLabel}
                                    </Typography>
                                    <TextField
                                      variant="outlined"
                                      style={webStyles.dateField}
                                      name="end_date"
                                      type="date"
                                      data-testid="end_date"
                                      value={expeienceData.end_date}
                                       onChange={(event)=>this.arrayItemsChange('work_experiences',index,'end_date',event.target.value)}
                                      InputProps={{
                                        style: { borderRadius: "10px" },
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box style={webStyles.textFieldContainer}>
                                  <Typography style={webStyles.label}>
                                    {configJSON.descriptionLabel}
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    name="description"
                                    data-testid="description"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    InputProps={{
                                      style: { borderRadius: "10px" },
                                    }}
                                    value={expeienceData.description}
                                    onChange={(event)=>this.arrayItemsChange('work_experiences',index,'description',event.target.value)}
                                  />
                                </Box>
                              </Grid>
                            </Grid>)}
                            </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid
                          item
                          sm={12}
                          md={12}
                          style={webStyles.addFieldBox}
                        >
                          <Box style={webStyles.textFieldContainer}>
                          <Box style={webStyles.addContainer}>
                            <Typography style={webStyles.addText}>
                              {configJSON.articlesLabel}
                            </Typography>
                            <AddCircleRoundedIcon
                              style={webStyles.editIcon}
                              color="primary"
                              data-testid="add-experience"
                              onClick={() => this.handleAddExperience("create")}
                            />
                            </Box>
                           { this.state.details.articles?.map((articleData,index) =>
                           <Box key={index} mt={1}>
                            <TextField
                              minRows={4}
                              data-testid="article"
                              fullWidth
                              variant="outlined"
                              multiline
                              name={`article-${index}`}
                              value={articleData.article}
                              InputProps={{
                                style: { borderRadius: "10px" },
                              }}
                              onChange={(event)=>this.arrayItemsChange('articles',index,'article',event.target.value)}
                            />
                            <FileUploadArea
              id={`profile-article-iage-${index}`}
              style={webStyles.UploadButton}
              testId={`profile-article-iage-${index}`}
              uploadIcon={uploadIcon2}
              onChange={(event:any)=>this.handleFileUpload('articles',index,'article_image',event)}
              stateKeyRef={'articleIFiles'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
              btnContent={
               articleData.article_image.name
                  ?  articleData.article_image.name
                  : 'Upload your image/video'}
            />
            </Box>)}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid
                          item
                          sm={12}
                          md={12}
                          style={webStyles.addFieldBox}
                        >
                          <Box style={webStyles.textFieldContainer}>
                          <Box style={webStyles.addContainer}>
                            <Typography style={webStyles.addText}>
                              {configJSON.successStoryLabel}
                            </Typography>
                            <AddCircleRoundedIcon
                              color="primary"
                              data-testid="add-experience"
                              onClick={() => this.handleAddExperience("create")}
                              style={webStyles.editIcon}
                            />
                            </Box>
          { this.state.details.success_stories?.map((successStory,index) =>
                            <Box key={index} mt={1}>
                            <TextField
                              data-testid={`successStory-${index}`}
                              variant="outlined"
                              name={`successStory-${index}`}
                              minRows={4}
                              multiline
                              fullWidth
                              InputProps={{
                                style: { borderRadius: "10px" },
                              }}
                              value={successStory.success_story}
                              onChange={(event)=>this.arrayItemsChange('success_stories',index,'success_stories',event.target.value)}
                            />
                            <FileUploadArea
              id={`successStory-${index}-file`}
              style={webStyles.UploadButton}
              testId={`successStory-${index}-file`}
              uploadIcon={uploadIcon2}
              onChange={(event:any)=>this.handleFileUpload('success_stories',index,'success_story_image',event)}
              stateKeyRef={'successStories'}
              backgroundColor= {'#FFF7E9'}
              border={'1px dashed #979797'}
              btnContent={
                successStory.success_story_image.name
                  ? successStory.success_story_image.name
                  : 'Upload your image/video'}
            />
            </Box>)}
            <Box style={webStyles.btnSaveBox}>
                        <Button color="primary" variant="contained" data-testid="update-btn"  onClick={this.handleUpdate} style={{marginTop:"10%"}}>{configJSON.save}</Button>
                      </Box>
                          </Box>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ThemeProvider>
        <FooterWeb />
      </>

      //Merge Engine End DefaultContainer
    );
  }
  top100Films = [{ title: "", year: 1994 }];
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
  mainBox: {
    background: "rgb(245, 247, 249) none repeat scroll 0% 0%",
    padding: "30px 50px",
  },
  UploadButton: {
    height: '100%',
    width: '100%',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#FFF7E9',
    border: '1px dashed #979797',
    borderRadius: '12px'
  },
  pageContainer: {
    padding: "20px",
    width: "100%",
  },
  Header: {
    fontFamily: "Inter",
    fontSize: "26px",
    fontWeight: "bold" as "bold",
    marginBottom: "20px",
  },
  profileDiv: {
    margin: "30px 0 0 56px",
  },
  profileText: {
    color: "#2378D1",
    fontWeight: "bold" as "bold",
    marginTop: "10px",
    display: "flex" as "flex",
    justifyContent: "center" as "center",
    fontSize: "20px",
  },
  arrowStyle: {
    margin: "30px 0 0 30px",
  },
  editFieldBox: {
    padding: "10px 20px",
  },
  btnSaveBox: {
    textAlign: "end" as "end",
  },
  editModalContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    marginBottom: "2%",
  },
  label: {
    fontSize: "1rem",
    fontWeight: 600,
    paddingBottom: "2px",
  },
  fieldBox: {
    marginBottom: "2%",
  },
  chip: {
    background: "#E9F2FB",
    color: "#2378D1",
    margin: "0 5px",
    maxWidth: "150px",
  },
  clearIcon: {
    color: "#2378D1",
  },
  addContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px 0",
  },
  addText: {
    fontWeight: 500,
    fontSize: "20px",
    fontFamily: "Rubik",
  },
  Hr: {
    height: "2px",
    background: "#DADADA",
    border: "none",
  },
  addModalFields: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    marginBottom: "2%",
  },
  addFieldBox: {
    padding: "10px 20px",
    
  },
  textFieldContainer: {
    marginBottom: "2%",
  },
  dateField: {
    width: "250px",
  },
  editIcon: {
    cursor: "pointer",
  },
};
// Customizable Area End
