Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = '/bx_block_dashboard/candidates';
exports.searchSkillEndPoint = 'bx_block_posts/posts/search_skills';
exports.InvitetoJobDataEndpoint = 'bx_block_posts/invitation_to_interviews'
exports.dashboarContentType = 'application/json';
exports.dashboarApiMethodType = 'GET';
exports.dashboardHost = '<calculated when request is sent>';
exports.dashboarUserAgent = 'PostmanRuntime/7.26.5';
exports.dashboarAccept = '*/*';
exports.dashboarAcceptEncoding = 'gzip, deflate, br';
exports.dashboarConnection = 'keep-alive';
exports.dashboartoken = '';
exports.labelTitleText = 'dashboard';
exports.totalCandidateslabelTitleText = 'Total Candidates';
exports.viewDetailsBtn = 'View Details';
exports.candidateslabelTitleText = 'Candidates';
exports.baseUrl = '';
exports.labelBodyText = 'dashboard Body';
exports.jobPostData = {
  name: 'Hotel Management System',
  type: 'Hourly-Public',
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.",
  workHours: 'Less than 30hrs/week',
  duration: '1 to 3 months',
  expertise: 'Intermediate',
  note: 'I am looking for mix experience and value',
  Proposals: '10 to 15',
  lastViewd: '10 minutes ago',
  inviteSend: 0,
  unansweredInvites: 0,
  skills: ['PHP', 'SQL', 'Android']
};
exports.proposalsData = [
  {
    id: '1',
    type: 'proposal',
    attributes: {
      description: null,
      hour_rate: 50.0,
      post_attributes: {
        id: 13,
        location: 'india',
        skills: ['lawyer']
      },
      account_attributes: {
        id: 74,
        description: 'fdfdfdf',
        user_name: 'usama ashraf',
        freelancer_status: true,
        designation: 'ceo',
        location: 'India',
        education: 'computer science',
        aadhar_img: {
          url: null
        },
        skills: []
      }
    }
  },
  {
    id: '3',
    type: 'proposal',
    attributes: {
      description: null,
      hour_rate: 50.0,
      post_attributes: {
        id: 13,
        location: 'india',
        skills: ['lawyer']
      },
      account_attributes: {
        id: 74,
        description: 'fdfdfdf',
        user_name: 'usama ashraf',
        freelancer_status: true,
        designation: 'ceo',
        location: 'India',
        education: 'computer science',
        aadhar_img: {
          url: null
        },
        skills: []
      }
    }
  }
];

exports.savedTalentData = [
  {
    jobTitle: 'Web Designer | UI/UX Designer',
    name: "John mehtas",
    location: 'Lativa in Europe',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount: "$10.00/hr",
    amount_basis: 'Hourly Price'
  },
  {
    jobTitle: 'Web Designer | UI/UX Designer',
    name: 'John mehta',
    location: 'Lativa in Europe',
    amount: '$10.00/hr',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount_basis: 'Hourly Price'
  },
  {
    jobTitle: 'Web Designer | UI/UX Designer',
    name: 'John mehtas',
    location: 'Lativa in Europe',
    amount: '$10.00/hr',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount_basis: 'Hourly Price'
  }
];
exports.yourHiresData = [
  {
    name: 'Johan mehta',
    jobTitle: 'Web Designer | UI/UX Designer',
    location: 'Lativa in Europe',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount: '$10.00/hr',
    amount_basis: 'Hourly Price'
  },
  {
    name: 'Johan mehta',
    jobTitle: 'Web Designer | UI/UX Designer',
    location: 'Lativa in Europe',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount: '$10.00/hr',
    amount_basis: 'Hourly Price'
  },
  {
    name: 'Johan mehta',
    jobTitle: 'Web Designer | UI/UX Designer',
    location: 'Lativa in Europe',
    relevantSkills: ['HTML', 'CSS', 'REACT', 'NEXT JS'],
    amount: '$10.00/hr',
    amount_basis: 'Hourly Price'
  }
];

exports.steps = [
  { label: 'View Job Post', value: 'VIEW_JOB_POST' },
  { label: 'Review Proposals', value: 'REVIEW_PROPOSALS' },
  { label: 'Hire', value: 'HIRE' }
];
exports.reusePost = 'Reuse this job post';
exports.ViewProposals = 'View proposals';
exports.InviteFreelancers = 'Invite freelancers'
exports.Viewjobposting = 'View job posting'
exports.yourDashboard = 'Your Dashboard';
exports.Editposting = 'Edit posting';
exports.Reuseposting = 'Reuse posting';
exports.Removeposting = 'Remove posting'
exports.userName = 'User Name';
exports.expert = 'Expert Tradestation, Meta Trader, NinjaTrader, TradingView';
exports.lorem =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";
exports.readMore = 'read more';
exports.readLess = 'read less';
exports.hire = 'invite';
exports.clientsFeedback = "Client's Feedback";
exports.dummyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
exports.userName1 = 'Rahul Sharma';
exports.role1 = 'Owner';
exports.skills = 'Skills';
exports.proposal = 'Proposals Details';
exports.proposed = 'Proposed Bid';
exports.hi = 'Hi,';
exports.just = 'I just deeply researched your requirements.';
exports.eyeCatch =
  'I can make an eye-catching, error-prune website according to your specification.';
exports.fullStack =
  'As a full-stack developer with five-plus years of association with Website and Mobile, I can surely convince you that I will make a highly tech-based website.';
exports.expertise = 'I have been expertise in the following stacks';
exports.pastWork = '@My past work on the same:';
exports.googleLink = 'https://www.google.com';
exports.regard = 'Regards';
exports.john = 'John Meheta';
exports.online = 'Online';
exports.website = 'Website Designer';
exports.lativa = 'Lativa in Europe';
exports.messageMe = 'Message Me';
exports.savedTalent = 'Saved Talent';
exports.talent='Talent';
exports.education = 'Education:';
exports.university = 'Ho Chi Minh City University of Information Technology';
exports.degree = "Engineer's degree, Information Technology";
exports.year = '1990-2000';
exports.contract = 'Contract Terms';
exports.payBy = 'Pay by the milestone';
exports.popular = 'PopularPay for the number of hours worked on a project';
exports.popularMilestone =
  'PopularPay for the number of milestone worked on a project';
exports.numberMile = 'Select Number of Milestone';
exports.mileStoneValidate = "*You can't create more than 30 Milestone.";
exports.startDate = 'Start Date(Optional)';
exports.mileDescription = 'Milestone Description';
exports.amount = 'Amount';
exports.option = '(Optional)';
exports.date = 'Date';
exports.status = 'Status';
exports.addMileStone = 'Add Milestone';
exports.agreeTerm =
  'Yes, I understand and agree to the Savio Terms of Service, including the User Agreement and Privacy Policy.';
exports.cancle = 'Cancel';
exports.next = 'Next';
exports.yourPosting = 'Your Posting';
exports.seeAllPost = 'See all posting';
exports.proposals = 'Proposals';
exports.messaged = 'Messaged';
exports.hired = 'Hired';
exports.yourDraft = 'Your Draft';
exports.seeAllDraft = 'See all Draft';
exports.edit = 'Edit draft';
exports.remove = 'Remove draft';
exports.howToWork = 'How To Work With Talent';
exports.perDayRate = 'Per Day Charge:';
exports.projectBudget = 'Per Project:';
exports.lorem =
  'Lorem Impsum is simply dummy text of the printing and typesetting industry';
exports.back = 'Back';
exports.next = 'Next Description';
exports.hour = '/project';
exports.day = '/day';
exports.postAJob = '1.Post a job to the marketplace';
exports.descriptionOne =
  'Provide enough detail for great talent to figure out if the work is right for them.(You can always edit your post, or send an invite to reach out to people directly.)';
exports.getProposals = '2.Get proposals from talent';
exports.descriptionTwo =
  'A strong working relationship starts with open communication. Here’s your chance to ask about experience set expectations for what you need, and discuss terms of the work.';
exports.startWork = '3.Start working together';
exports.descriptionThree =
  'Once you both agree on terms, collaborate with simple and secure tools like chat, file sharing, and time tracking.';
exports.payForWork = '4.Pay for work you approve';
exports.descriptionFour =
  ' Reports are useful for keeping track of payments and reviewing work. As you complete jobs, you can build trusting relationships with talent in a way that helps you both grow.';
exports.postJobBtn = 'Post A Job';
exports.exploreMore = 'Explore More Talent';
exports.AddBilling = 'Add Billing Method';
exports.enterDetails = 'Enter Details';
exports.emailAddress = 'Email Address:';
exports.stripeAccount = 'Enter Stripe Account:';
exports.IFCICode = 'Enter IFCI Code:';
exports.setAccount = 'Set Via Stripe Account';
exports.oneDayRate = 'One Day Rate:';
exports.rate = '$.18.00';
exports.noteSelf = 'Note to Self';
exports.savioService = 'Savio Service Fee:';
exports.serviceFee = '$.5.00';
exports.willReceive = "You'll Receive:";
exports.receive = '$.13.00';
exports.myJobs = 'My Jobs';
exports.jobs = 'Jobs';
exports.filters = 'Filters';
exports.projectDescription = 'Project Description';
exports.totalMileStone = 'Total Number of Milestone';
exports.completedMilestone = 'Completed Milestone';
exports.inCompletedMilestone = 'Incompleted Milestone';
exports.completed = 'Completed';
exports.ongoing = 'Ongoing';
exports.pending = 'Pending';
exports.confirmationMsg =
  'Are you sure you want to change the status of the project from pending to completed?';
exports.paymentTransfer =
  'After changing the status the payment will directly transfer to the project person.';
exports.confirm = 'Confirm';
exports.paymentSuccess = 'Your Payment has been successful';
exports.homeBtn = 'Home';
exports.viewInvoice = 'View Invoice';
exports.paymentFailed = 'Your Payment has been Failed';
exports.tryAgain = 'Try Again';
exports.confirm = 'Confirm';
exports.enterDetails = 'Enter Details';
exports.emailAddress = 'Email Address:';
exports.enterStripeAccount = 'Enter Stripe Account:';
exports.enterIFCICode = 'Enter IFCI Code:';
exports.stripeAccount = 'set via stripe account';
exports.oneDayRate = 'One Day Rate:';
exports.savioServiceFee = 'Savio Service Fee:';
exports.youReceive = 'You’ll Receive:';
exports.rateOneDay = '$18.00';
exports.rateSavioService = '$5.00';
exports.rateYouReceive = '$13.00';
exports.allcontract = 'All Contract';
exports.activecontract = 'Active Contract';
exports.resolvedispute = 'Resolve a Dispute';
exports.contract = 'Contract:';
exports.company = 'Company:';
exports.freelancer = 'Freelancer:';
exports.disputedhours = 'Disputed Hours:';
exports.amount = 'Amount:';
exports.comment = 'Comment:';
exports.submit = 'SUBMIT';
exports.cancel = 'CANCEL';
exports.invoice='Invoice(s)';
exports.selectInvc='SELECT INVOICES';
exports.RefundA='Refund Amount';
exports.currenB='Current Balance';
exports.note='Note to Client';
exports.yourB='Your Balance Will be Debite';
exports.guptaShuriya = 'Shaurya Gupta - Education Website design in Figma';
exports.exportCSV='Export CSV'
exports.selectDate='Select Date'
exports.ten='Ten'
exports.twenty='Twenty'
exports.thirty='Thirty'
exports.selectorder='Select Order'
exports.ascending='Ascending'
exports.descending='Descending'
exports.filter='Filter'
exports.hotelmanagementwebsite='Hotel Management Website'
exports.totalhourstracked='Total Hours Tracked:'
exports.hours72='72 Hours'
exports.hr120='120 Hour'
exports.clientcetimation='Client’s Estimation:'
exports.totalearned='Total Earned:'
exports.$9250='$92.50'
exports.jan2021='January 2021- Present'
exports.$10hr='$10/hr'
exports.hotelmanagementwebsite='Hotel Management Website'
exports.lorem='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae atque nobis totam incidunt corrupti quidem id amet, veritatis omnis ratione sapiente repudiandae quod ducimus magni quibusdam doloribus! Cupiditate voluptates voluptatem aspernatur libero!'
exports.contentContr='Contract youre actively working on will appear here.'
exports.startlink='Start searching for new project now!'
exports.project='Projects'
exports.fieldmanagementservice='Field Management Service'
exports.projectownername='Project Owner Name:'
exports.timeDuration='Time Duration:'
exports.giveRefund='Give Refund'
exports.rahulsharma='Rahul Sharma'
exports.dec24='24 December 2021'
exports.rate145='$14.5 per/hr hourly rate paid'
exports.projectdeatils='Project Deatils'
exports.raiseDisput='RAISE DISPUT'
exports.goToProject='GO TO PROJECT'
exports.charge='Charge:'
exports.textreme='Remember that this behavior may depend on your installed extensions.'
exports.textMain='Remember that this behavior may depend on your installed extensions and settings. If, for some reason, the lorem snippet doesnt work, you can check your user or workspace settings for any conflicting keybindings or extensions.'
exports.navbarMenu = [
  {
    label: 'Jobs',
    path: '/jobs',
    options: [
      { label: 'My Jobs', path: '/MyJobs' },
      { label: 'All Job Posts', path: '/JobPosting' },
      { label: 'Post a Job', path: '/postjob' }
    ]
  },
  {
    label: 'Talent',
    path: '/talent',
    options: [
      { label: 'Your Hires', path: '/yourhires' },
      { label: 'Saved Talent', path: '/savedtalent' }
    ]
  },
  {
    label: 'Reports',
    path: '/',
    options: [
      { label: 'Transaction History', path: '/transactionhistory' },
      { label: 'Saved Talent', path: '/savedtalent' }
    ]
  },
  { label: 'Message', path: '/chat' }
];
exports.freelancerNavbarMenu = [
  {
    label: 'Find Work',
    componentName: null,
    options: [
      { label: 'Find Work', componentName: 'SearchJob' },
      { label: 'Saved Jobs', componentName: 'FreelancerSavedJob' },
      { label: 'Proposals', componentName: 'FullWidthTabProposals' }
    ]
  },
  {
    label: 'My Jobs',
    componentName: null,
    options: [
      { label: 'My Jobs', componentName: "MyJobActiveContract" },
      { label: 'All Contract', componentName: 'AllContracts' }
    ]
  },
  {
    label: 'Reports',
    componentName: null,
    options: [
      { label: 'Overview', componentName: 'FreelancerOverview' },
      {
        label: 'Transaction History',
        componentName: 'FreelancerTransactionHistory'
      },
      { label: 'Billings', componentName: null }
    ]
  },
  { label: 'Message', componentName: 'ContactBox' }
];
exports.companyTitle = 'INVENTO HUB';
exports.freelancerTitle = 'INVENTO ';

exports.cardStyles = {
  container: {
    width: '80%',
    marginTop: '20Px'
  },
  cardContainer: {
    background: 'rgb(242, 245, 247)',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  talentText:{
    display: 'flex',
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0px",
    margin: '20px 0px 20px 0px',
    color:'#0000000'
  },
  saveText:{
    color:'#206FC4',
    display: 'flex',
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0px",
    margin: '20px 0px 20px 7px',
  },
  profileDetails: {
    padding: '2px',
    display: 'flex',
    marginBottom: '15px'
  },
  tabs: {
    color: '#787878',
    fontFamily: 'Inter',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  skills: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: '10px',
    marginTop: '13px'
  },
  avatar: {
    width: '80px',
    height: '80px',
    flexShrink: 0,
    background: '#206FC4',
    marginRight: '9px'
  },
  relevantSkills: {
    color: '#515152',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  nameText:{
    color: "#000",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: 700,
    fontWeight: 600
  },
  jobTitleText: {
    color: '#545555',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginLeft: '4px'
  },
  titleText: {
    color: '#545555',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    marginLeft: '4px'
  },
  userName: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    marginLeft: '4px'
  },
  amount: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  hourlyBasis: {
    color: '#5B5C5C',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    color: '#747576',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  FlexContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignContent: 'space-between'
  }
};
exports.payByHours = 'Pay by the hour';
exports.milestoneNumber = 'Number of Milestone';
exports.milestone = 'Milestone';
exports.noOfWeeks = 'No Of Weeks';
exports.location = 'Location:';
exports.hoursPerWeek = 'Hours per week:';
exports.verification = 'Verification:';
exports.langauges = 'Languages:';
exports.rating = 'Rating:';
exports.education = 'Education:';
exports.projectCompleted = 'Project Completed';
exports.hoursCompleted = 'Completed Hours';
exports.availbaleSlot = 'Available Slots:';
exports.invite = 'Invite';
exports.completedProject = 'Completed Projects';
exports.projectPortfolio = 'Project Portfolio';

exports.billsAndPaymentsData = [
  {
    Freelancer: 'Ronald',
    TransactionDate: '12 / 10 / 2021',
    ProjectName: 'App Development',
    AmountPaid: '$90,000'
  },
  {
    Freelancer: 'Triump',
    TransactionDate: '12 / 10 / 2021',
    ProjectName: 'App Development',
    AmountPaid: '$90,000'
  },
  {
    Freelancer: 'Messy',
    TransactionDate: '12 / 10 / 2021',
    ProjectName: 'App Development',
    AmountPaid: '$90,000'
  },
  {
    Freelancer: 'Christino',
    TransactionDate: '12 / 10 / 2021',
    ProjectName: 'App Development',
    AmountPaid: '$90,000'
  },
  {
    Freelancer: 'Lalaj',
    TransactionDate: '12 / 10 / 2021',
    ProjectName: 'App Development',
    AmountPaid: '$90,000'
  }
];
exports.billsAndPaymentsColumns = [
  'Freelancer',
  'Transaction Date',
  'Project Name',
  'Amount Paid',
  'Reciept',
  'Cancellation'
];
exports.getSearchJobResultEndPoint='bx_block_posts/posts/search_jobs?query='
exports.employmentTerms = { short_term_work: 'Less than 30hrs/week' };
exports.contentTypeApi = 'application/json';
exports.getJobPostDetailMethod = 'GET';
exports.getJobPostDetailEndPoint = 'bx_block_posts/posts';

exports.noOfWeeks = 'No Of Weeks';
exports.paymentCard = 'PaymentCard';
exports.paypal = 'Paypal';
exports.googlepay = 'Google Pay';
exports.cardNumber = 'Card Number';
exports.labelEnterDetails = 'Enter Details';
exports.personalDetails = 'Enter the personal details';
exports.placeholderFirstname = 'First Name';
exports.placeholderLastname = 'Last Name';
exports.labelCvvNumber = 'CVV Number';
exports.digitNumber = 'Enter 4 digit number on the card.';
exports.labelExpiryDate = 'Expiry Date';
exports.entryDate = 'Enter expiry date';
exports.makePayment = 'Make Payment';
exports.contentType = 'application/json';
exports.apiEndPointGetFreelancerData = 'bx_block_posts/posts/';
exports.remainingEndPoint = '/proposals/';
exports.getDetailsMethodeType = 'GET';
exports.offline = 'OFFLINE';
exports.apiEndPointAddMilestone = 'bx_block_contract/terms';
exports.addMilestoneMethodeType = 'POST';
exports.successMessage = 'Milestone Added successfully';
exports.failedMessage = 'Failed to Add Milestone';
exports.okBtnText = 'Ok';
exports.getFreelancerEndPoint = 'account_block/freelancers/';
exports.varified = 'ID Verified';
exports.notVerified = 'ID is not Verified';

exports.errorMessage = '*Fill this field';

exports.meetingMsg =
  'Rahul Sharma, please join the super web designer meeting?';
exports.description =
  ' Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best. solutions to the same.this will help users solve their problems quicker andhelp inventors solve practical problems facing humankind';
exports.withdrawProposalsEndpoint="bx_block_proposals/proposals";
exports.httpPutMethod = "PUT";
exports.apiContentType = "application/json";
exports.ClientHomePageRequestEndPoint="bx_block_static_content/client_home_page_contents"
exports.listOfJobIdEndPoint = "bx_block_posts/posts/client_job_list"
exports.freelancerHomePageRequestEndPoint="bx_block_static_content/freelancer_home_pages"
exports.clientJobPostDetailsRequestEndPoint="bx_block_posts/posts/search"
exports.saveLaterEndPoint="bx_block_favourite/favourites"
exports.showFavouritesRequestEndPoint="bx_block_favourite/favourites"
exports.httpDeleteMethod = "DELETE";
exports.removeJobFromFavouriteRequestEndPoint="bx_block_favourite/favourites/"
exports.createProposalsApiEndPoint = "bx_block_proposals/proposals"

exports.freelancerProfileResponseEndPoint="account_block/accounts"
exports.fetchCurrentProposalsJobDetailsEndPoint="bx_block_posts/posts/"
exports.submitProposalsApiEndPoint="bx_block_proposals/proposals"
exports.submitProposalsDetailsRequestEndPoint="bx_block_proposals/proposals/submitted_all_jobs_proposals"
exports.allOffersDetailsRequestEndPoint="bx_block_contract/terms"
exports.acceptProposalRequestEndPoint="bx_block_contract/terms/"
exports.patchApiMethodType = 'PATCH';
exports.rejectProposalRequestEndPoint="bx_block_contract/terms/"
exports.activeProposalsRequestEndPoint="bx_block_contract/terms/show_active_offer"
exports.updateProfileStatusEndPoint="account_block/freelancers/update_freelancer_profile_online_status"
exports.getProfileStatusEndPoint="account_block/freelancers/get_freelancer_profile_online_status"
exports.updateClientProfileStatusEndPoint="account_block/accounts/update_client_profile_online_status"
exports.getClientProfileStatusEndPoint="account_block/accounts/get_client_profile_online_status"
exports.countryListEndPoint="/bx_block_categories/get_list_of_countries"
  // Customizable Area End
