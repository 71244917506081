//Customizable Area Start
import React from "react";
import {  Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import { TableContainer,RightBox } from "../../../components/src/ActiveStep0.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

export default class FreelanceSubmitted extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { submittedProposalsData,activeProposalTotalPages,activeProposalCurrentPage } = this.state;
    
    if (!submittedProposalsData) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      );
    }

    const submittedProposals = this.state.submittedProposalsData?.data?.attributes?.submitted_proposals?.submitted_proposals?.data ?? [];

    return (
      <>
      <RightBox>
        {submittedProposals.length === 0 ? (
          <Typography variant="h6" align="center" style={{ margin: "20px 0" }}>
            No submitted proposals found
          </Typography>
        ) : (
          submittedProposals.map((item: any, index: number) => (
            <TableContainer key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <div>
                    <Typography style={{ ...webStyle.date, fontWeight: 700 }}>
                      Initiated {this.formatDate(item.attributes.accepted_at)}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={webStyle.jobname as React.CSSProperties}
                >
                  {item.attributes.post.data.attributes.name}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={webStyle.tableContent as React.CSSProperties}
                >
                  <Typography style={webStyle.startDateText}>
                    Visit Page
                  </Typography>
                </Grid>
              </Grid>
            </TableContainer>
          ))
        )}
      </RightBox>
      {submittedProposals.length > 0 && (
      <Box style={webStyle.paginationContainer}>
       <Pagination
       count={activeProposalTotalPages}
       page={activeProposalCurrentPage}
       onChange={this.handleActiveProposalPageChange}
       color="primary"
     />
     </Box>
      )}
     </>
    );
  }
}
const webStyle = {
  tableContent: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    textAlign: "center",
  },
  date: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
};

