import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
// Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  demoData:
      {
        id: number,
        projectTitle: string,
        projectOwner: string,
        date: string,
        serviceImg: String,
        price: number,
    }[],
  
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
// Customizable Area End
}

export default class MyJobActiveContractController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
        activeButton: "offers",
        demoData: [
          {
              id: 1,
              projectTitle: "Field Management Services",
              projectOwner: "By Rahul jain",
              date: "Oct 24, 2033",
              serviceImg: 'Rectangle.png',
              price: 15,
          },
          {
              id: 2,
              projectTitle: "Field Management Services",
              projectOwner: "By Rahul jain",
              date: "Oct 24, 2033",
              serviceImg: 'Rectangle.png',
              price: 12.21,
          },
          {
              id: 3,
              projectTitle: "Field Management Services",
              projectOwner: "By Rahul jain",
              date: "Oct 24, 2033",
              serviceImg: 'Rectangle.png',
              price: 8,
          },
      ],
      };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    this.setState({
    
    });
    // Customizable Area End

  }

    // Customizable Area Start
    // Customizable Area End

  // Customizable Area Start
  goToActiveContract = () => {
   this.props.navigation.navigate("ActiveContractProjects");
  }
  // Customizable Area End
}
