import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    TextField,
    InputAdornment,
    MenuItem,
    Typography,
    Card,
    CardContent
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const config = require("./config");
import NavBar from "./NavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Rating from "@material-ui/lab/Rating";
// Customizable Area End

import SearchIpController, { Props } from "./SearchIpController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: 'Inter, Rubik, Roboto',
    }
   
});

export default class SearchIp extends SearchIpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <NavBar {...this.props}/>
                <Grid container style={styles.mainContainer}>
                    <Grid item xs={12}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                size="medium"
                                data-testid="search"
                                value={this.state.searchIp}
                                placeholder={config.searchPlaceholder}
                                onChange={this.handleSearchIp}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="disabled"/>
                                        </InputAdornment>
                                    ),
                                    style: styles.inputPropsStyle
                                }}
                            />
                            <TextField
                                select
                                id="recent"
                                data-testid="recent-search"
                                value={this.state.recentSearch}
                                onChange={this.handleRecent}
                                variant="filled"
                                size="small"
                                color="secondary"
                                style={styles.selectField}
                                InputProps={{ disableUnderline: true,style:styles.selectInputProps }}

                            >
                                <MenuItem value="mostRecent">{this.state.option}</MenuItem>
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={styles.secondContainer}>
                            <Grid item xs={12} sm={3}>
                                <Box style={styles.searchedBox}>
                                    <Typography style={styles.searchedText}>{this.state.ipValuer}</Typography>
                                    <CloseIcon fontSize="small"/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            <Box style={styles.searchedBox}>
                                    <Typography style={styles.searchedText}>{this.state.ipLawyer}</Typography>
                                    <CloseIcon fontSize="small"/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography style={styles.clearFilter}>{config.clearFilter}</Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={styles.resultText}>{config.results}</Typography>
                        <Box style={styles.resultedBox}>
                            {this.state.selectedResult.map((option, index) =>
                                <TextField
                                    key={index}
                                    select
                                    value={option}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{ style: styles.resultSelectField }}
                                >
                                    <MenuItem value={option}>{option}</MenuItem>
                                </TextField>
                            )}
                        </Box>
                        <Typography style={styles.projectCount}>{this.state.projectCount} {config.available}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={styles.gridContainer}>
                            {this.state.searchedResult.map((item, index) =>
                                <Grid item xs={12} sm={5} md={3} key={index}>
                                    <Box>
                                        <Card variant="outlined" style={styles.cardBox} >
                                            <CardContent style={styles.cardContainer}>
                                                <img src={item.searchedImg} alt='searchedImage' style={styles.searchedImg} />
                                                <Box style={styles.contentMainBox}>
                                                    <Box style={styles.profileDetails}>
                                                        <img src={item.userProfile} alt='avtar' style={styles.avtarImg} />
                                                        <Box>
                                                            <Typography style={styles.profileName}>{item.userName}</Typography>
                                                            <Typography style={styles.profileSub} color="secondary">{item.designation}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box style={styles.profileDetails}>
                                                        <Rating
                                                            name="read-only"
                                                            value={item.rating}
                                                            readOnly
                                                            icon={
                                                                <StarRoundedIcon fontSize="small" />
                                                            }
                                                            emptyIcon={
                                                                <StarBorderRoundedIcon fontSize="small" style={styles.starIcon} />
                                                            }
                                                        />
                                                        <Typography style={styles.ratingCount}>{item.ratingCount}</Typography>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" style={styles.cardBoxTwo}>
                                            <CardContent style={styles.cardContainer}>
                                                <Typography style={styles.userDetails}>{item.userDetail}</Typography>
                                                <Box style={styles.priceDetails}>
                                                    <Typography style={styles.price}>{item.price}</Typography>
                                                    <Typography style={styles.duration}>{item.duration}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <FooterWeb/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    inputPropsStyle: {
        borderRadius: '11px'
    },
    selectInputProps:{
        borderRadius:'10px',
        lineHeight:0,
        background: '#E6EBEF'
    },
    selectField:{
        width:'max-content',
        marginLeft:'10px'
    },
    mainContainer:{
        padding:'4% 5% 6%'
    },
    searchedBox:{
        display: 'flex',
        background: '#206FC4',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: '15px',
        color: '#fff',
        padding: '4px 0'
    },
    searchedText: {
        fontSize: '0.9rem',
        fontWeight: 500
    },
    clearFilter: {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#0A0A0A',
        textDecoration: 'underline'
    },
    secondContainer: {
        gap: '10px',
        paddingTop: '2%'
    },
    resultText: {
        fontSize: "1.2rem",
        fontWeight: 500,
        padding: '2% 0',
        fontFamily: 'Rubik'
    },
    resultSelectField: {
        borderRadius: '10px',
        border: '1px solid #0A0A0A',
        fontSize: '0.8rem',
        color: '#0A0A0A'
    },
    resultedBox: {
        gap: '10px',
        display: 'flex',
        flexWrap: 'wrap' as 'wrap'
    },
    projectCount: {
        fontWeight: 500,
        color: '#6C6C6C',
        padding: '3% 0'
    },
    cardBox: {
        borderRadius: '25px 25px 0 0',
        background: '#E6EBEF',
        padding: '10px'
    },
    cardContainer: {
        padding: '0'
    },
    starIcon: {
        color: '#ffb400'
    },
    contentMainBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    profileDetails: {
        display: 'flex',
        gap: '5%',
        flexWrap: 'wrap' as 'wrap'
    },
    avtarImg: {
        width: '40px',
        height: '40px'
    },
    profileName: {
        color: '#0A0A0A'
    },
    profileSub: {
        fontSize: '0.9rem',
        color: '#6C6C6C'
    },
    ratingCount: {
        fontSize: '0.9rem'
    },
    searchedImg: {
        width: '100%'
    },
    gridContainer: {
        gap: '20px',
        justifyContent: 'center'
    },
    cardBoxTwo: {
        borderRadius: '0 0 25px 25px',
        background: '#E6EBEF',
        padding: '10px'
    },
    userDetails: {
        fontSize: '0.8rem',
        marginBottom: '7px'
    },
    priceDetails: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap' as 'wrap'
    },
    price: {
        fontWeight: 200,
        color: '#2378D1'
    },
    duration: {
        fontSize: '0.9rem'
    }
}
// Customizable Area End

