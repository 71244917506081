export const landing = require("../assets/landing.png");
export const postProject = require("../assets/postProject.png");
export const findHire = require("../assets/findHire.png");
export const workApprove = require("../assets/workApprove.png");
export const dots = require("../assets/dot.png");
export const ipLawyer = require("../assets/ipLawyer.png");
export const ipEnforcement = require("../assets/ipEnforcement.png");
export const patentHolder = require("../assets/patentHolder.png");
export const prototypeDeveloper = require("../assets/prototype.png");
export const patentIllustration = require("../assets/illustration.png");
export const prototypeAgent = require("../assets/agent.png");
export const database = require("../assets/database.png");
export const governmentNgo = require("../assets/governmentNgo.png");
export const opentoContact = require("../assets/openToContact.png");
export const line = require("../assets/lines.png");
export const bAirBus = require("../assets/bAirBus.png");
export const arrow = require("../assets/arrow.png");
export const agile = require("../assets/agile.png");
export const officeSpace = require("../assets/officeSpace.png");
export const rightArrow = require("../assets/rightArrow.png");
export const leftArrow = require("../assets/leftArrow.png");
export const attendMeeting = require("../assets/attendMeeting.png");
export const meetingLine = require("../assets/meetingLine.png");
export const inventoMember = require("../assets/inventoMember.png");
export const sendIcon = require("../assets/sendIcon.png");
export const combinedShape = require("../assets/combinedShape.png");
export const emailToJoin = require("../assets/emailToJoin.png");
export const serachedImg = require("../assets/IpResult.png");
export const avtar = require("../assets/Rectangle.png");
export const dot = require("../assets/dots.png")
export const eliipse = require("../assets/eliipse.png")
export const inventohub = require("../assets/unnamed.png")
export const Horizontalinventohub = require("../assets/horizontalInvento.png")
export const avtara = require("../assets/avtara.svg");







