import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  Checkbox,
  FormControl,
  Button,
  Container
} from '@material-ui/core';
import { locationIcon } from '../../blocks/dashboard/src/assets';
import Rating from '@material-ui/lab/Rating/Rating';
import Avatar from '@material-ui/core/Avatar';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { blue } from '@material-ui/core/colors';
import GlobalJobProfileController, { Props }  from './GlobalJobProfileController';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class GlobalJobProfile extends GlobalJobProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const GreenCheckbox = withStyles({
      root: {
        color: '#535353',
        '&$checked': {
          color: blue[600]
        }
      },
      checked: {}
    })(Checkbox);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ArrowBackIcon className={classes.arrowStyle} onClick={this.toggleDrawer}/>
        <Container style={{ marginTop: '2rem' }}>
          <Grid container spacing={2} style={{ display: 'flex' }}>
            <Grid item xs={12} md={9} sm={6}>
              <Box className={classes.mainbox}>
                <Box style={{display:"flex",justifyContent:"space-between",marginBottom:"32px"}}>
                <div>
                <Typography className={classes.text}>
                  {this.props?.jobDetails?.data?.attributes.name}
                </Typography>
                <Box className={classes.subBox}>
                  <Box className={classes.locationBox}>
                    <img src={locationIcon} className={classes.location} />
                    <Typography className={{...classes.text2, marginTop:"0px",marginLeft:"5px"}}>
                    {this.props?.jobDetails?.data?.attributes.location}
                    </Typography>
                    <Rating
                      name="size-small"
                      defaultValue={this.props?.jobDetails?.data?.attributes.rating}
                      size="small"
                      style={{ marginLeft: '15px' }}
                    />
                    <Typography style={{ marginLeft: '15px' }}>
                     {this.props?.jobDetails?.data?.attributes.rating}
                    </Typography>
                  </Box>
                  <Box className={classes.locationBox}>
                    <Typography style={{ marginLeft: '6px', fontWeight: 500 }}>
                    {this.props?.jobDetails?.data?.attributes.created_at}
                    </Typography>
                  </Box>
                  </Box>
                  </div>
                  <div>
                <Box
                  style={{ display: 'flex', flexDirection:"column"}}
                >
                  <Typography className={classes.estdStyle}>Estd Budget: ${this.props?.jobDetails?.data?.attributes.per_day_charge}</Typography>
                  <Typography className={{...classes.text2,marginLeft:"0px",marginTop:"4px" }}>
                    Proposals:{this.props?.jobDetails?.data?.attributes.proposals}
                  </Typography>
                </Box>
                </div>
                </Box>

                <Box style={{marginBottom:"32px"}}>
                  <Typography className={classes.text}>
                    Project Descriptions:
                  </Typography>
                  <Typography className={classes.text2}>
                   {this.props?.jobDetails?.data?.attributes.description}
                  </Typography>
                </Box>

                <Box style={{marginBottom:"32px"}}>
                <Typography className={classes.text}>
                  Skills and expertise:
                </Typography>
                <Box style={{ display: 'flex' }}>
                  {this.props?.jobDetails?.data?.attributes.skills?.map((skill: any, index: number) => {
                    return (
                      <Typography className={classes.php} key={index}>
                        {skill}
                      </Typography>
                    );
                  })}
                </Box>
                </Box>

                <Box style={{marginBottom:"32px"}}>
                <Typography className={classes.text}>Project time:</Typography>
                <Box style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>1</Avatar>
                      <Box>
                        <Typography className={classes.text3}>
                        {this.props?.jobDetails?.data?.attributes.employment_term}
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          Project work
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>2</Avatar>
                      <Box>
                        <Typography className={classes.text3}>
                         {this.props?.jobDetails?.data?.attributes.duration}
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          Project length
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>3</Avatar>
                      <Box>
                        <Typography className={{...classes.text, marginLeft:"0px"}}>
                          Intermediate
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          I am looking for a mix of experience and value
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                </Box>

                <Box style={{marginBottom:"32px"}}>
                <Typography className={classes.text}>
                  Project time:{' '}
                  <span style={{ fontWeight: 600, color: '#555555',fontSize:"16px" }}>
                    Ongoing Project
                  </span>
                </Typography>
                </Box>

                <>

                <Box style={{marginBottom:"32px"}}>
                <Typography className={classes.text}>
                 Activity on this job:
                </Typography>
                <Box style={{ marginLeft: '10px' }}>
                 {this.state.activityData.map((item, index) => (
        <Typography key={index} style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
          <GreenCheckbox
            checked={true}
            disabled={true}
            size="medium"
            color="primary"
            name="containsUppercase"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
          {item.label}: {this.props.jobDetails?.data?.attributes[item.value]}
        </Typography>
                  ))}
                </Box>
                </Box>

                <Box style={{ marginBottom: "32px" }}>
          <Typography className={classes.text}>
            About the client:
          </Typography>
          <Box style={{ marginLeft: '10px' }}>
            <Typography style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Jobs Posted: 0
            </Typography>
            <Typography style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Total Client: 0
            </Typography>
            <Typography style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Avg hourly rate paid: 0
            </Typography>
          </Box>
          </Box>
  </>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Box className={classes.mainbox2}>
              <Typography className={classes.SignupText}>Explore Invento opportunities for free</Typography>
                <Box >
                  <FormControl fullWidth style={{display:"grid",justifyItems:"center"}}>
                    <Button
                      onClick={() => this.handleRedirect('/UserTypeSelection')}
                      className={classes.applyBtn}
                      variant="contained"
                      color="primary"
                    >
                      SIGNUP
                    </Button>                   
                  </FormControl>
                  <Box component={"div"} style={{display:"flex",justifyContent:"center", marginTop:"8px"}}>
                  <span className={classes.haveAccount}>Already have an account?{" "} </span>
                  <span className={classes.signIn} onClick={() => this.handleRedirect('/EmailAccountLoginBlock')}>Login</span>
                </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '100%',
    backgroundColor: 'lightblue',
    padding: '10px',
    border: '1px solid #ccc',
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#206FC4'
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 6px)'
    }
  },
  arrowStyle:{
    margin:"30px 0 0 30px",
    cursor:"pointer"
   },
  estdStyle:{
   margin:"10px 10px 0px 0px",
   fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter',
  },
  mainbox: {
    borderRadius: '15px',
    background:"rgb(245, 247, 249) none repeat scroll 0% 0%"
  },
  text: {
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter',
    marginTop: '10px',
    marginLeft: '20px'
  },
  haveAccount:{
  fontSize: '12px',
  fontFamily: 'Inter',
},
signIn:{
    fontSize: '12px',
    fontFamily: 'Inter',
    color:"rgb(91,161,212)",
    fontWeight:"bold" as "bold",
    cursor:"pointer" as "pointer",
    textDecoration: "underline",
    marginLeft: "3px"
},
  SignupText:{
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter',
    color:"#4084CC"
  },
  location: {
    height: '20px'
  },
  locationBox: {
    display: 'flex',
    marginTop: '6px',
    fontFamily: 'Inter',
    alignItems: 'center',
    color: '#555555'
  },

  subBox: {
    marginLeft: '20px'
  },
  text2: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginTop: '4px',
    marginLeft: '20px',
    color: '#6C6C6C',
    width: '80%'
  },
  php: {
    background: ' #EBF1F9',
    borderRadius: '30px',
    width: 'fit-content',
    padding: '5px 20px 5px 20px',
    color: '#246dc3',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginLeft: '15px',
    marginTop: '20px'
  },
  avtar: {
    backgroundColor: ' #1F6DC1',
    fontWeight: 200,
    padding: '8px',
    marginLeft: '20px'
  },
  subgrid: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px'
  },
  text3: {
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#6C6C6C'
  },
  mainbox2: {
    borderRadius: '15px',
    marginLeft: '25px',
    padding: '15px',
    background:"rgb(245, 247, 249) none repeat scroll 0% 0%"
  },
  text4: {
    fontSize: '22px',
    fontFamily: 'Roboto',
    padding: '20px 20px 0px 20px',
    color: ' #525252'
  },
  proposals: {
    fontSize: '17px',
    fontFamily: 'Roboto',
    padding: '10px',
    color: ' #525252',
    marginRight: '10px',
    marginTop: '10px'
  },
  budget: {
    fontSize: '18px',
    fontFamily: 'Roboto',
    color: ' #525252',
    marginLeft: '20px',

  },
  applyBtn: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#2378D1',
    marginTop: '18px',
    color:"white",
    width:"50%",
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  favouriteBtn: {
    padding: '12px',
    borderRadius: '8px',
    marginTop: '10px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  }
};

export default withStyles(webStyle)(GlobalJobProfile);
// Customizable Area End
