import React from "react";
//Customizable Area Start
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import FreelanceActive from "./FreelancerActive.web";
import FreelanceSubmitted from "./FreelanceSubmitted.web";
import { TableContainer,RightBox } from "../../../components/src/ActiveStep0.web";
import FreelancerInvitationToInterviews from "./FreelancerInvitationToInterviews.web";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End

export default class Freelance extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  render() {
    //Customizable Area Start
    const { activeButton } = this.state;

    const Loader = () => (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
      </div>
     );
     const { loadingOffers, loadingActiveProposals, loadingSubmittedProposals, loadingInvitationToInterview, loadingViewInvitation, loadingArchiveInterview } = this.state;

     if (loadingOffers || loadingActiveProposals || loadingSubmittedProposals || loadingInvitationToInterview || loadingViewInvitation || loadingArchiveInterview) {
       return <Loader />;
    }
    const handleActiveTab = (activeButton: string) => {
      switch (activeButton) {
        case "activeProposals":
          return <FreelanceActive navigation={this.props.navigation} activeProposalData={this.state.activeProposalData} submittedProposalsData={null} invitationToInterviewData={null}/>;
        case "submittedProposals":
          return <FreelanceSubmitted navigation={this.props.navigation}  activeProposalData={null} submittedProposalsData={this.state.submittedProposalsData} invitationToInterviewData={null}/>;
        case "invitationToInterview":
          return <FreelancerInvitationToInterviews navigation={this.props.navigation}  activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={this.state.invitationToInterviewData}/>;

      }
    };
    const hasOffers = (this.state.offerData?.data?.attributes?.offers?.offers?.data?.length ?? 0) > 0;
    //Customizable Area End
    return (
      //Customizable Area Start
      <>
      <Box style={webStyle.mainBox}>
        <Grid container>
          <Grid container spacing={4} style={{ marginTop: "10px" }}>
            <Box display="flex" flexDirection={"column"} alignItems={"center"} style={{ marginTop: "20px" }}>
              <Button
                data-test-id="offers"
                style={activeButton === "offers" ? webStyle.activeBtn : webStyle.inactivbt}
                onClick={() => this.handleButtonClick("offers")}
              >
                {`Offers(${this.state.offerData?.data?.attributes?.offers.total_count ?? 0})`}
              </Button>
              <Button
                data-test-id="activeProposals"
                style={activeButton === "activeProposals" ? webStyle.activeBtn : webStyle.inactivbt}
                onClick={() => this.handleButtonClick("activeProposals")}
              >
                {`Active Proposals(${this.state.activeProposalData?.data?.attributes?.active_proposals.total_count ?? 0})`}
              </Button>
              <Button
                data-test-id="submittedProposals"
                style={activeButton === "submittedProposals" ? webStyle.activeBtn : webStyle.inactivbt}
                onClick={() => this.handleButtonClick("submittedProposals")}
              >
                {`Submitted Proposals(${this.state.submittedProposalsData?.data?.attributes?.submitted_proposals?.total_count ?? 0})`}
              </Button>
              <Button
                data-test-id="invitationToInterview"
                style={activeButton === "invitationToInterview" ? webStyle.activeBtn : webStyle.inactivbt}
                onClick={() => this.handleButtonClick("invitationToInterview")}
              >
                {`Invitation to interviews(${this.state.invitationToInterviewData?.data?.attributes?.invitation_to_interview.total_count ?? 0})`}
              </Button>
            </Box>
            <Grid item style={{ flex: 1 }}>
              {activeButton === "offers" ? (
                <>
                  <RightBox>
                    {this.state.offerData?.data?.attributes?.offers?.offers?.data.map((item: any, index: number) => (
                      <TableContainer key={index}>
                        <Grid container>
                          <Grid item xs={12} sm={2} style={webStyle.tableContent as React.CSSProperties}>
                            <div>
                              <Typography style={{ ...webStyle.date, fontWeight: 700 }}>
                                {"Received"}
                              </Typography>
                              <Typography style={webStyle.date}>
                                {item.attributes.days_ago}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={3} style={webStyle.jobname as React.CSSProperties}>
                            {item.attributes.post.data.attributes.name}
                          </Grid>
                          <Grid item xs={12} sm={1} style={webStyle.amount as React.CSSProperties}>
                            $ {item.attributes.post.data.attributes.est_budget}
                          </Grid>
                          <Grid item xs={12} sm={2} style={webStyle.tableContent as React.CSSProperties}>
                            <div>
                              <Typography style={webStyle.startDateText}>
                                Start Date
                              </Typography>
                              <Typography style={{ ...webStyle.startDateText, fontWeight: 400 }}>
                                {this.formatDate(item.attributes.start_date)}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={2} style={webStyle.tableContent as React.CSSProperties}>
                            <div>
                              <Typography style={webStyle.startDateText}>
                                End Date
                              </Typography>
                              <Typography style={{ ...webStyle.startDateText, fontWeight: 400 }}>
                                {item.attributes.end_date}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={1} style={webStyle.tableContent as React.CSSProperties}>
                            <Button style={webStyle.rejectBtn} onClick={() => this.rejectProposal(item.id)} data-testid="reject-0">Reject</Button>
                          </Grid>
                          <Grid item xs={12} sm={1} style={webStyle.tableContent as React.CSSProperties}>
                            <Button style={webStyle.accepBtn} onClick={() => { this.handleAccept(); this.acceptProposal(item.id) }}>Accept</Button>
                          </Grid>
                        </Grid>
                      </TableContainer>
                    ))}
                  </RightBox>
                  {hasOffers ?
                    <Box style={webStyle.paginationContainer}>
                      <Pagination
                        count={this.state.totalPages}
                        page={this.state.currentPage}
                        onChange={this.handlePageChange}
                      />
                    </Box> :
                    <Typography style={webStyle.noProfile}>{"No offers found"}</Typography>
                  }
                </>
              ) : handleActiveTab(activeButton)}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
      //Customizable Area End
    );
  }
}

//Customizable Area Start
const webStyle = {
  mainBox: {
    padding: "30px",
  },
  leftBox: {
    display: "flex",
    justifyContent: "center",
    
  },
  findWork: {
    color: "#0A0A0A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  proposal: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },

  activeBtn: {
    background: "#206FC4",
    width: "300px",
    height: "50px",
    borderRadius: "25px",
    color: "#FFFFFF",
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
  },
  inactivbt: {
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
    color: "#434343",
    marginTop: "10px",
  },
  rightBox: {
    border: "1px solid #EEE0E0",
    borderRadius: "10px",
  },
  tableContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  date: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  rightGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    padding: "20px",
    width: "1006px",
    borderBottom: "1px solid #EEE0E0",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  amount: {
    color: "#414141",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "18px",
  },
  rejectBtn: {
    background: "#DBE7F6",
    color: "#206FC4",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
  },
  accepBtn: {
    background: "#206FC4",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Inter",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
noProfile:{
  fontSize:'1.5rem',
  paddingTop:'5%',
  textAlign:'center' as 'center',
  color:'grey'
}
};


//Customizable Area End
