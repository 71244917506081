import React from "react";
// Customizable Area Start
import { StyleRules, withStyles } from "@material-ui/core/styles";
import SendamessageController, { Props, configJSON } from "./SendamessageController.web";
import {Link} from "react-router-dom";
// Customizable Area End


export class Sendamessage extends SendamessageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            //Merge Engine DefaultContainer
            <>
                {/* Customizable Area Start */}
                {/* Merge Engine UI Engine Code */}
                <div className={classes.container}>
                    <div>

                        <input placeholder={configJSON.placeHolders.user} data-test-id="messageCount" className={classes.autocomplete} type="text" onChange={this.handleChange} value={this.state.userVal} list="users" />

                        <datalist id="users" >
                            {this.state.usersData && this.state.usersData.map((user) =>
                                <option value={user.attributes.first_name} key={user.id} />)}
                        </datalist>
                        <h6 className={classes.errorMessage}>{this.state.isErrorFormData.userName}</h6>

                    </div>
                    <div>
                        <textarea data-test-id="messageText" value={this.state.messageInputValue} placeholder={configJSON.placeHolders.message} className={classes.messageInput} onChange={this.handleMessageChange} />
                        <h6 className={classes.errorMessage}>{this.state.isErrorFormData.message}</h6>
                    </div>
                    <div className={classes.buttonLayout}>
                        <div>
                            <button className={classes.sendmessageButton} onClick={this.submitButton} data-test-id="sendMessageBtn">{configJSON.btnCreateText}</button>
                        </div>
                        <div>
                            <Link to="/AllMessages">
                            <button data-test-id="allMessages"
                                disabled={this.state.messagecount === 0}
                                className={classes.allmessagesButton}
                            >{configJSON.allMessagesText}({this.state.messagecount})</button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Merge Engine UI Engine Code */}
                {/* Customizable Area End */}
            </>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const styles: StyleRules = {
    root: {
        margin: '15px'
    },
    container: {
        marginTop: "10px",
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px'
    },
    autocomplete: {
        width: '400px'
    },
    buttonLayout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: '20px'
    },
    messageInput: {
        width: "400px",
        height: "100px",
        borderRadius: "5px"
    },
    errorMessage: {
        color: "red",
        fontSize: "10px",
        fontWeight: "normal"
    },
    sendmessageButton: {
        width: "150px",
        height: "40px",
        background: "#3399ff",
        color: "#fff",
        border: "none",
        borderRadius: "5px"
    },
    allmessagesButton: {
        width: "150px",
        height: "40px",
        background: "#cccc00",
        color: "#fff",
        border: "none",
        borderRadius: "5px"
    }
};
export default withStyles(styles)(Sendamessage);
// Customizable Area End