// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  Divider,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import HowToHireController, { Props } from './HowToHireController.web';
import NavBar from '../../landingpage/src/NavBar.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import {
  HireImg1,
  HireImg2,
  HireImg3,
  checkBoxIcon} from './assets';
import ClientNavBar from '../../dashboard/src/Components/ClientNavBar.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export default class HowToHire extends HowToHireController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { HowToHireRes } = this.state;
    const getSectionData = (sectionId: number) => {
      return HowToHireRes.find(
        (sectionData: { attributes: { section: any; }; }) => Number(sectionData.attributes.section) == sectionId
      )?.attributes;
    };
  
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
       {this.state.token?<ClientNavBar />:<NavBar {...this.props} />}
        <Box marginTop={'1%'} padding={'10px'}>
          {/* section 1 */}
          <Box
            display={'flex'}
            style={{ backgroundColor: 'E1F0FF' }}
            padding={'10px 10px 10px 40px'}
            maxWidth={'100%'}
            maxHeight={'30%'}
            borderRadius={'15px'}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              flex={'0.6'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                marginTop={'3%'}
              >
                <Typography
                  style={{
                    fontSize: '40px',
                    fontFamily: 'Inter',
                    fontWeight: 600
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.title}}
                  />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 400
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.description}}
                  />

                <Box marginTop={'5%'}>
                  <Button
                    data-testid={'FindTalent1'}
                    onClick={()=>{this.handleStorageUpdate(); this.handleRedirection(this.state.token? "SearchTalent":"FullWidthTab")}}
                    variant="contained"
                    style={{
                      borderRadius: '10px',
                      width: 'max-content',
                      backgroundColor: '#2275CD',
                      color: '#FFF',
                      textTransform: 'none'
                    }}
                  >
                    {getSectionData(1)?.button_text}
                  </Button>
                </Box>
              </Box>
              {!this.state.token?
              <Box display={'flex'} justifyContent={'flex-start'}>
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 400
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.sub_title}}
                  />
                <Typography
                  onClick={()=>this.handleRedirection("UserTypeSelection")}
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    color: '#2275CD',
                    cursor: 'pointer'
                  }}
                >
                   Sign up here
                </Typography>
              </Box>
              :null }
            </Box>

            <Box display={'flex'} flexDirection={'column'} flex={'0.3'}>
              <img
                src={getSectionData(1)?.image.url}
                style={{
                  borderRadius: '20px',
                  width:"575px",
                  height:"432px"
                }}
              />
            </Box>
          </Box>
          {/* section2 */}
          <Box
            margin={'5%'}
            display={'grid'}
            gridRowGap={'2rem'}
            marginTop={'2%'}
          >
            <Box>
              <Typography
                style={{ ...webStyle.subHeading, marginBottom: '1%' }}
                dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.title}}
                />
              <Typography
                style={{ ...webStyle.text, width: '70%', marginBottom: '1%' }}
                dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.description}}
                />
              <Divider />
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              gridColumnGap={'5rem'}
            >
              <Box >
                <img
                 src={ getSectionData(2)?.image.url }
                  width={'450px'}
                  height={'370px'}
                  style={{ borderRadius: '10px' }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                width={'40%'}
              >
                <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.sub_title}}
                />
                <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.sub_title_description}}
                />
                <Button
                  data-testid={'FindTalent2'}
                  onClick={()=>{this.handleStorageUpdate();this.handleRedirection(this.state.token? "SearchTalent":"FullWidthTab")}}
                  variant="contained"
                  style={{
                    borderRadius: '10px',
                    width: 'max-content',
                    backgroundColor: '#2275CD',
                    color: '#FFF',
                    textTransform: 'none'
                  }}
                >
                {getSectionData(2)?.button_text}
                </Button>
              </Box>
            </Box>
          </Box>
          {/* section 3 */}
          <Box
            margin={'5%'}
            display={'flex'}
            flexDirection={'column'}
            gridRowGap={'1rem'}
          >
            <Box>
              <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(3)?.title}}
                />
            </Box>
            <Box>
              <Divider />
            </Box>
            <Box display={'flex'} justifyContent={"space-between"}>
              <Box width={'50%'}>
                <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(3)?.description}}
                />
              </Box>
              <Box display={'grid'} width={'40%'} gridRowGap={'1rem'} marginTop={"1.5%"}>
                {getSectionData(3)?.sub_title.map((item: string ,index:number) => (
                  <Box display={'flex'} gridColumnGap={'1rem'}>
                    <Box>
                      <img src={checkBoxIcon} />
                    </Box>
                    <Box>
                    <Typography
                     key={index}
                    style={{ ...webStyle.text, fontSize: '16px', fontWeight: 500 }}
                    dangerouslySetInnerHTML={{ __html: item }}/>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {/* section 4 */}
            <Box marginTop={'5%'}>
              <Divider />
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'self-start'}
            gridColumnGap={'3rem'}
            margin={'5%'}
            marginTop={'-2%'}
          >
            <Box>
              <img
                src={getSectionData(4)?.image.url}
                width={'430px'}
                height={'480px'}
                style={{ borderRadius: '10px' }}
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gridRowGap={'1rem'}>
              <Box display={"grid"}>
              <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.title}}
                />
              <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.description}}
                />
              </Box>
              <Box display={"grid"}>
              <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.sub_title}}
                />
              <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.sub_title_description}}
                />
              </Box>

            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  subHeading: {
    fontFamily: 'Inter',
    fontSize: '23px',
    fontWeight: 600
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400
  }
};

// Customizable Area End
