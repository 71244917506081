import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import storage from "../../../framework/src/StorageProvider";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import  { Color } from "@material-ui/lab/Alert";
interface WorkExperience {
  company: string;
  job_title: string;
  location: string;
  start_date: string;
  end_date: string;
  description: string;
}

interface AddExperienceResponse {
  id: number;
  account_id: number;
  company: string;
  job_title: string;
  location: string;
  start_date: string;
  end_date: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface EditExperienceResponse {
  account_id: number;
  id: number;
  company: string;
  job_title: string;
  location: string;
  start_date: string;
  end_date: string;
  description: string;
  created_at: string;
  updated_at: string;
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}
interface ValidResponseType {
  message: object;
  data: object;
}

interface InvalidResponseType {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  addExperience: boolean;
  handleAddExperience: () => void;
  // userId: number;
  buttonType: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  experience:{
    startDate:string;
    endDate:string;
  };
  company:string;
  jobTitle:string;
  expLocation:string;
  description:string;
  validationError:boolean;
  isAlert:boolean;
  alertMsg: string;
  alertType: Color;
  userId: number;
  // Customizable Area End
}
interface SS { }

export default class AddExperienceModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addExpCallId:string = "";
  updateUserExperienceID:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];

    this.state = {
      experience:{
        startDate:'',
        endDate:'',
      },
      company:'',
      jobTitle:'',
      expLocation:'',
      description:'',
      validationError:false,
      isAlert:false,
      alertMsg: "",
      alertType: "success",
      userId: NaN
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (this.inValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
      else if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  
  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson);
  };

  inValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: AddExperienceResponse & EditExperienceResponse) => {
    if (apiRequestCallId === this.addExpCallId) {
      this.setState({
        userId: responseJson.id
        },()=>{
          this.handleClose();
        });
    }
    if(apiRequestCallId === this.updateUserExperienceID){
      this.handleClose();
    }
  };

  apiFailureCallBacks = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.addExpCallId) {
      this.helplineFailureCallBack(responseJson);
    }
    if(apiRequestCallId === this.updateUserExperienceID){
      this.updateUserExperienceIDFailureCallBack(responseJson);
    }
  };

  onCompanyChange = (event: { target: { value: string } }) => {
    this.setState({ company: event.target.value })
  };
  

  onJobTitleChange = (event: { target: { value: string } }) => {
    this.setState({ jobTitle: event.target.value })
  };

  onLocationChange = (event: { target: { value: string } }) => {
    this.setState({ expLocation: event.target.value })
  };

  onDescriptionChange = (event: { target: { value: string } }) => {
    this.setState({ description: event.target.value })
  };


  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : await storage.get("authToken")
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleUpdateUserExperience = async () => {
    const apiEditData = {
      work_experience: {
        company: this.state.company,
        job_title: this.state.jobTitle,
        location: this.state.expLocation,
        start_date: this.state.experience.startDate,
        end_date: this.state.experience.endDate,
        description: this.state.description
      }
    };
    this.updateUserExperienceID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.apiEndPointupdateUserExperience+`/${this.state.userId}`,
      body: apiEditData
    });

  }

  handleClose = () => {
    this.props.handleAddExperience();
     this.setState({
      company : "",
      jobTitle: "",
      expLocation: "",
       experience:{
         startDate: "",
         endDate: ""
       },
        description: ""
     })
  }
  
  habdleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.handleValidation(event)
    const { name, value } = event.target;
    this.setState((prevState) => {
        let updatedExperience = { ...prevState.experience, [name]: value };
        if (name === 'endDate' && new Date(value) < new Date(prevState.experience.startDate)) {
            updatedExperience.endDate = '';
        }
        if (name === 'startDate' && new Date(value) > new Date(prevState.experience.endDate)){
          updatedExperience.endDate = '';
        }
        return { experience: updatedExperience };
    });
  }

  handleValidation = (event:React.ChangeEvent<HTMLInputElement>) => {
    const jobTitleReg = /^[a-zA-Z ]*$/;
    if(event.target.name === 'jobTitle'){
      if(!jobTitleReg.test(event.target.value)){
        this.setState({validationError:true})
      }else{
        this.setState({validationError:false})
      }
    }
  }

  postFormApi = async() => {
    const apiData = {
        work_experience: {
          company:this.state.company,
          job_title: this.state.jobTitle,
          location: this.state.expLocation,
          start_date: this.state.experience.startDate,
          end_date: this.state.experience.endDate,
          description: this.state.description
    }
    };
    this.addExpCallId =await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.apiEndPointupdateUserExperience,
      body: apiData
    });
  };

  helplineFailureCallBack = (responseJson: InvalidResponseType) => {
   
  };

  updateUserExperienceIDFailureCallBack = (responseJson: InvalidResponseType) => {
  };
  // Customizable Area End
}
