import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  postProject,
  findHire,
  workApprove,
  ipLawyer,
  ipEnforcement,
  patentHolder,
  prototypeDeveloper,
  patentIllustration,
  prototypeAgent,
  governmentNgo,
  database
} from './assets';
import storage from 'framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  ipJobs: null | HTMLElement;
  ipTalent: null | HTMLElement;
  search: string;
  searchSelect: string;
  inventoFacility: {
    title: string;
    description: string;
    image: string;
  }[];
  categoryArray: {
    image: string;
    title: string;
    desciption: string;
  }[];
  professionalDetails: string;
  chooseProfessional: string[];
  freelancerArray: string[];
  memberName: string;
  position: string;
  feedbackDetails: string;
  email: string;
  subscribersEmail:string;
  validationError: boolean;
  errorMessage: string;
  sectionData1:any
  sectionData2:any
  sectionData3:any
  sectionData4:any
  sectionData5:any
  sectionData6:any
  sectionData7:any
  sectionData8:any
  sectionData9:any
  SubscribersData:any
  successMessage:string
  selectPlaceholder:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sectionId1:string=""
  sectionId2:string=""
  sectionId3:string=""
  sectionId4:string=""
  sectionId5:string=""
  sectionId6:string=""
  sectionId7:string=""
  sectionId8:string=""
  sectionId9:string=""
  postSubscribersID:string=""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),


    ];

    this.state = {
      anchorEl: null,
      ipJobs: null,
      ipTalent: null,
      sectionData1:[],
      sectionData2:[],
      sectionData3:[],
      sectionData4:[],
      sectionData5:[],
      sectionData6:[],
      sectionData7:[],
      sectionData8:[],
      sectionData9:[],
      SubscribersData:[],
      search: '',
      searchSelect: 'IP Talent',
      inventoFacility: [
        {
          title: 'Post a Project',
          description:
            'Ready to Transform Your Vision into Reality? Post Your Project Today and Collaborate with Top IP Professionals on InventoHub!',
          image: postProject
        },
        {
          title: 'Find & Hire',
          description:
            'Finding and Hiring Top IP Professionals is a Breeze with InventoHub!',
          image: findHire
        },
        {
          title: 'Work & Approve',
          description:
            'Unlock Opportunities and Secure Success: Discover Work and Gain Approval with InventoHub!',
          image: workApprove
        }
      ],
      categoryArray: [
        {
          image: ipLawyer,
          title: 'IP Lawyer',
          desciption:
            "Your IP Guardians: Safeguarding Innovation, Empowering Creators - Trust the Expertise of Invento'Hubs IP Lawyers!"
        },
        {
          image: ipEnforcement,
          title: 'IP Enforcement',
          desciption:
            "Protecting Ideas, Ensuring Justice - Unleash the Power of Invento'Hubs IP Enforcement Experts!"
        },
        {
          image: patentHolder,
          title: 'Patent Holder',
          desciption:
            "Empowering Inventors, Securing Futures - Patent Holders Thrive with Invento'Hubs Support!"
        },
        {
          image: prototypeDeveloper,
          title: 'Prototype Developer',
          desciption:
            "Bringing Visions to Life - Innovate and Elevate with InventoHub's Prototype Developers!"
        },
        {
          image: patentIllustration,
          title: 'Patent Illustrator',
          desciption:
            "Visualizing Brilliance - Illuminate Your Patents with InventoHub's Expert Patent Illustrators!"
        },
        {
          image: database,
          title: 'Patent Database',
          desciption:
            "Unlocking Knowledge, Fueling Progress - Harness the Power of InventoHub's Extensive Patent Database!"
        },
        {
          image: prototypeAgent,
          title: 'Ptototype Agent',
          desciption:
            "Navigating the Path to Protection - Trust InventoHub's Patent Agents to Safeguard Your Innovations!"
        },
        {
          image: governmentNgo,
          title: 'Government, ngos msme',
          desciption:
            'Lorum Ipsum Rather skip the guesswork? Shop projects already'
        }
      ],
      professionalDetails:
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best. solutions to the same.this will help users solve their problems quicker and help inventors solve practical problems facing humankind',
      chooseProfessional: [
        'Choose IP professional',
        'Identify skill set required',
        'Choose location city or country '
      ],
      freelancerArray: [
        'Find an opportunity',
        'Do hardwork to make no.1',
        'Explore to different ways to earn'
      ],
      memberName: 'Rosinante Vole',
      position: 'Patent Database',
      feedbackDetails:
        'Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum.',
      email: '',
      subscribersEmail:"",
      validationError: false,
      errorMessage: '',
      successMessage:"",
      selectPlaceholder:"dskjfskdjfk"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson ) {
      switch (apiRequestCallId) {
        case this.sectionId1:         
        this.setState({sectionData1:responseJson});
        break;
        case this.sectionId2: 
        this.setState({sectionData2:responseJson});
        break;
        case this.sectionId3: 
        this.setState({sectionData3:responseJson});
        break;
        case this.sectionId4: 
        this.setState({sectionData4:responseJson});
        break;
        case this.sectionId5: 
        this.setState({sectionData5:responseJson});
        break;
        case this.sectionId6: 
        this.setState({sectionData6:responseJson});
        break;
        case this.sectionId7: 
        this.setState({sectionData7:responseJson});
        break;
        case this.sectionId8: 
        this.setState({sectionData8:responseJson});
        break;
        case this.sectionId9: 
        this.setState({sectionData9:responseJson});
        break;
        case this.postSubscribersID: 
        if (responseJson?.error && responseJson.error.length > 0) {
          this.setState({ errorMessage: responseJson.error[0] });
          return;
        } 
        else{
          this.setState({SubscribersData:responseJson});
          this.setState({ subscribersEmail: "", successMessage: "* Email sent successfully" });
          setTimeout(() => {
            this.setState({ successMessage: "" });
          }, 5000); 
        }
        break;
      }}

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
      this.getLandingPageSectionOne(1)
      this.getLandingPageSectionTwo(2)
      this.getLandingPageSectionThree(3)
      this.getLandingPageSectionFour(4)
      this.getLandingPageSectionFive(5)
      this.getLandingPageSectionSix(6)
      this.getLandingPageSectionSeven(7)
      this.getLandingPageSectionEight(8)
      this.getLandingPageSectionNine(9)
  }


  primaryButtonUrl = () => {
    return (
      this.state.sectionData4?.data &&
      this.state.sectionData4?.data[0]?.attributes?.primary_button_url
    );
  };
  primaryButtonUrlTwo = () => {
    return (
      this.state.sectionData8?.data &&
      this.state.sectionData8?.data[0]?.attributes?.primary_button_url
    );
  };
  primaryButtonText = () => {
    return (
      this.state.sectionData4?.data &&
      this.state.sectionData4?.data[0]?.attributes?.primary_button_text
    );
  };
  primaryButtonTextTwo = () => {
    return (
      this.state.sectionData8?.data &&
      this.state.sectionData8?.data[0]?.attributes?.primary_button_text
    );
  };
  secondaryButtonUrl = () => {
    return (
      this.state.sectionData4?.data &&
      this.state.sectionData4?.data[0]?.attributes?.secondary_button_url
    );
  };
  secondaryButtonUrlTwo = () => {
    return (
      this.state.sectionData8?.data &&
      this.state.sectionData8?.data[0]?.attributes?.secondary_button_url
    );
  };
  secondaryButtonText = () => {
    return (
      this.state.sectionData4?.data &&
      this.state.sectionData4?.data[0]?.attributes?.secondary_button_text
    );
  };
  secondaryButtonTextTwo = () => {
    return (
      this.state.sectionData8?.data &&
      this.state.sectionData8?.data[0]?.attributes?.secondary_button_text
    );
  };
  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailReg.test(event.target.value)) {
      this.setState({
        validationError: true,
        errorMessage: '* Only valid email addresses are allowed'
      });

    } else {
      this.setState({
        validationError: false,
        errorMessage: ''
      });
    }
    this.setState({ subscribersEmail: event.target.value });
  };
  handleSearch = (event: { target: { value: any; }; }) => {
    this.setState({ search: event.target.value });
  };

  handleSelectChange = (event: { target: { value: any; }; }) => {
    this.setState({ searchSelect: event.target.value });
  };

  keyPress = (event: { keyCode: number; }) => {
    if (event.keyCode === 13) { 
        const { search, searchSelect } = this.state;
        const trimmedSearch = search.trim(); 

        if (trimmedSearch && searchSelect) { 
            storage.set('searchQuery', search);
            storage.set('searchSelect', searchSelect);

            const searchMessage = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            searchMessage.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'FullWidthTab'
            );
            searchMessage.addData(
                getName(MessageEnum.NavigationPropsMessage),
                this.props
            );
            runEngine.sendMessage(searchMessage.id, searchMessage);
        } else {
           
        }
    }
};

  getLandingPageSectionOne = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId1 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${1}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageSectionTwo = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId2 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${2}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageSectionThree = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId3 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${3}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getLandingPageSectionFour = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId4 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${4}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getLandingPageSectionFive = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId5 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${5}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getLandingPageSectionSix = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId6 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${6}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getLandingPageSectionSeven = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId7 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${7}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageSectionEight = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId8 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${8}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageSectionNine = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionId9 = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_static_content/landing_page_contents/show_data?section=${9}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
handleSubscribersEmail = () => {
    if (!this.state.subscribersEmail) {
      this.setState({ errorMessage: "* Please enter your email" });
      return;
    }
    this.postSubscribersSectionTen();
   
  };
  
  
  postSubscribersSectionTen = () => {
    //post
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      subscriber: {
        email: this.state.subscribersEmail,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSubscribersID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_subscribers/subscribers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
