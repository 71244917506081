import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from 'framework/src/Utilities';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  type: string;
  category: string;
  legal: any;
  other: any;
  experienceInIpEnforcement: any;
  focusArea: any;
  linkedInProfileLink: any;
  countryOfOperation: any;
  cityOfOperation: any;
  lawFirm: any;
  fees: any;
  documentation: any;
  civilCase: any;
  criminalCase: any;
  raid: any;
  featuredInTheMedia: any;
  ItConference: any;
  article: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  step4FileUplod1: any;
  step4FileUplod2: any;
  step4FileUplod3: any;
  seconds: any;
  governmentAward: any;
  industryAward: any;
  academicAward: any;
  error: any;
  showPageError: boolean;
  enforcementDetails: any;
  disableBtn: boolean;
  verfication: boolean;
  successPage: boolean;
  disabledResendOtp: boolean;
  timerExpired: boolean;
  authToken: string;
  otpError: any;
  otpValue: any;
  VerificationError: boolean;
  step4FileUplod1Error: string;
  step4FileUplod2Error: string;
  step4FileUplod3Error: string;
  profileName: string;
  emailValid: string;
  radioError: any;
  numberError: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerIpEnforcementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  freelanceripenforcementById: string = "";
  sendEmailOtpCallId: string = "";
  otpVerificationForEmailCallId: string = "";
  myInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      type: '',
      category: '',
      legal: '',
      other: '',
      experienceInIpEnforcement: '',
      focusArea: '',
      linkedInProfileLink: '',
      countryOfOperation: '',
      cityOfOperation: '',
      lawFirm: '',
      fees: '',
      documentation: '',
      civilCase: '',
      criminalCase: '',
      raid: '',
      featuredInTheMedia: 'Yes',
      ItConference: 'Yes',
      article: 'Yes',
      anchorElement: null,
      openInfoPopper: false,
      step4FileUplod1: '',
      step4FileUplod2: "",
      step4FileUplod3: "",
      governmentAward: '',
      industryAward: "",
      academicAward: "",
      error: false,
      showPageError: false,
      disableBtn: false,
      enforcementDetails: {},
      verfication: false,
      successPage: false,
      seconds: 60,
      authToken: "",
      timerExpired: false,
      disabledResendOtp: true,
      otpError: "",
      otpValue: '',
      VerificationError: true,
      profileName: "",
      step4FileUplod1Error: "",
      step4FileUplod2Error: "",
      step4FileUplod3Error: "",
      emailValid: "",
      radioError: {},
      numberError: {}
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      profileName: await getStorageData("firstName")
    })
    // Customizable Area End
  }
  // Customizable Area Start
  handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
   
    let errCopy = {... this.state.error }
    const { name, value } = event.target;
    let errorKeyObject :{[key:string]:string}= {featuredInTheMedia:"step4FileUplod1",ItConference:"step4FileUplod2",article:"step4FileUplod3"}
  
      if (value == "No") {
      
          delete errCopy[errorKeyObject[name]]

      }
    
    this.setState(({
      [name]: value,
      error: errCopy, 
    } as unknown) as Pick<S, keyof S>);
      this.setState({  });
    
  }
  handleNext = () => {
    const { activeStep } = this.state;
    let radiofields: string[] = [];

    let fields: any = [];
    if (activeStep === 0) {
      fields = ['legal', 'other','focusArea','experienceInIpEnforcement'];
    } else if (activeStep === 1) {
      fields = ["cityOfOperation"];
    } else if (activeStep === 2) {
      fields = ["documentation", "civilCase", "criminalCase", "raid",];
    } else if (activeStep === 3) {
      if (this.state.featuredInTheMedia === 'Yes') {
        fields.push('step4FileUplod1');
      }
      if (this.state.ItConference === 'Yes') {
        fields.push('step4FileUplod2');
      }
      if (this.state.article === 'Yes') {
        fields.push('step4FileUplod3');
      }
    }

    const errors: any = {};

    fields.forEach((field: string) => {
      const fieldValue = (this.state as any)[field];
      const minLength = 3;
      const maxLength = 200;
      if (fieldValue === '') {
        errors[field] = "*This is required";
      } else if (fieldValue.length < (field !=='experienceInIpEnforcement'?minLength:1)) {
        errors[field] = '*Should contain minimum 3 characters';
      } else if (fieldValue.length > maxLength) {
        errors[field] = '*Should contain only letters and maximum of 200';
      }
      else {
        delete errors[field];
      }
    });


    let numberfields: any = [];    

    if(activeStep == 2){
      numberfields = ["fees"];
    }
    let numError: any = {};
    
    numberfields.forEach((numfield: string) => {
      const fieldValue = (this.state as any)[numfield];
      const maxDigits = 7;
      if (fieldValue === '') {
        numError[numfield] = "*This is required";
      } else if ( /^\d{0,7}$/.test(fieldValue) == false) {
        numError[numfield] = "*Only digits are allowed";
      } else if (fieldValue.length > maxDigits) {
        numError[numfield] = '*Allowed numbers with a maximum of 7 digits';
      }
      else{
        delete numError[numfield];
      }
    
    });


    if (activeStep === 0) {
      radiofields = ['type', 'category'];
    } else if (activeStep === 1) {
      radiofields = ['countryOfOperation', 'lawFirm'];
    }

    let radioError: any = {};
    radiofields.forEach((radiofields: string) => {
      if ((this.state as any)[radiofields] === '') {
        radioError[radiofields] = "*This is required";
      }else{
        delete radioError[radiofields];
      }
    });


    if (Object.keys(errors).length === 0 && Object.keys(radioError).length === 0  && Object.keys(numError).length === 0) {
      this.setState({ error: errors, radioError: radioError,numberError: numError });
      this.updateStepNum();
    } else {
      this.setState({ error: errors, radioError: radioError,numberError: numError });
    }
  };

  updateStepNum = () => {
    const { error, activeStep, featuredInTheMedia, ItConference, article } = this.state;

    if (Object.keys(error).length === 0) {
      if (activeStep === 4) {
        this.freelanceripenforcementDetails();
      } else {
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1
        }));
      }
    } else {
      let shouldShowPageError = false;
      if (activeStep === 3) {
        if ((featuredInTheMedia === 'Yes' && this.state.step4FileUplod1 === '') ||
          (ItConference === 'Yes' && this.state.step4FileUplod2 === '') ||
          (article === 'Yes' && this.state.step4FileUplod3 === '')) {
          shouldShowPageError = true;
        }
      } else {
        shouldShowPageError = true;
      }

      this.setState({ showPageError: shouldShowPageError });
    }

  
    if (
      activeStep === 3 &&
      (
        (featuredInTheMedia === 'Yes' && this.state.step4FileUplod1 !== '') ||
        (ItConference === 'Yes' && this.state.step4FileUplod2 !== '') ||
        (article === 'Yes' && this.state.step4FileUplod3 !== '')
      ) &&
      (
        (featuredInTheMedia === 'No' || this.state.step4FileUplod1 !== '') &&
        (ItConference === 'No' || this.state.step4FileUplod2 !== '') &&
        (article === 'No' || this.state.step4FileUplod3 !== '')
      )
    ) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1
      }));
    } else if (activeStep === 4) {
      this.freelanceripenforcementDetails();
    }
  };


  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
      error: {},
      numberError:{},
      radioError:{}
    }));
  };

  displayTime() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }

  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    this.sendEmailOtp();
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000);
  };

  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } else {
      this.otpVerificationForEmail()
    }
  }

  handleClickInfo = (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    this.setState(prevState => ({
      anchorElement: currentTarget,
      openInfoPopper: !prevState.openInfoPopper
    }));
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value,
    } as unknown) as Pick<S, keyof S>);
  };

  validation = () => {
    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9._%+-]*)?$/;
    const { linkedInProfileLink } = this.state;
    if (!websiteRegex.test(linkedInProfileLink)) {
      this.setState({
        error: {
          ...this.state.error,
          linkedInProfileLink: "*Should contain a valid website link"
        }
      });
    }
  };

  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  handleFileUpload = (event: any) => {
    const { files } = event.target;
    const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
    const stateKeyRef = event.target.name;
    const selectedFile = files[0];
    const maxSizeMB = 5;
    let error = "";

    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      if (!allowedExtensions.test(selectedFile.name)) {
        error = '*Invalid file type. Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.';
      } else if (fileSizeMB > maxSizeMB) {
        error = "*File size exceeds the maximum limit of 5 MB.";
      }

      // Set state only if there is no error
      if (!error) {
        this.setState(({
          [stateKeyRef]: selectedFile,
          error: {
            ...this.state.error,
            [stateKeyRef]: ""
          }
        } as unknown) as Pick<S, keyof S>);
      } else {
        this.setState(({
          error: {
            ...this.state.error,
            [stateKeyRef]: error
          }
        } as unknown) as Pick<S, keyof S>);
      }
    } else {
      // Reset file and error if no file selected
      this.setState(({
        [stateKeyRef]: null,
        error: {
          ...this.state.error,
          [stateKeyRef]: error
        }
      } as unknown) as Pick<S, keyof S>);
    }
  };

  handleLinkedinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const regexExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

    if (regexExp.test(value)) {
      if ((this.state as any)[name] !== value) {

        value.length >= 10 ? delete updatedError[name] : updatedError[name] = "*Should contain minimum 2 characters";
        this.setState({
          [name]: value,
          error: updatedError
        } as Pick<S, keyof S>);
      }
      else {
        updatedError[name] = "*Please enter a valid Linkedin URL";
        this.setState({ error: updatedError });
      }
    }
  }


  handletextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?! )[A-Za-z ]{0,200}$/.test(value)) {
      // Check if the value is changing before updating state
      if ((this.state as any)[name] !== value) {
          value.length >= 3 ? delete updatedError[name] : updatedError[name] = "*Should contain minimum 3 characters";
          this.setState({
              [name]: value,
              error: updatedError
          } as Pick<S, keyof S>);
      }
  } else {
      updatedError[name] = "*Should contain only letters and maximum of 200";
      this.setState({ error: updatedError });
      setTimeout(() => {
        this.setState({ error: false });
      }, 2000);
  }
    
  };
  handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const numberFields = ["experienceInIpEnforcement"];
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}$/;
      if (regexExp.test(value)) {
        delete updatedError[name];
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            error: updatedError
          } as Pick<S, keyof S>);
        }
        if (Number(value) > 99) {
          updatedError[name] = "*Should contain only numbers";
          this.setState({ error: updatedError });
        }
      } else {
        updatedError[name] = "*Allowed numbers with a maximum of 2 digits";
        this.setState({ error: updatedError });
        setTimeout(() => {
          this.setState({ error: false });
        }, 1000);
      }
    }

  };
  handleExpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const numberFields = ["experienceInIpEnforcement"];
    
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}(\.\d{0,1}){0,6}?$/;
  
      // Use regex to check if the value contains only allowed characters
      if (regexExp.test(value)) {
        delete updatedError[name];
        // Check if the value is changing before updating state
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            error: updatedError
          } as Pick<S, keyof S>);
        }
      } else {
        updatedError[name] = "*Enter a valid experience format like '2 or 2.2'.";
        this.setState({ error: updatedError });
        setTimeout(() => {
          this.setState({ error: {} });
        }, 1000);
      }
    }
  };

  handleFeesFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numberFields = ["fees"];
    let updatedError = { ...this.state.numberError };

    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,7}$/;
      if (regexExp.test(value)) {
        delete updatedError[name];
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            numberError: updatedError
          } as Pick<S, keyof S>);
        }
      } else {
        updatedError[name] = "*Should contain only numbers with a maximum of 7 digits";
        this.setState({ numberError: updatedError });
        setTimeout(() => {
          this.setState({ numberError: {} });
        }, 1000);
      }
      }
    
  };

  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
    removeStorageData("firstName");
    removeStorageData("lastName");
    removeStorageData("password");
    removeStorageData("email");
    removeStorageData("country");
    removeStorageData("job");
  }

  handleLogoClick = () => {
    this.props.navigation.navigate("LandingPage");
  }

  handleMouseEnterInfo = (event:React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    this.setState({
      anchorElement: currentTarget,
      openInfoPopper: true
    });
  };

  handleMouseLeaveInfo = () => {
    this.setState({
      openInfoPopper: false
    });
  };

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.freelanceripenforcementById) {
        this.setState({ disableBtn: false })
        if (responseJson.data) {
          this.sendEmailOtp();
        }
        else if (responseJson.errors) {
          this.setState({ emailValid: responseJson.errors[0].account })
        }
      }
      else if (apiRequestCallId === this.sendEmailOtpCallId) {
        if (responseJson) {
          this.setState({ authToken: responseJson?.meta?.token, verfication: true });
          this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
              this.setState(({ seconds }) => ({
                seconds: seconds - 1
              }));
            } else {
              clearInterval(this.myInterval);
              this.setState({ timerExpired: true, disabledResendOtp: false, seconds: 60 })
            }
          }, 1000);
        }
      } else if (apiRequestCallId === this.otpVerificationForEmailCallId) {
        if (responseJson.errors) {
          this.setState({ otpError: responseJson.errors[0].otp })
        } else {
          this.setState({ successPage: true })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  freelanceripenforcementDetails = async () => {
    this.setState({ disableBtn: true })
    const header = {
      "Content-Type": "application/json"
    };

    const { other, experienceInIpEnforcement, type, focusArea, article, linkedInProfileLink, lawFirm, countryOfOperation, governmentAward, industryAward, academicAward, step4FileUplod1, step4FileUplod2, step4FileUplod3, ItConference, cityOfOperation, fees, featuredInTheMedia, } = this.state;
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": await getStorageData("email"),
          "password": await getStorageData("password"),
          "first_name": await getStorageData("firstName"),
          "last_name": await getStorageData("lastName"),
          "role_id": "freelancer"
        },
        "ip_enforcment_attributes": {
          "qualification": {
            "other_qualification": other,
            "no_of_experience": Number(experienceInIpEnforcement),
            "enforcement_type": type,
            "selected_enforcement": "ip_lawyer",
            "focus_area": focusArea,
            "linkedin_profile_link": linkedInProfileLink
          },
          "area_operation": {
            "country_of_operation": countryOfOperation,
            "country_name": "india",
            "city_of_operation": cityOfOperation,
            "is_law_firm_practise": lawFirm,
          },
          "fee_assignment": {
            "fees_assignment": 'assignment'
          },
          "success_story": {
            "have_you_featured_media": featuredInTheMedia === 'Yes',
            "enforcement_featured_media_url": step4FileUplod1 ? URL.createObjectURL(step4FileUplod1) : '',
            "have_you_spoken_ip_conference": ItConference === 'Yes',
            "enforcement_ip_conference_url": step4FileUplod2 ? URL.createObjectURL(step4FileUplod2) : '',
            "have_you_published_article": article === 'Yes',
            "enforcement_published_url": step4FileUplod3 ? URL.createObjectURL(step4FileUplod3) : '',
          },
          "awards": {
            "enforcement_government_award_url": governmentAward ? URL.createObjectURL(governmentAward) : '',
            "enforcement_industry_award_url": industryAward ? URL.createObjectURL(industryAward) : '',
            "enforcement_academic_award_url": academicAward ? URL.createObjectURL(academicAward) : '',
          }
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freelanceripenforcementById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/sign_up"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendEmailOtp = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    let emailData = {
      "data": {
        "attributes": {
          "email": await getStorageData("email")
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sendEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/otps/email_otp");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(emailData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otpVerificationForEmail = () => {
    const header = {
      "Content-Type": "application/json"
    };

    let OtpData = {
      "data": {
        "token": this.state.authToken,
        "otp_code": this.state.otpValue
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otpVerificationForEmailCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/account_confirm");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(OtpData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
