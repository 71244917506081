import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Badge, Avatar } from '@material-ui/core';
const OnlineBadge = withStyles(() => ({
  badge: {
    backgroundColor: '#206FC4',
    color: '#FFF',
    left: '45%',
    top: '65%',
    '&::after': {
      position: 'absolute',
      bottom: '0',
      left: '50%', // Center the badge horizontally
      transform: 'translate(-50%, -50%)', // Center the badge vertically
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      content: '""'
    }
  }
}))(Badge);

interface OnlineAvatarProps {
  online: boolean;
  src: string;
  alt: string;
}

const OnlineAvatar: React.FC<OnlineAvatarProps> = ({ online, src, alt }) => {
  return (
    <OnlineBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      badgeContent="online"
      invisible={!online}
    >
      <Avatar src={src} alt={alt} style={webStyle.avatar} />
    </OnlineBadge>
  );
};
export default OnlineAvatar;
const webStyle = {
  avatar: {
    width: '80px',
    height: '80px',
    flexShrink: 0,
    background: '#206FC4',
    marginRight: '9px'
  }
};
