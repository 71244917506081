import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  toggleDrawer: ()=> void
  jobDetails:{data:{
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      description: string,
      body: string,
      job_title: string,
      job_description: string,
      per_day_charge: null | number,
      budget: string,
      duration: string
      employment_term: string,
      estimate:string,
      skills: any[],
      location: string,
      file: {
          url: null
      },
      account_id: number,
      created_at: string,
      updated_at: string,
      proposals: number,
      messaged: number,
      hired: number,
      is_drafted: true,
      est_budget: null,
      last_viewed_by_client: string,
      rating: number | undefined,
      intermediate: null,
      model_name: string,
      images_and_videos: any[],
      file_name: string
  }}}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string;
  jobId:number;
  anchorEl:string;
  activityData: { label: string; value: keyof Props['jobDetails']['data']['attributes'] }[];
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class GlobalJobProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      authToken: "",
      jobId: 0,
      anchorEl:"",
       activityData:[
        { label: 'Proposals', value: 'proposals' },
        { label: 'Last viewed by client', value: 'last_viewed_by_client' },
      
      ],
      
    
    };
   
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
  
    // Customizable Area End
  }

  // Customizable Area Start
  handleRedirect = (path: any) => {
    this.setState({ anchorEl: "" });
    if (path)  {
      window.location.pathname = path;
    }
  };
  toggleDrawer = () => {
    this.props.toggleDrawer()
  };

  // Customizable Area End

  // Customizable Area Start
  async receive(form:string,message:Message) {
    // Customizable Area Start
 
    // Customizable Area End
  }
  // Customizable Area End
}
