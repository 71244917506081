import React, { Component } from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    withStyles,
    TextField,
    Box,
    Checkbox,
    IconButton,
    OutlinedInput,
    Button,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    ClickAwayListener,
    styled
  } from "@material-ui/core";
  import { green } from "@material-ui/core/colors";
  import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
  import CheckCircleIcon from "@material-ui/icons/CheckCircle";
  import Visibility from "@material-ui/icons/Visibility";
  import VisibilityOff from "@material-ui/icons/VisibilityOff";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import Select, { components } from "react-select"
  const svgIcon = `
 <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding-right:19px; padding-top:4px; cursor: pointer;">
 <path d="M11.6883 11.5325C11.5325 11.5325 20.4156 2.64935 20.4156 2.64935C21.039 2.02597 21.039 1.09091 20.4156 0.467531C19.7922 -0.155846 18.8571 -0.155846 18.2338 0.467531L10.4416 8.1039L2.64935 0.467532C2.02597 -0.155845 1.09091 -0.155845 0.467532 0.467532C0.155843 0.779221 -4.76851e-07 1.09091 -4.56415e-07 1.55844C-4.35979e-07 2.02597 0.155843 2.33766 0.467532 2.64935L9.1948 11.5325C9.97402 12.1558 11.5325 11.5325 11.6883 11.5325Z" fill="black"/>
 </svg>
 `;
import ReCAPTCHA from "react-google-recaptcha"
import moment from 'moment';
// Customizable Area End
 export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:any;
    state:any;
    handleEmailChange:any;
    handleFirstNameChange:any;
    handleLastNameChange:any;
    handlePassword:any;
    togglePasswordVisibility:any;
    handleCheckboxChange:any;
    handleCountry:any;
    handleClickAway:any;
    handleSelectMainBoxClick:any;
    handleCaptchaValue:any;
    handleJobSelection:any;
    handleKeyPress:any;
    setState:(value:any)=>void
    captchaDone:any
    sitekey:string
    captchaErr:any
    // Customizable Area End
  }
export const configJSON = require("../../blocks/email-account-registration/src/config.js");
const customStyles = {
    control: (provided: any) => ({
      ...provided,
      "&:hover": {
        borderColor: "none" 
      },
      boxShadow: "none"
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ffffff" : "transparent",
      color: state.isFocused ? "#000000" : "#333333",
      width: "95%",
      margin: "auto",
      cursor:"pointer",
      "&:hover": {
        backgroundColor: "#206FC4",
        borderRadius: 10,
        color: "#ffffff"
      }
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      "&:hover": {
        color: "initial"
      }
    })
  };
  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMoreIcon style={{ cursor: 'pointer' }} />
      </components.DropdownIndicator>
    );
  };
const GreenCheckbox1 = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: green[600]
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);
  
  const GreenCheckbox = withStyles({
    root: {
      color: "#535353",
      "&$checked": {
        color: green[600]
      }
    },
    checked: {}
  })(Checkbox);
class ActiveStep0 extends Component<Props>{
   
    render(){
        
        return  <>
        <Box component={"div"}>
          <Typography className={this.props.classes.labelStyle}>
            Email<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            data-test-id="txtInputemail"
            name="email"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(event)=>this.props.handleEmailChange(event)}
            value={this.props.state.email}
            className={this.props.classes.inputStyle}
            error={!this.props.state.isValidEmail}
            helperText={
              !this.props.state.isValidEmail ? "* Invalid email format" : ""
            }
            InputProps={{
              style: { fontWeight: 600 }
            } }
            inputProps={{
              maxLength: 36
            }}
          />
        </Box>
        <Box component={"div"}>
          <Typography className={this.props.classes.labelStyle}>
            FirstName<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            data-test-id="txtInputemail"
            name="firstName"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(event)=>this.props.handleFirstNameChange(event)}
            value={this.props.state.firstName}
            className={this.props.classes.inputStyle}
            error={this.props.state.firstNameError}
            helperText={
              this.props.state.firstNameError
                ? "* First Name is required"
                : ""
            }
            InputProps={{
              style: { fontWeight: 600 },
              onKeyPress: this.props.handleKeyPress
            }}
            inputProps={{
              maxLength: 36
            }}
          />
        </Box>
        <Box component={"div"}>
          <Typography className={this.props.classes.labelStyle}>
            LastName<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            data-test-id="txtInputemail"
            name="lastName"
            type="text"
            onChange={(event)=>this.props.handleLastNameChange(event)}
            fullWidth
            variant="outlined"
            value={this.props.state.lastName}
            error={this.props.state.lastNameError}
            className={this.props.classes.inputStyle}
            helperText={
              this.props.state.lastNameError ? "* Last Name is required" : ""
            }
            InputProps={{
              style: { fontWeight: 600 },
              onKeyPress: this.props.handleKeyPress
            }}
            inputProps={{
              maxLength: 36
            }}

          />
        </Box>
        <Box component={"div"}>
          <Typography className={this.props.classes.labelStyle}>
            Password<span style={{ color: "red" }}>*</span>
          </Typography>
          <OutlinedInput
            name="password"
            fullWidth
            data-test-id="txtInputemail"
            className={this.props.classes.passwordInputStyle}
            type={this.props.state.showPassword ? "text" : "password"}
            value={this.props.state.password}
            onChange={(event)=>this.props.handlePassword(event)}
            onPaste={(e) => e.preventDefault()} 
            inputProps={{
              "aria-label": "password",
              style: {
                fontWeight: 500,
                fontSize: "16px",
                fontFamily: "'Inter', sans-serif",
                color: "#000000"
              }
            }}
            endAdornment={
              <IconButton
              onClick={this.props.togglePasswordVisibility}
              aria-label="toggle password visibility"
                edge="end"
              >
                {this.props.state.showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            }
          />
        </Box>
        <FormHelperText className={this.props.classes.countryErrorStyle}>
          {this.props.state.passwordError}
        </FormHelperText>
       
        <div  style={{marginTop:"18px"}}>
          {this.props.state.password?.length > 3 && (
            <span
              className={this.props.classes.seemsText}
            >
              Seems good, we suggest to make it stronger
            </span>
          )}
          <Typography
            style={{
              fontFamily: "'Inter', sans-serif",
              marginTop: "4px"
            }}
          >
            <GreenCheckbox
              size="medium"
              checked={this.props.state.containsUppercase}
              color="primary"
              name="containsUppercase"
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            Contain atleast one uppercase letter
          </Typography>
          <Typography style={{ fontFamily: "'Inter', sans-serif" }}>
            <GreenCheckbox
              size="medium"
              checked={this.props.state.containsNumber}
              color="primary"
              name="containsNumber"
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            Contain atleast one number
          </Typography>
          <Typography style={{ fontFamily: "'Inter', sans-serif" }}>
            <GreenCheckbox
              size="medium"
              checked={this.props.state.containsSpecialChar}
              color="primary"
              name="containsSpecialChar"
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            Contain atleast one special character
          </Typography>
        </div>
        <Box component={"div"} style={{ marginTop: "12px",cursor:"pointer" }}>
          <Typography className={this.props.classes.labelStyle}>
            Select Country<span style={{ color: "red" }}>*</span>
          </Typography>
          <Select
            value={this.props.state.country}
            options={configJSON.countryNames}
            data-test-id="handlecountry"
            onChange={(countries) => this.props.handleCountry(countries)}
            styles={customStyles}
            className={this.props.classes.selectmenu}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            placeholder="Select Country"

          />
          {this.props.state.countryError && <span className={this.props.classes.countryErrorStyle}>
            * Please Select Country
          </span>}
        </Box>
        <Box className={this.props.classes.boxstyle}>
          <Typography className={this.props.classes.labelStyle} style={{ marginTop: "10px" }}>Job Title:<span style={{ color: "red" }}>*</span></Typography>
          <ClickAwayListener onClickAway={this.props.handleClickAway}>
            <Box className={this.props.classes.selectMainBox}>
              <div
                id='SelectMainBox'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "18px",
                  fontFamily: "'Inter', sans-serif",
                  cursor: "pointer"
                }}
                onClick={() => this.props.handleSelectMainBoxClick()}
                data-test-id="handleSelectMainBoxClick"
              >
                {this.props.state.selectedJob === "" ? (
                  <span
                    style={{
                      marginLeft: "10px",
                      color: "#535353",
                      fontFamily: "'Inter', sans-serif",
                      textAlign: "center"
                    }}
                  >
                    Select Option
                  </span>
                ) : (
                  <span
                    style={{
                      flex: 1,
                      marginLeft: "10px",
                      flexWrap: "wrap",
                      fontFamily: "'Inter', sans-serif",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {this.props.state.selectedJob}
                  </span>
                )}
                <div
                  data-test-id="main-box-click"
                  dangerouslySetInnerHTML={{ __html: svgIcon }}
                />
              </div>
            </Box>
          </ClickAwayListener>
          {this.props.state.jobError && (
            <p className={this.props.classes.countryErrorStyle}>* Please Select Job</p>
          )}

          {this.props.state.showJobs && (
            <Grid className={this.props.classes.selectBox} container>
              {this.props.state.listJobs.map((item:any, index:any) => (
                <Grid 
                key={`${index}job`}
                item
                xs={6}
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.handleJobSelection(item.name, item.pathName)}
                >
                 <FormControlLabel
                  control={<GreenCheckbox1 />}
                  checked={this.props.state.selectedJob === item.name}
                  label={
                    <Typography
                      className={this.props.classes.labelStyle3}
                    >
                      {item.name}
                    </Typography>
                  }
                  data-test-id="handleJobSelection"
                  onChange={() => this.props.handleJobSelection(item.name, item.pathName)}
                />
                </Grid>
              ))}
            </Grid>
          )}

          {this.props.state.additionalTextBoxVisible && (
            <Grid container style={{ marginTop: 10 }}>
              <OutlinedInput
                placeholder="Please enter other job title"
                fullWidth
                inputProps={{
                  style: {
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#00000"
                  },
                  onKeyPress: this.props.handleKeyPress
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <Button id="addtionalTextBox"
                         onClick={() => {
                          if (this.props.state.additionalTextBoxValue.trim() !== "") {
                            this.props.setState({
                              listJobs: [
                                ...this.props.state.listJobs,
                                {
                                  name: this.props.state.additionalTextBoxValue
                                }
                              ],
                              additionalTextBoxValue: ""
                            });
                          }
                        }}
                        style={{
                          backgroundColor: "#2378D1",
                          color: "#FFFF",
                        }}
                      >
                        + Add
                      </Button>
                    </IconButton>
                  </InputAdornment>
                }
                value={this.props.state.additionalTextBoxValue}
                onChange={e =>
                  this.props.setState({
                    additionalTextBoxValue: e.target.value
                  })
                }
              />
            </Grid>
          )}
        </Box>
        <Box style={{ marginTop: "1rem",cursor:"pointer" }}>
          <ReCAPTCHA
            sitekey={this.props.sitekey}
            onChange={(value: any) => {
            this.props.handleCaptchaValue(value)
            }}
          />
          {this.props.captchaErr && (
            <span className={this.props.classes.countryErrorStyle}>
              {this.props.captchaErr}
            </span>
          )}
        </Box>
      </>
    }
}
export default ActiveStep0
export const TableContainer = styled(Box)({
  padding: "20px",
  borderBottom: "1px solid #EEE0E0",
  
})


const ISTOffset = 5.5 * 60; // Offset in minutes (Indian Standard Time is UTC+5:30)
const ISTTime = moment.utc().utcOffset(ISTOffset);

const hour = ISTTime.hours();
let greeting = '';
if (hour >= 5 && hour < 12) {
  greeting = 'Good Morning';
} else if (hour >= 12 && hour < 17) {
  greeting = 'Good Afternoon';
} else {
  greeting = 'Good Evening';
}

const dateFormat = 'dddd, MMM D YYYY, h:mm:ss A';
const formattedStringIST = ISTTime.format(dateFormat);
const wrappedFormattedStringIST = `(${formattedStringIST})`;

export { greeting, wrappedFormattedStringIST };



export const RightBox = styled(Box)({
  border: "1px solid #EEE0E0",
  borderRadius: "10px",
});