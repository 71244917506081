// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { ChangeEvent, createRef } from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider";
import { configJSON } from "./SendamessageController.web";
import moment from "moment";
export const webConfigJSON = require("./config.js");


export interface SearchData {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    profile_image:{
      url:string
    };
    country_code: null | string;
    user_name: null | string;
    email: string;
    first_name: string;
    full_phone_number: number;
    last_name: null | string;
    phone_number: null | string;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: null | string;
    unique_auth_id: string;
    full_name: string,
    unread_count: number
  };
}
             
               

interface ReceiveMessageData {
  id: string;
  type: string;
  attributes: {
    id: number;
    message: string;
    created_at: string;
    updated_at: string;
    current_user_id: number;
    account_id: number;
    user_name: null | string;
    unread_count: number;
    attachments: [
      {
          id: number;
          send_message_id: number;
          attachment: {
              url: string
          },
          created_at: string,
          updated_at: string
      }
  ],
    account_attributes: {
        profile_image: null,
        status: string
    }
  };
}

interface Messages{
   id:number,
   attributes:{
       current_user_id:number,
       message:string

   }
}
interface IMessageCount {
    unread_count?: number
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  chatId: string;
  data?: any[];
  profileImg?:string ;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  unreadCountState:boolean;
  id: string|number;
  account_id: number;
  messages: Messages[];
  currentMessage: string;
  searchQuery: string;
  activeChatId: string;
  searchText: string;
  activeText: string;
  token: string;
  searchData: SearchData[];
  recieveMessageData: ReceiveMessageData[];
  unreadCountData: number;
  createMessageData: string;
  deleteMessageData: string;
  userType: string;
  profileImage:string;
  chat_id: string;
  message: string;
  emoji: boolean;
  user_id: number | null;
  searchValue: string;
  first_name: string;
  full_name:string;
  pathname:string
  deleteMessage:string;
  messagecount:number;
  deletePopUp:boolean;
  deleteID:number;
  isLoading:boolean;
  selectedEmoji:string;
  errorMessage:string;
  freelancerProfileImg:string;
  selectedFile: null| File;
  imageUrl: string | null
  videoUrl:string | null;
  docUrl: string | null;
  docName: string|null;
  textUrl: string | null;
  audioUrl: string | null;
  textName:string ;
  previewImage: string 
  selectedAttachments: any[]
  tooltipOpen: boolean,
  tooltipAnchorEl: any,
  tooltipOpen2: boolean,
  tooltipAnchorEl2: any,
  isLoadingNewMessage: boolean
  // Customizable Area End
}
interface SS {}

export default class ChattingBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  chatIndexId: string = "";
  receiveMessageId: string = "";
  unreadCountId: string = "";
  searctext: string = "";
  createMessageId: string = "";
  deleteMessageId: string = "";
  zoomMeetingLinkId:string="";
  chatVal: string = "";
  chatContainerRef: any;
  componentRef: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.componentRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      unreadCountState:true,
      id: "",
      account_id: 345,
      deleteID:0,
      messages: [],
      currentMessage: "",
      activeText: "Message",
      activeChatId: "",
      searchQuery: "",
      token: "",
      searchData: [],
      recieveMessageData: [],
      unreadCountData: 0,
      imageUrl:"",
      videoUrl:"",
      docUrl:"",
      docName: "",
      textUrl:"",
      audioUrl:"",
      textName:"",
      createMessageData: "",
      deleteMessageData: "",
      searchText: "",
      userType: "",
      profileImage:"",
      chat_id: "",
      message: "",
      emoji: false,
      user_id: null,
      searchValue: "",
      first_name: "",
      pathname:window.location.pathname,
      full_name:"",
      deleteMessage:"",
      messagecount:0,
      deletePopUp:false,
      isLoading: false,
      selectedEmoji:"",
      freelancerProfileImg:"",
      errorMessage:"",
      previewImage: "",
      selectedFile: null,
      selectedAttachments: [],
      tooltipOpen: false,
      tooltipAnchorEl: null,
      tooltipOpen2: false,
      tooltipAnchorEl2: null,
      isLoadingNewMessage: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    let token = await storage.get("authToken");
    let userType = await storage.get("userType");
    let accountId = await storage.get("accountId");
    let profileinf = await storage.get("freelancerProfileInfo")
    let imgUrl = JSON.parse(profileinf)?.attributes?.profile_image
    
    this.setState({ user_id: parseInt(accountId) });
    this.setState({ userType: userType });
    this.setState({ token });
    const id=this.state.pathname.split("/")
    const topic_id=id[id.length - 2]
    const name=id[id.length - 1]
    if(topic_id){
      this.setState({activeChatId:topic_id,full_name:decodeURIComponent(name)})
      this.getRecievedMessage(topic_id);
    }
    if(topic_id){
      this.setState({activeChatId:topic_id})
      this.createZoomMeeting(topic_id);
    }
    this.setState({
      freelancerProfileImg:imgUrl ,
    });
    this.getSearchData("");
    // Customizable Area End
  }
  componentDidUpdate(prevProps:any) {
    if (this.props.profileImg !== prevProps.profileImg && this.props.profileImg !== undefined) {
      this.setState({
        freelancerProfileImg: this.props.profileImg
      });
    }
  }
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", _message);

    if (getName(MessageEnum.SessionResponseMessage) === _message.id) {
      let token = _message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = _message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = _message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      switch (apiRequestCallId) {
        
        case this.chatIndexId:
          this.setState({ searchData: responseJson.data ,searchQuery:this.chatVal??""});
          break;
          case this.receiveMessageId:
          this.setState({ messages: responseJson.data ,recieveMessageData:responseJson.data});
          this.scrollToBottom()
          break;
        case this.unreadCountId:
          this.getListMessagesCount(responseJson)
          break;
          case this.createMessageId:
            if (responseJson.errors && responseJson.errors.length > 0) {
              const error = responseJson.errors[0];
              let errorMessage = '';
      
              if (error.message) {
                errorMessage = error.message;
              }
              this.setState({ errorMessage });
              setTimeout(() => {
                this.setState({ errorMessage: "" });
              }, 5000); 
              return;
            }
      
            this.getUnreadCount();
            this.getRecievedMessage(this.state.activeChatId);
            this.setState({
              currentMessage: "",
              createMessageData: responseJson.data,
              selectedFile:null,
              imageUrl: "",
              videoUrl:"",
              docUrl:"",
              textUrl:"",
              audioUrl:""
            });
            this.getSearchData("");
            break;
      
         case this.deleteMessageId:
          this.getDeleteMessageApiResponse(responseJson)
          break;
          case this.zoomMeetingLinkId:
            if (responseJson.zoom_join_url) {
              window.open(responseJson.zoom_join_url, '_blank');
            }
            break;

            default:
              this.setState({ isLoading: false});
              break;
            }
          }else {
            this.setState({ isLoading: false });
          }

    // Customizable Area End
  }
  // Customizable Area Start
  scrollToBottom = () => {
    if (this.chatContainerRef?.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight
    }
  };
  handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
      tooltipAnchorEl: event.currentTarget,
    });
  };
  handleAvatarClick2 = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      tooltipOpen2: !this.state.tooltipOpen2,
      tooltipAnchorEl2: event.currentTarget,
    });
  };
  handleTooltipClose = () => {
    this.setState({
      tooltipOpen: false,
      tooltipAnchorEl: null,
    });
  };
  handleTooltipClose2 = () => {
    this.setState({
      tooltipOpen2: false,
      tooltipAnchorEl2: null,
    });
  };
  handleCancelImage = () => {
    this.setState({ imageUrl: '',videoUrl:"",docUrl:"",textUrl:"",audioUrl:"" }); 
  }
  getListMessagesCount = (responseJson: IMessageCount) => {
    if (responseJson) {
        this.setState({ messagecount: responseJson.unread_count! })
    }
  }
  getDeleteMessageApiResponse = (responseJson: { message: string }) => {
    if (responseJson && responseJson.message) {
      this.setState({deletePopUp:false})
      this.getRecievedMessage(this.state.activeChatId)
        this.setState({
            deleteMessage: configJSON.messageDelete
        })
    }
  }
  handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value , "fvghjghgghfghfgh");
    this.setState({ currentMessage: event.target.value });
    this.setState({selectedEmoji:event.target.value})
  };
  handleOuter=()=>{
    if(this.state.emoji){
      this.setState({emoji:!this.state.emoji})
    }
  }
  dateFormat = (ele: any) => {
    const utcDate = ele.attributes?.updated_at?.slice(0,-4); 
    const date = moment.utc(utcDate);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
  
    if (date.isSame(today, 'd')) {
      return 'Today';
    } else if (date.isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return date.utc().format('DD/MM/YY');
    }
  };

handleSendMessage = async () => {
  const { currentMessage, selectedFile } = this.state;
  
  const trimmedMessage = currentMessage.trim();
  try {
    if (trimmedMessage !== "" || selectedFile !== null) {
      await this.postCreateMessage();
    }
    
    await this.getUnreadCount();

    this.setState({
      currentMessage: "",
      selectedFile: null,
    }, () => {
      this.scrollToBottom();
    });
  } catch (error) {
  }
};
handleFileSelect = (event: any) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type || file.name.split('.').pop(); // Get the file type or infer it from the file extension

    console.log("Selected file type:", fileType);

    // Check if the file is a ".jif" file or has the type "application/octet-stream"
    const isGif = file.name.endsWith('.jif') || fileType === 'jif' || fileType === 'application/octet-stream';

    // Check if the file type is "text/plain"
    const isPlainText = fileType === 'text/plain';

    // Set the state variables based on the file type
    this.setState({
      selectedFile: file,
      imageUrl: fileType.startsWith('image/') || isGif ? fileUrl : null,
      videoUrl: fileType.startsWith('video/') ? fileUrl : null,
      docUrl: fileType.startsWith('application/') && !isGif ? fileUrl : null,
      docName: fileType.startsWith('application/') && !isGif ? file.name : null,
      textUrl:fileType.startsWith('text/') &&  isPlainText ? fileUrl : null, 
      textName: fileType.startsWith('text/') && isPlainText ? file.name : null, 
      audioUrl: fileType.startsWith('audio/') ? fileUrl : null,
    });
  }
};
handleDeleteClose=()=>{
  this.setState({deletePopUp:false})
}
  handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    let a = e.target.value;
    this.getSearchData(a);
    this.setState({ searchQuery: e.target.value });
  };
  handleClick = (text: string) => {
    this.setState({ activeText: text });
  };
  handleClickChat = (chatId: string,name:string) => {
    this.setState({ activeChatId: chatId,full_name:name ,isLoading:true});
    this.getRecievedMessage(chatId);
    this.getSearchData("")
  };
  handleEmojiClick = () => {
    this.setState((prevState) => ({
      emoji: !prevState.emoji,
    }));
  };

  handleEmojiSelection = (emojiObject: any) => {
    this.setState((prevState) => ({
      currentMessage: prevState.currentMessage + emojiObject.emoji,
    }));
  };

  handleKeyDown = async (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
  
      await this.handleSendMessage();
  
      this.scrollToBottom();
    }
  };
  handleImageClick = (image: any) => {
    this.setState({ previewImage: image });
  };

  closePreview = () => {
    this.setState({ previewImage: "" });
  };
  
  getSearchData = (value: string) => {
    //get
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.token,
    };
    const url = value == "" ? "/bx_block_send_message/send_messages/users" : `/bx_block_send_message/send_messages/users?query=${value}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.chatIndexId = requestMessage.messageId;
     this.chatVal=value;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRecievedMessage = async (id: string) => {
    //get
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.receiveMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_send_message/send_messages?user_id=${parseInt(id)}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    await this.getUnreadCount();
  };

  getUnreadCount = () => {
    //get
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unreadCountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_send_message/send_messages/unread_count`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

postCreateMessage = async () => {
  const { currentMessage, selectedFile, token, activeChatId } = this.state;
  const trimmedMessage = currentMessage.trim();
  if (trimmedMessage === "" && (selectedFile === null)) {
    return; 
  }
  const header = {
    token: token,
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.createMessageId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_send_message/send_messages?user_id=${parseInt(activeChatId)}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  const formdata = new FormData();
  if (selectedFile !== null && this.state.selectedFile) {
    formdata.append("data[attributes][attachments][attachment][]", selectedFile, selectedFile?.name);
  }
  if (trimmedMessage !== "") {
    formdata.append("data[attributes][message]", trimmedMessage);
  }
  formdata.append("data[attributes][account_id]", activeChatId);
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  await this.getUnreadCount();
};


  deleteMessage = () => {
    //delete
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_send_message/send_messages/${this.state.deleteID.toString()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createZoomMeeting = async (id: string) => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.token,
    };
    const body = {
      meeting: {
        topic: "Example Meeting",
        type: 2, 
        start_time: "2024-04-26T12:00:00Z",
        duration: 60,
        timezone: "America/New_York"
      }
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.zoomMeetingLinkId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/meetings?freelancer_id=${parseInt(id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
    
  };
  
  // Customizable Area End
}
