
import React from "react";
// Customizable Area Start
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { deleteIcon } from "./assets";
import SendAMessageController,{Props,configJSON, MessageListAttributes } from "./SendamessageController.web";
// Customizable Area End


export class AllMessages extends SendAMessageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.container}>
                    <div className={classes.layout}>
                        <div className={`${classes.textSize} ${classes.mb_10}`}>{configJSON.allMessagesText}</div>
                        {this.state.messageList.map((message:MessageListAttributes ) => (
                            <div className={`${classes.msgContainer} ${classes.borderLayout} ${classes.mb_10}`}>
                                <div className={classes.msgLayout}>
                                    <div className={classes.msgLayout}>
                                        <div>
                                            <h3>{message.attributes.user_name}</h3>
                                        </div>
                                        <div>
                                            <p className={classes.msgContent}>{message.attributes.message}</p>
                                        </div>

                                    </div>
                                    <div>
                                        <button className={classes.deleteButton} data-test-id="deleteMessage" onClick={() => { this.deletemessage(message.id) }} >

                                            <img src={deleteIcon} className={classes.deleteIcon} width={200} height={100} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.state.messageList.length <= 0 && (
                            <div>
                                
                                <h3 className={classes.notFound}>{configJSON.noMessagesText}</h3>
                            </div>
                        )}
                    </div>
                </div>

            </>
        );
    }
}

// Customizable Area Start
export const styles: StyleRules = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    layout: {
        maxWidth: "900px",
        width: "650px",
        border: "1px solid #999999",
        padding: "1rem",
        borderRadius: "10px"
    },
    textSize: {
        fontSize: "18px",
        fontWeight: "bold"
    },
    mt_10: {
        marginTop: "10px"
    },
    mb_10: {
        marginBottom: "10px"
    },
    msgContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
    msgLayout: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    deleteIcon: {
        width: "30px",
        height: "30px",
        cursor: "pointer"
    },
    borderLayout: {
        padding: "1.3rem 0.5rem",
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)"

    },
    msgGrid: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    msgContent: {
        maxWidth: "400px",
        width: "350px"
    },
    notFound: {
        textAlign: "center",
        fontsize: "15px",
        fontWeight: "normal"
    },
    deleteButton: {
        border: "none",
        background: "none"
    }
};


export default withStyles(styles)(AllMessages);
// Customizable Area End