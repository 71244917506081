import React from "react";
// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
  withStyles,
  Checkbox,
  Divider,
  Container
} from "@material-ui/core";
import { bulbicon } from "../assets";
import DashboardController from "../DashboardController.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ellipse } from "./assets";
const CustomTypography = styled(Typography)({
  fontFamily:"'Inter', sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  textDecoration: "none",
  cursor:"pointer",
  background: '#206FC4',
  borderRadius: 10,
  textAlign:"center",
    padding: "10px",
  width:"100%",
  '&:hover': {
    maxWidth: "161px",
    maxHeight: "55px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
// Customizable Area End
// Customizable Area Start

type MyComponentProps = {
  children: any,
  index: any,
  value: any,
};

export class TabPanel extends React.Component<MyComponentProps> {
  render() {
    const { children, value, index, ...other } = this.props 

    return (
      <div
        role="tabpanel"
        hidden={value != index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value == index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
}

const a11yProps =(index:any)=> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// Customizable Area End
// Customizable Area Start
export class ChooseCategory extends DashboardController {

  // Customizable Area Start
  constructor(props: any) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes ,categoriesInfo } = this.props;
    const { activeTabValue} = this.state;
    const currentSelectedCatItem = categoriesInfo?.find((catItem: any,index:number) => index == activeTabValue ? catItem : null);

    
    return (
      <>
        <Container
          maxWidth="xl"
          className={classes?.root}
          style={{ position: 'relative' as 'relative' }}
        >
          {/* Heading */}
          {/* Choose category div */}
          <Box className={classes?.chooseCategoryDiv}>
            <Typography className={classes?.chooseCategoryHeading}>
              Please choose category of patent.
            </Typography>
            <Typography className={classes?.chooseCategoryText}>
              Consider the size of your project and the time it will take.
            </Typography>
            <Box className={classes?.chooseCategoryWarningBox}>
              <img className={classes?.iconImage} src={bulbicon} alt="" />
              <Typography className={classes?.chooseCategoryWarningBoxText}>
                You can not select more than one category.
              </Typography>
            </Box>
          </Box>
          {/* Main boxes*/}

          {/* tabs - categories */}
          <Tabs
            value={this.state.activeTabValue}
            data-test-id="tabsChange"
            variant="scrollable"
            scrollButtons="on"
            className={classes?.tabColor}
            onChange={this.handleTabsChange}
            aria-label="simple tabs example"
          >
            {categoriesInfo?.map((category: any, index: number) => (
              <Tab
                key={index}
                label={
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: '#000000',
                      fontSize: 15,
                      textTransform: 'none' as 'none'
                    }}
                  >
                    {' '}
                    {category.attributes.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {/* body  */}
          <TabPanelStyle value={activeTabValue} index={activeTabValue}>
            <Grid container spacing={1} direction="row">
              {currentSelectedCatItem?.attributes?.sub_categories?.data?.map(
                (subCategoryItem: any, subCatItemIndex: number) => (
                  <Grid item md={3} key={subCatItemIndex}>
                    <Box
                      component={'div'}
                      className={classes?.accordianHeading}
                    >
                      <Typography>{subCategoryItem.attributes.name}</Typography>
                    </Box>
                    {subCategoryItem.attributes.sub_category_menus.data?.map(
                      (
                        subCategoryMenuItem: any,
                        subCategoryMenuItemIndex: number
                      ) => (
                        <Accordion key={subCategoryMenuItemIndex}>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {subCategoryMenuItem.attributes.title}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            {subCategoryMenuItem.attributes.sub_category_sub_menu.data?.map(
                              (
                                subCatSUbMenuItem: any,
                                subCatSUbMenuItemIndex: number
                              ) => (
                                <div
                                  className={classes?.checkboxDirection}
                                  key={subCatSUbMenuItemIndex}
                                >
                                  <div>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Checkbox
                                          style={{ color: '#2155c3' }}
                                          data-test-id="checkbox1Checked"
                                          onChange={() =>
                                            this.handleCheckboxChange(
                                              'checkbox1Checked'
                                            )
                                          }
                                        />

                                        <Typography
                                          className={classes?.accortxt}
                                        >
                                          {subCatSUbMenuItem.attributes.title}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        className={classes?.accorsubtxt}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              subCatSUbMenuItem.attributes
                                                .description
                                          }}
                                        />
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </TabPanelStyle>

          {/* Lower div*/}

          <Grid container spacing={6} className={classes?.lowerSectionDiv}>
            <Typography
              className={`${classes.lowerSectionItem1Text}`}
              onClick={this.handleChooseCategoryBack}
            >
              BACK
            </Typography>

            <Grid className={classes?.btnWithTextDiv}>
              <Typography
                className={classes?.clearText}
                onClick={this.handleClear}
              >
                CLEAR
              </Typography>
                <CustomTypography
                  className={classes?.btnText}
                  onClick={this.handleApply}
                >
                  APPLY
                </CustomTypography>
            </Grid>
            <img
          src={ellipse}
          alt="ellipse"
          style={{
               position: 'absolute',
               width: '340px',
               bottom: '0',
               zIndex: 0
              }}
              />
          </Grid>
        </Container>
      </>
      //Customizable Area End
    );
  }
}
// Customizable Area End
//Customizable Area Start
const TabPanelStyle = styled(TabPanel)({
  border: "2px solid #2155C3",
  borderRadius: '0 10px 10px 10px',
  position: 'relative',
  bottom: "2px"
})
// Customizable Area End
const webStyle = {
  //Customizable Area Start
  root: {
    "& .MuiTab-wrapper ":{
      fontWeight:700,
      color:"#000000",
      fontSize: 14,
      textTransform: 'none'
    },
    "& .MuiTab-root":{
      padding: '6px 10px',
      maxWidth: 205
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      border: "2px solid #2155C3",
      borderBottom: "none",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "white",
      color: "#2155C3",
      zIndex: 1,
      position: 'relative',
      top: 10
    },
    "& .MuiDivider-vertical":{
      height:"64px",
      marginTop:"10px"
    },
    "& .MuiTabs-flexContainer":{
      display: "flex",
      justifyContent: "space-between"
    }
  },
  accordianHeading: {
    minHeight: "48px",
    borderRadius: "12px 12px 0 0",
    backgroundColor: "#2155C3",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  accordionSubtxt: {
    color: "#2155C3",
  },
  accortxt: {
    fontSize: "12px",
    color: "#2155C3",
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  },
 
  accortxttwo:{
    fontSize: "12px",
    color: "#8e8f92",
    fontWeight: 600
  },
  checkboxDirection: {
    display: "flex",
    flexDirection: 'column' as 'column',
    height: 350,
    overflowX: "hidden" as const,
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2155c3',
      borderRadius: '10px',
    },
  },
  tabColor: {
    backgroundColor: "#F5F6FB",
    marginTop: 5
  },
  checkboxDirectiontwo:{
    display: "flex",
    flexDirection: 'column' as 'column',
    height: 200,
    overflowX: "hidden" as const,
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2155c3',
      borderRadius: '10px',
    },
  },
  heading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "44px",
    marginLeft: "10px",
    marginBottom: "50px",
  },
  chooseCategoryDiv: {
    marginLeft: "10px",
    marginTop: "53px",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  active: {
    backgroundColor: "white",
    color: "blue"
  },
  inActive: {
    backgroundColor: "gray",
    color: "black"
  },
  chooseCategoryHeading: {
    fontSize: "39px",
    fontWeight: 600,
    color: "#000000",
    marginBottom: "12px",
    lineHeight: "58px",
    display: "flex",
    justifyContent: 'center',
  },
  chooseCategoryText: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "#868686",
    marginBottom: "12px",
    display: "flex",
    justifyContent: 'center',
  },
  chooseCategoryWarningBox: {
    marginTop: "10px",
    height: "58px",
    backgroundColor: "#FFE6E6",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "12px",
    width: "35%"
  },
  iconImage: {
    marginLeft: "10px",
    marginBottom: "5px",
    marginRight: "5px",
  },
  chooseCategoryWarningBoxText: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#FF0C0C",

  },
  mainBox: {
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "120px",
  },
  box1: {
    width: "30%",
    marginTop: "36px",
  },
  box1Heading: {
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
    fontWeight: 600,
  },
  formGroupDiv: {
    display: "flex",
    gap: "3px",
    marginTop: "15px",
    marginLeft: "7px",
  },
  checkBoxColor: {
    color: "#206FC4",
    "&$checked": {
      color: "#206FC4",
    },
  },
  MuiCheckboxColorPrimaryMuiChecked: {
    color: "#206FC4",
  },
  checkBoxText: {
    lineHeight: "15px",
    fontWeight: 500,
    color: "#6E6E6E",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 8,
    "& .MuiCheckbox-root": {
      padding: 0,
      marginRight: 8,
    },
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
    },
    "& .MuiSvgIcon-root": {
      marginTop: "-3px !important",
    },
  },

  box2Heading: {
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
    fontWeight: 600,
  },

  accorsubtxt: {
    height: 200,
    fontSize:"12px",
    overflow: "auto",
    margin: "2px",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2155c3',
      borderRadius: '10px',
    },
  },


  lowerSectionDivMain: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-end",
    marginBottom: "20px",
    alignItems: "center",
  },
  lowerSectionDiv: {
    marginTop:"auto",
    width: "98%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position:"absolute" as "absolute",
    top:"500px"
  },
  lowerSectionBtn: {
    minWidth: "128px",
    maxHeight: "55px",
    backgroundColor: "#206FC4",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnWithTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  clearText: {
    fontSize: "16px",
    lineHeight: "27px",
    color: "#206FC4",
    marginRight: "50px",
    textDecoration: "underline",
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
      color: '#004080', 
    },
  },

  btnText: {
    fontSize: "16px",
    color: "#FFFFFF",
    padding: "10px",
  },
  lowerSectionItem1Text: {
    fontSize: "16px",
    color: "#535353",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontFamily: "'Inter', sans-serif" as "'Inter', sans-serif",
    fontWeight: "bold" as "bold",
    transition: "color 0.3s ease",
    zIndex: 1,
    marginLeft:"22px",
    "&:hover": {
      color: "black", 
    },
  },
  //Customizable Area End
};
//Customizable Area Start
export default withStyles(webStyle)(ChooseCategory);
// Customizable Area End

