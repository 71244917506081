// Customizable Area Start
import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Footer from './Components/Footer.web';
import FreelancerTransactionHistoryController, {
  Props
} from './FreelancerTransactionHistoryController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
// Customizable Area End
export default class FreelancerTransactionHistory extends FreelancerTransactionHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const tableData = [
      {
        title: 'PHP, Javascript Projects and Web design Project ',
        dueDate: '17/10/2022',
        cost: '$2,000,00',
        status: 'Ongoing'
      },
      {
        title: 'PHP, Javascript Projects and Web design Project',
        dueDate: '16/09/2023',
        cost: '$1,000,00',
        status: 'Ongoing'
      }
    ];
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box style={styles.mainContainer}>
          <Box display={'grid'} gridAutoFlow={'column'} width={'20%'}>
            <Typography
              style={{
                ...styles.tableContent,
                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px'
              }}
            >
              Reports
            </Typography>
            <ChevronRightIcon style={{ marginTop: '4px' }} />
            <Typography
              style={{
                ...styles.tableContent,
                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px',
                color: '#206FC4'
              }}
            >
              Transaction History
            </Typography>
          </Box>
          <Typography style={styles.heading as React.CSSProperties}>
            Transaction History
          </Typography>
          <div>
            <TableContainer style={styles.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {Object.keys(tableData[0]).map((key, index) => (
                      <TableCell
                        key={index}
                        style={
                          {
                            ...styles.tableHeading,
                            width: index === 0 ? '45%' : 'auto'
                          } as React.CSSProperties
                        }
                      >
                        {key === 'dueDate'
                          ? 'Due Date '
                          : key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.values(row).map((value, colIndex) => (
                        <TableCell
                          key={colIndex}
                          style={
                            colIndex === 0
                              ? (styles.title as React.CSSProperties)
                              : colIndex === 3
                              ? ({
                                  ...styles.tableContent,
                                  color: '#206FC4'
                                } as React.CSSProperties)
                              : (styles.tableContent as React.CSSProperties)
                          }
                        >
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
        <Footer />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainContainer: {
    flexGrow: 1,
    maxWidth: '100%',
    margin: '30px'
  },
  heading: {
    height: '36px',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '30px',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  tableContainer: {
    border: '1px solid #E7E7E7',
    borderBottom: 'none',
    marginTop: '40px'
  },
  tableHeading: {
    color: '#3F3F3F',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  tableContent: {
    color: '#000000',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0px',
    paddingTop: '0px'
  },
  status: {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#206FC4',
    paddingTop: '0px'
  },
  title: {
    color: '#0D0D0D',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0px',
    textAlign: 'left',
    width: '300px'
  }
};
// Customizable Area End
