import React from "react";
//Customizable Area Start
import FreelancerStripeAccountController, {
  Props,
  webConfigJSON,
} from "./FreelancerStripeAccountController.web";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
//Customizable Area End
export default class FreelancerStripeAccount extends FreelancerStripeAccountController {
  constructor(props: Props) {
    super(props);
//Customizable Area Start
//Customizable Area End
  }

  render() {
//Customizable Area Start
//Customizable Area End
    return (
//Customizable Area Start
      <>
        <FreelancerNavBar navigation={undefined} />
        <Box style={{ margin: "3rem" }}>
        <Box style={{ ...styles.leftBox, marginBottom:"2rem"}}>
          <Typography style={styles.findWork}>My Jobs</Typography>
            <NavigateNextIcon />
          <Typography style={styles.proposal}>
            My Proposals
          </Typography>
        </Box>
          <Grid container spacing={3}>
            {/* First Column */}
            <Grid item xs={6}>
              <Typography style={styles.mainTag}>
                {webConfigJSON.enterDetails}
              </Typography>
              <Typography style={styles.emailTag}>
                {webConfigJSON.emailAddress}
              </Typography>
              <input
                placeholder="john@gmail.com"
                style={styles.inputBox}
                type="text"
              ></input>
              <Typography style={styles.emailTag}>
                {webConfigJSON.enterStripeAccount}
              </Typography>
              <input
                placeholder="1234-2345-3456-4567"
                style={styles.inputBox}
              ></input>
              <Typography style={styles.emailTag}>
                {webConfigJSON.enterIFCICode}
              </Typography>
              <input
                placeholder="1234-2345-3456-4567"
                style={styles.inputBox}
              ></input>
              <Box>
                <Button style={styles.stripeButton as React.CSSProperties}>
                  {webConfigJSON.stripeAccount}
                </Button>
              </Box>
            </Grid>

            {/* Second Column */}
            <Grid item xs={6}>
              <Box style={styles.rateContainer}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography style={styles.oneDayRate as React.CSSProperties}>
                        {webConfigJSON.oneDayRate}
                      </Typography>
                      <Typography
                        style={styles.savioServiceFee as React.CSSProperties}
                      >
                        {webConfigJSON.savioServiceFee}
                      </Typography>
                      <Typography style={styles.youReceive as React.CSSProperties}>
                        {webConfigJSON.youReceive}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography style={{ ...styles.oneDayRate, textAlign: 'right' }}>
                        {webConfigJSON.rateOneDay}
                      </Typography>
                      <Typography
                        style={{ ...styles.savioServiceFee , textAlign: 'right'}}
                      >
                        {webConfigJSON.rateSavioService}
                      </Typography>
                      <Typography style={{ ...styles.youReceive, textAlign:'right'}}>
                        {webConfigJSON.rateYouReceive}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

          </Grid>
        </Box>
        <FooterWeb />
      </>
//Customizable Area End
    );
  }
}

//Customizable Area Start
const styles = {
  mainContainer: {},
  mainTag: {
    fontSize: "36px",
    fontFamily: "Inter,Rubik",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#3F3F3F",
  },
  emailTag: {
    fontSize: "24px",
    fontFamily: "Inter,Rubik",
    lineHeight: "43.57px",
    color: "#3F3F3F",
    paddingTop: "1rem",
  },
  inputBox: {
    width: "460px",
    height: "64px",
    padding: "1rem",
    border: "1px solid #DFD1D1",
    borderRadius: "10px",
    fontSize: "20px",
    fontFamily: "Inter,Rubik",
  },
  stripeButton: {
    width: "460px",
    height: "64px",
    backgroundColor: "#206FC4",
    color: "#FFFFFF",
    fontFamily: "Inter,Rubik",
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "left",
    borderRadius: "10px",
    marginTop: "3rem",
  },
  oneDayRate: {
    fontFamily: "Inter,Rubik",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#404040",
    padding:"20px"
  },
  savioServiceFee: {
    fontFamily: "Inter,Rubik",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#949494",
    padding:"20px"
  },
  youReceive:{
    fontFamily: "Inter,Rubik",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#206FC4",
    padding:"20px"
  },
  rateContainer:{
    border: "1px solid #DFD1D1" ,
    width: "65%", 
    margin: "auto",
    borderRadius: "10px",
  },
  leftBox: {
    display: "flex",
    justifyContent: "left",
  },
  findWork: {
    color: "#0A0A0A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  proposal:{
      color: "#206FC4",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
  }
};
//Customizable Area End