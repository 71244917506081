//Customizable Area Start
import React from "react";
import {  Button, Grid, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import {RightBox,TableContainer } from "../../../components/src/ActiveStep0.web";
// Customizable Area End

export default class FreelancerArchiveProposals extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }
  //Customizable Area Start
  render() {
    const { archiveInterviewData } = this.state;
    const archivedProposals = archiveInterviewData?.data?.attributes?.archived_praposal?.archived_praposal?.data.data?? [];


    return (
      <>
      <div style={{ margin: "16px" }}>
        <RightBox style={{ margin: "18px" }}>
          {archivedProposals.map((item: any, index: number) => (
            <TableContainer key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <div>
                    <Typography style={{ ...webStyle.dateArchive, fontWeight: 700 }}>
                      Archived on
                    </Typography>
                    <Typography style={webStyle.dateArchive}>{this.formatDate(item.attributes.rejected_at)}</Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={webStyle.jobname as React.CSSProperties}
                >
                  {item.attributes.post.data.attributes.name}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={webStyle.tableContent as React.CSSProperties}
                >
                </Grid>
              </Grid>
            </TableContainer>
          ))}
        </RightBox>
      </div>

      </>
    );
  }
}
const webStyle = {
  tableContent: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    textAlign: "center",
  },
  activeBtn: {
    background: "#206FC4",
    width: "275px",
    height: "50px",
    borderRadius: "25px",
    color: "#FFFFFF",
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
  },
  inactivbt: {
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
    color: "#434343",
    marginTop: "10px",
  },
  dateArchive: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
  },
};

// Customizable Area End
