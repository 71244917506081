Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully'
exports.aboutInvento = "About Invento Hub"
exports.FAQlistDetailsRequestEndPoint="bx_block_settings/faqs/search_faqs"
exports.getFAQlistDetailsRequestEndPoint="bx_block_settings/faqs"
exports.AboutUsDetailsRequestEndPoint="bx_block_settings/about_us/show_about_us_page"
exports.getContactUsDetailsRequestEndPoint="bx_block_settings/contact_us/show_contact_us_page"
exports.getPrivacyAndPolicyContentManagementDetailsRequestEndPoint="bx_block_privacy_settings/privacy_policies/show_privacy_policies_page"
exports.getTermsAndConditonsContentManagementDetailsRequestEndPoint="bx_block_privacy_settings/terms_and_conditions/show_terms_and_conditions_page"
exports.FindWorkDetailsRequestEndPoint="bx_block_settings/how_to_find_works/show_how_to_find_work"
exports.HireDetailsRequestEndPoint="bx_block_settings/how_to_hires/show_how_to_hire"
exports.description = "At InventoHub, our vision is to bridge the gap between innovation and expertise, creating a vibrant marketplace where inventors can unleash the full potential of their groundbreaking ideas. We believe that every ingenious concept deserves the best support and protection to flourish in the real world. Our platform is dedicated to empowering inventors by providing them with direct access to a carefully curated community of IP professionals, including IP lawyers, IP valuers, and IP enforcement personnel. Our mission is to facilitate a seamless connection between inventors and skilled IP professionals, fostering an environment where ideas can thrive and evolve into valuable assets. By offering a collaborative space, we enable inventors to fortify their inventions with robust intellectual property strategies and protect their creations with confidence. InventoHub is more than just a marketplace; it is a catalyst for innovation, where professionals passionate about intellectual property can share their expertise and contribute to shaping the future. Together, we aim to build a world where groundbreaking ideas receive the recognition and protection they deserve, propelling progress and transforming society for the better. Join us at InventoHub and become an integral part of a dynamic ecosystem, empowering inventors, and shaping the future of innovation through the lens of intellectual property expertise. Together, we can create a world where innovative ideas have the power to change lives and leave a lasting legacy. Welcome to InventoHub, where innovation meets expertise, and possibilities are boundless."
// Customizable Area End
