import React from "react";
// Customizable Area Start
import { Box, Button, FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from "@material-ui/core";
import { addBtn, thirdStep } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

import ExpertiseAndFeesFormController, {
  Props,
} from "./ExpertiseAndFeesFormController.web";
export default class ExpertiseAndFeesForm extends ExpertiseAndFeesFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  expertiseAreaError = () => {
    return (
      this.state.expertiseAreaError && (
        <div style={webStyle.formError}>
          {this.state.expertiseAreaError}
        </div>
      )
    );
  }

  countryExpertiseError = () => {
    return (
      this.state.countryExpertiseError && (
        <div style={webStyle.formError}>
          {this.state.countryExpertiseError}
        </div>
      )
    );
  }

  industryFocusError = () => {
    return (
      this.state.industryFocusError && (
        <div style={webStyle.formError}>
          {this.state.industryFocusError}
        </div>
      )
    );
  }

  servicesError = () => {
    return (
      this.state.servicesError && (
        <div style={webStyle.formError}>
          {this.state.servicesError}
        </div>
      )
    );
  }
  proficiencyError = (index: any) => {
    return this.state.proficiencyError[index] ? (
      <Typography style={webStyle.formError}>* Please select your language proficiency</Typography>
    ) : null;
  };


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={undefined} />
        <Box style={{ padding: "50px 20px 26px 20px" }}>
          <img src={thirdStep} style={webStyle.stepperStyle} />
        </Box>
        <Box style={webStyle.mainBox}>

          <Box style={webStyle.mainSubBox}>
            <Typography style={webStyle.basicDetailsHead}>Add your Area of Expertise, Industry Focus and Services</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Area of Expertise*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      name="skills"
                      placeholder="Add your Area of Expertise"
                      value={this.state.expertiseArea}
                      onChange={this.handleExpertiseInputChange}
                      onKeyPress={this.handleExpertiseKeyPress}
                      error={!!this.state.expertiseAreaError}
                    />
                  </FormControl>
                  {this.expertiseAreaError()}
                </Grid>
                {this.state.expertiseSkills.map((skill, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{skill}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteSkill(skill)}>
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Industry Focus*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      name="skills"
                      placeholder="Add your Industry Focus"
                      value={this.state.industryFocus}
                      onChange={this.handleIndustryInputChange}
                      onKeyPress={this.handleIndustryKeyPress}
                      error={!!this.state.industryFocusError}

                    />
                  </FormControl>
                  {this.industryFocusError()}
                </Grid>
                {this.state.industryExpertiseArray.map((industry, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{industry}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteIndustry(industry)}>
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Services*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <Select
                      defaultValue=""
                      displayEmpty
                      error={!!this.state.servicesError}
                      name="services"
                      value={this.state.services}
                      onChange={this.handleServicesInputChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" disabled>
                        Add your Services
                      </MenuItem>
                      <MenuItem value="Consulting">Consulting</MenuItem>
                      <MenuItem value="Development">Development</MenuItem>
                      <MenuItem value="Design">Design</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Training">Training</MenuItem>
                    </Select>
                  </FormControl>
                  {this.servicesError()}
                </Grid>
                <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.state.servicesArray.map((service, index) => (
                    <Box key={index} style={{ margin: '12px 14px' }}>
                      <Box style={webStyle.chipStyle}>
                        <Typography style={webStyle.skillStyle}>{service}</Typography>
                        <IconButton size="small" onClick={() => this.handleDeleteService(service)}>
                          <CloseIcon style={webStyle.iconStyle} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box style={webStyle.mainSubBox}>
            <Typography style={webStyle.basicDetailsHead}>Add your Countries of Expertise</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Country Expertise*</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <Select
                      value={this.state.countryExpertise}
                      defaultValue=""
                      displayEmpty
                      error={!!this.state.countryExpertiseError}
                      name="country"
                      onChange={this.handleCountrySelect}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" disabled>
                        All Countries
                      </MenuItem>
                      <MenuItem value="United States">United States</MenuItem>
                      <MenuItem value="Canada">Canada</MenuItem>
                      <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                      <MenuItem value="Australia">Australia</MenuItem>
                      <MenuItem value="India">India</MenuItem>
                    </Select>
                  </FormControl>
                  {this.countryExpertiseError()}
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {this.state.selectedCountries.map((country, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{country}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteCountry(country)}>
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          </Box>


          <Box style={webStyle.mainSubBox}>
            <Typography style={webStyle.basicDetailsHead}>Add your Language</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container>
                {this.state.addLanguage.map((item, index) => (
                  <Grid container spacing={3} key={index} alignItems="center">
                    <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <Typography style={webStyle.labelStyle}>Language</Typography>
                      <FormControl style={webStyle.textField} variant="outlined">
                        <Select
                          onChange={(event) => this.handleLanguageChange(index, event)}
                          value={item.language}
                          displayEmpty
                          name="language"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Choose Language
                          </MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi">Hindi</MenuItem>
                          <MenuItem value="Chinese">Chinese</MenuItem>
                          <MenuItem value="German">German</MenuItem>
                          <MenuItem value="French">French</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <Typography style={webStyle.labelStyle}>Proficiency</Typography>
                      <FormControl style={webStyle.textField} variant="outlined">
                        <Select
                          onChange={(event) => this.handleProficiencyChange(index, event)}
                          value={item.proficiency}
                          displayEmpty
                          error={!!this.state.proficiencyError[index]}
                          name="proficiency"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value="" disabled>
                            My Level is
                          </MenuItem>
                          <MenuItem value="Intermediate">Intermediate</MenuItem>
                          <MenuItem value="Advanced">Advanced</MenuItem>
                          <MenuItem value="Expert">Expert</MenuItem>
                        </Select>
                      </FormControl>
                      {this.proficiencyError(index)}
                    </Grid>

                    {index > 0 && (
                        <Button
                          onClick={() => this.handleRemoveLanguage(index)}
                          style={webStyle.removeTextStyle}
                        >
                          Remove
                        </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "32px",
                marginBottom: "40px",
                cursor: "pointer",
              }}
              onClick={this.handleAddLanguage}
            >
              <img src={addBtn} style={{ width: "34px", height: "34px" }} />
              <Typography style={webStyle.addStyle}>Add another language</Typography>
            </Box>
          </Box>


          <Box style={webStyle.mainSubBox}>
            <Typography style={webStyle.basicDetailsHead}>Add your Fees Structure</Typography>
            <Grid item xs={12} sm={12} style={{ marginTop: "32px" }} >
              <Grid container>

                <Grid item xs={12} sm={10} >
                  <Typography style={webStyle.subHeaderStyle}>
                    Clients will see your project rate on your profile and in search results once you publish your profile.
                  </Typography>
                  <Typography style={{ ...webStyle.subHeaderStyle, fontWeight: 400 }}>
                    You can adjust your rate every time you submit a proposal.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={{ ...webStyle.feeItem, marginTop: "0px" }}>
                    <TextField
                      variant="outlined"
                      style={webStyle.feeInput}
                      defaultValue="$20"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography style={webStyle.subHeaderStyle}>
                    Services Fee
                  </Typography>
                  <Typography style={{ ...webStyle.subHeaderStyle, fontWeight: 400 }}>
                    This helps us run the platform and provide services like payment and customer support.                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyle.feeItem}>
                    <TextField
                      variant="outlined"
                      style={webStyle.feeInput}
                      defaultValue="$20"
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography style={webStyle.subHeaderStyle}>
                    You’ll get
                  </Typography>
                  <Typography style={{ ...webStyle.subHeaderStyle, fontWeight: 400 }}>
                    The estimated amount you’ll receive after service fees.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyle.feeItem}>
                    <TextField
                      variant="outlined"
                      style={webStyle.feeInput}
                      defaultValue="$20"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography style={webStyle.flagStyle}>We do not deduct any tax. You need to pay taxes according to your country.</Typography>
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "48px" }}>
            <Grid item>
              <Button variant="outlined" style={webStyle.backButton}
               onClick={this.handleback.bind(this)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="text"
                    style={webStyle.laterText}
                  >
                    I'll do it later
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" style={webStyle.nextButton} onClick={this.handleNext}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  stepperStyle: {
    width: "100%",
    height: "auto",
    maxWidth: "1500px",
    margin: "0 auto",
    display: "block",
  },
  removeTextStyle:{
    fontFamily: "Rubik",
    marginLeft: "auto",
     display: "block",
     color:"red" ,
     fontSize:"14px",
     textTransform: "inherit" as "inherit",
     marginRight: "10px"
  },
  mainBox: {
    padding: "12px 56px"
  },
  mainSubBox: {
    marginTop: "40px"
  },
  basicDetailsHead: {
    fontFamily: "Rubik",
    fontSize: "36px",
    fontWeight: 600,
  },
  BoxTwoStyle: {
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius: "12px",
    marginTop: "32px"
  },
  labelStyle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#0A0A0A",
    fontWeight: 500,
    marginBottom: "8px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important"
    }
  },
  chipStyle: {
    background: "#E4F5FF",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  skillStyle: {
    color: '#0A0A0A',
    padding: "2px 8px"
  },
  iconStyle: {
    color: '#0A0A0A',
    fontSize: '20px',
  },
  addStyle: {
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color: "#364BA0"
  },
  subHeaderStyle: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 600,
    color: "#0A0A0A"
  },
  feeContainer: {
    marginTop: '30px',
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
  },
  feeItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
  },

  feeInput: {
    width: '250px',
  },
  noteText: {
    backgroundColor: '#FFEB3B',
    padding: '8px',
    borderRadius: '4px',
    marginTop: '20px',
    fontSize: '14px',
    color: '#666666',
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  laterText: {
    color: '#364BA0',
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  flagStyle: {
    marginTop: "24px",
    background: "#FEF3C7",
    color: "#D97706",
    padding: "10px",
    width: "443px",
    borderLeft: "4px solid #D97706",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Rubik",
    fontWeight: 500
  },
  formError: {
    color: "red",
    fontSize: 14,
    fontFamily: "'Inter', sans-serif",
    marginTop: "4px"
  },
};
// Customizable Area End