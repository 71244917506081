import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
import { getStorageData, removeStorageData, setStorageData } from 'framework/src/Utilities';
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  type: string;
  legal: any;
  other: any;
  experienceInIpValuation: any;
  countryOfOperationError: string;
  focusArea2: any;
  linkedInProfileLink: any;
  countryOfOperation: any;
  cityOfOperation: string;
  focusArea3: any;
  authToken: string;
  otpError: any;
  VerificationError: boolean;
  otpValue: any;
  feesAmount: any;
  focusArea3Error: string;
  featuredInTheMedia: any;
  IpConference: any;
  article: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  patentLawOffice: any[];
  showpatentLawOffice: boolean;
  error: any;
  showPageError: boolean;
  step3FileUplod1: any;
  step3FileUplod2: any;
  focusArea2Error: string;
  profileName: string;
  step3FileUplod3: any;
  step4FileUplod1: any;
  emailValid: string;
  step4FileUplod2: any;
  step4FileUplod3: any;
  typeError: string;
  disableBtn: boolean;
  otherInfo: string;
  timerExpired: boolean;
  featuredInTheMediaError: string;
  IpConferenceError: string;
  articleError: string;
  disabledResendOtp: boolean;
  seconds: any;
  verfication: boolean;
  successPage: boolean
  patentLawOfficeError: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerIpValuationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpVerificationForEmailCallId: string = '';
  ipValuationCallById: string = '';
  sendEmailOtpCallId: string = '';
  myInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      timerExpired: false,
      type: '',
      legal: '',
      verfication: false,
      successPage: false,
      other: '',
      experienceInIpValuation: '',
      patentLawOfficeError: '',
      typeError: '',
      focusArea2Error: '',
      otpError: "",
      VerificationError: true,
      otpValue: '',
      authToken: "",
      otherInfo: '',
      focusArea2: '',
      linkedInProfileLink: '',
      countryOfOperation: '',
      cityOfOperation: '',
      focusArea3: '',
      countryOfOperationError: '',
      feesAmount: '',
      featuredInTheMedia: '',
      IpConference: '',
      seconds: 60,
      emailValid: '',
      article: '',
      featuredInTheMediaError: '',
      IpConferenceError: '',
      articleError: '',
      anchorElement: null,
      openInfoPopper: false,
      patentLawOffice: [],
      showpatentLawOffice: false,
      error: {},
      showPageError: false,
      step3FileUplod1: '',
      step3FileUplod2: "",
      step3FileUplod3: "",
      step4FileUplod1: '',
      step4FileUplod2: "",
      step4FileUplod3: "",
      focusArea3Error: '',
      disabledResendOtp: true,
      profileName: '',
      disableBtn: false,

    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  handleNext1 = () => {
    const { legal, other, experienceInIpValuation, linkedInProfileLink, focusArea2, type } = this.state;
    if (!legal) {
      this.setState({
        error: { legal: '* Please  Enter Collage Name.' }
      })
    }
    else if (this.state.error.legal) {
      this.setState({
        error: {
          ...this.state.error,
          legal: '* Should  contain minimum 3 characters'
        }
      })
    } else if (!other) {
      this.setState({
        error: {
          other: '* Please  Enter Collage Name.'
        }
      })
    } else if (this.state.error.other) {
      this.setState({
        error: {
          ...this.state.error,
          other: '* Should  contain minimum 3 characters'
        }
      })
    } else if (!experienceInIpValuation) {
      this.setState({
        error: {
          experienceInIpValuation: '* Please  Enter IP Valuation'
        }
      })
    } else if (this.state.error.experienceInIpValuation) {
      this.setState({
        error: {
          ...this.state.error,
          experienceInIpValuation: '* Allowed numbers with a maximum of 2 digits'
        }
      })
    } else if (!type) {
      this.setState({
        typeError: '* Please  Select any Type.'
      })
    } else if (!focusArea2) {
      this.setState({
        focusArea2Error: '* Please  Select any Type.'
      })
    } else if (this.state.error.linkedInProfileLink) {
      this.setState({
        error: {
          linkedInProfileLink: "* Should  contain a valid website link."
        }
      });
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          legal: '',
          other: '',
          linkedInProfileLink: '',
          experienceInIpValuation: '',
          typeError: '',
          focusArea2Error: ''
        }
      })
    }
  }

  handleNext2 = () => {
    const { cityOfOperation, countryOfOperation, patentLawOffice, focusArea3, feesAmount } = this.state;
    if (!cityOfOperation) {
      this.setState({
        error: {
          cityOfOperation: '* This field is required.'
        }
      })
    } else if (this.state.error.cityOfOperation) {
      this.setState({
        error: {
          cityOfOperation: '* Should  contain minimum 3 characters.'
        }
      })
    } else if (!countryOfOperation) {
      this.setState({
        countryOfOperationError: '* Please  select any country Option.'
      })
    } else if (patentLawOffice.length == 0) {
      this.setState({
        patentLawOfficeError: '* Please  select any Country Patent.'
      })
    } else if (!focusArea3) {
      this.setState({
        focusArea3Error: '* Please  select any Focus.'
      })
    } else if (!feesAmount) {
      this.setState({
        error: {
          feesAmount: '* This field is requird.'
        }
      })
    } 
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          cityOfOperation: '',
          countryOfOperationError: '',
          showpatentLawOfficeError: ''
        }
      })
    }
  }

  handleNext3 = () => {
    const { featuredInTheMedia, IpConference, article, step3FileUplod1, step3FileUplod3, step3FileUplod2 } = this.state;
    if (!featuredInTheMedia) {
      this.setState({
        featuredInTheMediaError: '* Please  select any media.'
      })
    } else if (featuredInTheMedia === 'Yes' && !step3FileUplod1) {
      this.setState({
        error: {
          step3FileUplod1: '* Please  Upload any File.'
        }

      })
    } else if (!IpConference) {
      this.setState({
        IpConferenceError: '* Please  select any conference.'
      })
    } else if (IpConference === 'Yes' && !step3FileUplod2) {
      this.setState({
        error: {
          step3FileUplod2: '* Please  Upload any File.'
        }
      })
    }
    else if (!article) {
      this.setState({
        articleError: '* Please  select any article.'
      })
    } else if (article === 'Yes' && !step3FileUplod3) {
      this.setState({
        error: {
          step3FileUplod3: '* Please  Upload any File.'
        }
      })
    }
    else {
      this.setState({
        activeStep: this.state.activeStep + 1,
        error: {
          ...this.state.error,
          featuredInTheMediaError: '',
          IpConferenceError: '',
          articleError: '',
          step3FileUplod1: '',
          step3FileUplod2: '',
          step3FileUplod3: '',
        }
      })
    }
  }

  handleNext4 = () => {
    this.ipValuationDetails();
  }

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };
  handleLinkValidChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isValidLinkedInLink = /^https:\/\/www\.linkedin\.com\/.*/.test(value);
    this.setState(({
      [name]: value,
      error: {
        ...this.state.error,
        [name]: isValidLinkedInLink ? '' : 'Invalid LinkedIn profile link format'
      }
    } as unknown) as Pick<S, keyof S>);
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };

  handletextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?! )[A-Za-z ]{0,200}$/.test(value)) {
      if ((this.state as any)[name] !== value) {
        value.length >= 3 ? delete updatedError[name] : updatedError[name] = "* Should  contain minimum 3 characters";
        this.setState({
          [name]: value,
          error: updatedError
        } as Pick<S, keyof S>);
      }
    } else {
      updatedError[name] = "* Should  contain only letters and maximum of 200 characters long";
      this.setState({ error: updatedError });
    }
  };

  handletextChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?! )[A-Za-z, ]{0,200}$/.test(value)) {
      if ((this.state as any)[name] !== value) {
        if (value.length >= 3 || value === '') {
          delete updatedError[name]; 
        } else {
          updatedError[name] = "* Should contain minimum 3 characters";
        }
  
        this.setState({
          [name]: value,
          error: updatedError
        } as Pick<S, keyof S>);
      }
    } else {
      updatedError[name] = "* Should contain only letters, spaces, and commas (maximum 200 characters)";
      this.setState({ error: updatedError });
    }
  };
  

  handleExpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const numberFields = ["experienceInIpValuation"];
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}$/;

      // Use regex to check if the value contains only numbers
      if (regexExp.test(value)) {
        delete updatedError[name];
        // Check if the value is changing before updating state
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            error: updatedError
          } as Pick<S, keyof S>);
        }
        if (Number(value) > 99) {
          updatedError[name] = "* Should  contain only numbers";
          this.setState({ error: updatedError });
        }
      } else {
        updatedError[name] = "* Allowed numbers with a maximum of 2 digits";
        this.setState({ error: updatedError });
        setTimeout(() => {
          this.setState({ error: false });
        }, 2000);
      }
    }

  };
  handleFeesFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numberFields = ["feesAmount"]; // Add your number field names here
    let updatedError = { ...this.state.error };

    // Check if the field is in the numberFields array
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,7}$/; // Adjust the regular expression to allow 0 to 7 digits

      // Use regex to check if the value contains only numbers
      if (regexExp.test(value)) {
        delete updatedError[name];
        // Check if the value is changing before updating state
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            error: updatedError
          } as Pick<S, keyof S>);
        }
      } else {
        updatedError[name] = "* Should  contain only numbers with a maximum of 7 digits";
        this.setState({ error: updatedError });
      }
    } else {
      // Handle validation for other fields here
    }
  };


  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  handlePatentLawOfficeSelection = (value: any) => {
    this.setState(prevState => {
      let patentLawOfficeCopy = [...this.state.patentLawOffice];
      if (patentLawOfficeCopy.includes(value)) {
        patentLawOfficeCopy = patentLawOfficeCopy.filter(x => x !== value);

      } else {
        patentLawOfficeCopy = [...patentLawOfficeCopy, value];
        this.setState({
          patentLawOfficeError: ''
        })
      }
      return {
        ...prevState,
        showpatentLawOffice: true,
        patentLawOffice: patentLawOfficeCopy
      };
    });
  };
  handleClickAway = () => {
    this.setState({ showpatentLawOffice: false });
  };

  handleSelectBoxClick = () => {
    this.setState((prevState: any) => ({
      showpatentLawOffice: !prevState.showpatentLawOffice
    }));
  };
  
  handleFileUpload = (event: any) => {
    const { files } = event.target;
    const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
    const stateKeyRef = event.target.name;
    const selectedFile = files[0];
    const maxSizeMB = 5;
    let error = "";

    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);

      if (!allowedExtensions.test(selectedFile.name)) {
        error = '* Invalid file type. Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.';
      } else if (fileSizeMB > maxSizeMB) {
        error = "* File size exceeds the maximum limit of 5 MB.";
      }

      if (!error) {
        this.setState(({
          [stateKeyRef]: selectedFile,
          error: {
            ...this.state.error,
            [stateKeyRef]: ""
          }
        } as unknown) as Pick<S, keyof S>);
      } else {
        this.setState(({
          error: {
            ...this.state.error,
            [stateKeyRef]: error
          }
        } as unknown) as Pick<S, keyof S>);
      }
    } else {
      this.setState(({
        [stateKeyRef]: null,
        error: {
          ...this.state.error,
          [stateKeyRef]: error
        }
      } as unknown) as Pick<S, keyof S>);
    }
  };
  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } else {
      this.otpVerificationForEmail()
    }
  }

  displayTime() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }

  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    this.sendEmailOtp();
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000);
  };

  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
    removeStorageData("firstName");
    removeStorageData("lastName");
    removeStorageData("password");
    removeStorageData("email");
    removeStorageData("country");
    removeStorageData("job");
  }

  handleLogoClick = () => {
    this.props.navigation.navigate("LandingPage");
  }
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.ipValuationCallById) {
        this.setState({ disableBtn: false })
        if (responseJson.data) {
          this.sendEmailOtp();
        }
        else if (responseJson.errors) {
          this.setState({ emailValid: responseJson.errors[0].account })
        }
      }
      else if (apiRequestCallId === this.sendEmailOtpCallId) {
        if (responseJson) {
          this.setState({ authToken: responseJson?.meta?.token, verfication: true });
          this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
              this.setState(({ seconds }) => ({
                seconds: seconds - 1
              }));
            } else {
              clearInterval(this.myInterval);
              this.setState({ timerExpired: true, disabledResendOtp: false, seconds: 60 })
            }
          }, 1000);
        }
      } else if (apiRequestCallId === this.otpVerificationForEmailCallId) {
        if (responseJson.errors) {
          this.setState({ otpError: responseJson.errors[0].otp })
        } else {
          this.setState({ successPage: true })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  ipValuationDetails = async () => {
    this.setState({ disableBtn: true })
    const header = {
      "Content-Type": "application/json"
    };
    const { legal, linkedInProfileLink, other, step3FileUplod1, patentLawOffice, step3FileUplod2, step3FileUplod3, step4FileUplod3, step4FileUplod2, step4FileUplod1, experienceInIpValuation, article, IpConference, type, featuredInTheMedia, focusArea2, cityOfOperation, countryOfOperation, focusArea3, feesAmount } = this.state;
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": await getStorageData("email"),
          "password": await getStorageData("password"),
          "first_name": await getStorageData("firstName"),
          "last_name": await getStorageData("lastName"),
          "role_id": "freelancer"
        },
        "ip_valuation_attributes":
        {
          "qualification": {
            "legal_qualification": legal,
            "other_qualification": other,
            "no_of_experience": experienceInIpValuation,
            "is_individual_firm": type,
            "focus_area": focusArea2,
            "linkedin_profile_link": linkedInProfileLink
          },
          "area_operation": {
            "country_of_operation": countryOfOperation,
            "country_patent_law_guideline": patentLawOffice,
            "city_of_operation": cityOfOperation,
            "focus": focusArea3,
            "fees_assignment": "assignment",
            "fees": feesAmount
          },
          "success_story": {
            "have_you_featured_media": featuredInTheMedia === 'Yes' ? URL.createObjectURL(step3FileUplod1) : '',
            "valuation_featured_media_url": step3FileUplod1,
            "have_you_spoken_ip_conference": IpConference === 'Yes' ? URL.createObjectURL(step3FileUplod2) : '',
            "valuation_conference_url": step3FileUplod2,
            "have_you_published_article": article === 'Yes' ? URL.createObjectURL(step3FileUplod3) : '',
            "valuation_published_url": step3FileUplod3
          },
          "awards": {
            "valuation_government_award_url": step4FileUplod1 ? URL.createObjectURL(step4FileUplod1) : '',
            "valuation_industry_award_url": step4FileUplod3 ? URL.createObjectURL(step4FileUplod3) : '',
            "valuation_academic_award_url": step4FileUplod2 ? URL.createObjectURL(step4FileUplod2) : '',
          }
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ipValuationCallById = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/sign_up");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendEmailOtp = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    let emailData = {
      "data": {
        "attributes": {
          "email": await getStorageData("email")
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sendEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/otps/email_otp");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(emailData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otpVerificationForEmail = () => {
    const header = {
      "Content-Type": "application/json"
    };

    let OtpData = {
      "data": {
        "token": this.state.authToken,
        "otp_code": this.state.otpValue
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otpVerificationForEmailCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/account_confirm");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(OtpData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
