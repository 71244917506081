import React from "react";
// Customizable Area Start
import { Box, Button, Dialog, CircularProgress,DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Menu, MenuItem, Snackbar, TextField, Typography } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DraftsController, {
    DraftListData,
    Props,
    webConfigJSON
} from "./DraftsController";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';

// Customizable Area End
export default class Drafts extends DraftsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { loading } = this.state;
        if (loading) {
          return (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
              <CircularProgress />
            </Box>
          );
        }
        return (
            //Merge Engine DefaultContainer
            <>
            <Grid container style={webStyles.Container}>
            <Box style={webStyles.searchContainer}>
                                <TextField
                                    variant="outlined"
                                    id="search"
                                    data-test-id="searchDrafts"
                                    value={this.state.searchDrafts}
                                    style={webStyles.searchField}
                                    placeholder="search"
                                    size="small"
                                    onChange={this.handleSearchDraftsChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                                <Button variant="contained" style={webStyles.filterBtn} 
                                    data-test-id="handleClickSearchDrafts"
                                    onClick={this.handleClickSearchDrafts}>Search</Button>
             </Box>
             <Grid item style={webStyles.DraftBorderBox}>
                        <Box>
                        <Grid container>
  <Grid item style={{ width: "100%" }}>
    {this.state.noDraftsFound ? (
      <Typography style={webStyles.noProfile}>No Draft found</Typography>
    ) : (
      this.state.draftListData.map((item: DraftListData, index: any) => {
        return (
          <Box key={index} style={webStyles.proposalContainer}>
            <Box onClick={this.handleDraftJobDescription}>
              <Typography style={webStyles.postDescription} color="secondary">{item.attributes?.name}</Typography>
              <Typography color="secondary" style={{ fontSize: "15px" }}>{item.attributes?.drafted_at}</Typography>
            </Box>
            <Box style={{ display: "flex", gap: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                data-test-id="handleEditJob"
                style={webStyles.viewProposalBtn}
                onClick={() => this.handleEditJob(item.id)}
              >
                {webConfigJSON.edit}
              </Button>
              <Box style={webStyles.iconButton}>
                <IconButton
                  data-test-id="handleclick"
                  aria-label="more"
                  aria-controls="long-menu"
                  onClick={(event) => this.handleClick(event, item.id)}
                  style={{ width: "40px", height: "40px" }}
                >
                  <MoreHorizIcon color="primary" />
                </IconButton>
              </Box>
              {this.state.anchorEl && (
                <Menu
                  id="long-menu"
                  data-testid="menu-options"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  data-test-id="closeButton"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: -40,
                    horizontal: 'right',
                  }}
                >
                  <MenuItem data-test-id="handleClose" onClick={() => this.handleEditJob(this.state.activeDraftId)}>
                    {webConfigJSON.edit}
                  </MenuItem>
                  <MenuItem data-test-id="handleDeleteDraft" onClick={() => this.handleDeleteDraft(this.state.activeDraftId)}>
                    {webConfigJSON.remove}
                  </MenuItem>
                </Menu>
              )}
            </Box>
          </Box>
        );
      })
    )}
  </Grid>
</Grid>
                            </Box>
              </Grid>
              {this.state.totalPages > 1 && (
               <Box style={webStyles.paginationContainer}>
          <Pagination 
            count={this.state.totalPages} 
            page={this.state.currentPage} 
            onChange={this.handlePageChange}
          />
          </Box>
        )}
              </Grid>


                {/* Confirmation Dialog */}
                <Dialog
                    open={this.state.deletePopUp}
                    onClose={this.cancelDeleteDraft}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={webStyles.EditHeadings}>
                            Are you sure you want to delete this draft?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelDeleteDraft} data-test-id="cancelDeleteDraft" color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.confirmDeleteDraft} data-test-id="confirmDeleteDraft" style={webStyles.postBtn} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                  {/* Snackbar for Success Message */}
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          message={this.state.snackbarMessage}
          action={
            <Button color="inherit" size="small" onClick={this.closeSnackbar} data-test-id="closeSnackbar">
              Close
            </Button>
          }
        />
            </>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    Container: {
      boxSizing: 'border-box' as 'border-box',
      flexDirection: 'column' as 'column',
      display: 'flex',
      background: "#FAFBFC",
      overflow: 'hidden',
    },
    noProfile:{
      fontSize:'1.5rem',
      paddingTop:'5%',
      textAlign:'center' as 'center',
      color:'grey'
  },
    DraftBorderBox: {
        padding: '32px 0px',
        overflow: 'auto',
        borderRadius: '5px',
        background: 'FAFBFC',
        width: "100%"
    },
    searchContainer: {
      display: "flex",
      gap: "2%",
      marginTop:"40px",
      width:"810px",
      marginLeft:"24px"
    },
    searchField: {
      width: "100%",
    },
    filterBtn: {
      borderRadius: "7px",
      padding: "0 3%",
      textTransform: "capitalize" as "capitalize",
      background: "#206FC4",
      color: "white",
      fontFamily:"Inter",
      width:"20%"
    },
    viewProposalBtn: {
        textTransform: 'inherit' as 'inherit',
        minWidth: 'max-content',
        height: 'min-content',
        background: "#206FC4",
        fontSize:"17px",
       fontFamily:"Inter",
       borderRadius:"6px"
    },
    postDescription: {
        fontSize: '22px',
        marginBottom: '2%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
       fontFamily:"Inter"

    },
    iconButton: {
        background: '#DBE7F4',
        borderRadius: '50%',
        textAlign: 'center' as 'center'
    },
    proposalContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e1e1e1',
        padding: '22px 25px',
        gap: '20px',
        alignItems: 'center',
       fontFamily:"Inter"

    },
    EditHeadings:{
        fontSize:"18px",
        fontWeight:500,
        fontFamily:"Inter",
        color:"#515354"
      },
      postBtn:{
        background: "#206FC4",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "inherit" as "inherit",
    marginLeft: "10px",
    padding: "10px",
    borderRadius: "10px",
    fontFamily:"Inter"
    
    },
      paginationContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop:"14px"
    },
};
// Customizable Area End
