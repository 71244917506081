import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userName: string;
  designation: string;
  location: string;
  hourPerWeek: string;
  varification: string;
  langauge: string;
  rating: number | null;
  university: string;
  degree: string;
  projectCount: string;
  hoursCompleted: string;
  slotDay: string;
  slotsArray: string[];
  showMore: boolean;
  price: string;
  projectDetails: {
    id: number,
    projectName: string,
    projectRating: string,
    rate: number | null,
    startDate: string;
    endDate: string;
    earnings: string;
  }[];
  portfolio: string[];
  skills: string[];
  currentPage: number;
  profileRationg:string;
  freelancerId:string;
  authToken:string;
  freelancerDetails:{
    id: string,
    type: string,
    attributes: {
        freelancer: {
            profile: {
                url: string
            },
            username: string,
            job_title: string,
            location: string,
            hours_per_week: string,
            education: string,
            about_me: string,
            skills: string[],
            verification: boolean,
        }
    }
  }
  // Customizable Area End
}
interface SS { }

export default class InviteToJobController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  displyFreelancerDetails : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.displyFreelancerDetails = ""

    this.state = {
      userName: 'Rahul Sharma',
      designation: 'Website Designer',
      location: 'Latvia in Europe',
      hourPerWeek: 'As needed open to hours',
      varification: 'ID Verified',
      langauge: 'English',
      rating: 4,
      university: 'Ho Chi Minh City University of Information Technology',
      degree: 'Engineers Degree, Information Technology 1990-1995',
      projectCount: '1440',
      hoursCompleted: '5042',
      slotDay: 'Today(3 Slots)',
      slotsArray: ['04:00 PM', '05:00 PM', '06:00PM'],
      showMore: false,
      price: '$37.50',
      projectDetails: [
        {
          id: 1,
          projectName: 'Top Momentum T2',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 2,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 3,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 4,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        },
        {
          id: 5,
          projectName: 'Tradestation Drawing Tool',
          projectRating: '4.91/5',
          rate: 3,
          startDate: 'Mar 6,2020',
          endDate: 'Oct 12,2020',
          earnings: 'Private earnings',
        }
      ],
      portfolio: [
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
        'Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best solutions to the same this will help users solve their problems quicker and help inventors solve practical problems facong humankind',
      ],
      skills: ['PHP', 'Android', 'SQL', 'Java', 'Kotlin'],
      currentPage: 1,
      profileRationg:'4.25/5',
      freelancerId:'',
      authToken:'',
      freelancerDetails:{
        id: '',
        type: '',
        attributes: {
            freelancer: {
                profile: {
                    url: ''
                },
                username: '',
                job_title: '',
                location: '',
                hours_per_week: '',
                education: '',
                about_me: '',
                skills: [],
                verification: false
            }
        }
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      freelancerId: await storage.get('freelancerId'),
      authToken: await storage.get("authToken")
    })
    this.getFreelancerDetails();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if(this.displyFreelancerDetails === requestCallId){
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if(responseJson.data){
          this.setState({freelancerDetails : responseJson.data})
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleHireBtn = () =>{
    this.props.navigation.navigate("Hire");

  }
  handleMessageBtn=()=>{
    this.props.navigation.navigate("ContactBox");
  }
  handleShowText = () => {
    this.setState({ showMore: !this.state.showMore })
  }
  handleBackClick = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  }
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page })
  }
  handleNextClick = () => {
    if (this.state.currentPage < 10) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  }
  handleText = (type:string,page:number) => {
    if(type === 'previous'){
      return 'Back'
    }else if(type === 'next'){
      return 'Next'
    }else{
      return page
    }
  }
  handleViewProfile = () => {
    const ViewProfileMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    ViewProfileMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ViewFreelancerProfile'
    );
    ViewProfileMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(ViewProfileMessage);
    runEngine.sendMessage(ViewProfileMessage.id, ViewProfileMessage)
  }
  getFreelancerDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.contentTypeApi,
      "token": this.state.authToken
    };
    this.displyFreelancerDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getFreelancerEndPoint + `${this.state.freelancerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
