import React from 'react';
// Customizable Area Start
import { Box, Grid, Link } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import FreelancerProfile from './FreelancerProfile.web';
import FreelancerProfileSettings from './FreelancerProfileSettings.web';
import FreelancerPersonalDetails from './FreelancerPersonalDetails.web'


const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    },
    secondary: {
      main: '#696A6A'
    }
  },
  typography: {
    fontFamily: 'Inter,Rubik'
  }
});
// Customizable Area End
import FreelancerSettingsController, { Props } from './FreelancerSettingsController';
import FreelancerPasswordSetting from './FreelancerPasswordSetting.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';

export default class FreelancerSettings extends FreelancerSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
        <FreelancerNavBar navigation={this.props.navigation} />
          <Grid container style={webStyles.freelacerContainer}>
            <Grid item xs={12} sm={3}>
              <Box style={webStyles.linkBox}>
                {this.state.profileDetails.map((item,index) => 
                    <Link
                    key={index}
                    data-testid={'freelancer-link-' + index}
                    underline="none"
                    style={webStyles.settingLink}
                    onClick={() => this.handleSelectComponent(item.component)}
                    color={
                      this.state.storeData === item.component ? 'primary' : 'secondary'
                    }
                  >
                    {item.name}
                  </Link>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              {this.state.storeData === 'personalDetails' && (
                <FreelancerPersonalDetails {...this.props} data-testid="personal-details"/>
              )}
              {this.state.storeData === 'myProfile' && (
               <FreelancerProfile {...this.props} data-testid="my-profile"/>
              )}
              {this.state.storeData === 'profileSettings' && (
                <FreelancerProfileSettings data-testid="profile-settings" {...this.props}/>
              )}
              {this.state.storeData === 'passwordSecurity' && (
                <FreelancerPasswordSetting {...this.props} data-testid="password-security" isClient={false}/>
              )}
            
            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  linkBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'wrap' as 'wrap',
    background: '#F0F0F0',
    borderRadius: '15px',
  },
  settingLink: {
    borderBottom: '1px solid #fff',
    fontFamily: 'Rubik',
    fontSize:'1rem',
    padding: '18px 0 15px 20px',
    fontWeight:500,
    cursor:'pointer'
  },
  freelacerContainer: {
    padding: '3% 6%',
    justifyContent: 'space-evenly'
  },
};
// Customizable Area End
