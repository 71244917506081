// Customizable Area Start
import React from 'react';
import { Box, ThemeProvider, Typography, createTheme } from '@material-ui/core';
import TermsAndConditonsContentManagementController, {
  Props
} from './TermsAndConditonsContentManagementController.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { getNavBar } from './About.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export default class TermsAndConditonsContentManagement extends TermsAndConditonsContentManagementController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
      {getNavBar({userType:this.state.userType,...this.props})}
        <Box display={'grid'} style={{ padding: '5% 8%' }}>
          <Box>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '35px',
                fontWeight: 600,
                marginBottom: '2%'
              }}
            >
              Terms & Conditions
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 400 }}
              dangerouslySetInnerHTML={{
                __html: this.state.TermsAndConditonsContentManagementRes
                  .description
              }}
            />
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {};

// Customizable Area End
