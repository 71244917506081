import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  numberOfMileStone: string;
  completedMileStone: string;
  inCompletedMileStone: string,
  startDate: string;
  error: boolean;
  inputList: {
    id: number;
    mileDescription: string;
    amount: string,
    date: string;
    status: string;
  }[];
  postDescription: string;
  projectDetails: string;
  inputFieldId: number;
  open: boolean;
  // Customizable Area End
}
interface SS {}

export default class JobPostDescriptionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      numberOfMileStone: '',
      completedMileStone: '',
      inCompletedMileStone: '',
      startDate: "2021-01-01",
      error: false,
      postDescription: 'Hotel Website(I am looking for a person who can make me the website of my hotel)',
      projectDetails: `${webConfigJSON.lorem}`,
      inputList: [
        {
          id: 1,
          mileDescription: 'Flow Of Project',
          amount: '5',
          date: '2016-12-27',
          status: 'completed',
        },
        {
          id: 2,
          mileDescription: 'Flow Of Project',
          amount: '5',
          date: '2016-12-27',
          status: 'completed',
        },
        {
          id: 3,
          mileDescription: 'Flow Of Project',
          amount: '5',
          date: '2016-12-27',
          status: 'completed',
        },
        {
          id: 4,
          mileDescription: 'Flow Of Project',
          amount: '5',
          date: '2016-12-27',
          status: 'completed',
        }
      ],
      inputFieldId: 0,
      open: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleEdit = (index: number) => {
    this.setState({ inputFieldId: index })
  }
  onChangeInput = (event: { target: {name:string, value: string } }, id: number) => {
    const { name, value } = event.target
    const editData = this.state.inputList.map((item) =>
      item.id === this.state.inputFieldId && id && name ? { ...item, [name]: value } : item
    )
    this.setState({ inputList: editData })
  }
  handleOpen = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({ open: false })
  }

  numberOfMileStoneChange = (event: { target: { value: string } }) => {
    this.setState({ numberOfMileStone: event.target.value });
  };
  completedMileStoneChange = (event: { target: { value: string } }) => {
    this.setState({ completedMileStone: event.target.value });
  };
  handleStartDate = (event: { target: { value: string } }) => {
    this.setState({ startDate: event.target.value });
  };
  inCompletedMileStoneChange = (event: { target: { value: string } }) => {
    this.setState({ inCompletedMileStone: event.target.value });
  };
  // Customizable Area End
}
