// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider.web';
import { ChangeEvent } from 'react';
export const webConfigJSON = require('./config.js');

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  isPayment: boolean;
  cardNumber: any;
  firstName: string;
  lastName: string;
  expirationMonth: any;
  expirationYear: any;
  securityCode: any;
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: any;
  error: any;
  numberError: any;
  countryList:any;
  selected: boolean;
}

// Customizable Area End
interface SS {
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
export default class AddPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  AboutUsId: string;
  fetchCountryListId:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.AboutUsId = '';
    this.fetchCountryListId = "";
    this.state = {
      isPayment: false,
      cardNumber: "",
      firstName: '',
      lastName: '',
      expirationMonth: "",
      expirationYear: "",
      securityCode: "",
      country: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: "",
      error: '',
      numberError: '',
      countryList:"",
      selected: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchCountryList()

    // Customizable Area End
  }
  // Customizable Area Start

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?!.*,,)(?!^[ ,])(?![\s,])[A-Za-z, ]{0,60}$/.test(value)) {
      if (value.length >= 3 || (value.length >= 3 && value.includes(','))) {
        delete updatedError[name];
      } else {
        updatedError[name] = '*Should contain minimum 3 characters';
      }
      this.setState({
        [name]: value,
        error: updatedError,
      } as Pick<S, keyof S>);
    }
  };
   isValidNumber(s:string) {
    s = s.replace(/\s+/g, '');
    return /^\d{0,20}$/.test(s);
}
  formatCardNumber = (num: string) => {
    return num.replace(/(\d{4})(?=\d)/g, '$1 ');
  };

  handleCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.numberError };
  
    if (this.isValidNumber(value)) {
      delete updatedError[name];
  
      // Format the value
      const formattedValue = this.formatCardNumber(value.replace(/\s+/g, ''));
  
      this.setState((prevState) => ({
        cardNumber: formattedValue,
        numberError: updatedError,
      }));
    }
  };
  handleChange = () => {
    this.setState((prevState) => ({
      selected: !prevState.selected,
    }));
  };
  handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };

    if (!/^ /.test(value) && value.length <= 60) {
     if (value.length >= 2) {
      delete updatedError[name];
    } 
    else {
      updatedError[name] = '*Enter between 2 and 60 characters.';
    }
        this.setState({
          [name]: value,
         error: updatedError,
        } as Pick<S, keyof S>);
      }
  };

  validateYear = (value: string, expirationMonth: string) => {
    const regexExp = /^[0-9]{0,2}$/; // Regex to match 0-99 (2 digits)
    let updatedError = { ...this.state.numberError };
  
    if (value === '') {
      delete updatedError['expirationYear'];
      this.setState((prevState) => ({
        expirationYear: value,
        expirationMonth: expirationMonth,
        numberError: updatedError,
      }));
      return;
    }
  
    if (regexExp.test(value)) {
      delete updatedError['expirationYear'];
  
      const currentYear = Number(new Date().getFullYear().toString().slice(-2));
      const currentMonth = new Date().getMonth() + 1;
      const enteredYear = parseInt(value, 10);
  
      if (expirationMonth) {
        if (
          enteredYear < currentYear ||
          (enteredYear === currentYear &&
            parseInt(expirationMonth, 10) <= currentMonth)
        ) {
          updatedError['expirationYear'] = '*Select a future year.';
        }
      } else if (enteredYear < currentYear) {
        updatedError['expirationYear'] = '*Select a future year.';
      } else {
        delete updatedError['expirationYear'];
      }
      this.setState(() => ({
        expirationYear: value,
        expirationMonth: expirationMonth,
        numberError: updatedError,
      }));
    }
  };

  handleMonthFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.numberError };
  
    if (name === 'expirationMonth') {
      const regexExp = /^(0[1-9]|1[0-2]|[1-9]|0)?$/;
      let formattedValue = value;
  
      if (value.length === 1 && value >= '2' && value <= '9') {
        formattedValue = `0${value}`;
      }
  
      if (regexExp.test(formattedValue)) {
        delete updatedError[name];
        this.setState({
          expirationMonth: formattedValue,
          numberError: updatedError,
        } as Pick<S, keyof S>);
      } else {
        updatedError[name] = '*Invalid month';
        this.setState({
          numberError: updatedError,
        } as Pick<S, keyof S>);
      }
    }
  };
handleMonthFieldBlur = (event: { target: { name: any; value: any } }) => {
  const { name, value } = event.target;
  let formattedValue = '';
  const regexExp = /^(0[1-9]|1[0-2]|[1-9]|0)?$/;
  if (regexExp.test(value)) {
    formattedValue = value;
  }
  if (value.length === 1 && value === '1') {
    formattedValue = `0${value}`;
  }

  this.setState((prevState) => ({
    expirationMonth: formattedValue,
  }));
  this.validateYear(this.state.expirationYear, formattedValue);
};

handleYearFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const { expirationMonth } = this.state;
  this.validateYear(value, expirationMonth);
};

handleSecurityFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  const numberFields = ['securityCode'];
  let updatedError = { ...this.state.numberError };

  if (numberFields.includes(name)) {
    const regexExp = /^[0-9]{0,3}$/;
    if (regexExp.test(value)) {
      delete updatedError[name];
      this.setState({
        [name]: value,
        numberError: updatedError,
      }as Pick<S, keyof S>);
    } else {
      updatedError[name] = '*Enter a valid security code.';
      this.setState({
        numberError: updatedError,
      });
    }
  }
};
handleCountryChange = (newValue: any) => {
  this.setState({
    country: newValue ? newValue.code : '', 
    error: {
      ...this.state.error,
      country: '' 
    }
  });
};
  handlePostalCodeFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let updatedError = { ...this.state.error };
    const regexExp = /(?!^[ ,])^[a-zA-Z0-9\s,\-.\/#():;+]*$/; // Regex to match allowed characters

    // Use regex to check if the value contains only allowed characters
    if (regexExp.test(value)) {
      delete updatedError[name];
      // Check if the value is changing before updating state
      if ((this.state as any)[name] !== value) {
        this.setState({
          [name]: value,
          error: updatedError // Ensure you update 'error' state correctly
        } as Pick<S, keyof S>);
      }
    }
  };

  // Validate all fields before saving
  validateFields = () => {
    const {
      firstName,
      lastName,
      cardNumber,
      expirationMonth,
      expirationYear,
      addressLine1,
      country,
      error,
      numberError,
      securityCode
    } = this.state;
    const updatedError = { ...error };
    const updatedNumError = { ...numberError };
    const numberFields = ['expirationMonth', 'expirationYear', 'cardNumber',"securityCode"];

    const fields: any = {
      firstName,
      lastName,
      cardNumber,
      expirationMonth,
      expirationYear,
      addressLine1,
      country,
      securityCode
    };

    Object.keys(fields).every((key: string) => {
      if (fields[key] == '' || fields[key] == null) {
        if (numberFields.includes(key)) {
          updatedNumError[key] = '*Required field';
        } else {
          updatedError[key] = '*Required field';
        }
      }
      return true;
    });
    this.setState({
      error: updatedError,
      numberError: updatedNumError
    });

    const isAllfieldsValid = Object.values(fields).every(
      value => value != '' && value != null
    );
    if (isAllfieldsValid) {
      return true;
    } else {
      return false;
    }
  };

  handleSaveClick = () => {
    this.validateFields();
  };

  fetchCountryList = async () => {
    
    const fetchCountryList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      "token":  await storage.get('authToken') 
    };
    
    this.fetchCountryListId = fetchCountryList.messageId;
    fetchCountryList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.countryListEndPoint
    );
    fetchCountryList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchCountryList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(fetchCountryList.id, fetchCountryList);
  };



  // Customizable Area End
  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

    if (this.fetchCountryListId === apiRequestCallId) {
      const responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (responseJson) {
      this.setState({ countryList: responseJson});        
      
    } else {
      const errorMessage = _message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorMessage);
    }
    }}

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End
