import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { countries } from "countries-list";
import storage from "../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}
 interface UserData 
  {[key:string]: {[key:string]: string | {[key:string]: string | number | {[key:string]: string | number | null | {[key:string]:null}}}}}

interface Resprofile {
  [key:string]: number | string | null | boolean | {[key:string]:null}
}

  interface Responseinterface{
    data: {
        id: string;
        type: string;
        attributes: {
            profile :Resprofile;
        }
    }
}

  // Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editModalOpen: boolean;
  address:any;
  phone_number:any;
  userDetails: {
    id: number,
    email: string,
    user_name: string,
    full_phone_number: string,
    country_code: string;
    country: string;
    address: string,
    about_me: string,
    user_status: boolean;
    user_profile: {
      url: null
    },
  };
  userData1: Resprofile;
  responseMessage: boolean;
  popUpMessage: boolean;
  accountHolderName: string;
  // Customizable Area End
}
interface SS { }

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Star
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  addessReg= configJSON.addressRegs;
  phoneReg = configJSON.phoneReg;
  nameReg= configJSON.nameReg;
  displayProfileDetailsID: string="";
  updateUserProfileID: string;
  allCountries = Object.values(countries);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    // this.displayProfileDetailsID = "";
    this.updateUserProfileID = ""
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      editModalOpen: false,
      userData1:{},
      userDetails: {
        id: 0,
        email: "",
        user_name: "",
        full_phone_number: "",
        country_code: '',
        country: '',
        address: "",
        about_me: "",
        user_status: false,
        user_profile: {
          url: null
        },
        
      },
      responseMessage: false,
      address:"",
      phone_number:"",
      popUpMessage: false,
      accountHolderName:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({accountHolderName: await storage.get("accountHolderName")})
    this.getUserDetails()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ) 
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(requestCallId, responseJson);
        }
      if (this.updateUserProfileID === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
          this.setState({
            responseMessage: true,
            popUpMessage: true
          },()=>this.getUserDetails())
        } else {
          this.setState({
            responseMessage: false,
            popUpMessage: true
          })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  isValidResponse = (responseJson:Responseinterface) => {
    return (responseJson );
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson:Responseinterface ) => {
    if (apiRequestCallId === this.displayProfileDetailsID) {   
      console.log(responseJson)   
      this.getSkillApiSuccessCallBack(responseJson);
    }
  };
  getSkillApiSuccessCallBack= (responseJson: Responseinterface)=>{
    this.setState({userData1:responseJson?.data?.attributes?.profile})
  }

  handleModal = () => {
    this.setState({ editModalOpen: !this.state.editModalOpen,
    })
  }

  handleModal2 = () => {
  this.props.navigation.navigate('/')
  }
 
  handleDialog = () => {
    this.setState({
      popUpMessage: !this.state.popUpMessage
    })
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ userDetails: { ...this.state.userDetails, [event.target.name]: value }})
  }
  handleRedirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  

  preventETypeNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift"
    ];
    return (
      (allowedKeys.includes(event.key) ? false : !/^[^A-Za-z\W_]+$/.test(event.key)) &&
      event.preventDefault()
    );
  };

  
  apiCallSurvey = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : await storage.get("authToken")
    };
    this.setState({ editModalOpen: false }) 
    const requestMessageapiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessageapiCall.id, requestMessageapiCall);
    return requestMessageapiCall.messageId;
  };

  handleUpdate = async() => {
    const accountId = await storage.get("accountId")
    this.setState({ editModalOpen: false })  
    this.allCountries
      .filter(data => data.name === this.state.userDetails.country)
      .forEach(item => this.setState({userDetails:{...this.state.userDetails, country_code: item.phone}}))
    const body = {
      "account": {
        "user_name": this.state.userDetails.user_name,
        "email": this.state.userDetails.email,
        "full_phone_number": this.state.userDetails.full_phone_number,
        "country": this.state.userDetails.country,
        "country_code": this.state.userDetails.country_code,
        "about_me": this.state.userDetails.about_me,
        "address": this.state.userDetails.address,
      }
  };
  this.updateUserProfileID = await this.apiCallSurvey({
    method: configJSON.updateUserMethodeType,
    endPoint:  configJSON.apiEndPointUpdateUser + `${accountId}`,
    contentType:configJSON.validationApiContentType,
    body: body,
  });
    this.getUserDetails()
  }

  getUserDetails = async() => {
    const accountId = await storage.get("accountId")
      this.displayProfileDetailsID = await this.apiCallSurvey({
        contentType: configJSON.skillApiContentType,
        method: configJSON.httpGetMethod,
         endPoint: configJSON.apiEndPointGetUserData+`${accountId}`+configJSON.remainingEndPoint
      }
      ); 
  }
  // Customizable Area End
}
