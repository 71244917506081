// Customizable Area Start
import React from 'react';
import {
  Avatar,
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import AboutController, { Props } from './AboutController.web';
import NavBar from '../../landingpage/src/NavBar.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import ClientNavBar from '../../dashboard/src/Components/ClientNavBar.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';
import storage from 'framework/src/StorageProvider.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
  
export const getNavBar = (args:any)=>{
  switch (args.userType) {
    case 'customer':
      return <ClientNavBar />;
    case 'freelancer':
      return <FreelancerNavBar navigation={args.navigation} />;

    default:
      return <NavBar {...args} />;
  }
}

export default class About extends AboutController {
  constructor(props: Props) {
    super(props);
  }

// Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
       {getNavBar({userType:this.state.userType,...this.props})}
        <Box display={'grid'} style={{ padding: '5% 10%', gap: '1rem' }}>
          <Box display={'grid'}>
            <Typography
              style={{ fontFamily: 'Inter', fontSize: '40px', fontWeight: 600 }}
              dangerouslySetInnerHTML={{ __html: 'About Us' }}
            />
            <Typography
              style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500 }}
              dangerouslySetInnerHTML={{ __html: this.state.aboutUsRes.title }}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            style={{ paddingTop: '10px' }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <Avatar
                alt="image"
                src={this.state?.aboutUsRes?.image?.url}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                  marginRight: '1%'
                }}
              />
            </Box>
            <Box
              display={'grid'}
              style={{ marginBottom: '2%', marginLeft: '2%' }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '22px',
                  fontWeight: 600,
                  fontStyle: 'italic'
                }}
                dangerouslySetInnerHTML={{ __html: this.state.aboutUsRes.name }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: 500
                }}
                dangerouslySetInnerHTML={{
                  __html: this.state.aboutUsRes.designation
                }}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              style={{
                marginTop: '1%',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 500
              }}
              dangerouslySetInnerHTML={{
                __html: this.state.aboutUsRes.description
              }}
            />
          </Box>
          <Box>
            <Typography
              style={{ fontSize: '22px', fontWeight: 600, color: '#2275CD' }}
              dangerouslySetInnerHTML={{ __html: ' Start your journey' }}
            />
            <Box
              display={'flex'}
              marginTop={'1%'}
              style={{ width: '25%', gap: '5%' }}
            >
              {[
                { name: 'Find Talent', componentName: 'FullWidthTab',userTypeToHide: 'freelancer' },
                { name: 'Find Work', componentName: 'FullWidthTab',userTypeToHide: 'customer' }
              ].map(item => (
                (this.state.userType !== item.userTypeToHide) && (
                <Button
                  data-testid={item.name}
                  variant="contained"
                  style={{
                    borderRadius: '10px',
                    width: '200px',
                    backgroundColor: '#2275CD',
                    color: '#FFF',
                    textTransform: 'none' // Remove the default text transformation
                  }}
                  onClick={async () =>{await storage.set("searchSelect",item.name== 'Find Talent'?"IP Talent":"IP Jobs"); this.handleRedirection(this.getRedirectPath(this.state.userType))}}
                >
                  {item.name}
                </Button>
                )
              ))}
            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
 
};
// Customizable Area End
