import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography, Checkbox } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { unCheckIcon, checkIcon } from "./assets"

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "#696A6A",
        }
    },
    typography: {
        fontFamily: 'Inter,Rubik',
    }
});
// Customizable Area End
import FreelancerProfileSettingsController, {
    Props,
    configJSON
} from "./FreelancerProfileSettingsController";

export default class FreelancerProfileSettings extends FreelancerProfileSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box style={webStyles.settingHeadBox}>
                            <Typography style={webStyles.profileSetting}>{configJSON.profileSettings}</Typography>
                            <Button variant="contained" color="primary" style={webStyles.saveButton}>{configJSON.save}</Button>
                        </Box>
                        <Box style={webStyles.preferenceBox}>
                            <Typography style={webStyles.projectPreferenceText}>{configJSON.projectPreference}</Typography>
                            <Typography style={webStyles.projectType} color="secondary">{configJSON.projectTypeDetails}</Typography>
                            <Box style={webStyles.checkBoxInner}>
                                <Checkbox
                                    onChange={() => this.handleCheckboxChange('longTerm')}
                                    data-testid="long-term"
                                    value={this.state.projectType}
                                    color="primary"
                                    checked={this.state.projectType === "longTerm"}
                                    icon={<img src={unCheckIcon} alt="uncheck" />}
                                    checkedIcon={<img src={checkIcon} alt="check" />}
                                />
                                <Typography color="secondary">{configJSON.longTerm}</Typography>
                            </Box>
                            <Box style={webStyles.checkBoxInner}>
                                <Checkbox
                                    onChange={() => this.handleCheckboxChange('shortTerm')}
                                    data-testid="short-term"
                                    value={this.state.projectType}
                                    color="primary"
                                    checked={this.state.projectType === "shortTerm"}
                                    icon={<img src={unCheckIcon} alt="uncheck" />}
                                    checkedIcon={<img src={checkIcon} alt="check" />}
                                />
                                <Typography color="secondary">{configJSON.shortTerm}</Typography>
                            </Box>
                            <Box style={webStyles.checkBoxInner}>
                                <Checkbox
                                    onChange={() => this.handleCheckboxChange('bothTerm')}
                                    data-testid="long-short"
                                    value={this.state.projectType}
                                    color="primary"
                                    checked={this.state.projectType === "bothTerm"}
                                    icon={<img src={unCheckIcon} alt="uncheck" />}
                                    checkedIcon={<img src={checkIcon} alt="check" />}
                                />
                                <Typography color="secondary">{configJSON.bothTerm}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    settingHeadBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    profileSetting: {
        fontSize: '2.25rem',
        fontWeight: 100
    },
    saveButton: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: '0.9rem',
        padding: '6px 20px',
        borderRadius: '8px'
    },
    preferenceBox: {
        background: '#F0F0F0',
        borderRadius: '15px',
        padding: '20px 25px'
    },
    projectPreferenceText: {
        fontSize: '1.12rem',
        fontWeight: 100
    },
    projectType: {
        lineHeight: '19.36px',
        margin: '10px 0 15px'
    },
    checkBoxOuter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    checkBoxInner: {
        display: 'flex',
        alignItems: 'center'
    },
};
// Customizable Area End
