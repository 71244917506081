import React from 'react';
// Customizable Area Start
import {
  Box,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  TextareaAutosize,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { uploadIcon4 } from '../../blocks/dashboard/src/assets';
import FormGroup from '@material-ui/core/FormGroup';
import PatentAgentController, { Props } from './PatentAgentController.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';

export const autoCompleteSvgIcon = (
  <svg
    width="21"
    height="12"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingRight: '9px', paddingTop: '4px', cursor: 'pointer' }}
  >
    <path
      d="M11.6883 11.5325C11.5325 11.5325 20.4156 2.64935 20.4156 2.64935C21.039 2.02597 21.039 1.09091 20.4156 0.467531C19.7922 -0.155846 18.8571 -0.155846 18.2338 0.467531L10.4416 8.1039L2.64935 0.467532C2.02597 -0.155845 1.09091 -0.155845 0.467532 0.467532C0.155843 0.779221 -4.76851e-07 1.09091 -4.56415e-07 1.55844C-4.35979e-07 2.02597 0.155843 2.33766 0.467532 2.64935L9.1948 11.5325C9.97402 12.1558 11.5325 11.5325 11.6883 11.5325Z"
      fill="black"
    />
  </svg>
);

export const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>,
      '5': <Box>5</Box>,
      '6': <Box>6</Box>,
      '7': <Box>7</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#390fab' : '#F1F6FF',
          color: active || completed ? '#FFF' : '#390fab'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
export const StyledRadio = () => <Radio style={{ color: '#390fab' }} />;
export const FileUploadArea = ({
  id,
  btnContent,
  testId,
  uploadIcon,
  uploadTextColor,
  onChange,
  disabled,
  stateKeyRef,
  backgroundColor,
  border,
  width
}: any) => (
  <>
    <input
      type="file"
      id={id}
      key={id}
      name={stateKeyRef}
      style={{ display: 'none' }}
      onChange={event => onChange(event)}
      disabled={disabled}
    />
    <label htmlFor={id}>
      <Button
        variant="contained"
        data-testid={testId}
        color="default"
        style={{
          ...webStyle.UploadButton,
          backgroundColor:backgroundColor ?? '#F1F6FF',border:border??'1px dashed #808080',
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'column',
          width:width || "600px"
        }}
        component="span"
      >
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth:"400px" }}>
          <img
            src={uploadIcon || uploadIcon4}
            alt="Upload Icon"
            style={{ height: '50' }}
          />
          <Typography
            style={{
              textTransform: 'none',
              fontSize: '18px',
              fontWeight: 600,
              color: uploadTextColor || '#404040',
              overflow:"hidden",
              textOverflow:"ellipsis"
            }}
          >
            {btnContent}
          </Typography>
        </div>
      </Button>
    </label>
  </>
);
export const YesOrNoRadioButtons = () => {
  return ['Yes', 'No'].map((value: any) => (
    <FormControlLabel value={value} control={StyledRadio()} label={value} />
  ));
};
export const CountryGuidelines = [
  'USPTO',
  'Indian',
  'Chinese',
  'European Union',
  'Japanese',
  'Other'
];
// Customizable Area End
export default class PatentAgent extends PatentAgentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div style={{ display: 'inline-block' }}>
      <Popper
        open={this.state.openInfoPopper}
        anchorEl={this.state.anchorElement}
        placement={'bottom'}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: this.state.anchorElement
          }
        }}
        style={{
          width: '95%',
          borderRadius: '10px',
          background: '#FFF'
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography
                style={{
                  ...webStyle.header,
                  fontSize: '18px',
                  color: '#4C4C4C',
                  fontWeight: 400,
                  padding: '10px 20px',
                  letterSpacing: '0.27',
                  lineHeight: '35px'
                }}
              >
                {content}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );

  step1 = () => {
    const options = ['3Years', '5Years'];
    const Qualifications = ['Law', 'Engineering', 'Marketing', 'Other'];
    const TypeOfPatentAgent = ['Individual', 'Firm'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What are your qualifications?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="qualifications"
            name="qualifications"
            value={this.state.qualifications}
            onChange={this.handleChange}
            data-testid="PA1-qualifications"
          >
            {Qualifications.map((qualification: any) => (
              <FormControlLabel
                value={qualification}
                control={StyledRadio()}
                label={qualification}
              />
            ))}
          </RadioGroup>
          {!this.state.qualifications && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['qualifications']}</Typography>}

          <Typography style={webStyle.header}>
            No.of years as a patent licensing expert?
          </Typography>
          <Autocomplete
            key="Select Years"
            popupIcon={autoCompleteSvgIcon}
            options={options}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Years"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Are you an individual or a firm?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="type"
            name="type"
            value={this.state.type}
            onChange={this.handleChange}
            data-testid="PLE1-type"
          >
            {TypeOfPatentAgent.map((item: any) => (
              <FormControlLabel
                key={`${item}-type`}
                value={item}
                control={StyledRadio()}
                label={item}
              />
            ))}
          </RadioGroup>
          {!this.state.type && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['type']}</Typography>}

        </Box>
      </Box>
    );
  };
  step2 = () => {
    const country = ['India', 'America'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What is your target market?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="market"
            name="market"
            value={this.state.market}
            onChange={this.handleChange}
            data-testid="PLE2-market"
          >
            {['CountryWide', 'WorldWide'].map((item: any) => (
              <FormControlLabel
                value={item}
                control={StyledRadio()}
                label={item}
              />
            ))}
          </RadioGroup>
          {!this.state.market && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['market']}</Typography>}

          <Typography style={webStyle.header}>Country of Operation</Typography>
          <Autocomplete
            key="Select Country"
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Country"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>City of Operation</Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select City"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Which country's patent law guidelines are your familiar with?
          </Typography>
          <FormGroup
            data-testid="PA-Other"
            onChange={this.handleChangecountryLawGuidelines}
          >
            {CountryGuidelines.map((value: any) => (
              <FormControlLabel
                key={`${value}-country`}
                control={
                  <Checkbox
                    name={value}
                    style={
                      this.state.countryLawGuidelines.indexOf(value) > -1
                        ? { color: '#390fab' }
                        : { color: '#A9AEB2' }
                    }
                  />
                }
                label={value}
              />
            ))}
          </FormGroup>
          <Typography style={webStyle.header}>
            Focus area or industry of expertise as a patent licensing agent
          </Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Area"
                variant="outlined"
              />
            )}
          />
        </Box>
      </Box>
    );
  };
  step3 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        style={{ marginBottom: '450px' }}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Fees</Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400,marginLeft:"5px" }}
            >
              (Per day/per assignment)<span style={webStyle.importantField}>*</span>
            </Typography>
          </Box>
          <TextField
            data-testid="PLE3-fees"
            placeholder="Enter fees"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.fees}
            name="fees"
            onChange={this.handleFeesFieldChange}
            style={webStyle.inputField}
            error={this.state.numberError['fees']?true:false}
            helperText={this.state.numberError['fees']}
          />
        </Box>
      </Box>
    );
  };
  step4 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          {[
            {
              label: 'Have you been featured in the media?',
              value: 'media',
              linkKey: 'link1'
            },
            {
              label: 'Have you spoken at IP conferences?',
              value: 'ipConferences',
              linkKey: 'link2'
            },
            {
              label: 'Have you published any articles?',
              value: 'artcles',
              linkKey: 'link3'
            }
          ].map((item: any) => {
            const currentState: any = this.state;
            let dataKey: string = item.value;
            return (
              <>
                <Typography style={webStyle.header}>{item.label}<span style={webStyle.importantField}>*</span></Typography>
                <RadioGroup
                  style={{
                    ...webStyle.RadioGroupBox,
                    gridAutoFlow: 'column',
                    width: '20%'
                  }}
                  aria-label={dataKey}
                  name={dataKey}
                  value={currentState[dataKey]}
                  onChange={this.handleRadioChange}
                  data-testid="PLE4-media"
                >
                  {YesOrNoRadioButtons()}
                </RadioGroup>
                {!currentState[item.value] && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError[item.value]}</Typography>}

                <Box display={'flex'} alignItems={'baseline'}>
                  <Typography style={webStyle.header}>Attach Link </Typography>
                  <Typography style={{ ...webStyle.header, fontSize: '16px',marginLeft: '5px' }}>
                    (5 each){currentState[item.value] == 'Yes'&& <span style={webStyle.importantField}>*</span>}
                  </Typography>
                </Box>
                <TextField
                  data-testid={`PLE4-link-${dataKey}`}
                  placeholder="Enter link"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '60%'
                    }
                  }}
                  variant="outlined"
                  value={currentState[item.linkKey]}
                  name={item.linkKey}
                  onChange={this.handleLinkField}
                  error={this.state.error[item.linkKey]}
                  helperText={this.state.error[item.linkKey]}
                  style={webStyle.inputField}
                  disabled={currentState[item.value] === 'No'}
                />
              </>
            );
          })}
        </Box>
      </Box>
    );
  };
  step5 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>Awards<span style={webStyle.importantField}>*</span></Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="awards"
            name="awards"
            value={this.state.awards}
            onChange={this.handleRadioChange}
            data-testid="PLE5-awards"
          >
            {YesOrNoRadioButtons()}
          </RadioGroup>
          {!this.state.awards && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['awards']}</Typography>}

          {[
            {
              label: 'Government',
              value: 'government',
              stateKeyRef: 'step5GovernmentUpload'
            },
            {
              label: 'Industry',
              value: 'industry',
              stateKeyRef: 'step5IndustryUpload'
            },
            {
              label: 'Academic',
              value: 'academic',
              stateKeyRef: 'step5Academic'
            }
          ].map((item: any) => {
            const currentState: any = this.state;
            return (
              <>
                <Typography style={webStyle.header}>{item.label}{!currentState[item.value] && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError[item.value]}</Typography>}</Typography>
                <TextField
                  data-testid={`PA5-${item.label}`}
                  placeholder="Enter link"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '60%'
                    }
                  }}
                  variant="outlined"
                  value={currentState[item.value]}
                  name={item.value}
                  onChange={this.handleLinkField}
                  error={this.state.error[item.value]}
                  helperText={this.state.error[item.value]}
                  style={webStyle.inputField}
                  disabled={currentState[item.value] === 'No'}

                />
                <FileUploadArea
                  id={`fileInput-${item.label}`}
                  key={`fileInput-${item.stateKeyRef}`}
                  btnContent={
                    currentStateData[item.stateKeyRef]
                      ? currentStateData[item.stateKeyRef]?.name
                      : 'Upload certificate'
                  }
                  testId={`PatentAgent-fileInput-${item.label}`}
                  data-testid={`PatentAgent-fileInput-${item.label}`}
                  uploadIcon={uploadIcon4}
                  uploadTextColor={'#390fab'}
                  onChange={this.handleFileUpload}
                  stateKeyRef={item.stateKeyRef}
                  disabled={currentState[item.value] === 'No'}
                />
              {this.state.error[item.stateKeyRef] && <Typography style={{ color: 'red' }}>{this.state.error[item.stateKeyRef]}</Typography>}
              </>
            );
          })}
        </Box>
      </Box>
    );
  };
  step6 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginBottom={"200px"}
      >
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          flex={0.6}
          gridRowGap={'30px'}
        >
          <Typography style={webStyle.header}>
            Successful patent applications managed for companies
          </Typography>
          {this.state.successfulPatent.map((item: any, index: any) => {
            return (
              <TextField
                key={index}
                data-testid="PLE6-successfulPatent"
                placeholder={`Success ${index + 1}`}
                inputProps={{
                  style: {
                    ...webStyle.outlinedInput
                  }
                }}
                InputProps={{
                  style: {
                    borderRadius: '13px',
                    width: '60%'
                  }
                }}
                variant="outlined"
                value={item}
                name="successfulPatent"
                onChange={(event: any) =>
                  this.handleChangeSuccessfulPatent(event.target.value, index)
                }
                style={webStyle.inputField}
              />
            );
          })}
          <Button
            variant="contained"
            style={webStyle.addMoreButton}
            onClick={() => this.handleAddMorePatentCompanies()}
            data-testid="PA-Add-more"
          >
            {'+ Add more'}
          </Button>
        </Box>
      </Box>
    );
  };
  step7 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            Any other information?
          </Typography>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={10}
            placeholder="Add any other information you wish to convey here"
            style={{
              borderColor: '#979797',
              borderRadius: '10px',
              fontFamily: 'Inter',
              paddingLeft: '20px',
              paddingTop: '20px',
              height: '130px',
              width: '550px',
              marginBottom: '200px'
            }}
            value={this.state.otherInformation}
            name="otherInformation"
            onChange={this.handleOtherInfoField}
          />
        </Box>
      </Box>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      case 4:
        return <Box>{this.step5()}</Box>;
      case 5:
        return <Box>{this.step6()}</Box>;
      case 6:
        return <Box>{this.step7()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3, 4, 5, 6];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Box padding={'3%'}>
          <Box style={webStyle.outerContainer}>
          <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>

            <Box style={webStyle.root}>
              {/* stepper component starts */}
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: React.ReactNode } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
          {this.getStepComponent()}
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              variant="contained"
              color="default"
              data-testid="PA-back"
              onClick={() =>
                this.state.activeStep === 0 ? null : this.handleBack()
              }
              style={{
                border: '1px solid #390fab',
                color: '#390fab',
                width: '130px',
                height: '40px',
                flexShrink: 0,
                backgroundColor: 'white',
                borderRadius: '13px'
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              onClick={() =>
                this.state.activeStep === 6
                  ? this.handleredirection('ProfessionalAccountRegistration')
                  : this.handleNext()
              }
              data-testid="PA-next"
            >
              {this.state.activeStep === 6 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '50%',
    flex:"0.7"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  invento: {
    width:"250px",
    height:"61px",
    objectFit:"cover",
     marginTop: "25px",
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '20px',
    marginTop: '30px'
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },

  UploadButton: {
    minHeight: '130px',
    width: '800px',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#F1F6FF',
    border: '1px dashed #390fab',
    borderRadius: '12px'
  },
  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '130px',
    height: '40px',
    flexShrink: 0,
    borderRadius: '13px',
    color: '#FFF',
    backgroundColor: '#390fab'
  },
  addMoreButton: {
    flexShrink: 0,
    borderRadius: '13px',
    color: '#390fab',
    backgroundColor: 'lightgrey',
    border: '1px solid #390fab',
    justifyContent: 'flex-start',
    width: '60%'
  },
  importantField:{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FF5E5B"
  }
};
// Customizable Area End
