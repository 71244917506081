// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import FAQController, { Props } from './FAQController.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Pagination from '@material-ui/lab/Pagination';
import { faq1 } from './assets';
import { getNavBar } from './About.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export default class FAQ extends FAQController {
  constructor(props: Props) {
    super(props);
  }
 
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
      {getNavBar({userType:this.state.userType,...this.props})}
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          mt={'5rem'}
          justifyContent={'space-around'}
          margin={'0px 3rem'}
        >
          <Box flex={0.3} marginTop={'8%'}>
            <img src={faq1} />
          </Box>
          <Box
            display={'flex'}
            flex={0.6}
            flexDirection={'column'}
            gridColumnGap={'10px'}
            justifyContent={'center'}
          >
            <Typography
              style={{
                fontSize: '40px',
                fontWeight: 600,
                marginLeft: '10%',
                marginBottom: '5%',
                fontFamily: 'Inter'
              }}
            >
              Frequently Asked Questions
            </Typography>
            <Box display={'flex'} flexDirection={'column'}>
              <Box
                display={'grid'}
                gridTemplateColumns={'1fr 0.2fr'}
                gridAutoFlow={'column'}
                gridColumnGap={'1.5rem'}
              >
                <TextField
                  data-testid="search"
                  placeholder="Search"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput,
                      width: '100%'
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '100%',
                      flex: 1
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleChange}
                />
                <Button
                  data-testid="searchButton"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: '13px',
                    width: '200px',
                    backgroundColor: '#2275CD',
                    color: '#FFF',
                    textTransform: 'none' // Remove the default text transformation
                  }}
                  onClick={() => {
                    this.searchFAQlist();this.setState({ currentPage: 1 });
                  }}
                >
                  Search
                </Button>
              </Box>

              <Box mt={'2rem'}>
                {this.state.isNoResults &&( <Typography dangerouslySetInnerHTML={{ __html:this.state.message}}/>)}
                {this.state?.faqListRes.map(item => (
                  <div style={{ marginBottom: '1rem' }}>
                    <div
                      onClick={() => this.handleToggle(item.id)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease'
                      }}
                    >
                      <FiberManualRecordIcon
                        style={{
                          color: '#2275CD',
                          width: '15px',
                          marginRight: '10px'
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: 600,
                          fontFamily: 'Inter'
                        }}
                      >
                        {item.attributes.question}
                      </Typography>
                      <ExpandMoreIcon
                        style={{
                          marginLeft: 'auto',
                          transform:
                            this.state.expanded == item.id
                              ? 'rotate(360deg)'
                              : 'rotate(270deg)',
                          transition: 'transform 0.3s ease'
                        }}
                      />
                    </div>
                    <div
                      style={{
                        maxHeight:
                          this.state.expanded == item.id ? 'fit-content' : '0',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: 400,
                          fontFamily: 'Inter',
                          marginLeft: '30px',
                          width: '75%',
                          marginTop: '10px'
                        }}
                      >
                        {item.attributes.answer}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'end'} marginTop={'15px'}>
            {!this.state.isNoResults && 
              <Pagination
                count={this.state.totalPages}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
              />
            }
            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
 // Customizable Area End

// Customizable Area Start
const webStyle = {
  text: {
    color: '#535353',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px'
  },
  heading: {
    color: '#0A0A0A',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  shodowWrapper: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '4px'
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  accordionRoot: {
    border: 'none',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent !important' // Ensures the before pseudo-element is also transparent
    },
    backgroundColor: 'white !important'
  }
};
// Customizable Area End
