import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Link, TextField, Typography, Checkbox, MenuItem } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { green } from '@material-ui/core/colors';
import { avtara, online, checkImage, clientProfile } from './assets';
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";
import DatePicker from "react-datepicker";


const theme = createTheme({
    palette: {
        primary: {
            main: "#2378D1",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Rubik, Roboto',
    }
});
// Customizable Area End
import ViewFreelancerProfileController, {
    Props,
    configJSON
} from "./ViewFreelancerProfileController";

export default class ViewFreelancerProfile extends ViewFreelancerProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <>
                 <ClientNavBar />
                <ThemeProvider theme={theme}>
                    <Box style={webScreenStyles.boxConatiner}>
                        <Typography style={webScreenStyles.dashboardText}>{configJSON.yourDashboard}</Typography>
                        <Typography style={webScreenStyles.userNameText}>{this.state.userName}</Typography>
                        <Grid container justifyContent="space-evenly">
                            <Grid item xs={12} sm={12} md={7}>
                                <Box style={webScreenStyles.borderBox}>
                                    <Typography style={webScreenStyles.expertText}>{configJSON.expert}</Typography>
                                    <Box>
                                        {this.state.showMore ?
                                            <Typography style={webScreenStyles.detailText} color="secondary" data-testid="whole-text">
                                                {this.state.experienceDetails}<span>
                                                    <Link href="#" variant="body2" data-testid="readLessLink" style={webScreenStyles.linkText} onClick={this.handleShowText}>
                                                        {configJSON.readLess}
                                                    </Link>
                                                </span></Typography>
                                            :
                                            <Typography style={webScreenStyles.detailText} color="secondary" data-testid="hidden-text">
                                                {this.state.experienceDetails.slice(0, 300)}...<span>
                                                    <Link href="#" variant="body2" data-testid="readMoreLink" style={webScreenStyles.linkText} onClick={this.handleShowText}>
                                                        {configJSON.readMore}
                                                    </Link>
                                                </span></Typography>
                                        }
                                    </Box>
                                    <Box style={webScreenStyles.buttonDiv}>
                                        <Button variant="contained" color="primary" style={webScreenStyles.hireBtn}>
                                            {configJSON.hire}
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Typography style={webScreenStyles.feedbackText}>{configJSON.clientsFeedback}</Typography>
                                        <Grid container justifyContent="space-between">
                                            {this.state.feedBack.map((item, index) =>
                                                <Grid item xs={12} sm={3} md={3} style={webScreenStyles.feedbackBox} key={index}>
                                                    <Box>
                                                        <hr style={{ ...webScreenStyles.hr, background: item.background, }} />
                                                        <Typography style={webScreenStyles.feedBackDetail} color="secondary">{item.details}</Typography>
                                                        <Box style={webScreenStyles.profileBox}>
                                                            <img src={clientProfile} style={webScreenStyles.avtara} alt='avtara' />
                                                            <Box>
                                                                <Typography style={webScreenStyles.nameText}>{item.userName}</Typography>
                                                                <Typography style={webScreenStyles.roleText} color="secondary">{item.role}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography style={webScreenStyles.skillText}>{configJSON.skills}</Typography>
                                        <Box style={webScreenStyles.skillBox}>
                                            {this.state.skillArray.map((skill, index) =>
                                                <Typography style={webScreenStyles.skill} key={index}>{skill}</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box style={webScreenStyles.proposalBox}>
                                            <Typography style={webScreenStyles.proposalText}>{configJSON.proposal}</Typography>
                                            <Box>
                                                <Typography style={webScreenStyles.proposalText}>{this.state.proposalHours}</Typography>
                                                <Typography style={webScreenStyles.proposed} color="secondary">{configJSON.proposed}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography color="secondary" style={{ ...webScreenStyles.greeting, ...webScreenStyles.declrationText }}>{configJSON.hi}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{configJSON.just}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{configJSON.eyeCatch}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{configJSON.fullStack}</Typography>
                                        <Typography color="secondary" style={{ ...webScreenStyles.declrationText, ...webScreenStyles.greeting }}>{configJSON.expertise}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}> - {this.state.frontendTechnology}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}> - {this.state.backendTechnology}</Typography>
                                        <Typography color="secondary" style={{ ...webScreenStyles.declrationText, ...webScreenStyles.greeting }}> - {this.state.landingPage}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{this.state.fullTimeWork}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{this.state.quality}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{this.state.messageReply}</Typography>
                                        <Typography color="secondary" style={{ ...webScreenStyles.pastWork, ...webScreenStyles.declrationText }}>{configJSON.pastWork}</Typography>
                                        <Box>
                                            <Link style={webScreenStyles.declrationText} href="https://www.google.com">{configJSON.googleLink}</Link>
                                        </Box>
                                        <Box>
                                            <Link style={webScreenStyles.declrationText} href="https://www.google.com">{configJSON.googleLink}</Link>
                                        </Box>
                                        <Box>
                                            <Link style={webScreenStyles.declrationText} href="https://www.google.com">{configJSON.googleLink}</Link>
                                        </Box>
                                        <Typography color="secondary" style={{ ...webScreenStyles.regard, ...webScreenStyles.declrationText }}>{configJSON.regard}</Typography>
                                        <Typography color="secondary" style={webScreenStyles.declrationText}>{configJSON.john}</Typography>
                                    </Box>
                                </Box>
                                <Box style={webScreenStyles.borderBoxTwo}>
                                    <Typography style={webScreenStyles.contract}>{configJSON.contract}</Typography>
                                    <Grid container style={webScreenStyles.paymentOuterBox}>
                                        <Grid item xs={12} sm={5}>
                                            <Box
                                                style={this.state.paymentType === "hours" ? webScreenStyles.selectedPayBox : webScreenStyles.payBox}
                                                onClick={() => this.handleSelectPaymentType("hours")}
                                                data-testid="payByHours"
                                            >
                                                {this.state.paymentType === "hours" &&
                                                    <img data-testid="hours-selected" src={checkImage} alt="checkIcon" style={webScreenStyles.checkIcon} />
                                                }
                                                <Typography style={webScreenStyles.payBy}>{configJSON.payByHours}</Typography>
                                                <Typography style={webScreenStyles.popular}>{configJSON.popular}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <Box data-testid="payBymilestone" style={this.state.paymentType === "milestone" ? webScreenStyles.selectedPayBox : webScreenStyles.payBox} onClick={() => this.handleSelectPaymentType("milestone")}>
                                                {this.state.paymentType === "milestone" &&
                                                    <img data-testid="milestone-selected" src={checkImage} alt="checkIcon" style={webScreenStyles.checkIcon} />
                                                }
                                                <Typography style={webScreenStyles.payBy}>{configJSON.payBy}</Typography>
                                                <Typography style={webScreenStyles.popular}>{configJSON.popularMilestone}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box style={webScreenStyles.formInput}>
                                        <Grid container style={webScreenStyles.fieldsCOntainer}>
                                            <Grid item xs={12} sm={5}>
                                                <Box>
                                                    <Typography style={webScreenStyles.lable}>{configJSON.milestoneNumber}</Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        id="mile"
                                                        select
                                                        name="numberOfMileStone"
                                                        size="small"
                                                        data-testid="noMilestone"
                                                        fullWidth
                                                        value={this.state.numberOfMileStone}
                                                    >
                                                        {
                                                            [...Array(10)].map((_, i) => i + 1)
                                                                        .map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)
                                                        }
                                                    </TextField>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={5}></Grid>
                                        </Grid>
                                        <Box>
                                            {
                                                this.state.milestoneList.map((data, index) =>
                                                    <Grid container key={index} style={webScreenStyles.fieldsCOntainer} justifyContent="space-between">
                                                        <Grid item xs={12} sm={5}>
                                                            <Box>
                                                                <Typography style={webScreenStyles.lable}>{data.sNo} {configJSON.milestone}</Typography>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id="milestone"
                                                                    name="milestone"
                                                                    size="small"
                                                                    data-testid="milestone"
                                                                    value={data.milestone}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <Box>
                                                                <Typography style={webScreenStyles.lable}>{configJSON.noOfWeeks}</Typography>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id="weeks"
                                                                    name="weeks"
                                                                    size="small"
                                                                    data-testid="weeks"
                                                                    value={data.noOfWeeks}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Box>
                                        <Grid container>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={webScreenStyles.lable}>{configJSON.startDate}</Typography>
                                               <DatePicker 
                                                dateFormat="yyyy-MM-dd" 
                                                selected={this.state.startDate} 
                                                onChange={(date) => this.setState({startDate : date})} 
                                                placeholderText="yyyy-mm-dd"
                                                data-testid="startDate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box style={webScreenStyles.agreeBox}>
                                    <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleChecked}
                                        style={webScreenStyles.checkInput}
                                        color="primary"
                                        data-testid="checked"
                                    />
                                    <Typography style={webScreenStyles.agreeText} color="secondary">{configJSON.agreeTerm}</Typography>
                                </Box>
                                <Box style={webScreenStyles.buttonBox}>
                                    <Button variant="outlined" color="primary" style={webScreenStyles.cancelBtn}>{configJSON.cancle}</Button>
                                    <Button variant="contained" color="primary" style={webScreenStyles.nextBtn} data-testid="nextBtn" disabled={!this.state.checked} onClick={this.handleNext}>{configJSON.next}</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} style={{ ...webScreenStyles.borderBox, height: 'max-content' }}>
                                <Box>
                                    <Box style={webScreenStyles.profileDisplay}>
                                        <Box style={webScreenStyles.profileStatus}>
                                            <img src={avtara} alt="profile" />
                                            <img src={online} alt="online" style={webScreenStyles.status} />
                                        </Box>
                                        <Box>
                                            <Typography style={webScreenStyles.nameStyle}>{configJSON.john}</Typography>
                                            <Typography style={webScreenStyles.website}>{configJSON.website}</Typography>
                                            <Box style={webScreenStyles.locationBox}>
                                                <LocationOnIcon style={webScreenStyles.locationIcon} fontSize="small" />
                                                <Typography style={webScreenStyles.website}>{configJSON.lativa}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={webScreenStyles.messageContainer}>
                                        <Button variant="contained" color="primary" size="large" style={webScreenStyles.buttonSize}>{configJSON.messageMe}</Button>
                                    </Box>
                                    <Box style={webScreenStyles.savedContainer}>
                                        <Button variant="outlined" color="primary" size="large" style={webScreenStyles.buttonSize}>{configJSON.savedTalent}</Button>
                                    </Box>
                                    <Typography style={webScreenStyles.education}>{configJSON.education}</Typography>
                                    <Typography color="secondary" style={webScreenStyles.educationDetail}>{configJSON.university}</Typography>
                                    <Typography color="secondary" style={webScreenStyles.educationDetail}>{configJSON.degree}</Typography>
                                    <Typography color="secondary" style={webScreenStyles.educationDetail}>{configJSON.year}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </ThemeProvider>
                <FooterWeb />
            </>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webScreenStyles = {

    dashboardText: {
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '0.5%',
        paddingLeft: '3%',
    },
    userNameText: {
        marginBottom: '1%',
        paddingLeft: '3%',
        fontWeight: 400
    },
    boxConatiner: {
        padding: '30px 0'
    },
    borderBox: {
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
        padding: '2%'
    },
    borderBoxTwo: {
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
        padding: '2%',
        marginTop: '3%'
    },
    expertText: {
        fontSize: '1.2rem',
        marginBottom: '0.5%',
        fontWeight: 500
    },
    detailText: {
        fontSize: '0.9rem',
        marginBottom: '3%',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    buttonDiv: {
        textAlign: 'end' as 'end',
        marginBottom: '4%'
    },
    hireBtn: {
        width: 'max-content',
        padding: '1% 10%',
        borderRadius: '7px',
        fontFamily: 'Rubik',
    },
    feedbackText: {
        fontSize: '1.25rem',
        fontWeight:500
    },
    feedbackBox: {
        border: '1px solid #E0E0E0',
        borderRadius: '10px',
        position: 'relative' as 'relative',
        padding: '1%',
        background: '#F7F9FA',
        margin: '4% 0'
    },
    hr: {
        width: '50%',
        height: '5px',
        border: 'none',
        borderRadius: '5px',
        position: 'absolute' as 'absolute',
        top: '-8px',
        right: '25%'
    },
    feedBackDetail: {
        fontSize: '0.8rem',
        padding: '15% 0',
        fontFamily: 'Roboto'
    },
    profileBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    nameText: {
        fontSize: '1rem',
        fontFamily: 'Roboto'
    },
    roleText: {
        fontSize: '0.8rem',
        fontFamily: 'Roboto'
    },
    avtara: {
        width: '20%',
        height: '20%'
    },
    skillText: {
        fontSize: '1.3rem',
        fontWeight: 500
    },
    skillBox: {
        display: 'flex',
        gap: '2%',
        margin: '1% 0 5%',
        flexWrap: 'wrap' as 'wrap',
        alignItems: 'center'
    },
    skill: {
        color: '#fff',
        padding: '5px 15px',
        borderRadius: '5px',
        background: '#166489',
        marginTop: '2%',
        fontSize: '0.9rem'
    },
    proposalBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    proposalText: {
        fontSize: '1.3rem',
        fontWeight: 500
    },
    proposed: {
        textAlign: 'end' as 'end',
        marginTop: '2%',
        fontSize: '0.9rem',
        fontWeight: 300
    },
    greeting: {
        marginBottom: '4%'
    },
    pastWork: {
        margin: '4% 0'
    },
    regard: {
        marginTop: '4%'
    },
    locationIcon: {
        color: green[500]
    },
    profileDisplay: {
        display: 'flex',
        gap: '4%'
    },
    profileStatus: {
        position: 'relative' as 'relative'
    },
    status: {
        position: 'absolute' as 'absolute',
        top: '88%',
        right: '12%'
    },
    nameStyle: {
        fontSize: '1.5rem',
        marginBottom: '3%',
        fontWeight: 500
    },
    website: {
        fontSize: '0.8rem',
        fontFamily: 'Roboto'
    },
    locationBox: {
        display: 'flex'
    },
    messageContainer: {
        textAlign: 'center' as 'center',
        margin: '11% 0 5%'
    },
    savedContainer: {
        textAlign: 'center' as 'center'
    },
    buttonSize: {
        width: '100%',
        fontFamily: 'Rubik',
        fontWeight: 500
    },
    education: {
        margin: '7% 0 3%',
        fontWeight: 500
    },
    educationDetail: {
        fontSize: '0.9rem',
        fontFamily: 'Roboto'
    },
    payBox: {
        background: '#fff',
        borderRadius: '5px',
        color: '#000',
        marginTop: '3%',
        padding: '2% 3%',
        border: '1px solid #CCCCCC',
        cursor: 'pointer'
    },
    selectedPayBox: {
        position: 'relative' as 'relative',
        background: '#253342',
        borderRadius: '5px',
        color: '#fff',
        marginTop: '3%',
        padding: '2% 3%',
        cursor: 'pointer'
    },
    checkIcon: {
        position: 'absolute' as 'absolute',
        right: '5%',
        width: '15px',
        height: '15px'
    },
    payBy: {
        fontSize: '1.2rem',
        fontWeight: 500
    },
    popular: {
        fontSize: '0.9rem',
        marginTop: '8%',
        fontWeight: 500
    },
    contract: {
        fontSize: '1.3rem',
        fontWeight: 500
    },
    formInput: {
        margin: '3% 0'
    },
    lable: {
        marginBottom: '2%',
        fontSize: '1.1rem',
        fontWeight: 500
    },
    agreeBox: {
        display: 'flex',
        alignItems: 'start'
    },
    checkInput: {
        color: green[400]
    },
    agreeText: {
        fontSize: '0.8rem',
        marginTop: '1.4%'
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'end',
        gap: '2%',
        marginTop: '3%'
    },
    nextBtn: {
        borderRadius: '8px',
        padding: '1% 6%'
    },
    cancelBtn: {
        borderRadius: '8px',
        padding: '1% 4%'
    },
    linkText: {
        textDecoration: 'underline',
        fontFamily: 'Rubik'
    },
    declrationText: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1rem'
    },
    paymentOuterBox: {
        gap: '10px'
    },
    fieldsCOntainer: {
        marginBottom: '3%'
    },
    startDateField:{
        width:'83%'
    }
};
// Customizable Area End
