import React from "react";
// Customizable Area Start
import { Box, Button, Grid, InputAdornment, Modal, TextField, Typography, MenuItem, IconButton } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { green } from '@material-ui/core/colors';
import { avtara, online } from './assets';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";



const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import JobPostDescriptionController, {
    Props,
    webConfigJSON
} from "./JobPostDescriptionController";

export default class JobPostDescription extends JobPostDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderModal = () => {
        return (
            <ThemeProvider theme={theme}>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={webStyles.modalContainer}
                >
                    <Box style={webStyles.modalView}>
                        <Typography style={webStyles.confirmMsg} variant="h4">{webConfigJSON.confirmationMsg}</Typography>
                        <Typography style={webStyles.paymentDetails}>{webConfigJSON.paymentTransfer}</Typography>
                        <Box style={webStyles.buttonBox}>
                            <Button variant="text" color="primary" onClick={this.handleClose} data-testid="cancel">{webConfigJSON.cancle}</Button>
                            <Button variant="contained" color="primary" style={webStyles.confirmBtn} onClick={this.handleClose}>{webConfigJSON.confirm}</Button>
                        </Box>
                    </Box>
                </Modal>
            </ThemeProvider>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <ClientNavBar/>
                <Grid container style={webStyles.outerContainer}>
                    <Grid item xs={12} sm={8} md={8}>
                        <Grid container>
                            <Grid item xs={12} md={12} sm={12}>
                                <Typography style={webStyles.expertText}>{this.state.postDescription}</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} style={webStyles.descriptionBox}>
                                <Box>
                                    <Typography style={webStyles.description}>{webConfigJSON.projectDescription}</Typography>
                                    <Typography style={webStyles.detailText} color="secondary">{this.state.projectDetails}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} style={webStyles.displayBox}>
                                <Box>
                                    <Grid container style={webStyles.milestoneField}>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <Box>
                                                <Typography style={webStyles.lable}>{webConfigJSON.totalMileStone}<span style={webStyles.required}>*</span></Typography>
                                                <TextField variant="outlined"  name="numberOfMileStone" data-testid="noMilestone" 
                                                value={this.state.numberOfMileStone} onChange={this.numberOfMileStoneChange} placeholder="4" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <Box>
                                                <Typography style={webStyles.lable}>{webConfigJSON.completedMilestone}<span style={webStyles.required}>*</span></Typography>
                                                <TextField variant="outlined" id="completedMile" name="completedMileStone" data-testid="completedMilestone" value={this.state.completedMileStone} 
                                                onChange={this.completedMileStoneChange} placeholder="0"/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <Box>
                                                <Typography style={webStyles.lable}>{webConfigJSON.inCompletedMilestone}<span style={webStyles.required}>*</span></Typography>
                                                <TextField variant="outlined" id="inCompleteMile" name="inCompleteMile" data-testid="inCompleteMile" value={this.state.inCompletedMileStone} 
                                                onChange={this.inCompletedMileStoneChange}
                                                placeholder="4"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography style={webStyles.lable}>{webConfigJSON.startDate}<span style={webStyles.required}>*</span></Typography>
                                        <TextField variant="outlined" id="date" type="date"  name="startDate" data-testid="startDate" value={this.state.startDate} 
                                        onChange={this.handleStartDate}/>
                                    </Box>
                                    <Box>
                                        {this.state.inputList.map((count, index) => {
                                            return (
                                                <Grid container style={webStyles.inputContainer} key={count.id}>
                                                    <Grid item xs={12} sm={12} md={3}>
                                                        <Box style={webStyles.countBox}>
                                                            <Typography color="secondary">{index + 1}</Typography>
                                                            <Box>
                                                                <Typography style={webStyles.lable}>{webConfigJSON.mileDescription}<span style={webStyles.required}>*</span></Typography>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id="mileDescription"
                                                                    data-testid={"mileDescription-" + count.id}
                                                                    name="mileDescription"
                                                                    placeholder="description"
                                                                    disabled = {this.state.inputFieldId !== count.id}
                                                                    value={count.mileDescription}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput(event, count.id)}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2}>
                                                        <Box>
                                                            <Typography style={webStyles.lable}>{webConfigJSON.amount}<span style={webStyles.required}>*</span></Typography>
                                                            <TextField
                                                                variant="outlined"
                                                                id="amount"
                                                                data-testid={"amount-" + count.id}
                                                                name="amount"
                                                                placeholder="amount"
                                                                disabled = {this.state.inputFieldId !== count.id}
                                                                value={count.amount}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput(event, count.id)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3}>
                                                        <Box>
                                                            <Typography style={webStyles.lable}>{webConfigJSON.date}<span style={webStyles.required}>*</span></Typography>
                                                            <TextField
                                                                variant="outlined"
                                                                id="date"
                                                                data-testid={"dateInput-" + count.id}
                                                                type="date"
                                                                name="date"
                                                                value={count.date}
                                                                fullWidth
                                                                disabled = {this.state.inputFieldId !== count.id}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput(event, count.id)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2}>
                                                        <Box>
                                                            <Typography style={webStyles.lable}>{webConfigJSON.status}<span style={webStyles.required}>*</span></Typography>
                                                            <TextField
                                                                select
                                                                variant="outlined"
                                                                value={count.status}
                                                                id="status"
                                                                data-testid={"status-" + count.id}
                                                                name="status"
                                                                disabled = {this.state.inputFieldId !== count.id}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput(event, count.id)}
                                                                style={webStyles.statusField}
                                                            >
                                                                <MenuItem value="completed" onClick={this.handleOpen} data-testid={"pending-" + count.id}>{webConfigJSON.completed}</MenuItem>
                                                                <MenuItem value="ongoing">{webConfigJSON.ongoing}</MenuItem>
                                                                <MenuItem value="pending" >{webConfigJSON.pending}</MenuItem>
                                                            </TextField>

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={1} style={webStyles.iconContainer}>
                                                        <IconButton aria-label="edit" onClick={() => this.handleEdit(count.id)} data-testid={"edit-" + count.id}>
                                                            <EditSharpIcon fontSize="small" style={webStyles.editIcon} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ ...webStyles.displayBox, height: 'max-content' }}>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box style={webStyles.displayProfile}>
                                        <Box style={webStyles.statusProfile}>
                                            <img src={avtara} alt="profile" />
                                            <img src={online} alt="online" style={webStyles.activeStatus} />
                                        </Box>
                                        <Box>
                                            <Typography style={webStyles.nameText}>{webConfigJSON.john}</Typography>
                                            <Typography style={webStyles.role}>{webConfigJSON.website}</Typography>
                                            <Box style={webStyles.location}>
                                                <LocationOnIcon style={webStyles.locationImg} />
                                                <Typography style={webStyles.role}>{webConfigJSON.lativa}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={webStyles.containerMsg}>
                                    <Button variant="contained" color="primary" size="large" style={webStyles.sizeButton}>{webConfigJSON.messageMe}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <FooterWeb/>
                {this.renderModal()}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    outerContainer: {
        justifyContent: 'space-evenly',
        padding: '30px 0'
    },
    descriptionBox: {
        background: '#F5F7F9',
        padding: '15px',
        borderRadius: '5px',
        margin: '3% 0 2%'
    },
    displayBox: {
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
        padding: '2%'
    },
    expertText: {
        fontSize: '1.21rem',
        marginBottom: '0.5%'
    },
    detailText: {
        fontSize: '0.9rem',
        marginBottom: '2%',
    },
    description: {
        'font-weight': '600',
        marginBottom: '1%'
    },
    avtara: {
        width: '20%',
        height: '20%'
    },
    locationImg: {
        color: green[500]
    },
    displayProfile: {
        display: 'flex',
        gap: '4%'
    },
    statusProfile: {
        position: 'relative' as 'relative'
    },
    activeStatus: {
        position: 'absolute' as 'absolute',
        top: '88%',
        right: '12%'
    },
    nameText: {
        fontSize: '1.5rem',
        marginBottom: '3%'
    },
    role: {
        fontSize: '0.8rem'
    },
    location: {
        display: 'flex'
    },
    containerMsg: {
        textAlign: 'center' as 'center',
        margin: '11% 0 5%'
    },
    sizeButton: {
        width: '100%'
    },
    required: {
        color: 'red'
    },
    lable: {
        marginBottom: '1%'
    },
    inputContainer: {
        margin: '2% 0 1%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    countBox: {
        display: 'flex',
        alignItems: 'center' as 'center',
        gap: '5px'
    },
    milestoneField: {
        gap: '20px',
        marginBottom: '2%'
    },
    statusField: {
        width: '100%'
    },
    editIcon: {
        background: '#2378D1',
        borderRadius: '50%',
        color: '#fff',
        padding: '10px',
        position: 'absolute' as 'absolute',
        cursor: 'pointer',
        top: '2px'
    },
    iconContainer: {
        position: 'relative' as 'relative'
    },
    modalContainer: {
        width: '100px',
        height: '100px',
        margin: 'auto',
        background: '#fff'
    },
    modalView: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        height: 220,
        background: "#fff",
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '5px 10px 9px #4f4e4e'
    },
    confirmMsg: {
        fontSize: '1.1rem',
        'font-weight': '600',
        lineHeight: '1.8'
    },
    paymentDetails: {
        fontSize: '0.9rem',
        margin: '2% 0 15%',
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'end',
        gap: '4%'
    },
    confirmBtn: {
        padding: '1% 8%',
        borderRadius: '10px'
    }
};
// Customizable Area End
