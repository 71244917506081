import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { countries } from "countries-list";
import storage from "../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}
 interface UserData 
  {[key:string]: {[key:string]: string | {[key:string]: string | number | {[key:string]: string | number | null | {[key:string]:null}}}}}

interface Resprofile {
  [key:string]: number | string | null | boolean | {[key:string]:null}
}

  interface Responseinterface{
    data: {
        id: string;
        type: string;
        attributes: {
            profile :Resprofile;
        }
    }
}

  // Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editModalOpen: boolean;
  address:any;
  phone_number:any;
  userDetails: {
    id: string,
    first_name:string,
    email: string,
    address: string,
    country_code: string,
   full_phone_number: string,
   about_me: string,
   profile_image: string,
  };
  userData1: object;
  responseMessage: boolean;
  popUpMessage: boolean;
  accountHolderName: string;
  // Customizable Area End
}
interface SS { }

export default class EditClientProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Star
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  addessReg= configJSON.addressRegs;
  phoneReg = configJSON.phoneReg;
  nameReg= configJSON.nameReg;
  displayProfileDetailsID: string="";
  updateUserProfileID: string;
  allCountries = Object.values(countries);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    // this.displayProfileDetailsID = "";
    this.updateUserProfileID = ""
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      editModalOpen: false,
      userData1:{},
      userDetails: {
         id: "",
         first_name:"",
         email: "",
         address: "",
         country_code: "",
        full_phone_number: "",
        about_me: "",
        profile_image: "",
      },
      responseMessage: false,
      address:"",
      phone_number:"",
      popUpMessage: false,
      accountHolderName:'',
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({accountHolderName: await storage.get("accountHolderName")})
    this.getUserDetails()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ) 
      if(this.displayProfileDetailsID === requestCallId){
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson){         
          this.setState({userDetails: responseJson.data.attributes});
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    
    
      
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ userDetails: { ...this.state.userDetails, [event.target.name]: value }})
  }
  preventETypeNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift"
    ];
    return (
      (allowedKeys.includes(event.key) ? false : !/^[^A-Za-z\W_]+$/.test(event.key)) &&
      event.preventDefault()
    );
  };
  isValidResponse = (responseJson:Responseinterface) => {
    return (responseJson );
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson:Responseinterface ) => {
    if (apiRequestCallId === this.displayProfileDetailsID) {   
      this.getSkillApiSuccessCallBack(responseJson);
    }
  };
  getSkillApiSuccessCallBack= (responseJson: Responseinterface)=>{
    this.setState({userData1:responseJson?.data?.attributes?.profile})
  }

  apiCallSurvey = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : await storage.get("authToken")
    };
    this.setState({ editModalOpen: false }) 
    const requestMessageapiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessageapiCall.id, requestMessageapiCall);
    return requestMessageapiCall.messageId;
  };

  handleUpdate = async() => {
    const accountId = await storage.get("accountId")
    this.setState({ editModalOpen: false })  
    this.allCountries
      .filter(data => data.name === this.state.userDetails.country_code)
      .forEach(item => this.setState({userDetails:{...this.state.userDetails, country_code: item.phone}}))
    const body = {
      "account": {
        "id":accountId,
        "first_name": this.state.userDetails.first_name,
        "email": this.state.userDetails.email,
        "address": this.state.userDetails.address,
        "country_code": this.state.userDetails.country_code,
        "full_phone_number": this.state.userDetails.full_phone_number,
        "about_me": this.state.userDetails.about_me,
        "profile_image":this.state.userDetails.profile_image
      }
  };
  this.updateUserProfileID = await this.apiCallSurvey({
    method: configJSON.updateUserMethodeType,
    endPoint:  configJSON.apiEndPointUpdateUser + `${accountId}/update_client_profile`,
    contentType:configJSON.validationApiContentType,
    body: body,
  });
    this.getUserDetails()
  }

  getUserDetails = async() => {
    const accountId = await storage.get("accountId")
      this.displayProfileDetailsID = await this.apiCallSurvey({
        contentType: configJSON.skillApiContentType,
        method: configJSON.httpGetMethod,
         endPoint: configJSON.apiEndPointGetUserData+`${accountId}`
      }
      ); 
  }
  // Customizable Area End
}
