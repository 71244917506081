import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "framework/src/StorageProvider";
import { ActiveProposalData, InvitationToInterviewData, SubmittedProposalsData } from "./FreelancerOffersController.web";
// Customizable Area Start

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  proposalType?:number|string
  activeProposalData:ActiveProposalData| null
  submittedProposalsData:SubmittedProposalsData|null
  invitationToInterviewData:InvitationToInterviewData|null
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  search: string;
  authToken:string;
  proposalType:number|string
  activeProposalData:ActiveProposalData| null
  submittedProposalsData:SubmittedProposalsData|null
  invitationToInterviewData:InvitationToInterviewData|null
  selectedTab:number|string;
  searchSelect:number
  // Customizable Area End
}
interface SS { }

export default class FullWidthJobsTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      anchorEl: null,
      search: '',
      authToken:"",
      activeProposalData: null,
      submittedProposalsData:null,
      invitationToInterviewData:null,
      proposalType:0,
      selectedTab:0,
      searchSelect:0
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    if(this.props.navigation?.history?.location?.state?.tab){
      this.setState({selectedTab:this.props.navigation?.history?.location?.state?.tab})
    }
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }
  // Customizable Area Start

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  handleChange = (event:any, newValue: any) => {
    this.setState({ searchSelect: newValue });
  };
  // Customizable Area End
}
