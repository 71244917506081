import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Props } from './ViewJobController.web';
// Customizable Area Start
import Button from '@material-ui/core/Button';
import BillsAndPaymentController from './BillsAndPaymentController.web';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  Box,
  Grid,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import AddPaymentDetails from './AddPaymentDetails.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
export default class BillsAndPayment extends BillsAndPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Grid container>
          <Box sx={webStyle.tableWrapper}>
            <Typography style={webStyle.title}> Bills & Payments</Typography>
            <Box>
              <AddPaymentDetails {...this.props} />
            </Box>
            <InputBase
              value={this.state.searchValue}
              data-testid="transaction-search"
              inputProps={{ 'aria-label': 'naked' }}
              placeholder="Seach Transaction"
              onChange={e => this.handleChangeSearch(e.target.value)}
              style={webStyle.searcBox}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#206FC4' }} />
                </InputAdornment>
              }
            />
           
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead style={webStyle.header}>
                  <TableRow>
                    {this.state.columns.map((column: any) => (
                      <TableCell style={webStyle.headerText} align="center">
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows?.map((row: any) => (
                    <TableRow key={row.Freelancer}>
                      <TableCell style={webStyle.cellDetail} align="center">
                        {row.Freelancer}
                      </TableCell>
                      <TableCell align="center" style={webStyle.cellDetail}>
                        {row.TransactionDate}
                      </TableCell>
                      <TableCell align="center" style={webStyle.cellDetail}>
                        {row.ProjectName}
                      </TableCell>
                      <TableCell align="center" style={webStyle.cellDetail}>
                        {row.AmountPaid}
                      </TableCell>
                      <TableCell align="center">
                        <Button color="primary" startIcon={<SaveAltIcon />}>
                          Download
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button color="secondary">Cancel</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  header: {
    backgroundColor: '#e9f1f9'
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '0.5rem',
    flex: 1
  },
  headerText: {
    color: '#000',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  cellDetail: {
    color: '#000',
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 'normal',
    opacity: 0.7
  },
  title: {
    color: '#0A0A0A',
    fontFamily: 'Rubik',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    opacity: 0.9
  },
  searcBox: {
    margin: '15px 0',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '40px',
    alignSelf: 'end',
    width: '50%',
    padding: '10px 20px'
  }
};
// Customizable Area End
