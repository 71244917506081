import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  styled,
  withStyles,
  TableBody,
  TableRow,
  TableCell,
  Hidden,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const config = require('./config');

const NextButton = styled(Button)({
  background: '#0abd4c',
  border: 0,
  borderRadius: 4,
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: '#0abd4c'
  }
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 16
  },
  active: {
    '& $line': {
      backgroundColor: '#626262'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#626262'
    }
  },
  line: {
    height: 1,
    backgroundColor: '#626262',
    borderRadius: 1
  }
})(StepConnector);

const countries = [
  'United States',
  'Canada',
  'United Kingdom',
  'Australia',
  'Germany',
  'France',
  'Japan',
  'China',
  'India'
];

const getSteps = [1, 2, 3, 4, 5, 6, 7, 8];
export function PremiumIconCheck() {
  return <img src={greenCheck} alt="images" width="20px" />;
}
export function PremiumIconUnCheck() {
  return <img src={planCheck} alt="images" width="20px" />;
}

export function RadioIconCheck() {
  return <img src={radioCheck} alt="images" width="20px" />;
}

export function UncheckIcon() {
  return <img src={unCheck} alt="images" width="20px" />;
}

export function CheckIcon() {
  return <img src={checkIcon} alt="images" width="20px" />;
}

const WhiteOnBlackTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    padding: '8px',
    fontSize: '14px',
    borderRadius: '7px',
    fontWeight: 400,
    left: '2px',
    fontFamily: 'Inter',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    maxWidth:"500px",
    width:"100%"
  },
  arrow: {
    color: '#f7f7f7'
  }
})(Tooltip);

const step1Tooltip = `When submitting your invention to our marketplace, it's important to be clear about which category your invention falls into. This will not only help you in your patent search, but also in finding the right intellectual property (IP) professionals to patent your invention. We recommend that you take some time to research and identify the most appropriate category for your invention. This will make it easier for potential investors and partners to find and assess your invention. Thank you for being a part of our community, and we look forward to helping you bring your inventions to the marketplace!`;

const step2Tooltip = `As you showcase your invention on our marketplace, it's important to be clear about your target audience. Knowing your target audience allows you to tailor your marketing efforts and sell your invention more effectively. Think about who your invention benefits the most and who would be interested in buying it. Is your invention designed for a specific age group or demographic? Is it for the general public or a niche market? By being clear about your target audience, you'll increase your chances of finding the right buyers and creating a successful business around your invention. Thank you for being a part of our community, and we look forward to helping you reach your target audience!`;

const step3Tooltip = `As you prepare to showcase your invention on our marketplace, we wanted to remind you of the importance of having a clear illustration of your invention. A detailed and accurate illustration of your invention can be extremely helpful when it comes to patenting it and presenting it to potential investors. It allows others to understand how your invention works and how it can benefit them. Having a good illustration can also increase your chances of finding investors and customers, as it provides a more tangible and visually understandable representation of your invention. If you don't have an illustration already, we strongly recommend that you create one using accurate and detailed measurements to ensure that it properly depicts your invention. Thank you for being a part of our community, and we can't wait to see the amazing inventions you'll bring to our marketplace!`;
const step4Tooltip = `As you prepare to showcase your invention on our marketplace, we want to remind you to highlight any awards or recognition that your invention has received from gevernment bodies, industry bodies, or educational forums. Showcasing these awards can help you establish credibility and gain trust among potential investors and customers. It also highlights the value of your invention and sets you apart from competitors. When adding your awards and recognition to your marketplace profile, be sure to provide details such as the name of the award, when it was awarded, and by whom it was it was awarded. Additionally, provide a brief description of the award and what it signifies. By highlighting your awards and recognitions, you can maximize the potential of your invention and display the impact it has already made in the market.`
const step5Tooltip = `As you get ready to showcase your invention on our marketplace, we want to remind you that money attracts money. That's why highlighting any funding you have already received can be a great way to attract more investors to support your invention. When adding your funding information to your marketplace profile, be sure to include the amount of funding received, the funding source, and any relevant documentation. This can help other investors analyze their own funding strategies and make decisions on their investment potential. Make sure to also provide a brief description of how you plan to use the funds and any milestones you hope to achieve. This will give potential investors a clear idea of your business plan and where their investment can make a difference. By highlighting your current funding. you can demonstrate that your invention not only has market potential, but has already garnered support from others. This can build credibility and trust among potential investors, and help you secure additional funding to bring your invention to the next level.`;
const step6Tooltip = `As you prepare to showcase your inventions on the marketplace, it's crucial to consider the pricing strategy. Determining whether to sell outright or offer licensing fees will greatly impact the investors' expectations and level of interest.It's important to impress upon them that you've thoroughly assessed the value of your invention and are confident in your pricing approach. By doing so, you increase the likelihood of securing funding and accelerating the growth of your invention.`;
const imgUpload = require('../assets/imgUplod.svg');
import { importantField } from '../../../components/src/AppHeader.web';
import { Horizontalinventohub } from "../../landingpage/src/assets";
// Customizable Area End

import InventorFormController, { Props } from "./InventorFormController.web";
import InventorStepperLib from "../../../components/src/InventorStepperLib.web";
import {
  checkIcon,
  details,
  greenCheck,
  planCheck,
  radioCheck,
  unCheck
} from "./assets";

export class InventorForm extends InventorFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { inventionRadio, imagesRadio,prototypeRadio,do1Radio,lawyerRadio,do2Radio, selectedOption,wipoClassification,awardRadio } = this.state;

    const renderFileUploadStatus = () => {
      if (this.state.multipleFiles1.length > 0) {
        return 'Files Uploaded';
      } else {
        return '(5 documents with 3-5 images and 3-5 links)';
      }
    };
    const renderFileUploadStatus2 = () => {
      if (this.state.multipleFiles4.length > 0) {
        return 'Files Uploaded';
      } else {
        return '(5 documents with 3-5 images and 3-5 links)';
      }
    };
    const renderFileUploadStatus3 = () => {
      if (this.state.multipleFiles3.length > 0) {
        return 'Files Uploaded';
      } else {
        return '(5 documents with 3-5 images and 3-5 links)';
      }
    };

    const handleStep1 = () => {
      return (
        <>
        <Grid className={classes.subheadingPosition}>
          <Grid item>
            <div className={classes.subheadStyle}>
              <Typography className={classes.Subheading}>
              Which category (dropdown menu) does your invention belong to in terms of wipo classification?{' '}
                <span>
                  <WhiteOnBlackTooltip
                    title={step1Tooltip}
                    arrow
                    placement="bottom-start"
                    open={this.state.toolTip}
                  >
                    <img
                      src={details}
                      className={classes.frameStyle}
                      alt="frame"
                      height={18}
                      width={18}
                      onMouseEnter={this.handleMouseEnterInfo}
                      onMouseLeave={this.handleMouseLeaveInfo}
                    />
                  </WhiteOnBlackTooltip>
                </span>
              </Typography>
            </div>
          </Grid>
           
           <div style={{marginTop:"30px"}}>
          <Grid item xs={12} md={4} className={classes.marginCheckbox}>
            <Box
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                padding: '16px',
                background: selectedOption === 'Design' ? 'black' : '#fff',
                color: selectedOption === 'Design' ? '#fff' : 'black',
                cursor:"pointer"
              }}
              data-test-id="handleCheckChange"
              onClick={() => this.handleCheckChange({ target: { name: 'Design' } })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<PremiumIconUnCheck />}
                    checkedIcon={<PremiumIconCheck />}
                    color="default"
                    checked={selectedOption === 'Design'}
                    onChange={this.handleCheckChange}
                    name="Design"
                  />
                }
                label={<span style={{ fontSize: '18px' }}>Design</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.marginCheckbox}>
            <Box
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                padding: '16px',
                background: selectedOption === 'Plant' ? 'black' : '#fff',
                color: selectedOption === 'Plant' ? '#fff' : 'black',
                cursor:"pointer"

              }}
              data-test-id="handleCheckChange1"
              onClick={() => this.handleCheckChange({ target: { name: 'Plant' } })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<PremiumIconUnCheck />}
                    checkedIcon={<PremiumIconCheck />}
                    color="default"
                    checked={selectedOption === 'Plant'}
                    onChange={this.handleCheckChange}
                    name="Plant"
                  />
                }
                label={<span style={{ fontSize: '18px' }}>Plant</span>}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.marginCheckbox}>
            <Box
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                padding: '16px',
                background: selectedOption === 'Utility' ? 'black' : '#fff',
                color: selectedOption === 'Utility' ? '#fff' : 'black',
                cursor:"pointer"
              }}
              data-test-id="handleCheckChange2"
              onClick={() => this.handleCheckChange({ target: { name: 'Utility' } })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<PremiumIconUnCheck />}
                    checkedIcon={<PremiumIconCheck />}
                    color="default"
                    checked={selectedOption === 'Utility'}
                    onChange={this.handleCheckChange}
                    name="Utility"
                  />
                }
                label={<span style={{ fontSize: '18px' }}>Utility</span>}
              />
            </Box>
            </Grid>
            {this.state.validationError && (
              <Typography className={classes.errorText}>{this.state.validationError}</Typography>
            )}
           </div>
           </Grid>

            <Grid container className={classes.backNextBtn1}>
              <Grid item style={{marginRight:"10px"}}>
              <Button
                onClick={this.handleBack}
              >
                BACK
              </Button>
              </Grid>
              <Grid item style={{marginRight:"10px"}}>
              <NextButton onClick={this.handleNext1}>NEXT</NextButton>
              </Grid>
            </Grid>
        </>
      );
    };

    const handleStep2 = () => {
      return (
        <>
        <Grid className={classes.subheadingPosition}>
          <Grid item>
            <div className={classes.subheadStyle}>
              <Typography className={classes.Subheading}>
              Who is the target audience for your invention Direct to consumer company?{' '}
                <span>
                  <WhiteOnBlackTooltip
                    title={step2Tooltip}
                    arrow
                    placement="bottom"
                    open={this.state.toolTip}
                  >
                    <img
                      src={details}
                      className={classes.frameStyle}
                      alt="frame"
                      height={18}
                      width={18}
                      onMouseEnter={this.handleMouseEnterInfo}
                      onMouseLeave={this.handleMouseLeaveInfo}
                    />
                  </WhiteOnBlackTooltip>
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid container>
            {this.state.checkboxes.map((checkbox: any) => (
              <Grid
                key={checkbox.id}
                item
                xs={12}
                md={8}
                className={classes.marginCheckbox}
              >
                <Box
                  style={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    color: checkbox.checked ? '#fff' : 'black',
                    padding: '16px',
                    background: checkbox.checked ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<PremiumIconUnCheck />}
                        checkedIcon={<PremiumIconCheck />}
                        color="default"
                        checked={checkbox.checked}
                        onChange={this.handleCheckboxChange(checkbox.id)}
                        name={checkbox.label}
                      />
                    }
                    label={
                      <span style={{ fontSize: '18px' }}>{checkbox.label}</span>
                    }
                  />
                </Box>
           
              </Grid>
            ))}
          </Grid>
           {this.state.validationError && (
              <Typography className={classes.errorText}>{this.state.validationError}</Typography>
            )}
        </Grid>
            <Grid container className={classes.backNextBtn}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext2}>NEXT</NextButton>
          </Grid>
        </Grid>
        </>
      );
    };

    const handleStep3 = () => {
      return (
        <>
          <Grid className={classes.subheadingPositionStep3}>
            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading}>
                  Have you created any artwork for your invention?{' '}
                  <span>
                    <WhiteOnBlackTooltip
                      title={step3Tooltip}
                      arrow
                      placement="bottom"
                      open={this.state.toolTip}
                    >
                      <img
                        src={details}
                        className={classes.frameStyle}
                        alt="frame"
                        height={18}
                        width={18}
                        onMouseEnter={this.handleMouseEnterInfo}
                      onMouseLeave={this.handleMouseLeaveInfo}
                      />
                    </WhiteOnBlackTooltip>
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid container spacing={2} className={classes.marginCheckbox}>
              <Grid item xs={12} md={3}>
                <Box
                  style={{
                    borderRadius: '10px',
                    border: '1px solid black',
                    padding: '16px',
                    color: inventionRadio === 'true' ? '#fff' : 'black',
                    background: inventionRadio === 'true' ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="targetAudience"
                        icon={<PremiumIconUnCheck />}
                        color="default"
                        checked={inventionRadio === 'true'}
                        checkedIcon={<RadioIconCheck />}
                        onChange={this.handleRadio1Change}
                        value="true"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>Yes</span>}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  style={{
                    padding: '16px',
                    borderRadius: '10px',
                    color: inventionRadio === 'false' ? '#fff' : 'black',
                    background: inventionRadio === 'false' ? 'black' : 'none',
                    border: '1px solid black',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        icon={<PremiumIconUnCheck />}
                        name="targetAudience"
                        color="default"
                        checked={inventionRadio === 'false'}
                        checkedIcon={<RadioIconCheck />}
                        onChange={this.handleRadio1Change}
                        value="false"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>No</span>}
                  />
                </Box>
              </Grid>
            </Grid>
                {this.state.showStep3ValidationErrors && !inventionRadio && (
                    <Typography variant="caption" color="error">
                     * Please select an option for artwork creation.
                    </Typography>
                  )}

            <Grid item className={classes.marginCheckbox}>
              <Typography className={classes.Subheading}>
                Attach your work
              </Typography>
            </Grid>

            <Divider className={classes.marginCheckbox} />

          </Grid>

          <Grid container spacing={2} className={classes.marginCheckbox}>
  <Grid item xs={12} md={5}>
    <Typography className={classes.miniHeading}>
      If you can upload images or videos of the same?{importantField()}{""}
    </Typography>
    <div className={classes.spaceBetweenStyle}>
      <Box
        style={{
          border: '1px solid black',
          borderRadius: '10px',
          color: imagesRadio === 'true' ? '#fff' : 'black',
          padding: '16px',
          width: '50%',
          marginRight: '10px',
          background: imagesRadio === 'true' ? 'black' : 'none'
        }}
      >
        <FormControlLabel
          control={
            <Radio
              name="uploadimages"
              icon={<PremiumIconUnCheck />}
              checkedIcon={<RadioIconCheck />}
              color="default"
              checked={imagesRadio === 'true'}
              onChange={this.handleRadio2Change}
              value="true"
            />
          }
          label={<span style={{ fontSize: '18px' }}>Yes</span>}
        />
      </Box>
      <Box
        style={{
          border: '1px solid black',
          borderRadius: '10px',
          color: imagesRadio === 'false' ? '#fff' : 'black',
          padding: '16px',
          width: '50%',
          marginLeft: '10px',
          background: imagesRadio === 'false' ? 'black' : 'none'
        }}
      >
        <FormControlLabel
          control={
            <Radio
              name="uploadimages"
              icon={<PremiumIconUnCheck />}
              checkedIcon={<RadioIconCheck />}
              color="default"
              checked={imagesRadio === 'false'}
              onChange={this.handleRadio2Change}
              value="false"
            />
          }
          label={<span style={{ fontSize: '18px' }}>No</span>}
        />
      </Box>
    </div>
    {this.state.showStep3ValidationErrors && !imagesRadio && (
      <Typography variant="caption" color="error">
        * Please select an option for image privacy.
      </Typography>
    )}
  </Grid>
  <Grid item xs={12} md={6} className={classes.uploadBtnPosition}>
    <input
      id="contained-button-file1"
      type="file"
      className="file-display1"
      accept="image/*,video/*"
      style={{ display: 'none' }}
      data-test-id="handleFileChange1"
      onChange={(event) => this.handleFileChange1(event)}
      disabled={imagesRadio === 'false' || imagesRadio === ''}
    />

    <label htmlFor="contained-button-file1" style={{ display: "flex", flexDirection: "column" }}>
      <Button component="span" className={classes.uploadBtnStyle}>
        <div className={classes.uploadBtnInnerStyle}>
          <img
            src={imgUpload}
            height={25}
            className={classes.uploadIcon}
          />
          {this.state.uploadedImageName ? (
            <Typography>{this.state.uploadedImageName}</Typography>
          ) : (
            <Typography style={{color:"#404040",fontWeight:600,fontSize:"18px"}}>Upload your image/video</Typography>
          )}
        </div>
      </Button>
      {this.state.uploadErrorMessage && (
        <Typography variant="caption" color="error">
          {this.state.uploadErrorMessage}
        </Typography>
      )}
      {this.state.showStep3ValidationErrors && imagesRadio === 'true' && !this.state.uploadedImageName && (
        <Typography variant="caption" color="error">
          * This field is mandatory
        </Typography>
      )}
    </label>

  </Grid>
</Grid>

          {handleHalfStep13()}
          {handleHalfStep23()}
          <Grid container className={classes.backNextBtn}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext3}>NEXT</NextButton>
          </Grid>
        </Grid>
        </>
      );
    };

    const handleHalfStep13 = () => {
      return (
        <Grid container spacing={2} className={classes.marginCheckbox}>
          <Grid item xs={12} md={5}>
            <Typography className={classes.miniHeading}>
              Have you created a prototype if yes can you upload images or video
              of the same?{importantField()}{""}
            </Typography>
            <div className={classes.spaceBetweenStyle}>
              <Box
                style={{
                  border: '1px solid black',
                  borderRadius: '10px',
                  color:
                    this.state.prototypeRadio === 'true' ? '#fff' : 'black',
                  padding: '16px',
                  width: '50%',
                  marginRight: '10px',
                  background:
                    this.state.prototypeRadio === 'true' ? 'black' : 'none'
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      name="prototype"
                      icon={<PremiumIconUnCheck />}
                      checkedIcon={<RadioIconCheck />}
                      color="default"
                      checked={this.state.prototypeRadio === 'true'}
                      onChange={this.handleRadio3Change}
                      value="true"
                    />
                  }
                  label={<span style={{ fontSize: '18px' }}>Yes</span>}
                />
              </Box>
         
              <Box
                style={{
                  border: '1px solid black',
                  borderRadius: '10px',
                  color:
                    this.state.prototypeRadio === 'false' ? '#fff' : 'black',
                  padding: '16px',
                  width: '50%',
                  marginLeft: '10px',
                  background:
                    this.state.prototypeRadio === 'false' ? 'black' : 'none'
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      name="prototype"
                      icon={<PremiumIconUnCheck />}
                      checkedIcon={<RadioIconCheck />}
                      color="default"
                      checked={this.state.prototypeRadio === 'false'}
                      onChange={this.handleRadio3Change}
                      value="false"
                    />
                  }
                  label={<span style={{ fontSize: '18px' }}>No</span>}
                />
              </Box>
            </div>
            {this.state.showStep3ValidationErrors && !prototypeRadio && (
          <Typography variant="caption" color="error">
            * This field is mandatory
          </Typography>
        )}

            <div className={classes.marginCheckbox}>
              <Typography className={classes.miniHeading}>
                Do you want this to be?{importantField()}{""}
              </Typography>
            </div>
            <div className={classes.spaceBetweenStyle}>
              <Box
                style={{
                  border: '1px solid black',
                  borderRadius: '10px',
                  color: this.state.do1Radio === 'false' ? '#fff' : 'black',
                  padding: '16px',
                  width: '50%',
                  marginRight: '10px',
                  background: this.state.do1Radio === 'false' ? 'black' : 'none'
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      name="prototype"
                      icon={<PremiumIconUnCheck />}
                      checkedIcon={<RadioIconCheck />}
                      color="default"
                      checked={this.state.do1Radio === 'false'}
                      onChange={this.handleRadio4Change}
                      value="false"
                    />
                  }
                  label={<span style={{ fontSize: '18px' }}>Public</span>}
                />
              </Box>
              <Box
                style={{
                  border: '1px solid black',
                  borderRadius: '10px',
                  color: this.state.do1Radio === 'true' ? '#fff' : 'black',
                  padding: '16px',
                  width: '50%',
                  marginLeft: '10px',
                  background: this.state.do1Radio === 'true' ? 'black' : 'none'
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      name="prototype"
                      icon={<PremiumIconUnCheck />}
                      checkedIcon={<RadioIconCheck />}
                      color="default"
                      checked={this.state.do1Radio === 'true'}
                      onChange={this.handleRadio4Change}
                      value="true"
                    />
                  }
                  label={<span style={{ fontSize: '18px' }}>Private</span>}
                />
              </Box>
            </div>
            {this.state.showStep3ValidationErrors && !do1Radio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.uploadBtnPosition}>
            <input
              id="contained-button-file2"
              type="file"
              className="file-display2"
              accept="image/*,video/*"
              style={{ display: 'none' }}
              onChange={event => this.handleFileChange2(event)}
              disabled={prototypeRadio === 'false' || prototypeRadio === ''}
            />
            <label htmlFor="contained-button-file2" style={{display:"flex",flexDirection:"column"}}>
              <Button component="span" className={classes.uploadBtnStyle}>
                <div className={classes.uploadBtnInnerStyle}>
                  <img
                    src={imgUpload}
                    height={25}
                    className={classes.uploadIcon}
                  />
                  {this.state.uploadedPrototypeName ? (
                    <Typography>{this.state.uploadedPrototypeName}</Typography>
                  ) : (
                    <Typography  style={{color:"#404040",fontWeight:600,fontSize:"18px"}}>Upload your image/video</Typography>
                  )}
                </div>
              </Button>
              {this.state.uploadflie2ErrorMessage && (
        <Typography variant="caption" color="error">
          {this.state.uploadflie2ErrorMessage}
        </Typography>
      )}
            {this.state.showStep3ValidationErrors && prototypeRadio === 'true' && !this.state.uploadedPrototypeName && (
          <Typography variant="caption" color="error">
            * This field is mandatory
          </Typography>
        )}
            </label>
          </Grid>
        </Grid>
      );
    };

    const handleHalfStep23 = () => {
      return (
        <>
          <Grid container spacing={2} className={classes.marginCheckbox}>
            <Grid item xs={12} md={5}>
              <Typography className={classes.miniHeading}>
                Do you have legal documents from a lawyer regarding the
                invention?{importantField()}{""}
              </Typography>
              <div className={classes.spaceBetweenStyle}>
                <Box
                  style={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    color: this.state.lawyerRadio === 'true' ? '#fff' : 'black',
                    padding: '16px',
                    width: '50%',
                    marginRight: '10px',
                    background:
                      this.state.lawyerRadio === 'true' ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="prototype"
                        icon={<PremiumIconUnCheck />}
                        checkedIcon={<RadioIconCheck />}
                        color="default"
                        checked={this.state.lawyerRadio === 'true'}
                        onChange={this.handleRadio5Change}
                        value="true"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>Yes</span>}
                  />
                </Box>
                <Box
                  style={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    color:
                      this.state.lawyerRadio === 'false' ? '#fff' : 'black',
                    padding: '16px',
                    width: '50%',
                    marginLeft: '10px',
                    background:
                      this.state.lawyerRadio === 'false' ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="prototype"
                        icon={<PremiumIconUnCheck />}
                        checkedIcon={<RadioIconCheck />}
                        color="default"
                        checked={this.state.lawyerRadio === 'false'}
                        onChange={this.handleRadio5Change}
                        value="false"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>No</span>}
                  />
                </Box>
              </div>
                {this.state.showStep3ValidationErrors && !lawyerRadio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              <div className={classes.marginCheckbox}>
                <Typography className={classes.miniHeading}>
                  Do you want this to be?{importantField()}{""}
                </Typography>
              </div>
              <div className={classes.spaceBetweenStyle}>
                <Box
                  style={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    color: this.state.do2Radio === 'false' ? '#fff' : 'black',
                    padding: '16px',
                    width: '50%',
                    marginRight: '10px',
                    background:
                      this.state.do2Radio === 'false' ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="prototype"
                        icon={<PremiumIconUnCheck />}
                        checkedIcon={<RadioIconCheck />}
                        color="default"
                        checked={this.state.do2Radio === 'false'}
                        onChange={this.handleRadio6Change}
                        value="false"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>Public</span>}
                  />
                </Box>
                <Box
                  style={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    color: this.state.do2Radio === 'true' ? '#fff' : 'black',
                    padding: '16px',
                    width: '50%',
                    marginLeft: '10px',
                    background:
                      this.state.do2Radio === 'true' ? 'black' : 'none'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="prototype"
                        icon={<PremiumIconUnCheck />}
                        checkedIcon={<RadioIconCheck />}
                        color="default"
                        checked={this.state.do2Radio === 'true'}
                        onChange={this.handleRadio6Change}
                        value="true"
                      />
                    }
                    label={<span style={{ fontSize: '18px' }}>Private</span>}
                  />
                </Box>
              </div>
              {this.state.showStep3ValidationErrors && !do2Radio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.uploadBtnPosition}>
              <input
                id="contained-button-file3"
                type="file"
                className="file-display3"
                accept="image/*,video/*"
                style={{ display: 'none' }}
                onChange={event => this.handleFileChange3(event)}
                disabled={lawyerRadio === 'false' || lawyerRadio === ''}

              />
              <label htmlFor="contained-button-file3" style={{display:"flex",flexDirection:"column"}}>
                <Button component="span" className={classes.uploadBtnStyle}>
                  <div className={classes.uploadBtnInnerStyle}>
                    <img
                      src={imgUpload}
                      height={25}
                      className={classes.uploadIcon}
                    />
                    {this.state.uploadedLegalName ? (
                      <Typography>{this.state.uploadedLegalName}</Typography>
                    ) : (
                      <Typography style={{color:"#404040",fontWeight:600,fontSize:"18px"}}>Upload your image/video</Typography>
                    )}
                  </div>
                </Button>
                {this.state.uploadflie3ErrorMessage && (
        <Typography variant="caption" color="error">
          {this.state.uploadflie3ErrorMessage}
        </Typography>
      )}
              {this.state.showStep3ValidationErrors && lawyerRadio === 'true' && !this.state.uploadedLegalName && (
          <Typography variant="caption" color="error">
            * This field is mandatory
          </Typography>
        )}
              </label>
            </Grid>
          </Grid>
        </>
      );
    };

    const handleStep4 = () => {
      return (
        <>
          <Grid className={classes.subheadingPosition}>
            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading}>
                Has your invention won any government awards?{" "}
                  <span>
                    <WhiteOnBlackTooltip
                      title={step4Tooltip}
                      arrow
                      placement="bottom"
                      open={this.state.toolTip}
                    >
                      <img
                        src={details}
                        alt="frame"
                        className={classes.frameStyle}
                        width={18}
                        height={18}
                        onMouseEnter={this.handleMouseEnterInfo}
                        onMouseLeave={this.handleMouseLeaveInfo}
                      />
                    </WhiteOnBlackTooltip>
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid container spacing={2} >
              <Grid item xs={12} md={5} >
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      borderRadius: '10px',
                      border: '1px solid black',
                      marginRight: '10px',
                      width: '50%',
                      padding: '16px',
                      background:
                        this.state.wipoClassification === 'true' ? 'black' : 'none',
                      color: this.state.wipoClassification === 'true' ? '#fff' : 'black'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.wipoClassification === 'true'}
                          onChange={this.handleWipoClassificationChange}
                          value="true"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>Yes</span>}
                    />
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.wipoClassification === 'false' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.wipoClassification === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.wipoClassification === 'false'}
                          onChange={this.handleWipoClassificationChange}
                          value="false"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>No</span>}
                    />
                  </Box>
                </div>
                  {this.state.showStep4ValidationErrors && !this.state.wipoClassification && (
                 <Typography variant="caption" color="error">
                  * This field is mandatory
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6} className={classes.uploadBtnPosition2}>
                <input
                  id="contained-button-file6"
                  type="file"
                  multiple
                  className="file-display6"
                  accept="image/*,.pdf,.doc,.docx,.ppt,.pptx,.txt,.html,.htm"
                  style={{ display: 'none' }}
                  disabled={wipoClassification === 'false' || wipoClassification === ''}
                  onChange={event => this.handleFileChange4(event)}
                />
                <label htmlFor="contained-button-file6" style={{display:"flex",flexDirection:"column",alignItems:"end"}}>
                  <Button component="span" className={classes.uploadBtnStyle2}>
                    <div className={classes.uploadBtnInnerStyle2}>
                      <img
                        src={imgUpload}
                        height={25}
                        className={classes.uploadIcon}
                      />
                      <Typography>{renderFileUploadStatus()}</Typography>
                      {this.state.multipleFiles1error && (
                        <Typography>
                          <span className={classes.multipleFilesText}>
                            {this.state.multipleFiles1error}
                          </span>
                        </Typography>
                      )}
                    </div>
                  </Button>
                  {this.state.showStep4ValidationErrors && this.state.wipoClassification === 'true' && this.state.multipleFiles1==0 && (
                   <Typography variant="caption" color="error">
                    * This field is mandatory
                   </Typography>
                  )}
                </label>
              </Grid>
            </Grid>

            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading2}>
                Has your invention received any industry or university or school awards?
                </Typography>
              </div>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={5} >
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      borderRadius: '10px',
                      border: '1px solid black',
                      marginRight: '10px',
                      width: '50%',
                      padding: '16px',
                      background:
                        this.state.awardRadio === 'true' ? 'black' : 'none',
                      color: this.state.awardRadio === 'true' ? '#fff' : 'black'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.awardRadio === 'true'}
                          onChange={this.handleAwardRadioChange}
                          value="true"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>Yes</span>}
                    />
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.awardRadio === 'false' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.awardRadio === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.awardRadio === 'false'}
                          onChange={this.handleAwardRadioChange}
                          value="false"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>No</span>}
                    />
                  </Box>
                </div>
                  {this.state.showStep4ValidationErrors && !this.state.awardRadio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
              <Grid item xs={12} md={6} className={classes.uploadBtnPosition2}>
                <input
                  id="contained-button-file7"
                  type="file"
                  multiple
                  className="file-display6"
                  accept="image/*,.pdf,.doc,.docx,.ppt,.pptx,.txt,.html,.htm"
                  style={{ display: 'none' }}
                  onChange={event => this.handleFileChange42(event)}
                  disabled={awardRadio === 'false' || awardRadio === ''}
                />
                <label htmlFor="contained-button-file7" style={{display:"flex",flexDirection:"column",alignItems:"end"}} >
                  <Button component="span" className={classes.uploadBtnStyle2}>
                    <div className={classes.uploadBtnInnerStyle2}>
                      <img
                        src={imgUpload}
                        height={25}
                        className={classes.uploadIcon}
                      />
                     <Typography>{renderFileUploadStatus2()}</Typography>
                      {this.state.multipleFiles4error && (
                        <Typography>
                          <span className={classes.multipleFilesText}>
                            {this.state.multipleFiles4error}
                          </span>
                        </Typography>
                      )}
                    </div>
                  </Button>
                  {this.state.showStep4ValidationErrors && this.state.awardRadio === 'true' && this.state.multipleFiles4==0 && (
          <Typography variant="caption" color="error">
            * This field is mandatory
          </Typography>
        )}
                </label>
              </Grid>
            </Grid>
            <Grid container className={classes.backNextBtn1}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext4}>NEXT</NextButton>
          </Grid>
        </Grid>
          </Grid>
        </>
      );
    };

    const handleStep5 = () => {
      return (
        <>
          <Grid className={classes.subheadingPosition} container justifyContent="space-between">
            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading}>
                  Have you received any funding for your invention?{" "}
                  <span>
                    <WhiteOnBlackTooltip
                      title={step5Tooltip}
                      arrow
                      placement="bottom"
                      open={this.state.toolTip}
                    >
                      <img
                        src={details}
                        className={classes.frameStyle}
                        alt="frame"
                        height={18}
                        width={18}
                        onMouseEnter={this.handleMouseEnterInfo}
                        onMouseLeave={this.handleMouseLeaveInfo}
                      />
                    </WhiteOnBlackTooltip>
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid container spacing={2} className={classes.marginCheckbox}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.Subheading3}>
                  Please select the option{importantField()}
                </Typography>
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color: this.state.option === 'true' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginRight: '10px',
                      background:
                        this.state.option === 'true' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.option === 'true'}
                          onChange={this.handleOptionRadioChange}
                          value="true"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>Yes</span>}
                    />
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color: this.state.option === 'false' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.option === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.option === 'false'}
                          onChange={this.handleOptionRadioChange}
                          value="false"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>No</span>}
                    />
                  </Box>
                </div>
                {this.state.showStep5ValidationErrors && !this.state.option && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
              <Grid item xs={12} md={6} className={classes.uploadBtnPosition} style={{flexDirection:"column",alignItems:"end"}}>
                <div>
                  <Box component={'div'}>
                    <Typography className={classes.Subheading2}>
                      Linkedin Profile Link:
                    </Typography>
                  </Box>
                  <Box component={'div'} className={classes.linkDinLinkStyle}>
                    <TextField
                      id="outlined-secondary"
                      variant="outlined"
                      fullWidth
                      value={this.state.attachLink}
                      placeholder="Enter Link"
                      disabled={this.state.option === 'false' || this.state.option === ''}
                      onChange={this.handleLinkedInLinkChange}
                    />
                  </Box>
                </div>
                {this.state.showStep5ValidationErrors && this.state.option === 'true' && this.state.attachLink=="" && (
              <Typography variant="caption" color="error">
               * This field is mandatory
             </Typography>
               )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.marginCheckbox}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.Subheading3}>
                 Will you patent this invention?{importantField()}
                </Typography>
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.patenting === 'true' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginRight: '10px',
                      background:
                        this.state.patenting === 'true' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.patenting === 'true'}
                          onChange={this.handlePatentingRadioChange}
                          value="true"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>Yes</span>}
                    />
                  </Box>
                  <Box
                    style={{
                      borderRadius: '10px',
                      border: '1px solid black',
                      color: this.state.patenting === 'false' ? '#fff' : 'black',
                      width: '50%',
                      padding: '16px',
                      marginLeft: '10px',
                      background:
                        this.state.patenting === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checkedIcon={<RadioIconCheck />}
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          color="default"
                          checked={this.state.patenting === 'false'}
                          value="false"
                          onChange={this.handlePatentingRadioChange}
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>No</span>}
                    />
                  </Box>
                </div>
                {this.state.showStep5ValidationErrors && !this.state.patenting && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
              <Grid item xs={12} md={6} className={classes.uploadBtnPosition}>
                <input
                  id="contained-button-file"
                  type="file"
                  multiple
                  accept="image/*,.pdf,.doc,.docx,.ppt,.pptx,.txt,.html,.htm"
                  className="file-display"
                  style={{ display: 'none' }}
                  disabled={this.state.patenting === 'false' || this.state.patenting === ''}
                  onChange={event => this.handleFileChange6(event)}
                />
                <label htmlFor="contained-button-file" style={{display:"flex",flexDirection:"column",alignItems:"end"}}>
                  <Button component="span" className={classes.uploadBtnStyle2}>
                    <div className={classes.uploadBtnInnerStyle2}>
                      <img
                        src={imgUpload}
                        height={25}
                        className={classes.uploadIcon}
                      />
                      <Typography>{renderFileUploadStatus3()}</Typography>
                      <Typography>
                        {this.state.multipleFiles3error && (
                          <span className={classes.multipleFilesText}>
                            {this.state.multipleFiles3error}
                          </span>
                        )}
                      </Typography>
                    </div>
                  </Button>
                  {this.state.showStep5ValidationErrors && this.state.patenting === 'true' && this.state.multipleFiles3==0 && (
              <Typography variant="caption" color="error">
               * This field is mandatory
             </Typography>
               )}
                </label>
              </Grid>
            </Grid>
            <Grid container className={classes.backNextBtn1}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext5}>NEXT</NextButton>
          </Grid>
        </Grid>
          </Grid>
        </>
      );
    };

    const handleStep6 = () => {
      return (
        <>
          <Grid className={classes.subheadingPosition}>
            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading}>
                What price will you sell this invention at?{" "}
                  <span>
                    <WhiteOnBlackTooltip
                      title={step6Tooltip}
                      arrow
                      placement="bottom"
                      open={this.state.toolTip}
                      
                    >
                      <img
                        src={details}
                        alt="frame"
                        className={classes.frameStyle}
                        height={18}
                        width={18}
                        onMouseEnter={this.handleMouseEnterInfo}
                        onMouseLeave={this.handleMouseLeaveInfo}
                      />
                    </WhiteOnBlackTooltip>
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.priceRadio === 'false' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginRight: '10px',
                      background:
                        this.state.priceRadio === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.priceRadio === 'false'}
                          onChange={this.handlePriceRadioChange}
                          value="false"
                        />
                      }
                      label={
                        <span style={{ fontSize: '18px' }}>
                          Country Specific
                        </span>
                      }
                    />
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.priceRadio === 'true' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.priceRadio === 'true' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.priceRadio === 'true'}
                          onChange={this.handlePriceRadioChange}
                          value="true"
                        />
                      }
                      label={
                        <span style={{ fontSize: '18px' }}>World Wide</span>
                      }
                    />
                  </Box>
                </div>
              {this.state.showStep6ValidationErrors && !this.state.priceRadio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{marginTop:"34px"}}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.Subheading3}>
                  What fee will you license this invention?{importantField()}{" "}
                </Typography>
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.countryRadioDropDown === 'false'
                          ? '#fff'
                          : 'black',
                      padding: '16px',
                      width: '50%',
                      marginRight: '10px',
                      background:
                        this.state.countryRadioDropDown === 'false'
                          ? 'black'
                          : 'none'
                    }}
                  >
                    <div className={classes.radioButton}>
                      <FormControlLabel
                        control={
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Radio
                              name="uploadimages"
                              icon={<PremiumIconUnCheck />}
                              checkedIcon={<RadioIconCheck />}
                              color="default"
                              checked={
                                this.state.countryRadioDropDown === 'false'
                              }
                              onChange={this.handleCountryRadioDropDownChange}
                              value="false"
                            />
                          </div>
                        }
                        label={
                          <span style={{ fontSize: '18px' }}>
                            Country Specific
                          </span>
                        }
                      />
                      <IconButton
                        // onClick={this.handleDropdownClick}
                        aria-label="Toggle dropdown"
                        style={{
                          color:
                            this.state.countryRadioDropDown === 'false'
                              ? '#fff'
                              : 'black'
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.countryRadioDropDown === 'true'
                          ? '#fff'
                          : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.countryRadioDropDown === 'true'
                          ? 'black'
                          : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="uploadimages"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.countryRadioDropDown === 'true'}
                          onChange={this.handleCountryRadioDropDownChange}
                          value="true"
                        />
                      }
                      label={
                        <span style={{ fontSize: '18px' }}>World Wide</span>
                      }
                    />
                  </Box>
                </div>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  {this.state.countryRadioDropDown === 'false' && (
                    <Box
                      style={{
                        border: '1px solid gray',
                        width: '400px',
                        borderRadius: '10px',
                        height: '200px',
                        overflow: 'auto',
                        marginLeft: '10px'
                      }}
                    >
                      <TableBody>
                        {countries.map((country: any) => (
                          <TableRow key={country}>
                            <TableCell style={{ border: 'none' }}>
                              <Checkbox
                                icon={<UncheckIcon />}
                                checkedIcon={<CheckIcon />}
                              />
                              {country}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Box>
                  )}
              {this.state.showStep6ValidationErrors && !this.state.countryRadioDropDown && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.backNextBtn1}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext6}>NEXT</NextButton>
          </Grid>
        </Grid>
          </Grid>
        </>
      );
    };

    const handleStep7 = () => {
      return (
        <>
          <Grid className={classes.subheadingPosition} style={{marginRight:"70px"}}>
            <Grid item>
              <div className={classes.subheadStyle}>
                <Typography className={classes.Subheading} style={{display:"flex"}}>
                  You can share your bank details now or when you need to do
                  business -it will take 24 hours to verify your account{importantField()}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography className={classes.smallText}>
                As you upload your inventions to the marketplace, it's crucial
                to establish credibility with potential partners and investors.
                Sharing your account details and validating your account will go
                a long way in building trust and proving your legitimacy as a
                party. This will increase the likelihood of attracting interest
                and securing partnerships that will help grow your invention and
                business.
              </Typography>
            </Grid>
            <Grid container spacing={2} className={classes.marginCheckbox}>
              <Grid item xs={12} md={5}>
                <div className={classes.spaceBetweenStyle}>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      width: '50%',
                      marginRight: '10px',
                      color:
                        this.state.businessRadio === 'true' ? '#fff' : 'black',
                      padding: '16px',
                      background:
                        this.state.businessRadio === 'true' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.businessRadio === 'true'}
                          onChange={this.handleBusinessRadioChange}
                          value="true"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>Yes</span>}
                    />
                  </Box>
                  <Box
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      color:
                        this.state.businessRadio === 'false' ? '#fff' : 'black',
                      padding: '16px',
                      width: '50%',
                      marginLeft: '10px',
                      background:
                        this.state.businessRadio === 'false' ? 'black' : 'none'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          name="targetAudience"
                          icon={<PremiumIconUnCheck />}
                          checkedIcon={<RadioIconCheck />}
                          color="default"
                          checked={this.state.businessRadio === 'false'}
                          onChange={this.handleBusinessRadioChange}
                          value="false"
                        />
                      }
                      label={<span style={{ fontSize: '18px' }}>No</span>}
                    />
                  </Box>
                </div>
              {this.state.showStep6ValidationErrors && !this.state.businessRadio && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
            </Grid>
            <Grid container className={classes.backNextBtn1}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext7}>NEXT</NextButton>
          </Grid>
        </Grid>
          </Grid>
        </>
      );
    };

    const handleStep8 = () => {
      return (
        <>
          <Grid className={classes.subheadingPosition}>
            <Grid container spacing={2} className={classes.marginCheckbox}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.Subheading} style={{display:"flex"}}>
                  Add education and experience?{importantField()}{""}
                </Typography>
                <Box component={'div'} className={classes.marginCheckbox}>
                  <TextField
                    id="outlined-secondary"
                    variant="outlined"
                    placeholder="Add Work Experience"
                    style={{width:"70%",borderRadius:"20px"}}
                    value={this.state.addWorkExp}
                  />
                </Box>
                   {this.state.showStep6ValidationErrors && this.state.addWorkExp=="" && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
                <Box component={'div'} className={classes.marginCheckbox}>
                  <TextField
                    id="outlined-secondary"
                    variant="outlined"
                    placeholder="Collage Name"
                    style={{width:"70%",borderRadius:"20px"}}
                    value={this.state.collageName}
                  />
                </Box>
                   {this.state.showStep6ValidationErrors && this.state.collageName=="" && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
                <Box component={'div'} className={classes.marginCheckbox}>
                  <TextField
                    id="outlined-secondary"
                    variant="outlined"
                    placeholder="School Name"
                    style={{width:"70%",borderRadius:"20px"}}
                    value={this.state.schoolName}
                  />
                </Box>
                      {this.state.showStep6ValidationErrors && this.state.schoolName=="" && (
            <Typography variant="caption" color="error">
             * This field is mandatory
            </Typography>
           )}
              </Grid>
            </Grid>

            <Grid container className={classes.backNextBtn1}>
          <Grid item>
            <Button
              onClick={this.handleBack}
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <NextButton onClick={this.handleNext8}>NEXT</NextButton>
          </Grid>
        </Grid>
          </Grid>
        </>
      );
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container maxWidth={'xl'}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={3} className={classes.alignStyle}>
        <img src={Horizontalinventohub} className={classes.invento} />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} md={9}>
            <Stepper
              alternativeLabel
              connector={<ColorlibConnector />}
              activeStep={this.state.activeStep}
              style={{width:"770px"}}
            >
              {getSteps.map((label: any) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={InventorStepperLib} />
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Hidden>
        {/* step 1 */}
        {this.state.activeStep === 0 && handleStep1()}
        {/* step 2 */}
        {this.state.activeStep === 1 && handleStep2()}
        {/* step 3 */}
        {this.state.activeStep === 2 && handleStep3()}
        {/* step 4 */}
        {this.state.activeStep === 3 && handleStep4()}
        {/* step 5 */}
        {this.state.activeStep === 4 && handleStep5()}
        {/* step 6 */}
        {this.state.activeStep === 5 && handleStep6()}
        {/* step 7 */}
        {this.state.activeStep === 6 && handleStep7()}
        {/* step 8 */}
        {this.state.activeStep === 7 && handleStep8()}
      </Grid>
    </Container>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    '@media (max-width:425)': {
      '& .MuiStepper-root': {
        padding: 0
      }
    }
  },
  errorText:{
    fontFamily:"Inter",
    fontSize: "16px",
    fontWeight: 400,
    color:"red",
    lineHeight:"30px"
  },
  heading: {
    fontSize: '3rem',
    fontWeight: 700,
    color: '#000000',
    fontFamily: "'Inter', sans-serif"
  },
  Subheading: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#000000',
    fontFamily: "'Inter', sans-serif",
    '@media (max-width: 600px)': {
      fontSize: '1rem'
    }
  },
  Subheading2:{
    color: 'black',
    fontFamily: "'Inter', sans-serif",
    fontSize: "20px",
    fontWeight: 500,
  },
  Subheading3:{
    color: 'black',
    fontFamily: "'Inter', sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    display:"flex"
  },
  invento: {
    width: "300px",
    height: "61px",
    objectFit: "cover" as const,
    marginTop: "25px",
    marginLeft:"70px"
  },
  alignStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  subheadingPosition: {
    marginTop: '70px',
    width: '100%',
    marginInline:"70px"
  },
  subheadingPositionStep3: {
    marginTop: '70px',
    width: '100%',
  },
  backNextBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    marginTop: '20px',
    '@media (min-width: 425px) and (max-width:759px)': {
      marginTop: '20px',
      position: 'unset'
    }
  },
  marginCheckbox: {
    marginTop: '20px'
  },
  backNextBtn1: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    marginTop: '20px',
    position:"absolute" as "absolute",
    bottom: 0,
    left: 0,
    '@media (min-width: 425px) and (max-width:759px)': {
      marginTop: '20px',
      position: 'unset'
    }
  },
  subheadStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  miniHeading: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
    fontFamily: "'Inter', sans-serif",
    marginTop: '10px',
    display:"flex"
  },
  spaceBetweenStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  uploadBtnPosition: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  uploadBtnPosition2: {
    display: 'flex',
    flexDirection:"column" as "column"
  },
  linkdinStyle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  smallText: {
    fontSize: '16px',
    color: '#000000',
    fontFamily: "'Inter', sans-serif",
    marginTop: '10px'
  },
  uploadBtnStyle: {
    backgroundColor: '#e7F9ed',
    height: '165px',
    width: '475px',
    '&:hover': {
      background: '#e7F9ed'
    },
    '@media (min-width: 320px) and (max-width:499px)': {
      height: '45px',
      width: '275px'
    }
  },
  uploadBtnInnerStyle: {
    display: 'flex',
    textTransform: 'capitalize' as 'capitalize',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed',
    width: '455px',
    height: '144px',
    borderRadius: '12px',
    '@media (min-width: 320px) and (max-width:499px)': {
      height: '50px',
      width: '275px'
    }
  },
  uploadBtnStyle2: {
    backgroundColor: '#e7F9ed',
    textTransform: 'capitalize' as 'capitalize',
    height: '165px',
    width: '475px',
    '&:hover': {
      background: '#e7F9ed'
    },
    '@media (min-width: 320px) and (max-width:499px)': {
      height: '110px',
      width: '275px'
    }
  },
  uploadBtnInnerStyle2: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed',
    width: '455px',
    height: '144px',
    borderRadius: '12px',
    '@media (min-width: 320px) and (max-width:499px)': {
      height: '75px',
      width: '275px'
    }
  },
  linkDinLinkStyle: {
    width: 472,
    '@media (min-width: 320px) and (max-width:499px)': {
      width: 222
    }
  },
  uploadIcon: {
    marginBottom: 15
  },
  radioButton: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  multipleFilesText: {
    color: 'red'
  }
};
export default withStyles(webStyle)(InventorForm);
// Customizable Area End
