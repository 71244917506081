import React from "react";
// Customizable Area Start
import { createStyles, createTheme,Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails,styled, AccordionSummary, Box, Button,InputAdornment, Checkbox, Chip, Grid, LinearProgress, Link,Typography, IconButton,Drawer,TextField, CircularProgress } from "@material-ui/core";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from "@material-ui/lab/Pagination";
import { avtara } from '../../blocks/landingpage/src/assets';
import SearchIcon from '@material-ui/icons/Search';

const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paperAnchorRight': {
        maxWidth:"70%", width:"100%"
    }
})

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "#696A6A",
        }
    },
    typography: {
        fontFamily: 'Inter,Rubik',
    }
});

import GlobalSearchTalentController, {
    Props,
    configJSON
} from "./GlobalSearchTalentController";
import GlobalClientProfile from "./GlobalClientProfile.web";

export default class GlobalSearchTalent extends GlobalSearchTalentController {
// Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        if (this.state.loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            );
        }
        return (
            //Merge Engine DefaultContainer
            <>
             <CustomDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer}>
              <Box p={2}>
               <GlobalClientProfile toggleDrawer={this.toggleDrawer} navigation={undefined} clientProfileData={this.state.clientProfileData} />
              </Box>
              </CustomDrawer>
                <ThemeProvider theme={theme}>
                    <Grid container justifyContent="space-between" style={webStyles.outerContainer}>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                        <Grid container spacing={2} style={webStyles.chipOuterContainer} >
                                            {this.state.minRange &&
                                                <Grid item xs={4} sm={4} md={3} lg={4}>
                                                    <Box style={webStyles.searchedItem}>
                                                        <Typography style={webStyles.searchedText}>{this.state.minRange} {configJSON.to} {this.state.maxRange} {configJSON.proposal}</Typography>
                                                        <CloseIcon style={webStyles.closeIcon} fontSize="small" onClick={this.handleClearProposalFilter} data-testid="clear-proposal-filter"/>
                                                    </Box>
                                                </Grid>
                                            }
                                            {this.state.minHours &&
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <Box style={webStyles.searchedItem}>
                                                        <Typography style={webStyles.searchedText}>{this.state.minHours} {configJSON.to} {this.state.maxHours} {configJSON.hourlyRate}</Typography>
                                                        <CloseIcon data-testid="clear-hour-filter" style={webStyles.closeIcon} fontSize="small" onClick={this.handleClearHoursFilter}/>
                                                    </Box>
                                                </Grid>
                                            }
                                            {(this.state.minHours || this.state.minRange) &&
                                                <Grid item xs={4} sm={3} md={3} lg={3}>
                                                    <Typography data-testid="clear-all-filter" style={webStyles.clearFilter} color="secondary" onClick={this.handleClearAllFilter}>{configJSON.clearFilter}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                    {this.props.results.length === 0 ? (
                                        <Typography style={webStyles.noProfile}>{configJSON.noProfile}</Typography>
                                    ) : (
                                    this.props.results.map(data =>(
                                        <Box style={webStyles.boxContainer} key={data.id} onClick={()=>this.toggleDrawer(data.id)}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <Grid container style={{gap:"10px"}}>
                                                        <div style={webStyles.profileContainer}>
                                                            <img src={avtara} alt="avtar" style={webStyles.userProfileImg} />
                                                            {data?.attributes?.status &&
                                                                <FiberManualRecordIcon style={webStyles.onlineIcon} />
                                                            }
                                                        </div>
                                                        <div>
                                                            <Box>
                                                                <Typography style={webStyles.userName}>{data?.attributes?.first_name}</Typography>
                                                                <Typography style={webStyles.subText} color="secondary">{data?.attributes?.job_title}</Typography>
                                                                <Box style={webStyles.locationContainer}>
                                                                    <LocationOnRoundedIcon fontSize="small" style={webStyles.locationIcon} />
                                                                    <Typography style={webStyles.subText} color="secondary">{data?.attributes?.country}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </div>
                                                        <div>
                                                            {data?.attributes?.status ?
                                                                <Chip label={configJSON.availableStatus} style={webStyles.chipContainer} /> :
                                                                <Chip label={configJSON.notAvailable} style={webStyles.chipNotAvailable} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                                    <Box style={webStyles.menuBox}>
                                                        <Box style={webStyles.countBox}>
                                                            <Typography style={webStyles.dayText}>{this.state.perDayRate}<span style={webStyles.spanText}>{configJSON.day}</span></Typography>
                                                            <Typography style={webStyles.dayText}>{this.state.earnedPoint}<span style={webStyles.spanText}>{configJSON.earned}</span></Typography>
                                                        </Box>
                                                        <Box style={webStyles.progressContainer}>
                                                            <Typography style={webStyles.dayText}>{this.state.progressValue}<span style={webStyles.spanText}>{configJSON.jobSuccess}</span></Typography>
                                                            <BorderLinearProgress variant="determinate" data-testid={"linearprogress-" + data.id} value={this.state.progressValue} />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Typography style={webStyles.emailStyle}>Email:<span style={webStyles.emailSpanText}>{data?.attributes?.email}</span></Typography>
                                            <Box style={webStyles.summeryBox}>
                                            <Typography color="secondary" style={webStyles.profileSummery}>{this.state.profileSummery}</Typography>
                                            <Typography color="secondary" style={webStyles.profileSummery}>{this.state.profileDetail}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography style={webStyles.websiteHead}>{configJSON.websiteDevelop}</Typography>
                                                <Box style={webStyles.languageBox}>
                                                    {data?.attributes?.skills.map((language: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | undefined) =>
                                                        <Typography color="primary" style={webStyles.language} key={index}>{language}</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography style={webStyles.websiteHead}>{configJSON.websiteSkills}</Typography>
                                                <Box style={webStyles.languageBox}>
                                                    {data?.attributes?.skills.slice(0,3).map((skill:any, index:number) =>
                                                        <Typography color="primary" style={webStyles.language} key={index}>{skill}</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box style={webStyles.linkDisplay}>
                                                {data?.attributes?.skills.length > 3 && 
                                                     <Link href="#" data-testid="showMore" style={webStyles.linkText} onClick={this.handleShowMoreSkills}>
                                                     {configJSON.fourMore}
                                                 </Link>
                                                
                                                }
                                                 {this.state.showMore && 
                                                 <>
                                                 {data?.attributes?.skills.slice(3,data?.attributes?.skills.length).map((skill:any, index:number) =>
                                                         <Typography color="primary" style={webStyles.language} key={index}>{skill}</Typography>
                                                     )}
                                                 </>
                                                 }
                                            </Box>
                                            <Box style={webStyles.buttonContainer}>
                                                    <Button variant="outlined" style={webStyles.buttonOutlined} data-testid={"save-" + data.id}  startIcon={data?.attributes?.talent_saved ? <FavoriteIcon/> :<FavoriteBorderOutlinedIcon color="primary" />}>
                                                        {data?.attributes.talent_saved ? configJSON.removeFromSave : configJSON.saveLater}
                                                    </Button>
                                                    <Button variant="contained" style={webStyles.buttonContained} data-testid={"inviteBtn-" + data.id}>{configJSON.inviteToJob}</Button>
                                                </Box>
                                        </Box>
                                    ))
                                    )}
                                    <Box style={webStyles.paginationContainer}>
                                       <Pagination 
                                        count={this.props.totalPages} 
                                        page={this.props.currentPage} 
                                        onChange={this.handlePageChange}
                                       />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container spacing={3} >
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.filterBox}>
                                        <Typography color="secondary" style={webStyles.filterText}>{configJSON.filterBy}</Typography>
                                        <Typography color="secondary" style={webStyles.clearText}>{configJSON.clearAll}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.experienceLevel}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                    {
                                                        this.state.experienceList.map(experience =>
                                                            <Box key={experience.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(experience.value)}
                                                                        onChange={() => this.handleCheckboxChange(experience.value)}
                                                                        data-testid={"experienceCheck-" + experience.id}
                                                                        value={experience.value}
                                                                        color="primary"
                                                                    />
                                                                    <Typography color="secondary">{experience.label}</Typography>
                                                                </Box>
                                                                <Typography color="secondary">{experience.count}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                                
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.Skills}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                <TextField
                                placeholder="Search skill"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={this.state.searchSkillTerm}
                                // onChange={handleSearchChange}
                                style={{ marginBottom: '1rem' }}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon color="disabled" />
                                      </InputAdornment>
                                    ),
                                    style: { borderRadius: "13px" },
                                  }}
                            />
                                                    {
                                                        this.state.skillsList.map(info =>
                                                            <Box key={info.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography color="secondary">{info.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.Location}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                <TextField
                                placeholder="Enter location"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={this.state.searchSkillTerm}
                                // onChange={handleSearchChange}
                                style={{ marginBottom: '1rem' }}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon color="disabled" />
                                      </InputAdornment>
                                    ),
                                    style: { borderRadius: "13px" },
                                  }}
                            />
                                                    {
                                                        this.state.locationList.map(info =>
                                                            <Box key={info.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography color="secondary">{info.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyles.accordinBox}>
                                        <Accordion style={webStyles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography color="secondary" style={webStyles.filterText}>{configJSON.clearInfo}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyles.accordDetail}>
                                                    {
                                                        this.state.clearInfoList.map(info =>
                                                            <Box key={info.id} style={webStyles.checkBoxOuter}>
                                                                <Box style={webStyles.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography color="secondary">{info.label}</Typography>
                                                                </Box>
                                                                <Typography color="secondary">{info.count}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    outerContainer: {
        padding: '3% 0%'
    },
    searchedItem: {
        display: 'flex',
        background: '#206FC4',
        borderRadius: '20px',
        padding: '5px 0',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '2%'
    },
    searchedText: {
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 500
    },
    closeIcon: {
        color: '#fff',
        cursor: 'pointer'
    },
    clearFilter: {
        textDecoration: 'underline',
        fontSize: '0.9rem',
        fontWeight: 300,
        cursor: 'pointer'
    },
    chipContainer: {
        color: '#fff',
        background: '#66AC3D',
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    chipNotAvailable: {
        color: '#fff',
        background: '#C0C0C0',
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    profileContainer: {
        position: 'relative' as 'relative',
        display:"flex" as "flex",
        justifyContent:"center"
    },
    userProfileImg: {
        width: '50px',
        height: '50px',
    },
    onlineIcon: {
        position: 'absolute' as 'absolute',
        color: 'green',
        fontSize: '12px',
        left: '35px',
        top: '37px'
    },
    locationContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    boxContainer: {
        marginTop: '2%',
        background: '#F5F7F9',
        borderRadius: '15px',
        padding: '24px',
    },
    userName: {
        fontSize: '17px',
        fontWeight: 600
    },
    subText: {
        fontSize: '14px'
    },
    menuBox:{
    marginLeft:'30%'
    },
    countBox: {
        display: 'flex',
        gap:'20px',
        "@media(max-width:964px)": {
            gap:'5px',
        },
    },
    dayText: {
        fontSize: '16px',
        fontWeight: 600,
    },
    emailStyle:{
        fontSize: '17px',
        fontWeight: 600,
    },
    emailSpanText:{
        fontSize: '16px',
        fontWeight: 400,
        marginLeft:"5px"
    },
    spanText: {
        fontSize: '16px',
        fontWeight: 400
    },
    progressContainer: {
        margin: '4% 0 0 0'
    },
    locationIcon: {
        width: '16px',
        height: '15px',
        marginLeft:"-4px"
    },
    profileSummery: {
        fontSize: '16px',
        fontWeight:500
    },
    summeryBox: {
        margin: '3% 0'
    },
    languageBox: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        gap: '5px',
        margin: '1% 0 2%'
    },
    language: {
        background: '#E1ECFB',
        borderRadius: '19px',
        textAlign: 'center' as 'center',
        width: 'max-content',
        padding: '5px 17px',
        fontSize:"16px"
    },
    websiteHead: {
        fontSize: '16px',
        fontWeight: 500
    },
    buttonContainer: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'end'
    },
    linkDisplay: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonContained: {
        background: '#206FC4',
        color: '#fff'
    },
    buttonOutlined: {
        borderColor: '#206FC4',
        color: '#206FC4'
    },
    linkText: {
        fontFamily: 'Inter',
        textDecoration: 'underline'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop:"14px"
    },
    filterText: {
        fontSize: '1.1rem',
        fontWeight: 500
    },
    clearText: {
        fontSize: '1.1rem',
        fontWeight: 400
    },
    filterBox: {
        display: 'flex',
        background: '#F5F7F9',
        borderRadius: '15px',
        padding: '15px',
        justifyContent: 'space-between'
    },
    accordinBox: {
        background: '#F5F7F9',
        borderRadius: '15px',
    },
   
    accordion: {
        background: 'none',
        boxShadow: 'none',
    },
    checkBoxOuter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    checkBoxInner: {
        display: 'flex',
        alignItems: 'center'
    },
    accordDetail: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'baseline',
        width: '100%'
    },
    disableBtn: {
        fontSize: '1rem',
        fontWeight: 600,
        marginRight: '5px',
        cursor: 'not-allowed',
        fontFamily: 'Inter',
        color: 'rgb(96, 96, 96)'
    },
    enableBtn: {
        fontSize: '1rem',
        fontWeight: 600,
        marginRight: '5px',
        cursor: 'pointer',
        fontFamily: 'Inter',
        color: '#225DD1'
    },
    selectedPageNumber: {
        background: '#225DD1',
        borderRadius: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        padding: '5px 9px',
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 600
    },
    pageButton: {
        background: '#E4EBF4',
        borderRadius: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        padding: '5px 9px',
        color: '#000',
        fontSize: '0.8rem',
        fontWeight: 600
    },
    chipOuterContainer: {
        width: '100%'
    },
    noProfile:{
        fontSize:'1.5rem',
        paddingTop:'5%',
        textAlign:'center' as 'center',
        color:'grey'
    }
};

export const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
            width: 150,
            marginTop: '2%'
        },
        colorPrimary: {
            backgroundColor: '#DAE9D5'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#66AC3D"
        },

    })
)(LinearProgress);
// Customizable Area End
