// Customizable Area Start
import React from 'react';
import { Box, ThemeProvider, Typography, createTheme } from '@material-ui/core';
import ContactUsController, { Props } from './ContactUsController.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { contactUs } from './assets';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { getNavBar } from './About.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export default class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
       {getNavBar({userType:this.state.userType,...this.props})}
        <Box
          display={'flex'}
          style={{ padding: '3%' }}
          justifyContent={'space-around'}
        >
          <Box flex={0.4}>
            <img src={contactUs} style={{ width: '500px', height: '500px' }} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={0.4}>
            <Box display={'flex'} gridRowGap={'5rem'} flexDirection={'column'}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                style={{ marginTop: '5%' }}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '35px',
                    fontWeight: 600
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 400,
                    marginBottom: '5%',
                    marginTop: '-5%'
                  }}
                >
                 {this.state.contactUsRes.description}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gridRowGap={'1rem'}>
              <Box display={'flex'} gridColumnGap={'1rem'}>
                <LocationOnOutlinedIcon />
                <Typography style={webStyle.text}>
                {this.state.contactUsRes.address}
                </Typography>
              </Box>
              <Box display={'flex'} gridColumnGap={'1rem'}>
                <PhoneOutlinedIcon />
                <Typography style={webStyle.text}> {this.state.contactUsRes.phone_number}</Typography>
              </Box>
              <Box display={'flex'} gridColumnGap={'1rem'}>
                <MailOutlineOutlinedIcon />
                <Typography style={{ ...webStyle.text, color: '#2275CD' }}>
                {this.state.contactUsRes.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  text: {
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 600
  }
};

// Customizable Area End
