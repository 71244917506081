import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { paymentSuccess } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import PaymentSuccessfulController, {
    Props,
    webConfigJSON
} from "./PaymentSuccessfulController";

export default class PaymentSuccessful extends PaymentSuccessfulController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Grid container style={webStyles.gridContainer}>
                    <Grid item style={webStyles.gridInnerBox}>
                        <img src={paymentSuccess} alt="Payment success" style={webStyles.paymentSuccessImg} />
                        <Typography style={webStyles.successMsg}>{webConfigJSON.paymentSuccess}</Typography>
                        <Box style={webStyles.btnContainer}>
                            <Button variant="outlined" color="primary" style={webStyles.homeBtn} onClick={this.handleHomeBtn} data-testid="handleHomeBtn">{webConfigJSON.homeBtn}</Button>
                            <Button variant="contained" color="primary" style={webStyles.viewBtn}>{webConfigJSON.viewInvoice}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    gridContainer: {
        height: '100vh',
        alignContent: 'center',
        justifyContent: 'center'
    },
    gridInnerBox: {
        textAlign: 'center' as 'center',
        borderRadius: '15px',
        padding: '4% 10%',
        boxShadow: '1px 9px 21px 7px #e9f3f4',
        width: 'max-content',
        height: 'max-content'
    },
    successMsg: {
        fontSize: '1rem',
        fontWeight: 800,
        color: '#02CC7F',
        width: '188px',
        margin: 'auto',
        padding: '10px 0'
    },
    btnContainer: {
        display: 'flex',
        gap: '25px'
    },
    homeBtn: {
        borderRadius: '10px',
        padding: '0 45px',
        width: '120px',
        borderColor: '#7746bd',
        fontSize: '0.8rem',
        fontWeight: 700
    },
    viewBtn: {
        borderRadius: '10px',
        fontSize: '0.8rem',
        fontWeight: 700,
        padding: '6px 20px'
    },
    paymentSuccessImg: {
        width: '300px'
    }
};
// Customizable Area End
