import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#d2ffe8",
    zIndex: 1,
    color: "#000000",
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontFamily:"'Inter', sans-serif"
  },
  active: {
    backgroundColor: "#0abd4c",
    color: "#ffff ",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundColor: "#0abd4c",
    color: "#ffff "
  }
});

function InventorStepperLib({ icon,active,completed }:any) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
}

export default InventorStepperLib;