import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  rating: any;
  feedBackText: any;
  firstName: any;
  authToken: any;
  freelancerhomepageData: any;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerHomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  freelancerHomePageId: string;
  FreelancerProfileAPIid: string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.freelancerHomePageId = '';
    this.FreelancerProfileAPIid='';
    this.state = {
      rating: 2,
      feedBackText: '',
      firstName: localStorage.getItem('accountHolderName'),
      authToken: '',
      freelancerhomepageData: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')
    });
    this.getFreelancerProfileRes();
    this.fetchFreelancerHomePageDetails();
    // Customizable Area End
  }
  // Customizable Area Start
  fetchFreelancerHomePageDetails = () => {
    const freelancerHomePageRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      token: this.state.authToken
    };

    this.freelancerHomePageId = freelancerHomePageRequest.messageId;
    freelancerHomePageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.freelancerHomePageRequestEndPoint
    );
    freelancerHomePageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    freelancerHomePageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(
      freelancerHomePageRequest.id,
      freelancerHomePageRequest
    );
  };
  handleMyJobs = () => {
    const findwork: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    findwork.addData(getName(MessageEnum.NavigationTargetMessage), 'SearchJob');
    findwork.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(findwork);
  };

  getFreelancerProfileRes =async () => {
    const FreelancerProfileResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type":  webConfigJSON.dashboarContentType,
      "token": this.state.authToken
    };
    const accId =  await storage.get('accountId')
    this.FreelancerProfileAPIid = FreelancerProfileResponse.messageId;
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${ webConfigJSON.freelancerProfileResponseEndPoint}/${accId}/freelancer_profile`
    );
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getDetailsMethodeType
    );
    runEngine.sendMessage(FreelancerProfileResponse.id, FreelancerProfileResponse);
    
  }

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.freelancerHomePageId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({ freelancerhomepageData: responseJson.data });
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      if(this.FreelancerProfileAPIid === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson.data){
          storage.set("freelancerProfileInfo",JSON.stringify(responseJson.data))
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
    // Customizable Area End
  }
}
