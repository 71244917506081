import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    MenuItem,
    TextField,
    InputAdornment,
    Select,
    styled
  } from "@material-ui/core";
  import { createTheme, ThemeProvider } from "@material-ui/core/styles";
  import SearchIcon from "@material-ui/icons/Search";
  import {
    line,
    arrow,
    rightArrow,
    leftArrow,
    meetingLine,
    sendIcon,
    combinedShape,
    emailToJoin,
    findHire,
    postProject,
    workApprove,
    governmentNgo,
    prototypeDeveloper,
    patentHolder,
    ipLawyer,
    ipEnforcement,
    patentIllustration,
    prototypeAgent,
    database,
    dots,
  } from "./assets";
  import Carousel from "react-material-ui-carousel";
  import FooterWeb from "../../dashboard/src/Components/Footer.web";
  import NavBar from "./NavBar.web";
  export const config = require("./config");
  import {
    EightContainerBox,
    EightContainerBoxTwo,
    EightContainerTypography,
    EightContainerTypographyTwo,
    FirstContainerTypography,
    FirstContainerTypographyTwo,
    CustomGrid,
    FiveContainerTypography,
    FiveContainerTypographyTwo,
    FourthContainerGrid,
    FourthContainerTypography,
    FourthContainerTypographyTwo,
    SecondContainerBox,
    SecondContainerTypography,
    SevenContainerTypography,
    SevenContainerTypographyGrid,
    SevenContainerTypographyTwo,
    SixContainerTypography,
    SixContainerTypographyTwo,
    ThirdContainerBox,
    ThirdContainerTypography,
    NineContainerTypography,
    NineContainerTypographyTwo,
    FiveContainerImageTypography,
    FiveContainerImageTypography2,
  } from "../../../components/src/AppHeader.web";

  const CarouselGrid = styled(Grid)({
    '& > div': {
      overflow: 'unset'
    },
    '& button': {
      bottom: -50
    }
  })
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: "Inter"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    imageUrls = [findHire, postProject, workApprove];
    imageURLS = [
      governmentNgo,
      prototypeDeveloper,
      patentHolder,
      ipLawyer,
      ipEnforcement,
      patentIllustration,
      prototypeAgent,
      database,
    ];
    sectionTypoTwo = () => {
      
      return (
        <>
          <SecondContainerTypography>
            {this.state.sectionData2.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData2.data[0]?.attributes?.title,
                }}
              />
            )}
          </SecondContainerTypography>
          <Typography style={styles.unitText}>
            {this.state.sectionData2.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData2.data[0]?.attributes?.sub_title,
                }}
              />
            )}
          </Typography>
        </>
      );
    };
    sectionTypoThree = () => {
      return (
        <>
          <ThirdContainerTypography>
            {this.state.sectionData3.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData3.data[0]?.attributes?.title,
                }}
              />
            )}
          </ThirdContainerTypography>
          <Typography style={styles.unitText}>
            {this.state.sectionData3.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData3.data[0]?.attributes?.sub_title,
                }}
              />
            )}
          </Typography>
        </>
      );
    };
    sectionTypoFour = () => {
      return (
        <>
          <FourthContainerTypography>
            {this.state.sectionData4.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData4.data[0]?.attributes?.title,
                }}
              />
            )}
          </FourthContainerTypography>
          <FourthContainerTypographyTwo>
            {this.state.sectionData4.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData4.data[0]?.attributes?.sub_title,
                }}
              />
            )}
          </FourthContainerTypographyTwo>
        </>
      );
    };
    sectionTypoFive = () => {
      return (
        <>
          <FiveContainerTypography>
            {this.state.sectionData5.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData5.data[0]?.attributes?.title,
                }}
              />
            )}
          </FiveContainerTypography>
          <FiveContainerTypographyTwo>
            {this.state.sectionData5.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData5.data[0]?.attributes?.sub_title,
                }}
              />
            )}
          </FiveContainerTypographyTwo>
        </>
      );
    };

    sectionTypeSix = () =>{
      return(
        <>
        <SixContainerTypography
        style={{
          maxWidth: "912px",
        }}
      >
        {this.state.sectionData6.data && (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.sectionData6?.data[0]?.attributes
                ?.title,
            }}
          />
        )}
      </SixContainerTypography>
      <SixContainerTypographyTwo style={{ maxWidth: "531px" }}>
        {this.state.sectionData6.data && (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.sectionData6?.data[0]?.attributes
                ?.sub_title,
            }}
          />
        )}
      </SixContainerTypographyTwo>
      </>
      )
    }
    
    sectionTypoPartOne = () => {
      return (
        <>
          <FirstContainerTypography>
            {this.state.sectionData1.data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.sectionData1.data[0]?.attributes?.title,
                }}
              />
            )}
          </FirstContainerTypography>
          <Box>
            <FirstContainerTypographyTwo>
              {this.state.sectionData1.data && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.sectionData1.data[0]?.attributes
                      ?.sub_title,
                  }}
                />
              )}
            </FirstContainerTypographyTwo>
          </Box>
        </>
      );
    };

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
            <NavBar {...this.props} />
            <Grid container>


              <Grid item xs={12} style={styles.mainFirstContainer}>
                <Grid container style={{alignItems: 'center'}}>
                  <Grid item sm={6} md={6} style={styles.inventoContainer}>
                    {this.sectionTypoPartOne()}
                    <Box>
                      <TextField
                        id="search"
                        data-testid="search"
                        variant="outlined"
                        size="small"
                        color="primary"
                        autoComplete="off"
                        value={this.state.search}
                        placeholder={config.searchPlaceholder}
                        onChange={this.handleSearch}
                        onKeyDown={this.keyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon color="primary" />
                            </InputAdornment>

                          ),
                          endAdornment: (
                            <Select
                            data-testid="select-ip"
                            value={this.state.searchSelect}
                            onChange={this.handleSelectChange}
                            variant="outlined"
                            placeholder="Select Country"
                            style={styles.selectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                            inputProps={{
                              style: styles.selectInputProps,
                            }}
                          >
                            <MenuItem value="IP Talent">{config.ipTalent}</MenuItem>
                            <MenuItem value="Jobs">{config.ipJobs}</MenuItem>
                          </Select>
                          ),
                          style: styles.inputPropsStyle,
                        }}
                      />
                    </Box>
                  
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <img
                      src={
                        this.state.sectionData1.data &&
                        this.state.sectionData1.data[0]?.attributes?.image
                      }
                      style={styles.landingImage}
                    />
                  </Grid>
                </Grid>
              </Grid>
    
    
              <Grid item xs={12}>
                <SecondContainerBox>
                  {this.sectionTypoTwo()}
                  <Box style={{  maxWidth: 1418,
  width: '100%',
  margin: 'auto',
  position: 'relative',}}>
    <img src={dots} style={{position:"absolute",left:0,top:-45}}/>
    <img src={dots} style={{position:"absolute",right:0,bottom:-45}}/>
                  <Box style={styles.facilityOuterBox}>
                    {this.state.sectionData2?.data?.[0]?.attributes?.content_cards?.map(
                      (card: {
                        title:string;
                        sub_title:string
                      }, index: number) => (
                          <Card style={styles.cardContainer2}>
                            <CardContent>
                              <Box style={styles.imageOuterBox}>
                                <img
                                  src={this.imageUrls[index]}
                                  alt={`Image ${index + 1}`}
                                  style={styles.facilityImage}
                                />
                              </Box>
                              <Typography style={styles.cardHeading}>
                                {card.title}
                              </Typography>
                              <Typography style={styles.cardTitle}>
                                {card.sub_title}
                              </Typography>
                            </CardContent>
                          </Card>
                      )
                    )}
                  </Box>
                  </Box>
                </SecondContainerBox>
              </Grid>
    
    
              <Grid item xs={12}>
              <ThirdContainerBox>
  {this.sectionTypoThree()}
  <Grid container>
    {this.state.sectionData3?.data?.[0]?.attributes?.content_cards?.map(
      (card: {
        title:string;
        sub_title:string
      }, index: number) => {
        const imageWidths = ['55px', '60px', '60px', '60px','45px','60px','45px','70px']; 

        return (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            key={index}
            style={styles.cardOuterContainer}
          >
            <Box style={styles.categoryCard}>
              <img
                src={this.imageURLS[index]}
                alt={`Image ${index + 1}`}
                style={{ ...styles.categoryImg, width: imageWidths[index] }} 
              />
              <Typography style={styles.categoryTitle}>
                {card?.title}
              </Typography>
              <Typography style={styles.categoryDetail}>
                {card?.sub_title}
              </Typography>
            </Box>
          </Grid>
        );
      }
    )}
  </Grid>
</ThirdContainerBox>

              </Grid>
    
              <FourthContainerGrid item xs={12}>
                <Box style={styles.contractBox}>
                  <div style={{display: 'flex', gap: 140, alignItems: 'center'}}>
                    <div>
                      <div>
                        <img
                          src={
                            this.state.sectionData4.data &&
                            this.state.sectionData4?.data[0]?.attributes?.image
                          }
                          alt="open-to-contact"
                          style={styles.contractImg1}
                        />
                      </div>
                    </div>
                    <div>
                      {this.sectionTypoFour()}
                      <Box style={styles.opportunityButton}>
                        <Button
                          variant="contained"
                          style={styles.startHereBtn}
                          onClick={() =>
                            window.open(this.primaryButtonUrl(), "_blank")
                          }
                          data-test-id="ProfessionalAccountRegistration"
                        >
                          {this.primaryButtonText()}
                        </Button>
                        <Button
                          variant="outlined"
                          style={styles.learnBtn}
                          onClick={() =>
                            window.open(this.secondaryButtonUrl(), "_blank")
                          }
                          data-test-id="ContentManagement"
                        >
                          {this.secondaryButtonText()}
                        </Button>
                      </Box>
                    </div>
                  </div>
                  <img src={line} alt="line" style={styles.lineImg} />
                </Box>
              </FourthContainerGrid>
    
              <Grid item xs={12} style={styles.fifthMainContainer}>
                <Box>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={5} md={5}>
                      <Typography style={styles.forClient}>
                        {config.forClients}
                      </Typography>
                      {this.sectionTypoFive()}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <img
                        src={
                          this.state.sectionData5.data &&
                          this.state.sectionData5?.data[0]?.attributes?.image
                        }
                        alt="city-wise-professioal"
                        style={styles.professionalImg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    {this.state.sectionData5?.data?.[0]?.attributes?.content_cards?.map(
                      (card: {
                        title:string;
                        sub_title:string
                      }, index: number) => (
                        <Grid
                          item
                          xs={2}
                          key={index}
                          style={styles.displayProfessionals}
                        >
                          <FiveContainerImageTypography >
                            {card?.title}
                          </FiveContainerImageTypography>
                          <img src={arrow} alt="choose" style={styles.arrowImg} />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Grid>
    
              <Grid item xs={12} style={styles.fifthMainContainer}>
                <Box>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} md={4}>
                      <img
                        src={
                          this.state.sectionData6.data &&
                          this.state.sectionData6?.data[0]?.attributes?.image
                        }
                        alt="empowering"
                        style={styles.professionalImg}
                      />
                    </Grid>
                    <Grid item xs={8} md={8} style={styles.ipMarketPlace}>
                      <Box style={styles.forFreelancerBox}>
                        <Typography style={styles.forClient}>
                          {config.forFreelancer}
                        </Typography>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        {this.sectionTypeSix()}
                     
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    {this.state.sectionData6?.data?.[0]?.attributes?.content_cards?.map(
                      (card: {
                        title:string;
                        sub_title:string
                      }, index: number) => (
                        <Grid
                          item
                          xs={2}
                          key={index}
                          style={styles.displayProfessionals}
                        >
                          <FiveContainerImageTypography2>
                            {card?.title}
                          </FiveContainerImageTypography2>
                          <img src={arrow} alt="choose" style={styles.arrowImg} />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Grid>
    
              <SevenContainerTypographyGrid item xs={12}>
                <Card style={styles.videoCardContainer}>
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <CustomGrid item xs={12} md={5}>
                        <Typography style={styles.forClient}>
                          {config.ipProfessional}
                        </Typography>
                        <SevenContainerTypography>
                          {this.state.sectionData7.data && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.sectionData7?.data[0]?.attributes
                                  ?.title,
                              }}
                            />
                          )}
                        </SevenContainerTypography>
                        <SevenContainerTypographyTwo>
                          {this.state.sectionData7.data && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.sectionData7?.data[0]?.attributes
                                  ?.sub_title,
                              }}
                            />
                          )}
                        </SevenContainerTypographyTwo>
                      </CustomGrid>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                       

                      <CarouselGrid
                      item
                      xs={12}
                      md={7}
                      style={{ display: "flex", justifyContent: "center" }}> 
                      <Carousel
    animation="slide"
    fullHeightHover={false}
    navButtonsAlwaysVisible={true}
    autoPlay={false}
    navButtonsProps={{
      style: styles.navButton,
    }}
    navButtonsWrapperProps={{
      style: styles.navBtnWrapper,
    }}
    indicatorIconButtonProps={{
      style: styles.indicatoreIcon,
    }}
  >
    {this.state.sectionData7.data &&
      this.state.sectionData7.data.map((item:any, index: any) => (
        <video key={index} style={styles.videoImg} autoPlay loop>
          <source src={item?.attributes?.image_media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}
  </Carousel>
</CarouselGrid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </SevenContainerTypographyGrid>
    
              <Grid item xs={12} style={styles.sevenMainContainer}>
                <EightContainerBox>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <EightContainerTypography>
                        {this.state.sectionData8.data && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.sectionData8?.data[0]?.attributes
                                ?.title,
                            }}
                          />
                        )}
                      </EightContainerTypography>
                      <EightContainerTypographyTwo>
                        {this.state.sectionData8.data && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.sectionData8?.data[0]?.attributes
                                ?.sub_title,
                            }}
                          />
                        )}
                      </EightContainerTypographyTwo>
                      <EightContainerBoxTwo>
                        <Button
                          variant="contained"
                          style={styles.startHereBtn}
                          onClick={() =>
                            window.open(this.primaryButtonUrlTwo(), "_blank")
                          }
                          data-test-id="ProfessionalAccountRegistration2"
                        >
                          {this.primaryButtonTextTwo()}
                        </Button>
                        <Button
                          variant="outlined"
                          style={styles.learnBtn}
                          onClick={() =>
                            window.open(this.secondaryButtonUrlTwo(), "_blank")
                          }
                          data-test-id="ContentManagement2"
                        >
                          {this.secondaryButtonTextTwo()}
                        </Button>
                      </EightContainerBoxTwo>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={styles.meetingImgBox}>
                      <img
                        src={
                          this.state.sectionData8.data &&
                          this.state.sectionData8?.data[0]?.attributes?.image
                        }
                        alt="open-to-contact"
                        style={styles.contractImg}
                      />
                    </Grid>
                    <img src={meetingLine} alt="line" style={styles.meetingLine} />
                  </Grid>
                </EightContainerBox>
              </Grid>
    
              <Grid item xs={12}>
                <Box style={styles.inventoFamilyBox}>
                  <NineContainerTypography>
                    {this.state.sectionData9.data && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.sectionData9?.data[0]?.attributes
                            ?.title,
                        }}
                      />
                    )}
                  </NineContainerTypography>
                  <NineContainerTypographyTwo>
                    {this.state.sectionData9.data && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.sectionData9?.data[0]?.attributes
                            ?.sub_title,
                        }}
                      />
                    )}
                  </NineContainerTypographyTwo>
                  <Card style={styles.feedbackCard}>
                    <CardContent style={styles.feedbackCardContent}>
                      <Carousel
                        NextIcon={
                          <img src={rightArrow} style={styles.curouselArrow} />
                        }
                        PrevIcon={
                          <img src={leftArrow} style={styles.curouselArrow} />
                        }
                        animation="slide"
                        fullHeightHover={false}
                        navButtonsAlwaysVisible={true}
                        autoPlay={false}
                        navButtonsProps={{
                          style: styles.navButton,
                        }}
                        navButtonsWrapperProps={{
                          style: styles.navBtnFeedback,
                        }}
                        indicatorIconButtonProps={{
                          style: styles.indicatorIcon,
                        }}
                        activeIndicatorIconButtonProps={{
                          style: styles.activeIndicatorIcon,
                        }}
                        indicatorContainerProps={{
                          style: styles.indicatorContainer,
                        }}
                      >
                        {this.state.sectionData9?.data && this.state.sectionData9?.data[0]?.attributes?.testmonials?.data?.map(
                          (testimonial:
                            {
                              attributes:{  author_name:string,
                            author_role:string,
                            author_profile_image:string,
                            review: string}
                          } , index: number) => {
                           return(
                           <Grid
                              key={index}
                              container
                              justifyContent="center"
                              alignItems="center"
                              style={styles.feedbackInnerContainer}
                            >
                              <Grid item sm={12} md={4}>
                                <Box style={styles.inventoMemberBox}>
                                  <img
                                    src={
                                      testimonial.attributes.author_profile_image
                                    }
                                    style={styles.userProfileImg}
                                    alt="User Profile"
                                  />
                                  <Typography style={styles.userName}>
                                    {testimonial.attributes.author_name}
                                  </Typography>
                                  <Typography style={styles.userPosition}>
                                    {testimonial.attributes.author_role}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={7}>
                                <Typography style={styles.feedbakDescription}>
                                  {testimonial.attributes.review}
                                </Typography>
                              </Grid>
                            </Grid>
                           )
                          }
                        )}
                      </Carousel>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} style={styles.fifthMainContainer}>
                <Box style={styles.emailMainContainer}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={styles.emailMainBox}
                  >
                    <Grid item sm={1}>
                      <img
                        src={combinedShape}
                        alt="combinedShape"
                        style={styles.combinedShape}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} style={styles.meetingImgBox}>
                      <Typography style={styles.joinInvento}>
                        {config.joinInvento}
                      </Typography>
                      <Typography style={styles.emailDetails}>
                        {config.emailDetails}
                      </Typography>
                      <Box>
                        <TextField
                          id="emailid"
                          data-testid="email-id"
                          variant="outlined"
                          size="small"
                          value={this.state.subscribersEmail}
                          placeholder={config.emailPlaceholder}
                          
                          onChange={this.handleEmailChange}
                          helperText={this.state.errorMessage}
                          FormHelperTextProps={{ style: styles.helperTextStyle}}
                          InputProps={{
                            endAdornment: (
                              <Button
                                variant="contained"
                                color="primary"
                                style={styles.sendButton}
                                endIcon={
                                  <img
                                    src={sendIcon}
                                    alt="send"
                                    style={styles.sendIcon}
                                  />
                                }
                                id="handleSubscribersEmail"
                                onClick={this.handleSubscribersEmail}
                              >
                                {config.subscribe}
                              </Button>
                            ),
                            style: styles.inputEmailStyle,
                            inputProps: {
                              maxLength: 200
                            }
                          }}
                        />
                        {this.state.successMessage &&  (
                         <Typography variant="body1" style={{ color: "aliceblue" ,fontSize:"12px"}}>
                         {this.state.successMessage}
                          </Typography>
                            )}
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={4} style={styles.ipMarketPlace}>
                      <img
                        src={emailToJoin}
                        alt="email-to-join"
                        style={styles.joinMailImg}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FooterWeb />
              </Grid>
            </Grid>
          </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
  mainFirstContainer: {
    background: "#E6EBEF",
    padding: "20px",
    paddingBottom: 100
  },
  inventoContainer: {
    padding: "4% 0px 3% 5%",
  },
  helperTextStyle:{
   color:"aliceblue"
  },

  selectBox: {
    width: "70%",
    heigth:"46px",
    background:"#206FC4",
    color: "#fff",
   " &.MuiMenu-paper":{
      height: "88px !important" 
    }
  },
  popularBox: {
    display: "flex",
    gap: "5px",
    margin: "10px",
    alignItems: "center",
  },
  popularText: {
    color: "#206FC4",
  },
  IpText: {
    color: "#206FC4",
    border: "1px solid #206FC4",
    borderRadius: "20px",
    padding: "5px 15px",
  },
  landingImage: {
    width: "100%",
  },
  inputPropsStyle: {
    borderRadius: "8px",
    border: "1px solid #206FC4",
    color: "#206FC4",
    padding: "2px 2px 2px 8px",
    margin:"10px"
  },
  inputEmailStyle: {
    borderRadius: "8px",
    background: "#fff",
    color: "#206FC4",
    padding: "2px 4px",
    textAlign: "center" as "center",
    "&::placeholder": {
      padding: "13px",
    },
    "&.MuiFormHelperText-root": {
      color: "#fff !important",
    },
  },
  selectInputProps: {
    borderRadius: "8px",
    background: "#206FC4",
    color: "#fff",
    border: "1px solid #206FC4",
  },

  unitText: {
    margin: "2px 0",
    fontSize: "1.25rem",
    color: "#002345",
  },
  facilityImage: {
    objectFit:"contain"  as "contain",
    height: 'inherit',
    width: 'inherit',
  },
  imageOuterBox: {
    background: "#fdfdfd",
    borderRadius: "50%",
    padding: "10px",
    boxShadow: "0px 5px 14px 0px #1845A840",
    width: "70px",
    margin: "auto",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer2: {
    boxShadow: "0px 5px 14px 0px #1845A840",
    borderRadius: "15px",
    maxWidth: "312px",
    width:"100%",
    padding:"50px 36px 63px"
  },
  cardHeading: {
    margin: "35px 0 10px",
    fontSize: "30px",
    color: "#206FC4",
    fontWeight: 600,
  },
  cardTitle: {
    fontSize: "18px",
    lineHeight: "inherit",
    minHeight: "88px"
  },
  facilityOuterBox: {
    position: "relative" as "relative",
    margin: 'auto',
    marginTop: "69px",
    display: 'flex' as 'flex',
    gap: 75,
    justifyContent: 'space-between' as 'space-between',
    maxWidth: 1298,
  },
  dotImageBox: {
    position: "absolute" as "absolute",
    left: "127px",
    zIndex: -1,
  },
  dotImageBoxTwo: {
    position: "absolute" as "absolute",
    zIndex: -1,
    right: "127px",
  },
  dotImage: {
    width: "80px",
  },
  categoryCard: {
    background: "#F0F7FF",
    border: "0.3px solid #BCDCFF",
    borderRadius: "20px",
    padding: "12px",
    overflow: "hidden",
    height: "92%",
  },
  categoryImg: {
    width: "45px",
    marginTop: "20px",
  },
  categoryTitle: {
    color: "#206FC4",
    fontSize: "1.5rem",
    margin: "20px 0 5px",
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  categoryDetail: {
    fontSize: "14px",
  },
  cardOuterContainer: {
    padding: "1% 1% 1% 0",
  },
  fifthMainContainer: {
    padding: "3% 7% 7%",
  },
  sevenMainContainer: {
    padding: "0% 7% 4%",
  },
  contractBox: {
    background: "#206FC4",
    borderRadius: "15px",
    padding: "6% 6%",
    position: "relative" as "relative",
  },
  contractBoxTwo: {
    background: "#206FC4",
    borderRadius: "15px",
    padding: "4% 8%",
    position: "relative" as "relative",
  },
  emailMainContainer: {
    background: "#206FC4",
    borderRadius: "15px",
    padding: "4% 2%",
    position: "relative" as "relative",
  },
  contractImg: {
    width: "60%",
    height: "100%",
  },
  contractImg1: {
    width: "100%",
    height: "100%",
  },
  unlockIp: {
    fontSize: "44px",
    color: "#fff",
    fontWeight: 600,
  },
  joinInvento: {
    fontSize: "2rem",
    color: "#fff",
    fontWeight: 600,
  },
  opportunity: {
    fontSize: "20px",
    color: "#fff",
  },
  opportunityButton: {
    margin: "3% 0",
    display: "flex",
    gap: "10px",
  },
  startHereBtn: {
    color: "#206FC4",
    borderRadius: "10px",
    padding: "7px 30px",
    fontSize: "1.12rem",
    fontWeight: 500,
    background: "#fff",
  },
  learnBtn: {
    color: "#fff",
    borderColor: "#fff",
    borderRadius: "10px",
    fontSize: "1.12rem",
    fontWeight: 500,
    padding: "7px 20px",
  },
  lineImg: {
    position: "absolute" as "absolute",
    width: "146px",
    right: "0",
    bottom: "0",
  },
  forClient: {
    background: "#E5F2FF",
    padding: "4px 20px",
    borderRadius: "5px",
    color: "#206FC4",
    fontWeight: 500,
    fontSize: "1.5rem",
    width: "max-content",
  },
  professionalRegion: {
    fontSize: "48px",
    fontWeight: 600,
    color: "#002345",
    margin: "15px 0",
    width: "max-content",
  },

  professionalDetail: {
    color: "#002345",
    fontSize: "17px",
  },

  professionalImg: {
    width: "100%",
    height: "100%",
  },

  displayProfessionals: {
    background: "#206FC4",
    borderRadius: "10px",
    position: "relative" as "relative",
    width: "300px",
    height: "168px",
    padding: "20px",
  },
  professionalText: {
    color: "#fff",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  arrowImg: {
    position: "absolute" as "absolute",
    right: "-8%",
    bottom: "41px",
  },
  ipMarketPlace: {
    textAlign: "end" as "end",
  },
  videoCardContainer: {
    borderRadius: "10px",
    boxShadow: "1px 1px 6px 2px #d8dcdd",
    padding: "30px",
  },
  curouselArrow: {
    width: "27px",
  },
  curouselRightArrow: {
    width: "35px",
    position: "relative" as "relative",
    right: "145px",
  },
  curouselLeftArrow: {
    width: "35px",
    position: "relative" as "relative",
    left: "145px",
  },
  navButton: {
    backgroundColor: "transparent",
  },
  navBtnWrapper: {
    bottom: "0",
    top: "unset",
    height: "auto",
  },
  navBtnFeedback: {
    bottom: "0",
    top: "unset",
    height: "auto",
  },
  indicatoreIcon: {
    display: "none",
  },
  videoImg: {
    width: "100%",
    paddingBottom: "10px",
    maxWidth: "785px",
    maxHeight: "460px",
    borderRadius:"42px"
  },
  meetingImgBox: {
    textAlign: "end" as "end",
  },
  meetingLine: {
    position: "absolute" as "absolute",
    width: "145px",
    right: "-72px",
    bottom: "28%",
    zIndex: -1,
  },
  indicatorIcon: {
    color: "#ECEBED",
  },
  activeIndicatorIcon: {
    color: "#206FC4",
  },
  indicatorContainer: {
    position: "relative" as "relative",
    bottom: "40px",
    right: "77px",
  },
  inventoFamilyBox: {
    padding: "0 25% 8%",
  },
  feedbackHeading: {
    fontSize: "45px",
    color: "#002345",
    fontWeight: 600,
    textAlign: "center" as "center",
  },
  feedbackDetails: {
    fontSize: "16px",
    color: "#002345",
    textAlign: "center" as "center",
    padding: "0 1% 0 1%",
  },
  feedbackCard: {
    borderRadius: "15px",
    boxShadow: "0px -1px 44px 0px #849CAD42",
  },
  feedbackCardContent: {
    padding: "3%",
  },
  inventoMemberBox: {
    textAlign: "center" as "center",
  },
  userProfileImg: {
    width: "120px",
    height: "120px",
    borderRadius:"100%",
    border:"5px solid #005CDA80"
  },
  userName: {
    color: "#2C2B2B",
    fontSize: "1rem",
  },
  userPosition: {
    fontSize: "1rem",
    color: "#005CDA",
  },
  feedbakDescription: {
    fontSize: "1.1rem",
    fontStyle: "italic",
    color: "#252D3B",
  },
  feedbackInnerContainer: {
    paddingBottom: "45px",
  },
  combinedShape: {
    width: "15%",
    height: "66%",
    position: "absolute" as "absolute",
    bottom: "0",
    left: "0",
  },
  emailDetails: {
    fontSize: "1rem",
    color: "#fff",
    marginBottom: "20px",
  },
  sendButton: {
    width: "150px",
    padding: "10px 40px",
  },
  sendIcon: {
    width: "12px",
  },
  joinMailImg: {
    width: "80%",
  },
  forFreelancerBox: {
    display: "block ruby",
  },
  emailMainBox: {
    alignItems: "center",
  },
};
// Customizable Area End

