import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData,getStorageData} from "../../../framework/src/Utilities"
import storage from "framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string;
  jobId:number;
  jobDetails:any
  activityData:{label:string,value:string}[]
  clientData:{label:string,value:string}[]

  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SendProposalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchCurrentProposalsJobDetailsId: string

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.fetchCurrentProposalsJobDetailsId=""
    this.state = {
      authToken: "",
      jobId: 0,
      jobDetails:{},
       activityData:[
        { label: 'Proposals', value: 'proposals' },
        { label: 'Last viewed by client', value: 'lastViewedByClient' },
        { label: 'Interviewing', value: 'interviewing' },
        { label: 'Invites send', value: 'invitesSend' },
        { label: 'Unanswered Invites', value: 'unansweredInvites' }
      ],
      
     clientData : [
        { label: 'Jobs Posted', value: 'jobsPosted' },
        { label: 'Total Client', value: 'totalClient' },
        { label: 'Avg hourly rate paid', value: 'avgHourlyRate' },
        { label: 'Invites send', value: 'invitesSend' },
        { label: 'Unanswered Invites', value: 'unansweredInvites' }
      ]
    };
   
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')   
    });
    this.setState({jobId:await storage.get('currentProposalJobId')});
    this.fetchCurrentProposalsJobDetails()
  
    // Customizable Area End
  }

  // Customizable Area Start
  handleApply = () =>{
    this.props.navigation.navigate("SubmitProposals")
  }
 
  

  // Web Event Handling
  fetchCurrentProposalsJobDetails = () => {
    
    const fetchCurrentProposalsJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      "token": this.state.authToken    
    };
    
    this.fetchCurrentProposalsJobDetailsId = fetchCurrentProposalsJobDetails.messageId;
    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCurrentProposalsJobDetailsEndPoint+`${this.state.jobId}`
    );
    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(fetchCurrentProposalsJobDetails.id, fetchCurrentProposalsJobDetails);
  };
  
  // Customizable Area End

  // Customizable Area Start
  async receive(form:string,message:Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.fetchCurrentProposalsJobDetailsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({ jobDetails: responseJson.data.attributes });
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
    
    // Customizable Area End
  }
  // Customizable Area End
}
