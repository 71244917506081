import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  emailAddress: string;
  stripeAccount: string;
  IFCICode: string;
  error: boolean;
  validationError: boolean;
  errorMessage: string;
  // Customizable Area End
}
interface SS { }

export default class EnterUserDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      emailAddress: '',
      stripeAccount: '',
      IFCICode: '',
      error: false,
      validationError: false,
      errorMessage: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    // Customizable Area End
  }
  // Customizable Area Start
  handleEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailAddress: event.target.value })
  }
  handleStripeAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ stripeAccount: event.target.value })
  }
  handleIFCICode = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ IFCICode: event.target.value })
  }
  handleAccount = () => {
    if (!this.state.emailAddress || !this.state.stripeAccount || !this.state.IFCICode) {
      this.setState({ error: true, errorMessage: 'Required' })
    } else if(!this.emailReg.test(this.state.emailAddress)){
      this.setState({ errorMessage: 'Invalid Email' })
    } else {
      //set stipe account
      this.setState({ error: false, errorMessage: '' })
      const paymentmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
      paymentmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PaymentSuccessful'
      );
      paymentmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(paymentmessage);
      runEngine.sendMessage(paymentmessage.id, paymentmessage)
    }
  }
  // Customizable Area End
}
