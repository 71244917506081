import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "framework/src/StorageProvider";
import { setStorageData } from "../../../framework/src/Utilities";

export interface JobListData 
{
  
            "id": number,
            "type": string,
            "attributes": {
              "name": string,
              "description": string,
              "id": string,
              "job_title": string,
              "body": string,
              "budget": string,
              "job_description": string,
              "duration": string,
              "per_day_charge": number,
              "estimate": string,
              "employment_term": string,
              "location": string,
                "skills":string[],
                "file": {
                    "url": string | null
                },
                "created_at": string,
                "account_id": number,
                "updated_at":string,
                "images_and_videos": any[]
                "proposals": number,
                "messaged": number,
                "is_drafted": boolean,
                "model_name": string,
                "hired": number,
            },
            "total_count":number
      
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  jobListData: JobListData [],
  authToken: string,
  selectedJobId: string | number, 
  deletePopUp: boolean;
  deleteMessage: string;
  activeJobId: string | number;
  snackbarOpen: boolean;
  snackbarMessage: string;
  searchJob:string
  loading:boolean
  currentPage:number;
  totalPages:number;
  noJobsFound: boolean
  // Customizable Area End
}
interface SS { }

export default class JobPostingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  reuseThisJobsId: string = "";
  apiGetAllPostDataId: string = "";
  apiDeletePostedDataId: string = "";
  apiSearchJobsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      anchorEl: null,
      searchJob:"",
      authToken: "",
      jobListData: [],
      selectedJobId: 0, 
      deletePopUp: false,
      deleteMessage: "",
      activeJobId:  0,
      snackbarOpen: false,
      snackbarMessage: "",
      loading:true,
      currentPage:1,
      totalPages:0,
      noJobsFound: false  
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.handleSearchJob()
    setTimeout(() => {
      this.setState({ loading: false });
  }, 2000);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webResponseJson) {
        if (this.apiSearchJobsId === webApiRequestCallId) {
          const searchResults = webResponseJson.posts?.data || [];
          this.setState({
            jobListData: searchResults,
            totalPages: Math.ceil(webResponseJson.total_count / 10), 
            noJobsFound: searchResults.length === 0,
          });
        } else if (this.reuseThisJobsId === webApiRequestCallId) {
          const jobId = this.state.selectedJobId;
          this.setState({ loading: false }, () => {
            this.props.navigation.navigate('JobReviewPage', { jobId });
          });
        } else if (this.apiDeletePostedDataId === webApiRequestCallId) {
          this.setState((prevState) => ({
            jobListData: prevState.jobListData.filter(job => job.id !== this.state.activeJobId),
            activeJobId: '',
            deletePopUp: false,
            loading: false
          }), () => {
            this.showAlert("Job posting deleted successfully");
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage){
      this.handleSearchJob();
    }
  }
  handlePageChange = (_event: object, page: number) => {
    this.setState({currentPage: page});  
  };
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleDeleteJob = (jobId: string | number) => {
    this.setState({ activeJobId: jobId, deletePopUp: true });
    this.handleClose();
  };

  confirmDeleteJob = () => {
    this.deletePostedData(this.state.activeJobId);
  };

  cancelDeleteJob = () => {
    this.setState({ deletePopUp: false, activeJobId: '' });
  };

  showAlert = (message: string) => {
    this.setState({ snackbarOpen: true, snackbarMessage: message });
  };

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  };
  handleEditPosting = (id:any) => {
    setStorageData("jobId", id)
    const viewProposal: Message = new Message(getName(MessageEnum.NavigationMessage))
    viewProposal.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobReviewPage'
    );
    viewProposal.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(viewProposal);
  };
  
  handleJobPostDescription = () => {
    const viewProposal: Message = new Message(getName(MessageEnum.NavigationMessage))
    viewProposal.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobPostDescription'
    );
    viewProposal.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(viewProposal);
  }

  handleViewJob = (id: any) => {
    setStorageData("jobId", id)
    const viewProposal: Message = new Message(getName(MessageEnum.NavigationMessage))
    viewProposal.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ViewJob'
    );
    viewProposal.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(viewProposal);
    
  }
  handleClick = (event: React.MouseEvent<HTMLElement>,id:number) => {
    this.setState({ anchorEl: event.currentTarget, selectedJobId: id,});
};
handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const searchTerm = event.target.value;
  this.setState({ searchJob: searchTerm }, () => {
    this.handleSearchJob();
  });
};


handleClickSearch = () => {
  this.handleSearchJob(); 
};
handleReusethisJobs = (id: any) => {
  setStorageData("jobId", id);
  this.reusethisJobsData(id);
  this.handleClose();
};
handleSearchJob = () => {
  const searchTerm = this.state.searchJob;
  const page = this.state.currentPage; 
  const header = {
    "Content-Type": webConfigJSON.dashboarContentType,
    "token": this.state.authToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiSearchJobsId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_posts/posts/search_jobs?name=${searchTerm}&page=${page}&per_page=10`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  deletePostedData = async (jobId: string | number) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletePostedDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${jobId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  reusethisJobsData = async (jobId: string | number) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reuseThisJobsId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_posts/posts/${jobId}/reuse_jobs`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
