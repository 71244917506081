import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onStepChange:(stepChange:number)=>void
  getPerDayChargeSet:(value:number)=>void
  getBudgetSet:(value:string)=>void
  budgetTerm?:string
  perDayTerm?:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  budgetRate: string;
  dayCharge: number;
  error: boolean;
  isHovered: boolean
  showAverageRateMessage: boolean
  inputValue:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BudgetRangeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      budgetRate: "perDay",
      dayCharge: parseInt(localStorage.getItem("dayCharge") || ""),
      error: false,
      isHovered:false,
      inputValue: props.perDayTerm || "",
      showAverageRateMessage:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleInputChange = (event: any) => {
    const inputValue = event.target.value.trim();
    this.setState({ inputValue , error: false });
    this.props.getPerDayChargeSet(inputValue);
    if (inputValue !== "") {
      this.setState({ showAverageRateMessage: true });
    } else {
      this.setState({ showAverageRateMessage: false });
    }
  };
  
  handleBudget = (rate: string) => {
    this.setState({ budgetRate: rate });
  };

  handleChange = (value: number) => {
    this.setState({ dayCharge: value });
  };

  handleNext = () => {
    const { budgetRate, dayCharge, inputValue } = this.state;

    if (budgetRate === "" || dayCharge === 0 || inputValue === "") {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
      this.props.onStepChange(1);
    }
  };
  
  handleBudgetBack=()=>{
    this.props.onStepChange(-1)
  }
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift"];
    const allowedCharacters = /^[0-9.\b]+$/; 
  
    if (!allowedKeys.includes(event.key) && !allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  };
  handleHover = () => {
    this.setState({ isHovered: true });
  };
  
  handleLeave = () => {
    this.setState({ isHovered: false });
  };
  // Customizable Area End

  async receive() {
    // Customizable Area Start
    // Customizable Area End
  }
}
