import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import { ChangeEvent } from "react";

export interface JobListData 
{
  
            "id": number,
            "type": string,
            "attributes": {
              "name": string,
              "description": string,
              "id": string,
              "job_title": string,
              "body": string,
              "budget": string,
              "job_description": string,
              "duration": string,
              "per_day_charge": number,
              "estimate": string,
              "employment_term": string,
              "location": string,
                "skills": [
                  string
                ],
                "file": {
                    "url": string | null
                },
                "created_at": string,
                "account_id": number,
                "updated_at":string,
                "images_and_videos": any[]
                "proposals": number,
                "messaged": number,
                "is_drafted": boolean,
                "model_name": string,
                "hired": number,
            }
      
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  errorMessage:string,
  jobListData: JobListData [],
  recentOption: string;
  selectedOption: string;
  search: string;
  searchedList: string[];
  anchorEl: null,
  experienceList: {
    id: number,
    value: string,
    label: string,
    count: string,
    min: number,
    max: number
  }[];
  selectedOptions: string[];
  proposalSelected: string[];
  minRange: string;
  maxRange: string;
  hoursSelected: string[];
  proposalList: {
    id: number,
    value: string,
    minRange: number,
    maxRange: number,
    label: string,
  }[];
  hourlyRateList: {
    id: number,
    value: string,
    minRange: number,
    maxRange: number,
    label: string,
  }[];
  minHours: string,
  maxHours: string,
  clearInfoList: {
    id: number,
    value: string,
    label: string,
    count: string,
  }[];
  currentPage: number;
  totalPages:number;
  authToken: string;
  accountId:string
  searchedResult: {
    id: string,
    type: string,
    attributes: {
      freelancers: {
        full_name: string,
        user_name: string,
        job_title: string,
        education: string,
        aadhar_img: {
          url: string
        },
        user_status: boolean,
        talent_saved: boolean,
        skills: string[],
        hour_rate: string,
        description: string,
        country: string
      }
      profile_image:string
    }
  }[];
  totalFreelancer: {
    id: string,
    type: string,
    attributes: {
      freelancers: {
        full_name: string,
        user_name: string,
        job_title: string,
        education: string,
        aadhar_img: {
          url: string
        },
        user_status: boolean,
        talent_saved: boolean,
        skills: string[],
        hour_rate: string,
        description: string,
        country: string
      }
      profile_image:string
    }
  }[];
  showMore: boolean;
  userName: string;
  perDayRate: string;
  earnedPoint: string;
  progressValue: number;
  profileSummery: string;
  profileDetail: string;
  languages: string[]
  isModalOpen: boolean;
  inviteNotification:boolean;
  invitationSent:string
  invitationUnsent:string
  post_id:string;
  clientProfileData:{data:{
    id: string,
    type: string,
  attributes: {
    id: number,
    email: string,
    country: string,
    user_name: null,
    first_name: string,
    job_title: string,
    phone_number: null,
    full_phone_number: null,
    total_experience: null,
    activated: boolean,
    degree_img: {
      url: null
    },
    aadhar_img: {
        url: null
    },
    passport_img: {
        url: null
    },
    profile_image: string,
    work_history: null,
    skills: any[],
    unique_auth_id: string,
    address: null,
    created_at: string,
    updated_at: string,
    full_name: string,
    status: string,
    stripe_account_id: null,
    stripe_account_onboard_url: null,
    stripe_is_charges_enabled: boolean,
    stripe_is_onboard_completed: boolean,
    stripe_is_details_submitted: boolean,
    work_experiences: any[]
}}},
accountHolderName:string
selectedJob: string
searchedValue:string
  // Customizable Area End
}
interface SS { }

export default class SearchTalentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  displySearchedProfile: string;
  displyFilteredValue: string;
  saveLaterProfileId: string;
  previousClientsId: string;
  experienceLevelId: string;
  addTalentInSaveApiCallId: string;
  SendInvitationId:string;
  talentProfileId:string;
  apiGetAllPostDataId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.displySearchedProfile = "";
    this.displyFilteredValue = "";
    this.saveLaterProfileId = "";
    this.previousClientsId = "";
    this.experienceLevelId = "";
    this.addTalentInSaveApiCallId = "";
    this.SendInvitationId="";
    this.talentProfileId="";
    this.apiGetAllPostDataId=""

    this.state = {
      errorMessage:"",
      anchorEl: null,
      selectedOption: 'Most Recent',
      jobListData: [],
      recentOption: 'Most Recent',
      search: '',
      searchedList: ['Web Designer', 'App Developer'],
      experienceList: [
        {
          id: 1,
          value: 'entryLevel',
          label: "Entry Level",
          count: '$1487',
          min: 0,
          max: 2
        },
        {
          id: 2,
          value: 'interMediate',
          label: "intermediate",
          count: '$9383',
          min: 2,
          max: 5
        },
        {
          id: 3,
          value: 'expert',
          label: "Expert",
          count: '$12383',
          min: 5,
          max: 25
        }
      ],
      selectedOptions: [],
      proposalSelected: [],
      hoursSelected: [],
      minRange: '',
      maxRange: '',
      proposalList: [
        {
          id: 1,
          value: '0',
          minRange: 0,
          maxRange: 5,
          label: "Less Than 5",
        },
        {
          id: 2,
          value: '5',
          minRange: 5,
          maxRange: 10,
          label: "5 to 10",
        },
        {
          id: 3,
          value: '10',
          minRange: 10,
          maxRange: 15,
          label: "10 to 15",
        },
        {
          id: 4,
          value: '15',
          minRange: 15,
          maxRange: 20,
          label: "15 to 20",
        },
        {
          id: 5,
          value: '20',
          minRange: 20,
          maxRange: 30,
          label: "20 to 30",
        },
        {
          id: 6,
          value: '30',
          minRange: 30,
          maxRange: 50,
          label: "30 to 50",
        },
      ],
      hourlyRateList: [
        {
          id: 1,
          label: "Less Than 5",
          value: '5',
          maxRange: 5,
          minRange: 0,
        },
        {
          id: 2,
          label: "5 to 10",
          value: '10',
          maxRange: 10,
          minRange: 5,
        },
        {
          id: 3,
          label: "10 to 15",
          value: '15',
          maxRange: 15,
          minRange: 10,
        },
        {
          id: 4,
          label: "15 to 20",
          value: '20',
          maxRange: 20,
          minRange: 15,
        },
        {
          id: 5,
          label: "20 to 30",
          value: '30',
          maxRange: 30,
          minRange: 20,
        },
        {
          id: 6,
          label: "30 to 50",
          value: '50',
          maxRange: 50,
          minRange: 30,
        },
      ],
      minHours: '',
      maxHours: '',
      clearInfoList: [
        {
          id: 1,
          value: 'previousClients',
          label: "My Previous Clients",
          count: '0'
        },
        {
          id: 2,
          value: 'paymentsVerified',
          label: "Payments Verified",
          count: '300'
        }
      ],
      currentPage: 1,
      totalPages:0,
      authToken: '',
      accountId: "",
      searchedResult: [],
      totalFreelancer: [],
      showMore: false,
      userName: 'Rahul Sharma',
      perDayRate: '$100.00',
      earnedPoint: '$20k+',
      progressValue: 45,
      profileSummery: 'IP professionals offer standardized services which can solve IP users immediate needs e.g. NDA contract, illustration contract, patent search services.',
      profileDetail: 'Just browse the offers, select, buy, and use.',
      languages: ['PHP', 'Android', 'SQL', 'Java', 'Kotlin'],
      isModalOpen: false,
      post_id:"12",
      inviteNotification:false,
      invitationSent:"",
      invitationUnsent:"",
      clientProfileData:{data:{
        id: "",
        type: "",
      attributes: {
        id: 971,
        email: "",
        country: "",
        user_name: null,
        first_name: "",
        job_title: "",
        phone_number: null,
        full_phone_number: null,
        total_experience: null,
        activated: false,
        degree_img: {
          url: null
        },
        aadhar_img: {
            url: null
        },
        passport_img: {
            url: null
        },
        profile_image: "",
        work_history: null,
        skills: [
        ],
        unique_auth_id: "",
        address: null,
        created_at: "",
        updated_at: "",
        full_name: "",
        status: "",
        stripe_account_id: null,
        stripe_account_onboard_url: null,
        stripe_is_charges_enabled: false,
        stripe_is_onboard_completed: false,
        stripe_is_details_submitted: false,
        work_experiences: []
    }
      }},
      accountHolderName:"",
      selectedJob: '',
      searchedValue:""
    };
    this.handleSearch = this.handleSearch.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    const storedSearchedValue = await storage.get("searchedValue") || ""; // Default to empty if not found
    this.setState({ searchedValue: storedSearchedValue });
    this.setState({
      authToken: await storage.get("authToken"),
      accountId: await storage.get("accountId"),
      accountHolderName : await storage.get("accountHolderName"),
    })
    this.getSearchedResult(storedSearchedValue);
    this.getAllJobPostedData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.displySearchedProfile === requestCallId || this.displyFilteredValue === requestCallId || this.previousClientsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const start = 0;
        const end = start + 4;
        if (responseJson.freelancers.data) {
          this.setState({ searchedResult: responseJson.freelancers.data,
            totalPages: Math.ceil(responseJson?.total_count / 10), 
        })
          this.setState({ totalFreelancer: this.state.searchedResult.slice(start, end) })
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      } else if (requestCallId === this.addTalentInSaveApiCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){
          this.getSearchedResult(this.state.search)
        }
      }
      if (this.apiGetAllPostDataId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          this.setState({ jobListData: responseJson.data });
        }
      } else if (this.talentProfileId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          this.setState({ clientProfileData: responseJson });
        }
      } else if (this.SendInvitationId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          this.setState({invitationSent:responseJson?.meta?.message})
          this.setState({invitationUnsent:responseJson?.errors})
          this.setState({inviteNotification:true})
          this.getAllJobPostedData();
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = event.target.value;
    await storage.set("searchedValue", searchValue);

    this.setState({ searchedValue: searchValue });

    if (searchValue === "") {
        this.setState({
            searchedResult: [],
        });
    } else {
        this.getSearchedResult(searchValue);
    }
}
handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  this.setState(({
    [name]: value
  } as unknown) as Pick<S, keyof S>);
};
handleMostRecentCloseMenu = (selectedOption: string) => {
  this.setState({ selectedOption, anchorEl: null }, () => {
    this.getSearchedResult(this.state.searchedValue);
  });
};

handleMostRecentClick = (event: { currentTarget: any; }) => {
  this.setState({ anchorEl: event.currentTarget });
};
  handleCheckboxChange = (value: string) => {
    this.setState((prevState) => {
      if (prevState.selectedOptions.includes(value)) {
        return {
          selectedOptions: prevState.selectedOptions.filter((option) => option !== value),
        };
      } else {
        return {
          selectedOptions: [...prevState.selectedOptions, value],
        };
      }
    }, () => this.getFilteredResult());


  };
  componentDidUpdate() {

    if (this.state?.selectedOptions?.includes("previousClients")) {
      this.handlePreviousClients()
    }
  }
  handlePageChange = (_event: object, page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getSearchedResult(this.state.searchedValue);
    });
  };
  
  handleProposalCheckbox = (value: string) => {
    const { proposalSelected, proposalList } = this.state;
    if (proposalSelected.includes(value)) {
      const updatedSelectedOptions = proposalSelected.filter((option) => option !== value);
      let min = '';
      let max = '';
      if (updatedSelectedOptions.length > 0) {
        min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.minRange || 0)));
        max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      }
      this.setState({
        proposalSelected: updatedSelectedOptions,
        minRange: min,
        maxRange: max,
      }, () => this.getFilteredResult());
    } else {
      const updatedSelectedOptions = [...proposalSelected, value];
      let min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.minRange || 0)));
      let max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      this.setState({
        proposalSelected: updatedSelectedOptions,
        minRange: min,
        maxRange: max,
      }, () => this.getFilteredResult());
    }
  };

  handleClearProposalFilter = () => {
    this.setState({
      proposalSelected: [],
      minRange: '',
      maxRange: '',
    }, () => this.getSearchedResult(this.state.search));
  }

  handleHourCheck = (value: string) => {
    const { hoursSelected, hourlyRateList } = this.state;
    if (hoursSelected.includes(value)) {
      const updatedSelectedOptions = hoursSelected.filter((option) => option !== value);
      let min = '';
      let max = '';
      if (updatedSelectedOptions.length > 0) {
        min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.minRange || 0)));
        max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      }
      this.setState({
        hoursSelected: updatedSelectedOptions,
        minHours: min,
        maxHours: max,
      }, () => this.getFilteredResult());

    } else {
      const updatedSelectedOptions = [...hoursSelected, value];
      let min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.minRange || 0)));
      let max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      this.setState({
        hoursSelected: updatedSelectedOptions,
        minHours: min,
        maxHours: max,
      }, () => this.getFilteredResult());
    }
  };

  handleClearHoursFilter = () => {
    this.setState({
      hoursSelected: [],
      minHours: '',
      maxHours: '',
    }, () => this.getSearchedResult(this.state.search));
  }

  handleClearAllFilter = () => {
    this.setState({
      hoursSelected: [],
      minHours: '',
      maxHours: '',
      proposalSelected: [],
      minRange: '',
      maxRange: '',
    }, () => this.getSearchedResult(this.state.search));
  }

  handleInviteToJob = (id:string) => {
    this.setState({
      isModalOpen: true,
    });
    this.getTalentProfileDetails(id)
  };
  
  handleCloseModal = () => {
    this.setState({ isModalOpen: false,selectedJob:"" });
  };
  handleCloseDialog = () => {
    this.setState({ inviteNotification: false });
  };
handleSendInvitation = () => {
  const { jobListData, clientProfileData, selectedJob } = this.state;
  if (clientProfileData && jobListData && jobListData.length > 0) {
    if (!selectedJob) {
      this.setState({ errorMessage: '* Please choose a job' });
    } else {
      this.postSendInvitation(selectedJob, clientProfileData.data.attributes.id.toString());
      this.setState({ isModalOpen: false, errorMessage: '' });
    }
  }
};

  handleJobChange = (event: { target: { value: any; }; }) => {    
    this.setState({ selectedJob: event.target.value,errorMessage: "" }); 
  }
 
  getSearchedResult = (searchValue: string) => {
    const { currentPage, selectedOption, authToken } = this.state;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };
    const isMostRelevant = selectedOption === "Most Recent" ? true : false;
    this.displySearchedProfile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchResult}?query=${searchValue}&page=${currentPage}&per_page=10&most_relevant=${isMostRelevant}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsMethodeType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  buildQueryParams() {
    const { minRange, maxRange, minHours, maxHours, selectedOptions, experienceList } = this.state;
    const queryParams: { [key: string]: string } = {};

    if (minRange !== '' && maxRange !== '') {
      queryParams['search[min_proposals]'] = minRange;
      queryParams['search[max_proposals]'] = maxRange;
    }

    if (minHours !== '' && maxHours !== '') {
      queryParams['search[min_rate]'] = minHours;
      queryParams['search[max_rate]'] = maxHours;
    }
    if (selectedOptions?.length) {
      let minimumExp = '';
      let maxExp = '';

      const updatedSelectedExperiences = experienceList.filter(option =>
        selectedOptions.includes(option.value)
      );

      if (updatedSelectedExperiences?.length) {

        minimumExp = String(
          Math.min(
            ...selectedOptions.map(
              selectedValue =>
                updatedSelectedExperiences.find(
                  item => item.value === selectedValue
                )?.min || 0
            )
          )
        );
        maxExp = String(
          Math.max(
            ...selectedOptions.map(
              selectedValue =>
                updatedSelectedExperiences.find(
                  item => item.value === selectedValue
                )?.max || 0
            )
          )
        );

        queryParams['search[max_total_experience]'] = maxExp;
        queryParams['search[min_total_experience]'] = minimumExp;
      }
    }
    return queryParams
  }

  getFilteredResult = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displyFilteredValue = requestMessage.messageId;
    const queryParams = this.buildQueryParams();
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilteredEndPoint + `?${queryString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsMethodeType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleShowMoreSkills = () => {
    this.setState({ showMore: !this.state.showMore })
  }

  handleSaveLater = (freelancerId: string) => {
    const saveLaterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };

    const body = {
      "favourites": {
        "favourable_type": "AccountBlock::Account",
        "favourable_id": freelancerId
      }
    }
    this.saveLaterProfileId = saveLaterMessage.messageId;
    saveLaterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveLaterEndPoint
    );
    saveLaterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    saveLaterMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    saveLaterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(saveLaterMessage.id, saveLaterMessage);
  }

  handlePreviousClients = () => {
    const previousClients = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };


    this.previousClientsId = previousClients.messageId;
    previousClients.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.previousClientsEndPoint
    );
    previousClients.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    previousClients.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(previousClients.id, previousClients);
  }

  addTalentInSave = (id: any) => {
    const {authToken} = this.state;

    const httpBody = {
      "favourites": {
        "favourable_type": "AccountBlock::Account",
        "favourable_id": id
      },
      "token": authToken
    }

    const header = { "Content-Type": "application/json", "token": authToken};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addTalentInSaveApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_favourite/favourites");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  postSendInvitation = (post_id: string, account_id: string) => {
    const saveLaterMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = { "token": this.state.authToken };
    const formdata = new FormData();
    formdata.append("post_id", post_id);
    formdata.append("account_id", account_id);
    formdata.append("url", window.location.origin);


    this.SendInvitationId = saveLaterMessage.messageId;
    saveLaterMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.inviteToJobEndPoint);
    saveLaterMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    saveLaterMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    saveLaterMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);

    runEngine.sendMessage(saveLaterMessage.id, saveLaterMessage);
  };

  getTalentProfileDetails = async (id:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.talentProfileId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${id}/show_freelancer_profile`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllJobPostedData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": this.state.authToken
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllPostDataId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/invite_to_job_post"
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
