import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
// Customizable Area End
}
interface S {
  // Customizable Area Start
  savedTalentData: any[];
  yourHiresData: any[];
  isSavedTalent: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
// Customizable Area End
}

export default class TalentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSavedTalentApiCallId: string = '';
  removeSavedTalentApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];
    this.state = {
      yourHiresData: [],
      savedTalentData: [],
      isSavedTalent: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      yourHiresData: webConfigJSON.yourHiresData,
      savedTalentData: [],
      isSavedTalent:
        location.pathname == '/talent' || location.pathname == '/savedtalent'
    });
    this.getSavedTalent();
    // Customizable Area End
  }

  

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSavedTalentApiCallId) {
        if (responseJson.data) {
          this.setState({
            savedTalentData: responseJson.data
          });
        }
      } else if (apiRequestCallId === this.removeSavedTalentApiCallId) {
        if (responseJson.message) {
          this.getSavedTalent()
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getSavedTalent = async () => {
    const header = { "Content-Type": "application/json", "token": await storage.get("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSavedTalentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_talents/talents');
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeSavedTalent = async (id: any) => {
    const header = { "Content-Type": "application/json", "token": await storage.get("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.removeSavedTalentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_favourite/favourites/' + id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
