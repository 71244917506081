import React from "react";

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  Checkbox,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { dot, locationIcon } from "../src/assets";
import Avatar from "@material-ui/core/Avatar";
import { blue } from "@material-ui/core/colors";
import InvitationToInterviewController, {
  Props,
} from "./InvitationToInterviewController.web";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

// Customizable Area Start


// Customizable Area End

export default class InvitationToInterview extends InvitationToInterviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const GreenCheckbox = withStyles({
      root: {
        color: "#535353",
        "&$checked": {
          color: blue[600],
        },
      },
      checked: {},
    })(Checkbox);
    let {name,location,updated_at,description,skills,estimate,duration,per_day_charge,est_budget,proposals,posted_job}=this.state.inviteToJobData?.attributes?.post?.data?.attributes;
    const {date,time} = this.formatDate(updated_at);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box style={{ margin: "2rem" }}>
              <Box>
                <Typography
                  style={{
                      ...webStyle.headingText,
                      fontSize: "36px",
                      marginBottom: "0px",
                      margin: "20px 20px",
                    }}
                >
                  Invitation to interview
                </Typography>
              </Box>
          <Grid container spacing={2} style={{width:"100%"}} >
            <Grid item xs={12} md={9}>
              <Box style={webStyle.mainbox}>
                <Box style={{ borderBottom: "1px solid #DEDEDE" }}>
                  <Typography style={webStyle.JobHead}>Job details</Typography>
                </Box>

                <Box style={{ margin: "10px" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "32px",
                    }}
                  >
                    <div>
                      <Typography style={webStyle.text}>
                        {name}
                      </Typography>
                      <Box style={webStyle.subBox}>
                        <Box style={webStyle.locationBox}>
                          <img src={locationIcon} style={webStyle.location} />
                          <Typography
                            style={{
                              ...webStyle.text2,
                              marginTop: "0px",
                              marginLeft: "5px",
                            }}
                          >
                            {location}
                          </Typography>
                        </Box>
                        <Box style={webStyle.locationBox}>
                        <Box display={'flex'} flex={1} alignItems={'center'}>
                      <Typography
                        style={{
                          ...webStyle.time,
                          marginRight: '10px'
                        }}
                      >
                        {date}
                      </Typography>
                      <img src={dot} style={{ marginRight: '10px' }} />
                      <Typography style={webStyle.time}>{time}</Typography>
                    </Box>
                        </Box>
                      </Box>
                    </div>
                  </Box>

                  <Box style={webStyle.hrStyle}>
                    <Typography style={{ ...webStyle.text, fontSize: "16px" }}>
                      Project Descriptions:
                    </Typography>
                    <Typography
                      style={{ ...webStyle.text2, marginBottom: "24px" }}
                    >
                      {description}
                    </Typography>
                  </Box>

                  <Box style={webStyle.hrStyle}>
                    <Typography
                      style={{
                        ...webStyle.text,
                        fontSize: "16px",
                        marginTop: "0px",
                      }}
                    >
                      Skills and expertise:
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        margin: "12px 20px 24px 20px",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      {skills?.map((skill: any, index: number) => {
                        return (
                          <Typography style={webStyle.php} key={index}>
                            {skill}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>

                  <Box style={{ marginBottom: "32px" }}>
                    <Typography style={{ ...webStyle.text, fontSize: "16px" }}>
                      Project Details:
                    </Typography>
                    <Box style={webStyle.timeLineGrid}>
                      <Box style={{ display: "flex", flex: "1 1 100%" }}>
                        <div style={webStyle.Flex}>
                          <Avatar style={webStyle.avatar}>1</Avatar>
                          <Box style={webStyle.timeLineContent}>
                            <Typography
                              style={{
                                ...webStyle.headingText,
                                marginBottom: "0px",
                              }}
                            >
                              {"Scope"}
                            </Typography>
                            <Typography style={webStyle.subHeadingText}>
                              {estimate},{duration}
                            </Typography>
                          </Box>
                        </div>
                        <div style={webStyle.Flex}>
                          <Avatar style={webStyle.avatar}>2</Avatar>
                          <Box style={webStyle.timeLineContent}>
                            <Typography
                              style={{
                                ...webStyle.headingText,
                                marginBottom: "0px",
                              }}
                            >
                              {"Budget"}
                            </Typography>
                            <Typography style={webStyle.subHeadingText}>
                              {per_day_charge}/ {"day"}
                            </Typography>
                          </Box>
                        </div>
                      </Box>
                      <div style={webStyle.Flex}>
                        <Avatar style={webStyle.avatar}>3</Avatar>
                        <Box style={webStyle.timeLineContent}>
                          <Typography
                            style={{
                              ...webStyle.headingText,
                              marginBottom: "0px",
                            }}
                          >
                            {"Category of Patent"}
                          </Typography>
                          <Typography style={webStyle.subHeadingText}>
                            {"Category/Subcat1,Subcat2,Subcat3"}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box style={webStyle.mainbox}>
              <Box style={{ borderBottom: "1px solid #DEDEDE" }}>
                <Typography style={webStyle.JobHead}>Original message from client</Typography>
              </Box>
              <div style={{margin:"12px 20px 24px 20px",width:"700px"}}>
                <Typography style={webStyle.Message}>Hello!</Typography>
                <Typography style={{...webStyle.Message, marginBottom:"10px"}}>I’d like to invite you to take a look at the job I’ve posted. Please submit a proposal if you’re available and interested.</Typography>
                <Typography style={webStyle.Message}>
                {`${this.state.inviteToJobData.attributes.client_first_name} ${this.state.inviteToJobData.attributes.client_last_name}.`}
                </Typography>
              </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={3} style={{margin:"24px auto"}}>
             <Box style={webStyle.estdBoxStyle}>
            <div style={{display: "flex" ,flexDirection: "column"}}>
             <Typography style={webStyle.numberStyle}>${est_budget}</Typography>
             <Typography style={webStyle.estdStyle}>Estd Budget</Typography>
             </div>
             <div style={{display:"flex",justifyContent:"end"}}>
             <Typography style={webStyle.estdStyle}>
             Proposals: {proposals}
             </Typography>
             </div>
            </Box>


                <Box style={{...webStyle.mainbox,margin:"24px auto auto",padding:"30px"}}>
          <Typography style={{...webStyle.text,marginTop:"0px",marginLeft:"0px",marginBottom:"18px"}}>
            About the client:
          </Typography>
          <Box style={{ marginLeft: '-4%' }}>
            <Typography style={webStyle.aboutThisJobTexts}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Jobs Posted: {posted_job}
            </Typography>
            <Typography style={webStyle.aboutThisJobTexts}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Invites send: {this.state.inviteToJobData.attributes.invite_send}
            </Typography>
            <Typography style={webStyle.aboutThisJobTexts}>
              <GreenCheckbox
                checked={true}
                disabled={true}
                size="medium"
                color="primary"
                name="containsUppercase"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              Unanswered Invites: {this.state.inviteToJobData.attributes.unanswered_invites}
            </Typography>
          </Box>
          </Box>

          <Box>
            <Button style={webStyle.acceptInterviewBtn} onClick={this.handleAcceptInterview}>Accept Interview</Button>
            <Button style={{...webStyle.acceptInterviewBtn,backgroundColor:"white",color:"#206FC4",border:"1px solid #206FC4"}}  data-test-id="handleClickOpen" onClick={this.handleClickOpen}>Reject Interview</Button>
          </Box>
          </Grid>
          </Grid>
        </Box>
        <FooterWeb />

        <Dialog open={this.state.openRejectDailog} onClose={this.handleClose}  PaperProps={{
            style: {
              minWidth: '650px',
              borderRadius: "20px",
               padding: "42px 22px"
            },
          }}>
          <DialogTitle >
           <span style={webStyle.dialogTitle}> Do you want to reject the invitation to Interview?</span>
            </DialogTitle>
          <DialogContent>
            <DialogContentText style={webStyle.DialogContentText}>
              Message to client (optional)
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="message"
              type="text"
              fullWidth
              onChange={(event)=>this.handleChange(event)}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions style={webStyle.DialogActionsBox}>
            <Button onClick={this.handleRejectInterview} style={webStyle.rejectBtn} data-test-id="handleRejectInterview">Reject</Button>
            <Button onClick={this.handleClose} style={{...webStyle.rejectBtn,background:"",color:"#206FC4",border:"1px solid #206FC4"}} >Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: "100%",
    backgroundColor: "lightblue",
    padding: "10px",
    border: "1px solid #ccc",
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#206FC4",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 6px)",
    },
  },
  time: {
    color: "#6C6C6C",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  acceptInterviewBtn: {
    width: "100%",
    background: "#206FC4",
    color: "#FFFFFF",
    marginTop: "20px",
    fontSize: "17px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    lineHeight: "18.75px",
    borderRadius: "10px",
    cursor: "pointer",
    padding:"22px",
    textTransform:"inherit" as "inherit"
  },
  rejectBtn:{
    width: "180px",
    background: "#206FC4",
    color: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    borderRadius: "8px",
    cursor: "pointer",
    padding:"6px",
    textTransform:"inherit" as "inherit"

  },
  DialogActionsBox:{
    display:"flex",
    justifyContent:"center"
  },
  dialogTitle:{
    fontSize: '22px',
    fontWeight:600,
    lineHeight:"1",
    letterSpacing:"0px",
    fontFamily: "'Inter', sans-serif",
    color:"#515354" 
  },
  DialogContentText:{
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
    color:"#2B2B2B",
    fontWeight:600,
    marginBottom:"0px"

  },
  headingText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0px",
    textalign: "left",
    color: "#000000",
    marginBottom: "20px",
  },
  hrStyle: {
    marginBottom: "20px",
    borderBottom: "1px solid #DEDEDE",
  },
  estdBoxStyle:{
    display:"flex",
    justifyContent:"space-between",
    border:"2px solid #DEDEDE",
    borderRadius: "15px",
    padding: "30px"
  },
  subHeadingText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    letterSpacing: "0px",
    textalign: "left",
    color: "rgb(108, 108, 108)",
    width: "280px",
  },
Message:{
    fontSize: "14px",
    color: "#2B2B2B",
    fontFamily:"Inter"
},
aboutThisJobTexts:{
    fontFamily: "'Inter', sans-serif",
     marginTop: '4px',
     fontSize:"18px" ,
     color:"rgb(108, 108, 108)"
},
  timeLineGrid: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    margin: "12px 20px 24px 20px",
    gap: "20px",
  },
  timeLineContent: {
    display: "grid",
    gridAutoFlow: "row",
    rowGap: "6px",
  },
  avatar: {
    width: "31px",
    height: "31px",
    flexShrink: 0,
    background: "#206FC4",
    marginRight: "9px",
  },
  Flex: {
    display: "flex",
    alignItems: "center",
  },
  numberStyle:{
    fontSize: "18px",
    fontWeight:700,
    color:"#4A4A4A",
    fontFamily: "Inter",
  },
  estdStyle: {
    fontSize: "16px",
    color:"#4A4A4A",
    fontFamily: "Inter",
  },
  mainbox: {
    borderRadius: "15px",
    border: "2px solid #DEDEDE",
    margin: "24px auto auto 38px",
  },
  JobHead: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    margin: "18px",
  },
  text: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    marginTop: "10px",
    marginLeft: "20px",
  },

  location: {
    height: "15px",
  },
  locationBox: {
    display: "flex",
    marginTop: "6px",
    fontFamily: "Inter",
    alignItems: "center",
    color: "#555555",
  },

  subBox: {
    marginLeft: "20px",
  },
  text2: {
    fontSize: "14px",
    fontFamily: "Inter",
    marginTop: "4px",
    marginLeft: "20px",
    color: "#6C6C6C",
    width: "80%",
  },
  php: {
    background: " #EBF1F9",
    borderRadius: "30px",
    width: "fit-content",
    padding: "5px 20px 5px 20px",
    color: "#246dc3",
    fontWeight: 600,
    fontFamily: "Inter",
    textTransform: "inherit" as "inherit",
  },
  avtar: {
    backgroundColor: " #1F6DC1",
    fontWeight: 200,
    padding: "8px",
    marginLeft: "20px",
  },
  subgrid: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },
  text3: {
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#6C6C6C",
  },
  mainbox2: {
    borderRadius: "15px",
    marginLeft: "25px",
    padding: "15px",
    background: "rgb(245, 247, 249) none repeat scroll 0% 0%",
  },
  text4: {
    fontSize: "22px",
    fontFamily: "Roboto",
    padding: "20px 20px 0px 20px",
    color: " #525252",
  },
  proposals: {
    fontSize: "17px",
    fontFamily: "Roboto",
    padding: "10px",
    color: " #525252",
    marginRight: "10px",
    marginTop: "10px",
  },
  budget: {
    fontSize: "18px",
    fontFamily: "Roboto",
    color: " #525252",
    marginLeft: "20px",
  },
  applyBtn: {
    padding: "8px",
    borderRadius: "8px",
    backgroundColor: "#2378D1",
    marginTop: "18px",
    color: "white",
    width: "50%",
    "&:hover": {
      backgroundColor: "#2676D8",
    },
  },
  favouriteBtn: {
    padding: "12px",
    borderRadius: "8px",
    marginTop: "10px",
    border: "1px solid #2676D8",
    color: "#2676D8",
    fontWeight: 600,
    "&:hover": {
      border: "1px solid #2676D8",
    },
  },
};

// Customizable Area End
