import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType

{
  message: object;
  data: object;
}

interface InvalidResponseType
 {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isClient?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showOldPassword: boolean;
  oldPassword: string;
  showNewPassword: boolean;
  newPassword: string;
  showConfirmPassword: boolean;
  confirmPassword: string;
  confirmValidation: boolean;
  errorMessage: string;
  upperCaseCheck:boolean;
  numberCheck:boolean;
  specialCharCheck:boolean;
  requiredError:boolean;
  passwordErr: string;
  oldPasswordError:string;
  currentPassword:string;
  alertSamePopUp:boolean;
  alertSuccessPopUp:boolean;
    // Customizable Area End
}
interface SS { }

export default class FreelancerPasswordSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  forgatePasswordAPICallID:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.forgatePasswordAPICallID='';
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {  
      showOldPassword:false,
      oldPassword:'',
      showNewPassword: false,
      newPassword:'',
      showConfirmPassword: false,
      confirmPassword:'',
      confirmValidation:false,
      errorMessage:'',
      upperCaseCheck:false,
      numberCheck:false,
      specialCharCheck:false,
      requiredError:false,
      passwordErr:"",
      oldPasswordError:"",
      currentPassword:"",
      alertSamePopUp:false,
      alertSuccessPopUp:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({currentPassword:await storage.get("UserPassword")});
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data || responseJson.message);
  };

  apiSucessCall = async (apiRequestCallId: string) => {
    if (apiRequestCallId === this.forgatePasswordAPICallID) {
    this.setState({alertSamePopUp:false})
      this.helplineSucessCallBack();
    }
  };
 
  handleDeleteClose=()=>{
    this.setState({alertSamePopUp:false})
  }

  handleDialogOpen = () => {
    this.setState({ alertSamePopUp: true });
};

handleSucessClose=()=>{
  this.setState({alertSuccessPopUp:false})

}
handleSuccessOpen = () => {
  this.setState({ alertSuccessPopUp: true });

};
  handleShowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword });
  }
  handleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };
  handleOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ oldPassword: event.target.value });    
    if(event.target.value != this.state.currentPassword){
    this.setState({oldPasswordError:"Old password is incorrect"})
    }else{
      this.setState({oldPasswordError:""})
    }
  };

  passwordErrorMessage = "Please Enter password";
  passwordErrorMessage3= "Password must not exceed 12 characters"
  passwordErrorMessage4 = "Password must not contain spaces"

  handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
      this.setState({ newPassword: event.target.value });
    
      if (!newPassword) {
        this.setState({ passwordErr: this.passwordErrorMessage });
      } else if (newPassword.length > 12) {
        this.setState({
          passwordErr: this.passwordErrorMessage3
        });
      } else if (newPassword.includes(" ")) {
        this.setState({
          passwordErr: this.passwordErrorMessage4
        });
      } 
      else {
        this.setState({ passwordErr: "" });
      }
      this.state.confirmPassword &&this.checkConfirmIsMatchWithNewPassword(event.target.value)
      this.handlePasswordValidation(event.target.value)
  };

  handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: event.target.value });
    if (this.state.newPassword !== event.target.value) {
      this.setState({
        confirmValidation: true,
        errorMessage: "Passwords did not match"
      });
    } else {
      this.setState({ confirmValidation: false, errorMessage: "" });
    }
  };
 
  checkConfirmIsMatchWithNewPassword = (value:string)=>{
    if (this.state.confirmPassword !== value) {
      this.setState({
        confirmValidation: true,
        errorMessage: "Passwords did not match"
      });
    } else {
      this.setState({ confirmValidation: false, errorMessage: "" });
    }
  }
  handleConfirmShowPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handlePasswordValidation = (password:string) => {
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/;

    const hasUppercase = uppercaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);

    this.setState({
      upperCaseCheck: hasUppercase,
      numberCheck: hasNumber,
      specialCharCheck: hasSpecialChar,
    });
  }

  handleUpdate = async () => {
    const { oldPassword, newPassword, confirmPassword, upperCaseCheck, numberCheck, specialCharCheck } = this.state;

    if (oldPassword === newPassword) {
        this.handleDialogOpen();
        return;
    }

    if (!upperCaseCheck || !numberCheck || !specialCharCheck) {
        this.setState({ passwordErr: configJSON.notMatchPasswordCriteria });
        return;
    }

    const token = await storage.get("authToken");
    const updatedMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };
    const body = {
        new_password: newPassword,
        old_password: oldPassword,
        password_confirmation: confirmPassword
    };

    this.forgatePasswordAPICallID = updatedMessage.messageId;
    const endpointURL = this.props.isClient ? configJSON.clientUpdatePassword : configJSON.freelancUpdatePassword;
    updatedMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpointURL
    );
    updatedMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateUserMethodeType
    );
    updatedMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    updatedMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    );
    runEngine.sendMessage(updatedMessage.id, updatedMessage);
};

  helplineSucessCallBack = () => {
    this.handleSuccessOpen();
    this.setState({oldPassword:"",newPassword:"",confirmPassword:""});
 }; 

  isFormValidate=()=>{
   return this.state.oldPassword!=""&& this.state.oldPasswordError==""&&this.state.newPassword!=""&&this.state.confirmPassword!=""&&this.state.errorMessage==""&&this.state.passwordErr==""
  }
  // Customizable Area End
}
