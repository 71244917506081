import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import storage from "framework/src/StorageProvider";

// Customizable Area Start
export const webConfigJSON = require("./config");
interface PostFile {
  url: string;
}

interface PostAttributes {
  id: number;
  name: string;
  description: string;
  body: string;
  job_title: string;
  job_description: string;
  per_day_charge: string;
  budget: string;
  duration: string;
  employment_term: string;
  estimate: string;
  skills: string[];
  location: string;
  file: PostFile;
  account_id: number;
  created_at: string;
  updated_at: string;
  proposals: number;
  messaged: number;
  hired: number;
  is_drafted: boolean;
  est_budget: number;
  last_viewed_by_client: string;
  rating: number | null;
  intermediate: string | null;
  model_name: string;
  categorizations: any[];
  images_and_videos: any[];
  file_name: string;
  country: string | null;
  state: string | null;
  city: string | null;
  drafted_at: string;
  posted_job: number;

}

interface PostData {
  id: string;
  type: string;
  attributes: PostAttributes;
}

interface InvitationAttributes {
  id: number;
  post_id: number;
  message: string;
  created_at: string;
  updated_at: string;
  post: {
      data: PostData;
  };
  days_ago: string;
  client_first_name: string;
  client_last_name: string;
  invite_send:number;
  unanswered_invites:number
}

interface Invitation {
  id: string;
  type: string;
  attributes: InvitationAttributes;
}

// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  freelancerID : string;
  coverLetterError:string;
  skills:any[]
  openRejectDailog:boolean
  count: number;
  coverLetterText:string;
  inviteToJobData:Invitation
  statusOption:{
    value:number,
    option:string
  }[];
  inputList: {
    description: string,
    amount: string,
    date: string,
    status: string,
    post_id: number,
  }[];
  authToken:string
  interview_id:string
  rejectionMessage:string
  isSubmittedProposal:boolean
  proposalRes:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class InvitationToInterviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  InvitetoJobDataId:string=""
  AcceptInvitationId:string=""
  rejectInterviewId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
];
    this.state = {
     coverLetterError:"",
     freelancerID:"",
     coverLetterText:"",
     skills:["PHP","Python" ],
     openRejectDailog:false,
     count: 1,
     isSubmittedProposal:false,
     proposalRes:"",
     inviteToJobData: {
      "id": "",
      "type": "",
      "attributes": {
          "id": 0,
          "post_id": 0,
          "message": "",
          "created_at": "",
          "updated_at": "",
          "post": {
              "data": {
                  "id": "0",
                  "type": "",
                  "attributes": {
                      "id": 0,
                      "name": "",
                      "description": "",
                      "body": "",
                      "job_title": "",
                      "job_description": "",
                      "per_day_charge": "",
                      "budget": "",
                      "duration": "",
                      "employment_term": "",
                      "estimate": "",
                      "skills": [
                      ],
                      "location": "",
                      "file": {
                          "url": ""
                      },
                      "account_id": 0,
                      "created_at": "",
                      "updated_at": "",
                      "proposals": 0,
                      "messaged": 0,
                      "hired": 0,
                      "is_drafted": false,
                      "est_budget": 0.0,
                      "last_viewed_by_client": "",
                      "rating": null,
                      "intermediate": null,
                      "model_name": "",
                      "categorizations": [],
                      "images_and_videos": [],
                      "file_name": "",
                      "country": null,
                      "state": null,
                      "city": null,
                      "drafted_at": "",
                      "posted_job": 0,
                      
                  }
              }
          },
          "days_ago": "",
          "client_first_name": "client",
          "client_last_name": "fasjkf",
          "invite_send": 0,
          "unanswered_invites":0
      }
  },
     statusOption:[
      {
        value:0,
        option:"Ongoing"
      },
      {
        value:1,
        option:"Completed",
        
      },
      {
        value:2,
        option:"Pending"
      },
      {
        value:3,
        option:"Accept"
      },
      {
        value:4,
        option:"Rejected"
      }
    ],
    inputList: [],
    authToken:"",
    interview_id:"",
    rejectionMessage:""
    };
   
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
      freelancerID: await storage.get("freelancerID"),
    })
    if(location.pathname.includes('InvitationToInterview')){
    const id=window.location.pathname.split("/")
    this.setState({freelancerID:id[2]})
    storage.set("freelancerID",id[2])
    }
    this.handleInvitetoJobData()
    // Customizable Area End
  }

  // Customizable Area Start
  handleAcceptInterview=() => { 
    this.props.navigation.navigate('AcceptInterview',{id:this.state.freelancerID})
  }
  handleClickOpen = () => {
    this.setState({ openRejectDailog: true });
  };
  handleCancelBtn=()=>{
    this.props.navigation.navigate('InvitationToInterview',{id:this.state.freelancerID})
  }
  handleClose = () => {
    this.setState({ openRejectDailog: false });
  };
  handleChangeCoverLetter = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const coverLetterText = event.target.value;
    this.setState({ coverLetterText });
    if (coverLetterText.trim() === "") {
      this.setState({ coverLetterError: "Cover letter is required." });
    } else {
      this.setState({ coverLetterError: "" });
    }
  }
  handleChange=(event:any)=>{
    this.setState({ rejectionMessage: event.target.value });

  }
  formatDate(utcDateString: string): { date: string, time: string } {
    const utcDate: Date = new Date(utcDateString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Asia/Kolkata'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    };
    const formattedDate: string = utcDate.toLocaleDateString('en-US', dateOptions);
    const formattedTime: string = utcDate.toLocaleTimeString('en-US', timeOptions);
    return { date: formattedDate, time: formattedTime };
}
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list })
  };
  handleInvitetoJobData = () => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      "token": this.state.authToken
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.InvitetoJobDataId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/invitation_to_interviews'
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postAcceptInvitation = () => {
    const { coverLetterText } = this.state;
    if (coverLetterText.trim() === "") {
      this.setState({ coverLetterError: "* Cover letter is required." });
      return;
    }
    const header = {
      token: this.state.authToken
    };
    let formData=new FormData();
    formData.append("post_id", String(this.state.inviteToJobData.attributes.post_id));
    formData.append("invitation_to_interview_id", String(this.state.inviteToJobData.id));
    formData.append("cover_latter", this.state.coverLetterText);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AcceptInvitationId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/my_proposals`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  handleRejectInterview = async () => {
    const header = {
      token: await storage.get('authToken')
    };
  
    let formdata = new FormData();
    formdata.append("interview_id", String(this.state.inviteToJobData.id));
    formdata.append("freelancer_message", this.state.rejectionMessage);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.rejectInterviewId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/invitation_to_interviews/reject_interview`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata  // Include form data in the request body
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "put"
    );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCloseDialog=()=>{
    this.props.navigation.navigate('FullWidthTabProposals',{id:this.state.freelancerID})

  }
  // Customizable Area End

  // Customizable Area Start
  async receive(form:string,message:Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
       if (webResponseJson ) {
         if (webApiRequestCallId===this.InvitetoJobDataId){  
           const data=webResponseJson.data.find((item:any)=>{
          return item.id==this.state.freelancerID
           })
           
          this.setState({inviteToJobData:data})
        }
        if(webApiRequestCallId==this.AcceptInvitationId){
          if(webResponseJson){
            this.setState({proposalRes:webResponseJson.message})
            this.setState({isSubmittedProposal:true})
          }
        }
        if (webApiRequestCallId === this.rejectInterviewId) {
          if (webResponseJson.message === 'You rejected the interview invitation') {
            this.setState({ openRejectDailog: false });
            this.props.navigation.navigate('FullWidthTabProposals', { id: this.state.freelancerID,tab:1 });
          }
        }
      }
    }
  }
  // Customizable Area End
}
