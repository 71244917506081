import React from 'react';
// Customizable Area Start
import { Avatar, Box, Typography, Button } from '@material-ui/core';
export interface ViewJobPostProps {
  jobData: any;
}

const ViewJobPost: React.FC<ViewJobPostProps> = ({ jobData }) => {
  // Render the job post details and activity
  return (
    <>
    <Box sx={{ display: 'grid' }}>
      <Typography style={webStyle.jobTitleText}>Details</Typography>

      <Box sx={{ display: 'grid' }} style={webStyle.detailsConainer}>
        <Typography style={webStyle.headingText}> Job title:</Typography>
        <Typography style={webStyle.content as React.CSSProperties}> {jobData.name}</Typography>
        <div style={webStyle.devider} />

        <Typography style={webStyle.headingText}>
          Project Description:
        </Typography>
        <Typography style={webStyle.content as React.CSSProperties}> {jobData.description}</Typography>
        <div style={webStyle.devider} />

        <Typography style={webStyle.headingText}> Project Details:</Typography>
        <Box style={webStyle.timeLineGrid}>
          <div style={webStyle.Flex}>
            <Avatar style={webStyle.avatar}>1</Avatar>

            <Box style={webStyle.timeLineContent}>
              <Typography style={{...webStyle.headingText,marginBottom:"0px"}}>{'Scope'}</Typography>
              <Typography style={webStyle.subHeadingText}>
                {jobData?.estimate},{jobData?.duration}
              </Typography>
            </Box>
          </div>
          <div style={webStyle.Flex}>
            <Avatar style={webStyle.avatar}>2</Avatar>
            <Box style={webStyle.timeLineContent}>
              <Typography style={{...webStyle.headingText,marginBottom:"0px"}}>
                {'Budget'}
              </Typography>
              <Typography style={webStyle.subHeadingText}>
              $ {jobData.per_day_charge}/ {jobData.budget === 'project_budget' ? 'project' : 'day'}
              </Typography>
            </Box>
          </div>
          <div style={webStyle.Flex}>
            <Avatar style={webStyle.avatar}>3</Avatar>
            <Box style={webStyle.timeLineContent}>
              <Typography style={{...webStyle.headingText,marginBottom:"0px"}}>
                {'Category of Patent'}
              </Typography>
              <Typography style={webStyle.subHeadingText}>
                {'Category/Subcat1,Subcat2,Subcat3'}
              </Typography>
            </Box>
          </div>
        </Box>
        <div style={webStyle.devider} />
        <Typography style={webStyle.headingText}>
          Activity on this job:
        </Typography>
        <Box sx={{ display: 'grid' }} style={webStyle.detailsConainer}>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>Proposals:</Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'10 To 15 '}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Last viewed by the client:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'10 min ago'}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Invite Sends:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'0'}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Unanswered invites:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'0'}</Typography>
          </Box>

          <div style={webStyle.devider} />
          <Box style={webStyle.timeLineContent}>
            <Typography style={webStyle.headingText}>
              {'Skills and expertise:'}
            </Typography>
            <div style={webStyle.skills}>
              {jobData?.skills?.slice(0, 5)?.map((item: any) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.button}
                  fullWidth={true}
                >
                  {item}
                </Button>
              ))}
              {jobData?.skills?.length > 5 ? jobData?.skills?.length - 5 : ''}
            </div>
          </Box>

          <div style={webStyle.devider} />
          <Box style={webStyle.timeLineContent}>
      <Typography style={webStyle.headingText}>
        Attachments:
      </Typography>
      <Box style={webStyle.attactmentStyle}>
  <Typography
    style={{
      flex: 1,
      padding: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}
  >
    {jobData?.file_name}
  </Typography>
</Box>

    </Box>
        </Box>
      </Box>
    </Box>
   </>
  );
};
export default ViewJobPost;

const webStyle = {
  detailsConainer: {
    background: '#F7F9FA',
    padding: '15px',
    display: ''
  },
  avatar: {
    width: '31px',
    height: '31px',
    flexShrink: 0,
    background: '#206FC4',
    marginRight: '9px'
  },
  attactmentStyle:{
    display:"flex",
    alignItems:"center",
    border:"1px solid #ccc",
    borderRadius:"8px",
    maxWidth:"200px",
    overflow:"hidden" as "hidden",
    background: "#206FC4",
    color:"white",
    paddingLeft:"16px"
  },
  devider: {
    margin: '20px 0px',
    border: '1px solid #E4E5E6'
  },
  jobTitleText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#000000',
    marginBottom: '9px'
  },
  headingText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#000000',
    marginBottom: '20px'
  },
  subHeadingText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.94px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#515152',
    width: '280px'
  },
  content: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    letterSpacing: '0px',
    textalign: 'left',
    wordBreak:"break-all"
  },
  activityItem: {
    display: 'flex',
    marginBottom: '10px'
  },
  timeLineGrid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '50px'
  },
  timeLineContent: {
    display: 'grid',
    gridAutoFlow: 'row',
    rowGap: '6px'
  },
  Flex: {
    display: 'flex',
    alignItems: 'center'
  },
  skills: {
    display: 'flex',
    flexDirecrion: 'column',
    alignItems: 'center',
    marginTop: '13px'
  },
  button: {
    marginRight: '8px',
    maxWidth: 'max-content'
  },
  lowerSectionBtn: {
    maxWidth: "161px",
    maxHeight: "55px",
    backgroundColor: "#206FC4",
    borderRadius: "10px",
  },
  btnText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    color: "#FFFFFF",
    padding: "15px",
    lineHeight: "27px",
    textDecoration: "none",
  },
};
// Customizable Area End
