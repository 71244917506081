import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  qualifications: any;
  patentlicensing: any;
  market: any;
  fees: any;
  isExpanded1: any;
  isExpanded2: any;
  isExpanded3: any;
  isExpanded4: any;
  media: any;
  ipConferences: any;
  countryOfOperation: any;
  cityOfOperation: any;
  artcles: any;
  awards: any;
  government: any;
  industry: any;
  academic: any;
  successfulPatent: any;
  link1: any;
  link2: any;
  link3: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  countryLawGuidelines: any[];
  error:any;
  showPageError:boolean;
  step5GovernmentFileUplod:any;
  step5IndustryFileUplod:any;
  step5AcademicFileUplod:any;
  step6NumberOfSuccessFileUpload:any;
  radioError:  {[key:string]:string};
  numberError:  {[key:string]:string};
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PatentLisceningExpertController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      qualifications: '',
      patentlicensing: '',
      market: '',
      fees: '',
      awards: '',
      media: '',
      ipConferences: '',
      countryOfOperation: '',
      cityOfOperation: '',
      artcles: '',
      government: '',
      industry: '',
      academic: '',
      successfulPatent: '',
      link1: '',
      link2: '',
      link3: '',
      isExpanded1: false,
      isExpanded2: false,
      isExpanded3: false,
      isExpanded4: false,
      anchorElement: null,
      openInfoPopper: false,
      countryLawGuidelines: [],
      error:false,
      showPageError:false,
      step5GovernmentFileUplod:"",
      step5IndustryFileUplod:"",
      step5AcademicFileUplod:"",
      step6NumberOfSuccessFileUpload:"",
      radioError: {},
      numberError: {},
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  handleNext = () => {
    const { activeStep } = this.state;

    let fields: string[] = []; // text fields
    let numberfields: string[] = [];    //number fields
    let radiofields: string[] = []; //radio buttons


    let errors: {[key:string]:string} = {};
    let radioError:  {[key:string]:string} = {};
    let numError:  {[key:string]:string} = {};


    if (activeStep === 0) {
      radiofields = ['qualifications','patentlicensing'];
    
    } else if (activeStep === 1) {
      radiofields = ["market"];
    } else if (activeStep === 2) {
      numberfields=["fees"]
     
    } else if (activeStep === 3) {
      radiofields = ["media","ipConferences","artcles"];
      if (this.state.media === 'Yes') {
        fields.push('link1')
      }
      if (this.state.ipConferences === 'Yes') {
        fields.push('link2');
      }
      if (this.state.artcles === 'Yes') {
        fields.push('link3');
      }
    }else if (activeStep === 4) {
      radiofields = ["awards"];
      if (this.state.awards === 'Yes') {
        fields.push('government','industry','academic')
      }
     
    }
   

    fields.forEach((field: string) => {
      const fieldValue = (this.state as any)[field];
      const minLength = 3;
      const maxLength = 200;
      if (fieldValue === '') {
        errors[field] = "*This is required";
      } else if (fieldValue.length < minLength) {
        errors[field] = '*Should contain minimum 3 characters';
      } else if (fieldValue.length > maxLength) {
        errors[field] = '*Should contain only letters and maximum of 200';
      }
      else{
        delete errors[field];
      }

    });


    numberfields.forEach((numfield: string) => {
      const fieldValue = (this.state as any)[numfield];
      const maxDigits = 3;
      if (fieldValue === '') {
        numError[numfield] = "*This is required";
      } else if (!/^\d{0,7}$/.test(fieldValue)) {
        numError[numfield] = "*Only digits are allowed";
      }
      else{
        delete numError[numfield]
      }
    });

   

    radiofields.forEach((radiofields: string) => {
      if ((this.state as any)[radiofields] === '') {
        radioError[radiofields] = "*This is required";
      }else{
        delete radioError[radiofields]
      }
    });

    if (Object.keys(errors).length === 0 && Object.keys(radioError).length === 0  && Object.keys(numError).length === 0) {
      this.updateStepNum();
    } else {
      this.setState({ error: errors, radioError: radioError,numberError: numError });
    }
  };

  updateStepNum = () => {
        this.setState((prevState) => ({
          error: {}, 
          radioError: {},
          numberError: {}, 
          activeStep: prevState.activeStep + 1
        }));
  };
  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleChangecountryLawGuidelines = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let countryLawGuidelinesCopy = this.state.countryLawGuidelines;
    if (checked) {
      // Check if the value is not already present in the array
      if (!countryLawGuidelinesCopy.includes(name)) {
        // Add the value to the array
        countryLawGuidelinesCopy.push(name);
      }
    } else {
      // Remove the value from the array if it exists
      const index = countryLawGuidelinesCopy.indexOf(name);
      if (index !== -1) {
        countryLawGuidelinesCopy.splice(index, 1);
      }
    }
    this.setState(({
      countryLawGuidelines: countryLawGuidelinesCopy
    } as unknown) as Pick<S, keyof S>);
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  handleToggleClick = () => {
    this.setState(prevState => ({
      isExpanded1: !prevState.isExpanded1
    }));
  };
  handleFeesFieldChange  = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numberFields = ["fees"]; // Add your number field names here
    let updatedError = { ...this.state.error };

    // Check if the field is in the numberFields array
    if (numberFields.includes(name)) {
        const regexExp = /^\d{0,7}$/; // Adjust the regular expression to allow 0 to 7 digits

        // Use regex to check if the value contains only numbers
        if (regexExp.test(value)) {
            delete updatedError[name];
            // Check if the value is changing before updating state
            if ((this.state as any) [name] !== value) {
                this.setState({
                    [name]: value,
                    numberError: updatedError
                } as Pick<S, keyof S>);
            }
            if (value.length > 7 && updatedError[name]) {
                setTimeout(() => {
                    delete updatedError[name];
                    this.setState({ numberError: updatedError });
                }, 3000); // Remove error message after 3 seconds
            }
        } else {
            updatedError[name] = "*Should contain only numbers with a maximum of 7 digits";
            this.setState({ numberError: updatedError });
            setTimeout(() => {
              this.setState({ numberError: {} });
            }, 2000);
        }
    } else {
        // Handle validation for other fields here
    }
};
handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
   
  let errCopy = {... this.state.error }
  const { name, value } = event.target;
  let errorKeyObject :{[key:string]:string[]}= {media:["link1"],ipConferences:["link2"],artcles:["link3"],awards:["government",'industry','academic']}

    if (value == "No") {
      errorKeyObject[name].forEach(element => {
        delete errCopy[element]
      });
     
    }
  
  this.setState(({
    [name]: value,
    error: errCopy, 
  } as unknown) as Pick<S, keyof S>);
    this.setState({  });
  
}
handleLinkField = (event: ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  let updatedError = { ...this.state.error };
  if (/^\S?.{0,20000}$/.test(value)) { // Modified regex pattern to allow optional leading space
      // Check if the value is changing before updating state
      if ((this.state as any)[name] !== value) {
          value.length >= 3 ? delete updatedError[name] : updatedError[name] = "*Should contain minimum 3 characters";
          this.setState({
              [name]: value.trim(), // Trim leading and trailing spaces
              error: updatedError
          } as Pick<S, keyof S>);
      }
  } else {
      updatedError[name] = "*Should contain a valid URL";
      this.setState({ error: updatedError });
      setTimeout(() => {
          this.setState({ error: false });
      }, 2000);
  }
};
handleFileUpload = (event: any) => {
  const { files } = event.target;
  const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
  const stateKeyRef = event.target.name;
  const selectedFile = files[0];
  const maxSizeMB = 5;
  let error = "";

  if (selectedFile) {
    const fileSizeMB = selectedFile.size / (1024 * 1024);
    if (!allowedExtensions.test(selectedFile.name)) {
      error = '*Invalid file type. Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.';
    } else if (fileSizeMB > maxSizeMB) {
      error = "*File size exceeds the maximum limit of 5 MB.";
    }

    // Set state only if there is no error
    if (!error) {
      this.setState(({
        [stateKeyRef]: selectedFile,
        error: {
          ...this.state.error,
          [stateKeyRef]: ""
        }
      } as unknown) as Pick<S, keyof S>);
    } else {
      this.setState(({
        error: {
          ...this.state.error,
          [stateKeyRef]: error
        }
      } as unknown) as Pick<S, keyof S>);
    }
  } else {
    // Reset file and error if no file selected
    this.setState(({
      [stateKeyRef]: null,
      error: {
        ...this.state.error,
        [stateKeyRef]: error
      }
    } as unknown) as Pick<S, keyof S>);
  }
};

  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
