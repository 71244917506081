import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
// Customizable Area Start
import storage from "../../framework/src/StorageProvider";

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  results: any[];
  handlePageChange:(event: React.ChangeEvent<unknown>, page: number)=>void
  currentPage:number
  totalPages:number
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading:boolean,
  jobDetails:{data:{
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      description: string,
      body: string,
      job_title: string,
      job_description: string,
      per_day_charge: null | number,
      budget: string,
      duration: string
      employment_term: string,
      estimate:string,
      skills: any[],
      location: string,
      file: {
          url: null
      },
      account_id: number,
      created_at: string,
      updated_at: string,
      proposals: number,
      messaged: number,
      hired: number,
      is_drafted: true,
      est_budget: null,
      last_viewed_by_client: string,
      rating: number | undefined,
      intermediate: null,
      model_name: string,
      images_and_videos: any[],
      file_name: string
  }}}
  searchSkillTerm:string;
  search:string;
  favouritesJobIds:any[]
  anchorEl: null,
  selectedOption: string;
  currentPage:number;
  totalPages:number;
  drawerOpen:boolean;
  selectedOptions: string[];
  jobTypeList:{
    id: number,
    value: string,
    label: string,
  }[];
  ClientLocationList:{
    id: number,
    value: string,
    label: string,
  }[];
  ProjectLengthList:{
    id: number,
    value: string,
    label: string,
  }[];
  jobDurationList:{
    id: number,
    value: string,
    label: string,
  }[];
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class GlobalSeachJobsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  jobsProfileId: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      loading:true,
      search:"",
      drawerOpen:false,
      selectedOptions: [],
      jobDetails:{data:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: '',
          description: "",
          body: "",
          job_title: "",
          job_description: "",
          per_day_charge: 0,
          budget: "",
          duration: "",
          employment_term: "",
          estimate: "",
          skills: [],
          location: "",
          file: {
              url: null
          },
          account_id: 76,
          created_at: "",
          updated_at: "",
          proposals: 0,
          messaged: 0,
          hired: 0,
          is_drafted: true,
          est_budget: null,
          last_viewed_by_client: "",
          rating: 0,
          intermediate: null,
          model_name: "",
          images_and_videos: [],
          file_name: ""
      }}},
      searchSkillTerm:"",
      favouritesJobIds:[],
      anchorEl: null,
      selectedOption: 'Most Recent',
      currentPage:1,
      totalPages:0,
      jobTypeList: [
        {
          id: 1,
          value: 'Per Day Rate',
          label: "Per Day Rate",
        },
        {
          id: 2,
          value: 'Project Budget',
          label: "Project Budget",
        },
      ],
      ClientLocationList: [
        {
          id: 1,
          value: 'India',
          label: "India",
        },
        {
          id: 2,
          value: 'Pakistan',
          label: "Pakistan",
        },
        {
          id: 3,
          value: 'Afganistan',
          label: "Afganistan",
        }
      ],
      ProjectLengthList: [
        {
          id: 1,
          value: '3 to 6 months',
          label: "3 to 6 months",
        },
        {
          id: 2,
          value: '1 to 3 months',
          label: "1 to 3 months",
        },
        {
          id: 3,
          value: 'Less than 1 month',
          label: "Less than 1 month",
        }
      ],
      jobDurationList: [
        {
          id: 1,
          value: 'Less than 6 months',
          label: "Less than 6 months",
        },
        {
          id: 2,
          value: 'More than 6 months',
          label: "More than 6 months",
        },
      ],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    setTimeout(() => {
      this.setState({ loading: false });
  }, 2000);
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    if ((prevState.search !== this.state.search)||(prevState.currentPage !== this.state.currentPage)) {
    }
  }
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleClick = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = (option: string) => {
    this.setState({ anchorEl: null, selectedOption: option });
  };
formatDate(utcDateString: string): { date: string, time: string } {
    const utcDate: Date = new Date(utcDateString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate: string = utcDate.toLocaleDateString('en-US', dateOptions);
    const formattedTime: string = utcDate.toLocaleTimeString('en-US', timeOptions);
    return { date: formattedDate, time: formattedTime };
}

toggleDrawer=(id?:any)=> {
  this.setState({ drawerOpen: !this.state.drawerOpen });
  this.getJobsProfileDetails(id)
}
handleCheckboxChange = (value: string) => {
  this.setState((prevState) => {
    if (prevState.selectedOptions.includes(value)) {
      return {
        selectedOptions: prevState.selectedOptions.filter((option) => option !== value),
      };
    } else {
      return {
        selectedOptions: [...prevState.selectedOptions, value],
      };
    }
  });

};
handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
  this.setState({ currentPage: page });
  this.props.handlePageChange(event, page);
};
getJobsProfileDetails = async (id:any) => {
  const header = {
    "Content-Type": webConfigJSON.validationApiContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.jobsProfileId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_static_content/landing_page_contents/show_job?id=${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (requestCallId === this.jobsProfileId) {
        if (response) {
          this.setState({jobDetails:response});
        }
      }
    }
    // Customizable Area End
  }
}
