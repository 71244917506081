//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
  // Customizable Area Start
import {
  Grid, Typography
} from "@material-ui/core";
import NavBar from "../../landingpage/src/NavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
  // Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter"
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavBar {...this.props} />
        <Grid container style={Styles.gridContainer}>
          <Grid item xs={12}>
            <Typography style={Styles.aboutHeading}>{configJSON.aboutInvento}</Typography>
            <Typography style={Styles.aboutText}>{configJSON.description}</Typography>
          </Grid>
        </Grid>
        <FooterWeb />
      </ThemeProvider>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Styles = {
  gridContainer: {
    padding: '5% 7%'
  },
  aboutHeading: {
    fontSize: '2.5rem',
    fontWeight: 500,
    color: '#206FC4'
  },
  aboutText: {
    fontSize: '1.2rem',
    marginTop: '10px',
    lineHeight: '35px',
    textAlign: 'justify'
  }
}
// Customizable Area End
