import React from "react";
//Customizable Area Start
import { Box, Button, Grid, Link, Typography, styled } from "@material-ui/core";
import ActiveContractProjectsController, { Props } from './ActiveContractProjectsController.web'
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
export const webConfigJSON = require("./config");
// Customizable Area End

export default class ActiveContractProjects extends ActiveContractProjectsController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start
    
    renderMain = () => {
        return (
            <Grid container spacing={2} lg={12} style={webStyle.gridBox}>
                <Grid item lg={12} xs={12} style={webStyle.myJobsContainer}>
                    <Box style={{ display: "flex" }}>
                        <UpperText>{webConfigJSON.myJobs}</UpperText>
                        <ArrowIcon>{">"}</ArrowIcon>
                        <UpperText>{webConfigJSON.allcontract}</UpperText>
                        <ArrowIcon>{">"}</ArrowIcon>
                        <ActiveText>{webConfigJSON.activecontract}</ActiveText>
                    </Box>
                </Grid>
                <Grid item lg={12} xs={12}>
                <Box style={{ marginTop: "30px" }}>
                <HeaderTxt>{webConfigJSON.activecontract}</HeaderTxt>
                <TitleTxt>
                    {webConfigJSON.textMain}
                </TitleTxt>
                <TitleTxt>
                    {webConfigJSON.textreme}
                    <LinkText>{webConfigJSON.startlink}</LinkText>
                </TitleTxt>
            </Box>
                </Grid>
                <Grid item lg={12} style={webStyle.myJobsContainer}>
                    <ActiveFont>{webConfigJSON.project}</ActiveFont>
                </Grid>
                <Box style={webStyle.boxCotainer}>
                    <Typography style={webStyle.filedText}>{webConfigJSON.fieldmanagementservice}</Typography>
                    <Box style={webStyle.borderline}></Box>
                    <Box style={webStyle.boxHead}>
                        <MainBox>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.projectownername}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.timeDuration}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.charge}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.totalhourstracked}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.clientcetimation}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.totalearned}</Typography>
                        </MainBox>
                        <MainBox>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.rahulsharma}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.dec24}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.rate145}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.hours72}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.hr120}</Typography>
                            <Typography style={webStyle.filedBox}>{webConfigJSON.$9250}</Typography>
                        </MainBox>
                    </Box>
                    <Box style={webStyle.borderline}></Box>
                    <Typography style={webStyle.filedText}>{webConfigJSON.projectdeatils}</Typography>
                    <Typography style={webStyle.textContaine}>{webConfigJSON.lorem}</Typography>
                    <Box style={webStyle.borderline}></Box>
                    <Button
                    variant="contained" 
                    style={webStyle.disputButton} onClick={this.goToResolvePage} data-testid="raiseButton">
                        {webConfigJSON.raiseDisput}
                    </Button>
                </Box>
            </Grid>
        )
    };

    // Customizable Area End

    render() {
        //Customizable Area Start
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                {this.renderMain()}
                <FooterWeb />
            </>
        );
        //Customizable Area End
    }
}

//Customizable Area Start

const webStyle = {
    myJobsContainer: {
        display: "flex",
        width: '1250px'
    },

    arrowImg: {
        marginTop: "4px",
        height: "13px"
    },

    gridBox: {
        display: 'flex',
        margin: "45px 35px 45px 35px",
        overflow: "hidden"
    },

    textContaine: {
        fontSize: "12px",
        color: "#3F3F3F",
        fontFamily: 'Inter',
        width: '1230px',
        margin: "12px",
        lineHeight: "23px"
    },

    textContainer: {
        fontSize: "12px",
        color: "#3F3F3F",
        fontFamily: 'Inter',
        width: '1230px',
        lineHeight: "23px"
    },

    boxCotainer: {
        background: "#F1F2F4",
        width: "1250px",
        height: "100%",
        borderRadius: "12px"
    },

    filedBox: {
        margin: "12px",
        fontSize: "15px",
        color: "#3F3F3F"
    },


    filedText: {
        color: "#3F3F3F",
        marginLeft: "1px",
        margin: "12px",
        fontFamily: "Inter",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "29px",
        letterSpacing: "0px",
    },

    borderline: {
        height: "1px",
        borderRadius: "1px",
        background: "#D5D5D4"
    },

    disputButton: {
        background: "#2378D1",
        color: "#ffff",
        margin: "12px 20px"
    },

    boxTextContent: {
        color: "#3F3F3F",
        lineHeight: "21px",
        margin: "14px",
        fontSize: "12px"
    },

    mainBox: {
        display: "flex",
        flexDirection: 'column'
    },

    boxHead: {
        display: 'flex'
    }
}

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: 'column'
})

const ActiveFont = styled(Typography)({
    fontWeight: "bold",
    fontSize: '26px',
    color: "#3F3F3F",
    fontFamily: 'Inter'
})

const ActiveText = styled(Typography)({
    color: "#206FC4",
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left"
});

const UpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const LinkText = styled(Link)({
    color:"#353535",
    fontFamily: "Inter",
fontSize: "14px",
fontWeight: 600,
lineHeight: "17px",
letterSpacing: "0px",
textAlign: "left",
textDecoration:'underLine'
});

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#353535"
});

const HeaderTxt = styled(Typography)({
    fontSize: "30px",
    color: "#3F3F3F",
    fontFamily: "Inter",
    fonSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    letterSpacing: "0px",
    textAlign: "left"
});

//Customizable Area End
