import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { editIcon } from "./assets";
import CancelIcon from "@material-ui/icons/Cancel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    },
  },
  typography: {
    fontFamily: "Inter,Rubik",
  },
});
// Customizable Area End
import FreelancerPersonalDetailsController, {
  Props,
  configJSON,
} from "./FreelancerPersonalDetailsController";

export default class FreelancerPersonalDetails extends FreelancerPersonalDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEditContactModal = () => {
    return (
      <ThemeProvider theme={theme}>
        <Modal
          open={this.state.editContactOpen}
          onClose={this.handleContactEditModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={webStyles.modalContainer}
          data-testid="edit-contact-modal"
        >
          <Box style={webStyles.modalView}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Box style={webStyles.editContainer}>
                  <Typography style={webStyles.editText}>
                    {configJSON.editContact}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={this.handleContactEditModal}
                  >
                    <CancelIcon fontSize="small" color="primary" />
                  </IconButton>
                </Box>
                <hr style={webStyles.Hr} />
                <Box style={webStyles.modalField}>
                  <Grid container>
                    <Grid item sm={12} md={12}>
                      <Box style={webStyles.countryPhone}>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.name}
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="user_name"
                            data-testid="name-field"
                            placeholder={configJSON.usernamePlaceholder}
                            size="small"
                            error={!this.nameReg.test(this.state.first_name)}
                            helperText={
                              !this.nameReg.test(this.state.first_name) &&
                              configJSON.nameFiled
                            }
                            InputProps={{ style: webStyles.textFieldProps }}
                            fullWidth
                            value={this.state.first_name}
                            onChange={this.handleFirstNameChange}
                          />
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.phone}
                          </Typography>
                          <TextField
                            name="full_phone_number"
                            onKeyDown={this.preventETypeNumber}
                            data-testid="phone-number"
                            value={this.state.full_phone_number}
                            variant="outlined"
                            placeholder={configJSON.phoneNumPlaceholder}
                            size="small"
                            error={
                              !this.phoneReg.test(this.state.full_phone_number)
                            }
                            helperText={
                              !this.phoneReg.test(
                                this.state.full_phone_number
                              ) && configJSON.nameFiled
                            }
                            fullWidth
                            onChange={(e:any)=>this.handlePhoneNumberChange(e)}
                            InputProps={{ style: webStyles.textFieldProps }}
                          />
                        </Box>
                      </Box>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>
                          {configJSON.email}
                        </Typography>
                        <TextField
                          type="email"
                          name="email"
                          onChange={this.handleEmailChange}
                          data-testid="email-field"
                          fullWidth
                          value={this.state.email}
                          variant="outlined"
                          placeholder={configJSON.emailPlaceholder}
                          InputProps={{ style: webStyles.textFieldProps }}
                          size="small"
                          helperText={
                            !this.emailReg.test(this.state.email) &&
                            configJSON.invalidEmail
                          }
                          error={!this.emailReg.test(this.state.email)}
                        />
                      </Box>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>
                          {configJSON.address}
                        </Typography>
                        <TextField
                          size="small"
                          name="address"
                          fullWidth
                          variant="outlined"
                          value={this.state.address}
                          data-testid="address-field"
                          placeholder={configJSON.addressPlaceholder}
                          onChange={this.handleAddressChange}
                          InputProps={{ style: webStyles.textFieldProps }}
                        />
                      </Box>
                      <Box style={webStyles.btnSaveBox}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.updateUserDetails}
                          data-testid="update-btn"
                        >
                          {configJSON.save}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </ThemeProvider>
    );
  };
  renderEditBankModal = () => {
    return (
      <ThemeProvider theme={theme}>
        <Modal
          open={this.state.editBankModal}
          onClose={this.handleBankEditModal}
          aria-labelledby="bank-modal-title"
          aria-describedby="bank-modal-description"
          style={webStyles.modalContainer}
          data-testid="bank-edit-modal"
        >
          <Box style={webStyles.modalView}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Box style={webStyles.editContainer}>
                  <Typography style={webStyles.editText}>
                    {configJSON.editBank}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={this.handleBankEditModal}
                  >
                    <CancelIcon fontSize="small" color="primary" />
                  </IconButton>
                </Box>
                <hr style={webStyles.Hr} />
                <Box style={webStyles.modalField}>
                  <Grid container>
                    <Grid item sm={12} md={12}>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>
                          {configJSON.account}
                        </Typography>
                        <TextField
                          type="text"
                          onKeyDown={this.preventETypeNumber}
                          name="accountNo"
                          data-testid="accountNo-field"
                          value={this.state.bankDetails.accountNo}
                          variant="outlined"
                          placeholder={configJSON.accountNoPlaceholder}
                          size="small"
                          InputProps={{ style: webStyles.textFieldProps }}
                          fullWidth
                          onChange={this.handleBankChange}
                        />
                      </Box>
                      <Box style={webStyles.countryPhone}>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.panNumber}
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="PanNo"
                            data-testid="PanNo-field"
                            placeholder={configJSON.panNumPlaceholder}
                            size="small"
                            InputProps={{ style: webStyles.textFieldProps }}
                            fullWidth
                            value={this.state.bankDetails.PanNo}
                            onChange={this.handleBankChange}
                          />
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>
                            {configJSON.IfcsCode}
                          </Typography>
                          <TextField
                            name="IfscCode"
                            data-testid="IfscCode"
                            value={this.state.bankDetails.IfscCode}
                            variant="outlined"
                            placeholder={configJSON.IFSCPlaceholder}
                            size="small"
                            fullWidth
                            onChange={this.handleBankChange}
                            InputProps={{ style: webStyles.textFieldProps }}
                          />
                        </Box>
                      </Box>
                      <Box style={webStyles.btnSaveBox}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleBankEditModal}
                          data-testid="save-btn"
                        >
                          {configJSON.save}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </ThemeProvider>
    );
  };

  renderDialogResponse = () => {
    return (
      <Dialog
        data-testid="response-modal"
        onClose={this.handleDialog}
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={this.state.popUpMessage}
        style={webStyles.dialogOuterContainer}
      >
        <DialogContent>
          {this.state.responseMessage ? (
            <DialogContentText
              style={webStyles.successMessage}
              id="alert-dialog-description"
            >
              {configJSON.successMessage}
            </DialogContentText>
          ) : (
            <DialogContentText
              style={webStyles.failedMessage}
              id="alert-dialog-description2"
            >
              {configJSON.failedMessage}
            </DialogContentText>
          )}
          <DialogActions style={webStyles.btnOuterContainer}>
            <Button
              data-testid="ok-btn"
              color="primary"
              variant="contained"
              onClick={this.handleDialog}
            >
              {configJSON.okBtnText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box>
          <Grid container>
            <Grid item sm={12} md={12}>
              <Typography variant="h2" style={webStyles.profileText}>
                {configJSON.personal}
              </Typography>
              <Box style={webStyles.aboutBox}>
                <Box style={webStyles.editMainBox}>
                  <Typography style={webStyles.aboutMeHead}>
                    {configJSON.contactInfo}
                  </Typography>
                  <Box style={webStyles.editIconBox}>
                    <Typography>{configJSON.edit}</Typography>
                    <img
                      src={editIcon}
                      data-testid="edit-contact"
                      onClick={this.handleContactEditModal}
                    />
                  </Box>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.userHeading}>
                    {configJSON.userId}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.id}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.userHeading}>
                    {configJSON.name}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.first_name}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.userHeading}>
                    {configJSON.email}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.email}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.userHeading}>
                    {configJSON.address}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.address}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.userHeading}>
                    {configJSON.phone}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.full_phone_number}
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyles.aboutBox}>
                <Box style={webStyles.editMainBox}>
                  <Typography style={webStyles.aboutMeHead}>
                    {configJSON.bankDetails}
                  </Typography>
                  <Box style={webStyles.editIconBox}>
                    <Typography>{configJSON.edit}</Typography>
                    <img
                      src={editIcon}
                      onClick={this.handleBankEditModal}
                      data-testid="edit-bank"
                    />
                  </Box>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.bankHead}>
                    {configJSON.account}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.bankDetails.accountNo}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.bankHead}>
                    {configJSON.panNumber}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.bankDetails.PanNo}
                  </Typography>
                </Box>
                <Box style={webStyles.detailHeading}>
                  <Typography color="secondary" style={webStyles.bankHead}>
                    {configJSON.IfcsCode}
                  </Typography>
                  <Typography style={webStyles.detailsText}>
                    {this.state.bankDetails.IfscCode}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {this.renderDialogResponse()}
        {this.renderEditContactModal()}
        {this.renderEditBankModal()}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  aboutMeHead: {
    fontSize: "1.125rem",
    fontWeight: 100,
    marginBottom: "1%",
  },
  detailHeading: {
    display: "flex",
    marginTop: "1%",
  },
  detailsText: {
    fontSize: "0.87rem",
    fontWeight: 500,
    fontFamily: "Rubik",
  },
  userHeading: {
    fontSize: "0.87rem",
    width: "90px",
    fontWeight: 400,
  },
  successMessage: {
    color: "green",
  },
  failedMessage: {
    color: "red",
  },
  bankHead: {
    fontSize: "0.87rem",
    width: "120px",
    fontWeight: 400,
  },
  modalContainer: {
    width: "100px",
    height: "100px",
    margin: "auto",
    background: "#fff",
  },
  btnOuterContainer: {
    display: "flex",
    justifyContent: "center",
  },

  modalView: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 507,
    height: "auto",
    background: "#fff",
    borderRadius: "10px",
  },
  editContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 4px 0px 15px",
  },
  editText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "1.2rem",
    padding: "10px 0",
  },
  Hr: {
    height: "2px",
    background: "#DADADA",
    border: "none",
    margin: "0",
  },
  modalField: {
    padding: "15px",
  },
  label: {
    fontSize: "0.9rem",
    fontWeight: 600,
    marginBottom: "2%",
  },
  fieldBox: {
    marginBottom: "3%",
  },
  countryPhone: {
    display: "flex",
    gap: "15px",
  },
  dialogOuterContainer: {
    boxShadow: "none",
  },

  btnSaveBox: {
    textAlign: "end" as "end",
  },
  profileText: {
    fontSize: "2.25rem",
    fontWeight: 100,
    marginBottom: "2%",
  },
  aboutBox: {
    background: "#F2F2F2",
    borderRadius: "11px",
    padding: "15px 25px",
    marginTop: "2%",
  },
  editMainBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  editIconBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
  },
  textFieldProps: {
    borderRadius: "11px",
    borderColor: "#6C6C6C",
  },
};
// Customizable Area End
