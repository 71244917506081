import React from 'react';
// Customizable Area Start
import { Box, Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import ClientNavBar from '../../dashboard/src/Components/ClientNavBar.web';
import EditClientProfileController, { Props, configJSON } from './EditClientProfileController';
import { profileSettingImg } from './assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End

export default class EditClientProfile extends EditClientProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <ClientNavBar />
        <ThemeProvider theme={theme}>
        <ArrowBackIcon style={webStyles.arrowStyle}/>
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <div style={webStyles.profileDiv}>
        <img src={profileSettingImg}/>
        <Box style={{display:"flex",gap:"10px",justifyContent:"center"}}>
        <Typography style={webStyles.profileText}>Change profile picture </Typography>
        <EditIcon style={{background:"#2378D1" , color:"white", borderRadius:"100%",padding:"5px",marginTop:"8px"}} />
        </Box>
        </div>
          <Box style={webStyles.pageContainer}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <Box style={webStyles.Header}>Edit Details</Box>
                <Box style={webStyles.modalField}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>{configJSON.name}</Typography>
                        <TextField
                          variant="outlined"
                          name="first_name"
                          data-testid="name-field"
                          placeholder="enter name"
                          size="small"
                          InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                          fullWidth
                          inputProps={{ maxLength: 36 }}
                          value={this.state.userDetails.first_name}
                          onChange={this.handleChange}
                        />
                      </Box>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>{configJSON.email}</Typography>
                        <TextField
                          type="email"
                          name="email"
                          data-testid="email-field"
                          value={this.state.userDetails.email}
                          variant="outlined"
                          placeholder="enter email address"
                          size="small"
                          InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                          fullWidth
                          onChange={this.handleChange}
                        />
                      </Box>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>{configJSON.address}</Typography>
                        <TextField
                          name="address"
                          value={this.state.userDetails.address}
                          data-testid="address-field"
                          variant="outlined"
                          placeholder="enter address"
                          size="small"
                          maxRows={4}
                          onChange={this.handleChange}
                          inputProps={{ maxLength: 300 }}
                          fullWidth
                          InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C'} }}
                        />
                      </Box>
                      <Box style={webStyles.countryPhone}>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.labelCountry}>{configJSON.country}</Typography>
                          <TextField
                            name="country_code"
                            data-testid="country-code"
                            value={this.state.userDetails.country_code}
                            variant="outlined"
                            select
                            size="small"
                            fullWidth
                            onChange={this.handleChange}
                            style={webStyles.countryField}
                            InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                          >
                            {this.allCountries.map((country) =>
                              <MenuItem value={country.name} key={country.name}>{country.name}(+{country.phone})</MenuItem>
                            )}
                          </TextField>
                        </Box>
                        <Box style={webStyles.fieldBox}>
                          <Typography style={webStyles.label}>{configJSON.phone}</Typography>
                          <TextField
                            name="full_phone_number"
                            onKeyDown={this.preventETypeNumber}
                            data-testid="phone-number"
                            value={this.state.userDetails.full_phone_number}
                            variant="outlined"
                            placeholder="Phone Number"
                            size="small"
                            fullWidth
                            inputProps={{ maxLength: 15 }}
                            onChange={this.handleChange}
                            InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                          />
                        </Box>
                      </Box>
                      <Box style={webStyles.fieldBox}>
                        <Typography style={webStyles.label}>{configJSON.aboutMe}</Typography>
                        <TextField
                          name="about_me"
                          data-testid="about-me"
                          value={this.state.userDetails.about_me}
                          variant="outlined"
                          fullWidth
                          InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                          multiline
                          minRows={4}
                          inputProps={{ maxLength: 150 }}
                          onChange={this.handleChange}
                        />
                      </Box>
                      <Box style={webStyles.btnSaveBox}>
                        <Button color="primary" variant="contained" onClick={this.handleUpdate} data-testid="update-btn">{configJSON.save}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          </div>
        </ThemeProvider>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  pageContainer: {
    padding: '20px',
    width:"100%"
  },
  Header:{
    fontFamily:"Inter",
    fontSize:"26px",
    fontWeight:"bold" as "bold",
    marginBottom:"20px"
  },
  profileDiv:{
    margin:"30px 0 0 56px"
  },
  profileText:{
    color:"#2378D1",
    fontWeight : "bold" as "bold",
    marginTop:"10px",
    display:"flex" as "flex",
    justifyContent:"center" as "center",
    fontSize:"20px"
  },
  arrowStyle:{
   margin:"30px 0 0 30px"
  },
  modalField: {
    padding: '36px',
    background: 'rgb(245, 247, 249) none repeat scroll 0% 0%',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  },
  label: {
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: '2%'
  },
  labelCountry:{
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: '9%'
  },
  fieldBox: {
    marginBottom: '3%'
  },
  countryPhone: {
    display: 'flex',
    gap: '15px',
  },
  btnSaveBox: {
    textAlign: 'end' as 'end'
  },
  countryField: {
    width: '100%'
  },
};
// Customizable Area End
