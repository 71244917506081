import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import React, { ChangeEvent } from 'react';
import storage from 'framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  isFromHomePage?: boolean;
  profileImg?:string ;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: any;
  searchValue: any;
  menuItems: any;
  freelancerTitle: any;
  freelancerProfileImg:string
  profileAnchorEl:null|HTMLDivElement
  currentStatus:number;
  accountHolderName:string |null;
  authToken:string,
  isTokenExpired:boolean,
 
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerLawyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateOnlineStatusId:string;
  getProfileStatusId:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.updateOnlineStatusId="";
    this.getProfileStatusId="";
    this.state = {
      anchorEl: null,
      searchValue: '',
      menuItems: [],
      freelancerTitle: '',
      freelancerProfileImg:"",
      profileAnchorEl:null,
      currentStatus:0,
      accountHolderName:"",
      authToken:"",
      isTokenExpired:false,

  
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let profileinf = await storage.get("freelancerProfileInfo")
    let accountHolderName = await storage.get("accountHolderName")

    let imgUrl = JSON.parse(profileinf)?.attributes?.profile_image
    this.setState({
      menuItems: webConfigJSON.freelancerNavbarMenu,
      freelancerTitle: webConfigJSON.freelancerTitle,
      freelancerProfileImg:imgUrl ,
      accountHolderName:accountHolderName,
      authToken: await storage.get('authToken')
      
    });
    this.getProfileStatus()
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps:any) {
    // Check if props.profileImage has changed and is not undefined
    if (this.props.profileImg !== prevProps.profileImg && this.props.profileImg !== undefined) {
      // Update the state with the new profileImage
      this.setState({
        freelancerProfileImg: this.props.profileImg
      });
    }
  }
  handleClick = (event: any): void => {
    const { anchorEl } = this.state;
    if (Boolean(anchorEl)) {
      // If the same item is clicked again, close the popover
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      
      storage.set('searchedValue', this.state.searchValue);
    }
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleRedirection = (componentName: any) => {
    if (componentName) {
      this.props.navigation.navigate(componentName);
    }
  };
   handleClose = () => {
    this.setState({ profileAnchorEl: null });
  };
  handleProfileClick = (event:React.MouseEvent<HTMLImageElement, MouseEvent>)=> {
    this.setState({profileAnchorEl:event.currentTarget});
  };
  handleLogout=(componentName: any)=>{
    storage.remove("authToken");
    storage.remove("accountId");
    storage.remove("accountHolderName");
    storage.remove("userType")
    this.props.navigation.navigate(componentName)
  }
  getColorValue = (statusVal:number)=>{
    const activeBtn =  this.state.currentStatus == statusVal
    let   colorItem = {
      color:"#ffffff",
      backgroundColor:"#2275CD"
    };
    if(!activeBtn){
           
          colorItem= {
              color:"#2275CD",
              backgroundColor:"#ffffff"
            }

    }
      return colorItem;
 }
  
  updateOnlineStatus = (currentStatus:number) => {
    const updateOnlineStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.apiContentType,
      token: this.state.authToken
    };
    

    this.updateOnlineStatusId = updateOnlineStatusRequest.messageId;
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.updateProfileStatusEndPoint +`?online_status=${currentStatus===0?"online":"away"}`
        
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.patchApiMethodType
    );

    runEngine.sendMessage(
      updateOnlineStatusRequest.id,
      updateOnlineStatusRequest
    );
  };

  getProfileStatus = () => {
    const getProfileStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.apiContentType,
      token: this.state.authToken
    };
    

    this.getProfileStatusId = getProfileStatusRequest.messageId;
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getProfileStatusEndPoint
        
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(
      getProfileStatusRequest.id,
      getProfileStatusRequest
    );
  };
  handleResponse=(responseJson: {errors?:any; exception?: string; current_user: { online_status: string; }; })=>{
    if(responseJson.exception?.includes("#<JWT") ||responseJson.errors?.some((error: any) => error.toLowerCase().includes("token"))){
      this.setState({isTokenExpired: true});

    }else {
      this.setState({ currentStatus: responseJson.current_user.online_status =="away"?1:0 });

    }
  }
  
  
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(this.getProfileStatusId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
       
        if(responseJson){
       this.handleResponse(responseJson);
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);          
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
