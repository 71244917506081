import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const config = require("./config");
import { authImage } from "./assets";
import { Horizontalinventohub } from "../../landingpage/src/assets";

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter, Rubik, Roboto"
  }
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderResponseDialog = () => {
    return (
      <Dialog
        open={this.state.popUpMessage}
        onClose={this.handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={styles.dialogOuterContainer}
        data-testid="response-modal"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.mailNotification}
          </DialogContentText>

          <DialogActions style={styles.btnOuterContainer}>
            <Button
              onClick={this.handleDialog}
              data-testid="ok-btn"
              color="primary"
              variant="contained"
            >
              {config.okBtnText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container style={{overflow:"hidden"}}>
          <Grid item xs={12} sm={6} style={styles.firstContainer}>
            <img src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>

            <Typography style={styles.forgotPassword}>
              {config.forgotPassowrd}
            </Typography>
            <Typography style={styles.details}>
              {config.emailEnterDetails}
            </Typography>
            <Box>
              <Typography style={styles.emailLable}>
                {config.emailLabel}
              </Typography>
              <TextField
                variant="outlined"
                value={this.state.emailId}
                placeholder={config.emailPlaceholder}
                onChange={this.handleEmailChange}
                fullWidth
                InputProps={{
                  style: styles.inputPropsStyle
                }}
                data-testid="email-id"
                error={this.state.validationError || this.state.requireError}
                helperText={this.state.errorMessage}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={styles.buttonContainer}
              onClick={this.handleSubmit}
              data-testid="submit-btn"
            >
              {config.restButtonText}
            </Button>
            <Typography style={styles.backToText}>
              {config.backTo}
              <span>
                <Link
                  underline="always"
                  style={styles.loginText}
                  onClick={this.handleBackToLogin}
                  data-testid="login-btn"
                >
                  {config.login}
                </Link>
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={authImage}
              alt="forgot-password"
              style={styles.authImage}
            />
          </Grid>
        </Grid>
        {this.renderResponseDialog()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  firstContainer: {
    padding: "4% 9%"
  },
  inventoText: {
    fontSize: "2.25rem",
    marginBottom: "18%"
  },
  invento:{
    width:"300px",
    height:"61px",
    objectFit:"cover",
     marginLeft:"10px",
     marginBottom: "45px"
  },
  forgotPassword: {
    fontSize: "2rem",
    color: "#206FC4",
    fontWeight: 700,
    marginBottom: "1%"
  },
  details: {
    fontSize: "1.125rem",
    lineHeight: "21.78px",
    marginBottom: "10%"
  },
  emailLable: {
    fontSize: " 1.25rem",
    color: "#535353",
    fontWeight: 500,
    marginBottom: "2%"
  },
  inputPropsStyle: {
    borderRadius: "10px"
  },
  buttonContainer: {
    borderRadius: "10px",
    width: "100%",
    margin: "11% 0 10%",
    padding: "15px",
    fontWeight: 700,
    fontSize: "1rem"
  },
  backToText: {
    fontSize: "1.25rem",
    color: "#6F6E6E",
    fontWeight: 500
  },
  loginText: {
    fontWeight: 600,
    marginLeft: "9px",
    cursor: "pointer"
  },
  authImage: {
    width: "100%",
    height: "100%"
  },
  dialogOuterContainer: {
    boxShadow: "none"
  },
  btnOuterContainer: {
    display: "flex",
    justifyContent: "center"
  }
};
// Customizable Area End
