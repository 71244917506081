import React from 'react';
// Customizable Area Start
import {
  Box,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  withStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { infoIcon,uploadIcon } from '../../blocks/dashboard/src/assets';

import FreelancerIpIllustrationController, {
  Props
} from './FreelancerIpIllustrationController.web';
import { green } from '@material-ui/core/colors';
import { FileUploadArea, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';
import { importantField } from './AppHeader.web';

const svgIcon = `
 <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding-right:9px; padding-top:4px; cursor: pointer;">
 <path d="M11.6883 11.5325C11.5325 11.5325 20.4156 2.64935 20.4156 2.64935C21.039 2.02597 21.039 1.09091 20.4156 0.467531C19.7922 -0.155846 18.8571 -0.155846 18.2338 0.467531L10.4416 8.1039L2.64935 0.467532C2.02597 -0.155845 1.09091 -0.155845 0.467532 0.467532C0.155843 0.779221 -4.76851e-07 1.09091 -4.56415e-07 1.55844C-4.35979e-07 2.02597 0.155843 2.33766 0.467532 2.64935L9.1948 11.5325C9.97402 12.1558 11.5325 11.5325 11.6883 11.5325Z" fill="black"/>
 </svg>
 `;
const GreenCheckbox1 = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: "rgb(56, 70, 184)"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

// Customizable Area End
// Customizable Area Start
export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          color: active || completed ? '#FFF' : '#2089F4',
          background: active || completed ? '#3846B8' : '#D2E3F3',
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
// Customizable Area End
export default class FreelancerIpIllustration extends FreelancerIpIllustrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div
      style={{
        width: 'inherit',
        background: '#FFF',
        borderRadius: '10px',
        display: this.state.openInfoPopper ? 'inline-block' : 'none',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
      }}
    >
      <Typography
        style={{
          ...webStyle.header,
          fontWeight: 400,
          padding: '10px 20px',
          fontSize: '18px',
          color: '#4C4C4C',
          lineHeight: '35px',
          letterSpacing: '0.27',
        }}
      >
        {content}
      </Typography>
    </div>
  );

  step1 = () => {
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          data-testid="IPI1-infoButton"
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Qualification
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '10px',width:"30px" }}
          />
        </Box>

        {this.popper(
          `It is crucial to share your qualifications and experience clearly and concisely to attract potential clients and generate business. Make sure to include this information on your website, social media platforms, and any other marketing materials you use.
           Clearly state your area of expertise, relevant experience, and qualifications. 
           This will help potential clients understand your capabilities and make informed decisions about working with you. Don't be afraid to showcase your skills and accomplishments, as it can set you apart from other illustrators and attract more business.`
        )}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                <Typography style={{...webStyle.header,display:"flex" }}>Legal{importantField()}</Typography>
                <TextField
                  data-testid="IPI1-legal"
                  placeholder="Name of College"
                  variant="outlined"
                  value={this.state.legal}
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    }
                  }}
                  style={webStyle.inputField}
                  name="legal"
                  onChange={this.handletextChange}
                  error={this.state.error['legal']}
                  helperText={this.state.error['legal']}
                />
                {this.state.legal === "" && <Typography style={{ color: 'red' }}>{this.state.legalError}</Typography>}
              </Box>

              <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                <Typography style={webStyle.header}>
                  LinkedIn Profile Link:
                </Typography>
                <TextField
                  data-testid="IPI1-linkedInProfileLink"
                  placeholder="https://www.google.com/intl/en_in/business/"
                  variant="outlined"
                  value={this.state.linkedInProfileLink}
                  InputProps={{
                    style: {
                      ...webStyle.outlinedInput
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachFileIcon
                          style={{ transform: 'rotate(45deg)', fill: 'blue' }}
                        />
                      </InputAdornment>
                    )
                  }}
                  style={webStyle.inputField}
                  name="linkedInProfileLink"
                  onChange={this.handleChange}
                />
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} width={'35%'}>
              <Typography style={{...webStyle.header,display:"flex" }}>Other{importantField()}</Typography>
              <TextField
                data-testid="IPI1-other"
                placeholder="Name of College"
                inputProps={{
                  style: {
                    ...webStyle.outlinedInput
                  }
                }}
                variant="outlined"
                value={this.state.other}
                name="other"
                onChange={this.handletextChange}
                  error={this.state.error['other']}
                  helperText={this.state.error['other']}
                style={webStyle.inputField}
              />
              {this.state.other === "" && <Typography style={{ color: 'red' }}>{this.state.otherError}</Typography>}

              <Typography style={{...webStyle.header,display:"flex" }}>
                No. of experience in IP illustration{importantField()}
              </Typography>
              <TextField
                data-testid="IPI1-experienceInIpillusration"
                inputProps={{
                  style: {
                    ...webStyle.outlinedInput
                  }
                }}
                placeholder="Total Experience"
                style={webStyle.inputField}
                variant="outlined"
                name="experienceInIpillusration"
                value={this.state.experienceInIpillusration}
                onChange={this.handleExpFieldChange}
                error={this.state.error['experienceInIpillusration']}
                  helperText={this.state.error['experienceInIpillusration']}
              />
              {this.state.experienceInIpillusration === "" && <Typography style={{ color: 'red' }}>{this.state.experienceInIpillusrationError}</Typography>}

              <Typography style={webStyle.header}>
                Are you an individual or firm?{importantField()}
              </Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="type"
                name="type"
                value={this.state.type}
                onChange={this.handleChange}
                data-testid="IPI1-type"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      style={
                        this.state.type === 'Yes'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.type === 'Yes'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      style={
                        this.state.type === 'No'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.type === 'No'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="No"
                />
              </RadioGroup>
              {this.state.type === "" && <Typography style={{ color: 'red' }}>{this.state.typeError}</Typography>}
            </Box>

            <Box display={'flex'} flexDirection={'column'} width={'85%'}>
              <Typography style={webStyle.header}>Focus Area:{importantField()}</Typography>
              <RadioGroup
                style={{
                  ...webStyle.RadioGroupBox,
                  display:"flex",
                  gap:"30px",
                  flexDirection:"row"
                }}
                aria-label="focusArea1"
                name="focusArea1"
                value={this.state.focusArea1}
                onChange={this.handleChange}
                data-testid="IPI1-focusArea1"
              >
                <FormControlLabel
                  value="2D"
                  control={
                    <Radio
                      style={
                        this.state.focusArea1 === '2D'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea1 === '2D'
                      ? { ...webStyle.SelectedRadioButton,flex:1  }
                      : { ...webStyle.UnSelectedRadioButton,flex:1 }
                      
                  }
                  label="2D"
                />
                <FormControlLabel
                  value="3D"
                  control={
                    <Radio
                      style={
                        this.state.focusArea1 === '3D'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea1 === '3D'
                      ? { ...webStyle.SelectedRadioButton,flex:1  }
                      : { ...webStyle.UnSelectedRadioButton,flex:1  }
                  }
                  label="3D"
                />
                <FormControlLabel
                  value="Technology like CAD"
                  control={
                    <Radio
                      style={
                        this.state.focusArea1 === 'Technology like CAD'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea1 === 'Technology like CAD'
                      ? { ...webStyle.SelectedRadioButton,flex:1  }
                      : { ...webStyle.UnSelectedRadioButton,flex:1  }
                  }
                  label="Technology like CAD"
                />
                <FormControlLabel
                  value="Other"
                  control={
                    <Radio
                      style={
                        this.state.focusArea1 === 'Other'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea1 === 'Other'
                      ? { ...webStyle.SelectedRadioButton,flex:1 }
                      : { ...webStyle.UnSelectedRadioButton,flex:1  }
                  }
                  label="Other"
                />
              </RadioGroup>
              {this.state.focusArea1 === "" && <Typography style={{ color: 'red' }}>{this.state.focusArea1Error}</Typography>}
            </Box>
            <Box display={'flex'} flexDirection={'column'} width={'35%'}>
              <Typography style={webStyle.header}>Focus Area:{importantField()}</Typography>
              <RadioGroup
                style={webStyle.RadioGroupBox}
                aria-label="focusArea2"
                name="focusArea2"
                value={this.state.focusArea2}
                onChange={this.handleChange}
                data-testid="IPI1-focusArea2"
              >
                <FormControlLabel
                  value="Industry Specific"
                  control={
                    <Radio
                      style={
                        this.state.focusArea2 === 'Industry Specific'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea2 === 'Industry Specific'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="Industry Specific"
                />
                <FormControlLabel
                  value="All"
                  control={
                    <Radio
                      style={
                        this.state.focusArea2 === 'All'
                          ? { color: '#206FC4' }
                          : { color: '#979797' }
                      }
                    />
                  }
                  style={
                    this.state.focusArea2 === 'All'
                      ? { ...webStyle.SelectedRadioButton }
                      : { ...webStyle.UnSelectedRadioButton }
                  }
                  label="All"
                />
              </RadioGroup>
              {this.state.focusArea2 === ""  && <Typography style={{ color: 'red' }}>{this.state.focusArea2Error}</Typography>}
            </Box>
          </Box>
        </Box>
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              color="default"
              data-testid="ipIllustration-back"
              onClick={this.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              color="primary"
              onClick={this.handleNext1}
              data-testid="ipIllustration-next"
            >
              Next
            </Button>
          </Box>
      </Box>
    );
  };
  step2 = () => {
    const countryPatentLawGuidelines = [
      'USPTO',
      'European Union',
      'Indian',
      'Japanese',
      'Chinese',
      'Other'
    ];
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'} width={'45%'}>
            <Typography style={{...webStyle.header,display:"flex"}}>
              Country Of Operation{importantField()}
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="countryOfOperation"
              name="countryOfOperation"
              value={this.state.countryOfOperation}
              data-testid="IPI2-countryOfOperation"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="CountryWide"
                control={
                  <Radio
                    style={
                      this.state.countryOfOperation === 'CountryWide'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.countryOfOperation === 'CountryWide'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="CountryWide"
              />
              <FormControlLabel
                value="WorldWide"
                control={
                  <Radio
                    style={
                      this.state.countryOfOperation === 'WorldWide'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.countryOfOperation === 'WorldWide'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="WorldWide"
              />
            </RadioGroup>
            {this.state.countryOfOperation === "" && <Typography style={{ color: 'red' }}>{this.state.countryOfOperationError}</Typography>}

            <Typography style={{...webStyle.header,display:"flex"}}>City Of Operation{importantField()}</Typography>
            <TextField
              data-testid="IPI2-cityOfOperation"
              placeholder="Upto 20 Cities "
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              variant="outlined"
              value={this.state.cityOfOperation}
              name="cityOfOperation"
              onChange={this.handletextChange}
              style={webStyle.inputField}
            />
            {this.state.cityOfOperation === "" && <Typography style={{ color: 'red' }}>{this.state.cityOfOperationError}</Typography>}

            <Typography style={{...webStyle.header,display:"flex"}}>
            Which country's patent law guidelines are you familiar with?{importantField()}
            </Typography>

            <ClickAwayListener onClickAway={this.handleClickAway}>
              <Box style={webStyle.selectMainBox}>
                <div
                  id="SelectMainBox"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '18px',
                    fontFamily: "'Inter', sans-serif",
                    cursor: 'pointer'
                  }}
                  onClick={() => this.handleSelectBoxClick()}
                >
                  {this.state.patentOffice.length === 0 ? (
                    <span
                      style={{
                        marginLeft: '10px',
                        color: '#000000',
                        fontFamily: "'Inter', sans-serif",
                        textAlign: 'center'
                      }}
                    >
                      Select Patent Office
                    </span>
                  ) : (
                    <span
                      style={{
                        flex: 1,
                        marginLeft: '10px',
                        flexWrap: 'wrap',
                        fontFamily: "'Inter', sans-serif",
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {this.state.patentOffice.join(', ')}
                    </span>
                  )}
                  <div
                    data-test-id="main-box-click"
                    style={{ paddingRight: 13, borderRadius: '10px' }}
                    dangerouslySetInnerHTML={{ __html: svgIcon }}
                  />
                </div>
              </Box>
            </ClickAwayListener>
            {this.state.showpatentOffice && (
              <Grid style={webStyle.selectBox} container>
                {countryPatentLawGuidelines.map((item, index) => (
                  <Grid key={`${index}job`} item xs={6}>
                    <FormControlLabel
                      control={<GreenCheckbox1 />}
                      checked={this.state.patentOffice.includes(item)}
                      label={
                        <Typography style={webStyle.labelStyle3}>
                          {item}
                        </Typography>
                      }
                      onChange={() => this.handlePatentOfficeSelection(item)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {this.state.patentOffice.length === 0 && <Typography style={{ color: 'red' }}>{this.state.patentOfficeError}</Typography>}
          </Box>

          <Box display={'flex'} flexDirection={'column'} width={'70%'}>
            <Typography style={webStyle.header}>Focus Area:{importantField()}</Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridTemplateColumns: 'repeat(4) 1fr',
                gridAutoFlow: 'column'
              }}
              aria-label="focusArea3"
              name="focusArea3"
              value={this.state.focusArea3}
              onChange={this.handleChange}
              data-testid="IPI2-focusArea3"
            >
              <FormControlLabel
                value="Utility Patent Ilustration"
                control={
                  <Radio
                    style={
                      this.state.focusArea3 === 'Utility Patent Ilustration'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea3 === 'Utility Patent Ilustration'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Utility Patent Illustration"
              />
              <FormControlLabel
                value="Design Patent Illustraion"
                control={
                  <Radio
                    style={
                      this.state.focusArea3 === 'Design Patent Illustraion'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea3 === 'Design Patent Illustraion'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Design Patent Illustration"
              />
              <FormControlLabel
                value="Both"
                control={
                  <Radio
                    style={
                      this.state.focusArea3 === 'Both'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea3 === 'Both'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Both"
              />
              <FormControlLabel
                value="Other"
                control={
                  <Radio
                    style={
                      this.state.focusArea3 === 'Other'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.focusArea3 === 'Other'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Other"
              />
            </RadioGroup>
            {this.state.focusArea3 === "" && <Typography style={{ color: 'red' }}>{this.state.focusArea3Error}</Typography>}
          </Box>

          <Box display={'flex'} flexDirection={'column'} width={'45%'}>
            <Typography style={{...webStyle.header,display:"flex"}}>Fees{importantField()}</Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="Fees"
              name="feesType"
              value={this.state.feesType}
              data-testid="IPI2-feesType"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="Per Assignment"
                control={
                  <Radio
                    style={
                      this.state.feesType === 'Per Assignment'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesType === 'Per Assignment'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Per Assignment"
              />
              <FormControlLabel
                value="Page"
                control={
                  <Radio
                    style={
                      this.state.feesType === 'Page'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.feesType === 'Page'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Page"
              />
            </RadioGroup>
            {this.state.feesType === "" && <Typography style={{ color: 'red' }}>{this.state.feesTypeError}</Typography>}

            <TextField
              data-testid="IPI2-feesAmount"
              placeholder="Enter Fees"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput,
                }
              }}
              variant="outlined"
              value={this.state.feesAmount}
              name="feesAmount"
              onChange={this.handleFeesFieldChange}
              error={this.state.error['feesAmount']}
              helperText={this.state.error['feesAmount']}
              style={webStyle.inputField}
            />
            {this.state.feesAmount === "" && <Typography style={{ color: 'red' }}>{this.state.feesAmountError}</Typography>}
          </Box>
        </Box>
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              color="default"
              data-testid="ipIllustration-back"
              onClick={this.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              color="primary"
              onClick={this.handleNext2}
              data-testid="ipIllustration-next"
            >
              Next
            </Button>
          </Box>
      </Box>
    );
  };
  step3 = () => {
    const currentStateData: any = this.state;
    return (
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Success Stories
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '10px', width:"30px" }}
          />
        </Box>
        {this
          .popper(`Sharing your success stories and awards can help you stand out from the competition and attract potential cliets.
          Be sure to include this information on your website, social media platforms, and any other marketing materials you use.
          Clearly state the accolades you have received and how they demonstrate your skills and expertise.
          Sharing success stories can also help potential Client's understand how you can help them achieve their goals.
          By showcasing your accomplishments, you can build trust and credibility with potential clients and increase your chances of generating business.`)}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box flex={0.5}>
            <Typography style={webStyle.header}>
              Have you been featured in the media?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="featured in the mediaegory"
              name="featuredInTheMedia"
              value={this.state.featuredInTheMedia}
              data-testid="IPI3-featuredInTheMedia"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.featuredInTheMedia === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.featuredInTheMedia === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.featuredInTheMedia === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.featuredInTheMedia === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.featuredInTheMedia && <Typography style={{ color: 'red' }}>{this.state.featuredInTheMediaError}</Typography>}

            <Typography style={webStyle.header}>
              Have you spoken at an IP conference?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="IpConference"
              name="IpConference"
              value={this.state.IpConference}
              data-testid="IPE3-IpConference"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.IpConference === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.IpConference === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.IpConference === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.IpConference === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.IpConference && <Typography style={{ color: 'red' }}>{this.state.IpConferenceError}</Typography>}

            <Typography style={webStyle.header}>
              Have you published any articles?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="article"
              name="article"
              value={this.state.article}
              data-testid="IPI3-article"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    style={
                      this.state.article === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    style={
                      this.state.article === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            {!this.state.article && <Typography style={{ color: 'red' }}>{this.state.articleError}</Typography>}

          </Box>
          <Box
            style={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateRows: '1 1fr',
              rowGap: '20px',
              flex: 0.3
            }}
          >
             {[{title: "Attach Image or Video(max-5MB)",state:"step3FileUplod1", error: 'step3FileUplod1' },
              {title:"Attach Image or Video(max-5MB)",state:"step3FileUplod2", error: 'step3FileUplod2' },
              {title:"Attach link",state:"step3FileUplod3", error: 'step3FileUplod3'},
            ].map((item: any, index: any) => (
              <>
              <FileUploadArea
                testId={`FreelancerIpIllustration-step-3${index +
                  1}`}
                id={`FreelancerIpIllustration-step-3${index +
                  1}`}
                uploadIcon={uploadIcon}
                btnContent={
                  currentStateData[item.state]
                    ? currentStateData[item.state]?.name
                    :item.title}
                    onChange={this.handleFileUpload}
                    stateKeyRef={item.state}
                    disabled={(this.state.IpConference === 'No' && index == 1) || (this.state.featuredInTheMedia === 'No' && index == 0) || (this.state.article === 'No' && index == 2)}
              />
                  {this.state.featuredInTheMedia === 'Yes' && index == 0 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                  {this.state.IpConference === 'Yes' && index == 1 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                  {this.state.article === 'Yes' && index == 2 && <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
              </>
              ))}
          </Box>
        </Box>
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              color="default"
              data-testid="ipIllustration-back"
              onClick={this.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              color="primary"
              onClick={this.handleNext3}
              data-testid="ipIllustration-next"
            >
              Next
            </Button>
          </Box>
      </Box>
    );
  };
  step4 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        style={{
          display: 'grid',
          gridAutoFlow: 'row',
        }}
      >
        <Box>
          <Typography style={{...webStyle.header, marginTop:"70px",marginBottom:"0px"}}>Awards</Typography>
        </Box>
        <Box
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: '1 0.35fr',
            rowGap: '20px',
            justifyContent: 'space-around'
          }}
        >
          {[{title:
            'Government: Certificate and link of website',state:"step4FileUplod1"},
            {title:'Academic certificate and link of website',state:"step4FileUplod2"}
          ].map((item: any, index: any) => (
            <FileUploadArea
              id={`IPIllustration-step-4-fileupload-${index + 1}`}
              btnContent={
                currentStateData[item.state]
                  ? currentStateData[item.state]?.name
                  :item.title}
                  onChange={this.handleFileUpload}
                  stateKeyRef={item.state}
              uploadIcon={uploadIcon}
            />
          ))}
        </Box>
        <Box
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: '1 0.35fr',
            rowGap: '20px',
            justifyContent: 'center'
          }}
        >
          
          <FileUploadArea
            id="FreelancerIpIllustration-step-4-upload-3"
            btnContent={
              currentStateData['step4FileUplod3']
                ? currentStateData['step4FileUplod3']?.name
                :'Industry certificate and link of website'}
            testId={'FreelancerIpIllustration-step-4-upload-3'}
            uploadIcon={uploadIcon}
            onChange={this.handleFileUpload}
            stateKeyRef={'step4FileUplod3'}
          />
        </Box>
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              color="default"
              data-testid="ipIllustration-back"
              onClick={() =>
                this.state.activeStep === 0 ? this.handleredirection('ProfessionalAccountRegistration') : this.handleBack()
              }
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              color="primary"
              onClick={() =>
                this.state.activeStep === 3
                  ? this.handleredirection('ProfessionalAccountRegistration')
                  : this.handleNext()
              }
              data-testid="ipIllustration-next"
            >
              Next
            </Button>
          </Box>
      </Box>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Box padding={'3%'}>
          <Box style={webStyle.outerContainer}>
          <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>

            <Box style={webStyle.root}>
              {/* stepper component starts */}
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: React.ReactNode } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
          {this.getStepComponent()}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '400px',
    flex:"0.7"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  invento: {
    width:"300px",
    height:"61px",
    objectFit:"cover",
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '14px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '20px',
    marginTop: '30px',
    display:"flex"
  },
  RadioGroupBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Two equal columns
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  UploadButton: {
    height: '100%',
    width: '100%',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#E9F0FA',
    border: '1px dashed #979797',
    borderRadius: '12px'
  },
  SelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: '#21212B',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  UnSelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: 'transparent',
    color: '#626262',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '110px',
    height: '45px',
    borderRadius: '10px',
    flexShrink: 0,
    color: '#FFF',
    backgroundColor: '#3180C3'
  },
  selectMainBox: {
    minHeight: '52px',
    border: '1px solid silver',
    marginBottom: '15px',
    borderRadius: '10px'
  },
  selectBox: {
    padding: '12px',
    borderRadius: 15,
    background: '#4A4A4A'
  },
  labelStyle3: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif"
  }
};
// Customizable Area End
