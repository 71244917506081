import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import {setStorageData} from "../../../framework/src/Utilities"
export const config = require("./config");

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userRole: string
  buttonText: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserTypeSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      userRole: "user",
      buttonText: config.applyAsUser
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleContinue = () => {
    const { userRole } = this.state;
  
    // Set the role in storage
    setStorageData("role", userRole);
  
    // Create a dynamic message for navigation
    const signUpmessage: Message = new Message(getName(MessageEnum.NavigationMessage));
    signUpmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
    // Update the button text based on the selected role
    let buttonText;
    if (userRole === 'client') {
      signUpmessage.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountRegistration');
      buttonText = config.applyAsClient;
    } else {
      signUpmessage.addData(getName(MessageEnum.NavigationTargetMessage), 'ProfessionalAccountRegistration');
      buttonText = config.applyAsUser;
    }
  
    // Send the navigation message
    runEngine.sendMessage(signUpmessage.id, signUpmessage);
  
    // Update the button text dynamically in the state
    this.setState({ buttonText });
    
  };
  
  

  handleredirection = () => {
      const signInmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
      signInmessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountLoginBlock'
      );
      signInmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(signInmessage);
  }
  handleUserType = (role:string) => {
    this.setState({ userRole: role });
  }
  
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start 
    // Customizable Area End
  }
}
