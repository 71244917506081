import React from 'react';
// Customizable Area Start
import { Box, Button, Step, Stepper, styled } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ViewJobController, {
  Props,
  webConfigJSON
} from './ViewJobController.web';
import ViewJobPost from './ViewJobPost.web';
import ProposalNHire from './ProposalNHire.web';
import ClientNavBar from './Components/ClientNavBar.web';
import FooterWeb from './Components/Footer.web';

const stepperTitle = require('../assets/stepperTitle.svg');
const stepperTitleActive = require('../assets/stepperTitleActive.svg');

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    },
    secondary: {
      main: 'rgb(96, 96, 96)'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});
const CustomButton = styled(Button)({
  background: "#206FC4",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0px",
  color: "white",
  borderRadius: "10px",
  width: " 173px",
  height: " 56px",
  marginRight:"1rem",
  fontFamily:"'Inter', sans-serif",
  fontWeight:"bold",
  cursor:"pointer",
  marginTop: "20px",
  '&:hover': {
    width: " 173px",
    height: " 56px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
// Customizable Area End

export default class ViewJob extends ViewJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getStepContent(step: string) {
    switch (step) {
      case 'VIEW_JOB_POST':
        return (
          <ViewJobPost
            jobData={
              this.state.jobPostedData?.attributes || webConfigJSON.jobPostData
            }
          />
        );
      case 'REVIEW_PROPOSALS':
        return (
          <ProposalNHire
            data={this.state.proposalsData}
            isfromReviewProposal={true}
            handleRedirect={this.handleredirection}
          />
        );
      case 'HIRE':
        return <ProposalNHire data={webConfigJSON.proposalsData} />;
      default:
        return 'Unknown step';
    }
  }

  // Customizable Area End

  render() {
    const steps = webConfigJSON.steps;
    console.log(this.state);
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <ClientNavBar />
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          margin={'26px 26px 0px 4.5rem'}
        >
          <div style={webStyles.userName}>{this.state.firstName}</div>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => this.handleredirection()}
          >
            POST A JOB
          </CustomButton>
        </Box>
        <Box style={webStyles.container} padding={'1rem  5rem 1rem 3rem'}>
          <Stepper activeStep={this.state.activeStep}>
            {steps.map((item: any) => {
              const stepProps: { completed?: boolean } = {};

              return (
                <Step
                  key={item.label}
                  {...stepProps}
                  onClick={() => this.changeCurrentStep(item.value)}
                  data-testid={item.value}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      className="vector"
                      alt="Vector"
                      src={
                        this.state.activeStep == item.value
                          ? stepperTitleActive
                          : stepperTitle
                      }
                      style={{
                        fill:
                          this.state.activeStep >= item.value
                            ? '#206FC4;'
                            : '#E8F0F9'
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '25%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        color:
                          this.state.activeStep == item.value
                            ? 'white'
                            : 'black',
                        padding: '10px',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500
                      }}
                    >
                      {item.label}
                    </div>
                  </div>
                </Step>
              );
            })}
          </Stepper>
          <Box style={webStyles.stepperContent}>
            {this.getStepContent(this.state.activeStep)}
          </Box>
        </Box>
        {this.state.activeStep !== 'VIEW_JOB_POST' && <Box style={{marginLeft:"30px"}}>
          <Button
            variant="contained"
            color="primary"
            style={webStyles.btnText2 as React.CSSProperties}
            onClick={this.handleBack}
          >
            Back
          </Button>
          </Box>
        }
    { this.state.activeStep !=="HIRE" &&   <Box style={{display:"Flex",justifyContent:"end",alignItems:"end",marginRight:"30px"}}>
    <Button
          variant="contained"
          color="primary"
          style={webStyles.btnText as React.CSSProperties}
          onClick={this.handleNext}
          disabled={this.state.activeStep === steps.length - 1}
        >
          Next
        </Button>
      </Box>
      }
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    margin: '0 25% 2% 0'
  },
  stepTitle: {
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: 'white',
    padding: '10px',
    fontFamily: 'Inter',
    fontSize: '27px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  userName: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400
  },
  stepperContent: {
    padding: '24px'
  },
  btnText: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "15px",
    lineHeight: "27px",
    textDecoration: "none",
    width:"5rem"
  },
  btnText2: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "15px",
    lineHeight: "27px",
    textDecoration: "none",
    width:"5rem"
  },
};
// Customizable Area End
