import React from 'react';
import { Props } from './ViewJobController.web';
// Customizable Area Start
import ClientNavBar from './Components/ClientNavBar.web';
import FooterWeb from './Components/Footer.web';
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import FeedBackFormController from './FeedBackFormController.web';
import Rating from '@material-ui/lab/Rating';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
export default class FeedBackForm extends FeedBackFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <ClientNavBar />
        <Box
          display={'grid'}
          gridRowGap={'10px'}
          margin={'30px 30px 15px 30px'}
        >
          <ArrowBackIcon fontSize="large" />

          <Typography style={webStyle.heading}>Feedback </Typography>
          <Typography style={webStyle.text}>
            Form How Satisfied are you with the work?
          </Typography>
          <Box style={webStyle.shodowWrapper}>
            <Rating
              name="simple-controlled"
              data-testid="satisfaction-value"
              value={this.state.rating}
              onChange={(event, newValue) => {
                this.setState({ rating: newValue });
              }}
              style={{ padding: '8px 15px' }}
            />
          </Box>
          <Typography style={webStyle.text}>
            Share your thoughts about experience with us:-
          </Typography>
          <Box style={webStyle.shodowWrapper}>
            <TextareaAutosize
              defaultValue=""
              data-testid="feedbacktext"
              style={{
                width: '100%',
                border: 'none',
                height: '243px',
                padding: '20px'
              }}
              onChange={(event: any) => {
                this.setState({ feedBackText: event.target.value });
              }}
            />
          </Box>
          <Box alignItems={'end'} display={'flex'} justifyContent={'end'}>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: '4px 15px', width: 'max-content' }}
            >
              Submit Form
            </Button>
          </Box>
        </Box>

        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#535353',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px'
  },
  heading: {
    color: '#0A0A0A',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  shodowWrapper: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '4px'
  }
};
// Customizable Area End
