import React from 'react';
// Customizable Area Start
import { Props } from './ViewJobController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from '@material-ui/lab/Pagination';
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
  Button,
  withStyles,
  Badge,
  Avatar,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Divider,
  Menu,
  MenuItem} from '@material-ui/core';
import {
  calendar,
  dot,
  jobProfileAvatar,
  searchJob1,
  verified
} from './assets';
import SearchJobController from './SearchJobController.web';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import Slider from '@material-ui/core/Slider';
import CancelIcon from '@material-ui/icons/Cancel';
import FavoriteIcon from '@material-ui/icons/Favorite';

const AutoCompleteSvgIcon = (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
      id="Path"
      d="M14.56 0.430739C14.42 0.294665 14.26 0.186001 14.07 0.11258C13.89 0.0381791 13.7 0 13.5 0C13.3 0 13.11 0.0381791 12.93 0.11258C12.74 0.186001 12.58 0.294665 12.44 0.430739L7.85 4.91924C7.76 5.01126 7.63 5.06216 7.5 5.06216C7.37 5.06216 7.24 5.01126 7.15 4.91924L2.56 0.430739C2.28 0.155653 1.9 0 1.5 0C1.1 0 0.72 0.154675 0.44 0.42976C0.16 0.705825 0 1.07881 0 1.46843C0 1.85805 0.16 2.23201 0.44 2.5071L5.03 6.99657C5.35 7.31473 5.74 7.5673 6.16 7.7396C6.59 7.91189 7.04 8 7.5 8C7.96 8 8.42 7.91189 8.84 7.7396C9.26 7.5673 9.65 7.31473 9.98 6.99657L14.56 2.5071C14.84 2.23201 15 1.85805 15 1.46843C15 1.07978 14.84 0.705825 14.56 0.430739Z"
      fill="white"
    />
  </svg>
);

// Customizable Area End
export default class SearchJob extends SearchJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  RadioLabel = (labelText: any) => {
    return (
      <Typography style={{ fontWeight: 600, color: '#000' }}>
        {labelText}
      </Typography>
    );
  };
  profileSections = [
    {
      title: 'Employment history',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (30%)'
    },
    {
      title: 'Education',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Video Introduction',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Skills',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    },
    {
      title: 'Other Experience',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    }
  ];

  simplifiedJSX = this.profileSections.map((section, index) => (
    <div key={index}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <RadioGroup style={webStyle.RadioGroupBox}>
          <FormControlLabel
            value={section.title}
            control={
              <Radio
                style={{
                  ...webStyle.dialogtext,
                  fontSize: '18px',
                  color: '#979797'
                }}
              />
            }
            label={this.RadioLabel(section.title)}
          />
        </RadioGroup>
        <ChevronRightIcon />
      </Box>
      <Typography style={{ ...webStyle.dialogtext, marginLeft: '40px' }}>
        {section.content}
      </Typography>
      <Divider />
    </div>
  ));

  render() {
    // Customizable Area Start   
    return (
      //Merge Engine DefaultContainerj
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box
          display={'grid'}
          gridAutoFlow={'column'}
          gridColumnGap={'3rem'}
          gridTemplateColumns={'1fr 0.3fr'}
        >
          <Box display={'grid'} gridAutoFlow={'row'} gridRowGap={'3rem'}>
            <Box
              style={{
                backgroundImage: `url(${searchJob1})`,
                height: '300px',
                borderRadius: '30px',
                marginTop: '48px',
                marginLeft: '100px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginRight: "50px"
              }}
              flex={1}
            >
              {/* Your content goes here */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                marginLeft={'40px'}
              >
                <Box style={{ marginTop: '150px' }}>
                  <Typography
                    style={{
                      ...webStyle.text,
                      color: '#FFF',
                      fontSize: '36px',
                      fontWeight: 700,
                      lineHeight: '62px',
                      letterSpacing: '-0.5px',
                      marginBottom: '0rem'
                    }}
                  >
                    Advertisement Details
                  </Typography>
                </Box>
                <Box
                  style={{
                    ...webStyle.text,
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 500,
                    marginBottom: '0rem'
                  }}
                >
                  <Typography>
                    Advertisement Description, Lorum Ipsum
                    <Typography>Rather skip the guesswork?</Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display={'grid'}
              gridAutoFlow={'row'}
              gridRowGap={'2rem'}
              marginLeft={'4rem'}
            >
              <Typography
                style={{
                  ...webStyle.text,

                  fontSize: '36px',
                  fontWeight: 700
                }}
              >
                Search Job
              </Typography>

              <Box
                display={'grid'}
                gridTemplateColumns={'1fr 0.2fr'}
                gridAutoFlow={'column'}
                gridColumnGap={'1.5rem'}
              >
                <TextField
                  data-testid="search"
                  placeholder="Search"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput,
                      width: '100%'
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '100%',
                      flex: 1
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleChange}
                />
        <Button
          data-testid="Most Recent"
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          endIcon={AutoCompleteSvgIcon}
          style={{
            borderRadius: '13px',
            width: '200px',
            backgroundColor: '#2275CD',
            color: '#FFF',
            textTransform: 'none' // Remove the default text transformation
          }}
          onClick={this.handleClick}
        >
          {this.state.selectedOption}
        </Button>
        <Paper >
        <Menu
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          {['Most Recent','Older'].filter((option:string) => option != this.state.selectedOption).map((item:string) => 
             <MenuItem style={{minWidth: 200}} onClick={() => this.handleCloseMenu(item)}>{item}</MenuItem>
            )}        
        </Menu>
        </Paper>
        </Box>
        

              {this.state.clientJobPostData.map(item => {  
                const {date,time} = this.formatDate(item.attributes.updated_at);
                return(
                <Box
                  style={{
                    borderRadius: '24px',
                    backgroundColor: '#E6EBEF',
                    padding: '37px',
                    width:"89%"
                  }}
                  key={item.attributes.id}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    style={{ marginBottom: '10px' }}
                  >
                    <Box>
                      <Typography
                        style={{
                          ...webStyle.text,
                          fontSize: '18px',
                          fontWeight: 100
                        }}
                      >
                        {item.attributes.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          ...webStyle.text,
                          fontSize: '18px',
                          fontWeight: 100
                        }}
                      >
                        Est. Budget: $500
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <LocationOnIcon
                        style={{ marginLeft: '-5px', marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginTop: '10px',
                          display: 'contents'
                        }}
                      >
                        {item.attributes.location}
                      </Typography>
                      <Rating
                        name="simple-controlled"
                        value={3}
                        disabled
                        style={{
                          padding: '8px 0px',
                          width: '90px',
                          marginLeft: '20px',
                          marginRight: '20px'
                        }}
                      />
                      <Typography
                        style={{ ...webStyle.text, marginLeft: '30px' }}
                      >
                        3/5
                      </Typography>
                    </Box>
                    <Box style={{ marginRight: '35px' }}>
                      <Typography style={webStyle.text}>
                        Proposals: {item.attributes.proposals}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ width: '70%' ,overflowWrap:"anywhere"}}>
                    <Typography style={webStyle.text}>
                      {item.attributes.description}
                    </Typography>
                  </Box>

                  <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {item.attributes.skills.map((skill: any) => (
                      <Chip
                        label={skill}
                        style={{
                          backgroundColor: '#bed8eb',
                          color: '#2378D1',
                          marginRight: '10px'
                        }}
                      />
                    ))}
                  </Box>

                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} flex={1} alignItems={'center'}>
                      <img src={verified} style={{ marginRight: '10px' }} />
                      <Typography style={webStyle.text}>
                        Payment veified
                      </Typography>
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                      >
                        {date}
                      </Typography>
                      <img src={dot} style={{ marginRight: '10px' }} />
                      <Typography style={webStyle.text}>{time}</Typography>
                    </Box>
                    <Box display={'flex'} style={{ marginTop: '-15px' }}>
                      <Button
                        variant="contained"
                        data-testid='sendProposal-Button'
                        color="primary"
                        onClick={() => this.handleSendProposalClick(item.attributes.id)}
                        style={{
                          borderRadius: '13px',
                          marginRight: '10px',
                          backgroundColor: '#2275CD'
                        }}
                      >
                         send proposals
                      </Button>
                      <Button
                        data-testid={`saveLaterBtn-${item.attributes.id}`}
                        variant="contained"
                        style={{
                          color: '#2275CD',
                          backgroundColor: 'white',
                          border: '1px solid #2275CD',
                          borderRadius: '13px'
                        }}
                        startIcon={
                          this.state.favouritesJobIds.includes(
                            item.attributes.id
                          ) ? (
                            <FavoriteIcon style={{ color: '#206FC4' }} />
                          ) : (
                            <FavoriteBorderRoundedIcon
                              style={{ color: '#2275CD' }}
                            />
                          )
                        }
                        onClick={() => {
                          this.handleSaveforLaterJobs(item.attributes.id);
                        }}
                      >
                        {this.state.favouritesJobIds.includes(
                          item.attributes.id
                        )
                          ? 'Saved'
                          : 'Save for later'}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                  </Box>
                </Box>
              )})}
            </Box>
            <Box display={"flex"} justifyContent={"end"} marginTop={"-25px"}>
            <Pagination count={this.state.totalPages}   page={this.state.currentPage} onChange={this.handlePageChange}/>
            </Box>
          </Box>
          <Box>
            <Box
              style={{
                marginTop: '50px',
                borderRadius: '24px',
                backgroundColor: '#E6EBEF',
                padding: '30px',
                marginBottom: '40px',
                marginRight: '100px',
                width: 'max-content'
              }}
            >
              <Box
                style={{
                  display: 'flex'
                }}
              >
                <Typography
                  style={{
                    ...webStyle.text,
                    marginRight: '55px',
                    fontSize: '18px',
                    fontWeight: 700,
                    justifyContent: 'space-between'
                  }}
                >
                  Profile Completeness
                </Typography>
                <ChevronRightIcon
                  style={{ marginLeft: '40px', cursor: 'pointer' }}
                  onClick={() => this.handleClickOpen()}
                  data-testid="profile-completionStatus-btn"
                />
                <Dialog
                  // onClose={() => this.handleClose()}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                  PaperComponent={props => (
                    <Paper
                      {...props}
                      style={{
                        borderRadius: '15px',
                        maxWidth: '800px',
                        maxHeight: '800px'
                      }}
                    />
                  )}
                >
                  <DialogContent>
                    <Box display={'flex'}>
                      <Box style={{ width: '62%', marginTop: '234px' }}>
                        <Box>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            defaultValue={this.state.progressValue}
                            data-testid="linearprogress"
                            style={{ marginTop: '20px' }}
                          />
                          <Box display={'grid'} justifyContent={'end'}>
                            <Typography
                              style={{
                                fontWeight: 700,
                                fontSize: 20
                              }}
                            >
                              {this.state.progressValue} %
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              ...webStyle.dialogtext,
                              fontSize: '24px',
                              fontWeight: 700,
                              color: '#206FC4'
                            }}
                          >
                            John Mehta
                          </Typography>
                          <Typography style={webStyle.dialogtext}>
                            Web Designer
                          </Typography>
                        </Box>
                      </Box>

                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ margin: '10px' }}
                      />
                      <Box>
                        <Box>
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography
                              style={{
                                ...webStyle.dialogtext,
                                fontSize: '20px',
                                fontWeight: 700
                              }}
                            >
                              Complete your profile
                            </Typography>
                            <IconButton
                              onClick={() => this.handleClose()}
                              data-testid="dailog-close-btn"
                            >
                              <CancelIcon
                                style={{
                                  fill: '#2378D1',
                                  fontSize: '1.9rem',
                                  marginRight: '-28px',
                                  marginTop: '-12px'
                                }}
                              />
                            </IconButton>
                          </Box>
                          <Typography style={webStyle.dialogtext}>
                            Freelancers with complete, quality profiles are 4,5
                            times more likely to get hired by clients.
                          </Typography>
                        </Box>
                        <Divider />

                        {this.simplifiedJSX}
                      </Box>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
              <Box
                display={'grid'}
                gridAutoFlow={'row'}
                gridRowGap={'2rem'}
                style={{ placeItems: 'end' }}
              >
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={this.state.progressValue}
                  data-testid="linearprogress"
                  style={{ marginTop: '20px' }}
                />
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginTop: '-30px'
                  }}
                >
                  {this.state.progressValue} %
                </Typography>
              </Box>
              <Box style={{ marginTop: '2.5rem' }}>
                <Typography
                  style={{
                    ...webStyle.text,
                    fontSize: '24px',
                    fontWeight: 700,
                    color: '#206FC4'
                  }}
                >
                  John Mehta
                </Typography>
                <Typography style={{ ...webStyle.text, fontSize: '16px' }}>
                  Web Designer
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '24px',
                backgroundColor: '#E6EBEF',
                padding: '30px',
                marginRight: '100px',
                width: 'max-content'
              }}
            >
              <Box style={{ display: 'flex' }}>
                <Typography
                  style={{
                    ...webStyle.text,
                    marginRight: '117px',
                    fontSize: '18px',
                    fontWeight: 700,
                    justifyContent: 'space-between'
                  }}
                >
                  Upload Story
                </Typography>
                <ChevronRightIcon style={{ marginLeft: '50px' }} />
              </Box>
              <Box style={{ marginRight: '55px' }}>
                <Typography style={{ ...webStyle.text, fontSize: '15px' }}>
                  Upload your story on the first page
                </Typography>
                <Typography style={{ ...webStyle.text, fontSize: '15px' }}>
                  so the client can see it.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  rating: {},
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '2rem',
    opacity: 0.6
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  infoCard: {
    borderRadius: '24px',
    background: '#206FC4',
    color: '#fff',
    height: '434px',
    flex: 0.25
  },
  avatar: {
    background: '#FFF',
    width: '100px',
    height: '100px',
    marginBottom: '1.5rem',
    color: '#0A0A0A',

    fontSize: '28px',

    fontWeight: 100
  },
  GridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: '2rem'
  },
  dialogtext: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  }
};
export const PrettoSlider = withStyles({
  root: {
    color: '#206FC4',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);
// Customizable Area End
