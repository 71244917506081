import React from "react";

// Customizable Area Start
import {
  Typography,
  Step,
  StepLabel,
  Box,
  Grid,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
export const config = require("./config");
import Payment from "./Payment.web";
import PaymentSuccessful from "./PaymentSuccessful.web";
import { backArrow } from "./assets"
import ClientNavBar from "./Components/ClientNavBar.web";
import FooterWeb from "./Components/Footer.web";

export class ColorlibStepIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      "1": <Box>1</Box>,
      "2": <Box>2</Box>,
      "3": <Box>3</Box>,
    };

    return (
      <div
        data-testid="stepper"
        style={{ ...webStyle.stepIcon, background: active || completed ? '#66AC3D' : '#C2DEB1', color: active || completed ? '#fff' : '#66AC3D' }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}

// Customizable Area End

import PaymentStepperController, { Props } from "./PaymentStepperController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

export default class PaymentStepper extends PaymentStepperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Payment data-testid="payment-component" {...this.props} onStepChange={this.onStepChange} />;
      case 1:
        return <Typography data-testid="step-two">step 2</Typography>
      case 2:
        return <PaymentSuccessful data-testid="payment-success" {...this.props} onStepChange={this.onStepChange} />

      default:
        return <Payment data-testid="payment-component" {...this.props} onStepChange={this.onStepChange} />
    }
  }
  // Customizable Area End
  render() {
    const steps = this.getSteps();
    return (
      // Customizable Area Start

      // Required for all blocks
      <>
        <ClientNavBar />
        <ThemeProvider theme={theme}>
          <Grid container style={webStyle.outerContainer}>
            <Grid item xs={12} sm={4}>
              <Box onClick={this.handleBack} style={webStyle.backIcon} data-testid="back-button">
                <img src={backArrow} alt="back" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box style={webStyle.root}>
                <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                  ))}
                </Stepper>

              </Box>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
          {this.getStepContent(this.state.activeStep)}
        </ThemeProvider>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: "100%",
  },
  outerContainer: {
    padding: '30px',
    alignItems: 'center'
  },
  invento: {
    padding: "20px 0 0 30px",
    fontSize: "2rem",
    "font-weight": "400",
    width: '30%'
  },
  stepIcon: {
    backgroundColor: "#C2DEB1",
    zIndex: 1,
    color: "#66AC3D",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#66AC3D",
    color: "#fff",
  },
  completed: {
    background: "#66AC3D",
    color: "#fff",
  },
  backIcon: {
    cursor: 'pointer',
    background: 'none',
    border: 'none'
  }
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      background: "#66AC3D",
    },
  },
  completed: {
    "& $line": {
      background: "#66AC3D",
    },
  },
  line: {
    border: "1px solid #66AC3D",
    borderRadius: 1,
  },
})(StepConnector);

// Customizable Area End
