import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Modal, Snackbar, TextField, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "#696A6A",
        }
    },
    typography: {
        fontFamily: 'Inter,Rubik',
    }
});
// Customizable Area End
import AddExperienceModalController, {
    Props,
    configJSON
} from "./AddExperienceModalController";
import Alert from "@material-ui/lab/Alert";

export default class AddExperienceModal extends AddExperienceModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
               <Snackbar
          open={this.state.isAlert}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar> 
                <Modal
                    open={this.props.addExperience}
                    aria-labelledby="add-modal-title"
                    aria-describedby="add-modal-description"
                    style={webStyles.addModalContainer}
                    data-testid="add-modal"
                >
                    <Box style={webStyles.addModalView}>
                        <Grid container>
                            <Grid item sm={12} md={12}>
                                <Box style={webStyles.addContainer}>
                                    <Typography style={webStyles.addText}>{configJSON.addExperience}</Typography>
                                    <CancelIcon data-testid="close-icon" style={webStyles.addIcon} fontSize="small" color="primary" onClick={this.handleClose}/>
                                </Box>
                                <hr style={webStyles.Hr} />
                                <Box>
                                    <Grid container>
                                        <Grid item sm={12} md={12} style={webStyles.addFieldBox}>
                                            <Box style={webStyles.textFieldContainer}>
                                                    <Typography style={webStyles.label}>{configJSON.companyLabel}</Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        data-testid="company"
                                                        name="company"
                                                        placeholder={configJSON.companyPlaceholder}
                                                        size="small"
                                                        InputProps={{ style: { borderRadius: '10px' } }}
                                                        value={this.state.company}
                                                        onChange={this.onCompanyChange}
                                                    />
                                            </Box>
                                            <Box style={webStyles.textFieldContainer}>
                                                <Typography style={webStyles.label}>{configJSON.jobTitleLabel}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    name="jobTitle"
                                                    data-testid="jobTitle"
                                                    fullWidth
                                                    InputProps={{
                                                        style: { borderRadius: '10px' }
                                                    }}
                                                    value={this.state.jobTitle}
                                                    onChange={this.onJobTitleChange}
                                                    placeholder={configJSON.jobTitlePlaceholder}
                                                    error={this.state.validationError}
                                                    helperText={this.state.validationError && configJSON.titleErrorMessage}
                                                />
                                            </Box>
                                            <Box style={webStyles.textFieldContainer}>
                                                <Typography style={webStyles.label}>{configJSON.locationLabel}</Typography>
                                                <TextField
                                                        data-test-id="locationTestId"
                                                    variant="outlined"
                                                    name="location"
                                                    fullWidth
                                                    InputProps={{
                                                        style: { borderRadius: '10px' }
                                                    }}
                                                    value={this.state.expLocation}
                                                    onChange={this.onLocationChange}
                                                    placeholder={configJSON.locationPlaceholder}
                                                />
                                            </Box>
                                            <Box style={webStyles.addModalFields}>
                                            <Box>
                                                <Typography style={webStyles.label}>{configJSON.startDateLabel}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    data-testid="startDate"
                                                    type="date"
                                                    style={webStyles.dateField}
                                                    name="startDate"
                                                    value={this.state.experience.startDate}
                                                    onChange={this.habdleExperienceChange}
                                                    InputProps={{
                                                        style: { borderRadius: '10px' },
                                                    }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography style={webStyles.label}>{configJSON.endDateLabel}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    style={webStyles.dateField}
                                                    name="endDate"
                                                    type="date"
                                                    data-testid="endDate"
                                                    value={this.state.experience.endDate}
                                                    onChange={this.habdleExperienceChange}
                                                    InputProps={{
                                                        style: { borderRadius: '10px' },
                                                    }}

                                                />
                                            </Box>
                                            </Box>
                                            <Box style={webStyles.textFieldContainer}>
                                                <Typography style={webStyles.label}>{configJSON.descriptionLabel}</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    name="description"
                                                    data-test-id="Description"
                                                    fullWidth
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        style: { borderRadius: '10px' }
                                                    }}
                                                    value={this.state.description}
                                                    onChange={this.onDescriptionChange}
                                                />
                                            </Box>
                                            <Box style={webStyles.saveBtnBox}>
                                                <Button color="primary" style={webStyles.saveButton} variant="contained"  onClick={this.props.buttonType == "create" ? this.postFormApi : this.handleUpdateUserExperience } data-testid="save-button">{configJSON.save}</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    addIcon: {
        cursor: 'pointer'
    },
    addModalContainer: {
        width: '100px',
        height: '100px',
        margin: 'auto',
        background: '#fff'
    },
    addModalView: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        height: 600,
        background: "#fff",
        borderRadius: '10px',
        overflowY: 'scroll' as 'scroll'

    },
    addContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px 0'
    },
    addText: {
        fontWeight: 500,
        fontSize:'1.2rem',
        fontFamily:'Rubik'
    },
    Hr: {
        height: '2px',
        background: '#DADADA',
        border: 'none'
    },
    addModalFields: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        marginBottom: '2%'
    },
    addFieldBox: {
        padding: '10px 20px'
    },
    saveBtnBox: {
        textAlign: 'end' as 'end'
    },
    label: {
        fontSize: '1.12rem',
        fontWeight: 500,
        paddingBottom: '2px'
    },
    textFieldContainer: {
        marginBottom: '2%'
    },
    dateField:{
        width:'250px'
    },
    saveButton:{
        fontFamily:'Rubik',
        fontSize:'0.9rem',
        fontWeight:500,
        borderRadius:'8px',
        padding:'7px 21px'
    }

};
// Customizable Area End
