Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";

exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SendAMessage";
exports.labelBodyText = "SendAMessage Body";
exports.textInput_PlaceHolder = "Search list";
exports.textInput_msg = "Enter message";
exports.btn_Title = "Send";

exports.btnExampleTitle = "CLICK ME";
exports.getUsers="search/users"
exports.sendMessage="bx_block_send_message/send_messages"
exports.getCount="bx_block_send_message/send_messages/unread_count"
exports.getMessages="bx_block_send_message/send_messages"
exports.deleteMessage="bx_block_send_message/send_messages"
exports.deleteMethodType="DELETE"

exports.getUserListUrl= "search/users"

exports.btnCreateText="Send message"
exports.allMessagesText="All Messages"
exports.unReadcountText="Unread count"
exports.noMessagesText="No messages received"
exports.storageToken="token"

exports.placeHolders={
  user:"Search users ...",
  message:"Please enter the message"
}

exports.messageDelete="Message Deleted Successfully"
exports.messageSent="Message Sent Successfully"
// Customizable Area End