import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
import Alert from '@material-ui/lab/Alert';
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from '../../framework/src/Utilities';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
   // Customizable Area Start
  // Customizable Area End
  
}
interface S {
  // Customizable Area Start
  activeStep: number;
  phd: string;
  nameOfCollege: string;
  llbCollegeName: any;
  iimCollegeName: any;
  experienceInIPLaw: any;
  step1FileUpload: any;
  step4FileUpload1: any;
  step4FileUpload2: any;
  step4FileUpload3: any;
  step4FileUploadAwards1: any;
  step4FileUploadAwards2: any;
  step4FileUploadAwards3: any;
  disableBtn: boolean;
  focusArea: any;
  ownLawFirm: any;
  lawFirm: any;
  feesAssignment: any;
  document: any;
  civilCase: any;
  criminalCase: any;
  iPConference: any;
  article: any;
  cityOfOperation: any;
  countryOfOperation: any;
  areaOfOperation: any;
  practise: any;
  firm: any;
  yourCollegues: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  error: any;
  showPageError: boolean;
  verfication: boolean;
  successPage: boolean;
  otpValue: any;
  VerificationError: boolean;
  otpError: any;
  seconds: any;
  disabledResendOtp: boolean;
  timerExpired: boolean;
  emailValid: string;
  authToken: string;
  profileName: string;
  radioError: any;
  numberError: any;
  step2FileUpload1: {[key:string]:string|object};
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerLawyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpAsIpLawyerCallId: string = "";
  sendEmailOtpCallId: string = "";
  otpVerificationForEmailCallId: string = "";
  myInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      phd: '',
      disableBtn: false,
      nameOfCollege: '',
      llbCollegeName: '',
      iimCollegeName: '',
      experienceInIPLaw: '',
      step1FileUpload: '',
      focusArea: '',
      ownLawFirm: '',
      lawFirm: 'civil_lawyer',
      feesAssignment: '',
      document: 'per_document',
      civilCase: '',
      criminalCase: '',
      iPConference: '',
      article: '',
      cityOfOperation: '',
      countryOfOperation: '',
      areaOfOperation: '',
      practise: '',
      firm: '',
      yourCollegues: '',
      anchorElement: null,
      openInfoPopper: false,
      error: {},
      showPageError: false,
      step4FileUpload1: '',
      step4FileUpload2: "",
      step4FileUpload3: "",
      step4FileUploadAwards1: "",
      step4FileUploadAwards2: "",
      step4FileUploadAwards3: "",
      verfication: false,
      successPage: false,
      radioError: {},
      otpValue: "",
      VerificationError: true,
      otpError: "",
      seconds: 60,
      disabledResendOtp: true,
      timerExpired: false,
      emailValid: '',
      numberError: {},
      authToken: '',
      profileName: "",
      step2FileUpload1:{}
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      profileName: await getStorageData("firstName")
    })
    // Customizable Area End
  }
  // Customizable Area Start
  handleNext = () => {
    const { activeStep } = this.state;
    let fields: any = [];
    let radiofields: string[] = [];
    if (activeStep === 0) {
      fields.push('llbCollegeName', 'iimCollegeName', 'focusArea');
      if (this.state.phd === 'Yes') {
        fields.push('nameOfCollege')
      }
    } else if (activeStep === 1) {
      fields = ['yourCollegues'];
    } else if (activeStep === 2) {
      fields = ['feesAssignment']
    }
    else if (activeStep === 3) {
      radiofields.push('iPConference', 'article');
      if (this.state.iPConference === 'Yes') {
        fields.push('step4FileUpload2');
      }
      if (this.state.article === 'Yes') {
        fields.push('step4FileUpload3');
      }
    }
    const errors: any = {};
    // Check if any field is empty

    fields.forEach((field: string) => {
      const fieldValue = (this.state as any)[field];
      const minLength = 3;
      const maxLength = 200;
      if (fieldValue === '') {
        errors[field] = "*This is required";
      } else if (fieldValue.length < minLength) {
        errors[field] = '*Should contain minimum 3 characters';
      } else if (fieldValue.length > maxLength) {
        errors[field] = '*Should contain only letters and maximum of 200';
      } else {
        delete errors[field];
      }
    });

    let numberfields: any = [];
    let numError: any = {};
    
    if (activeStep === 0) {
      numberfields = ['experienceInIPLaw',];
    }
    numberfields.forEach((numfield: string) => {
      const fieldValue = (this.state as any)[numfield];
      const maxDigits = 4 
      if (fieldValue === '') {
        numError[numfield] = "*This is required";
      } else if (!/^\d{0,2}(\.\d{0,1}){0,6}?$/.test(fieldValue)) {
        numError[numfield] = "*Only digits are allowed";
      } else if (fieldValue.length > maxDigits) {
        numError[numfield] = '*Allowed numbers with a maximum of 2 digits';
      } else {
        delete numError[numfield];
      }
    });


   
    if (activeStep === 0) {
      radiofields = ['ownLawFirm', 'phd',];
    } else if (activeStep === 1) {
      radiofields = ['practise', 'areaOfOperation', 'countryOfOperation', 'cityOfOperation', 'firm'];
    }
    let radioError: any = {};
    radiofields.forEach((radiofields: string) => {
      if ((this.state as any)[radiofields] === '') {
        radioError[radiofields] = "*This is required";
      }
      else {
        delete errors[radiofields];
      }
    });

    if (Object.keys(errors).length === 0 && Object.keys(radioError).length === 0 && Object.keys(numError).length === 0) {
      this.updateStepNum();
    } else {
      this.setState({ error: errors, radioError: radioError, numberError: numError });
    }
  };
  updateStepNum = () => {
    if (Object.keys(this.state.error).length === 0) {
      if (this.state.activeStep === 3) {
        this.signUpAsIpLawyer()
        this.setState({ showPageError: false });
      } else {
        this.setState(prevState => ({
          activeStep: prevState.activeStep + 1,
          showPageError: false
        }));
      }
    } else {
      this.setState({ showPageError: true });
    }

  }

  handletextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?! )[A-Za-z ]{0,200}$/.test(value)) {
      // Check if the value is changing before updating state
      if ((this.state as any)[name] !== value) {
          value.length >= 3 ? delete updatedError[name] : updatedError[name] = "*Should contain minimum 3 characters";
          this.setState({
              [name]: value,
              error: updatedError
          } as Pick<S, keyof S>);
      }
  } else {
      updatedError[name] = "*Should contain only letters and maximum of 200";
      this.setState({ error: updatedError });
      setTimeout(() => {
        this.setState({ error: false });
      }, 2000);
  }
    
  };

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?!.*,,)(?!^[ ,])(?![\s,])[A-Za-z, ]{0,200}$/.test(value)) {
        // Check if the value is changing before updating state
        if ((this.state as any)[name] !== value) {
            if (value.length >= 3 || (value.length >= 3 && value.includes(','))) {
                delete updatedError[name];
            } else {
                updatedError[name] = "*Should contain minimum 3 characters";
            }
            this.setState({
                [name]: value,
                error: updatedError
            } as Pick<S, keyof S>);
        }
    } else {
        updatedError[name] = "*Should contain only letters";
        this.setState({ error: updatedError });
        setTimeout(() => {
            this.setState({ error: false });
        }, 2000);
    }
};



  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
      error: {}
    }));
  };

  handleClickInfo = (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    this.setState(prevState => ({
      anchorElement: currentTarget,
      openInfoPopper: !prevState.openInfoPopper
    }));
  };
  handleMouseEnterInfo = (event:React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    this.setState({
      anchorElement: currentTarget,
      openInfoPopper: true
    });
  };

  handleMouseLeaveInfo = () => {
    this.setState({
      openInfoPopper: false
    });
  };


  handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const numberFields = ["experienceInIPLaw"];
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}$/;
      if (regexExp.test(value)) {
        delete updatedError[name];
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            error: updatedError
          } as Pick<S, keyof S>);
        }
        if (Number(value) > 99) {
          updatedError[name] = "*Should contain only numbers";
          this.setState({ error: updatedError });
        }
      } else {
        updatedError[name] = "*Allowed numbers with a maximum of 2 digits";
        this.setState({ error: updatedError });
        setTimeout(() => {
          this.setState({ error: false });
        }, 1000);
      }
    }

  };

  handlePHDChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const updatedError = { ...this.state.error };
    delete updatedError.nameOfCollege;
    this.setState({
      phd: value,
      error: updatedError
    })
  };

  handleIPChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const updatedError = { ...this.state.error };
    delete updatedError.step4FileUpload2;
    this.setState({
      iPConference: value,
      error: updatedError
    });
  };
  handleExpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.numberError };
    const numberFields = ["experienceInIPLaw"];
    
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}(\.\d{0,1}){0,6}?$/;
  
      // Use regex to check if the value contains only allowed characters
      if (regexExp.test(value)) {
        delete updatedError[name];
        // Check if the value is changing before updating state
        if ((this.state as any)[name] !== value) {
          this.setState({
            [name]: value,
            numberError: updatedError
          } as Pick<S, keyof S>);
        }
      } else {
        updatedError[name] = "*Enter a valid experience format like '2 or 2.2'.";
        this.setState({ numberError: updatedError });
        setTimeout(() => {
          this.setState({ numberError: {} });
        }, 1000);
      }
    }
  };

  handleArticleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let updatedError = { ...this.state.error };
    delete updatedError.step4FileUpload3
    this.setState({
      article: value,
      error: updatedError
    })
  };

  handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    setTimeout(() => {
      this.setState({ error: false });
    }, 1000);
  };

  handleFeesAssignment = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let updatedError = { ...this.state.error };
    delete updatedError.feesAssignment
    this.setState({
      feesAssignment: value,
      error:updatedError
    });
  };

  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  handleFileUpload = (event: any) => {
    const { name } = event.target;
    const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
    const stateKeyRef = event.target.name;
    const selectedFile = event.target.files[0];
    const maxSizeMB = 5;
    const error = { ...this.state.error };
    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      delete error[name];
      this.setState(({
        [stateKeyRef]: selectedFile,
        error: error
      } as unknown) as Pick<S, keyof S>);

      if (!allowedExtensions.test(selectedFile.name)) {
        this.setState(({
          [stateKeyRef]: null,
          error: error
        } as unknown) as Pick<S, keyof S>);
        this.setState({ error });
        error[name] = '*Invalid file type. Only JPG, JPEG, PNG, PDF, and MP4 files are allowed.'
      }
      else if (fileSizeMB > maxSizeMB) {
        this.setState(({
          [stateKeyRef]: null,
          error: error
        } as unknown) as Pick<S, keyof S>);
        error[name] = "*File size exceeds the maximum limit of 5 MB."
      }
    } else {
      this.setState(({
        [stateKeyRef]: null
      } as unknown) as Pick<S, keyof S>);
    }
  };

  handleLogoClick = () => {
    this.props.navigation.navigate("LandingPage");
  }
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {

      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.signUpAsIpLawyerCallId) {
        this.setState({ disableBtn: false })
        if (responseJson.data) {
          this.sendEmailOtp()
        } else if (responseJson.errors) {
          this.setState({ emailValid: responseJson.errors[0].account })
        }
      } else if (apiRequestCallId === this.sendEmailOtpCallId) {
        if (responseJson) {
          this.setState({ authToken: responseJson?.meta?.token, verfication: true });
          this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
              this.setState(({ seconds }) => ({
                seconds: seconds - 1
              }));
            } else {
              clearInterval(this.myInterval);
              this.setState({ timerExpired: true, disabledResendOtp: false, seconds: 60 })
            }
          }, 1000);
        }
      } else if (apiRequestCallId === this.otpVerificationForEmailCallId) {
        if (responseJson.errors) {
          this.setState({ otpError: responseJson.errors[0].otp })
        } else {
          this.setState({ successPage: true })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }

  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } else {
      this.otpVerificationForEmail()
    }
  }

  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    this.sendEmailOtp();
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000);
  };

  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
    removeStorageData("firstName");
    removeStorageData("lastName");
    removeStorageData("password");
    removeStorageData("email");
    removeStorageData("country");
    removeStorageData("job");
  }

  signUpAsIpLawyer = async () => {
    this.setState({ disableBtn: true })
    const header = {
      "Content-Type": "application/json"
    };

    const { experienceInIPLaw, step1FileUpload, ownLawFirm, countryOfOperation, focusArea, cityOfOperation, llbCollegeName, iimCollegeName, phd, article, firm, nameOfCollege, feesAssignment, iPConference, step4FileUpload1, step4FileUpload2, step4FileUpload3, step4FileUploadAwards1, step4FileUploadAwards2, step4FileUploadAwards3 } = this.state;
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": await getStorageData("email"),
          "password": await getStorageData("password"),
          "first_name": await getStorageData("firstName"),
          "last_name": await getStorageData("lastName"),
          "role_id": "freelancer"
        },
        "job_lawyer_attributes": {
          "qualification": {
            "llb_college_name": llbCollegeName,
            "iim_college_name": iimCollegeName,
            "is_phd": phd,
            "college_name": nameOfCollege,
            "no_of_experience_years": experienceInIPLaw,
            "focus_area": focusArea,
            "have_law_firm": ownLawFirm,
            "bar_carousel_certificate": step1FileUpload ? URL.createObjectURL(step1FileUpload) : ''
          },
          "area_operation": {
            "city_of_operation": cityOfOperation,
            "is_world_wide": "india",
            "lawyer_type": countryOfOperation,
            "are_you_individual": firm === 'Yes'
          },
          "fee_assignment": {
            "fees_assignment": feesAssignment
          },
          "success_story": {
            "lawyer_featured_media_url": step4FileUpload1 ? URL.createObjectURL(step4FileUpload1) : '',
            "have_you_spoken_ip_conference": iPConference === 'Yes',
            "lawyer_it_conference_url": step4FileUpload2 ? URL.createObjectURL(step4FileUpload2) : '',
            "have_you_published_article": article === 'Yes',
            "lawyer_published_url": step4FileUpload3 ? URL.createObjectURL(step4FileUpload3) : ''
          },
          "awards": {
            "lawyer_government_award_url": step4FileUploadAwards1 ? URL.createObjectURL(step4FileUploadAwards1) : '',
            "lawyer_industry_award_url": step4FileUploadAwards2 ? URL.createObjectURL(step4FileUploadAwards2) : '',
            "lawyer_academic_award_url": step4FileUploadAwards3 ? URL.createObjectURL(step4FileUploadAwards3) : ''
          }
        }
      }

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpAsIpLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/sign_up"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendEmailOtp = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    let emailData = {
      "data": {
        "attributes": {
          "email": await getStorageData("email")
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otps/email_otp"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(emailData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  otpVerificationForEmail = () => {
    const header = {
      "Content-Type": "application/json"
    };

    let OtpData = {
      "data": {
        "token": this.state.authToken,
        "otp_code": this.state.otpValue
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpVerificationForEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/account_confirm"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(OtpData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  // Customizable Area End
}
