import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import storage from "framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number
  search: string;
  IpTalent: string
  IpJobs: string
  searchSelect:number
  talentResults: any[]; 
  jobResults: any[];
  currentPage:number;
  totalPages:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FullWidthTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  displayLandingSearchedProfileId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      value:0,
      search: "",
      searchSelect:0,
      IpTalent: 'IP Talents',
      IpJobs: 'Jobs',
      talentResults: [],
      jobResults: [],
      currentPage:1,
      totalPages:0
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const searchQuery = await storage.get('searchQuery') || '';
    const searchSelect = await storage.get('searchSelect') || 'IP Talent';
    const selectedTab = (searchSelect === 'IP Talent') ? 0 : 1;
    this.setState({
      search: searchQuery,
      searchSelect: selectedTab 
    });
    this.getSearchedResult(this.state.search)
    // Customizable Area End
  }
    // Customizable Area Start

    // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (requestCallId === this.displayLandingSearchedProfileId) {
        if (response) {
          const searchSelect = this.state.searchSelect;
          if (searchSelect === 0) {
            this.setState({ talentResults: response.freelancers.data,
            totalPages: Math.ceil(response?.total_count / 10)
             });
          } else {
            this.setState({ jobResults: response.jobs.data,
            totalPages: Math.ceil(response?.total_count / 10)
             });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearch = (event: { target: { value: any; }; }) => {
    this.setState({ search: event.target.value });
    storage.set("searchQuery","")
  };

  handleChange = (event:any, newValue: any) => {
    this.setState({ searchSelect: newValue });
  };
  componentDidUpdate(prevProps: any, prevState: any) {
    if(prevState.search !== this.state.search ||prevState.searchSelect!==this.state.searchSelect)
      {
         this.getSearchedResult(this.state.search)
      }
  }

handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
  this.setState({currentPage: page}, () => {
    this.getSearchedResult(this.state.search);
  });
};

  getSearchedResult = async(searchQuery:string) => {
    const page = this.state.currentPage; 
    let searchData=searchQuery==""?await storage.get('searchQuery') :searchQuery
    const type = this.state.searchSelect==0?"ip_talent":'jobs';
    const formdata = new FormData();
    formdata.append("search", searchQuery);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.displayLandingSearchedProfileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_static_content/landing_page_contents/landing_page_search?type=${type}&search=${searchData}&page=${page}&per_page=10`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsMethodeType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  // Customizable Area End
}
