import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  FormControl,
  Button,
  Container,
  Divider,
  OutlinedInput,
  InputAdornment,
  Select,
  TextField,
  Radio,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  TextareaAutosize,
  IconButton,
  Paper
} from '@material-ui/core';
import SubmitProposalsController, {
  Props
} from './SubmitProposalsController.web';
export const configJSON = require('./config');
import { uploadDoc } from '../../email-account-registration/src/assets';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import CancelIcon from '@material-ui/icons/Cancel';

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class SubmitProposals extends SubmitProposalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start

      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Container style={{ marginTop: '2rem' }}>
          <Typography className={classes.headerBold}>
            Submit a proposal
          </Typography>
          <Box component={'div'} className={classes.boxStyle}>
            <Typography className={classes.headerBold}>Terms</Typography>
            <Divider className={classes.dividerStyle} />
            <Typography className={classes.headerBold}>
              What is the rate you like to bid for this job?
            </Typography>
            <p className={classes.subHeading}>Profile Rate: $14.00/day</p>
            <Grid container spacing={2} className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}>
                  Project Budget:<span style={{ color: "red" }}>*</span>
                </Typography>
                <p className={classes.subHeading}>
                  Lorum Ipsum Rather skip the guesswork? Shop projects already
                  planned for you by the talent who know them best. solutions to
                  the same.this will help users solve theirLorum Ipsum Rather
                  skip the guesswork? Shop projects already planned for you by
                  the talent who know them best.
                </p>
              </Grid>
              <Grid item md={4} xs={12}>
                <OutlinedInput
                  fullWidth
                  value={this.state.projectBudget}
                  data-testid="project-btn"
                  name="projectBudget"
                  onChange={this.handleProjectBudget}
                  inputProps={{
                    style: {
                      textAlign: 'end'
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}>
                  Savio Service Fee:
                </Typography>
                <p className={classes.subHeading}>
                  Lorum Ipsum Rather skip the guesswork? Shop projects already
                  planned for you by the talent who know them best. solutions to
                  the same.this will help users solve theirLorum Ipsum Rather
                  skip the guesswork? Shop projects already planned for you by
                  the talent who know them best
                </p>
              </Grid>
              <Grid item md={4} xs={12}>
                <OutlinedInput
                  fullWidth
                  value={this.state.savioServiceFee}
                  disabled
                  onChange={this.handleChange}
                  name="savioServiceFee"
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  inputProps={{
                    style: {
                      textAlign: 'end'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}>
                  You'll Receive:
                </Typography>
                <p className={classes.subHeading}>
                  Lorum Ipsum Rather skip the guesswork? Shop projects already
                  planned for you by the talent who know them best. solutions to
                  the same.this will help users solve theirLorum Ipsum Rather
                  skip the guesswork? Shop projects already planned for you by
                  the talent who know them best.
                </p>
              </Grid>
              <Grid item md={4} xs={12}>
                <OutlinedInput
                  fullWidth
                  value={this.state.youReceive}
                  disabled
                  name="youReceive"
                  onChange={this.handleChange}
                  inputProps={{
                    style: {
                      textAlign: 'end'
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box component={'div'} className={classes.boxStyle}>
            <Typography className={classes.headerBold}>
              Select duration of the project<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl variant="outlined">
              <Select
                native
                value={this.state.months}
                id="selectmenu"
                onChange={e => this.handleMonths(e)}
              >
                <option value="" disabled>
                  Select a duration
                </option>
                <option value={1}>&lt;1 month</option>
                <option value={3}>1-3 months</option>
                <option value={6}>3-6 months</option>
                <option value={12}>6-12 months</option>
                <option value={12}>12+ months</option>
              </Select>
            </FormControl>
          </Box>
          <Box component={'div'} className={classes.box1Style}>
            <Typography className={classes.headerBold}>
              Attach your work<span style={{ color: "red" }}>*</span>
            </Typography>
            <input
              type="file"
              id="contained-button-file3"
              data-testid="upload-btn"
              className="file-display"
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx"
              onChange={this.handleResumeUpload}
              name="uploadedResume"
            />
            <label htmlFor="contained-button-file3">
              <Button component="span" className={classes.uploadBtn} fullWidth>
                <div>
                  <div className={classes.imgCenter}>
                    <img src={uploadDoc} alt="upload" />
                  </div>
                  <div>
                    <p className={classes.uploadTxt}>
                      {this.state.uploadedResume
                        ? this.state.uploadedResume.name
                        : 'Upload your resume'}
                    </p>
                  </div>
                </div>
              </Button>
            </label>
          </Box>
          <Box component={'div'} className={classes.lastBoxStyle}>
            <Typography className={classes.headerBold}>
              Describe your recent work experience
            </Typography>
            <TextField
              variant="outlined"
              multiline
              maxRows={6}
              fullWidth
              onChange={this.handleChange}
              value={this.state.describeYourExp}
              name="describeYourExp"
            />
          </Box>
          <Box component={'div'} sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                disabled={!this.isFormValid()}
                  variant="contained"
                  data-testid="proposalSubmit-btn"
                  color="primary"
                  className={classes.btnStyle}
                  onClick={() => {
                    this.handleSubmitProposalsAPI();
                    
                  }}
                >
                  SUBMIT A PROPOSALS
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="proposal-cancel-btn"
                  className={classes.btnStyle2}
                  onClick={() => this.handleClickOpen()}
                >
                  CANCEL
                </Button>
                <Dialog
                  // onClose={() => this.handleClose()}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                  PaperComponent={props => (
                    <Paper
                      {...props}
                      style={{
                        borderRadius: '15px',
                        maxWidth: '650px',
                        maxHeight: '650px'
                      }}
                    />
                  )}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    style={{
                      paddingLeft: '16px',
                      paddingTop: '20px',
                      paddingRight: '10px'
                    }}
                  >
                    <Typography style={webStyle.dialogtext}>
                      Withdraw Proposal
                    </Typography>

                    <IconButton
                      onClick={() => this.handleClose()}
                      data-testid="dailog-close-btn"
                    >
                      <CancelIcon
                        style={{ fill: '#2378D1', fontSize: '1.9rem' }}
                      />
                    </IconButton>
                  </Box>

                  <DialogContent style={{overflowX:"hidden"}}>
                    <Typography gutterBottom>
                      We will politely notify the client that you are not
                      interested. The client will be able to view the reason
                      you've withdrawn your proposal.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        ...webStyle.dialogtext,
                        fontSize: '22px',
                        fontWeight: 600
                      }}
                    >
                      Reason
                    </Typography>
                    <RadioGroup
                      style={webStyle.RadioGroupBox}
                      aria-label="reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleChange}
                      data-testid="reason"
                    >
                      <FormControlLabel
                        value="Applied by mistake"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Applied by mistake"
                      />
                      <FormControlLabel
                        value="Rate too low"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Rate too low"
                      />
                      <FormControlLabel
                        value="Scheduling confilct with client"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Scheduling confilct with client"
                      />
                      <FormControlLabel
                        value="Unresponsive client"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Unresponsive client"
                      />
                      <FormControlLabel
                        value="Inappropriate client behavior"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Inappropriate client behavior"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio style={{ color: '#2378D1' }} />}
                        label="Other"
                      />
                    </RadioGroup>
                    <Typography
                      style={{
                        ...webStyle.dialogtext,
                        fontSize: '22px',
                        fontWeight: 600
                      }}
                    >
                      Message
                    </Typography>
                    <Typography>
                      Add an optional message to share with the client when we
                      notify them that this proposal has been withdrawn.
                    </Typography>
                    <TextareaAutosize
                      minRows={10}
                      name="message"
                      style={{
                        borderColor: '#979797',
                        borderRadius: '10px',
                        fontFamily: 'Inter',
                        paddingLeft: '20px',
                        paddingTop: '20px',
                        height: '130px',
                        width: '550px'
                      }}
                    />
                    <Box display={'flex'} alignItems={'center'}>
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <Typography>
                        Block future invitations from this client
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      data-testid="dailog-cancel-btn"
                      onClick={() => this.handleClose()}
                      style={{
                        backgroundColor: '#E9F2F9',
                        width: '140px',
                        marginRight: '20px',
                        color: '#206FC4'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#206FC4' }}
                      color="primary"
                      autoFocus
                      data-testid="dailog-withdraw-proposal-btn"
                      onClick={this.handleWithdrawProposals}
                    >
                      withdraw proposals
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  boxStyle: {
    border: '1px solid #e6e6e6',
    marginTop: '20px',
    borderRadius: '6px',
    padding: '10px'
  },
  box1Style: {
    border: '1px solid #e6e6e6',
    marginTop: '20px',
    borderRadius: '6px 6px 0 0',
    padding: '10px'
  },
  headerBold: {
    fontWeight: 600,
    marginTop: '10px'
  },
  subHeading: {
    color: '656565',
    fontFamily: 'Poppins',
    fontSize: '12px'
  },
  gridPosition: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dividerStyle: {
    color: 'black'
  },
  uploadBtn: {
    border: '1px dashed',
    padding: '30px',
    borderRadius: '16px',
    height: '150px',
    marginTop: '12px'
  },
  imgCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  uploadTxt: {
    textTransform: 'capitalize' as 'capitalize'
  },
  lastBoxStyle: {
    border: '1px solid #e6e6e6',
    borderRadius: '0 0 6px 6px',
    padding: '10px'
  },
  btnStyle: {
    padding: '10px',
    fontWeight: 500,
    borderRadius: '8px',
    backgroundColor: '#2676D8',
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  btnStyle2: {
    padding: '10px 50px 10px 50px',
    borderRadius: '8px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  },
  dialogtext: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '36px',
    fontWeight: 700
  },
  dialogBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textprg: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '15px'
  },
  reason: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '22px',
    fontWeight: 600,
    marginTop: '10px'
  },
  textprg2: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '10px'
  },
  favouriteBtn: {
    padding: '12px 50px 12px 50px',
    borderRadius: '8px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  },
  applyBtn: {
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#2676D8',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  btngroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  }
};

export default withStyles(webStyle)(SubmitProposals);
// Customizable Area End
