// Customizable Area Start
import React, { ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { messageIcon } from './assets';
import { TextField, InputAdornment, Popover, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import FreelancerNavBarController, {
  Props
} from './FreelancerNavBarController.web';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import { inventohub } from '../../landingpage/src/assets';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Customizable Area End

class FreelancerNavBar extends FreelancerNavBarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render(): ReactElement {
    const open = Boolean(this.state.profileAnchorEl);
    return (
      <div style={styles.root}>
        <AppBar position="static">
          <Toolbar style={styles.toolBar}>
            <Box style={styles.boxStyle}>
            <img src={inventohub} style={{width:"100px",height:"40px",objectFit:"cover", marginRight:"22px"}} onClick={() => this.handleRedirection('FreelancerHomePage')} data-test-id='FreelancerHomePage'/>

              {/* <Divider orientation="vertical" flexItem /> */}
              <Box style={styles.boxStyle}>
                {this.state.menuItems.map((item: any, index: number) => (
                  <Typography
                    key={item.label}
                    variant="h6"
                    aria-describedby={`popover-${item.label}`}
                    onMouseEnter={
                      item.options
                        ? event => {
                            this.handlePopoverOpen(event);
                            event.currentTarget.style.color = '#206FC4'
                          }
                        : undefined
                    }
                    onMouseLeave={
                      item.options ? event => {
                          this.handlePopoverClose();
                          event.currentTarget.style.color = '#4A4A4A'; // Reset color on mouse leave
                        }
                        : undefined
                  }
                    onClick={
                      item.options
                        ? this.handleClick
                        : () => this.handleRedirection(item.componentName)
                    }
                    style={styles.menuItem}
                    data-testid={`navbar-option-${index}`}
                  >
                    {item.label}
                    {item.options &&
                      (Boolean(this.state.anchorEl) &&
                      this.state.anchorEl &&
                      this.state.anchorEl.getAttribute('aria-describedby') ===
                        `popover-${item.label}` ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      ))}
                    {item.options && (
                      <Popper
                        key={`popover-${item.label}`}
                        id={`popover-${item.label}`}
                        open={
                          Boolean(this.state.anchorEl) &&
                          this.state.anchorEl &&
                          this.state.anchorEl.getAttribute(
                            'aria-describedby'
                          ) === `popover-${item.label}`
                        }
                        anchorEl={this.state.anchorEl}
                        placement="bottom"
                        disablePortal={true}
                        modifiers={{
                          flip: {
                            enabled: true
                          },
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent'
                          },
                          arrow: {
                            enabled: true,
                            element: this.state.anchorEl
                          }
                        }}
                        style={{
                          background: '#FFF',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          zIndex: 1,
                          padding: '10px 5px',
                          width: 'inherit'
                        }}
                      >
                        {item.options.map((option: any, index: number) => (
                          <StyledMenuItem
                            key={option.label}
                            onClick={() =>
                              this.handleRedirection(option.componentName)
                            }
                            style={styles.options}
                            data-testid={`menu-option-${index}`}
                          >
                            {option.label}
                          </StyledMenuItem>
                        ))}
                      </Popper>
                    )}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box style={styles.sectionRight}>
              {!this.props.isFromHomePage && (
                <TextField
                  value={this.state.searchValue}
                  variant="outlined"
                  data-testid="search-field"
                  placeholder="Search"
                  style={{ ...styles.searchInput, height: '52px' }}
                  onChange={this.handleChange}
                  onKeyDown={this.keyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  name="searchValue"
                />
              )}
              {!this.props.isFromHomePage && (
                <Avatar
                  alt="image"
                  style={{
                    ...styles.imgAvatar,
                    overflow: 'visible',
                    height: '45px',
                    width: '45px'
                  }}
                  src={messageIcon}
                />
              )}
              <Avatar
                data-testid="profileImage"
                alt="image"
                src={this.state.freelancerProfileImg}
                style={{
                  ...styles.imgAvatar,
                 
                  height: '45px',
                  width: '45px',
                  borderRadius: '50%'
                }}
                onClick={this.handleProfileClick} //this.handleRedirection('FreelancerSetting'),
              />
              <Popover
              data-testid="account_card"
                open={open}
                anchorEl={this.state.profileAnchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                
              
              >
               <Box style={{padding: '7px 0',display: 'grid',gap: '7px',minWidth:"245px"}}>
               <Box
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"center"}
               style={{paddingTop:"10px"}}>
                
                <Avatar

                alt="image"
                src={this.state.freelancerProfileImg}
                style={{
                
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%'
                }}
                
              />
              <Typography style={{fontWeight: 500,fontFamily: 'Inter',fontSize:"16px",marginTop:"10px",padding:"0 15px",color:"#181818"}}>{this.state.accountHolderName}</Typography> 
              <Typography style={{fontWeight:400,fontFamily: 'Inter',fontSize:'11px',color:"#676767"}}>Freelancer</Typography>          
              </Box>
              {/* online/offline byttons */}
   <Box
      style={{
        border: "2px solid #d9d9d9",
        borderRadius: "10rem",
        display:"flex",
        justifyContent: "center",
        margin:"0px 10px"
      }}
    >
      <Button
        variant={this.state.currentStatus === 0 ? "contained" : "text"}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor: this.getColorValue(0).backgroundColor,
          fontWeight: 500,
          fontFamily: 'Inter',
          textTransform:"none",
          color:this.getColorValue(0).color,
          flex:0.5,
        }}
        data-testid="prf-online"
        onClick={() => {this.setState({currentStatus:0});this.updateOnlineStatus(0)}}
      >
        Online
      </Button>
      <Button
        variant={this.state.currentStatus === 1 ? "contained" : "text"}
        onClick={() => {this.setState({currentStatus: 1});this.updateOnlineStatus(1)}}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor: this.getColorValue(1).backgroundColor,
          fontFamily: 'Inter',
          textTransform:"none",
          color: this.getColorValue(1).color,
          flex:0.5
        }}
        data-testid="prf-invisible"
            >
          Invisible
        </Button>
        </Box>
              <Box display={"flex"} flexDirection={"column"} style={{gap:"10px",margin:"10px 20px"}}>
              <Button startIcon={<AccountCircleIcon/>}style={styles.actionButton} >
              <Box display={"flex"} flexDirection={"column"} >
              <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>{this.state.accountHolderName}</Typography>
              <Typography  style={{...styles.actionButtonContent,fontSize:"11px",color:"#676767",textTransform:"none",textAlign:"left"}}>Client</Typography>
              </Box>
              </Button>
              <Button  data-testid="prf-setting" startIcon={<SettingsIcon/>} onClick={()=>this.handleRedirection('FreelancerSetting')} style={styles.actionButton} >
              
                <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>Settings</Typography>
                </Button>
                <Button   data-testid="prf-logout" startIcon={<ExitToAppIcon/>} onClick={() => this.handleLogout('LandingPage')} style={styles.actionButton}>
               
                <Typography style={{...styles.actionButtonContent,textTransform:"none"}}>Log out</Typography>
                </Button>    
               </Box>
               </Box> 
              </Popover>
          </Box>
          </Toolbar>
        </AppBar>
        <div>
    
      <Dialog
     
        open={this.state.isTokenExpired}
        onClose={()=>this.handleLogout('LandingPage')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Session expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Please log in again as your session has ended due to inactivity.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  data-testid='handleCloseDialog' onClick={()=>this.handleLogout('LandingPage')} color="primary" autoFocus>
           Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      </div>
    );
  }
}

export default FreelancerNavBar;
// Customizable Area End
// Customizable Area Start
const StyledMenuItem = withStyles(() => ({
  root: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: 'inherit',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  }
}))(MenuItem);
const styles = {
  root: {},
  title: {
    color: '#0A0A0A',
    fontFamily: 'poppins',
    fontSize: '26px',
    fontWeight: 275,
    lineHeight: 'normal',
    letterSpacing: '-1.244px',
    paddingRight: '1rem'
  },
  actionButton:{
    cursor:"pointer",
    justifyContent:"left",
    
  },
  actionButtonContent:{
    marginLeft:"15px",
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize:"15px",
    color:"#181818",
    
  },
  menuItem: {
    marginRight: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    // textAlign: 'left',
    color: '#4A4A4A',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Inter'
  },
  options: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: '150px',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  },
  toolBar: {
    backgroundColor: ' #F2F5F7',
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectionRight: {
    display: 'flex',
    alignItems: 'center',
    flex: 0.75,
    justifyContent: 'end',
    gap: '20px'
  },

  imgAvatar: {
    cursor: 'pointer',

    '&.MuiAvatar-img': {
      borderLeft: '2px solid #E6E9EA',
      lineHeight: '5rem',
      paddingRight: '1rem',
      overflow: 'visible',
      width: '45px',
      height: '45px',

    }
  },

  searchInput: {
    width: '25rem',
    '& .MuiOutlinedInput-adornedStart': {
      height: '45px',
      borderRadius: '18px'
    }
  },

  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    cursor: 'pointer'
  }
};

// Customizable Area End
