// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider.web';
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  id: string;
}
interface S {
  currentPage:number;
  totalPages:number;
  contactUsRes:Attributes;
  userType: string;
  
}
interface Attributes {
  description: string;
    address: string;
    phone_number: string;
    email: string;
}
// Customizable Area End
interface SS {
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
export default class ContactUsController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start
  contactId:string
  getcontactId:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.contactId=""
    this.getcontactId=""

    this.state = {
      currentPage:1,
      totalPages:0,
      contactUsRes:{
        description: '',
        address: '',
        phone_number: '',
        email: '',
      },
      userType: ''
    
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
        this.getContactUsDetails();
        this.setState({
          userType: await storage.get('userType'),
        });
    // Customizable Area End
  }
  // Customizable Area Start
  getContactUsDetails = async() => {
    const getContactUsDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.validationApiContentType
    };
    

    this.getcontactId = getContactUsDetailsRequest.messageId;
    getContactUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getContactUsDetailsRequestEndPoint
    );
    getContactUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getContactUsDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getMethod
    );

    runEngine.sendMessage(
      getContactUsDetailsRequest.id,
      getContactUsDetailsRequest
    );
  };
   // Customizable Area End
  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.contactId === requestCallId || this.getcontactId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {         
          this.setState({ contactUsRes: responseJson.data.attributes}); 
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End
