import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
    InputAdornment,
    IconButton,
    Checkbox,
    styled,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const config = require("./config");
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { roundCheckIcon } from "./assets"
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
// Customizable Area End

import FreelancerPasswordSettingController, { Props } from "./FreelancerPasswordSettingController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: "Inter, Rubik, Roboto"
    }
});

export default class FreelancerPasswordSetting extends FreelancerPasswordSettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {  
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box style={styles.btnBox}>
                        <Typography style={styles.securityHeading}>{config.passSecurityHead}</Typography>
                        <Button style={styles.saveButton} variant="contained" color="primary" onClick={this.handleUpdate} disabled={!this.isFormValidate()} data-testid="save-password">{config.save}</Button>
                        </Box>
                        <Box>
                            <Typography style={styles.passwordLable}>
                                {config.oldPasswordLable}
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-testid="old-password"
                                type={this.state.showOldPassword ? "text" : "password"}
                                value={this.state.oldPassword}
                                placeholder={config.oldPasswordLable}
                                onChange={this.handleOldPassword}
                                fullWidth
                                onPaste={(e) => e.preventDefault()} 
                                InputProps={{
                                    style: styles.inputPropsStyle,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleShowOldPassword}
                                                data-testid="oldPasswordIcon"
                                            >
                                                {this.state.showOldPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={this.state.oldPasswordError !=""}
                                helperText={this.state.oldPasswordError}
                            />
                        </Box>
                        <Box>
                            <Typography style={styles.passwordLable}>
                                {config.newPasswordLable}
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-testid="new-password"
                                type={this.state.showNewPassword ? "text" : "password"}
                                value={this.state.newPassword}
                                placeholder={config.newPasswordLable}
                                onChange={this.handleNewPassword}
                                fullWidth
                                onPaste={(e) => e.preventDefault()} 
                                InputProps={{
                                    style: styles.inputPropsStyle,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleShowNewPassword}
                                                data-testid="newPasswordIcon"
                                            >
                                                {this.state.showNewPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {this.state.passwordErr && (
                    <div style={styles.formError}>{this.state.passwordErr}</div>
                  )}
                        </Box>
                        <Box>
                            <Typography style={styles.passwordLable}>
                                {config.confirmPasswordLable}
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-testid="confirm-password"
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                value={this.state.confirmPassword}
                                placeholder={config.confirmPasswordLable}
                                onChange={this.handleConfirmPassword}
                                fullWidth
                                onPaste={(e) => e.preventDefault()} //prevent pasting
                                InputProps={{
                                    style: styles.inputPropsStyle,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-testid="password-show"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleConfirmShowPassword}
                                            >
                                                {this.state.showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={this.state.confirmValidation}
                                helperText={
                                    (this.state.confirmValidation) &&
                                    this.state.errorMessage
                                }
                            />
                        </Box>
                        <Box>
                            <Typography style={styles.passwordLable}>
                                {config.seemsGood}
                            </Typography>
                            <Box style={styles.checkBoxInner}>
                                <Checkbox
                                    data-testid="upperletter"
                                    color="primary"
                                    checked={this.state.upperCaseCheck}
                                    icon={<RadioButtonUncheckedRoundedIcon fontSize="small"/>}
                                    checkedIcon={<img src={roundCheckIcon} alt="check" />}
                                />
                                <Typography style={styles.checkInfo}>{config.uppercase}</Typography>
                            </Box>
                            <Box style={styles.checkBoxInner}>
                                <Checkbox
                                    data-testid="number-pass"
                                    color="primary"
                                    checked={this.state.numberCheck}
                                    icon={<RadioButtonUncheckedRoundedIcon fontSize="small"/>}
                                    checkedIcon={<img src={roundCheckIcon} alt="check" />}
                                />
                                <Typography style={styles.checkInfo}>{config.numberCase}</Typography>
                            </Box>
                            <Box style={styles.checkBoxInner}>
                                <Checkbox
                                    data-testid="special-character"
                                    color="primary"
                                    checked={this.state.specialCharCheck}
                                    icon={<RadioButtonUncheckedRoundedIcon fontSize="small"/>}
                                    checkedIcon={<img src={roundCheckIcon} alt="check" />}
                                />
                                <Typography style={styles.checkInfo}>{config.specialCharCase}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {this.state.alertSamePopUp && <DiologComponent 
                success={this.state.alertSamePopUp} handleClose={this.handleDeleteClose} 
                 firstHeading={"Old password and new password cannot be the same."} 
                    secondbuttonname={"Ok"} 
                    mainFunction={this.handleUpdate}
                                     />}

{this.state.alertSuccessPopUp && (
  <DiologComponent 
    success={this.state.alertSuccessPopUp} 
    handleClose={this.handleSucessClose} 
    firstHeading={"Password successfully updated"} 
    secondbuttonname={"Ok"} 
    mainFunction={this.helplineSucessCallBack}
  />
)}

            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DiologComponent = ({ success, handleClose, firstHeading, secondbuttonname, mainFunction }: any) => {
    return (
      <DiologStyle onClose={handleClose} aria-labelledby="customized-dialog-title" open={success}>
        <DialogContent style={{ paddingInline: "40px" }}>
          <Typography
            style={{
              color: "#0F172A",
              lineHeight: "32px",
              letterSpacing: "-0.12px",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "12px",
              fontFamily: "Inter",
            }}
          >
            {firstHeading}
          </Typography>
        </DialogContent>
        <DialogContent
          dividers
          style={{ justifyContent: "flex-end", display: "flex", gap: "16px", flexWrap: "wrap" }}
        >
          <Button
            style={{
              background: "#206FC4",
              color: "#fff",
              height: "46px",
              marginLeft: "0px",
              fontFamily: "Inter",
              minWidth: 137,
            }}
            onClick={() => {
              mainFunction();
              handleClose();
            }}
          >
            {secondbuttonname}
          </Button>
        </DialogContent>
      </DiologStyle>
    );
  }
  
  export const DiologStyle = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "600px",
      minWidth: "600px",
      borderRadius: "8px 8px 32px 8px"
    },
    "@media (max-width:722px)": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "unset",
        borderRadius: "8px 8px 32px 8px"
      },
    },
  })
  export const ButtonComponent=styled(Button)({
    borderRadius: "8px",
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "16px",
    width:"140px",
    height: "44px",
    fontWeight: 700,
    lineHeight: "24px",
  
    textTransform: "inherit",
    border: "1px solid #206FC4",
    '@media (max-width: 409px)': {
     minWidth:"137px"
    },
  })
const styles = {
    passwordLable: {
        fontSize: '16px',
        fontWeight: 100,
        margin: '20px 0 10px'
    },
    inputPropsStyle: {
        borderRadius: '10px'
    },
    formError: {
        color: "red",
        fontSize: 14,
        fontFamily: "'Inter', sans-serif"
      },
    securityHeading: {
        fontSize: '2.25rem',
        fontWeight: 100,
    },
    checkBoxInner: {
        display: 'flex',
        alignItems: 'center'
    },
    checkInfo:{
        color:'#6C6C6C'
    },
    btnBox:{
        display:'flex',
        justifyContent:'space-between'
    },
    saveButton:{
        borderRadius: '10px',
        fontFamily: 'Rubik',
        fontWeight: 500,
        height: '40px',
        width: '100px'
    }
}
// Customizable Area End
