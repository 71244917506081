import React from "react";
//Customizable Area Start

import { Box, Button, Grid, TextField, Typography, styled } from "@material-ui/core";
import ResolveDisputeController, { Props, webConfigJSON } from './ResolveDisputeController.web.'
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// Customizable Area End

export default class ResolveDispute extends ResolveDisputeController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    renderMail = () => {
        return (
            <Grid container spacing={2} lg={12} style={webStyle.gridBox}>
                <Grid item lg={12} xs={12} style={webStyle.myJobsContainer}>
                    <UpperText>{webConfigJSON.myJobs}</UpperText>
                    <ArrowIcon>{">"}</ArrowIcon>
                    <UpperText>{webConfigJSON.allcontract}</UpperText>
                    <ArrowIcon>{">"}</ArrowIcon>
                    <ActiveText>{webConfigJSON.activecontract}</ActiveText>
                </Grid>
                <Grid item lg={12} xs={12} style={webStyle.myJobsContainer}>
                    <ActiveFont>{webConfigJSON.resolvedispute}</ActiveFont>
                </Grid>
                <Box style={webStyle.boxCotainer}>
                    <Typography style={webStyle.filedText}>{webConfigJSON.contract}</Typography>
                    <TextField
                        placeholder="Filed Management Service"
                        variant="outlined"
                        style={webStyle.textFiledInput}
                    />
                    <Typography style={webStyle.filedText}>{webConfigJSON.company}</Typography>
                    <TextField
                        placeholder="xyz pvt.Ltd"
                        variant="outlined"
                        style={webStyle.textFiledInput}
                    />
                    <Typography style={webStyle.filedText}>{webConfigJSON.freelancer}</Typography>
                    <TextField
                        placeholder="Jhon"
                        variant="outlined"
                        style={webStyle.textFiledInput}
                    />
                    <Typography style={webStyle.filedText}>{webConfigJSON.disputedhours}</Typography>
                    <TextField
                        placeholder="315"
                        variant="outlined"
                        style={webStyle.textFiledInput}
                    />
                    <Typography style={webStyle.filedText}>{webConfigJSON.amount}</Typography>
                    <TextField
                        placeholder="$1000"
                        variant="outlined"
                        style={webStyle.textFiledInput}
                    />
                    <Typography style={webStyle.filedText}>{webConfigJSON.comment}</Typography>
                    <CommectBox />
                    <Box>
                        <Button variant="contained" data-test-id="toggleID" onClick={this.goToRefundPage} style={webStyle.submitButton}>
                            {webConfigJSON.submit}
                        </Button>
                        <Button variant="contained" style={webStyle.cancelButton}>
                            {webConfigJSON.cancel}
                        </Button>
                    </Box>
                </Box>
            </Grid>
        )
    };

    // Customizable Area End

    render() {
        //Customizable Area Start

        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                {this.renderMail()}
                <FooterWeb />
            </>
        );

        //Customizable Area End
    }
}

//Customizable Area Start

const webStyle = {
    myJobsContainer: {
        display: "flex",
        width: '1250px'
    },

    gridBox: {
        display: 'flex',
        margin: "45px 35px 45px 35px"
    },

    boxCotainer: {
        width: "1280px",
        height: "50%",
        borderRadius: "12px",
        marginLeft: "15px"
    },

    filedText: {
        color: "#3F3F3F",
        fontWeight: 600,
        fontSize: "15px"
    },
    textFiledInput: {
        width: "732px",
        height: "56px",
        margin: "10px 0px"
    },

    submitButton: {
        background: "#2378D1",
        color: "#ffff",
        width: '10%'
    },

    cancelButton: {
        background: '#E5F1FF',
        width: '10%',
        color: "#2378D1",
        margin: '12px'
    },
}
const ActiveFont = styled(Typography)({
    fontWeight: "bold",
    fontSize: '26px',
    color: "#3F3F3F",
    fontFamily: 'Inter'
});
const UpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});
const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px",
});
const ActiveText = styled(Typography)({
    color: "#206FC4",
cursor: "pointer",
fontFamily: "Inter",
fontSize: "17px",
fontWeight: 500,
lineHeight: "24px",
letterSpacing: "0px",
textAlign: "left"

});
const CommectBox = styled(TextareaAutosize)({
    width: "732px",
    height: "150px !important",
    borderRadius: "3px",
    border: "1px solid grey",
    opacity: 0.6,
    background: '#FFF',
    resize: "none"
    
});

//Customizable Area End
