import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { countries } from "countries-list";
import storage from "../../../framework/src/StorageProvider";
import React from "react";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}
 interface UserData 
  {[key:string]: {[key:string]: string | {[key:string]: string | number | {[key:string]: string | number | null | {[key:string]:null}}}}}

interface Resprofile {
  [key:string]: number | string | null | boolean | {[key:string]:null}
}


interface ArticleImage {
  url: null | string;
  name?:null|string ;
}

interface SuccessStory {
  id:            number;
  success_story: null|string;
  success_story_image: ArticleImage;
}

interface WorkExperience{
  id:         number;
  account_id: number;
  company:    string;
  job_title:  string;
  location:   string;
  start_date: Date;
  end_date:   Date;
  description:string;
}

interface Article {
  id:            number;
  article:       string;
  article_image: ArticleImage;
}

  // Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  details: {
    id:                number;
    first_name:        string;
    job_title:         string;
    price:             string;
    title_description: string;
    work_history:      string;
    skills:            string[];
    profile_image:     string;
    work_experiences:  WorkExperience[];
    articles:          Article[];
    success_stories:   SuccessStory[];
    [key:string]:unknown;
   
},
  validationError: boolean;
  priceError: boolean;
  detailTitleError: boolean;
  workHistoryError: boolean;
  addExperience: boolean;
  buttonType: string;
  company:string;
  jobTitle:string;
  expLocation:string;
  description:string;
  error: any;
  profileImage:any;
  fileInputRef:any;
  userID:number;
  authToken:string;
  freelancerProfileInfo:object;

  // Customizable Area End
}
interface SS { }

export default class EditFreelancerProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Star
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  addessReg= configJSON.addressRegs;
  phoneReg = configJSON.phoneReg;
  nameReg= configJSON.nameReg;
  displayProfileDetailsID: string="";
  updateUserProfileID: string;
  allCountries = Object.values(countries);
  freelancerDetailsId: string="";
  updateProfileImageId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    // this.displayProfileDetailsID = "";
    this.updateUserProfileID = ""
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
        details: {
          id: 0,
          first_name: "",
          job_title: "",
          price: "",
          title_description: "",
          work_history: "",
          skills: [],
          profile_image: "",
          work_experiences: [],
          articles: [],
          success_stories: []
        },
       
          validationError: false,
          priceError: false,
          detailTitleError: false,
          workHistoryError: false,
          addExperience: false,
          buttonType: "",
          company:'',
          jobTitle:'',
          expLocation:'',
          description:'',
          error: "",
          profileImage:"",
          fileInputRef:React.createRef(),
          userID:0,
          authToken:"",
          freelancerProfileInfo:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.fetchFreelancerDetails();
    let data =await storage.get('freelancerProfileInfo')
    this.setState({userID: await storage.get('accountId'),
    authToken: await storage.get('authToken'),
    freelancerProfileInfo: JSON.parse(data),
    profileImage:JSON.parse( data)?.attributes?.profile_image || null,
  })
    
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.freelancerDetailsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (responseJson.data) {
         
          let respUpdated = {...responseJson.data.attributes,          
            work_experiences: responseJson.data.attributes.work_experiences?.length ?  responseJson.data.attributes.work_experiences :[{
              account_id: this.state.userID,
              company:    '',
              job_title:  '',
              location:   '',
              start_date: null,
              end_date:   null,
              description:'',
            }] ,
            articles:responseJson.data.attributes.articles?.length ?  responseJson.data.attributes.articles : [{
              "article": "",
              "article_image": {
                  "url": null
              }
          }],
            success_stories:responseJson.data.attributes.success_stories?.length ?  responseJson.data.attributes.success_stories : [{
           
              "success_stories": null,
              "success_story_image": {
                  "url":null
              }
          }]}
          this.setState({ details:respUpdated});
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      if(this.updateProfileImageId === requestCallId ){
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson.data){
          storage.set('freelancerProfileInfo', JSON.stringify(responseJson.data));
          
          this.setState({
            profileImage:responseJson.data.attributes.profile_image,
          }) 
          
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
        this.updateProfileImageId = ""
      }
    }
   
    // Customizable Area End
  }
  // Customizable Area Start
  handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ details: { ...this.state.details, [event.target.name]: value } })
    this.handleValidation(event)
  }
  handleValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const titleReg = /^[^!@#$%^&*_\-+=~`123456789]*$/;
    const priceReg = /^\d+$/;
    const firstChar = value.charAt(0);
    
    switch (name) {
        case 'title':
            if (/\s/.test(firstChar) || value.length > 100 || (value.length !== 0 && /\s/.test(firstChar)) || (!titleReg.test(value) && value.length !== 0)) {
                this.setState({ validationError: true });
            } else {
                this.setState({ validationError: false });
            }
            break;
        case 'price':
            if (!priceReg.test(value) || value.length > 6) {
                this.setState({ priceError: true });
            } else {
                this.setState({ priceError: false });
            }
            break;
        case 'title_description':
            if (/\s/.test(firstChar) || value.length > 500 || (value.length !== 0 && !titleReg.test(value)) || (/\s/.test(firstChar) && value.length !== 0)) {
                this.setState({ detailTitleError: true });
            } else {
                this.setState({ detailTitleError: false });
            }
            break;
        case 'work_history':
            if (/\s/.test(firstChar) || value.length > 1000 || (value.length !== 0 && !titleReg.test(value)) || (value.length !== 0 && /\s/.test(firstChar))) {
                this.setState({ workHistoryError: true });
            } else {
                this.setState({ workHistoryError: false });
            }
            break;
        default:
            break;
    }
}
handleSkillChange = (event: any, value: any) => {
    this.setState({
      details: {
        ...this.state.details,
        skills: value
      },
    })

  }

  arrayItemsChange = (stateKeyRef:string,index:number,objectRef:string,value:string)=>{
    this.setState((prevState:any) => {
      const updatedArray = prevState.details[stateKeyRef].map((item: any, i: number) => {
        if (i == index) {
          if (objectRef) {
            return { ...item, [objectRef]: value };
          }
          return { ...item };
        }
        return item;
      });
  return {
    details: {
      ...prevState.details,
      [stateKeyRef]: updatedArray,
    },
  };
});
  }
  // habdleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
  //   this.handleValidation(event)
  //   const { name, value } = event.target;
  //   this.setState((prevState) => {
  //       let updatedExperience = { ...prevState.experience, [name]: value };
  //       if (name === 'endDate' && new Date(value) < new Date(prevState.experience.startDate)) {
  //           updatedExperience.endDate = '';
  //       }
  //       if (name === 'startDate' && new Date(value) > new Date(prevState.experience.endDate)){
  //         updatedExperience.endDate = '';
  //       }
  //       return { experience: updatedExperience };
  //   });
  // };
  handleAddExperience = (type: string) => {
    this.setState({ addExperience: !this.state.addExperience, buttonType: type })
  };
  handleFileUpload = (stateKeyRef:string,index:number,objectRef:string,event:any) => {
    const { name } = event.target;
    const allowedExtensions = /\.(jpg|jpeg|png|pdf|mp4)$/i;
   
    const selectedFile = event.target.files[0];
    const maxSizeMB = 5;
    const error = { ...this.state.error };
    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      delete error[name];
      this.setState((prevState:any) => {
        const updatedArray = prevState.details[stateKeyRef].map((item: any, i: number) => {
          if (i == index) {
            if (objectRef) {
              return { ...item, [objectRef]: selectedFile };
            }
            return { ...item };
          }
          return item;
        });
    return {
      details: {
        ...prevState.details,
        [stateKeyRef]: updatedArray,
        
      },
      error: error
    };
  });
      
}  
};
  apiCallSurvey = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : await storage.get("authToken")
    };
    const requestMessageapiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessageapiCall.id, requestMessageapiCall);
    return requestMessageapiCall.messageId;
  };
  handleUpdate = async() => {
    const accountId = await storage.get("accountId")
    const body = {
      account: {...this.state.details,
        ['work_experiences_attributes']:this.state.details.work_experiences,
    ['articles_attributes']:this.state.details.articles,
      ['success_stories_attributes']:this.state.details.success_stories}
    };
  this.updateUserProfileID = await this.apiCallSurvey({
    method: configJSON.updateUserMethodeType,
    endPoint:  configJSON.apiEndPointUpdateUser + `${accountId}/update_freelancer_profile`,
    contentType:configJSON.validationApiContentType,
    body: body,
  });
    
  }
  handleFileChange = (e:any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ profileImage: reader.result });
        // Handle the profile image update logic here
      };
      reader.readAsDataURL(file);
    }
    this.handleUpdateProfileImage(file);
  };

  handleEditClick = () => {
    const fileInput = this.state.fileInputRef.current;
    if (fileInput) {
      fileInput.click();
    }
  };
  fetchFreelancerDetails = async() => {
    const accountId = await storage.get("accountId")
    
    const freelancerDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.validationApiContentType,
    };
    
    this.freelancerDetailsId = freelancerDetails.messageId;
    freelancerDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.freelancerDetailsEndPoint+`${accountId}`
    );
    freelancerDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    freelancerDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(freelancerDetails.id, freelancerDetails);
  };
  handleUpdateProfileImage =async(updatedIMG: any) => {
    const updateProfileImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "token": await storage.get("token")
    };
    // Create a new FormData object
    const formdata = new FormData();
    formdata.append("[data][attributes][profile_image]", updatedIMG);
    
    this.updateProfileImageId = updateProfileImage.messageId;
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.freelancerProfileImageEndPoint}/${this.state.userID}/update_freelancer_profile_image`
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );
    runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
   
    
  }

  // Customizable Area End
}
