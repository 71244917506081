import React from 'react';
import { Props } from './ViewJobController.web';
// Customizable Area Start
import ClientNavBar from './Components/ClientNavBar.web';
import FooterWeb from './Components/Footer.web';
import { Box, ThemeProvider, Typography, createTheme } from '@material-ui/core';
import ProjectCompletionController from './ProjectCompletionController.web';
import { successImg } from './assets';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
export default class ProjectCompletion extends ProjectCompletionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <ClientNavBar />
        <Box textAlign="center" margin={'0 4rem'} gridRowGap={'15px'}>
          <Typography style={webStyle.text}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.
          </Typography>
          <Box>
            <img src={successImg} style={{ width: '90%', flexShrink: 0 }} />
          </Box>
          <Box
            mt={'1rem'}
            gridGap={'20px'}
            gridRowGap={'20px'}
            display="flex"
            justifyContent="center"
          >
            <Button variant="contained" style={webStyle.button}>
              <Typography style={{ color: '#2378D1', fontWeight: 600 }}>
                CANCEL
              </Typography>
            </Button>
            <Button variant="contained" color="primary" style={webStyle.button}>
              <Typography style={{ fontWeight: 600 }}>
                SUBMIT FEEDBACK
              </Typography>
            </Button>
          </Box>
        </Box>

        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  header: {
    backgroundColor: '#e9f1f9'
  },
  text: {
    color: '#535353',
    fontFamily: 'Rubik',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '57px',
    width: '90%',
    padding: '0 5rem',
    marginTop: 35,
    marginBottom: 25
  },
  button: {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontStyle: 'normal',
    height: '56px',
    width: '228px'
  }
};
// Customizable Area End
