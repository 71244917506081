import React from 'react';
// Customizable Area Start
import {
  Box,
  Grid,
  FormControlLabel,
  InputAdornment,
  FormControl,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import FreelancerIpEnforcementController, {
  Props
} from './FreelancerIpEnforcementController.web';
import { ColorlibStepIcon } from '../../blocks/landingpage/src/CustomizedSteppers.web';
import { infoIcon, uploadIcon } from '../../blocks/dashboard/src/assets';
import { FileUploadArea, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';
import { login, successImage } from '../../blocks/email-account-registration/src/assets';
import ReactCodeInput from 'react-code-input';

const LawFirms = [
  { name: 'Civil Lawyer', value: 'civil_lawyer' },
  { name: 'Criminal Lawyer', value: 'criminal_lawyer' },
  { name: 'Both', value: 'both' },
  { name: 'Non-litigation and documentation', value: 'non_litigation_and_documentation' },
  { name: 'All', value: 'all' }
];

const props = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '70px',
    borderRadius: '10px',
    fontSize: '30px',
    height: '55px',
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}
// Customizable Area End
export default class FreelancerIpEnforcement extends FreelancerIpEnforcementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div
      style={{
        display: this.state.openInfoPopper ? 'inline-block' : 'none',
        background: '#FFF',
        width: 'inherit',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        borderRadius: '10px',
      }}
    >
      <Typography
        style={{
          ...webStyle.header,
          letterSpacing: '0.27',
          fontSize: '15px',
          fontWeight: 400,
          padding: '10px 20px',
          lineHeight: '25px',
          color: '#4C4C4C',
        }}
      >
        {content}
      </Typography>
    </div>
  );

  step1 = () => {
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          data-testid="IPE1-infoButton"
        >
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Qualifications
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '23px',width:"26px",cursor: 'pointer'}}
          />
        </Box>
        {this
          .popper(`As an expert in IP enforcement, you possess a unique set of skills and qualifications that can help invetors and other IP professionals defend their intellectual property.
          Your ability to navigate complex legal frameworks and protect against infringement is critical in today's fast-paced business landscape.
          By showcasing your qualifications and expertise, you can attract new clients and build lasting business relationships.
          Don't miss out on the opportunity to assist those in need of IP enforcement and safeguard the innovations of tomorrow.
          Reach out and make a difference today! `)}

        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.4}>
            <Typography style={webStyle.header}>Legal<span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-testid="IPE1-legal"
              placeholder="Name of College"
              variant="outlined"
              value={this.state.legal}
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              style={webStyle.inputField}
              name="legal"
              onChange={this.handletextChange}
              error={this.state.error['legal']}
              helperText={this.state.error['legal']}
            />
            <Typography style={webStyle.header}>Other<span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-testid="IPE1-other"
              placeholder="Other"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              variant="outlined"
              value={this.state.other}
              name="other"
              onChange={this.handletextChange}
              error={this.state.error['other']}
              helperText={this.state.error['other']}
              style={webStyle.inputField}
            />
            <Typography style={webStyle.header}>
              No.of experience in IP enforcement<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              data-testid="IPE1-experienceInIpEnforcement"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              placeholder=" No.of experience"
              style={webStyle.inputField}
              variant="outlined"
              name="experienceInIpEnforcement"
              value={this.state.experienceInIpEnforcement}
              onChange={this.handleExpFieldChange}
              error={this.state.error['experienceInIpEnforcement']}
              helperText={this.state.error['experienceInIpEnforcement']}
            />
            <Typography style={webStyle.header}>Select Your Type:<span style={{ color: "red"}}>*</span></Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="type"
              name="type"
              value={this.state.type}
              onChange={this.handleChange}
              data-testid="IPE1-type"
            >
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    style={
                      this.state.type === 'individual'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.type === 'individual'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Individual"
              />
              <FormControlLabel
                value="firm"
                control={
                  <Radio
                    style={
                      this.state.type === 'firm'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.type === 'firm'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Firm"
              />
            </RadioGroup>
            {!this.state.type && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['type']}</Typography>}

            <Typography style={webStyle.header}>Choose One:<span style={{ color: "red"}}>*</span></Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="category"
              name="category"
              value={this.state.category}
              onChange={this.handleChange}
              data-testid="IPE1-category"
            >
              <FormControlLabel
                value="ip_lawyer"
                control={
                  <Radio
                    style={
                      this.state.category === 'ip_lawyer'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.category === 'ip_lawyer'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="IP Lawyer"
              />
              <FormControlLabel
                value="other"
                control={
                  <Radio
                    style={
                      this.state.category === 'other'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.category === 'other'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Other Group"
              />
            </RadioGroup>
            {!this.state.category && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['category']}</Typography>}

            <Typography style={webStyle.header}>Focus Area:<span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-testid="IPE1-focusArea"
              placeholder="Industry Specific"
              variant="outlined"
              value={this.state.focusArea}
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              style={webStyle.inputField}
              name="focusArea"
              onChange={this.handletextChange}
              error={this.state.error['focusArea']}
              helperText={this.state.error['focusArea']}
            />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={0.4}>
            {' '}
            <Typography style={webStyle.header}>
              LinkedIn Profile Link:
            </Typography>
            <TextField
              data-testid="IPE1-linkedInProfileLink"
              placeholder="https://www.google.com/intl/en_in/business/"
              variant="outlined"
              value={this.state.linkedInProfileLink}
              InputProps={{
                style: {
                  ...webStyle.outlinedInput
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachFileIcon
                      style={{ transform: 'rotate(45deg)', fill: 'blue' }}
                    />
                  </InputAdornment>
                )
              }}
              style={webStyle.inputField}
              name="linkedInProfileLink"
              onChange={this.handleChange}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  step2 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.4}>
          <Typography style={webStyle.header}>Country Of Operation<span style={{ color: "red" }}>*</span></Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="countryOfOperation"
            name="countryOfOperation"
            value={this.state.countryOfOperation}
            data-testid="IPE2-countryOfOperation"
            onChange={this.handleChange}
          >
            <FormControlLabel
              value="country_wide"
              control={
                <Radio
                  style={
                    this.state.countryOfOperation === 'country_wide'
                      ? { color: '#206FC4' }
                      : { color: '#979797' }
                  }
                />
              }
              style={
                this.state.countryOfOperation === 'country_wide'
                  ? { ...webStyle.SelectedRadioButton }
                  : { ...webStyle.UnSelectedRadioButton }
              }
              label="CountryWide"
            />
            <FormControlLabel
              value="world_wide"
              control={
                <Radio
                  style={
                    this.state.countryOfOperation === 'world_wide'
                      ? { color: '#206FC4' }
                      : { color: '#979797' }
                  }
                />
              }
              style={
                this.state.countryOfOperation === 'world_wide'
                  ? { ...webStyle.SelectedRadioButton }
                  : { ...webStyle.UnSelectedRadioButton }
              }
              label="WorldWide"
            />
          </RadioGroup>
          {!this.state.countryOfOperation && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['countryOfOperation']}</Typography>}

          <Typography style={webStyle.header}>City Of Operation<span style={{ color: "red" }}>*</span></Typography>
          <TextField
            data-testid="IPE2-cityOfOperation"
            placeholder="Upto 20 Cities "
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            variant="outlined"
            value={this.state.cityOfOperation}
            name="cityOfOperation"
            onChange={this.handletextChange}
            error={this.state.error['cityOfOperation']}
            helperText={this.state.error['cityOfOperation']}
            style={webStyle.inputField}
          />

          <Typography style={webStyle.header}>
            If a Law Firm Is Your Practice as a<span style={{ color: "red" }}>*</span>
          </Typography>
          <RadioGroup
            style={{
              display: 'grid',
              rowGap: 25,
              gridAutoFlow: 'row',
              gridTemplateRows: 'repeat(1, 1fr)',
              marginLeft: '10px'
            }}
            aria-label="lawFirm"
            name="lawFirm"
            value={this.state.lawFirm}
            data-testid="IPE2-lawFirm"
            onChange={this.handleChange}
          >
            {LawFirms.map((firm: any) => (
              <FormControlLabel
                value={firm.value}
                control={
                  <Radio
                    style={
                      this.state.lawFirm === firm.value
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.lawFirm === firm.value
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label={firm.name}
              />
            ))}
          </RadioGroup>
          {!this.state.lawFirm && <Typography style={{ color: 'red', fontSize: '12px',marginLeft:"14px" }}>{this.state.radioError['lawFirm']}</Typography>}

        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={0.4}>
          {' '}
          <Typography style={webStyle.header}>
            LinkedIn Profile Link:
          </Typography>
          <TextField
            id="outlined"
            placeholder="https://www.google.com/intl/en_in/business/"
            variant="outlined"
            value={this.state.linkedInProfileLink}
            data-testid="IPE2-linkedInProfileLink"
            InputProps={{
              style: {
                ...webStyle.outlinedInput
              },
              startAdornment: (
                <InputAdornment position="start">
                  <AttachFileIcon
                    style={{ transform: 'rotate(45deg)', fill: 'blue' }}
                  />
                </InputAdornment>
              )
            }}
            style={webStyle.inputField}
            name="linkedInProfileLink"
            onChange={this.handleChange}
            // onBlur={this.validation}
            error={this.state.error['linkedInProfileLink']}
            helperText={this.state.error['linkedInProfileLink']}
          />
        </Box>
      </Box>
    );
  };
  step3 = () => {
    return (
      <Box>
        <Typography style={webStyle.header}>Fees<span style={{ color: "red" }}>*</span></Typography>
        <TextField
          id="outlined"
          placeholder="Per Assignment"
          variant="outlined"
          value={this.state.fees}
          data-testid="IPE3-fees"
          inputProps={{
            style: {
              ...webStyle.outlinedInput
            }
          }}
          style={{ ...webStyle.inputField, width: '30%' }}
          name="fees"
          onChange={this.handleFeesFieldChange}
          error={this.state.numberError['fees']}
          helperText={this.state.numberError['fees']}
        />
        <Typography style={webStyle.header}>Documentation<span style={{ color: "red" }}>*</span></Typography>
        <TextField
          id="outlined"
          placeholder="Documentation"
          variant="outlined"
          value={this.state.documentation}
          data-testid="IPE3-documentation"
          inputProps={{
            style: {
              ...webStyle.outlinedInput
            }
          }}
          style={{ ...webStyle.inputField, width: '30%' }}
          name="documentation"
          onChange={this.handletextChange}
          error={this.state.error['documentation']}
          helperText={this.state.error['documentation']}
        />
        <Typography style={webStyle.header}>Civil Case<span style={{ color: "red" }}>*</span></Typography>
        <TextField
          id="outlined"
          placeholder="Civil Case"
          variant="outlined"
          value={this.state.civilCase}
          data-testid="IPE3-civilCase"
          inputProps={{
            style: {
              ...webStyle.outlinedInput
            }
          }}
          style={{ ...webStyle.inputField, width: '30%' }}
          name="civilCase"
          onChange={this.handletextChange}
          error={this.state.error['civilCase']}
          helperText={this.state.error['civilCase']}
        />
        <Typography style={webStyle.header}>Criminal Case<span style={{ color: "red" }}>*</span></Typography>
        <TextField
          id="outlined"
          placeholder="Criminal Case"
          variant="outlined"
          value={this.state.criminalCase}
          data-testid="IPE3-criminalCase"
          inputProps={{
            style: {
              ...webStyle.outlinedInput
            }
          }}
          style={{ ...webStyle.inputField, width: '30%' }}
          name="criminalCase"
          onChange={this.handletextChange}
          error={this.state.error['criminalCase']}
          helperText={this.state.error['criminalCase']}
        />
        <Typography style={webStyle.header}>Raid<span style={{ color: "red" }}>*</span></Typography>
        <TextField
          id="outlined"
          placeholder="Raid"
          variant="outlined"
          value={this.state.raid}
          data-testid="IPE3-raid"
          inputProps={{
            style: {
              ...webStyle.outlinedInput
            }
          }}
          style={{ ...webStyle.inputField, width: '30%' }}
          name="raid"
          onChange={this.handletextChange}
          error={this.state.error['raid']}
          helperText={this.state.error['raid']}
        />
      </Box>
    );
  };
  step4 = () => {
    const currentStateData: any = this.state;
    return (
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography style={{ ...webStyle.header, fontSize: '32px' }}>
            Success Stories
          </Typography>
          <img
            src={infoIcon}
            alt="info"
            onMouseEnter={this.handleMouseEnterInfo}
            onMouseLeave={this.handleMouseLeaveInfo}
            style={{ marginLeft: '8px', marginTop: '23px',cursor: 'pointer',width:"26px"}}
          />
        </Box>
        {this
          .popper(`Inventors and other IP professionals are in search of experts who can assist them in defending and protecting their intellectual property.
          Your expertise in this field can make a significant difference in the success of their ventures.
          As a trusted legal representative in IP enforcement, it is crucial to demonstrate your past successes and achievements to build trust and confidence with potential clients.
          Share documented success stories and case studies to showcase your skills and experience in IP law.
          Don't miss out on the opportunity to assist those in need of IP enforcement and safeguard the innovations of tomorrow.
          Reach out and make a difference today!`)}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box flex={0.5}>
            <Typography style={webStyle.header}>
              Have you been featured in the media?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="featured in the mediaegory"
              name="featuredInTheMedia"
              value={this.state.featuredInTheMedia}
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value="Yes"
                data-testid="IPE4-featuredInTheMedia-Yes"
                control={
                  <Radio
                    style={
                      this.state.featuredInTheMedia === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.featuredInTheMedia === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                data-testid="IPE4-featuredInTheMedia-No"
                control={
                  <Radio
                    style={
                      this.state.featuredInTheMedia === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.featuredInTheMedia === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            <Typography style={webStyle.header}>
              Have you spoken at an IT conference?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="ItConference"
              name="ItConference"
              value={this.state.ItConference}
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value="Yes"
                data-testid="IPE4-ItConference-Yes"
                control={
                  <Radio
                    style={
                      this.state.ItConference === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.ItConference === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                data-testid="IPE4-ItConference-No"
                control={
                  <Radio
                    style={
                      this.state.ItConference === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.ItConference === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
            <Typography style={webStyle.header}>
              Have you published any article?
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="article"
              name="article"
              value={this.state.article}
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value="Yes"
                data-testid="IPE4-article-Yes"
                control={
                  <Radio
                    style={
                      this.state.article === 'Yes'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'Yes'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                data-testid="IPE4-article-No"
                control={
                  <Radio
                    style={
                      this.state.article === 'No'
                        ? { color: '#206FC4' }
                        : { color: '#979797' }
                    }
                  />
                }
                style={
                  this.state.article === 'No'
                    ? { ...webStyle.SelectedRadioButton }
                    : { ...webStyle.UnSelectedRadioButton }
                }
                label="No"
              />
            </RadioGroup>
          </Box>
          <Box
            style={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateRows: '1 1fr',
              rowGap: '20px',
              flex: 0.3
            }}
          >
            {[{ title: "Attach Image or Video(max-5MB)", state: "step4FileUplod1", error: "step4FileUplod1" },
            { title: "Attach Image or Video(max-5MB)", state: "step4FileUplod2", error: "step4FileUplod2" },
            { title: "Attach Image or Video(max-5MB)", state: "step4FileUplod3", error: "step4FileUplod3" },
            ].map((item: any, index: any) => (
              <div key={index}>
                <FileUploadArea
                  id={`FreelancerIpEnf-step-4${index +
                    1}`}
                  disabled={(this.state.featuredInTheMedia === 'No' && index ==0) ||  (this.state.ItConference === 'No' && index ==1) || (this.state.article === 'No' && index ==2)}
                  uploadIcon={uploadIcon}
                  btnContent={
                    currentStateData[item.state]
                      ? currentStateData[item.state]?.name
                      : item.title}
                  onChange={this.handleFileUpload}
                  stateKeyRef={item.state}
                />
                {this.state.featuredInTheMedia === 'Yes' && index == 0 &&
                  <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                {this.state.ItConference === 'Yes' && index == 1 &&
                  <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
                {this.state.article === 'Yes' && index == 2 &&
                  <Typography style={{ color: 'red' }}>{this.state.error[item.error]}</Typography>}
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };
  step5 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        style={{
          display: 'grid',
          gridAutoFlow: 'row',
          rowGap: '40px'
        }}
      >
        <Box>
          <Typography style={{...webStyle.header,marginBottom:"-45px"}}>Awards:</Typography>
        </Box>{' '}
        <Box
          style={{
            display: 'flex',
            gap: '200px',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}
        >
          {[{ title: 'Government Certificate and link of website', state: "governmentAward" },
          { title: 'Academic certificate and link of website', state: "industryAward" }
          ].map((item, index) => (
            <Box key={index}>
              <FileUploadArea
                id={`FreelancerIpEnf-step-5-fileupload-${index + 1}`}
                btnContent={
                  (this.state as any)[item.state]
                    ? (this.state as any)[item.state]?.name
                    : item.title}
                onChange={this.handleFileUpload}
                stateKeyRef={item.state}
                uploadIcon={uploadIcon}
              />
              {this.state.error[item.state] && <Typography style={{ color: 'red' }}>{this.state.error[item.state]}</Typography>}
            </Box>
          ))}
        </Box>
        <Box
          style={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateRows: '1 0.35fr',
            justifyContent: 'center'
          }}
        >
          <FileUploadArea
            id="FreelancerIpEnf-step-5-fileupload-3"
            btnContent={
              currentStateData['academicAward']
                ? currentStateData['academicAward']?.name
                : 'Industry Certificate and link of website'}
            testId={'Industry certificate and link of website'}
            onChange={this.handleFileUpload}
            stateKeyRef={"academicAward"}
            uploadIcon={uploadIcon}
          />
          {!this.state.academicAward && <Typography style={{ color: 'red' }}>{this.state.error['academicAward']}</Typography>}
        </Box>
        {this.state.emailValid && <Typography style={{ color: 'red' }}>{this.state.emailValid}</Typography>}
      </Box>
    );
  };
  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      case 4:
        return <Box>{this.step5()}</Box>;
      default:
        return <Box>{'Unkown Step'}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start    
    const steps = [0, 1, 2, 3, 4];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        {!this.state.verfication && !this.state.successPage &&
          <Box padding={'3%'}>
            <Box style={webStyle.outerContainer}>
              <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick" />
              <Box style={webStyle.root}>
                {/* stepper component starts */}
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          StepIconComponent={ColorlibStepIcon}
                        ></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </Box>
            {this.getStepComponent()}
            <Box
              justifyContent={'space-between'}
              display={'flex'}
              marginTop={'60px'}
            >
              <Button
                variant="contained"
                color="default"
                data-testid="ipEnforcement-back"
                onClick={() =>
                  this.state.activeStep === 0 ? this.handleredirection('ProfessionalAccountRegistration') : this.handleBack()
                }
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                disabled={this.state.disableBtn}
                data-testid="ipEnforcement-next"
              >
                Next
              </Button>
            </Box>
          </Box>}
        {this.state.verfication === true && !this.state.successPage &&
          <>
            <Grid container spacing={0}>
              <Grid container item xs={12} md={5} justifyContent="center">
                <Grid item xs={9}>
                  <img src={Horizontalinventohub} style={webStyle.invento} />
                  <div style={webStyle.verifyStyle}>
                    <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
                    <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
                    <ReactCodeInput
                      {...props}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue}
                      name={"VerifyOtp"} inputMode={"tel"} />
                    <Box component={"div"} style={{ marginTop: '16px' }}>
                      <FormControl style={{ width: '98%', display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          disabled={this.state.VerificationError}
                          onClick={this.handleVerifybox}
                        >
                          VERIFY
                        </Button>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          onClick={this.handleResendOtp}
                          disabled={this.state.disabledResendOtp}
                          data-testid="resend_emailotp"
                        >
                          Resend OTP

                        </Button>
                      </FormControl>
                    </Box>
                    {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
                    {this.state.VerificationError && <Typography style={webStyle.timer}> Please Enter OTP</Typography>}
                    <Box component={"div"} margin={"30px 0"}>
                      <span style={webStyle.haveAccount}>Have an account? </span>
                      <span style={webStyle.signIn} onClick={this.handleSignIn}>Sign in</span>
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <img src={login} style={webStyle.image} alt="peopleImg" />
              </Grid>
            </Grid>
          </>
        }{this.state.successPage === true && <>
          <Grid container spacing={0} style={{ display: "block" }}>
            <Grid container item xs={12} md={5} justifyContent="center">
              <Grid item xs={9}>
                <img src={Horizontalinventohub} style={webStyle.invento} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={webStyle.successImage}>
              <img src={successImage} alt="successImage" />
            </Grid>
            <Grid item>
              <Typography style={webStyle.suceesText}>Congratulations! {this.state.profileName} your profile has been created</Typography>
            </Grid>
            <Grid container style={webStyle.backNextBtn}>
              <Grid item>
                <Button
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" style={{ padding: "10px 40px", fontSize: " 18px" }} onClick={this.handleSignIn}>
                  NEXT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>}

      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '40%',
    flex:"0.5"

  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom:"40px",
    gap:"14rem"
  },
  otpTime: {
    marginBottom: "20px",
    color: "#6F6E6E",
    fontWeight: 600,
    fontSize: " 18px",
  },
  haveAccount: {
    color: "#6F6E6E",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px"
  },

  backNextBtn: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
    marginTop: "84px",
    "@media (min-width: 425px) and (max-width:759px)": {
      marginTop: "20px",
      position: "unset"
    }
  },
  suceesText: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "40px",
    textAlign: "center" as "center"
  },
  successImage: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  signIn: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "40px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  nxtBtn: {
    padding: "12px", borderRadius: "8px"
  },
  otpStyle: {
    margin: "76px 0px",
    fontSize: "32px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "'Inter', sans-serif"
  },
  verifyStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginTop: '200px',
    "@media (max-width: 768px)": {
      marginTop: 0
    }
  },

  timer: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "red",
    marginTop: "10px"
  },

  image: {
    width: "100%",
    height: "auto",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  invento: {
    width: "250px",
    height: "61px",
    objectFit: "cover" as const,
    marginTop: "25px",
   
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '5px',
    marginTop: '23px'
  },
  RadioGroupBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Two equal columns
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  UploadButton: {
    height: '100%',
    width: '100%',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#E9F0FA',
    border: '1px dashed #979797',
    borderRadius: '12px'
  },
  SelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: '#21212B',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  UnSelectedRadioButton: {
    borderRadius: '10px',
    border: '1px solid #979797',
    background: 'transparent',
    color: '#626262',
    fontFamily: 'Inter',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  }
};
// Customizable Area End
