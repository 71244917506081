import React from "react";
// Customizable Area Start
import { Box, Button, Chip, Grid, InputAdornment, Modal, TextField, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { avtara, locationIcon, editIcon } from './assets';
import CancelIcon from '@material-ui/icons/Cancel';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Rating from '@material-ui/lab/Rating';
import ClearIcon from '@material-ui/icons/Clear';
import AddExperienceModal from "./AddExperienceModal.web";
import Avatar from '@material-ui/core/Avatar';

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "#696A6A",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import FreelancerProfileController, {
    Props,
    configJSON
} from "./FreelancerProfileController";

export default class FreelancerProfile extends FreelancerProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderModal = () => {
        return (
            <ThemeProvider theme={theme}>
                <Modal
                    open={this.state.open}
                    onClose={this.invertModalState}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={webStyles.modalContainer}
                    data-testid="edit-modal"
                >
                    <Box style={webStyles.modalView}>
                        <Grid container>
                            <Grid item sm={12} md={12}>
                                <Box style={webStyles.editContainer}>
                                    <Typography style={webStyles.editText}>{configJSON.editDetails}</Typography>
                                    <CancelIcon fontSize="small" color="primary" style={webStyles.editIcon} />
                                </Box>
                                <hr style={webStyles.Hr} />
                                <Box>
                                    <Grid container>
                                        <Grid item sm={12} md={12} style={webStyles.editFieldBox}>
                                            <Box style={webStyles.editModalContainer}>
                                                <Box>
                                                    <Typography style={webStyles.label}>{configJSON.title}</Typography>
                                                    <TextField
                                                        data-testid="title"
                                                        variant="outlined"
                                                        name="title"
                                                        placeholder={configJSON.titlePlaceholder}
                                                        size="small"
                                                        InputProps={{ style: { borderRadius: '10px' } }}
                                                        style={{ width: '273px' }}
                                                        value={this.state.details.title}
                                                        onChange={this.handleDetailsChange}
                                                        error={this.state.validationError}
                                                        helperText={this.state.validationError && configJSON.titleErrorMessage}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography style={webStyles.label}>{configJSON.price}</Typography>
                                                    <TextField
                                                        data-testid="price"
                                                        variant="outlined"
                                                        name="lawyerPrice"
                                                        size="small"
                                                        placeholder="enter price"
                                                        style={{ width: '273px' }}
                                                        InputProps={{
                                                            startAdornment:
                                                                <InputAdornment position="start">$</InputAdornment>,
                                                            style: { borderRadius: '10px' }
                                                        }}
                                                        type="number"
                                                        value={this.state.details.lawyerPrice}
                                                        onChange={this.handleDetailsChange}
                                                        error={this.state.priceError}
                                                        helperText={this.state.priceError && configJSON.priceErrorMessage}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.title}</Typography>
                                                <TextField
                                                    data-testid="title-details"
                                                    variant="outlined"
                                                    name="titleDetails"
                                                    fullWidth
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        style: { borderRadius: '10px' }
                                                    }}
                                                    value={this.state.details.titleDetails}
                                                    onChange={this.handleDetailsChange}
                                                    error={this.state.detailTitleError}
                                                    helperText={this.state.detailTitleError && configJSON.titleDetailsErrorMessage}
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.workHistory}</Typography>
                                                <TextField
                                                    data-testid="work-history"
                                                    variant="outlined"
                                                    name="workHistory"
                                                    fullWidth
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        style: { borderRadius: '10px' }
                                                    }}
                                                    value={this.state.details.workHistory}
                                                    onChange={this.handleDetailsChange}
                                                    error={this.state.workHistoryError}
                                                    helperText={this.state.workHistoryError && configJSON.workHistoryErrorMessage}
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.skills}</Typography>
                                                <Autocomplete
                                                    multiple
                                                    data-testid="skill"
                                                    id="tags-outlined"
                                                    open={false}
                                                    value={this.state.details.skills}
                                                    options={this.top100Films.map((option) => option.title)}
                                                    onChange={this.handleSkillChange}
                                                    freeSolo
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                variant="outlined"
                                                                style={webStyles.chip}
                                                                label={option}
                                                                deleteIcon={<ClearIcon fontSize="small" style={webStyles.clearIcon} />}
                                                                {...getTagProps({ index })} />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField style={{ borderRadius: 10 }} {...params} variant="outlined" placeholder="Favorites" />
                                                    )}
                                                />
                                            </Box>
                                            <Box style={webStyles.saveBtnBox}>
                                                <Button color="primary" variant="contained" data-testid="save-btn" onClick={this.handleSave}>{configJSON.save}</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </ThemeProvider>
        )
    }

    top100Films = [{ title: '', year: 1994 },]
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box style={webStyles.profileSeetingBox}>
                            <Typography variant="h2" style={webStyles.profileText}>{configJSON.clientProfile}</Typography>
                            <Box style={webStyles.editBox}>
                                <Typography>{configJSON.edit}</Typography>
                                <img src={editIcon} alt="edit" style={webStyles.editIcon} onClick={()=>this.handleRedirection("EditFreelancerProfile")}  data-testid="edit-details" />
                            </Box>                        </Box>
                        <Box style={webStyles.profileBox}>
                            <Box style={webStyles.nameInfo}>
                                <Box style={webStyles.imageBox}>
                                    <Avatar src={this.state.profilePic} style={webStyles.avtara} />
                                    <FiberManualRecordIcon style={webStyles.onlineIcon} />
                                </Box>
                                <Box>
                                    <Typography style={webStyles.userName}>{this.state.userName}</Typography>
                                    <Box style={webStyles.locationBox}>
                                        <img src={locationIcon} style={webStyles.locationImg} />
                                        <Typography style={webStyles.locationText} color="secondary">{this.state.location}</Typography>
                                        <Rating name="read-only" value={this.state.ratingNumber} readOnly icon={<StarRoundedIcon fontSize="inherit" />} emptyIcon={<StarBorderRoundedIcon fontSize="inherit" style={webStyles.starIcon} />} />
                                        <Typography style={webStyles.rating} color="secondary">{this.state.rating}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Typography style={webStyles.statusIcon}>{this.state.status}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyles.detalisBox}>
                            <Typography style={webStyles.detailText}>{configJSON.details}</Typography>
                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={webStyles.detailsBox}>
                                <Box style={webStyles.lawyerBox} >
                                    <Typography style={webStyles.aboutMeHead}>{this.state.details.title}</Typography>
                                    <Typography style={webStyles.aboutPrice}>${this.state.details.lawyerPrice}/hr</Typography>
                                </Box>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{this.state.details.titleDetails}</Typography>
                            </Box>
                            <hr style={webStyles.detailHr} />
                            <Box style={webStyles.detailsBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.workHistory}</Typography>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{this.state.details.workHistory}</Typography>
                            </Box>
                            <hr style={webStyles.detailHr} />
                            <Box style={webStyles.detailsBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.skills}</Typography>
                                <Box style={webStyles.skillDisplay}>
                                    {this.state.details.skills?.map((skill, index) =>
                                        <Typography color="primary" style={webStyles.skills} key={index}>{skill}</Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={webStyles.experienceBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.experience}</Typography>
                            </Box>
                            <hr style={webStyles.detailHr} />

                            {this.state?.experience &&this.state?.experience.length>0&& this.state?.experience.map((experience:any, index:any) => (
                            <Box key={index} style={webStyles.detailsBox}>
                                <Box style={webStyles.lawyerBox}>
                                    <Typography style={webStyles.aboutMeHead}>{experience?.job_title}</Typography>
                                </Box>
                                <Box style={webStyles.googleContainer}>
                                    <Typography style={webStyles.googleText} color="secondary">{experience?.company}</Typography>
                                    <FiberManualRecordIcon style={webStyles.addressDot} color="secondary" />
                                    <Typography style={webStyles.googleText} color="secondary">{experience?.location}</Typography>
                                </Box>
                                <Box style={webStyles.googleContainer}>
                                    <Typography color="secondary" style={webStyles.month}>{experience?.start_date}</Typography>
                                    <Typography color="secondary" style={webStyles.month}>-</Typography>
                                    <Typography color="secondary" style={webStyles.month}>{experience?.end_date}</Typography>
                                </Box>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{experience?.description}</Typography>
                            </Box>
                        ))}

                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={webStyles.experienceBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.awards}</Typography>
                            </Box>
                            <hr style={webStyles.detailHr} />
                            <Box style={webStyles.detailsBox}>
                                <Box style={webStyles.lawyerBox}>
                                    <Typography style={webStyles.aboutMeHead}>{this.state.award.title}</Typography>
                                </Box>
                                <Box style={webStyles.googleContainer}>
                                    <Typography style={webStyles.googleText} color="secondary">{this.state.award.company}</Typography>
                                    <FiberManualRecordIcon style={webStyles.addressDot} color="secondary" />
                                    <Typography style={webStyles.googleText} color="secondary">{this.state.award.location}</Typography>
                                </Box>
                                <Box style={webStyles.googleContainer}>
                                    <Typography color="secondary" style={webStyles.month}>{this.state.award.date}</Typography>
                                </Box>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{this.state.award.description}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={webStyles.experienceBox}>
                                <Typography style={webStyles.aboutMeHead}>Articles</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={webStyles.experienceBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.successStory}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyles.aboutBox}>
                            <Box style={{...webStyles.experienceBox,display:"grid",padding: '25px'}}>
                                <Typography style={{...webStyles.aboutMeHead,fontFamily: "Rubik",fontWeight: 700,fontSize:"1.12rem"}}>Additional Account</Typography>
                                <Typography color="secondary" style={webStyles.month}>Creating a new account allow you to use invento hub in a different way, while still having just one login.</Typography>
                                <Typography style={{...webStyles.aboutMeHead,marginTop:"2%",fontFamily: "Rubik",fontWeight: 400}}>Client Account</Typography>
                                <Typography color="secondary" style={webStyles.month}>Hire, manage and pay as a different company. Each client comoany has its own freelancer, payment methods and reports.</Typography>
                                <Box>
                                <Button variant="outlined" color="primary"style={{ borderRadius:"8px",textTransform:"capitalize",width:"fitContent",marginTop:"2%",fontFamily:"Rubik"}}>
                                 Create Client Account
                                </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {this.renderModal()}
                <AddExperienceModal data-testid="add-experience-modal" {...this.props} addExperience={this.state.addExperience} handleAddExperience={() => this.handleAddExperience("")} buttonType={this.state.buttonType} />
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    linkBox: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        flexWrap: 'wrap' as 'wrap',
        background: '#F2F2F2',
        borderRadius: '15px',
        gap: '21px'
    },
    billLink: {
        borderBottom: '2px solid #fff',
        padding: '10% 10%',
    },
    linkText: {
        borderBottom: '1px solid #fff',
        padding: '0 10% 5%'
    },
    profileText: {
        fontSize: '2.25rem',
        fontWeight: 100,
    },
    profileBox: {
        background: '#F0F0F0',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 25px'
    },
    nameInfo: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    avtara: {
        width: '50px',
        height: '50px',
        borderRadius:"50%"
    },
    userName: {
        fontSize: '1.5rem',
        fontWeight: 100
    },
    locationBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        flexWrap: 'wrap' as 'wrap'
    },
    locationImg: {
        width: '11px',
        height: '14px'
    },
    statusIcon: {
        background: '#66AC3D',
        color: '#fff',
        border: '20px',
        borderRadius: '20px',
        padding: '5px 17px',
        fontWeight: 500,
        fontSize: '0.75rem',
        fontFamily: 'Rubik'
    },
    detalisBox: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5% 0 1%',
        alignItems: 'center'
    },
    detailText: {
        fontSize: '1.5rem',
        fontWeight: 100
    },
    editBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    editIcon: {
        cursor: 'pointer'
    },
    aboutBox: {
        background: '#F0F0F0',
        borderRadius: '15px',
        marginBottom: '2%'
    },
    detailsBox: {
        padding: '15px 25px',
    },
    experienceBox: {
        padding: '15px 25px 0 25px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    aboutMeHead: {
        fontSize: '1.1rem',
        fontWeight: 100,
        marginBottom: '1%'
    },
    aboutPrice: {
        fontSize: '1.1rem',
        fontWeight: 100,
        marginBottom: '1%',
        whiteSpace: 'nowrap' as const
    },
    aboutMeDetail: {
        fontSize: '1rem',
        lineHeight: '19px',
        wordBreak: "break-all" as const
    },
    modalContainer: {
        width: '100px',
        height: '100px',
        margin: 'auto',
        background: '#fff'
    },
    modalView: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        height: 470,
        background: "#fff",
        borderRadius: '10px',
        overflowY: 'scroll' as 'scroll'

    },
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px 0'
    },
    editText: {
        fontWeight: 600
    },
    Hr: {
        height: '2px',
        background: '#DADADA',
        border: 'none'
    },
    profileSeetingBox: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1% 0 3%'
    },
    profileBtn: {
        fontSize: '1.12rem',
        fontFamily: 'Rubik',
        fontWeight: 500,
        borderRadius: '8px',
        border: '1px solid #2378D1',
        padding: '8px 20px'
    },
    starIcon: {
        color: '#FFB81F'
    },
    rating: {
        fontSize: '0.87rem',
        fontWeight: 400
    },
    lawyerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        wordBreak: 'break-all' as const
    },
    detailHr: {
        height: '1px',
        background: '#FFF',
        border: 'none'
    },
    skills: {
        background: '#E3EDF7',
        borderRadius: '19px',
        textAlign: 'center' as 'center',
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '7px 20px',
        fontWeight: 500
    },
    skillDisplay: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        gap: '5px'
    },
    googleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '1%'
    },
    googleText: {
        fontSize: '1.12rem',
        fontWeight: 500,
        fontFamily: 'Rubik',
        paddingRight: '2px'
    },
    editModalContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        marginBottom: '2%'
    },
    editFieldBox: {
        padding: '10px 20px'
    },
    saveBtnBox: {
        textAlign: 'end' as 'end'
    },
    label: {
        fontSize: '1rem',
        fontWeight: 600,
        paddingBottom: '2px'
    },
    fieldBox: {
        marginBottom: '2%'
    },
    imageBox: {
        position: 'relative' as 'relative'
    },
    onlineIcon: {
        position: 'absolute' as 'absolute',
        color: 'green',
        fontSize: '12px',
        left: '35px',
        top: '37px'
    },
    addressDot: {
        fontSize: '10px'
    },
    locationText: {
        fontSize: '0.87rem',
        fontWeight: 400
    },
    month: {
        fontSize: '0.87rem'
    },
    chip: {
        background: '#E9F2FB',
        color: '#2378D1',
        margin: '0 5px',
        maxWidth: '150px'
    },
    clearIcon: {
        color: '#2378D1'
    }

};
// Customizable Area End
