import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {toggleOn,toggleOff} from "./assets"


const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    }
  },
  typography: {
    fontFamily: 'Inter,Rubik',
  }
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12}>
            <Box style={webStyle.notifyBox}>
              <Typography style={webStyle.notificationHeading}>{configJSON.notificationHead}</Typography>
              <Button color="primary" variant="contained" style={webStyle.saveButton}>{configJSON.save}</Button>
            </Box>
            <Grid container justifyContent="space-between" style={webStyle.notificationSecCOntainer}>
              <Grid item xs={12} sm={5}>
                <Typography style={webStyle.emailNotification}>{configJSON.emailNotification}</Typography>
                <Typography style={webStyle.emailDetails} color="secondary">{configJSON.notificationDetail}</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                {this.state.emailNotification.map((data,index) => 
                  <Box style={webStyle.switchBox} key={index}>
                  <Checkbox
                    checked={this.state.emailNotify.includes(data.value)}
                    onChange={() => this.handleEmailNotify(data.value)}
                    icon={<img src={toggleOff} alt="toggle-off"/>}
                    checkedIcon={<img src={toggleOn} alt="toggle-on"/>}
                    value={data.value}
                    data-testid={"email-notify-" + index}
                  />
                  <Box>
                      <Typography style={webStyle.switchHeading}>{data.notificationTitle}</Typography>
                      <Typography style={webStyle.switchSubTitle} color="secondary">{data.notificationDetail}</Typography>
                  </Box>
                </Box>
                )}
                
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" style={webStyle.notificationThirdCOntainer}>
              <Grid item xs={12} sm={5}>
                <Typography style={webStyle.emailNotification}>{configJSON.pushNotification}</Typography>
                <Typography style={webStyle.emailDetails} color="secondary">{configJSON.pushnotificationDetail}</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                {this.state.pushNotification.map(item => 
                  <Box style={webStyle.switchBox} key={item.id}>
                  <Checkbox
                    checked={this.state.pushNotifyArray.includes(item.notify)}
                    data-testid={"push-notify-" + item.id}
                    onChange={() => this.handlePushNotify(item.notify)}
                    icon={<img src={toggleOff} alt="toggle-off"/>}
                    checkedIcon={<img src={toggleOn} alt="toggle-on"/>}
                    value={item.notify}
                  />
                  <Box>
                      <Typography style={webStyle.switchHeading}>{item.title}</Typography>
                      <Typography style={webStyle.switchSubTitle} color="secondary">{item.detail}</Typography>
                  </Box>
                </Box>
                )}
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  notificationHeading: {
    fontSize: '2.25rem',
    fontWeight: 100
  },
  notifyBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  saveButton: {
    fontSize: '1.12rem',
    fontWeight: 500,
    fontFamily: 'Rubik',
    borderRadius: '7px',
    height: '43px',
    padding: '0 20px'
  },
  emailNotification: {
    fontFamily:'Rubik',
    fontSize:'1.12rem',
    fontWeight:500
  },
  emailDetails: {
    fontSize:'0.9rem',
    lineHeight:'20px'
  },
  switchBox:{
    display:'flex',
    gap:'10px',
    alignItems:'start',
    marginBottom:'15px'
  },
  switchHeading:{
    fontSize:'1.12rem',
    fontWeight:500
  },
  switchSubTitle:{
    fontSize:'0.9rem'
  },
  notificationSecCOntainer:{
    marginTop:'20px'
  },
  notificationThirdCOntainer:{
    marginTop:'30px'
  }
};
// Customizable Area End
