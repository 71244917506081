import React, { useState } from 'react';
// Customizable Area Start

import {
  Box,
  Divider,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import OnlineAvatar from './OnlineAvatar';
import { webConfigJSON } from './ViewJobController.web';
import Rating from '@material-ui/lab/Rating';
import storage from 'framework/src/StorageProvider';
export const profileimg = require('../assets/avtara.svg');

interface ProposalNHireProps {
  data: any;
  isfromReviewProposal?: any;
  handleRedirect?: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const ProposalNHire: React.FC<ProposalNHireProps> = ({
  data,
  isfromReviewProposal,
  handleRedirect
}) => {
  console.log(handleRedirect,"sdfghjgfdsfgh")
  const cardStyles = webConfigJSON.cardStyles;
  const [value, setValue] = useState(0);
  const buttonContent = isfromReviewProposal ? 'Hire' : 'Message';
  const buttonTestId = isfromReviewProposal
    ? 'HIRE_CANDIDATE'
    : 'HIRED_CANDIDATE';

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
console.log(data,"datadatadata");

  const createCards = (data: any[]) => {
    return data.map((item: any, index: any) => {
      const {
        attributes: { account_attributes, post_attributes, hour_rate },
        id
      } = item;
      const parseOrReturnString = (input: any) => {
        if (typeof input === 'string') {
            try {
                return JSON.parse(input);
            } catch (error) {
                return input;
            }
        }
        return input;
    }
    
      return (
        <Box
          sx={{ display: 'grid' }}
          style={cardStyles.cardContainer}
          key={index}
        >
          {/* {left side} */}
          <div style={cardStyles.FlexContainer}>
            <div style={cardStyles.profileDetails}>
              <OnlineAvatar online={true} src={profileimg} alt="Avatar" />

              <div>
                <Typography style={cardStyles.userName}>
                  {account_attributes.user_name}
                </Typography>
                <Typography style={cardStyles.jobTitleText}>
                  {parseOrReturnString(account_attributes.designation)}
                </Typography>
                <Typography style={cardStyles.location}>
                  <RoomIcon style={{ fill: '#3CC261' }} />{' '}
                  {account_attributes.location}
                </Typography>
                <Box style={{display:'flex', gap:'12px', alignItems:'center'}}>
                  <Rating
                    name="simple-controlled"
                    value={0}
                    disabled
                    style={{ padding: '8px 0px' }}
                  />
                  <Typography style={{ fontFamily:'Inter', fontSize:'12px' }}>{`${0}/5`}</Typography>
                </Box>
              </div>
            </div>
            <div>
              <Typography style={cardStyles.relevantSkills}>
                {`Has ${post_attributes.skills.length ||
                  0} relevant skills to your job`}
              </Typography>
              <Box style={cardStyles.skills}>
                {post_attributes.skills.map((skill: any, index: number) => {
                  return (
                    <Button
                    style={{padding:'4px 8px', background:'#166489', color:'#FFFFFF', width:'fit-content'}}
                      key={index}
                    >
                      {skill}
                    </Button>
                  );
                })}
              </Box>
            </div>
          </div>
          <Divider orientation="vertical" flexItem />

          <div style={cardStyles.FlexContainer}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'end'}}>
              <Typography style={cardStyles.amount}>${hour_rate}<span style={{fontSize:"12px"}}>/project</span></Typography>
              <Typography style={cardStyles.hourlyBasis}>
                {'Project Price'}
              </Typography>
            </div>
            <div>
              <Button
              style={{height:'56px', width:'220px'}}
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={() => {
    if(buttonContent=="Message"){
      window.location.href=`/chatInbox/${account_attributes.id}/${account_attributes.user_name}`
    }
    else {
      window.location.pathname = buttonContent;
    }
                  storage.set('post_id', post_attributes.id);
                  storage.set('proposal_id', id);
                
                }}
                data-testid={buttonTestId}
              >
                {buttonContent}
              </Button>
            </div>
          </div>
        </Box>
      );
    });
  };

  return (
    <div>
      {isfromReviewProposal ? (
        <>
          <div style={cardStyles.tabs}>
            <Paper square>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab label="All Proposals" data-testid={'ALL_PROPOSALS'} />
                <Tab label="Messaged" data-testid={'MESSAGED'} />
                <Tab label="Archived(1)" data-testid={'ARCHIVED'} />
              </Tabs>
            </Paper>
          </div>
          <TabPanel value={value} index={0} data-testid="tabpanel-0">
            {createCards(data)}
          </TabPanel>
          <TabPanel value={value} index={1} data-testid="tabpanel-1">
            {createCards(data.filter((item: any, index: number) => index < 2))}
          </TabPanel>
          <TabPanel value={value} index={2} data-testid="tabpanel-2">
            {createCards(data.filter((item: any, index: number) => index < 1))}
          </TabPanel>
        </>
      ) : (
        <div>{createCards(data)}</div>
      )}
    </div>
  );
};

export default ProposalNHire;
// Customizable Area End
