import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    IconButton,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export const config = require("./config");
import {Horizontalinventohub} from "./assets"

// Customizable Area End

import NavBarController, { Props } from "./NavBarController";
import { cursorTo } from "readline";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: "Inter,Poppins,Roboto"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

export default class NavBar extends NavBarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12} style={styles.mainFirstContainer}>
                        <Box style={styles.navOuterContainer}>
                            <Box style={styles.navInnerContainer}>
                              <img src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleIpJobClick}/>
                                <Box style={styles.menuOuterBox}>
                                    <IconButton
                                        data-testid="ipJob-menu"
                                        aria-label="more"
                                        aria-controls="ip-jobs"
                                        onClick={this.handleIpJobClick}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.home}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="ipTalent-menu"
                                        aria-label="more"
                                        aria-controls="ip-talent"
                                        onClick={(event)=>{this.handleIpTalentClick(event);this.handleRedirection("HowToHire");}}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.howToHire}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="reports-menu"
                                        aria-label="more"
                                        aria-controls="reports"
                                        onClick={(event)=>{this.handleClick(event);this.handleRedirection("HowToFindWork");}}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.howToFindWork}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="about-btn"
                                        aria-label="more"
                                        aria-controls="reports"
                                        style={styles.iconButton}
                                        onClick={this.handleAbout} 
                                    >
                                    <Typography style={styles.menuText}>{config.about}</Typography>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                            <Box style={styles.buttonContainer}>
                                <Button style={styles.loginBtn} data-testid="login" onClick={this.handleUsertypeSelection2}>{config.login}</Button>
                                <Button variant="contained" color="primary" data-testid="signUp" style={styles.signUpBtn} onClick={this.handleUsertypeSelection}>{config.signUp}</Button>
                            </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    menuText: {
        display: 'flex',
        alingItems: 'center',
        fontSize: '1rem',
        fontWeight: 500,
        color: 'gray',
        cursor:'pointer'
    },
    navOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap' as 'wrap',
        gap:"36px"
    },
    navInnerContainer: {
        display: 'flex',
        gap: '50px'
    },
    menuOuterBox: {
        display: 'flex',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        flexWrap: 'wrap' as 'wrap',
        marginTop:"6px"
    },
    invento: {
        width:"223px",
        height:"40px",
        objectFit:"cover",
        cursor:"pointer"
    },
    buttonContainer: {
        display: 'flex',
        gap: '30px'
    },
    iconButton: {
        height: '0'
    },
    mainFirstContainer: {
        background: '#E6EBEF',
        padding: '48px',
        display:"flex",
        justifyContent:"space-between"
    },
    signUpBtn: {
        borderRadius: '15px',
        padding: '0 20px'
    },
    loginBtn: {
        textTransform: 'capitalize' as 'capitalize',
    },
}

// Customizable Area End

