import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  type: string;
  legal: any;
  other: any;
  experienceInIpillusration: any;
  focusArea1: any;
  focusArea2: any;
  linkedInProfileLink: any;
  countryOfOperation: any;
  cityOfOperation: any;
  focusArea3: any;
  feesType: any;
  feesAmount: any;
  featuredInTheMedia: any;
  IpConference: any;
  article: any;
  anchorElement: null | HTMLElement;
  openInfoPopper: boolean;
  patentOffice: any[];
  showpatentOffice: boolean;
  error:any;
  showPageError:boolean;
  step3FileUplod1:any;
  step3FileUplod2:any;
  step3FileUplod3:any;
  step4FileUplod1:any;
  step4FileUplod2:any;
  step4FileUplod3:any;
  showNextOneError:string;
  experienceInIpillusrationError:string;
  legalError:string;
  otherError: string;
  typeError:string;
  focusArea1Error:string;
  focusArea2Error:string;
  featuredInTheMediaError:string;
  IpConferenceError:string;
  articleError:string;
  countryOfOperationError:string;
  cityOfOperationError:string;
  patentOfficeError:string;
  focusArea3Error:string;
  feesTypeError:string;
  feesAmountError:string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerIpIllustrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      activeStep: 0,
      type: '',
      legal: '',
      other: '',
      experienceInIpillusration: '',
      focusArea1: '',
      focusArea2: '',
      linkedInProfileLink: '',
      countryOfOperation: '',
      cityOfOperation: '',
      focusArea3: "",
      feesType: '',
      feesAmount: '',
      featuredInTheMedia: '',
      IpConference: '',
      article: '',
      anchorElement: null,
      openInfoPopper: false,
      patentOffice: [],
      showpatentOffice: false,
      error:false,
      showPageError:false,
      step3FileUplod1:'',
      step3FileUplod2:"",
      step3FileUplod3:"",
      step4FileUplod1:'',
      step4FileUplod2:"",
      step4FileUplod3:"",
      showNextOneError:"",
      experienceInIpillusrationError:"",
      legalError:"",
      otherError:"",
      typeError:"",
      focusArea1Error:"",
      focusArea2Error:"",
      featuredInTheMediaError:"",
      IpConferenceError:"",
      articleError:"",
      countryOfOperationError:"",
      cityOfOperationError:"",
      patentOfficeError:"",
      focusArea3Error:"",
      feesTypeError:"",
      feesAmountError:""
    };
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

handleNext1 = () => {
  const{legal,other,experienceInIpillusration,type,focusArea1,focusArea2}=this.state
  if (legal==="") {
    this.setState({
      legalError: '* This field is required.'
    })
  } else if (other === "") {
    this.setState({
        otherError: '* This field is required.'
    })
  } else if (experienceInIpillusration==="") {
    this.setState({
      experienceInIpillusrationError: '* This field is required.'
    })
  } else if (type === "") {
    this.setState({
      typeError: '* Please select an option.'
    })
  }
  else if (focusArea1 === "") {
    this.setState({
      focusArea1Error: '* Please select an option.'
    })
  } else if (focusArea2 === "") {
    this.setState({
      focusArea2Error: '* Please select an option.'
    })
  }
  else {
    this.setState({
      activeStep: this.state.activeStep + 1,
      error: {
        ...this.state.error,
        experienceInIpillusrationError:"",
        legalError:"",
        otherError:"",
        typeError:"",
        focusArea1Error:"",
        focusArea2Error:""
      }
    })
  }
}
handleNext2 = () => {
  const{countryOfOperation,cityOfOperation,patentOffice,focusArea3,feesType,feesAmount}=this.state
  if (countryOfOperation==="") {
    this.setState({
      countryOfOperationError: '* Please select an option.'
    })
  } else if (cityOfOperation === "") {
    this.setState({
      cityOfOperationError: '* This field is required.'
    })
  } else if (patentOffice.length === 0) {
    this.setState({
      patentOfficeError: '* This field is required.'
    })
  } else if (focusArea3 === "") {
    this.setState({
      focusArea3Error: '* Please select an option.'
    })
  }
  else if (feesType === "") {
    this.setState({
      feesTypeError: '* Please select an option.'
    })
  } else if (feesAmount === "") {
    this.setState({
      feesAmountError: '* Please select an option.'
    })
  }
  else {
    this.setState({
      activeStep: this.state.activeStep + 1,
      error: {
        ...this.state.error,
        countryOfOperationError:"",
        cityOfOperationError:"",
        patentOfficeError:"",
        focusArea3Error:"",
        feesTypeError:"",
        feesAmountError:""
      }
    })
  }
}
handleNext3 = () => {
  const { featuredInTheMedia, IpConference, article, step3FileUplod1, step3FileUplod3, step3FileUplod2 } = this.state;
  if (!featuredInTheMedia) {
    this.setState({
      featuredInTheMediaError: '* Please  select any media.'
    })
  } else if (featuredInTheMedia === 'Yes' && !step3FileUplod1) {
    this.setState({
      error: {
        step3FileUplod1: '* Please  Upload any File.'
      }

    })
  } else if (!IpConference) {
    this.setState({
      IpConferenceError: '* Please  select any conference.'
    })
  } else if (IpConference === 'Yes' && !step3FileUplod2) {
    this.setState({
      error: {
        step3FileUplod2: '* Please  Upload any File.'
      }
    })
  }
  else if (!article) {
    this.setState({
      articleError: '* Please  select any article.'
    })
  } else if (article === 'Yes' && !step3FileUplod3) {
    this.setState({
      error: {
        step3FileUplod3: '* Please  Upload any File.'
      }
    })
  }
  else {
    this.setState({
      activeStep: this.state.activeStep + 1,
      error: {
        ...this.state.error,
        featuredInTheMediaError: '',
        IpConferenceError: '',
        articleError: '',
        step3FileUplod1: '',
        step3FileUplod2: '',
        step3FileUplod3: '',
      }
    })
  }
}
handleNext=()=>{}
  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleClickInfo = (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    this.setState(prevState => ({
      anchorElement: currentTarget,
      openInfoPopper: !prevState.openInfoPopper
    }));
  };
  handleMouseEnterInfo = (event:any) => {
    const { currentTarget } = event;
    this.setState({
      anchorElement: currentTarget,
      openInfoPopper: true
    });
  };

  handleMouseLeaveInfo = () => {
    this.setState({
      openInfoPopper: false
    });
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  handlePatentOfficeSelection = (value: any) => {
    this.setState(prevState => {
      let patentOfficeCopy = [...this.state.patentOffice];
      if (patentOfficeCopy.includes(value)) {
        patentOfficeCopy = patentOfficeCopy.filter(x => x !== value);
      } else {
        patentOfficeCopy = [...patentOfficeCopy, value];
      }
      return {
        ...prevState,
        showpatentOffice: true,
        patentOffice: patentOfficeCopy
      };
    });
  };
  handleClickAway = () => {
    this.setState({ showpatentOffice: false });
  };
  handleSelectBoxClick = () => {
    this.setState((prevState: any) => ({
      showpatentOffice: !prevState.showpatentOffice
    }));
  };
  handletextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    if (/^(?! )[A-Za-z ]{0,200}$/.test(value)) {
      // Check if the value is changing before updating state
      if ((this.state as any)[name] !== value) {
          value.length >= 2 ? delete updatedError[name] : updatedError[name] = "* Should contain minimum 2 characters";
          this.setState({
              [name]: value,
              error: updatedError
          } as Pick<S, keyof S>);
      }
  } else {
      updatedError[name] = "* Should contain only letters and maximum of 200 characters long";
      this.setState({ error: updatedError });
      setTimeout(() => {
        this.setState({ error: false });
      }, 2000);
  }
    
  };
  handleExpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedError = { ...this.state.error };
    const numberFields = ["experienceInIpillusration"];
    if (numberFields.includes(name)) {
      const regexExp = /^\d{0,2}$/;

      // Use regex to check if the value contains only numbers
      if (regexExp.test(value) ) {
          delete updatedError[name];
          // Check if the value is changing before updating state
          if ((this.state as any)[name] !== value) {
              this.setState({
                  [name]: value,
                  error: updatedError
              } as Pick<S, keyof S>);
          }
          if(Number(value) > 99) {
            updatedError[name] = "* Should contain only numbers";
            this.setState({ error: updatedError });
        }
      } else {
          updatedError[name] = "* Allowed numbers with a maximum of 2 digits";
          this.setState({ error: updatedError });
          setTimeout(() => {
            this.setState({ error: false });
          }, 2000);
      }
  }
    
  };
  handleFileUpload = (event: any) => {
    const stateKeyRef = event.target.name;
   
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.setState(({
        [stateKeyRef]: selectedFile
      } as unknown) as Pick<S, keyof S>);
    } else {
      this.setState(({
        [stateKeyRef]: null
      } as unknown) as Pick<S, keyof S>);
    }
  };
  handleFeesFieldChange  = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numberFields = ["feesAmount"]; // Add your number field names here
    let updatedError = { ...this.state.error };

    // Check if the field is in the numberFields array
    if (numberFields.includes(name)) {
        const regexExp = /^\d{0,7}$/; // Adjust the regular expression to allow 0 to 7 digits

        // Use regex to check if the value contains only numbers
        if (regexExp.test(value)) {
            delete updatedError[name];
            // Check if the value is changing before updating state
            if ((this.state as any) [name] !== value) {
                this.setState({
                    [name]: value,
                    error: updatedError
                } as Pick<S, keyof S>);
            }
            if (value.length > 7 && updatedError[name]) {
                setTimeout(() => {
                    delete updatedError[name];
                    this.setState({ error: updatedError });
                }, 3000); // Remove error message after 3 seconds
            }
        } else {
            updatedError[name] = "* Should contain only numbers with a maximum of 7 digits";
            this.setState({ error: updatedError });
            setTimeout(() => {
              this.setState({ error: false });
            }, 2000);
        }
    } else {
        // Handle validation for other fields here
    }
};

  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
