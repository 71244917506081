import React from 'react';
// Customizable Area Start
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from '@material-ui/lab/Pagination';
import {
  Box,
  Chip,
  Typography,
  Button,
  withStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  Drawer,
  Accordion, AccordionDetails, AccordionSummary,
  Divider,Grid,Checkbox, TextField,InputAdornment,
  CircularProgress} from '@material-ui/core';
import {
  verified
} from '../../blocks/dashboard/src/assets';
import GlobalSeachJobsController , {webConfigJSON,Props}  from './GlobalSearchJobsController';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import Slider from '@material-ui/core/Slider';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GlobalJobProfile from './GlobalJobProfile.web';
import { createTheme,ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
const theme = createTheme({
  palette: {
      primary: {
          main: "#206FC4",
      },
      secondary: {
          main: "#696A6A",
      }
  },
  typography: {
      fontFamily: 'Inter,Rubik',
  }
});
// Customizable Area Ends
export default class GlobalSeachJobs extends GlobalSeachJobsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  RadioLabel = (labelText: any) => {
    return (
      <Typography style={{ fontWeight: 600, color: '#000' }}>
        {labelText}
      </Typography>
    );
  };
  profileSections = [
    {
      title: 'Employment history',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (30%)'
    },
    {
      title: 'Education',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Video Introduction',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Skills',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    },
    {
      title: 'Other Experience',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    }
  ];

  simplifiedJSX = this.profileSections.map((section, index) => (
    <div key={index}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <RadioGroup style={webStyle.RadioGroupBox}>
          <FormControlLabel
            value={section.title}
            control={
              <Radio
                style={{
                  ...webStyle.dialogtext,
                  fontSize: '18px',
                  color: '#979797'
                }}
              />
            }
            label={this.RadioLabel(section.title)}
          />
        </RadioGroup>
        <ChevronRightIcon />
      </Box>
      <Typography style={{ ...webStyle.dialogtext, marginLeft: '40px' }}>
        {section.content}
      </Typography>
      <Divider />
    </div>
  ));

  render() {
    // Customizable Area Start
   if(this.state.loading){
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
      </Box>
    );
   }
    return (
      //Merge Engine DefaultContainerj
      <>
       <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer}>
          <Box p={2}>
            <GlobalJobProfile navigation={undefined} id={''} classes={undefined} toggleDrawer={this.toggleDrawer} jobDetails={this.state.jobDetails}/>
          </Box>
        </Drawer>
        <ThemeProvider theme={theme}>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display={'grid'} gridAutoFlow={'row'} gridRowGap={'3rem'} padding={'55px 0px'}>
        {this.props.results.length === 0 ?(  <Typography style={webStyle.noProfile}>
                      No job found
                    </Typography>):(
              this.props.results.map(item => {  
                const {date,time} = this.formatDate(item?.updated_at);
                return(
                <Box
                  style={{
                    borderRadius: '24px',
                    background: 'rgb(245, 247, 249) none repeat scroll 0% 0%',
                    padding: '38px',
                    height:"max-content"
                  }}
                  key={item?.id}
                  onClick={()=>this.toggleDrawer(item?.id)}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                  >
                    <Box>
                      <Typography
                        style={{
                          ...webStyle.text,
                          fontSize: '17px',
                          fontWeight: 700
                        }}
                      >
                        {item?.attributes?.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          ...webStyle.text,
                          fontSize: '17px',
                          fontWeight: 700
                        }}
                      >
                        Est. Budget: ${item?.attributes?.per_day_charge}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <LocationOnIcon
                        style={{ marginLeft: '-5px', marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginTop: '10px',
                          display: 'contents'
                        }}
                      >
                        {item?.attributes?.location}
                      </Typography>
                      <Rating
                        name="simple-controlled"
                        value={3}
                        disabled
                        style={{
                          padding: '8px 0px',
                          width: '90px',
                          marginLeft: '20px',
                          marginRight: '20px'
                        }}
                      />
                      <Typography
                        style={{ ...webStyle.text, marginLeft: '30px' }}
                      >
                        3/5
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={webStyle.text}>
                        Proposals: {item?.attributes.proposals}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ width: '70%' }}>
                    <Typography style={webStyle.description}>
                      {item.attributes.description}
                    </Typography>
                  </Box>

                  <Box style={{ marginTop: '10px', marginBottom: '14px' }}>
                        {item.attributes.skills.map((skill: any, index: number) => (
                          <Chip
                            key={index}
                            label={skill}
                            style={{
                              backgroundColor: '#bed8eb',
                              color: '#2378D1',
                              marginRight: '10px'
                            }}
                          />
                        ))}
                      </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} flex={1} alignItems={'center'}>
                      <img src={verified} style={{ marginRight: '10px' }} />
                      <Typography style={{...webStyle.text,fontWeight:500}}>
                        Payment veified
                      </Typography>
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginLeft: '10px',
                          marginRight: '10px',
                          fontWeight:500
                        }}
                      >
                        {item.attributes.created_at}
                      </Typography>
                    </Box>
                    <Box display={'flex'} style={{ marginTop: '-15px' }}>
                      <Button
                        variant="contained"
                        data-testid='sendProposal-Button'
                        color="primary"
                        style={{
                          borderRadius: '13px',
                          marginRight: '10px',
                          backgroundColor: '#2275CD'
                        }}
                      >
                         send proposals
                      </Button>
                      <Button
                        data-testid={`saveLaterBtn-${item.id}`}
                        variant="contained"
                        style={{
                          color: '#2275CD',
                          backgroundColor: 'white',
                          border: '1px solid #2275CD',
                          borderRadius: '13px'
                        }}
                        startIcon={
                          this.state.favouritesJobIds.includes(
                            item.id
                          ) ? (
                            <FavoriteIcon style={{ color: '#206FC4' }} />
                          ) : (
                            <FavoriteBorderRoundedIcon
                              style={{ color: '#2275CD' }}
                            />
                          )
                        }
                        onClick={() => {
                        }}
                      >
                        {this.state.favouritesJobIds.includes(
                          item.id
                        )
                          ? 'Saved'
                          : 'Save for later'}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                  </Box>
                </Box>
              )}
            ))
           }
            </Box>
            <Box style={webStyle.paginationContainer}>
                <Pagination 
                                        count={this.props.totalPages} 
                                        page={this.props.currentPage} 
                                        onChange={this.handlePageChange}
                                       />
                                    </Box>
        </Grid>


        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container spacing={3} style={webStyle.filterOuterContainer}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyle.filterBox}>
                                        <Typography  color="secondary" style={webStyle.filterText}>{webConfigJSON.filterBy}</Typography>
                                        <Typography  color="secondary" style={webStyle.clearText}>{webConfigJSON.clearAll}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyle.accordinBox}>
                                        <Accordion style={webStyle.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography  color="secondary" style={webStyle.filterText}>{webConfigJSON.jobType}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyle.accordDetail}>
                                                    {
                                                        this.state.jobTypeList.map(experience =>
                                                            <Box key={experience.id} style={webStyle.checkBoxOuter}>
                                                                <Box style={webStyle.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(experience.value)}
                                                                        onChange={() => this.handleCheckboxChange(experience.value)}
                                                                        data-testid={"experienceCheck-" + experience.id}
                                                                        value={experience.value}
                                                                        color="primary"
                                                                    />
                                                                    <Typography color="secondary" >{experience.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                                
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyle.accordinBox}>
                                        <Accordion style={webStyle.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography  color="secondary" style={webStyle.filterText}>{webConfigJSON.clientLocation}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <Box style={webStyle.accordDetail}>
                                                <TextField
                                placeholder="Enter location"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={this.state.searchSkillTerm}
                                style={{ marginBottom: '1rem' }}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon color="disabled" />
                                      </InputAdornment>
                                    ),
                                    style: { borderRadius: "13px" },
                                  }}
                            />
                                                    {
                                                        this.state.ClientLocationList.map(info =>
                                                            <Box key={info.id} style={webStyle.checkBoxOuter}>
                                                                <Box style={webStyle.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography color="secondary">{info.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyle.accordinBox}>
                                        <Accordion style={webStyle.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography  color="secondary" style={webStyle.filterText}>{webConfigJSON.projectLength}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyle.accordDetail}>
                                                    {
                                                        this.state.ProjectLengthList.map(info =>
                                                            <Box key={info.id} style={webStyle.checkBoxOuter}>
                                                                <Box style={webStyle.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography  color="secondary">{info.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                    <Box style={webStyle.accordinBox}>
                                        <Accordion style={webStyle.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="medium" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography  color="secondary" style={webStyle.filterText}>{webConfigJSON.jobDuration}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={webStyle.accordDetail}>
                                                    {
                                                        this.state.jobDurationList.map(info =>
                                                            <Box key={info.id} style={webStyle.checkBoxOuter}>
                                                                <Box style={webStyle.checkBoxInner}>
                                                                    <Checkbox
                                                                        checked={this.state.selectedOptions.includes(info.value)}
                                                                        onChange={() => this.handleCheckboxChange(info.value)}
                                                                        value={info.value}
                                                                        color="primary"
                                                                        data-testid={"clearInfoCheck-" + info.id}
                                                                    />
                                                                    <Typography  color="secondary">{info.label}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                            </Grid>
         </Grid>
         </Grid>
         </ThemeProvider>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop : "14px"
},
  noProfile:{
    fontSize:'1.5rem',
    paddingTop:'5%',
    textAlign:'center' as 'center',
    color:'grey'
},
  rating: {},
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '1rem',
    marginTop: '1rem',
    opacity: 0.6
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterOuterContainer: {
    marginTop: '8%'
},
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  infoCard: {
    borderRadius: '24px',
    background: '#206FC4',
    color: '#fff',
    height: '434px',
    flex: 0.25
  },
  avatar: {
    background: '#FFF',
    width: '100px',
    height: '100px',
    marginBottom: '1.5rem',
    color: '#0A0A0A',

    fontSize: '28px',

    fontWeight: 100
  },
  GridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: '2rem'
  },
  dialogtext: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  accordinBox: {
    background: '#F5F7F9',
    borderRadius: '15px',
},

accordion: {
    background: 'none',
    boxShadow: 'none'
},
checkBoxOuter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
},
checkBoxInner: {
    display: 'flex',
    alignItems: 'center'
},
accordDetail: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'baseline',
    width: '100%'
},
filterText: {
  fontSize: '1.1rem',
  fontWeight: 500
},
clearText: {
  fontSize: '1.1rem',
  fontWeight: 400
},
filterBox: {
display: 'flex' as 'flex',
background: '#F5F7F9' as '#F5F7F9',
borderRadius: '15px',
padding: '15px',
justifyContent: 'space-between' as 'space-between'
},
};
export const PrettoSlider = withStyles({
  root: {
    color: '#206FC4',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },

})(Slider);
// Customizable Area End
