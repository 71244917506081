import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider';

// Customizable Area Start
import React from 'react';
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  
   // Customizable Area End
}
interface S {
  // Customizable Area Start
  rating: any;
  feedBackText: any;
  showOptions: boolean;
  profileImage:string|any;
  fileInputRef:any;
  userID:number |null;
  authToken:string
  freelancerProfileInfo:any
  errorMessage:string
  open:boolean,
  severity:string,
  message:string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerProfileDetailsSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateProfileImageId:string;
  deleteProfileImageId:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.updateProfileImageId="";
    this.deleteProfileImageId="";

    this.state = {
      rating: 2,
      feedBackText: '',
      showOptions: false,
      profileImage:"",
      fileInputRef:React.createRef(),
      userID: null,
      authToken:'',
      freelancerProfileInfo:{},
      errorMessage:"",
      open:false,
      severity:"success",
      message:"",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let data =await storage.get('freelancerProfileInfo')
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
      freelancerProfileInfo: JSON.parse(data),
      profileImage:JSON.parse( data)?.attributes?.profile_image || null,
    });

    // Customizable Area End
  }
  // Customizable Area Start
  toggleOptions = () => {
    this.setState(prevState => ({
      showOptions: !prevState.showOptions
    }));
  };
  handleFileChange =( e:any) => {
    
    const file = e.target.files[0];

  // Check file type
  const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
  if (!allowedTypes.includes(file.type)) {
    this.setState({
      errorMessage: "Please select a JPG, JPEG, or PNG file"
    });
    return;
  }

  // Check file size
  const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
  if (file.size > maxSizeInBytes) {
    this.setState({
      errorMessage: "File size exceeds the limit of 5MB"
    });
    return;
  }

  const reader = new FileReader();
  reader.onloadend = () => {
    this.setState({
      errorMessage:"",
  profileImage:reader.result,
      showOptions: false // Close the options after updating the image
    });
  };
    reader.readAsDataURL(file);
    this.handleUpdateProfileImage(file);
  };

  handleRemoveImage = () => {
    // Simulating the removal of the profile image
    this.setState({
      profileImage: "",
      showOptions: false,     
    });
   this.deleteProfileImage()
   
  
  };

  handleUpdateProfileImage =(updatedIMG: any) => {
    const updateProfileImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "token": this.state.authToken
    };
    // Create a new FormData object
    const formdata = new FormData();
    formdata.append("[data][attributes][profile_image]", updatedIMG);
    
    this.updateProfileImageId = updateProfileImage.messageId;
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.freelancerProfileImageEndPoint}/${this.state.userID}/update_freelancer_profile_image`
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
   
    
  }

  deleteProfileImage = () => {
    const deleteProfileImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };

   
    this.deleteProfileImageId = deleteProfileImage.messageId;
    deleteProfileImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.freelancerProfileImageEndPoint}/${this.state.userID}/freelancer_profile_image_remove`
    );
    deleteProfileImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    deleteProfileImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(deleteProfileImage.id, deleteProfileImage);
  }

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({open:false});

  };
 
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );      
      if(this.updateProfileImageId === apiRequestCallId ||this.deleteProfileImageId === apiRequestCallId ){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson.data){
          storage.set('freelancerProfileInfo', JSON.stringify(responseJson.data));
          
          this.setState({
            open:true,
            profileImage:responseJson.data.attributes.profile_image,
            severity:"success",
            message:`Profile picture has been ${this.updateProfileImageId? "updated":"deleted"} successfully!`
          }) 
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
          this.setState({
          open:true,
          severity:"error", 
          message:`Something went wrong while ${this.updateProfileImageId? "updating":"deleting"} profile picture!`
        }) 
          
        
        }
        this.updateProfileImageId = ""
        this.deleteProfileImageId= ""
      }
     

    }
    

    // Customizable Area End
  }
  // Customizable Area Start
 
  handleRedirection=(componentName: any)=>{
    this.props.navigation.navigate(componentName);
  };
    handleLogout=(componentName: any)=>{
      storage.remove("authToken");
      storage.remove("accountId");
      storage.remove("accountHolderName");
      storage.remove("userType")
      this.props.navigation.navigate(componentName)
      console.log("logout")
    }
  // Customizable Area End
}
