import React from "react";
// Customizable Area Start
import { Box, Button, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { paymentCard, payPal, googlePay, checkImage, selectedPaymentCard, masterCard } from "./assets"


const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Rubik, Roboto',
    }
});
// Customizable Area End
import PaymentController, {
    Props,
    webConfigJSON
} from "./PaymentController";

export default class Payment extends PaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
                <ThemeProvider theme={theme}>
                    <Grid container style={webStyles.outerContainer}>
                        <Grid item xs={12}>
                            <Grid container style={webStyles.gridContainer}>
                                <Grid item xs={12} sm={3}>
                                    {this.state.paymentType === "card" ?
                                        <Box style={webStyles.selectedCardBox} data-testid="card">
                                            <img data-testid="card-selected" src={checkImage} alt="checkIcon" style={webStyles.checkIcon} />
                                            <img src={selectedPaymentCard} alt="payment card" />
                                            <Typography style={webStyles.cardText}>{webConfigJSON.paymentCard}</Typography>
                                        </Box> :
                                        <Box style={webStyles.cardBox} data-testid="pay-by-card"  onClick={() => this.handleSelectPayment("card")}>
                                            <img src={paymentCard} alt="payment card" />
                                            <Typography>{webConfigJSON.paymentCard}</Typography>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {this.state.paymentType === "paypal" ?
                                        <Box style={webStyles.selectedCardBoxTwo} data-testid="paypal">
                                            <img data-testid="card-selected" src={checkImage} alt="checkIcon" style={webStyles.checkIcon} />
                                            <img src={payPal} alt="paypal" />
                                            <Typography>{webConfigJSON.paypal}</Typography>
                                        </Box> :
                                        <Box style={webStyles.cardBoxTwo} data-testid="pay-by-paypal" onClick={() => this.handleSelectPayment("paypal")}>
                                            <img src={payPal} alt="paypal" />
                                            <Typography>{webConfigJSON.paypal}</Typography>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {this.state.paymentType === "googlepay" ?
                                        <Box style={webStyles.selectedCardBoxTwo} data-testid="googlePay">
                                            <img data-testid="card-selected" src={checkImage} alt="checkIcon" style={webStyles.checkIcon} />
                                            <img src={googlePay} alt="paypal" />
                                            <Typography >{webConfigJSON.googlepay}</Typography>
                                        </Box> :
                                        <Box style={webStyles.cardBoxTwo} data-testid="pay-by-googlepay" onClick={() => this.handleSelectPayment("googlepay")}>
                                            <img src={googlePay} alt="paypal" />
                                            <Typography >{webConfigJSON.googlepay}</Typography>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                            <Box>
                                <Typography style={webStyles.cardNumber}>{webConfigJSON.cardNumber}</Typography>
                                <Typography style={webStyles.cardDetails} color="secondary">{this.state.cardDetails}</Typography>

                            </Box>
                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <Box>
                                        <TextField
                                            style={webStyles.cardNumberInput}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={masterCard} alt="mastercard" />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            placeholder="   2412  -  2512  -  1213  -  1212"
                                            value={this.state.cardNumber}
                                            onChange={this.handleCardNumber}
                                            data-testid="card-number"
                                        />
                                        <Grid container style={webStyles.fieldContainer}>
                                            <Grid item xs={12} sm={5}>
                                                <Typography style={webStyles.labelHeading}>{webConfigJSON.labelEnterDetails}</Typography>
                                                <Typography style={webStyles.labelDetails} color="secondary">{webConfigJSON.personalDetails}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <Box style={webStyles.textfieldDisplay}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder={webConfigJSON.placeholderFirstname}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                            }
                                                        }}
                                                        value={this.state.firstName}
                                                        onChange={this.handleFirstName}
                                                        data-testid="first-name"
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder={webConfigJSON.placeholderLastname}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                            }
                                                        }}
                                                        value={this.state.lastName}
                                                        onChange={this.handleLastName}
                                                        data-testid="last-name"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={webStyles.fieldContainer}>
                                            <Grid item xs={12} sm={5}>
                                                <Typography style={webStyles.labelHeading}>{webConfigJSON.labelCvvNumber}</Typography>
                                                <Typography style={webStyles.labelDetails} color="secondary">{webConfigJSON.digitNumber}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "8px",
                                                        }
                                                    }}
                                                    value={this.state.cvvNumber}
                                                    onChange={this.handleCvvNumber}
                                                    data-testid="cvv-number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container style={webStyles.fieldContainer}>
                                            <Grid item xs={12} sm={5}>
                                                <Typography style={webStyles.labelHeading}>{webConfigJSON.labelExpiryDate}</Typography>
                                                <Typography style={webStyles.labelDetails} color="secondary">{webConfigJSON.entryDate}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <Box style={webStyles.textfieldDisplay}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"

                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                            },
                                                            startAdornment: <InputAdornment position="start">MM</InputAdornment>,
                                                        }}
                                                        value={this.state.month}
                                                        onChange={this.handleMonth}
                                                        data-testid="month"
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "8px",
                                                            },
                                                            startAdornment: <InputAdornment position="start">YY</InputAdornment>,
                                                        }}
                                                        value={this.state.year}
                                                        onChange={this.handleYear}
                                                        data-testid="year"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={webStyles.fieldContainer}>
                                            <Grid item xs={12} sm={5}>

                                            </Grid>
                                            <Grid item xs={12} sm={5} style={webStyles.paymentButton}>
                                                <Button data-testid="next-button" color="primary" variant="contained" onClick={this.handleMakePayment}>{webConfigJSON.makePayment}</Button>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    gridContainer: {
        gap: '20px'
    },
    outerContainer: {
        padding: '30px'
    },
    cardBox: {
        border: '1px solid #c1c1c1',
        borderRadius: '10px',
        textAlign: 'center' as 'center',
        padding: '26px 10px',
        cursor: 'pointer'
    },
    cardBoxTwo: {
        border: '1px solid #c1c1c1',
        borderRadius: '10px',
        textAlign: 'center' as 'center',
        padding: '21px',
        cursor: 'pointer'
    },
    selectedCardBox: {
        borderRadius: '10px',
        textAlign: 'center' as 'center',
        padding: '26px 10px',
        background: '#253342',
        position: 'relative' as 'relative',
        color: '#fff',
        cursor: 'pointer'
    },
    checkIcon: {
        position: 'absolute' as 'absolute',
        right: '6%',
        width: '15px',
        height: '15px',
        top: '6%'
    },
    selectedCardBoxTwo: {
        borderRadius: '10px',
        textAlign: 'center' as 'center',
        padding: '21px',
        cursor: 'pointer',
        background: '#253342',
        position: 'relative' as 'relative',
        color: '#fff',
    },
    cardText: {
        fontFamily: 'Roboto',
        marginTop: '5px'
    },
    cardNumber: {
        fontSize: '1.2rem',
        fontWeight: 500,
        marginTop: '2%'
    },
    cardDetails: {
        fontSize: '0.9rem',
        fontFamily: 'Roboto',
        marginBottom: '2%'
    },
    cardNumberInput: {
        // width:'80%'
    },
    textfieldDisplay: {
        display: 'flex',
        gap: '10px'
    },
    fieldContainer: {
        margin: '2% 0',
        justifyContent: 'space-between'
    },
    labelHeading: {
        fontFamily: 'Roboto',
        fontSize:'1.3rem'
    },
    labelDetails: {
        fontFamily: 'Roboto',
        fontSize: '0.8rem',
    },
    paymentButton: {
        textAlign: 'end' as 'end'
    }

};
// Customizable Area End
