import React from 'react';
// Customizable Area Start
import {
  Box,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { uploadIcon3 } from '../../blocks/dashboard/src/assets';
import PatentLisceningExpertController, {
  Props
} from './PatentLisceningExpertController.web';
import FormGroup from '@material-ui/core/FormGroup';
import {
  FileUploadArea,
  theme,
  autoCompleteSvgIcon,
  CountryGuidelines
} from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';

// Customizable Area End
// Customizable Area Start
const PatentlicencingOptions = [
  'Exclusive License',
  'Non Exclusive License',
  'Sub License',
  'Cross-Licensing',
  'Voluntary Licensing',
  'Compulsory Licensing',
  'Carrot Licensing',
  'Stick Licensing'
];
const Qualifications = ['Law', 'Engineering', 'Marketing', 'Other'];

export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>,
      '5': <Box>5</Box>,
      '6': <Box>6</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#F400FF' : '#FEEBFF',
          color: active || completed ? '#FFF' : '#F400FF'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
// Customizable Area End
export default class PatentLisceningExpert extends PatentLisceningExpertController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div style={{ display: 'inline-block' }}>
      <Popper
        open={this.state.openInfoPopper}
        anchorEl={this.state.anchorElement}
        placement={'bottom'}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: this.state.anchorElement
          }
        }}
        style={{
          width: '95%',
          borderRadius: '10px',
          background: '#FFF'
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography
                style={{
                  ...webStyle.header,
                  fontSize: '18px',
                  color: '#4C4C4C',
                  fontWeight: 400,
                  padding: '10px 20px',
                  letterSpacing: '0.27',
                  lineHeight: '35px'
                }}
              >
                {content}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );

  step1 = () => {
    const options = ['3Years', '5Years'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What are your qualifications?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="qualifications"
            name="qualifications"
            value={this.state.qualifications}
            onChange={this.handleChange}
            data-testid="PLE1-qualifications"
          >
            {Qualifications.map((qualification: any) => (
              <FormControlLabel
                value={qualification}
                control={<Radio style={{ color: '#F400FF' }} />}
                label={qualification}
              />
            ))}
          </RadioGroup>
          {!this.state.qualifications && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['qualifications']}</Typography>}

          <Typography style={webStyle.header}>
            No.of years as a patent licensing expert?
          </Typography>
          <Autocomplete
            key="Select Years"
            options={options}
            style={{
              ...webStyle.autoCompleteBox,
              width: 330
            }}
            popupIcon={autoCompleteSvgIcon}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Years"
                variant="outlined"
                style={{
                  ...webStyle.autoCompleteBox
                }}
              />
            )}
          />

          <Typography style={webStyle.header}>
            Types of patent licensing expertise<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="patentlicensing"
            name="patentlicensing"
            value={this.state.patentlicensing}
            onChange={this.handleChange}
            data-testid="PLE1-patentlicensing"
          >
            {PatentlicencingOptions.map((option: any) => (
              <FormControlLabel
                value={option}
                control={<Radio style={{ color: '#F400FF' }} />}
                label={option}
              />
            ))}
          </RadioGroup>
          {!this.state.patentlicensing && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['patentlicensing']}</Typography>}

        </Box>
      </Box>
    );
  };
  step2 = () => {
    const country = ['India', 'America'];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            What is your target market?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={webStyle.RadioGroupBox}
            aria-label="market"
            name="market"
            value={this.state.market}
            onChange={this.handleChange}
            data-testid="PLE2-market"
          >
            <FormControlLabel
              value="CountryWide"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="CountryWide"
            />
            <FormControlLabel
              value="WorldWide"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="WorldWide"
            />
          </RadioGroup>
          {!this.state.market && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['market']}</Typography>}

          <Typography style={webStyle.header}>Country of operation</Typography>
          <Autocomplete
            key="Select Country"
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{
              ...webStyle.autoCompleteBox,
              width: 400
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Country"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>City of operation</Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{
              ...webStyle.autoCompleteBox,
              width: 400
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select City"
                variant="outlined"
              />
            )}
          />
          <Typography style={webStyle.header}>
            Which country's patent law guidelines are your familiar with?
          </Typography>
          <FormGroup
            data-testid="PLE2-Other"
            onChange={this.handleChangecountryLawGuidelines}
          >
            {CountryGuidelines.map((countryItem: any) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={countryItem}
                    style={
                      this.state.countryLawGuidelines.indexOf(countryItem) > -1
                        ? { color: '#F400FF' }
                        : { color: 'lightgray' }
                    }
                  />
                }
                label={countryItem}
              />
            ))}
          </FormGroup>
          <Typography style={webStyle.header}>
            Focus area or industry of expertise as a patent licensing agent
          </Typography>
          <Autocomplete
            options={country}
            popupIcon={autoCompleteSvgIcon}
            style={{
              ...webStyle.autoCompleteBox,
              width: 400
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Area"
                variant="outlined"
              />
            )}
          />
        </Box>
      </Box>
    );
  };
  step3 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        style={{ marginBottom: '450px' }}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Box display={'flex'} alignItems={'baseline'} >
            <Typography style={webStyle.header}>Fees</Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400 ,marginLeft:"5px"}}
            >
              (Per day/per assignment)<span style={webStyle.importantField}>*</span>
            </Typography>
          </Box>
          <TextField
            data-testid="PLE3-fees"
            placeholder="Enter fees"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.fees}
            name="fees"
            onChange={this.handleFeesFieldChange}
            style={webStyle.inputField}
            error={this.state.numberError['fees']?true:false}
            helperText={this.state.numberError['fees']}
          />
        </Box>
      </Box>
    );
  };
  step4 = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            Have you been featured in the media?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="media"
            name="media"
            value={this.state.media}
            onChange={this.handleRadioChange}
            data-testid="PLE4-media"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.media && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['media']}</Typography>}

          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link </Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400 ,marginLeft: '5px' }}
            >
              (5 each){this.state.media == 'Yes'&& <span style={webStyle.importantField}>*</span>}
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link1"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link1}
            name="link1"
            style={webStyle.inputField}
            onChange={this.handleLinkField}
            error={this.state.error['link1']}
            helperText={this.state.error['link1']}
            disabled={this.state.media === 'No'}
          />
          <Typography style={webStyle.header}>
            Have you spoken at IP conferences?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label=" ipConferences"
            name="ipConferences"
            value={this.state.ipConferences}
            onChange={this.handleRadioChange}
            data-testid="PLE4-ipConferences"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.ipConferences && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['ipConferences']}</Typography>}

          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link </Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400,marginLeft: '5px' }}
            >
              (5 each){this.state.ipConferences == 'Yes'&& <span style={webStyle.importantField}>*</span>}
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link2"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link2}
            name="link2"
            onChange={this.handleLinkField}
            error={this.state.error['link2']}
            helperText={this.state.error['link2']}
            style={webStyle.inputField}
            disabled={this.state.ipConferences === 'No'}

          />
          <Typography style={webStyle.header}>
            Have you published any articles?<span style={webStyle.importantField}>*</span>
          </Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="artcles"
            name="artcles"
            value={this.state.artcles}
            onChange={this.handleRadioChange}
            data-testid="PLE4-artcles"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.artcles && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['artcles']}</Typography>}
          <Box display={'flex'} alignItems={'baseline'}>
            <Typography style={webStyle.header}>Attach Link</Typography>
            <Typography
              style={{ ...webStyle.header, fontSize: '16px', fontWeight: 400,marginLeft: '5px' }}
            >
              (5 each){this.state.artcles == 'Yes'&& <span style={webStyle.importantField}>*</span>} 
            </Typography>
          </Box>
          <TextField
            data-testid="PLE4-link3"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.link3}
            name="link3"
            onChange={this.handleLinkField}
            error={this.state.error['link3']}
            helperText={this.state.error['link3']}
            style={webStyle.inputField}
            disabled={this.state.artcles === 'No'}

          />
        </Box>
      </Box>
    );
  };
  step5 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>Awards<span style={webStyle.importantField}>*</span></Typography>
          <RadioGroup
            style={{
              ...webStyle.RadioGroupBox,
              gridAutoFlow: 'column',
              width: '20%'
            }}
            aria-label="awards"
            name="awards"
            value={this.state.awards}
            onChange={this.handleRadioChange}
            data-testid="PLE5-awards"
          >
            <FormControlLabel
              value="Yes"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: '#F400FF' }} />}
              label="No"
            />
          </RadioGroup>
          {!this.state.awards && <Typography style={{ color: 'red', fontSize: '12px', margin: '5px 5px' }}>{this.state.radioError['awards']}</Typography>}

          <Typography style={webStyle.header}>Government{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-government"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.government}
            name="government"
            onChange={this.handleLinkField}
            error={this.state.error['government']}
            helperText={this.state.error['government']}
            style={webStyle.inputField}
            disabled={this.state.awards === 'No'}

          />
          <FileUploadArea
            id="PatentLicencingExp-step-5-fileInputgovernment"
            btnContent={
              currentStateData['step5GovernmentFileUplod']
                ? currentStateData['step5GovernmentFileUplod']?.name
                :'Upload certificate'}
                onChange={this.handleFileUpload}
                stateKeyRef={"step5GovernmentFileUplod"}
            testId={'PatentLicencingExp-step-5-fileInputgovernment'}
            uploadIcon={uploadIcon3}
            uploadTextColor={'#F400FF'}
            border={'1px dashed #F400FF'}
            disabled={this.state.awards === 'No'}

          />
          {!this.state.step5GovernmentFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5GovernmentFileUplod']}</Typography>}
          <Typography style={webStyle.header}>Industry{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-Industry"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.industry}
            name="industry"
            onChange={this.handleLinkField}
            error={this.state.error['industry']}
            helperText={this.state.error['industry']}
            style={webStyle.inputField}
            disabled={this.state.awards === 'No'}

          />
          <FileUploadArea
            id="PatentLicencingExp-step-5-fileInputindustry"
            btnContent={
              currentStateData['step5IndustryFileUplod']
                ? currentStateData['step5IndustryFileUplod']?.name
                :'Upload certificate'}
            testId={'PatentLicencingExp-step-5-fileInputindustry'}
            uploadIcon={uploadIcon3}
            uploadTextColor={'#F400FF'}
            onChange={this.handleFileUpload}
            stateKeyRef={"step5IndustryFileUplod"}
            border={'1px dashed #F400FF'}
            disabled={this.state.awards === 'No'}

          />
          {!this.state.step5IndustryFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5IndustryFileUplod']}</Typography>}
          <Typography style={webStyle.header}>Academic{this.state.awards == 'Yes'&& <span style={webStyle.importantField}>*</span>}</Typography>
          <TextField
            data-testid="PLE5-academic"
            placeholder="Enter link"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.academic}
            name="academic"
            onChange={this.handleLinkField}
            error={this.state.error['academic']}
            helperText={this.state.error['academic']}
            style={webStyle.inputField}
            disabled={this.state.awards === 'No'}

          />
          <FileUploadArea
            id="PatentLicencingExp-step-5-fileInputacademic"
            btnContent={
              currentStateData['step5AcademicFileUplod']
                ? currentStateData['step5AcademicFileUplod']?.name
                :'Upload certificate'}
            testId={'PatentLicencingExp-step-5-fileInputacademic'}
            uploadIcon={uploadIcon3}
            uploadTextColor={'#F400FF'}
            onChange={this.handleFileUpload}
            stateKeyRef={"step5AcademicFileUplod"}
            border={'1px dashed #F400FF'}
            disabled={this.state.awards === 'No'}

          />
         {!this.state.step5AcademicFileUplod && <Typography style={{ color: 'red' }}>{this.state.error['step5AcademicFileUplod']}</Typography>}
        </Box>
      </Box>
    );
  };
  step6 = () => {
    const currentStateData: any = this.state;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginBottom={"200px"}
      >
        <Box display={'flex'} flexDirection={'column'} flex={0.6}>
          <Typography style={webStyle.header}>
            Successful patent / inventions licensed for patent holders /
            inventors
          </Typography>
          <TextField
            data-testid="PLE6-successfulPatent"
            placeholder="Number of success"
            inputProps={{
              style: {
                ...webStyle.outlinedInput
              }
            }}
            InputProps={{
              style: {
                borderRadius: '13px',
                width: '60%'
              }
            }}
            variant="outlined"
            value={this.state.successfulPatent}
            name="successfulPatent"
            onChange={this.handleChange}
            error={this.state.error['successfulPatent']}
            helperText={this.state.error['successfulPatent']}
            style={webStyle.inputField}
          />
          <Typography style={webStyle.header}>Upload files</Typography>
          <FileUploadArea
            id="PatentLicencingExp-step-6-fileInputsuccessfulPatent"
            btnContent={
              currentStateData['step6NumberOfSuccessFileUpload']
                ? currentStateData['step6NumberOfSuccessFileUpload']?.name
                :'Upload files'}
            testId={'PatentLicencingExp-step-6-fileInputsuccessfulPatent'}
            uploadIcon={uploadIcon3}
            uploadTextColor={'#F400FF'}
            onChange={this.handleFileUpload}
            stateKeyRef={"step6NumberOfSuccessFileUpload"}
            border={'1px dashed #F400FF'}    
          />
        {!this.state.step6NumberOfSuccessFileUpload && <Typography style={{ color: 'red' }}>{this.state.error['step6NumberOfSuccessFileUpload']}</Typography>}
        </Box>
      </Box>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      case 4:
        return <Box>{this.step5()}</Box>;
      case 5:
        return <Box>{this.step6()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    
    const steps = [0, 1, 2, 3, 4, 5];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Box padding={'3%'}>
          <Box style={webStyle.outerContainer}>
          <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
            <Box style={webStyle.root}>
              {/* stepper component starts */}
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: React.ReactNode } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
          {this.getStepComponent()}
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            marginTop={'60px'}
          >
            <Button
              variant="contained"
              color="default"
              data-testid="PLE-back"
              onClick={() =>
                this.state.activeStep === 0
                    ? this.handleredirection(
                      'ProfessionalAccountRegistration'
                    )
                    : this.handleBack()
              }
              style={{
                border: '1px solid #F400FF',
                color: '#F400FF',
                width: '130px',
                height: '40px',
                flexShrink: 0,
                backgroundColor: 'white',
                borderRadius: '13px'
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={webStyle.nextButton}
              onClick={() =>
                this.state.activeStep === 5
                  ? this.handleredirection('ProfessionalAccountRegistration')
                  : this.handleNext()
              }
              data-testid="PLE-next"
            >
              {this.state.activeStep === 5 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '40%',
    flex:"0.7"
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  invento: {
    width:"250px",
    height:"61px",
    objectFit:"cover",
     marginTop: "25px",
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '5px',
    marginTop: '23px'
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },

  UploadButton: {
    height: '165px',
    width: '751px',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    // backgroundColor: '#FDDBFF',
    border: '1px dashed #F400FF',
    borderRadius: '12px'
  },

  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '130px',
    height: '40px',
    flexShrink: 0,
    borderRadius: '13px',
    color: '#FFF',
    backgroundColor: '#F400FF'
  },
  autoCompleteBox: {
    borderRadius: '13px',
    '& .MuiInputBase-root .MuiOutlinedInput-root .MuiAutocomplete-inputRoot .MuiInputBase-fullWidth .MuiInputBase-formControl .MuiInputBase-adornedEnd .MuiOutlinedInput-adornedEnd': {
      borderRadius: '13px'
    }
  },
  importantField:{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FF5E5B"
  }
};
// Customizable Area End
