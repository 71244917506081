// Customizable Area Start
import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Footer from './Components/Footer.web';
import FreelancerOverviewController, {
  Props
} from './FreelancerOverviewController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
// Customizable Area End
export default class FreelancerOverview extends FreelancerOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar
          navigation={this.props.navigation}
          isFromHomePage={true}
        />
        <Box style={styles.mainContainer}>
          <Box display={'grid'} gridAutoFlow={'column'} width={'12%'}>
            <Typography
              style={{
                ...styles.text,

                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px'
              }}
            >
              Reports
            </Typography>
            <ChevronRightIcon style={{ marginTop: '4px' }} />
            <Typography
              style={{
                ...styles.text,

                fontSize: '20px',
                fontWeight: 500,
                marginBottom: '15px',
                color: '#206FC4'
              }}
            >
              Overview
            </Typography>
          </Box>
          <Typography style={styles.heading as React.CSSProperties}>
            Overview
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: '0.25fr, 0.75fr',
              gridRowGap: '2rem'
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridColumnGap: '2rem',
                gridTemplateColumns: 'repeat(4, 1fr)'
              }}
            >
              <Box style={styles.Content}>
                <Typography style={{ fontSize: '18px', fontFamily: 'inter' }}>
                  Work in progress
                </Typography>
                <Typography style={{ color: '#206FC4' }}>$50.00</Typography>
              </Box>
              <Box style={styles.Content}>
                <Typography style={{ fontSize: '18px', fontFamily: 'inter' }}>
                  Inreview
                </Typography>
                <Typography style={{ color: '#206FC4' }}>$0.00</Typography>
              </Box>
              <Box style={styles.Content}>
                <Typography style={{ fontSize: '18px', fontFamily: 'inter' }}>
                  Pending
                </Typography>
                <Typography style={{ color: '#206FC4' }}>$0.00</Typography>
              </Box>
              <Box style={styles.Content}>
                <Typography style={{ fontSize: '18px', fontFamily: 'inter' }}>
                  Available
                </Typography>
                <Typography style={{ color: '#206FC4' }}>$0.00</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridColumnGap: '2rem',
                gridTemplateColumns: 'repeat(4, 1fr)'
              }}
            >
              <Box
                style={{
                  ...styles.Content,
                  height: '400px',
                  alignContent: 'baseline'
                }}
              >
                <Typography style={{ fontSize: '30px', fontWeight: 600 }}>
                  Hotel Management Website
                </Typography>
                <Typography>January 2021-Present</Typography>
                <Typography style={{ color: '#206FC4', fontStyle: 'italic' }}>
                  $10.00/hr
                </Typography>

                <Box style={{ display: 'flex' }}>
                  <div>
                    <Typography>Total Hours Tracked:</Typography>
                    <Typography>Client’s Estimation:</Typography>
                    <Typography>Total Earned:</Typography>
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      72 Hour
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>
                      120 Hour
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>
                      $92.50
                    </Typography>
                  </div>
                </Box>
              </Box>

              <Box style={styles.Content}>
                <Typography>You have no work in progress</Typography>
              </Box>
              <Box style={styles.Content}>
                <Typography>You have no work in progress</Typography>
              </Box>
              <Box style={{ ...styles.Content, alignContent: 'none' }}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'end'}
                >
                  <Typography style={{ marginTop: '35px' }}>
                    You have no work in progress
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'end'}
                >
                  <Button variant="contained" color="primary" style={{backgroundColor:"#206FC4"}}>
                    setup a payment
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Footer />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainContainer: {
    flexGrow: 1,
    maxWidth: '100%',
    margin: '30px'
  },
  heading: {
    height: '36px',
    color: '#3F3F3F',
    fontFamily: 'Inter',
    fontSize: '30px',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: '20px'
  },

  Content: {
    display: 'inherit',
    color: '#5A5A5A',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 400,
    backgroundColor: '#f0f4f7',
    padding: '20px 25px',
    alignContent: 'center'
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  }
};
// Customizable Area End
