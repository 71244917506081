//Customizable Area Start
import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Box, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Message, profile } from "../assets";
import storage from "../../../../framework/src/StorageProvider";
import { inventohub } from "../../../landingpage/src/assets";
//Customizable Area End

//Customizable Area Start
const useStyles = makeStyles(() => ({
  root: {},
  title: {
    color: "#000000",
    borderRight: "2px solid #E6E9EA",
    lineHeight: "5rem",
    paddingRight: "1rem",
  },
  menuItem: {
    marginRight: "10px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginLeft: "2rem",
    color: " #000000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "29px",
    textAlign: "left",
  },
  toolBar: {
    backgroundColor: " #F2F5F7",
    height: "5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  imgAvatar: {
    "&.MuiAvatar-img": {
      borderLeft: "2px solid #E6E9EA",
      lineHeight: "5rem",
      paddingRight: "1rem",
    },
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "2rem",
    "&..MuiOutlinedInput-root": {
      borderRadius: "18px",
    },
  },
  searchInput: {
    borderRadius: "15rem",
    marginLeft: "1rem",
    width: "25rem",
    flexGrow: 1,
  },
  imgAvatarBox: {
    display: "flex",
    gap: "30px",
  },
  boxStyle: {
    display: "flex",
  },
}));

const NavBar: React.FC<{navigation: {navigate: Function}}> = ({navigation}) => {
  const classes = useStyles();
  const [searchValue,setSearchValue] = useState("");
  const menuItems = [
    { label: "Find Work", target: "FindWork" },
    { label: "My Jobs", target: "MyJobs" },
    { label: "Reports", target: "Reports" },
    { label: "Message", target: "ContactBox" },
  ];
  const handleMessageClick = ():void => {
    navigation.navigate('ContactBox') 
  };

  const handleMenuItemClick = (target: string) => {
    navigation.navigate(target);
  };
  const handleAvatarClick = ():void => {
    navigation.navigate('Settings') 
  };
  const handleInventoNavigation = ():void => {
    navigation.navigate('ClientHomePage') 
  };
  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }
  const keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.keyCode === 13){
      navigation.navigate('SearchTalent')
      storage.set("searchedValue", searchValue)
    }
  }
  //Customizable Area End
  return (
    //Customizable Area Start
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <Box className={classes.boxStyle}>
          <img src={inventohub} style={{width:"100px",height:"40px",objectFit:"cover", marginRight:"22px"}} onClick={handleInventoNavigation} data-test-id="handleInventoNavigation" />
            {menuItems.map((item) => (
              <Typography
                key={item.label}
                variant="h6"
                className={classes.menuItem}
                onClick={() => handleMenuItemClick(item.target)}
              >
                {item.label}
              </Typography>
            ))}
          </Box>
          <Box className={classes.imgAvatarBox}>
            <div className={classes.searchContainer}>
              <TextField
                value={searchValue}
                variant="outlined"
                data-testid="search-field"
                placeholder="Search"
                className={classes.searchInput}
                onChange={handleChange}
                onKeyDown={keyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Avatar alt="image" src={Message} onClick = {handleMessageClick} className={classes.imgAvatar} style={{cursor:"pointer"}}/>
            <div onClick={handleAvatarClick} data-testid="avatar" style={{cursor:"pointer"}}>
              <Avatar alt="image" src={profile} className={classes.imgAvatar} />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
//Customizable Area End
