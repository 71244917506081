// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
export const webConfigJSON = require('./config.js');
import storage from "framework/src/StorageProvider";

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  HowToFindWorkRes:any;
  token:string|null;
  
}
interface Attributes {
  description: string;
}
// Customizable Area End
interface SS {
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
export default class HowToFindWorkController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start
 FindWorkResId:string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.FindWorkResId=""
    this.state = {
      HowToFindWorkRes:[],
      token:""
    
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchFindWorkRes()
    this.setState({token:localStorage.getItem('authToken')})

    // Customizable Area End
  }
  // Customizable Area Start
  fetchFindWorkRes =() => {
    const FindWorkResDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.validationApiContentType
    };
    

    this.FindWorkResId = FindWorkResDetailsRequest.messageId;
    FindWorkResDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.FindWorkDetailsRequestEndPoint
    );
    FindWorkResDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    FindWorkResDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getMethod
    );

    runEngine.sendMessage(
      FindWorkResDetailsRequest.id,
      FindWorkResDetailsRequest
    );
  };

  handleRedirection = (componentName: string) => {
    if (componentName) {
      this.props.navigation.navigate(componentName);
    }
  };
  handleSetStorage= async()=>{
    await storage.set("searchSelect","IP Jobs")
  }
   // Customizable Area End
  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.FindWorkResId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {                   
          this.setState({ HowToFindWorkRes: responseJson.data});                  
          
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End
