import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  Checkbox,
  FormControl,
  Button,
  Container
} from '@material-ui/core';
import SendProposalsController, { Props } from './SendProposalsController.web';
import { dot, locationIcon } from './assets';
import Rating from '@material-ui/lab/Rating/Rating';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { blue } from '@material-ui/core/colors';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class SendProposals extends SendProposalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const GreenCheckbox = withStyles({
      root: {
        color: '#535353',
        '&$checked': {
          color: blue[600]
        }
      },
      checked: {}
    })(Checkbox);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Container style={{ marginTop: '2rem' }}>
          <Grid container spacing={2} style={{ display: 'flex' }}>
            <Grid item xs={12} md={9} sm={6}>
              <Box className={classes.mainbox}>
                <Typography className={classes.text}>
                  {this.state.jobDetails.name}
                </Typography>
                <Box className={classes.subBox}>
                  <Box className={classes.locationBox}>
                    <img src={locationIcon} className={classes.location} />
                    <Typography style={{ marginLeft: '6px', fontWeight: 500 }}>
                    {this.state.jobDetails.location}
                    </Typography>
                    <Rating
                      name="size-small"
                      defaultValue={this.state.jobDetails.rating}
                      size="small"
                      style={{ marginLeft: '15px' }}
                    />
                    <Typography style={{ marginLeft: '15px' }}>
                     {this.state.jobDetails.rating}
                    </Typography>
                  </Box>
                  <Box className={classes.locationBox}>
                    <Typography style={{ marginLeft: '6px', fontWeight: 500 }}>
                    {this.state.jobDetails.created_at}
                    </Typography>
                    <img src={dot} style={{ marginLeft: '5px' }} />
                    <Typography style={{ marginLeft: '5px' }}>10 pm</Typography>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box>
                  <Typography className={classes.text}>
                    Project Descriptions:
                  </Typography>
                  <Typography className={classes.text2}>
                   {this.state.jobDetails.description}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Typography className={classes.text}>
                  Skills and expertise:
                </Typography>
                <Box style={{ display: 'flex' }}>
                  {this.state.jobDetails.skills?.map((skill: any, index: number) => {
                    return (
                      <Typography className={classes.php} key={index}>
                        {skill}
                      </Typography>
                    );
                  })}
                </Box>
                <Divider className={classes.divider} />
                <Typography className={classes.text}>Project time:</Typography>
                <Box style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>1</Avatar>
                      <Box>
                        <Typography className={classes.text3}>
                        {this.state.jobDetails.employment_term}
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          Less than 30 hrs/week
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>2</Avatar>
                      <Box>
                        <Typography className={classes.text3}>
                         {this.state.jobDetails.duration}
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          Project length
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>3</Avatar>
                      <Box>
                        <Typography className={classes.text3}>
                          Intermediate
                        </Typography>
                        <Typography
                          className={{ ...classes.text3, fontWeight: 400 }}
                        >
                          I am looking for a mix of experience and valu
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider className={classes.divider} />
                <Typography className={classes.text}>
                  Project time:{' '}
                  <span style={{ fontWeight: 600, color: '#555555' }}>
                    Ongoing Project
                  </span>
                </Typography>
                <Divider className={classes.divider} />
                <>
    <Typography className={classes.text}>
      Activity on this job:
    </Typography>
    <Box style={{ marginLeft: '10px' }}>
      {this.state.activityData.map((item, index) => (
        <Typography key={index} style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
          <GreenCheckbox
            checked={true}
            disabled={true}
            size="medium"
            color="primary"
            name="containsUppercase"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
          {item.label}: {this.state.jobDetails[item.value]}
        </Typography>
      ))}
    </Box>
    <Divider className={classes.divider} />
    <Typography className={classes.text}>
      About the client:
    </Typography>
    <Box style={{ marginLeft: '10px' }}>
      {this.state.clientData.map((item, index) => (
        <Typography key={index} style={{ fontFamily: "'Inter', sans-serif", marginTop: '4px' }}>
          <GreenCheckbox
            checked={true}
            disabled={true}
            size="medium"
            color="primary"
            name="containsUppercase"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
          {item.label}: {this.state.jobDetails[item.value]}
        </Typography>
      ))}
    </Box>
  </>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Box className={classes.mainbox2}>
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography className={classes.text4}>
                    {' '}
                    <span style={{ fontWeight: 600 }}>$</span>5
                    <span style={{ fontWeight: 600 }}>00</span>
                  </Typography>
                  <Typography className={classes.proposals}>
                    Proposals:{this.state.jobDetails.proposals}
                  </Typography>
                </Box>
                <Typography className={classes.budget}>Estd Budget</Typography>
                <Box>
                  <FormControl fullWidth>
                    <Button
                      onClick={this.handleApply}
                      className={classes.applyBtn}
                      variant="contained"
                      color="primary"
                    >
                      APPLY NOW
                    </Button>                   
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '100%',
    backgroundColor: 'lightblue',
    padding: '10px',
    border: '1px solid #ccc',
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#206FC4'
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 6px)'
    }
  },
  mainbox: {
    border: '1px solid #E1E1E1',
    borderRadius: '15px'
  },
  text: {
    fontSize: '18px',
    fontWeight: 200,
    fontFamily: 'Inter',
    marginTop: '10px',
    marginLeft: '20px'
  },
  location: {
    height: '20px'
  },
  locationBox: {
    display: 'flex',
    marginTop: '10px',
    fontFamily: 'Inter',
    alignItems: 'center',
    color: '#555555'
  },
  divider: {
    backgroundColor: '#E1E1E1',
    marginTop: '20px',
    marginBottom: '20px'
  },
  subBox: {
    marginLeft: '20px'
  },
  text2: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginTop: '10px',
    marginLeft: '20px',
    color: '#6C6C6C',
    width: '80%'
  },
  php: {
    background: ' #EBF1F9',
    borderRadius: '30px',
    width: 'fit-content',
    padding: '5px 20px 5px 20px',
    color: '#246dc3',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginLeft: '15px',
    marginTop: '20px'
  },
  avtar: {
    backgroundColor: ' #1F6DC1',
    fontWeight: 200,
    padding: '8px',
    marginLeft: '20px'
  },
  subgrid: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px'
  },
  text3: {
    fontSize: '18px',
    fontFamily: 'Inter',
    color: '#6C6C6C'
  },
  mainbox2: {
    border: '1px solid #E1E1E1',
    borderRadius: '15px',
    marginLeft: '25px',
    padding: '15px'
  },
  text4: {
    fontSize: '22px',
    fontFamily: 'Roboto',
    padding: '20px 20px 0px 20px',
    color: ' #525252'
  },
  proposals: {
    fontSize: '17px',
    fontFamily: 'Roboto',
    padding: '10px',
    color: ' #525252',
    marginRight: '10px',
    marginTop: '10px'
  },
  budget: {
    fontSize: '18px',
    fontFamily: 'Roboto',
    color: ' #525252',
    marginLeft: '20px'
  },
  applyBtn: {
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#2676D8',
    marginTop: '50px',
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  favouriteBtn: {
    padding: '12px',
    borderRadius: '8px',
    marginTop: '10px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  }
};

export default withStyles(webStyle)(SendProposals);
// Customizable Area End
