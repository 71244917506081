import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editContactOpen: boolean;
  full_phone_number: number;
  id: number;
  email: string;
  first_name: string;
  address: string;
  country: string;
  about_me: string;
  ratings: number;
  bankDetails: {
    accountNo: string;
    PanNo: string;
    IfscCode: string;
  };
  editBankModal: boolean;
  accountHolderName: string;
  popUpMessage: boolean;
  responseMessage: boolean;
  // Customizable Area End
}
interface SS {}

export default class FreelancerPersonalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  nameReg = configJSON.nameReg;
  phoneReg = configJSON.phoneReg;
  updateUserDetailsAPICallID: string = "";
  GetContactInfoId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.updateUserDetailsAPICallID = "";
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      editContactOpen: false,
      id: 0,
      email: "",
      address: "",
      first_name: "",
      country:"",
      about_me:"",
      ratings: 4,
      full_phone_number: 1234567890,
      bankDetails: {
        accountNo: "XXXX XXXX XXXX 7540",
        PanNo: "XXXXXX234D",
        IfscCode: "XXXXXXX5943",
      },
      editBankModal: false,
      accountHolderName: "",
      popUpMessage: false,
      responseMessage: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      accountHolderName: await storage.get("accountHolderName"),
    });
    this.getUserDetails();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
            if (responseJson && !responseJson.errors) {
        if (requestCallId === this.GetContactInfoId) {
          this.setState({ email: responseJson?.data.attributes?.email });
          this.setState({ first_name: responseJson?.data.attributes?.first_name });
          this.setState({ id: responseJson?.data.attributes?.id });
          this.setState({ full_phone_number: responseJson?.data.attributes?.full_phone_number });
          this.setState({ address: responseJson?.data.attributes?.address });
        }
      }

      if (responseJson && !responseJson.errors) {
        if (requestCallId === this.updateUserDetailsAPICallID) {
          this.getUserDetails()
          this.setState({editContactOpen:false})
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleContactEditModal = () => {
    this.setState({ editContactOpen: !this.state.editContactOpen });
  };

  handleDialog = () => {
    this.setState({
      popUpMessage: !this.state.popUpMessage,
    });
  };

  preventETypeNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift",
    ];
    return (
      (allowedKeys.includes(event.key)
        ? false
        : !/^[^A-Za-z\W_]+$/.test(event.key)) && event.preventDefault()
    );
  };
  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
     first_name : value
    });
  };
  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const phoneNumber = parseInt(value); 
    this.setState({
      full_phone_number: phoneNumber,
    });
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
     email : value
    });
  };

  handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
     address : value
    });
  };
  handleBankEditModal = () => {
    this.setState({ editBankModal: !this.state.editBankModal });
  };
  handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      bankDetails: { ...this.state.bankDetails, [event.target.name]: value },
    });
  };
  maskSensitiveData = (data: any) => {
    // Display only the last four characters and mask the rest
    const maskedData = "*".repeat(data.length - 4) + data.slice(-4);
    return maskedData;
  };

  getUserDetails = async () => {
    const accountId = await storage.get("accountId");
    const token = await storage.get("authToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    this.GetContactInfoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetUserData +
        `${accountId}` +
        configJSON.freelancerProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsMethodeType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateUserDetails = async () => {
    const accountId = await storage.get("accountId");
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const body = {
      account: {
        first_name: this.state.first_name,
        email: this.state.email,
        full_phone_number: this.state.full_phone_number,
        country: this.state.country,
        about_me: this.state.about_me,
        address: this.state.address,
        ratings: this.state.ratings,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserDetailsAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetUserData + `${accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

 

  // Customizable Area End
}
