import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  results: any[];
  handlePageChange:(event: React.ChangeEvent<unknown>, page: number)=>void
  currentPage:number
  totalPages:number
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  id:number
  loading:boolean;
  currentPage:number;
  totalPages:number;
  IpTalent: string;
  IpJobs:string;
  search: string;
  searchedList: string[];
  experienceList: {
    id: number,
    value: string,
    label: string,
    count: string,
    min: number,
    max: number
  }[];
  selectedOptions: string[];
  proposalSelected: string[];
  minRange: string;
  maxRange: string;
  hoursSelected: string[];
  proposalList: {
    id: number,
    value: string,
    minRange: number,
    maxRange: number,
    label: string,
  }[];
  hourlyRateList: {
    id: number,
    value: string,
    minRange: number,
    maxRange: number,
    label: string,
  }[];
  minHours: string,
  maxHours: string,
  clearInfoList: {
    id: number,
    value: string,
    label: string,
    count: string,
  }[];
  skillsList:{
    id: number,
    value: string,
    label: string,
  }[];
  locationList:{
    id: number,
    value: string,
    label: string,
  }[];
  authToken: string;
  searchedResult: {
    id: string,
    type: string,
    attributes: {
      freelancers: {
        full_name: string,
        user_name: string,
        job_title: string,
        education: string,
        aadhar_img: {
          url: string
        },
        user_status: boolean,
        talent_saved: boolean,
        skills: string[],
        hour_rate: string,
        description: string,
        country: string
      }
    }
  }[];
  totalFreelancer: {
    id: string,
    type: string,
    attributes: {
      freelancers: {
        full_name: string,
        user_name: string,
        job_title: string,
        education: string,
        aadhar_img: {
          url: string
        },
        user_status: boolean,
        talent_saved: boolean,
        skills: string[],
        hour_rate: string,
        description: string,
        country: string
      }
    }
  }[];
  showMore: boolean;
  userName: string;
  perDayRate: string;
  earnedPoint: string;
  progressValue: number;
  profileSummery: string;
  profileDetail: string;
  languages: string[]
  drawerOpen:boolean;
  searchSkillTerm:string;
  clientProfileData:{data:{
    id: string,
    type: string,
  attributes: {
    id: number,
    email: string,
    country: string,
    user_name: null,
    first_name: string,
    job_title: string,
    phone_number: null,
    full_phone_number: null,
    total_experience: null,
    activated: boolean,
    degree_img: {
      url: null
    },
    aadhar_img: {
        url: null
    },
    passport_img: {
        url: null
    },
    profile_image: null,
    work_history: null,
    skills: any[],
    unique_auth_id: string,
    address: null,
    created_at: string,
    updated_at: string,
    status: string,
    stripe_account_id: null,
    stripe_account_onboard_url: null,
    stripe_is_charges_enabled: boolean,
    stripe_is_onboard_completed: boolean,
    stripe_is_details_submitted: boolean,
    work_experiences: any[]
}}}
  // Customizable Area End
}
interface SS { }

export default class SearchTalentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  talentProfileId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      id:0,
      loading:true,
      currentPage: props.currentPage || 1,
      totalPages: props.totalPages || 1,
      IpTalent: 'Ip Talents',
      IpJobs: 'Jobs',
      search: '',
      searchedList: ['Web Designer', 'App Developer'],
      experienceList: [
        {
          id: 1,
          value: 'entryLevel',
          label: "Entry Level",
          count: '$1487',
          min: 0,
          max: 2
        },
        {
          id: 2,
          value: 'interMediate',
          label: "intermediate",
          count: '$9383',
          min: 2,
          max: 5
        },
        {
          id: 3,
          value: 'expert',
          label: "Expert",
          count: '$12383',
          min: 5,
          max: 25
        }
      ],

      selectedOptions: [],
      proposalSelected: [],
      hoursSelected: [],
      minRange: '',
      maxRange: '',
      proposalList: [
        {
          id: 1,
          value: '0',
          minRange: 0,
          maxRange: 5,
          label: "Less Than 5",
        },
        {
          id: 2,
          value: '5',
          minRange: 5,
          maxRange: 10,
          label: "5 to 10",
        },
        {
          id: 3,
          value: '10',
          minRange: 10,
          maxRange: 15,
          label: "10 to 15",
        },
        {
          id: 4,
          value: '15',
          minRange: 15,
          maxRange: 20,
          label: "15 to 20",
        },
        {
          id: 5,
          value: '20',
          minRange: 20,
          maxRange: 30,
          label: "20 to 30",
        },
        {
          id: 6,
          value: '30',
          minRange: 30,
          maxRange: 50,
          label: "30 to 50",
        },
      ],
      hourlyRateList: [
        {
          id: 1,
          label: "Less Than 5",
          value: '5',
          maxRange: 5,
          minRange: 0,
        },
        {
          id: 2,
          label: "5 to 10",
          value: '10',
          maxRange: 10,
          minRange: 5,
        },
        {
          id: 3,
          label: "10 to 15",
          value: '15',
          maxRange: 15,
          minRange: 10,
        },
        {
          id: 4,
          label: "15 to 20",
          value: '20',
          maxRange: 20,
          minRange: 15,
        },
        {
          id: 5,
          label: "20 to 30",
          value: '30',
          maxRange: 30,
          minRange: 20,
        },
        {
          id: 6,
          label: "30 to 50",
          value: '50',
          maxRange: 50,
          minRange: 30,
        },
      ],
      minHours: '',
      maxHours: '',
      clearInfoList: [
        {
          id: 1,
          value: 'previousClients',
          label: "My Previous Clients",
          count: '0'
        },
        {
          id: 2,
          value: 'paymentsVerified',
          label: "Payments Verified",
          count: '300'
        }
      ],
      skillsList: [
        {
          id: 1,
          value: 'Skill 1',
          label: "Skill 1",
        },
        {
          id: 2,
          value: 'Skill 2',
          label: "Skill 2",
        },
        {
          id: 3,
          value: 'Skill 3',
          label: "Skill 3",
        }
      ],
      locationList: [
        {
          id: 1,
          value: 'India',
          label: "India",
        },
        {
          id: 2,
          value: 'Pakistan',
          label: "Pakistan",
        },
        {
          id: 3,
          value: 'Afganistan',
          label: "Afganistan",
        }
      ],
      authToken: '',
      searchedResult: [],
      totalFreelancer:[ {
        id: "0",
        type: "string",
        attributes: {
          freelancers: {
            full_name: "ramansh",
            user_name: "mudgal",
            job_title: "string",
            education: "string",
            aadhar_img: {
              url: "https://images.app.goo.gl/XjonpHxNGr2884Z78"
            },
            user_status: false,
            talent_saved: false,
            skills: ["python","fasjdf"],
            hour_rate: "string",
            description: "string",
            country: "string"
          }
        }
      }],
      showMore: false,
      userName: 'Rahul Sharma',
      perDayRate: '$100.00',
      earnedPoint: '$20k+',
      progressValue: 45,
      profileSummery: 'IP professionals offer standardized services which can solve IP users immediate needs e.g. NDA contract, illustration contract, patent search services.',
      profileDetail: 'Just browse the offers, select, buy, and use.',
      languages: ['PHP', 'Android', 'SQL', 'Java', 'Kotlin'],
      drawerOpen:false,
      searchSkillTerm:"",
      clientProfileData:{data:{
        id: "",
        type: "",
      attributes: {
        id: 971,
        email: "",
        country: "",
        user_name: null,
        first_name: "",
        job_title: "",
        phone_number: null,
        full_phone_number: null,
        total_experience: null,
        activated: false,
        degree_img: {
          url: null
        },
        aadhar_img: {
            url: null
        },
        passport_img: {
            url: null
        },
        profile_image: null,
        work_history: null,
        skills: [
        ],
        unique_auth_id: "",
        address: null,
        created_at: "",
        updated_at: "",
        status: "",
        stripe_account_id: null,
        stripe_account_onboard_url: null,
        stripe_is_charges_enabled: false,
        stripe_is_onboard_completed: false,
        stripe_is_details_submitted: false,
        work_experiences: []
    }
      }}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    setTimeout(() => {
      this.setState({ loading: false });
      }, 2000);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (requestCallId === this.talentProfileId) {
        if (response) {
          this.setState({clientProfileData:response});
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value })
  }
  handleCheckboxChange = (value: string) => {
    this.setState((prevState) => {
      if (prevState.selectedOptions.includes(value)) {
        return {
          selectedOptions: prevState.selectedOptions.filter((option) => option !== value),
        };
      } else {
        return {
          selectedOptions: [...prevState.selectedOptions, value],
        };
      }
    });


  };
  getTalentProfileDetails = async (id:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.talentProfileId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${id}/show_freelancer_profile`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleProposalCheckbox = (value: string) => {
    const { proposalSelected, proposalList } = this.state;
    if (proposalSelected.includes(value)) {
      const updatedSelectedOptions = proposalSelected.filter((option) => option !== value);
      let min = '';
      let max = '';
      if (updatedSelectedOptions.length > 0) {
        min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.minRange || 0)));
        max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      }
      this.setState({
        proposalSelected: updatedSelectedOptions,
        minRange: min,
        maxRange: max,
      });
    } else {
      const updatedSelectedOptions = [...proposalSelected, value];
      let min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.minRange || 0)));
      let max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => proposalList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      this.setState({
        proposalSelected: updatedSelectedOptions,
        minRange: min,
        maxRange: max,
      });
    }
  };

  handleClearProposalFilter = () => {
    this.setState({
      proposalSelected: [],
      minRange: '',
      maxRange: '',
    });
  }

  handleHourCheck = (value: string) => {
    const { hoursSelected, hourlyRateList } = this.state;
    if (hoursSelected.includes(value)) {
      const updatedSelectedOptions = hoursSelected.filter((option) => option !== value);
      let min = '';
      let max = '';
      if (updatedSelectedOptions.length > 0) {
        min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.minRange || 0)));
        max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      }
      this.setState({
        hoursSelected: updatedSelectedOptions,
        minHours: min,
        maxHours: max,
      });

    } else {
      const updatedSelectedOptions = [...hoursSelected, value];
      let min = String(Math.min(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.minRange || 0)));
      let max = String(Math.max(...updatedSelectedOptions.map((selectedValue) => hourlyRateList.find((item) => item.value === selectedValue)?.maxRange || 0)));
      this.setState({
        hoursSelected: updatedSelectedOptions,
        minHours: min,
        maxHours: max,
      });
    }
  };

  handleClearHoursFilter = () => {
    this.setState({
      hoursSelected: [],
      minHours: '',
      maxHours: '',
    });
  }

  handleClearAllFilter = () => {
    this.setState({
      hoursSelected: [],
      minHours: '',
      maxHours: '',
      proposalSelected: [],
      minRange: '',
      maxRange: '',
    })
  }
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page });
    this.props.handlePageChange(event, page);
  };
  
  handleShowMoreSkills = () => {
    this.setState({ showMore: !this.state.showMore })
  }
  toggleDrawer=(id?:any)=> {
    this.setState({ drawerOpen: !this.state.drawerOpen });
    this.getTalentProfileDetails(id)
  }

  // Customizable Area End
}
