import React from 'react';
// Customizable Area Start
import {
  Box,
  Fade,
  Grid,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  TextareaAutosize,
  ThemeProvider,
  Typography,
  FormControl,
  createTheme
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { uploadIcon6 } from '../../blocks/dashboard/src/assets';
import PatentHolderController, { Props } from './PatentHolderController.web';
import { FileUploadArea, autoCompleteSvgIcon, theme } from './PatentAgent.web';
import { Horizontalinventohub } from '../../blocks/landingpage/src/assets';
import ReactCodeInput from 'react-code-input';
import { login, successImage } from '../../blocks/email-account-registration/src/assets';
import { importantField } from './AppHeader.web';
// Customizable Area End
// Customizable Area Start

const props = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '70px',
    borderRadius: '10px',
    fontSize: '30px',
    height: '55px',
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}

const CustomBox = styled(Box)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius:"10px",
    border: "1px solid #979797"
  },
  '& .MuiInputBase-root':{
    width:"38%"
  }
})
export class StepLabelIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      '1': <Box>1</Box>,
      '2': <Box>2</Box>,
      '3': <Box>3</Box>,
      '4': <Box>4</Box>,
      '5': <Box>5</Box>,
      '6': <Box>6</Box>,
      '7': <Box>7</Box>,
      '8': <Box>8</Box>,
      '9': <Box>9</Box>
    };

    return (
      <div
        data-testid="stepper"
        style={{
          ...webStyle.stepLabelIcon,
          background: active || completed ? '#FFA42F' : '#FFE8CB',
          color: active || completed ? '#FFF' : '#FFA42F'
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}
const TargetAudience = [
  'Direct to consumer',
  'Companies below 5 million',
  'Companies between $5-10 million',
  'Companies between $10-100 million',
  'Companies more than $100 million',
  'Ngo',
  'Government',
  'All the above'
];
const Market = [{ label: 'City', value: 'city' }, { label: 'Country', value: 'country' }, { label: 'Worldwide', value: 'worldwide' }];
export const CustomisedRadio = () => <Radio style={{ color: '#FFA42F' }} />;
export const RadioButtonsYoN = () => {
  return ['Yes', 'No'].map((value: any) => (
    <FormControlLabel value={value} control={CustomisedRadio()} label={value} />
  ));
};
// Customizable Area End
export default class PatentHolder extends PatentHolderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  useStyles = makeStyles(theme => ({
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  }));
  popper = (content: string) => (
    <div style={{ display: 'inline-block' }}>
      <Popper
        open={this.state.openInfoPopper}
        anchorEl={this.state.anchorElement}
        placement={'bottom'}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: this.state.anchorElement
          }
        }}
        style={{
          width: '95%',
          borderRadius: '10px',
          background: '#FFF'
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography
                style={{
                  ...webStyle.header,
                  fontSize: '18px',
                  color: '#4C4C4C',
                  fontWeight: 400,
                  padding: '10px 20px',
                  letterSpacing: '0.27',
                  lineHeight: '35px'
                }}
              >
                {content}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );

  step1 = () => {
    const options = ['Option 1', 'Option 2', 'Option 3'];

    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          style={{ marginBottom: '500px' }}
        >
          <CustomBox display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            <Typography style={webStyle.header}>
              Which category does your patent belong to?{importantField()}
            </Typography>
            <Autocomplete
              key="Select Category"
              options={options}
              value={this.state.category}
              onChange={this.handleChangeAutocomplete}
              popupIcon={autoCompleteSvgIcon}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select Category"
                  variant="outlined"
                  style={{ borderRadius: '50px' }}
                />
              )}
            />
            {this.state.category != null && <Typography style={{ color: 'red' }}>{this.state.categoryError}</Typography>}
          </CustomBox>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            color="default"
            variant="contained"
            data-testid="PH-back1"
            onClick={() =>
              this.handleredirection('ProfessionalAccountRegistration')
            }
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext1}
            data-testid="PH-next1"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step2 = () => {
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'25px'}>
            <Typography style={webStyle.header}>
              Who is your target audience for your invention?{importantField()}
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="targetAudience"
              name="targetAudience"
              value={this.state.targetAudience}
              onChange={this.handleChange}
              data-testid="PH2-targetAudience"
            >
              {TargetAudience.map((item: any) => (
                <FormControlLabel
                  value={item}
                  control={<Radio style={{ color: '#FFA42F' }} />}
                  label={item}
                />
              ))}
            </RadioGroup>
            {this.state.targetAudienceError && <Typography style={{ color: 'red' }}>{this.state.targetAudienceError}</Typography>}
            <Typography style={webStyle.header}>
              What is your target market?{importantField()}
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="market"
              name="market"
              value={this.state.market}
              onChange={this.handleChange}
              data-testid="PLE2-market"
            >
              {Market.map((marketItem: any) => (
                <FormControlLabel
                  value={marketItem.value}
                  control={<Radio style={{ color: '#FFA42F' }} />}
                  label={marketItem.label}
                />
              ))}
            </RadioGroup>
            {this.state.marketError && <Typography style={{ color: 'red' }}>{this.state.marketError}</Typography>}

          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back2"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext2}
            data-testid="PH-next2"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step3 = () => {
    const currentStateData: any = this.state;
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            {[
              {
                label: 'Can you upload images or videos of the prototype?',
                value: 'imagesvideoPrototype',
                dataKey: 'imagesvideoPrototype_publicOrPrivate',
                state: 'imagesvideoPrototypeFile',
                title: 'Upload certificate',
                dataKeyError: 'imagesvideoError',
                valueError: 'imagesvideoPrototypeError'
              },
              {
                label: 'Can you share legal documents from a lawyer regarding the patent?',
                value: 'legalDocuments',
                dataKey: 'legalDocuments_publicOrPrivate',
                state: 'legalDocumentsFile',
                title: 'Upload certificate',
                dataKeyError: 'legalDocumentsError',
                valueError: 'legalDocumentsPrototypeError'
              }
            ].map((item, index) => {
              return (
                <Box key={index}>
                  <Typography style={webStyle.header}>{item.label}{importantField()}</Typography>
                  <RadioGroup
                    style={{
                      ...webStyle.RadioGroupBox,
                      gridAutoFlow: 'column',
                      width: '30%'
                    }}
                    aria-label={item.value}
                    name={item.value}
                    value={currentStateData[item.value]}
                    onChange={this.handleChange}
                    data-testid={`PH3-${item.value}`}
                  >
                    {RadioButtonsYoN()}
                  </RadioGroup>
                  {!currentStateData[item.value] && <Typography style={{ color: 'red' }}>{currentStateData[item.valueError]}</Typography>}
                  <Typography style={{...webStyle.header,marginBottom:"0px"}}>Upload files</Typography>
                  <FileUploadArea
                    id={`PatentHolder-step-3-fileInput${item.value}`}
                    btnContent={
                      (this.state as any)[item.state]
                        ? (this.state as any)[item.state]?.name
                        : item.title}
                    testId={`PatentHolder-step-3-fileInput${item.value}`}
                    onChange={this.handleFileUpload}
                    disabled={currentStateData[item.value] === 'No' || currentStateData[item.value] === ''}
                    stateKeyRef={item.state}
                    uploadIcon={uploadIcon6}
                    uploadTextColor={'#FFA42F'}
                    accept 
                  />
                  {currentStateData[item.value] === 'Yes' && index === 0 &&
                    <Typography style={{ color: 'red' }}>{this.state.error[item.state]}</Typography>}

                  {currentStateData[item.value] === 'Yes' && index === 1 &&
                    <Typography style={{ color: 'red' }}>{this.state.error[item.state]}</Typography>}
                  <Typography style={webStyle.header}>
                    Do you want this to be public or private?{importantField()}
                  </Typography>
                  <RadioGroup
                    style={{
                      ...webStyle.RadioGroupBox,
                      gridAutoFlow: 'column',
                      width: '30%'
                    }}
                    aria-label={item.dataKey}
                    name={item.dataKey}
                    value={currentStateData[item.dataKey]}
                    onChange={this.handleChange}
                    data-testid={`PH3-${item.dataKey}`}
                  >
                    <FormControlLabel
                      value="Public"
                      control={<Radio style={{ color: '#FFA42F' }} />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="Private"
                      control={<Radio style={{ color: '#FFA42F' }} />}
                      label="Private"
                    />
                  </RadioGroup>
                  {!currentStateData[item.dataKey] && index == 0 && <Typography style={{ color: 'red' }}>{this.state.imagesvideoError}</Typography>}
                  {!currentStateData[item.dataKey] && index == 1 && <Typography style={{ color: 'red' }}>{this.state.legalDocumentsError}</Typography>}
                </Box>
              );
            })}

          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            data-testid="PH-back3"
            variant="contained"
            color="default"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext3}
            data-testid="PH-next3"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step4 = () => {

    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            <Typography style={webStyle.header}>
              Has your patent won any awards?{importantField()}
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridAutoFlow: 'column',
                width: '30%'
              }}
              aria-label="awards"
              name="awards"
              value={this.state.awards}
              onChange={this.handleChange}
              data-testid="PLE5-awards"
            >
              {RadioButtonsYoN()}
            </RadioGroup>
            {this.state.awardsRadioError && <Typography style={{ color: 'red' }}>{this.state.awardsRadioError}</Typography>}

            {[
              { label: 'Government', value: 'government', state: 'governmentFile', title: 'Upload certificate' },
              { label: 'Industry', value: 'industry', state: 'industryFile', title: 'Upload certificate' },
              { label: 'Academic', value: 'academic', state: 'academicFile', title: 'Upload certificate' }
            ].map((item: any, index: any) => {
              const currentState: any = this.state;
              return (
                <>
                  <Box key={index}>
                    <Typography style={webStyle.header}>{item.label}</Typography>
                    <TextField
                      data-testid={`PH4-${item.label}`}
                      placeholder="Enter link"
                      inputProps={{
                        style: {
                          ...webStyle.outlinedInput
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '13px',
                          width: '400px'
                        }
                      }}
                      variant="outlined"
                      value={currentState[item.value]}
                      disabled={this.state.awards == 'No' || this.state.awards == ''}
                      name={item.value}
                      onChange={this.handleChange}
                      style={webStyle.inputField}
                    />
                    {this.state.awards === 'Yes' && <Typography style={{ color: 'red' }}>{this.state.awardsLinkError}</Typography>}

                    <FileUploadArea
                      id={`fileInput-${item.label}`}
                      uploadTextColor={'#FFA42F'}
                      testId={`PatentHolder-fileInput-${item.label}`}
                      btnContent={
                        (this.state as any)[item.state]
                          ? (this.state as any)[item.state]?.name
                          : item.title}
                      onChange={this.handleFileUpload}
                      disabled={this.state.awards == 'No' || this.state.awards == ''}
                      stateKeyRef={item.state}
                      uploadIcon={uploadIcon6}
                    />
                    {this.state.awards === 'Yes' && <Typography style={{ color: 'red' }}>{this.state.error[item.state]}</Typography>}
                  </Box>
                </>
              );
            })}
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back4"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext4}
            data-testid="PH-next4"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step5 = () => {
    const currentStateData: any = this.state;

    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.4} marginTop={'45px'}>
            <Typography style={webStyle.header}>
              Have you received any funding for your patent?{importantField()}
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridAutoFlow: 'column',
                width: '30%'
              }}
              aria-label="funding"
              name="funding"
              value={this.state.funding}
              onChange={this.handleChange}
              data-testid="PLE5-funding"
            >
              {RadioButtonsYoN()}
            </RadioGroup>
            {this.state.fundingError && <Typography style={{ color: 'red' }}>{this.state.fundingError}</Typography>}
            <Typography style={{...webStyle.header, marginBottom:"0px"}}>Share documents</Typography>
            <FileUploadArea
              id={'fileInputfunding'}
              btnContent={
                currentStateData['document']
                  ? currentStateData['document']?.name
                  : 'Upload certificate'}
              testId={'PatentHolder-fileInputfunding'}
              onChange={this.handleFileUpload}
              disabled={this.state.funding == 'No' || this.state.funding == ''}
              stateKeyRef={"document"}
              uploadIcon={uploadIcon6}
              uploadTextColor={'#FFA42F'}
            />
            {this.state.funding === 'Yes' && <Typography style={{ color: 'red' }}>{this.state.error["document"]}</Typography>}
            <Typography style={webStyle.header}>Enter amount</Typography>
            <TextField
              data-testid="PLE5-Industry"
              placeholder="Enter amount"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              InputProps={{
                style: {
                  borderRadius: '13px',
                  width: '60%'
                }
              }}
              variant="outlined"
              value={this.state.Amount}
              disabled={this.state.funding == 'No' || this.state.funding == ''}
              name="Amount"
              style={webStyle.inputField}
              onChange={this.handleExpFieldChange}
              error={this.state.error['Amount']}
              helperText={this.state.error['Amount']}
            />

            <Typography style={webStyle.header}>
              Do you want this to be public and private?{importantField()}
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridAutoFlow: 'column',
                width: '30%'
              }}
              aria-label="publicOrPrivate"
              name="publicOrPrivate"
              value={this.state.publicOrPrivate}
              onChange={this.handleChange}
              data-testid="PLE2-publicOrPrivate"
            >
             <FormControlLabel
                      value="Public"
                      control={<Radio style={{ color: '#FFA42F' }} />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="Private"
                      control={<Radio style={{ color: '#FFA42F' }} />}
                      label="Private"
                    />
            </RadioGroup>
            {this.state.publicStep5Error && <Typography style={{ color: 'red' }}>{this.state.publicStep5Error}</Typography>}
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back5"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext5}
            data-testid="PH-next5"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step6 = () => {
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'} marginBottom= {'300px'}> 
            {[
              {
                title: 'What price will you sell this invention for?',
                value: 'price',
                top:"20px"
              },
              {
                title: 'What fee will you charge for this invention?',
                value: 'fees',
                top:"50px"
              }
            ].map((item: any, index: number) => {
              const stateData: any = this.state;
              return (
                <>
                  <Typography style={{ ...webStyle.header, marginTop: item.top }}>{item.title}{importantField()}</Typography>
                  <TextField
                    data-testid={`PH6-${item.value}`}
                    placeholder="Enter amount"
                    inputProps={{
                      style: {
                        ...webStyle.outlinedInput
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '13px',
                        width: '40%'
                      }
                    }}
                    variant="outlined"
                    value={stateData[item.value]}
                    name={item.value}
                    style={webStyle.inputField}
                    onChange={this.handleExpFieldChange}
                    error={this.state.error[item.value]}
                    helperText={this.state.error[item.value]}
                  />
                </>
              );
            })}
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back6"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext6}
            data-testid="PH-next6"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step7 = () => {
    const currentStateData: any = this.state;

    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography style={webStyle.header}>
                Share your bank details 
              </Typography>
              <Typography style={{ ...webStyle.header, fontSize: '16px',marginLeft:"2px" }}>
              (It will take 24 hours to verify){importantField()}
              </Typography>
            </Box>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridAutoFlow: 'column',
                width: '30%'
              }}
              aria-label="bankDetails"
              name="bankDetails"
              value={this.state.bankDetails}
              onChange={this.handleChange}
              data-testid="PLE2-bankDetails"
            >
              <FormControlLabel
                value="Now"
                control={<Radio style={{ color: '#FFA42F' }} />}
                label="Now"
              />
              <FormControlLabel
                value="Later"
                control={<Radio style={{ color: '#FFA42F' }} />}
                label="Later"
              />
            </RadioGroup>
            {this.state.bankDetailsError && <Typography style={{ color: 'red' }}>{this.state.bankDetailsError}</Typography>}
            <Typography style={{...webStyle.header, marginTop:"62px"}}>Account Number</Typography>
            <TextField
              data-testid="PLE5-Account Number"
              placeholder="Enter amount"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              InputProps={{
                style: {
                  borderRadius: '13px',
                  width: '50%'
                }
              }}
              variant="outlined"
              value={this.state.accountNumber}
              name="accountNumber"
              disabled={this.state.bankDetails == 'Later'}
              style={webStyle.inputField}
              onChange={this.handleAccountNumberChange}
              error={this.state.error['accountNumber']}
              helperText={this.state.error['accountNumber']}
            />
            <Typography style={{...webStyle.header, marginBottom:"0px",marginTop:"45px"}}>Share documents</Typography>
            <FileUploadArea
              id={'fileInputaccountNumber'}
              btnContent={
                currentStateData['accountDoc']
                  ? currentStateData['accountDoc']?.name
                  : 'Upload certificate'}
              testId={'PatentHolder-fileInputaccountNumber'}
              onChange={this.handleFileUpload}
              disabled={this.state.bankDetails == 'Later' || this.state.bankDetails == ''}
              stateKeyRef={"accountDoc"}
              uploadIcon={uploadIcon6}
              uploadTextColor={'#FFA42F'}
            />
            {this.state.bankDetails === 'Now' && <Typography style={{ color: 'red' }}>{this.state.error["accountDoc"]}</Typography>}
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back7"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext7}
            data-testid="PH-next7"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step8 = () => {
    const qualifications = ['BSc', 'MBA', 'CPA', 'PMP', 'MD', 'BA', 'CSM', 'GD', 'MA', 'GA', 'BE', 'LLB', 'CA', 'ICWA', 'BCom', 'BEd', 'MCom', 'MSc', 'MCA', 'PhD'];
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <CustomBox display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            <Typography style={webStyle.header}>
              What are your qualifications?{importantField()}
            </Typography>
            <Autocomplete
              key="Select qualification"
              popupIcon={autoCompleteSvgIcon}
              value={this.state.qualification}
              onChange={this.handleChangeQualification}
              options={qualifications}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select qualification"
                  variant="outlined"
                />
              )}
            />
            {this.state.qualification != null && <Typography style={{ color: 'red' }}>{this.state.qualificationError}</Typography>}
            <Typography style={{...webStyle.header, marginTop:"50px"}}>
              Do you have any research papers to your credit?{importantField()}
            </Typography>
            <RadioGroup
              style={{
                ...webStyle.RadioGroupBox,
                gridAutoFlow: 'column',
                width: '30%'
              }}
              aria-label="researchPapers"
              name="researchPapers"
              value={this.state.researchPapers}
              onChange={this.handleChange}
              data-testid="PLE2-researchPapers"
            >
              {RadioButtonsYoN()}
            </RadioGroup>
            {this.state.researchPapersError && <Typography style={{ color: 'red' }}>{this.state.researchPapersError}</Typography>}

            <Typography style={{...webStyle.header, marginTop:"50px"}}>Attach Link</Typography>
            <TextField
              data-testid="PLE5-attachLink"
              placeholder="Enter Link"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                }
              }}
              InputProps={{
                style: {
                  borderRadius: '13px',
                  width: '38%'
                }
              }}
              variant="outlined"
              value={this.state.attachLink}
              name="attachLink"
              style={webStyle.inputField}
              disabled={this.state.researchPapers === 'No' || this.state.researchPapers === ''}
              onChange={this.handleLinkChange}
              error={this.state.error['attachLink']}
              helperText={this.state.error['attachLink']}
            />
          </CustomBox>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back8"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            onClick={this.handleNext8}
            data-testid="PH-next8"
          >
            Next
          </Button>
        </Box>
      </>
    );
  };
  step9 = () => {
    return (
      <>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} flex={0.6} marginTop={'45px'}>
            <Typography style={webStyle.header}>
              Who does this patent belong to?{importantField()}
            </Typography>
            <RadioGroup
              style={webStyle.RadioGroupBox}
              aria-label="patent"
              name="patent"
              value={this.state.patent}
              onChange={this.handleChange}
              data-testid="PLE2-patent"
            >
              {[{ label: 'University', value: 'university' }, { label: 'Individual', value: 'individual' }, { label: 'Company', value: 'company' },].map((value: any) => (
                <FormControlLabel
                  value={value.value}
                  control={<Radio style={{ color: '#FFA42F' }} />}
                  label={value.label}
                />
              ))}
            </RadioGroup>
            {this.state.patentError && <Typography style={{ color: 'red' }}>{this.state.patentError}</Typography>}
            <Typography style={{...webStyle.header, marginTop:"40px"}}>
              Name of the individual, company or university{importantField()}
            </Typography>
            <TextField
              data-testid="PLE5-companyOruniversity"
              placeholder="Enter name"
              inputProps={{
                style: {
                  ...webStyle.outlinedInput
                },
                maxLength: 200
              }}
              InputProps={{
                style: {
                  borderRadius: '13px',
                  width: '40%'
                },
              }}
              
              variant="outlined"
              value={this.state.companyOruniversity}
              name="companyOruniversity"
              style={webStyle.inputField}
              onChange={this.handleChange2}
              error={this.state.error['companyOruniversity']}
              helperText={this.state.error['companyOruniversity']}
            />
            <Typography style={{...webStyle.header, marginTop:"50px"}}>
              Any other information?{importantField()}
            </Typography>
            <TextField
              multiline
              minRows={5}
              maxRows={5}
              data-testid="PLE5-companyOruniversity"
              placeholder="Add any other information you wish to convey here"
              variant="outlined"
              value={this.state.otherInfo}
              name="otherInfo"
              inputProps={{  maxLength: 1000 }}
              style={{
                borderColor: '#979797',
                borderRadius: '10px',
                fontFamily: 'Inter',
                paddingTop: '10px',
                maxHeight: '130px',
                width: '550px',
              }}
              onChange={this.handleChange2}
              error={this.state.error['otherInfo']}
              helperText={this.state.error['otherInfo']}
            />
          </Box>
        </Box>
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          marginTop={'60px'}
        >
          <Button
            variant="contained"
            color="default"
            data-testid="PH-back9"
            onClick={this.handleBack}
            style={webStyle.backBtn}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={webStyle.nextButton}
            disabled={this.state.disableBtn}
            onClick={this.handleNext9}
            data-testid="PH-next9"
          >
            Finish
          </Button>
        </Box>
      </>
    );
  };

  getStepComponent = () => {
    switch (this.state.activeStep) {
      case 0:
        return <Box>{this.step1()}</Box>;
      case 1:
        return <Box>{this.step2()}</Box>;
      case 2:
        return <Box>{this.step3()}</Box>;
      case 3:
        return <Box>{this.step4()}</Box>;
      case 4:
        return <Box>{this.step5()}</Box>;
      case 5:
        return <Box>{this.step6()}</Box>;
      case 6:
        return <Box>{this.step7()}</Box>;
      case 7:
        return <Box>{this.step8()}</Box>;
      case 8:
        return <Box>{this.step9()}</Box>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        {!this.state.verfication && !this.state.successPage &&
          <Box padding={'3%'}>
            <Box style={webStyle.outerContainer}>
              <img src={Horizontalinventohub} style={webStyle.invento as React.CSSProperties} onClick={this.handleLogoClick}  />

              <Box style={webStyle.root}>
                {/* stepper component starts */}
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                  {steps.map(label => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel StepIconComponent={StepLabelIcon}></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </Box>
            {this.getStepComponent()}
          </Box>
        }

        {this.state.verfication === true && !this.state.successPage &&
          <>
            <Grid container spacing={0}>
              <Grid container item xs={12} md={5} justifyContent="center">
                <Grid item xs={9}>
                  <img src={Horizontalinventohub} style={webStyle.invento} />
                  <div style={webStyle.verifyStyle}>
                    <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
                    <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
                    <ReactCodeInput
                      {...props}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue}
                      name={"VerifyOtp"} inputMode={"tel"} />
                    <Box component={"div"} style={{ marginTop: '16px' }}>
                      <FormControl style={{ width: '98%', display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          disabled={this.state.VerificationError}
                          onClick={this.handleVerifybox}
                        >
                          VERIFY
                        </Button>
                        <Button
                          style={webStyle.nxtBtn}
                          variant="contained"
                          color="primary"
                          onClick={this.handleResendOtp}
                          disabled={this.state.disabledResendOtp}
                          data-testid="resend_emailotp"
                        >
                          Resend OTP

                        </Button>
                      </FormControl>
                    </Box>
                    {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
                    {this.state.VerificationError && <Typography style={webStyle.timer}> Please Enter OTP</Typography>}
                    <Box component={"div"} margin={"30px 0"}>
                      <span style={webStyle.haveAccount}>Have an account? </span>
                      <span style={webStyle.signIn} onClick={this.handleSignIn}>Sign in</span>
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <img src={login} style={webStyle.image} alt="peopleImg" />
              </Grid>
            </Grid>
          </>
        }{this.state.successPage === true && <>
          <Grid container spacing={0} style={{ display: "block" }}>
            <Grid container item xs={12} md={5} justifyContent="center">
              <Grid item xs={9}>
                <img src={Horizontalinventohub} style={webStyle.invento} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={webStyle.successImage}>
              <img src={successImage} alt="successImage" />
            </Grid>
            <Grid item>
              <Typography style={webStyle.suceesText}>Congratulations! {this.state.profileName} your profile has been created</Typography>
            </Grid>
            <Grid container style={webStyle.backNextBtn}>
              <Grid item>
                <Button
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" style={{ padding: "10px 40px", fontSize: " 18px" }} onClick={this.handleSignIn}>
                  NEXT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>}

      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '50%',
    flex:"0.7"
  },

  otpTime: {
    marginBottom: "20px",
    color: "#6F6E6E",
    fontWeight: 600,
    fontSize: " 18px",
  },
  backNextBtn: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
    marginTop: "84px",
    "@media (min-width: 425px) and (max-width:759px)": {
      marginTop: "20px",
      position: "unset"
    }
  },
  haveAccount: {
    color: "#6F6E6E",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px"
  },

  timer: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "red",
    marginTop: "10px"
  },
  suceesText: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "40px",
    textAlign: "center" as "center"
  },
  successImage: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  signIn: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "40px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  nxtBtn: {
    padding: "12px", borderRadius: "8px"
  },
  otpStyle: {
    margin: "76px 0px",
    fontSize: "32px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "'Inter', sans-serif"
  },
  verifyStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginTop: '200px',
    "@media (max-width: 768px)": {
      marginTop: 0
    }
  },
  backBtn: {
    border: '1px solid #FFA42F',
    color: '#FFA42F',
    width: '130px',
    height: '40px',
    flexShrink: 0,
    backgroundColor: 'white',
    borderRadius: '13px'
  },
  image: {
    width: "100%",
    height: "auto",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:"10rem"
  },
  invento: {
    width: "300px",
    height: "61px",
    objectFit: "cover" as const,
  },
  inputField: {
    maxHeight: '56px',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  header: {
    color: '#383838',
    fontFamily: 'Inter',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '30px',
    marginBottom:"20px",
    display:"flex"
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px',
    width: '100%', 
  },

  UploadButton: {
    height: '165px',
    width: '751px',
    padding: '10px',
    display: 'block',
    alignItems: 'center',
    backgroundColor: '#F1F6FF',
    border: '1px dashed #808080 !important',
    borderRadius: '12px'
  },

  stepLabelIcon: {
    backgroundColor: '#FFF',
    zIndex: 1,
    color: '#B4CDEA',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #B4CDEA'
  },
  nextButton: {
    width: '130px',
    height: '40px',
    flexShrink: 0,
    borderRadius: '13px',
    color: '#FFF',
    backgroundColor: '#FFA42F'
  }
};
// Customizable Area End
