export const faq1 = require("../assets/faq1.svg");
export const contactUs = require("../assets/contactUs.svg");
export const findWorkImg1 = require("../assets/findWorkImg1.svg");
export const findWorkImg2 = require("../assets/findWorkImg2.svg");
export const findWorkImg3 = require("../assets/findWorkImg3.svg");
export const findWorkImg4 = require("../assets/findWorkImg4.svg");
export const findWorkImg5 = require("../assets/findWorkImg5.svg");
export const HireImg1 = require("../assets/HireImg1.svg");
export const HireImg2 = require("../assets/HireImg2.svg");
export const HireImg3 = require("../assets/HireImg3.svg");
export const checkBoxIcon = require("../assets/checkBoxIcon.svg");


