import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "framework/src/StorageProvider";
import { setStorageData } from "../../../framework/src/Utilities";

export interface DraftListData 
{
            "id": string,
            "type": string,
            "attributes": {
              "id": string,
              "name": string,
              "description": string,
              "job_title": string,
              "body": string,
              "job_description": string,
              "budget": string,
              "duration": string,
              "per_day_charge": number,
              "estimate": string,
              "location": string,
              "skills": any[],
              "employment_term": string,
              "file": {
                  "url": string | null
              },
              "images_and_videos": any[]
              "is_drafted": boolean,
              "account_id": number,
              "hired": number,
              "proposals": number,
              "created_at": string,
              "updated_at":string,
                "messaged": number,
                "model_name": string,
                "drafted_at":string
            },
            "total_count":number
      
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  searchDrafts: string;
  draftListData: DraftListData [],
  authToken: string,
  deletePopUp: boolean;
  deleteMessage: string;
  activeDraftId: string;
  snackbarOpen: boolean,
  snackbarMessage: string;
  loading: boolean; 
  currentPage:number;
  totalPages:number;
  noDraftsFound:boolean
  // Customizable Area End
}
interface SS { }

export default class JobPostingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDeleteDraftedDataId:string = "";
  apiSearchDraftsId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      authToken: "",
      searchDrafts: '',
      anchorEl: null,
      draftListData: [],
      deletePopUp: false,
      deleteMessage: '',
      activeDraftId: '',
      snackbarOpen: false,
      snackbarMessage: '',
      loading: true,
      currentPage:1,
      totalPages:0,
      noDraftsFound:false
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.handleSearchDrafts()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson) {
         if (this.apiSearchDraftsId === webApiRequestCallId) {
          const searchResults = webResponseJson.drafts?.data|| []; 
          this.setState({ draftListData: searchResults,
             loading: false,
            totalPages: Math.ceil(webResponseJson.total_count / 10), 
            noDraftsFound: searchResults.length === 0,
             });
        }
        if (this.apiDeleteDraftedDataId === webApiRequestCallId) {
          this.setState((prevState) => ({
            draftListData: prevState.draftListData.filter(draft => draft.id !== this.state.activeDraftId),
            activeDraftId: '',
            deletePopUp: false,
            loading: false
          }), () => {
            this.showAlert("Draft deleted successfully");
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage){
      this.handleSearchDrafts();
    }
  }
  handlePageChange = (_event: object, page: number) => {
    this.setState({currentPage: page});  
  };
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleDeleteDraft = (draftId: string) => {
    this.setState({ activeDraftId: draftId, deletePopUp: true });
    this.handleClose()
  };

  confirmDeleteDraft = () => {
    this.deleteDraftedData(this.state.activeDraftId);
  };

  cancelDeleteDraft = () => {
    this.setState({ deletePopUp: false, activeDraftId: '' });
  };

  showAlert = (message: string) => {
    this.setState({ snackbarOpen: true, snackbarMessage: message });
  };

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  };
  handleDraftJobDescription = () => {
    const viewProposal: Message = new Message(getName(MessageEnum.NavigationMessage))
    viewProposal.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobPostDescription'
    );
    viewProposal.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(viewProposal);
  }

  handleEditJob = (id: any) => {
    const viewProposal: Message = new Message(getName(MessageEnum.NavigationMessage))
    viewProposal.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobReviewPage'
    );
    viewProposal.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(viewProposal);
    setStorageData("jobId", id)
    this.handleClose()
    
  }
  handleClick = (event: React.MouseEvent<HTMLElement>, draftId: string) => {
    this.setState({ anchorEl: event.currentTarget, activeDraftId: draftId });
};

handleSearchDraftsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const searchTerm = event.target.value;
  this.setState({ searchDrafts: searchTerm }, () => {
    this.handleSearchDrafts();
  });
};


handleClickSearchDrafts = () => {
  this.handleSearchDrafts(); 
};

handleSearchDrafts = () => {
  const searchTerm = this.state.searchDrafts; 
  const page = this.state.currentPage; 

  const header = {
    "Content-Type": webConfigJSON.dashboarContentType,
    "token": this.state.authToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiSearchDraftsId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_posts/posts/search_draft_jobs?name=${searchTerm}&page=${page}&per_page=10`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
  deleteDraftedData = async (draftId: string) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteDraftedDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${draftId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}
