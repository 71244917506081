import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});
const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
       fontWeight:"600",
       fontSize:"22px",
       textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
      fontWeight:"600",
      fontSize:"22px",
      textTransform: "inherit"
   },
   '& .PrivateTabIndicator-root-31' :{
    height:"4px"
   }
    
  })
  import FullWidthJobsTabController, {
    Props,
    webConfigJSON,
  } from "./FullWidthJobsTabController";
  import MyJobs from "./MyJobs.web";
  import Drafts from "./Drafts.web";
// Customizable Area End

export default class FullWidthJobsTab extends FullWidthJobsTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
          <ClientNavBar />
          <Grid container style={webStyles.mainConatiner}>
            <Grid item style={{width:"100%"}}>
              <Box style={webStyles.jobBtnBox}>
                <Typography style={webStyles.jobText}>
                  {webConfigJSON.jobs}
                  <span style={webStyles.myJobText}>
                    /{webConfigJSON.myJobs}
                  </span>
                </Typography>
              </Box>
            <Box style={{ padding: "2%" }}>
              <ThemeProvider theme={theme}>
                <CustomTabs
                  value={this.state.searchSelect}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  style={{borderBottom:"1px solid #959595"}}
                >
                  <Tab label="Posted Jobs" />
                  <Tab label="Drafts" />
                </CustomTabs>
              </ThemeProvider>
              {this.state.searchSelect === 0 && (
                <MyJobs
                  navigation={this.props.navigation}
                />
              )}
              {this.state.searchSelect === 1 && (
                <Drafts
                  navigation={this.props.navigation}
                />
              )}
            </Box>
         
            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  mainConatiner: {
    padding: "30px",
  },
  jobBtnBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
  },
  jobText: {
    fontSize: "20px",
    fontFamily:"Inter"
  },
  myJobText: {
    color: "#206FC4",
    fontFamily:"Inter"
  },
  postBtn: {
    borderRadius: "7px",
    padding: "1% 2%",
    fontFamily:"Inter"
  },
  borderBox: {
    background: "#F5F7F9",
    padding: "15px",
    borderRadius: "5px",
    marginTop: "3%",
    overflow: "auto",
  },
};
// Customizable Area End
