import React from "react";
// Customizable Area Start
import { Box, Button, Grid, InputAdornment, TextField, Typography, Checkbox, MenuItem, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { green } from '@material-ui/core/colors';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { avtara, online, checkIcon } from './assets';
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";


const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import HireController, {
    Props,
    webConfigJSON
} from "./HireController";

export default class Hire extends HireController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderResponseDialog = () => {
        return (
            <Dialog
                open={this.state.popUpMessage}
                onClose={this.handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={webStyles.dialogOuterContainer}
                data-testid="response-modal"
            >
                <DialogContent>
                    {this.state.responseMessage ?
                        <DialogContentText id="alert-dialog-description" style={webStyles.successMessage}>
                            {webConfigJSON.successMessage}
                        </DialogContentText> :
                        <DialogContentText id="alert-dialog-description2" style={webStyles.failedMessage}>
                            {webConfigJSON.failedMessage}
                        </DialogContentText>
                    }
                    <DialogActions style={webStyles.btnOuterContainer}>
                        <Button onClick={this.handleDialog} data-testid="ok-btn" color="primary" variant="contained">
                            {webConfigJSON.okBtnText}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const accountDetails = this.state.freelancerDetails.account_attributes
        const postAttributes = this.state.freelancerDetails.post_attributes
        return (
            //Merge Engine DefaultContainer
            <>
                <ThemeProvider theme={theme}>
                    <ClientNavBar />
                    <Box style={webStyles.boxConatiner}>
                        <Typography style={webStyles.dashboardText}>{webConfigJSON.yourDashboard}</Typography>
                        <Typography style={webStyles.userNameText}>{this.state.acountHolderName}</Typography>
                        <Grid container style={webStyles.outerContainer}>
                            <Grid item xs={12} sm={7} md={7}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} style={webStyles.borderBox}>
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Box>
                                                            <Typography style={webStyles.skillText}>{webConfigJSON.skills}</Typography>
                                                            <Box style={webStyles.skillBox}>
                                                                {postAttributes.skills.map((skill, index) =>
                                                                    <Typography style={webStyles.skill} key={index}>{skill}</Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Box>
                                                            <Box style={webStyles.proposalBox}>
                                                                <Typography style={webConfigJSON.proposalText}>{webConfigJSON.proposal}</Typography>
                                                                <Box>
                                                                    <Typography style={webStyles.price}>${this.state.freelancerDetails.hour_rate}/day</Typography>
                                                                    <Typography style={webStyles.proposed} color="secondary">{webConfigJSON.proposed}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Box>
                                                            <Typography color="secondary" style={webStyles.greeting}>{accountDetails.description}</Typography>
                                                        </Box>

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={{ ...webStyles.borderBox, marginTop: '3%' }}>
                                            <Box>
                                                <Typography style={webStyles.contract}>{webConfigJSON.contract}</Typography>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12} style={webStyles.payBox}>
                                                        <Box>
                                                            <img src={checkIcon} alt="checkIcon" style={webStyles.checkIcon} />
                                                            <Typography style={webStyles.payBy}>{webConfigJSON.payBy}</Typography>
                                                            <Typography style={webStyles.popular}>{webConfigJSON.popular}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <form style={webStyles.formInput}>
                                                            <Box>
                                                                <Typography style={webStyles.lable}>{webConfigJSON.numberMile}<span style={webStyles.required}>*</span></Typography>
                                                                <TextField
                                                                    type="number"
                                                                    variant="outlined"
                                                                    id="mile"
                                                                    placeholder="Number of Milestone"
                                                                    name="numberOfMileStone"
                                                                    size="small"
                                                                    data-testid="noMilestone"
                                                                    value={this.state.numberOfMileStone}
                                                                    onChange={this.handleNumberMilestone}
                                                                    error={this.state.error && !this.state.numberOfMileStone}
                                                                    helperText={(this.state.error && !this.state.numberOfMileStone) && 'Required'}
                                                                />
                                                                <Typography color="error" style={webStyles.limitation}>{webConfigJSON.mileStoneValidate}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography style={webStyles.lable}>{webConfigJSON.startDate}</Typography>
                                                                <TextField variant="outlined" id="date" size="small" type="date" name="startDate" data-testid="startDate" value={this.state.startDate} onChange={this.handleStartDate} />
                                                            </Box>

                                                            {this.state.inputList.map((count, index) => {
                                                                return (
                                                                    <Grid container spacing={2} style={webStyles.inputContainer} key={index}>
                                                                        <Grid item xs={12} sm={12} md={4}>
                                                                            <Typography style={webStyles.lable}>{webConfigJSON.mileDescription}<span style={webStyles.required}>*</span></Typography>
                                                                            <Box style={webStyles.countBox}>
                                                                                <Typography color="secondary">{index + 1}</Typography>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    id="description"
                                                                                    size="small"
                                                                                    placeholder="description"
                                                                                    name="description"
                                                                                    data-testid={"description-" + index}
                                                                                    value={count.description}
                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, index)}
                                                                                    error={this.state.error && !count.description}
                                                                                    helperText={(this.state.error && !count.description) && 'Required'}
                                                                                />

                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={3}>
                                                                            <Box>
                                                                                <Typography style={webStyles.lable}>{webConfigJSON.amount}<span style={webStyles.required}>*</span></Typography>
                                                                                <TextField variant="outlined" id="amount" data-testid={"amount-" + index} size="small" name="amount" placeholder="amount" value={count.amount} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, index)} error={this.state.error && !count.amount} helperText={(this.state.error && !count.amount) && 'Required'} InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            $
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }} />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={3}>
                                                                            <Box>
                                                                                <Typography style={webStyles.lable}>{webConfigJSON.date}<span style={webStyles.required}>*</span></Typography>
                                                                                <TextField variant="outlined" id="date" data-testid={"date-" + index} type="date" size="small" name="date" value={count.date} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, index)} error={this.state.error && !count.date} helperText={(this.state.error && !count.date) && 'Required'} />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={2}>
                                                                            <Box>
                                                                                <Typography style={webStyles.lable}>{webConfigJSON.status}<span style={webStyles.required}>*</span></Typography>
                                                                                <TextField 
                                                                                    select 
                                                                                    variant="outlined" 
                                                                                    placeholder="status" 
                                                                                    value={count.status} 
                                                                                    id="status" 
                                                                                    data-testid={"status-" + index} 
                                                                                    name="status" 
                                                                                    size="small" 
                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, index)} error={this.state.error && !count.status} 
                                                                                    helperText={(this.state.error && !count.status) && 'Required'} 
                                                                                >
                                                                                    {this.state.statusOption.map((data,index) => 
                                                                                         <MenuItem key={index} value={data.value}>
                                                                                            {data.option}
                                                                                         </MenuItem>
                                                                                    )}
                                                                                  
                                                                                </TextField>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })}

                                                            <Button startIcon={<AddSharpIcon fontSize="small" />} variant="outlined" style={webStyles.addButton} size="large" onClick={this.handleAdd}>
                                                                {webConfigJSON.addMileStone}
                                                            </Button>
                                                        </form>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box style={webStyles.agreeBox}>
                                                <Checkbox
                                                    checked={this.state.checked}
                                                    onChange={this.handleChecked}
                                                    style={webStyles.checkInput}
                                                    color="primary"
                                                    data-testid="checked"
                                                />
                                                <Typography style={webStyles.agreeText} color="secondary">{webConfigJSON.agreeTerm}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box style={webStyles.buttonBox}>
                                                <Button variant="outlined" color="primary" style={webStyles.cancelBtn}>{webConfigJSON.cancle}</Button>
                                                <Button variant="contained" color="primary" style={webStyles.nextBtn} data-testid="nextBtn" onClick={this.handleNext} disabled={!this.state.checked}>{webConfigJSON.next}</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} style={{ ...webStyles.borderBox, height: 'max-content' }}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box style={webStyles.profileDisplay}>
                                                <Box style={webStyles.profileStatus}>
                                                    <img src={avtara} alt="profile" />
                                                    {accountDetails.freelancer_status ?
                                                     <img src={online} alt="online" style={webStyles.status} />
                                                        :
                                                        <Typography style={webStyles.offlineText}>{webConfigJSON.offline}</Typography>
                                                    }
                                                </Box>
                                                <Box>
                                                    <Typography style={webStyles.nameStyle}>{accountDetails.user_name}</Typography>
                                                    <Typography style={webStyles.website}>{accountDetails.designation}</Typography>
                                                    <Box style={webStyles.locationBox}>
                                                        <LocationOnIcon style={webStyles.locationIcon} />
                                                        <Typography style={webStyles.website}>{accountDetails.location}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={webStyles.messageContainer}>
                                            <Button variant="contained" color="primary" size="large" style={webStyles.buttonSize} onClick={this.handleHireBtn} data-testid="handleHireBtn">{webConfigJSON.messageMe}</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={webStyles.savedContainer}>
                                            <Button variant="outlined" color="primary" size="large" style={webStyles.buttonSize} onClick={this.handleSavedBtn} data-testid="handleSavedBtn">{webConfigJSON.savedTalent}</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography style={webStyles.education}>{webConfigJSON.education}</Typography>
                                            <Typography color="secondary">{accountDetails.education}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {this.renderResponseDialog()}
                </ThemeProvider>
                <FooterWeb />
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    outerContainer: {
        justifyContent: 'space-evenly'
    },
    dashboardText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        marginBottom: '0.5%',
        paddingLeft: '3%'
    },
    userNameText: {
        marginBottom: '1%',
        paddingLeft: '3%'
    },
    boxConatiner: {
        padding: '30px 0'
    },
    borderBox: {
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
        padding: '2%'
    },
    avtara: {
        width: '20%',
        height: '20%'
    },
    skillText: {
        fontSize: '1.3rem'
    },
    skillBox: {
        display: 'flex',
        gap: '2%',
        margin: '3% 0 5%',
        flexWrap: 'wrap' as 'wrap'
    },
    skill: {
        color: '#fff',
        padding: '5px 15px',
        borderRadius: '5px',
        background: '#166489',
        marginTop: '2%'
    },
    proposalBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    proposalText: {
        fontSize: '1.5rem'
    },
    price: {
        fontSize: '1.5rem',
        fontWeight: 600
    },
    proposed: {
        textAlign: 'end' as 'end',
        marginTop: '2%'
    },
    greeting: {
        marginBottom: '4%'
    },
    pastWork: {
        margin: '4% 0'
    },
    regard: {
        marginTop: '4%'
    },
    locationIcon: {
        color: green[500]
    },
    profileDisplay: {
        display: 'flex',
        gap: '4%'
    },
    profileStatus: {
        position: 'relative' as 'relative'
    },
    status: {
        position: 'absolute' as 'absolute',
        top: '88%',
        right: '12%'
    },
    nameStyle: {
        fontSize: '1.5rem',
        marginBottom: '3%'
    },
    website: {
        fontSize: '0.8rem'
    },
    locationBox: {
        display: 'flex'
    },
    messageContainer: {
        textAlign: 'center' as 'center',
        margin: '11% 0 5%'
    },
    savedContainer: {
        textAlign: 'center' as 'center'
    },
    buttonSize: {
        width: '100%'
    },
    education: {
        margin: '7% 0 3%'
    },
    payBox: {
        position: 'relative' as 'relative',
        background: '#253342',
        borderRadius: '5px',
        color: '#fff',
        marginTop: '3%',
        padding: '2% 3%'
    },
    checkIcon: {
        position: 'absolute' as 'absolute',
        right: '3%',
        width: '3%'
    },
    payBy: {
        fontSize: '1.2rem'
    },
    popular: {
        fontSize: '0.9rem',
        marginTop: '6%'
    },
    contract: {
        fontSize: '1.3rem'
    },
    formInput: {
        margin: '3% 0'
    },
    required: {
        color: 'red'
    },
    lable: {
        marginBottom: '2%'
    },
    limitation: {
        margin: '1% 0 2%',
        fontSize: '0.9rem'
    },
    inputContainer: {
        margin: '2% 0 1%'
    },
    addButton: {
        borderRadius: '20px',
        fontSize: '0.7rem',
        padding: '10px 7px'
    },
    agreeBox: {
        display: 'flex',
        alignItems: 'start'
    },
    checkInput: {
        color: green[400]
    },
    agreeText: {
        fontSize: '0.8rem',
        marginTop: '1.4%'
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'end',
        gap: '2%',
        marginTop: '3%'
    },
    nextBtn: {
        borderRadius: '8px',
        padding: '1% 6%'
    },
    cancelBtn: {
        borderRadius: '8px',
        padding: '1% 4%'
    },
    countBox: {
        display: 'flex',
        alignItems: 'center' as 'center',
        gap: '5px'
    },
    offlineText: {
        fontSize: '0.7rem',
        textAlign: 'center' as 'center',
        background: '#206FC4',
        color: '#fff',
        fontWeight: 600,
        borderRadius: '14px',
        padding: '4px 7px'
    },
    dialogOuterContainer: {
        boxShadow: 'none'
    },
    successMessage: {
        color: 'green',
    },
    failedMessage: {
        color: 'red'
    },
    btnOuterContainer: {
        display: 'flex',
        justifyContent: 'center'
    },

};
// Customizable Area End
