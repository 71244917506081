// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import AddPaymentDetailsController, {
  Props
} from './AddPaymentDetailsController.web';
import AddIcon from '@material-ui/icons/Add';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start

export default class AddPaymentDetails extends AddPaymentDetailsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <Box marginTop={'5%'}>
          {!this.state.isPayment ? (
            <Box
              border={'2px solid #8080801a'}
              component={Paper}
              display={'flex'}
              flexDirection={'column'}
              padding={'2%'}
              gridRowGap={'30px'}
            >
              <Typography
                style={{
                  fontSize: '25px',
                  fontFamily: 'Inter',
                  fontWeight: 600
                }}
              >
                Billing methods
              </Typography>
              <Typography>
                You haven’t set up any billing methods yet. Add a method so you
                can hire when you’re ready.
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                gridColumnGap={'10px'}
                onClick={() => {
                  this.setState({ isPayment: true });
                }}
                style={{ cursor: 'pointer' }}
              >
                <AddIcon style={{ color: '#2275CD', fontSize: '2.5rem' }} />
                <Typography style={{ color: '#2275CD' }}>
                  Add a billing method
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                border={'2px solid #8080801a'}
                component={Paper}
                display={'flex'}
                flexDirection={'column'}
                padding={'2%'}
                gridRowGap={'30px'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography
                    style={{
                      fontSize: '25px',
                      fontFamily: 'Inter',
                      fontWeight: 600
                    }}
                  >
                    Add a billing method
                  </Typography>
                  <Button
                    data-testid={'cancel'}
                    onClick={() => {
                      this.setState({ isPayment: false });
                    }}
                    variant="contained"
                    style={{
                      borderRadius: '10px',
                      width: '150px',
                      backgroundColor: '#fff',
                      color: '#2275CD',
                      textTransform: 'none',
                      border: '1px solid #2275CD'
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gridColumnGap={'10px'}
                >
                   <Radio
                   data-test-id="handleChange"
                   checked={this.state.selected}
                   onChange={this.handleChange}
                   icon={<PanoramaFishEyeIcon style={{ color: '#2275CD' }} />}
                   checkedIcon={<RadioButtonCheckedIcon style={{ color: '#2275CD' }} />}
                   />
                  <Typography style={{ fontSize: '16px', fontWeight: 600 }}>
                    Payment card
                  </Typography>
                  <Typography style={{ fontSize: '13px' }}>
                    Visa, Mastercard, American Express, Discover, Diners
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gridRowGap={'1.5rem'}
                >
                  <Box>
                    <Typography style={webStyle.header}>Card number<span style={webStyle.importantField}>*</span></Typography>
                    <TextField
                      data-testid="cardNumber"
                      placeholder="1234 5678 9012 3456"
                      variant="outlined"
                      value={this.state.cardNumber}
                      inputProps={{
                        style: {
                          ...webStyle.outlinedInput
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '10px',
                          height: '40px'
                        }
                      }}
                      style={{ ...webStyle.inputField, width: '100%' }}
                      name="cardNumber"
                      onChange={this.handleCardNumber}
                      error={this.state.numberError['cardNumber']}
                      helperText={this.state.numberError['cardNumber']}
                    />
                  </Box>
                  <Box display={'flex'} gridColumnGap={'1rem'} flex={1}>
                    <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                      <Typography style={webStyle.header}>
                        First Name<span style={webStyle.importantField}>*</span>
                      </Typography>
                      <TextField
                       
                        data-testid="firstName"
                        placeholder="First Name"
                        variant="outlined"
                        value={this.state.firstName}
                        inputProps={{
                          style: {
                            ...webStyle.outlinedInput
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            height: '40px'
                          }
                        }}
                        style={{ ...webStyle.inputField}}
                        name="firstName"
                        onChange={this.handleNameChange}
                        error={this.state.error['firstName']}
                        helperText={this.state.error['firstName']}
                      />
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                      <Typography style={webStyle.header}>Last name<span style={webStyle.importantField}>*</span></Typography>
                      <TextField
                       
                        data-testid="lastName"
                        placeholder="Last name"
                        variant="outlined"
                        value={this.state.lastName}
                        inputProps={{
                          style: {
                            ...webStyle.outlinedInput
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            height: '40px'
                          }
                        }}
                        style={{ ...webStyle.inputField }}
                        name="lastName"
                        onChange={this.handleNameChange}
                        error={this.state.error['lastName']}
                        helperText={this.state.error['lastName']}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} gridColumnGap={'1rem'} flex={1}>
                    <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                      <Typography style={webStyle.header}>
                        Expiration month<span style={webStyle.importantField}>*</span>
                      </Typography>
                      <TextField
                        data-testid="expirationMonth"
                        placeholder="MM"
                        variant="outlined"
                        value={this.state.expirationMonth}
                        inputProps={{
                          style: {
                            ...webStyle.outlinedInput
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            height: '40px'
                          }
                        }}
                        style={{ ...webStyle.inputField }}
                        name="expirationMonth"
                        onChange={this.handleMonthFieldChange}
                        onBlur={this.handleMonthFieldBlur}
                        error={this.state.numberError['expirationMonth']}
                        helperText={this.state.numberError['expirationMonth']}
                      />
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                      <Typography style={webStyle.header}>
                        Expiration year<span style={webStyle.importantField}>*</span>
                      </Typography>
                      <TextField
                        data-testid="expirationYear"
                        placeholder="YY"
                        variant="outlined"
                        value={this.state.expirationYear}
                        inputProps={{
                          style: {
                            ...webStyle.outlinedInput
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            height: '40px'
                          }
                        }}
                        style={{ ...webStyle.inputField}}
                        name="expirationYear"
                        onChange={this.handleYearFieldChange}
                        error={this.state.numberError['expirationYear']}
                        helperText={this.state.numberError['expirationYear']}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} gridColumnGap={'1rem'} flex={1} flexDirection={"column"} width={"50%"}>
                    <Typography style={webStyle.header}>
                      Security code<span style={webStyle.importantField}>*</span>
                    </Typography>
                    <TextField
                      data-testid="securityCode"
                      placeholder="3 digits"
                      variant="outlined"
                      value={this.state.securityCode}
                      inputProps={{
                        style: {
                          ...webStyle.outlinedInput
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '10px',
                          height: '40px',
                          marginRight:"0.5rem"
                        }
                      }}
                      style={{ ...webStyle.inputField}}
                      name="securityCode"
                      onChange={this.handleSecurityFieldChange}
                      error={this.state.numberError['securityCode']}
                      helperText={this.state.numberError['securityCode']}
                    />
                  </Box>

                  <Typography style={{ fontSize: '20px', fontWeight: 600 }}>
                    Billing address
                  </Typography>
                </Box>
                <Box>
                  <Typography style={webStyle.header}>Country<span style={webStyle.importantField}>*</span></Typography>
                  <Autocomplete
                    style={{ borderRadius: '10px' }}
                    id="size-small-outlined-multi"
                    size="small"
                    options={this.state.countryList || []}
                    getOptionLabel={(option: { name: string; code: string }) =>
                      option.name
                    }
                    onChange={(newValue) => this.handleCountryChange(newValue)} // Add onChange handler
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: '10px' }
                        }}
                        error={Boolean(this.state.error['country'])} // Display error state if any
                        helperText={this.state.error['country']} 
                      />
                    )}
                    />
                </Box>
                <Box>
                  <Typography style={webStyle.header}>
                    Address line 1<span style={webStyle.importantField}>*</span>
                  </Typography>
                  <TextField
                    data-testid="addressLine1"
                    variant="outlined"
                    value={this.state.addressLine1}
                    inputProps={{
                      style: {
                        ...webStyle.outlinedInput
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                        height: '40px'
                      }
                    }}
                    style={{ ...webStyle.inputField, width: '100%' }}
                    name="addressLine1"
                    onChange={this.handleTextChange}
                    error={this.state.error['addressLine1']}
                    helperText={this.state.error['addressLine1']}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.header}>
                    Address line 2 (optional)
                  </Typography>
                  <TextField
                    data-testid="addressLine2"
                    variant="outlined"
                    value={this.state.addressLine2}
                    inputProps={{
                      style: {
                        ...webStyle.outlinedInput
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                        height: '40px'
                      }
                    }}
                    style={{ ...webStyle.inputField, width: '100%' }}
                    name="addressLine2"
                    onChange={this.handleTextChange}
                    error={this.state.error['addressLine2']}
                    helperText={this.state.error['addressLine2']}
                  />
                </Box>
                <Box display={'flex'} gridColumnGap={'1rem'} flex={1}>
                  <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                    <Typography style={webStyle.header}>City</Typography>
                    <TextField
                      data-testid="city"
                      variant="outlined"
                      value={this.state.city}
                      inputProps={{
                        style: {
                          ...webStyle.outlinedInput
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '10px',
                          height: '40px'
                        }
                      }}
                      style={{ ...webStyle.inputField}}
                      name="city"
                      onChange={this.handleTextChange}
                      error={this.state.error['city']}
                      helperText={this.state.error['city']}
                    />
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} flex={0.5}>
                    <Typography style={webStyle.header}>
                      Postal code (optional)
                    </Typography>
                    <TextField
                      data-testid="postalCode"
                      variant="outlined"
                      value={this.state.postalCode}
                      inputProps={{
                        style: {
                          ...webStyle.outlinedInput
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '10px',
                          height: '40px'
                        }
                      }}
                      style={{ ...webStyle.inputField }}
                      name="postalCode"
                      onChange={this.handlePostalCodeFieldChange}
                      error={this.state.error['postalCode']}
                      helperText={this.state.error['postalCode']}
                    />
                  </Box>
                </Box>
                <Box>
                  <Button
                    data-testid={'Save'}
                    variant="contained"
                    onClick={() => this.handleSaveClick()}
                    style={{
                      borderRadius: '10px',
                      width: '150px',
                      backgroundColor: '#2275CD',
                      color: '#fff',
                      textTransform: 'none',
                      border: '1px solid #2275CD'
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  outlinedInput: {
    borderRadius: '13px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  },
  inputField: {
    maxHeight: '40px',
    borderRadius: '10px',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    },
  },
  header: {
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'Inter'
  },
  importantField:{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FF5E5B"
  }
};
// Customizable Area End
