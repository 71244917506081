import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  rating: any;
  feedBackText: any;
  firstName: any;
  authToken:string;
  homepageData: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class HomePageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ClientHomePageRequestId:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.ClientHomePageRequestId=""
    this.state = {
      rating: 2,
      feedBackText: '',
      firstName: localStorage.getItem("accountHolderName"),
      authToken:'',
      homepageData:[]

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.fetchClientHomePageDetails()
    // Customizable Area End
  }
  // Customizable Area Start
  handlePostJob =async () => {
    const first_login=await storage.get("is_first_time_login")
    console.log(first_login,"first_login",typeof first_login);

    
    if (first_login!=="null") {
     this.props.navigation.navigate( 'PostJob');
    } else {
     this.props.navigation.navigate( 'JobPosting');

    }
  
  };
  handleJobPost = () => {
    const jobPost: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    jobPost.addData(getName(MessageEnum.NavigationTargetMessage), 'JobPosting');
    jobPost.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(jobPost);
  };
  handleMyJobs = () => {
    const findwork: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    findwork.addData(getName(MessageEnum.NavigationTargetMessage), 'MyJobs');
    findwork.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(findwork);
  };

  fetchClientHomePageDetails = () => {
    const ClientHomePageRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.authToken
    };

   
    this.ClientHomePageRequestId = ClientHomePageRequest.messageId;
    ClientHomePageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.ClientHomePageRequestEndPoint
    );
    ClientHomePageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ClientHomePageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    
    runEngine.sendMessage(ClientHomePageRequest.id, ClientHomePageRequest);
  }

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === _message.id){
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if(this.ClientHomePageRequestId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson.data){
          this.setState({homepageData : responseJson.data})
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
    // Customizable Area End
  }
}
