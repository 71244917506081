Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.notificationHead = "Notification & Settings";
exports.save = "SAVE";
exports.emailNotification = "Email Notification";
exports.notificationDetail = "Get Emails to find out what’s going on when you are not online. You can turn these off";
exports.pushNotification = "Push Notifcation";
exports.pushnotificationDetail = "Get desktop notifications to find out what’s going on when you are online. You can turn these off"
// Customizable Area End