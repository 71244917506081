import React from "react";

// Customizable Area Start
import InvitationToInterviewController, {
  Props,
  webConfigJSON
} from "./InvitationToInterviewController.web";
import { Typography, Grid, withStyles, Box, Checkbox, TextField,InputAdornment, MenuItem, Button,styled, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { locationIcon , addicon, dot} from "../src/assets";
import Avatar from "@material-ui/core/Avatar";
import { blue } from "@material-ui/core/colors";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// Customizable Area End

// Customizable Area Start
const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius:"10px",
    borderColor:"#E6E6E6",
    height:"59px"
  }
})
const CoverTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
})
// Customizable Area End

export default class AcceptInterview extends InvitationToInterviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const GreenCheck = withStyles({
      root: {
        "&$checked": {
          color: blue[600],
        },
        color: "#535353",
      },
      checked: {},
    })(Checkbox);
    let {duration,per_day_charge,est_budget,proposals,
      posted_job,name,skills,estimate,location,
      description,updated_at
      }=this.state.inviteToJobData?.attributes?.post?.data?.attributes;
      const {date,time} = this.formatDate(updated_at);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box style={{ margin: "2rem" }}>
          <Box>
            <Typography
              style={{
                ...webStyle.headingTextAccept,
                margin: "20px 20px",
                marginBottom: "0px",
                fontSize: "36px",
              }}
            >
              Accept interview
            </Typography>
          </Box>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} md={9}>
              <Box style={{...webStyle.mainboxAccept,padding:"10px"}}>
                <Box style={{ borderBottom: "1px solid #DEDEDE" }}>

                  <Typography style={webStyle.JobHeadAccept}>Job details</Typography>
                </Box>

                <Box style={{ margin: "10px" }}>
                  <Box
                    style={
                      webStyle.nameBox
                    }
                  >
                    <div>
                      <Typography style={webStyle.textAccept}>
                        {name}
                      </Typography>
                      <Box style={webStyle.subBox}>
                        <Box style={webStyle.locationBox}>
                          <img src={locationIcon} style={webStyle.locationAccept} />
                          <Typography
                            style={{
                              ...webStyle.text2Accept,
                              marginTop: "0px",
                              marginLeft: "5px",
                            }}
                          >
                            {location}
                          </Typography>
                        </Box>
                        <Box style={webStyle.locationBox}>
                        <Box display={'flex'} flex={1} alignItems={'center'}>
                      <Typography
                        style={{
                          ...webStyle.text,
                          marginRight: '10px'
                        }}
                      >
                        {date}
                      </Typography>
                      <img src={dot} style={{ marginRight: '10px' }} />
                      <Typography style={webStyle.text}>{time}</Typography>
                    </Box>
                        </Box>
                      </Box>
                    </div>
                  </Box>

                  <Box style={webStyle.hrStyleAccept}>
                    <Typography style={{ ...webStyle.textAccept, fontSize: "16px" }}>
                      Project Descriptions:
                    </Typography>
                    <Typography
                      style={{ ...webStyle.text2Accept, marginBottom: "24px" }}
                    >
                      {description}
                    </Typography>
                  </Box>

                  <Box style={webStyle.hrStyleAccept}>
                    <Typography
                      style={{
                        ...webStyle.textAccept,
                        marginTop: "0px",
                        fontSize: "16px",
                      }}
                    >
                      Skills and expertise:
                    </Typography>
                    <Box
                      style={{
                        margin: "12px 20px 24px 20px",
                        alignItems: "center",
                        gap: "12px",
                        display: "flex",
                      }}
                    >
                      {skills?.map((skill: any, index: number) => {
                        return (
                          <Typography style={webStyle.php} key={index}>
                            {skill}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>

                  <Box style={{ marginBottom: "32px" }}>
                    <Typography style={{ ...webStyle.textAccept, fontSize: "16px" }}>
                      Project Details:
                    </Typography>
                    <Box style={webStyle.timeLineGrid}>
                      <Box style={{ display: "flex", flex: "1 1 100%" }}>
                        <div style={webStyle.Flex}>
                          <Avatar style={webStyle.avatar}>1</Avatar>
                          <Box style={webStyle.timeLineContent}>
                            <Typography
                              style={{
                                ...webStyle.headingTextAccept,
                                marginBottom: "0px",
                              }}
                            >
                              {"Scope"}
                            </Typography>
                            <Typography style={webStyle.subHeadingText}>
                            {estimate},{duration}
                            </Typography>
                          </Box>
                        </div>
                        <div style={webStyle.Flex}>
                          <Avatar style={webStyle.avatar}>2</Avatar>
                          <Box style={webStyle.timeLineContent}>
                            <Typography
                              style={{
                                ...webStyle.headingTextAccept,
                                marginBottom: "0px",
                              }}
                            >
                              {"Budget"}
                            </Typography>
                            <Typography style={webStyle.subHeadingText}>
                              {per_day_charge}/ {"day"}
                            </Typography>
                          </Box>
                        </div>
                      </Box>
                      <div style={webStyle.Flex}>
                        <Avatar style={webStyle.avatar}>3</Avatar>
                        <Box style={webStyle.timeLineContent}>
                          <Typography
                            style={{
                              ...webStyle.headingTextAccept,
                              marginBottom: "0px",
                            }}
                          >
                            {"Category of Patent"}
                          </Typography>
                          <Typography style={webStyle.subHeadingText}>
                            {"Category/Subcat1,Subcat2,Subcat3"}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={3} style={{ margin: "24px auto" }}>
              <Box style={webStyle.estdBoxStyle}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={webStyle.numberStyle}>${est_budget}</Typography>
                  <Typography style={webStyle.estdStyle}>
                    Estd Budget
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Typography style={webStyle.estdStyle}>
                    Proposals: {proposals}
                  </Typography>
                </div>
              </Box>

              <Box
                style={{
                  ...webStyle.mainboxAccept,
                  margin: "24px auto auto",
                  padding: "30px",
                }}
              >
                <Typography
                  style={{
                    ...webStyle.textAccept,
                    marginTop: "0px",
                    marginLeft: "0px",
                    marginBottom: "18px",
                  }}
                >
                  About the client:
                </Typography>
                <Box style={{ marginLeft: "-4%" }}>
                  <Typography style={webStyle.aboutThisJobTexts}>
                    <GreenCheck
                      checked={true}
                      disabled={true}
                      size="medium"
                      color="primary"
                      name="containsUppercase"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    Jobs Posted: {posted_job}
                  </Typography>
                  <Typography style={webStyle.aboutThisJobTexts}>
                    <GreenCheck
                      checked={true}
                      disabled={true}
                      size="medium"
                      color="primary"
                      name="containsUppercase"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                   Invites send: {this.state.inviteToJobData.attributes.invite_send}
                  </Typography>
                  <Typography style={webStyle.aboutThisJobTexts}>
                    <GreenCheck
                      checked={true}
                      disabled={true}
                      size="medium"
                      color="primary"
                      name="containsUppercase"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    Unanswered Invites: {this.state.inviteToJobData.attributes.unanswered_invites}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box>
                <Typography
                  style={{ ...webStyle.textAccept, margin: "10px auto 38px 38px" }}
                >
                  Milestone details
                </Typography>
              </Box>
              <Box style={webStyle.mainboxAccept}>
                <Box>
                  <Typography style={{ ...webStyle.textAccept, fontSize: "16px",marginTop:"24px",marginLeft:"26px" }}>
                    How many milestones do you want to include?
                  </Typography>
                </Box>
                <Box style={webStyle.hrStyleAccept}>
                  <Grid
                    container
                    spacing={1}
                    style={webStyle.inputContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography style={{ ...webStyle.textAccept, fontSize: "16px",marginLeft:""}}>
                        {webConfigJSON.mileDescription}
                        <span style={webStyle.required}>*</span>
                      </Typography>
                      <Box style={{...webStyle.countBox,gap:"10px"}}>
                        <Typography>1</Typography>
                        <CustomTextField
                          variant="outlined"
                          id="description"
                          size="small"
                          placeholder="description"
                          name="description"
                          data-testid="description"
                          style={{width:"100%"}}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box>
                        <Typography style={{ ...webStyle.textAccept, fontSize: "16px",marginLeft:""}}>
                          {webConfigJSON.amount}
                          <span style={webStyle.required}>*</span>
                        </Typography>
                        <Box style={webStyle.countBox}>
                        <CustomTextField
                          variant="outlined"
                          id="amount"
                          data-testid="amount"
                          size="small"
                          name="amount"
                          placeholder="amount"
                          style={{width:"100%"}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box>
                        <Typography style={{ ...webStyle.textAccept, fontSize: "16px",marginLeft:""}}>
                          {webConfigJSON.date}
                          <span style={webStyle.required}>*</span>
                        </Typography>
                        <Box style={webStyle.countBox}>
                        <CustomTextField
                          variant="outlined"
                          id="date"
                          data-testid="date"
                          type="date"
                          size="small"
                          name="date"
                          style={{width:"100%"}}
                        />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <Box>
                        <Typography style={{ ...webStyle.textAccept, fontSize: "16px",marginLeft:""}}>
                          {webConfigJSON.status}
                          <span style={webStyle.required}>*</span>
                        </Typography>
                        <Box style={webStyle.countBox}>
                        <CustomTextField
                          select
                          variant="outlined"
                          placeholder="status"
                          id="status"
                          data-testid="status"
                          name="status"
                          size="small"
                          style={{width:"100%"}}
                        >
                          {this.state.statusOption.map((data, index) => (
                            <MenuItem key={index} value={data.value}>
                              {data.option}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                        <CancelOutlinedIcon style={{marginLeft:"10px"}}/>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={webStyle.addMilestoneStyleBox}>
                  <img src={addicon} style={{width:"30px"}}/>
                  <Typography style={webStyle.addMilestoneStyle}>Add  milestone</Typography>
                  </Box>
                </Box>
              
                <Box style={webStyle.hrStyleAccept}>
                <Typography style={{...webStyle.blackBoldText,fontWeight:700}}>
                  Total price of project
                  </Typography>
                    <Typography style={{...webStyle.lightGreyText,marginTop:"30px",marginLeft:"32px"}}>
                    This includes all milestones, and is the amount your client
                    will see
                    </Typography>
                    <Typography
                      style={{...webStyle.blackBoldText,
                        marginBottom: "30px"}}
                    >
                      $00.00
                    </Typography>
                </Box>

                <Box style={webStyle.hrStyleAccept}>
                  <Typography style={{...webStyle.blackBoldText,fontWeight:700}}>
                   10% Freelancer Service Fee
                  </Typography>
                  <Typography style={{...webStyle.blackBoldText,marginBottom: "30px"}}>$10.00</Typography>
                </Box>

                <Box>
                    <Typography style={{...webStyle.blackBoldText,fontWeight:700,marginTop:"32px"}}>
                    You'll receive
                    </Typography>
                    <Typography style={{...webStyle.lightGreyText,marginTop:"22px",marginLeft:"32px"}}>
                    The estimated payment, after service fees.
                    </Typography>
                    <Typography
                      style={{...webStyle.blackBoldText,
                        marginBottom: "22px"}}
                    >
                      $00.00
                    </Typography>
                </Box>

              </Box>
            </Grid>

          </Grid>
            <Box style={webStyle.mainboxAccept}>
                <Box style={{ borderBottom: "1px solid #DEDEDE" }}>
                  <Typography style={webStyle.JobHeadAccept}>Cover letter</Typography>
                </Box>
                <CoverTextField variant="outlined" style={{width: "100%" ,height:"8%"}} 
                value={this.state.coverLetterText} 
                onChange={this.handleChangeCoverLetter}
                />
            </Box>
                 {!this.state.coverLetterText && (
                    <Typography style={{ color: 'red',marginLeft:"50px" }}>{this.state.coverLetterError}</Typography>
                  )}
        </Box>
        <Grid item xs={12} sm={12} md={12}>
            <Box style={webStyle.buttonBox}>
                <Button variant="contained" style={webStyle.nextBtn} data-testid="nextBtn" onClick={this.postAcceptInvitation}>{"Submit Proposal"}</Button>
                <Button variant="outlined"  style={webStyle.cancelBtn} data-testid="Cancel" onClick={this.handleCancelBtn}>{"Cancel"}</Button>
            </Box>
        </Grid>
        <div>
        <Dialog
      open={this.state.isSubmittedProposal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: '350px',
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">Submit proposal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
      {this.state.proposalRes}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  data-testid='handleCloseDialog' onClick={()=>this.handleCloseDialog()} color="primary" autoFocus>
         Ok
        </Button>
      </DialogActions>
    </Dialog>
        </div>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: "100%",
    backgroundColor: "lightblue",
    padding: "10px",
    border: "1px solid #ccc",
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#206FC4",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 6px)",
    },
  },

  nameBox:{
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "32px",
  },
  inputContainer: {
    margin: '22px',
    width:"unset"
},
addMilestoneStyleBox:{
  display: "flex",
  gap: "10px",
  marginLeft: "30px",
  marginBottom:"24px"
},
buttonBox: {
  display: 'flex',
  gap: '2%',
  marginTop: '5%',
  marginLeft:"76px"
},
addMilestoneStyle:{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontFamily: "Rubik",
  color: "#2378D1",
},
nextBtn: {
  borderRadius: '8px',
  padding: '1% 3%',
  background:"#2378D1",
  color:"white",
  fontFamily:"Inter",
  textTransform:"inherit" as "inherit"
},
cancelBtn: {
  borderRadius: '8px',
  padding: '1% 5%',
  borderColor:"#2378D1",
  color:"#2378D1",
  fontFamily:"Inter",
  textTransform:"inherit" as "inherit"

},
  lightGreyText: {
    color: "#515354",
    fontSize: "16px",
    fontFamily:"Inter",
  },
  blackBoldText:{
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "inter",
    marginTop: "5px",
    marginLeft: "32px"
  },
  required: {
    color: "red",
  },
  lable: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  countBox: {
    display: "flex",
    alignItems: "center" as "center",
    marginTop: "10px",
  },
  addButton: {
    borderRadius: "20px",
    fontSize: "0.7rem",
    padding: "10px 7px",
  },
  rejectBtn: {
    width: "180px",
    background: "#206FC4",
    color: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    borderRadius: "8px",
    cursor: "pointer",
    padding: "6px",
    textTransform: "inherit" as "inherit",
  },
  DialogActionsBox: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "1",
    letterSpacing: "0px",
    fontFamily: "'Inter', sans-serif",
    color: "#515354",
  },
  DialogContentText: {
    fontSize: "18px",
    fontFamily: "'Inter', sans-serif",
    color: "#2B2B2B",
    fontWeight: 600,
    marginBottom: "0px",
  },
  headingTextAccept: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0px",
    textalign: "left",
    color: "#000000",
    marginBottom: "20px",
  },
  hrStyleAccept: {
    marginBottom: "20px",
    borderBottom: "1px solid #DEDEDE",
  },
  text: {
    color: "#6C6C6C",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  estdBoxStyle: {
    display: "flex",
    justifyContent: "space-between",
    border: "2px solid #DEDEDE",
    borderRadius: "15px",
    padding: "30px",
  },
  subHeadingText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    letterSpacing: "0px",
    textalign: "left",
    color: "rgb(108, 108, 108)",
    width: "280px",
  },
  Message: {
    fontSize: "14px",
    color: "#2B2B2B",
    fontFamily: "Inter",
  },
  aboutThisJobTexts: {
    fontFamily: "'Inter', sans-serif",
    marginTop: "4px",
    fontSize: "18px",
    color: "rgb(108, 108, 108)",
  },
  timeLineGrid: {
    margin: "12px 20px 24px 20px",
    flexWrap: "wrap" as "wrap",
    gap: "20px",
    display: "flex",
  },
  timeLineContent: {
    gridAutoFlow: "row",
    rowGap: "6px",
    display: "grid",
  },
  avatar: {
    flexShrink: 0,
    background: "#206FC4",
    width: "31px",
    height: "31px",
    marginRight: "9px",
  },
  Flex: {
    alignItems: "center",
    display: "flex",
  },
  numberStyle: {
    color: "#4A4A4A",
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: "18px",
  },
  estdStyle: {
    color: "#4A4A4A",
    fontSize: "16px",
    fontFamily: "Inter",
  },
  mainboxAccept: {
    borderRadius: "15px",
    border: "2px solid #DEDEDE",
    margin: "24px auto auto 38px",
  },
  JobHeadAccept: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    margin: "18px",
  },
  textAccept: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    marginTop: "10px",
    marginLeft: "20px",
  },

  locationAccept: {
    height: "15px",
  },
  locationBox: {
    display: "flex",
    marginTop: "6px",
    fontFamily: "Inter",
    alignItems: "center",
    color: "#555555",
  },

  subBox: {
    marginLeft: "20px",
  },
  text2Accept: {
    marginTop: "4px",
    fontSize: "14px",
    marginLeft: "20px",
    width: "80%",
    color: "#6C6C6C",
    fontFamily: "Inter",
  },
  php: {
    borderRadius: "30px",
    fontFamily: "Inter",
    textTransform: "inherit" as "inherit",
    color: "#246dc3",
    padding: "5px 20px 5px 20px",
    fontWeight: 600,
    width: "fit-content",
    background: " #EBF1F9",
  },
  avtar: {
    padding: "8px",
    fontWeight: 200,
    marginLeft: "20px",
    backgroundColor: " #1F6DC1",
  },
  subgrid: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
  },
  text3: {
    color: "#6C6C6C",
    fontFamily: "Inter",
    fontSize: "16px",
  },
  mainbox2: {
    background: "rgb(245, 247, 249) none repeat scroll 0% 0%",
    borderRadius: "15px",
    padding: "15px",
    marginLeft: "25px",
  },
  text4: {
    padding: "20px 20px 0px 20px",
    fontSize: "22px",
    color: " #525252",
    fontFamily: "Roboto",
  },
  proposals: {
    marginRight: "10px",
    marginTop: "10px",
    color: " #525252",
    fontFamily: "Roboto",
    padding: "10px",
    fontSize: "17px",
  },
  budget: {
    color: " #525252",
    fontFamily: "Roboto",
    marginLeft: "20px",
    fontSize: "18px",
  },
  applyBtn: {
    width: "50%",
    color: "white",
    padding: "8px",
    backgroundColor: "#2378D1",
    marginTop: "18px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#2676D8",
    },
  },
  favouriteBtn: {
    marginTop: "10px",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #2676D8",
    fontWeight: 600,
    color: "#2676D8",
    "&:hover": {
      border: "1px solid #2676D8",
    },
    
  },
};

// Customizable Area End
