import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles"
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start

interface FormErrors {
    userName?: string,
    message?: string
}
interface MessageValues {
    name: string;
    id: string;
    attributes: {
        first_name: string;
        id: string
        user_name: string,
        chat_user_id: null,
        email: string,
        full_phone_number: string
        country_code: number,
        phone_number: number,
        avatar: string
    }
}

export interface IMessageData {
    data?: MessageListAttributes[]
}

export interface MessageListAttributes {
    id: string;
    attributes: {
        id: string,
        message: string,
        created_at: string,
        updated_at: string,
        current_user_id: number,
        account_id: number,
        user_name: string
    }

}

interface IMesssageDelete {
    message?: string
}
interface IMessageCount {
    unread_count?: number
    errors?: {
        message?: string
    }
}

interface IAccountUsers {
    data?: MessageValues[],
    errors?: {
        message?: string
    }
}

interface ISendMessage {
    data?: {
        id?: string,
        type?: string,
        attributes?: {
            id?: number,
            message?: string,
            created_at?: string,
            updated_at?: string,
            current_user_id?: number,
            account_id?: number,
            user_name?: string
        }
    },
    errors?: [
        { message: string }
    ]

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    usersData: MessageValues[]
    userVal: string
    isErrorFormData: FormErrors
    accountId: string,
    messageInputValue: string
    messagecount: number
    messageStatus: ISendMessage
    messageList: MessageListAttributes[]
    errorMessage: string
    deleteMessage: string

    // Customizable Area End
}

interface SS {
    id: any;
}

export default class SendamessageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetUsers: string = "";
    apiCallIdMessageCount: string = "";
    apiCallIdsendMessage: string = "";
    apiCallIdGetAllMessages: string = ""
    apiCallIdDeleteMessages: string = ""
    token: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess), getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            usersData: [],
            userVal: "",
            isErrorFormData: {},
            messageInputValue: "",
            accountId: "",
            messagecount: 0,
            messageStatus: {},
            messageList: [],
            errorMessage: "",
            deleteMessage: "",


        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId === this.apiCallIdGetUsers) {
                this.getAccountUsersApiResponse(responseJson)
            } else if (apiRequestCallId === this.apiCallIdsendMessage) {
                this.sendMessageApiResponce(responseJson)
            } else if (apiRequestCallId === this.apiCallIdMessageCount) {
                this.getListMessagesCount(responseJson)
            } else if (apiRequestCallId === this.apiCallIdGetAllMessages) {
                this.getMessagesApiResponce(responseJson)
            } else if (apiRequestCallId === this.apiCallIdDeleteMessages) {
                this.getDeleteMessageApiResponse(responseJson)
            }


        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Api responces
    sendMessageApiResponce = (responseJson: ISendMessage) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ messageStatus: responseJson }, () => {
                this.showAlert('Alert', configJSON.messageSent)
                this.setState({
                    userVal: "",
                    messageInputValue: ""
                })
            })
        } else {
            this.sendMessageErrorApiResponce(responseJson)
        }
    }
    sendMessageErrorApiResponce = (responseJson: ISendMessage) => {
        if (responseJson && responseJson.errors) {
            this.setState({ errorMessage: responseJson.errors[0].message })
            this.showAlert('Alert', this.state.errorMessage)
        }
    }
    getAccountUsersApiResponse = (responseJson: IAccountUsers) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ usersData: responseJson.data! })
        }
    }
    getListMessagesCount = (responseJson: IMessageCount) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ messagecount: responseJson.unread_count! })
        } else if (responseJson && responseJson.errors) {
            this.setState({ errorMessage: responseJson.errors.message! })
            this.showAlert("Alert", this.state.errorMessage)

        }
    }
    getMessagesApiResponce = (responseJson: IMessageData) => {
        if (responseJson && responseJson.data) {
            this.setState({
                messageList: responseJson.data
            })
        }
    }
    getDeleteMessageApiResponse = (responseJson: IMesssageDelete) => {
        if (responseJson && responseJson.message) {
            this.setState({
                deleteMessage: configJSON.messageDelete
            }, () => {
                this.allMessages()
                this.showAlert("Alert", this.state.deleteMessage)
            })
        }
    }


    componentDidMount = async () => {
        const token = await getStorageData('authToken')
        this.token = token
        this.getMessagesCount()

        window.location.pathname === '/AllMessages' &&
            this.allMessages()
     
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({
            userVal: event.target.value,
        }, () => {
            this.getUsers()
        });

        let accountID = this.state.usersData.filter((user: MessageValues) => {
            return user.attributes.first_name === event.target.value
        })[0]
        this.setState({
            accountId: accountID?.id
        })

    }


    handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ messageInputValue: event.target.value })
    }

    validateForm = (userName: string, message: string) => {
        const errors: FormErrors = {};

        if (!userName) {
            errors.userName = "user name is required"
        }
        if (!message) {
            errors.message = "Message is required"

        }
        return errors
    }
    submitButton = () => {
        this.setState({ isErrorFormData: this.validateForm(this.state.userVal, this.state.messageInputValue) },
            () => {
                if (Object.keys(this.state.isErrorFormData).length === 0) {
                    this.createMessage()
                }
            }

        );
    }
    handleUnreadMessages = async () => {
        this.props.navigation.navigate('/AllMessages')
    }

    getMessagesCount = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdMessageCount = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCount
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    createMessage = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.token
        };
        const body = {
            "chat_id": this.state.accountId,
            "message": {
                "message": this.state.messageInputValue
        
            }
        }
        const sendMessageEndpoint =
      configJSON.sendMessage +
      `?data[attributes][account_id]=${this.state.accountId}&data[attributes][message]=${this.state.messageInputValue}`;
 
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdsendMessage = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            sendMessageEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUsers = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token:this.token
        };
      
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdGetUsers = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUsers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    allMessages = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.token

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdGetAllMessages = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMessages
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    };
    deletemessage = (messageId: string | number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.token

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdDeleteMessages = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteMessage}/${messageId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}