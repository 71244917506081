import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  backStep: string;
  name: string;
  description: string;
  category_id: any;
      skillsLabel:[]
  body: string;
  data: any;
  location: string;
  job_title: string;
  job_description: string;
  per_day_charge: any;
  budget: any;
  duration: any;
  employment_term: string;
  estimate: string;
  token: any;
  originalSkillsOption:any[];
  accountId: number;
  skillsOption: any[];
  skillValue: string;
  file:File | any;
  describeError:boolean
  error:boolean
  fileError:boolean;
  categoriesInfo:any[];
  is_user_want_post_job:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BudgetRangeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPostJobId: string = '';
  getSkillsApiCallId: any;
  UserLoginUpdateStatusId:string=""
  chooseCategoryAPIid: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      describeError:false,
      error: false,
      skillsLabel:[],
      activeStep: 0,
      backStep: 'back',
      name: '',
      description: '',
      category_id: 1,
      body: '',
      token: '',
      location: '',
      job_title: '',
      job_description: '',
      per_day_charge: null,
      budget: 'per_day_rate',
      duration: "3 to 6 months",
      employment_term: "short_term_work",
      estimate: "large",
      data:{},
      originalSkillsOption:[],
      accountId: 0,
      skillsOption: [],
      skillValue: '',
      file:null,
      fileError:false,
      categoriesInfo:[],
      is_user_want_post_job:true

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getAccountId();
    this.getSkills();
    this.getChooseCategoryRes();
    // Customizable Area End
  }

  // Customizable Area Start
  getUserLoginUpdateStatus = async () => {
    const header = {
      'content-type': 'application/json',
      token: await storage.get('authToken')
    };
    const body = {
      "meta": {
        isFirstTimeLogin: true
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserLoginUpdateStatusId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/update_user_login_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    }
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getPostJobId) {
        this.handlePostThisJob(responseJson);
      }
      if (apiRequestCallId === this.getSkillsApiCallId) {
        this.handleGetSkills(responseJson);
      }
      if(this.UserLoginUpdateStatusId==apiRequestCallId){
        if(responseJson?.meta?.message== "updated sucessfully"){
storage.set("is_first_time_login","null")
          this.props.navigation.navigate('ViewJob');
        }

      }
      if (apiRequestCallId === this.chooseCategoryAPIid) {
        this.handleCategoryResp(responseJson);
      }
    }
  }
  // Web Event Handling

  getAccountId = async() => {
    this.setState({accountId: await storage.get("accountId")})
  }
  handleGetSkills=(responseJson:any)=>{
        this.setState({originalSkillsOption:responseJson})
  }
  handleCategoryResp =(responseJson:any) => {
    this.setState({categoriesInfo:responseJson?.data})
  }
  
  handlePostThisJob = async(response: any) => {
    this.setState({ data: response });
    storage.set('jobId', response.data.id);
    const first_login=await storage.get("is_first_time_login")
    if(first_login!=="null"){
this.getUserLoginUpdateStatus()
    }
    else{

      this.props.navigation.navigate('JobReviewPage');
    }
  };
  getSteps = () => {
    return ['1', '2', '3', '4', '5', '6', '7'];
  };

  getChooseCategoryRes =async () => {
    const chooseCategoryResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type":  configJSON.validationApiContentType,
      "token": await storage.get('authToken')
    };
    this.chooseCategoryAPIid = chooseCategoryResponse.messageId;
    chooseCategoryResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.chooseCategoryResponseEndPoint
    );
    chooseCategoryResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    chooseCategoryResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(chooseCategoryResponse.id, chooseCategoryResponse);
    
  }


  getSkills = async () => {
    const headers = {
      'content-type': 'application/json',
      token: await storage.get('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSkillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkillEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
handleError=()=>{
  if(this.state.description=="" ){
this.setState({describeError:true})
  }
  if(this.state.file==null){
this.setState({fileError:true})
  }
  else{

    this.getRoadMap()
  }
}
  getRoadMap = async () => {
    const headers = {
      token: await storage.get('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostJobId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts`
    );    
    let formdata = new FormData();
    formdata.append("[data][attributes][name]", this.state.name);
    formdata.append("[data][attributes][description]", this.state.description);
    formdata.append("[data][attributes][category_id]", "1");
    formdata.append("[data][attributes][body]", 'body');
    formdata.append("[data][attributes][location]", 'india');
    formdata.append("[data][attributes][account_id]","9");
    formdata.append("[data][attributes][skills][]", this.state.skillValue);
    formdata.append("[data][attributes][job_title]", "job title");
    formdata.append("[data][attributes][job_description]",this.state.description);
    formdata.append("[data][attributes][per_day_charge]", this.state.per_day_charge);
    formdata.append("[data][attributes][budget]", this.state.budget);
    formdata.append("[data][attributes][duration]", this.state.duration.toString());
    formdata.append("[data][attributes][employment_term]",this.state.employment_term);
    formdata.append("[data][attributes][estimate]", this.state.estimate);
    formdata.append("[data][attributes][file]", this.state.file);
    
    formdata.append("[data][attributes][is_user_want_post_job]", this.state.is_user_want_post_job.toString());

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onStepChange = (stepChange: number) => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + stepChange
    }));
  };
  getNameSet = (value: any) => {
    
    this.setState({ name: value, describeError: false  });
  };
  getDescriptionSet = (value: string) => {
    this.setState({ description: value, describeError: false });
  };
  

  getCategoryIdSet = (value: any) => {
    this.setState({ category_id: 1 });
  };
  getDurationSet = (value: any) => {
    this.setState({ duration: value });
  };
  getEmploymentTermSet = (value: any) => {
    this.setState({ employment_term: value });
  };
  getEstimateSet = (value: any) => {
    this.setState({ estimate: value });
  };
  getBudgetSet = (value: string) => {
    this.setState({ budget: value });
  };
  getPerDayChargeSet = (value: number) => {
    console.log(value,"per_day_chargeper_day_charge")
    this.setState({ per_day_charge: value });
  };
 
  handleInputChange = (newValue: any) => {
    this.setState({ skillValue: newValue });
    if (newValue) {
      const filtered = this.state.originalSkillsOption.filter((option:any) =>
        option.toLowerCase().includes(newValue.toLowerCase())
      );
      this.setState({ skillsOption: filtered });
    } else {
      this.setState({skillsOption:this.state.originalSkillsOption});
    }
  };

  handleSkillMenuInputChange = (_: any, newValue: any) => {
        this.handleInputChange(newValue);
  };
  // Customizable Area End
  // Customizable Area Start
  handleFileChange = (files:any) => {    
    if (files && files.length > 0) {
      const file = files[0];
      this.setState({ file: file });
    }
  };


  handleLogoClick = ()=>{
    this.props.navigation.navigate("ClientHomePage");
  }
  // Customizable Area End
}
