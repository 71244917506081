import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { ChangeEvent } from 'react';
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  months: string;
  open: any;
  reason: any;
  savioServiceFee: any;
  projectBudget: any;
  youReceive: any;
  authToken:any;
  message:any;
  proposalId:any;
  uploadedResume:any;
  describeYourExp: any;
  jobId: any;
  submitProposalRes:object;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SubmitProposalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  withdrawProposalsId:any;
  submitProposalsId:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.withdrawProposalsId="";
    this.submitProposalsId="";
    this.state = {
      months: '',
      open: false,
      reason: '',
      savioServiceFee: '5',
      projectBudget: '',
      youReceive: '',
      authToken: '',
      message:"",
      proposalId:"24",
      uploadedResume:"",
      describeYourExp:"",
      jobId:"",
      submitProposalRes:{},
      
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.setState({jobId:await storage.get('currentProposalJobId')});


    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleMonths = (event: any) => {
    this.setState({ months: event.target.value });
    
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);

  };
  handleProjectBudget =(event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; 
    // Check if the input is projectBudget
   
      // Convert value to a number
      const numericValue = parseFloat(value);
      const numericRegex = /^[0-9]+(?:\.[0-9]*)?$/
      // Check if the value is a valid number or empty string
      if (numericRegex.test(value) || value === '') {
        // Calculate 5% commission and remaining amount
        const commission = isNaN(numericValue) ? 0 : numericValue * 0.05;
        const remainingAmount = isNaN(numericValue) ? '' : (numericValue - commission).toFixed(2);
  
        // Update state for projectBudget and youReceive
        this.setState({
          projectBudget: value, // Keep the input value as is
          youReceive: remainingAmount // Update youReceive with the remaining amount
        });
      }
  };
  

  handleWithdrawProposals = () => {
    const withdrawProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
     
    };

    const body = {
      proposals: {
        reason: this.state.reason,
        message: this.state.message
      },

      token: this.state.authToken
    };
    this.withdrawProposalsId = withdrawProposals.messageId;
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.withdrawProposalsEndpoint}/${this.state.proposalId}/withdraw_proposal`
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(withdrawProposals.id, withdrawProposals);

  }
  handleResumeUpload = (event:any) => {
    const file = event.target.files[0]; // Get the uploaded file
    this.setState({ uploadedResume: file }); // Update state with the uploaded file
    
  };

  handleSubmitProposalsAPI = () => {
    const submitProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      "token": this.state.authToken
    };
  
    const requestData = {
      proposals: {
        post_id: this.state.jobId,
        duration: this.state.months,
        status: 'active',
        work_experience: 'Experience in Promt',
        hour_rate: this.state.projectBudget,
        service_rate: '5.0',
        receive_rate: this.state.youReceive,
        work_experience_description: this.state.describeYourExp,
        resume_document: this.state.uploadedResume
      },
      token: this.state.authToken
    };
    this.submitProposalsId = submitProposals.messageId;
    submitProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitProposalsApiEndPoint
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addMilestoneMethodeType
    );
    runEngine.sendMessage(submitProposals.id, submitProposals);
   
  }
  

  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  isFormValid() {
    const { projectBudget, months, uploadedResume } = this.state;
    // Check if all required fields are filled
    return projectBudget.trim() !== '' && months.trim() !== '' && uploadedResume !== "";
}

    
  // Customizable Area End

  // Customizable Area Start
  async receive(_form: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.submitProposalsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        ;
        

        if (responseJson) {
        
          if(responseJson.message){
            window.alert(responseJson.message);

          }else {
            window.alert("Proposal submitted successfully");
          }
        
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
         
        }
          this.handleredirection('FreelancerSavedJob');
    }
  }
    // Customizable Area End
  }
  // Customizable Area End
}
