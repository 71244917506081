import React from "react";
// Customizable Area Start
import {Box, Grid, styled} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DashboardController, { Props,webConfigJSON } from "../DashboardController.web";
import { ellipse } from "./assets";

const CustomTypography = styled(Typography)({
  fontFamily:"'Inter', sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  padding: "15px",
  textDecoration: "none",
  cursor:"pointer",
  background: '#206FC4',
  borderRadius: 10,
  textAlign:"center",
  width:"100%",
  maxWidth: "161px",
  maxHeight: "55px",
  '&:hover': {
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
// Customizable Area End
export default class Title extends DashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
      <div style={styles.root as React.CSSProperties}>
        <Grid
          container
          spacing={4}
          style={styles.boxMain as React.CSSProperties}
        >
          <Grid item xs={12} md={6}>
            <Typography style={styles.TileHeading as React.CSSProperties}>
              Let’s start with the strong title.
            </Typography>
            <div style={styles.titleTextDiv as React.CSSProperties}>
              <Typography style={styles.titleText as React.CSSProperties}>
                When searching for an IP professional, It’s important to clearly
                identify the skills required for the job. This will help you
                attract the right candidates who have the expertise and
                experience necessary to meet your needs. Make a list of the
                specific skills you need. such as patent prosecution, trademark
                law, licensing, or litigation. Be sure to prioritize the skills
                that are most important to your project or invention. By clearly
                identifying the required skills, you can save time and avoid
                potential miscommunication with candidates who may not have the
                right exepertise.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={styles.box2 as React.CSSProperties}>
            <div style={styles.searchPostTextDiv as React.CSSProperties}>
              <Typography style={styles.searchPostText as React.CSSProperties}>
                Write a title of your job post
              </Typography>
            </div>

            <TextField
              variant="outlined"
              style={styles.searchTextFiled as React.CSSProperties}
              data-testid="titleName"
              inputProps={{
                style: {
                  ...styles.outlinedInput,
                },
                onKeyPress: this.handleKeyPress,
                onInput: this.handleInput,
              }}
              value={this.props.name}
              onChange={(event) => this.props.getNameSet && this.props.getNameSet(event.target.value)}
              error={this.state.error}
              helperText={this.state.error ? '* Please enter a valid job title (up to 200 characters)' : ''}
            />

            <div style={styles.examplesDiv1 as React.CSSProperties}>
              <Typography style={styles.examplesText as React.CSSProperties}>
                Examples:
              </Typography>
            </div>
            {/* Examples div 2 */}
            <div style={styles.examplesDiv2 as React.CSSProperties}>
              <Typography style={styles.secondPara as React.CSSProperties}>
              “Seeking a Skilled Patent Lawyer to Protect My Intellectual Property" 
              "In Need of an Experienced Patent Enforcement Expert for Legal Assistance" 
              "Searching for a Knowledgeable Patent Valuer to Assess the Worth of My Invention" 
              "Looking for an IP Attorney to Safeguard My Innovations and Ideas"
              "Seeking a Seasoned Patent Consultant to Guide Me Through the IP Landscape" 
              "In Need of a Talented Patent Litigator to Handle IP Disputes"
              "Looking for an IP Strategist to Develop a Strong Intellectual Property Portfolio" 
              "Seeking an Expert Patent Agent to Assist with Patent Filing and Prosecution"
              "In Need of a Specialized IP Researcher to Conduct Infringement Analysis"
              "Searching for a Skilled Technology Transfer Specialist to Monetize My Intellectual Property"
              </Typography>
            </div>
          </Grid>
        </Grid>
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
      </div>
        <Box style={styles.lowerSectionDiv as React.CSSProperties}>
           <Box style={styles.lowerSectionDiv2}>
           <Typography
             style={{
              ...styles.lowerSectionItem1Text,
              ...(this.state.isHovered && styles.lowerSectionItem1TextHovered),
            }}
              onClick={() => this.handleTitleBack()}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleLeave}
            >
              BACK
            </Typography>
              <CustomTypography 
              onClick={() => this.handleTitleContinue()}
              >
                CONTINUE
              </CustomTypography>
           </Box>
        </Box>
        </>
      // Customizable Area End
    );
  }
}

const styles = {
  // Customizable Area Start
  root: {
    flexGrow: 1,
    maxWidth: "1440px",
    margin: "0 auto",
  },
  heading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "40px",
    marginLeft: "20px",
  },
  boxMain: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    marginTop: "40px",
    marginLeft: "80px",
    marginBottom: "100px",
    padding: "19px",
  },

  box2: {
    width: "100%",
  },
  TileHeading: {
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "bold",
    color: "#000000",
  },
  titleTextDiv: {
    marginTop: "20px",
  },
  titleText: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "14px",
    color: "#868686",
    lineHeight: "30px",
    fontWeight:600
  },
  searchPostTextDiv: {
    marginTop: "-10px",
  },
  searchPostText: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "19px",
    fontWeight: "bolder",
    color: "#535353",
    lineHeight: "30px",
    marginBottom: "5px",
  },
  searchTextFiled: {
    width: "80%",
    maxHeight: "56px",
    borderRadius: "4rem",
    marginTop: "5px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-input": {
      width: "432px",
    },
  },

  outlinedInput: {
    borderRadius: "10px",
    "&::placeholder": {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
  examplesDiv1: {
    marginTop: "20px",
  },
  examplesText: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
  },

  examplesDiv2: {
    marginTop: "-10px",
    display: "contents",
    flexWrap: "wrap",
  },
  secondPara: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "14px",
    lineHeight: "27px",
    letterSpacing: "0px",
    textAlign: "start",
    width: "100%",
    color: "#868686",
    paddingTop: "10px",
    fontWeight:"bold"
  },
  lowerSectionDiv: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
    position: 'absolute', 
    bottom: 20, 
    left: 0, 
    width: '100%'
  },
  lowerSectionDiv2:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 20,
    width: '100%'
  },
  lowerSectionItem1Text: {
    fontFamily: "'Inter', sans-serif" as "'Inter', sans-serif",
    fontWeight: "bold" as  "bold", 
    fontSize: "16px",
    color: "#707070",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    position: "relative" as "relative",
    zIndex: 1
  },
  lowerSectionItem1TextHovered: {
    color: '',
  },
  btnText: {
    fontFamily:"'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "15px",
    textDecoration: "none",
    cursor:"pointer",
    background: '#206FC4',
    borderRadius: 10
  },
  lowerSectionBtnHovered: {
    maxWidth: "161px",
    maxHeight: "55px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  },
  lowerSectionBtn: {
    // maxWidth: "161px",
    // maxHeight: "55px",
  },
  bottomLeftImage: {
    position: "absolute",
    width: "231px",
    height: "231px",
    top: "739px",
    left: "-37px",
    opacity: "0.4000000059604645px",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    width:'340px',
    left:'0',
    bottom: '0',
    zIndex: 0
  }
  // Customizable Area End
};
