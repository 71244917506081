import React from "react";
// Customizable Area Start
import FooterWeb from "./Components/Footer.web";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ClientNavBar from "./Components/ClientNavBar.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import EnterUserDetailsController, {
  Props,
  webConfigJSON
} from "./EnterUserDetailsController";

export default class EnterUserDetails extends EnterUserDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
       <ThemeProvider theme={theme}>
        <ClientNavBar/>
        <Grid container style={webStyles.gridContainer}>
        <Grid item xs={12} sm={5} md={5}>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography style={webStyles.detailHeading}>{webConfigJSON.enterDetails}</Typography>
                        <Box style={webStyles.inputContainer}>
                            <Typography style={webStyles.label}>{webConfigJSON.emailAddress}</Typography>
                            <TextField 
                                variant="outlined" 
                                type="email" 
                                id="email" 
                                placeholder="Enter Email Address" 
                                size="small" 
                                data-testid="email" 
                                value={this.state.emailAddress}
                                fullWidth
                                onChange={this.handleEmailAddress}
                                error={(!this.state.emailAddress && this.state.error) || this.state.errorMessage ==='Invalid Email'}
                                helperText={this.state.errorMessage}
                            />
                        </Box>
                        <Box style={webStyles.inputContainer}>
                            <Typography style={webStyles.label}>{webConfigJSON.stripeAccount}</Typography>
                            <TextField 
                                variant="outlined" 
                                id="stripe" 
                                size="small" 
                                data-testid="stripe"
                                placeholder="Enter Stripe Account"
                                value={this.state.stripeAccount} 
                                fullWidth
                                onChange={this.handleStripeAccount}
                                error={!this.state.stripeAccount && this.state.error}
                                helperText={(!this.state.stripeAccount && this.state.error) && this.state.errorMessage}
                            />
                        </Box>
                        <Box style={webStyles.inputContainer}>
                            <Typography style={webStyles.label}>{webConfigJSON.IFCICode}</Typography>
                            <TextField 
                                variant="outlined" 
                                id="ifciCode" 
                                size="small" 
                                data-testid="ifceCode" 
                                fullWidth
                                value={this.state.IFCICode}
                                placeholder="Enter IFCI Code"
                                onChange={this.handleIFCICode}
                                error={!this.state.IFCICode && this.state.error}
                                helperText={(!this.state.IFCICode && this.state.error) && this.state.errorMessage}
                            />
                        </Box>
                        <Box>
                            <Button color="primary" variant="contained" data-testid="stripeBtn" style={webStyles.button} onClick={this.handleAccount}>{webConfigJSON.setAccount}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
        <Box>
            <Grid container style={webStyles.boxContainer}>
                <Grid item xs={12} md={12} sm={12}>
                    <Box style={webStyles.displayBox}>
                        <Typography style={webStyles.oneDayRate}>{webConfigJSON.oneDayRate}</Typography>
                        <Typography style={webStyles.oneDayRate}>{webConfigJSON.rate}</Typography>
                    </Box>
                    <Box style={webStyles.displayBox}>
                        <Typography color="secondary">{webConfigJSON.savioService}</Typography>
                        <Typography color="secondary">{webConfigJSON.serviceFee}</Typography>
                    </Box>
                    <Box style={webStyles.displayBox}>
                        <Typography style={webStyles.receiveText}>{webConfigJSON.willReceive}</Typography>
                        <Typography style={webStyles.receiveText}>{webConfigJSON.receive}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        </Grid>
      </Grid>
      </ThemeProvider>
      <FooterWeb/>
      </>
     
      
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    gridContainer:{
        padding:'3%',
        justifyContent:'space-between'
    },
    detailHeading:{
        fontSize:'1.2rem',
        'font-weight':'600',
        marginBottom:'2%'
    },
    inputContainer:{
        marginBottom:'4%'
    },
    label:{
        marginBottom:'1%',
        fontSize:'1.1rem'
    },
    button:{
        width:'100%',
        padding:'10px',
        marginTop:'2%',
        borderRadius:'8px'
    },
    boxContainer:{
        border:'1px solid #aeaeae',
        borderRadius:'5px',
        padding:'3% 2%',
        marginTop:'15%'

    },
    displayBox:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'3%'
    },
    oneDayRate:{
        fontWeight:600
    },
    receiveText:{
        color:'#2378D1',
        'font-weight':'600'
    },
  
};
// Customizable Area End
