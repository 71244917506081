// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  Divider,
  ThemeProvider,
  Typography,
  createTheme
} from '@material-ui/core';
import HowToFindWorkController, { Props } from './HowToFindWorkController.web';
import NavBar from '../../landingpage/src/NavBar.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { findWorkImg1, findWorkImg2, findWorkImg3, findWorkImg4, findWorkImg5 } from './assets';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});

// Customizable Area End
// Customizable Area Start
export default class HowToFindWork extends HowToFindWorkController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { HowToFindWorkRes } = this.state;
    const getSectionData = (sectionId: number) => {
      return HowToFindWorkRes.find(
        (sectionData: { attributes: { section: string; }; }) => Number(sectionData.attributes.section) == sectionId
      )?.attributes;
    };

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
       {this.state.token?<FreelancerNavBar navigation={this.props.navigation} />: <NavBar {...this.props} />}
         {/* section1 */}
        <Box marginTop={'1%'} padding={'10px'}>
          <Box
            display={'flex'}
            style={{ backgroundColor: 'E1F0FF' }}
            padding={'10px 10px 10px 40px'}
            maxWidth={'100%'}
            maxHeight={'30%'}
            borderRadius={'15px'}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              flex={'0.6'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                marginTop={'3%'}
              >
                <Typography
                  style={{
                    fontSize: '40px',
                    fontFamily: 'Inter',
                    fontWeight: 600
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.title}}
                  />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 400
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.description}}
                  />

                <Box marginTop={'5%'}>
                  <Button
                    data-testid={'Find Talent'}
                    variant="contained"
                    onClick={()=>{this.handleSetStorage(); this.handleRedirection(this.state.token?"SearchJob":"FullWidthTab")}}
                    style={{
                      borderRadius: '10px',
                      width: 'max-content',
                      backgroundColor: '#2275CD',
                      color: '#FFF',
                      textTransform: 'none'
                    }}
                  >
                   {getSectionData(1)?.button_text}
                  </Button>
                </Box>
              </Box>
              {!this.state.token?
              <Box display={'flex'} justifyContent={'flex-start'}>
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 400
                  }}
                  dangerouslySetInnerHTML={{__html:
                    getSectionData(1)?.sub_title}}
                  />
                <Typography
                 onClick={()=>this.handleRedirection("UserTypeSelection")}
                 data-testid={'SignUp'}
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    color: '#2275CD',
                    cursor:"pointer"
                  }}
                >
                   Sign up here
                </Typography>
              </Box>:null}
            </Box>

            <Box display={'flex'} flexDirection={'column'} flex={'0.3'}>
              <img
                src={getSectionData(1)?.image.url}
                style={{
                  borderRadius: '20px',
                  width:"575px",
                  height:"432px"
                }}
              />
            </Box>
          </Box>
           {/* section2 */}
          <Box margin={'5%'} display={'grid'} gridRowGap={'2rem'} marginTop={"2%"}>
            <Box>
              <Typography
                style={{ ...webStyle.subHeading, marginBottom: '1%' }}
                dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.title}}
                />
              <Divider />
            </Box>
            <Box display={'flex'} alignItems={'center'} gridColumnGap={'3rem'}>
              <Box style={{ backgroundColor: 'E1F0FF' }}>
                <img src={getSectionData(2)?.image.url} width={'245px'} height={'234px'} />
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography style={webStyle.subHeading} dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.sub_title}}
                />
                <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.sub_title_description}}
                />
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} gridGap={'2rem'}>
              <Box style={{ backgroundColor: 'E1F0FF' }}>
                <img src={getSectionData(2)?.secondary_image.url} width={'245px'} height={'234px'} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} >
                <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.secondary_subtitle}}
                />
                <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(2)?.secondary_subtitle_description}}
                />
              </Box>
            </Box>
           {!this.state.token? <Box>
              <Button
                data-testid={'Create Profile'}
                variant="contained"
                onClick={()=>this.handleRedirection("UserTypeSelection")}
                style={{
                  borderRadius: '10px',
                  width: 'max-content',
                  backgroundColor: '#2275CD',
                  color: '#FFF',
                  textTransform: 'none'
                }}
              >
               { getSectionData(2)?.button_text}
              </Button>
            </Box> :null}
            <Box>
              <Divider />
            </Box>
          </Box>
          {/* section3 */}
          <Box  margin={"5%"} display={'flex'} flexDirection={"column"} gridRowGap={"1rem"}>
            <Box display={'flex'} gridColumnGap={"10rem"}>
              <Box display={"flex"} gridRowGap={"2rem"} flexDirection={"column"}>
                <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(3)?.title}}
                />
                <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(3)?.description}}
                />
                <Button
                data-testid={'FindJobs2'}
                onClick={()=>{this.handleSetStorage();this.handleRedirection( this.state.token?"SearchJob":"FullWidthTab")}}
                variant="contained"
                style={{
                  borderRadius: '10px',
                  width: 'max-content',
                  backgroundColor: '#2275CD',
                  color: '#FFF',
                  textTransform: 'none'
                }}
              >
               { getSectionData(3)?.button_text}
              </Button>
             
              </Box>
              <Box>
                <img src={getSectionData(3)?.image.url}  style={{borderRadius:"20px",width:"445px",height:"240px"}}/>
              </Box>
            </Box>
            <Box>
            <Divider/>
            </Box>
          </Box>
          {/* section4 */}
          <Box display={"flex"} alignItems={"center"} gridColumnGap={"2rem"} margin={"5%"} marginTop={"-2%"}>
            <Box>
              <img src={getSectionData(4)?.image.url}  style={{borderRadius:"10px",width:"470px",height:"560px" }}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
          <Typography style={webStyle.subHeading}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.title}}
                />
           <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.description}}
                />
           <Typography style={{...webStyle.subHeading,fontSize:"18px"}}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.sub_title}}
                />
           <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.sub_title_description}}/>
            
            <Typography style={{...webStyle.subHeading,fontSize:"18px"}}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.secondary_subtitle}}/>
           <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.secondary_subtitle_description}}/>
           <Typography style={{...webStyle.subHeading,fontSize:"18px"}}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.tertiary_subtitle}}/>
           <Typography style={webStyle.text}dangerouslySetInnerHTML={{__html:
                  getSectionData(4)?.tertiary_subtitle_description}}/>
           </Box>
            </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  subHeading: {
    fontFamily: 'Inter',
    fontSize: '22px',
    fontWeight: 600
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400
  }
};

// Customizable Area End
