import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  onStepChange: (stepChange: number) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  paymentType: string;
  cardDetails: string;
  cardNumber: string;
  firstName: string;
  lastName: string;
  cvvNumber: string;
  month: string;
  year: string;
  // Customizable Area End
}
interface SS { }

export default class PaymentSuccessfulController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      paymentType: 'card',
      cardDetails: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy.',
      cardNumber: '',
      firstName: '',
      lastName: '',
      cvvNumber: '',
      month: '',
      year: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }
  // Customizable Area Start
  handleSelectPayment = (type: string) => {
    this.setState({ paymentType: type })
  }
  handleMakePayment = () => {
    this.props.onStepChange(+2)
  }
  handleCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cardNumber: event.target.value })

  }
  handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value })
  }
  handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value })
  }
  handleCvvNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cvvNumber: event.target.value })
  }
  handleMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ month: event.target.value })
  }
  handleYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ year: event.target.value })
  }
  // Customizable Area End

}
