import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  Table,
  TableBody,
  TableCell,
  TableContainer, 
  TableHead, 
  TableRow, 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Footer from "../../dashboard/src/Components/Footer.web";
import ClientNavBar from "../../dashboard/src/Components/ClientNavBar.web";
// Customizable Area End

import DashboardController, {
  Props,
} from "./DashboardController.web";

export default class TransactionHistory extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
      // Customizable Area Start
      const tableData = [
        {
            title: "PHP, Javascript Projects and Web design Project lorem ipsum is simply...",
            ownerName: "John",
            issueDate: "17/10/2022",
            cost: "$1,000,00",
            milestoneNo: "1",
            status: "Ongoing"
        },
        {
            title: "PHP, Javascript Projects and Web design Project",
            ownerName: "John",
            issueDate: "01/02/2023",
            cost: "$1,000,00",
            milestoneNo: "2",
            status: "Ongoing"
        },
    ]
      // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ClientNavBar/>
      <Box style={styles.mainContainer}>
      <Typography style={styles.heading as React.CSSProperties}>Transaction History</Typography>
      <div>
      <TableContainer style={styles.tableContainer} >
          <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                      <TableCell style={styles.tableHeading as React.CSSProperties}>
                          Title
                      </TableCell>
                      <TableCell align="left" style={styles.tableHeading as React.CSSProperties}>
                          Owner Name
                      </TableCell>
                      <TableCell align="left" style={styles.tableHeading as React.CSSProperties}>
                          Due Date
                      </TableCell>
                      <TableCell align="left" style={styles.tableHeading as React.CSSProperties}>
                          Cost
                      </TableCell>
                      <TableCell align="left" style={styles.tableHeading as React.CSSProperties}>
                          Milestone No
                      </TableCell>
                      <TableCell align="left" style={styles.tableHeading as React.CSSProperties}>Status</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {tableData.map((row) => (
                      <TableRow
                          key={row.title} >
                          <TableCell style={styles.title as React.CSSProperties}>{row.title}</TableCell>
                          <TableCell align="left" style={styles.tableContent as React.CSSProperties}>{row.ownerName}</TableCell>
                          <TableCell align="left" style={styles.tableContent as React.CSSProperties}>{row.issueDate}</TableCell>
                          <TableCell align="left" style={styles.tableContent as React.CSSProperties}>{row.cost}</TableCell>
                          <TableCell align="left" style={styles.tableContent as React.CSSProperties}>{row.milestoneNo}</TableCell>
                          <TableCell align="left" style={styles.status as React.CSSProperties}>{row.status}</TableCell>
                      </TableRow>
                  ))}
              </TableBody>
          </Table>
      </TableContainer>
      </div>
  </Box>
  <Footer/>
  </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  mainContainer: {
      flexGrow: 1,
      maxWidth: "100%",
      margin: "30px"
  },
  heading: {
      height: "36px",
      color: "#000000",
      fontFamily: "Inter",
      fontSize: "30px",
      lineHeight: "35px",
      letterSpacing: "0px",
      textalign: "left",
      fontWeight: "bold",
      marginBottom:"20px",
  },
  tableContainer:{
      border:"1px solid #E7E7E7",
      borderBottom:"none",
      marginTop:"40px",
  },
  tableHeading:{
      color:"#6C6C6C",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0px",
      textAlign: "left",
  },
  tableContent:{
      color:"#000000",
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: "0px",
      paddingTop:"0px"
  },
  status:{
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: "0px",
      textAlign: "left",
      color:"#206FC4",
      paddingTop:"0px"

  },
  title:{
      color:"#0D0D0D",
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: "0px",
      textAlign: "left",
      width:"300px"
      // width:"467px"
  },
}
// Customizable Area End