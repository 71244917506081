import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "framework/src/StorageProvider";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeData:string;
  // Customizable Area End
}
interface SS { }

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      storeData:'profile',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    // Customizable Area End
  }
  // Customizable Area Start

  handleClick = (data:string) => {
    this.setState({storeData:data})
  }
  handleRedirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  handleLogout=(componentName: any)=>{
    storage.remove("authToken");
    storage.remove("accountId");
    storage.remove("accountHolderName");
    storage.remove("userType")
    this.props.navigation.navigate(componentName)
    console.log("logout")
  }
  // Customizable Area End
}
