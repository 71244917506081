import React from "react";
// Customizable Area Start
import FooterWeb from "./Components/Footer.web";
import { Box, Button, Grid, IconButton, Link, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ClientNavBar from "./Components/ClientNavBar.web";
import { userProfile, attached } from './assets';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import Rating from '@material-ui/lab/Rating';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
interface PaginationItemProps {
  type: string;
  text: string | number;
  selected: boolean;
  disabled: boolean;
}

export class PaginationItem extends React.Component<PaginationItemProps> {
  render() {
    const { type, text, selected, disabled, ...rest } = this.props;
    if (type === 'previous') {
      return <Typography {...rest} style={disabled ? webStyles.disableText : webStyles.textBtn}>{text}</Typography>;
    }
    if (type === 'next') {
      return <Typography {...rest} style={disabled ? webStyles.disableText : webStyles.textBtn}>{text}</Typography>;
    }
    return <Typography style={selected ? webStyles.selectedPageBtn : webStyles.pageCountBtn} {...rest}>{text}</Typography>;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#225DD1",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    }
  },
  typography: {
    fontFamily: 'Inter',
  }
});
// Customizable Area End
import InviteToJobController, {
  Props,
  webConfigJSON
} from "./InviteToJobController";

export default class InviteToJob extends InviteToJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const freelancerData = this.state.freelancerDetails.attributes.freelancer
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
          <ClientNavBar />
          <Grid container style={webStyles.gridContainer}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box style={webStyles.outerBox}>
                <Box style={webStyles.userContainer}>
                  <Box style={webStyles.profileContainer}>
                    <img src={userProfile} alt="profile" style={webStyles.avtar} />
                    <FiberManualRecordIcon style={webStyles.dotIcon} />
                  </Box>
                  <Box>
                    <Typography style={webStyles.userNameText}>{this.state.userName}</Typography>
                    <Typography style={webStyles.subText} color="secondary">{freelancerData.job_title}</Typography>
                  </Box>
                </Box>
                <Box style={webStyles.buttonContainer}>
                  <Button variant="contained" color="primary" fullWidth style={webStyles.messageBtn} onClick={this.handleMessageBtn} data-test-id="handleMessageBtn">{webConfigJSON.messageMe}</Button>
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.location}</Typography>
                  <Box style={webStyles.displayIcon}>
                    <LocationOnRoundedIcon fontSize="small" />
                    <Typography style={webStyles.subText} color="secondary">{this.state.location}</Typography>
                  </Box>
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.hoursPerWeek}</Typography>
                  <Typography style={webStyles.subText} color="secondary">{this.state.hourPerWeek}</Typography>
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.langauges}</Typography>
                  <Typography style={webStyles.subText} color="secondary">{this.state.langauge}</Typography>
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.verification}</Typography>
                  {freelancerData.verification ? 
                    <Box style={webStyles.displayIcon}>
                     <CheckCircleSharpIcon fontSize="small" style={webStyles.checkIcon} />
                     <Typography style={webStyles.verificationText}>{webConfigJSON.varified}</Typography>
                    </Box> :
                    <Box style={webStyles.displayIcon}>
                     <Typography style={webStyles.notVerifiedText}>{webConfigJSON.notVerified}</Typography>
                    </Box>
                }
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.rating}</Typography>
                  <Box style={webStyles.ratingBox}>
                  <Rating name="read-only" value={this.state.rating} readOnly icon={<StarRoundedIcon fontSize="inherit" />} emptyIcon={<StarBorderRoundedIcon fontSize="inherit" style={webStyles.starIcon} />} />
                  <Typography>{this.state.profileRationg}</Typography>
                  </Box>
                </Box>
                <Box style={webStyles.contentBox}>
                  <Typography style={webStyles.heading}>{webConfigJSON.education}</Typography>
                  <Typography style={webStyles.subText} color="secondary">{freelancerData.education}</Typography>
                </Box>
                <Grid container style={webStyles.countContainer}>
                  <Grid item xs={5} sm={5} md={5} lg={5} style={webStyles.projectContainer}>
                    <Typography style={webStyles.countNumber}>{this.state.projectCount}</Typography>
                    <Typography color="secondary">{webConfigJSON.projectCompleted}</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} style={webStyles.verticalLine}>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} style={webStyles.projectContainer}>
                    <Typography style={webStyles.countNumber}>{this.state.hoursCompleted}</Typography>
                    <Typography color="secondary">{webConfigJSON.hoursCompleted}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box style={webStyles.outerSecondBox}>
                <Typography style={webStyles.heading}>{webConfigJSON.availbaleSlot}</Typography>
                <Typography style={webStyles.daysSlot}>{this.state.slotDay}</Typography>
                <Grid container style={webStyles.slotGrid}>
                  {this.state.slotsArray.map((slot, index) =>
                    <Grid item xs={5} sm={5} md={5} lg={5} key={index}>
                      <Box style={webStyles.slotBox}>
                        <Typography color="primary" style={webStyles.slotCount}>{slot}</Typography>
                      </Box>
                    </Grid>
                  )
                  }
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box style={webStyles.outerSecondContainer}>
                <Box style={webStyles.expertOuterBox}>
                  <Box>
                    <Typography style={webStyles.expertText}>{webConfigJSON.expert}</Typography>
                    {this.state.showMore ?
                      <Typography style={webStyles.detailText} data-testid="showText" color="secondary">
                        {webConfigJSON.lorem}<span>
                          <Link href="#" variant="body2" data-testid="readLessLink" onClick={this.handleShowText}>
                            {webConfigJSON.readLess}
                          </Link>
                        </span></Typography>
                      :
                      <Typography style={webStyles.detailText} color="secondary">
                        {webConfigJSON.lorem.slice(0, 300)}...<span>
                          <Link href="#" variant="body2" data-testid="readMoreLink" onClick={this.handleShowText}>
                            {webConfigJSON.readMore}
                          </Link>
                        </span></Typography>
                    }
                  </Box>
                  <Typography style={webStyles.expertText}>{this.state.price}</Typography>
                </Box>
                <Box style={webStyles.expertOuterBox}>
                  {/* <IconButton aria-label="attach" style={webStyles.attachIconContainer}>
                    <img src={attached} alt="attach" />
                  </IconButton> */}
                  <Box style={webStyles.buttonDisplay}>
                    <Button variant="outlined" color="primary" style={webStyles.hireBtn} onClick={this.handleHireBtn} data-testid="view-freelancer">{webConfigJSON.invite} </Button>
                  </Box>
                </Box>
              </Box>
              <Box style={webStyles.outerThirdBox}>
                <Typography style={webStyles.heading}>{webConfigJSON.completedProject}</Typography>
                {this.state.projectDetails.map(data =>
                  <Box style={webStyles.projectBox} key={data.id}>
                    <Typography style={webStyles.projectName} color="secondary">{data.projectName}</Typography>
                    <Box style={webStyles.detailDisplay}>
                      <Rating
                        name="read-only"
                        value={data.rate}
                        readOnly
                        icon={<StarRoundedIcon fontSize="small" />}
                        emptyIcon={<StarBorderRoundedIcon fontSize="small" style={webStyles.starIcon} />}
                      />
                      <Typography color="secondary" style={webStyles.detailsText}>{data.projectRating}</Typography>
                      <Typography color="secondary" style={webStyles.detailsText}>{data.startDate} - {data.endDate}</Typography>
                    </Box>
                    <Typography color="secondary" style={webStyles.earnigText}>{data.earnings}</Typography>
                  </Box>

                )}
                <Box style={webStyles.paginationContainer}>
                  <IconButton data-testid="project-back-btn" onClick={this.handleBackClick} disabled={this.state.currentPage === 1}>
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                  <Pagination
                    count={10}
                    color="primary"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={1}
                    data-testid="project-page-items"
                    page={this.state.currentPage}
                    onChange={this.handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        text={this.handleText(item.type,item.page)}
                      />
                    )}
                  />
                  <IconButton onClick={this.handleNextClick} data-testid="project-next-btn" disabled={this.state.currentPage === 10}>
                    <ArrowForwardIosIcon fontSize="small" style={{color:'#225DD1'}}/>
                  </IconButton>
                </Box>
              </Box>
              <Box style={webStyles.outerThirdBox}>
                <Typography style={webStyles.heading}>{webConfigJSON.projectPortfolio}</Typography>
                <Grid container justifyContent="space-between">
                  {this.state.portfolio.map((data,index) => 
                   <Grid item xs={12} sm={3} key={index}>
                   <Box style={webStyles.portfolioBox}>
                     <Typography style={webStyles.portfolioText} color="secondary">{data}</Typography>
                   </Box>
                 </Grid>
                  )}
                </Grid>
                <Box style={webStyles.paginationContainer}>
                  <IconButton data-testid="back-btn" onClick={this.handleBackClick} disabled={this.state.currentPage === 1}>
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                  <Pagination
                    count={10}
                    color="primary"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={1}
                    data-testid="portfolio-page-items"
                    page={this.state.currentPage}
                    onChange={this.handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem

                        {...item}
                        text={this.handleText(item.type,item.page)}
                      />
                    )}
                  />
                  <IconButton onClick={this.handleNextClick} data-testid="next-btn" disabled={this.state.currentPage === 10}>
                    <ArrowForwardIosIcon fontSize="small" style={{color:'#225DD1'}} />
                  </IconButton>
                </Box>
              </Box>
              <Box style={webStyles.outerFourthBox}>
                <Typography style={webStyles.heading}>{webConfigJSON.skills}</Typography>
                <Box style={webStyles.languageBox}>
                  {this.state.skills.map((skill, index) =>
                    <Typography color="primary" style={webStyles.language} key={index}>{skill}</Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  gridContainer: {
    padding: '3%',
    justifyContent: 'space-around'
  },
  dotIcon: {
    position: 'absolute' as 'absolute',
    color: 'green',
    fontSize: '12px',
    left: '35px',
    top: '37px'
  },
  outerBox: {
    background: '#F5F7F9',
    marginBottom: '8%',
    borderRadius: '15px'
  },
  profileContainer: {
    position: 'relative' as 'relative'
  },
  avtar: {
    width: '50px'
  },
  userContainer: {
    display: 'flex',
    gap: '10px',
    padding: '15px'
  },
  buttonContainer: {
    margin: '2% 5%',
  },
  messageBtn: {
    borderRadius: '15px',
    fontSize: '1rem',
    fontWeight: 700,

  },
  userNameText: {
    fontSize: '1.3rem',
    fontWeight: 600
  },
  subText: {
    fontSize: '0.9rem'
  },
  verificationText: {
    fontSize: '0.9rem',
    color: 'green'
  },
  notVerifiedText:{
    fontSize: '0.9rem',
    color: 'red'
  },
  contentBox: {
    padding: '15px'
  },
  ratingBox:{
    display:'flex'
  },
  heading: {
    fontSize: '1rem',
    fontWeight: 600
  },
  displayIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  checkIcon: {
    color: 'green'
  },
  unCheckIcon:{
    color: 'red'
  },
  starIcon: {
    color: '#ffb400'
  },
  verticalLine: {
    borderLeft: '3px solid #fff',
  },
  countContainer: {
    justifyContent: 'center',
    background: '#E1E7F5',
    borderRadius: '15px'
  },
  projectContainer: {
    textAlign: 'center' as 'center',
    padding: '20px 10px'
  },
  countNumber: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  outerSecondBox: {
    background: '#F5F7F9',
    padding: '20px',
    borderRadius: '15px'
  },
  outerSecondContainer: {
    background: '#F5F7F9',
    padding: '20px',
    borderRadius: '15px 15px 0 0'
  },
  outerThirdBox: {
    background: '#F5F7F9',
    padding: '20px',
    marginTop: '1%',
  },
  outerFourthBox: {
    background: '#F5F7F9',
    padding: '20px',
    marginTop: '1%',
    borderRadius: '0 0 15px 15px'
  },
  slotBox: {
    border: '1px solid #225DD1',
    padding: '10px',
    textAlign: 'center' as 'center',
    borderRadius: '15px'
  },
  daysSlot: {
    fontSize: '0.9rem',
    fontWeight: 600,
    margin: '3% 0'
  },
  slotCount: {
    fontSize: '0.9rem',
    fontWeight: 500
  },
  slotGrid: {
    gap: '15px'
  },
  expertOuterBox: {
    display: 'flex',
    justifyContent: 'end'
  },
  expertText: {
    fontSize: '1.1rem',
    marginBottom: '0.5%',
    fontWeight: 600
  },
  detailText: {
    fontSize: '0.9rem',
    marginBottom: '2%',
  },
  buttonDisplay: {
    display: 'flex',
    gap: '10px',

  },
  attachIconContainer: {
    border: '1px solid #225DD1',
    borderRadius: '15px'
  },
  hireBtn: {
    padding: '12px 25px',
    borderRadius: '15px',
    fontWeight: 800,
    color:"#FFFFFF",
    border:"1px solid #2378D1",
    backgroundColor: '#2378D1',

  },
  detailDisplay: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center'
  },
  projectBox: {
    marginTop: '2%'
  },
  projectName: {
    fontSize: '0.9rem',
    textDecoration: 'underline'
  },
  detailsText: {
    fontSize: '0.85rem'
  },
  earnigText: {
    fontSize: '0.8rem'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  portfolioBox: {
    background: '#E4EBF4',
    padding: '10px',
    width: '100%',
    margin: '5% 0',
    borderRadius: '15px'
  },
  portfolioText: {
    fontSize: '0.7rem'
  },
  languageBox: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    gap: '5px',
    margin: '1% 0 2%'
  },
  language: {
    background: '#E1ECFB',
    textAlign: 'center' as 'center',
    width: 'max-content',
    padding: '5px 17px',
    borderRadius: '15px'
  },
  pageCountBtn: {
    background: '#E4EBF4',
    borderRadius: '7px',
    cursor: 'pointer',
    marginRight: '5px',
    padding: '5px 9px',
    color: '#000',
    fontSize: '0.8rem',
    fontWeight: 600
  },
  textBtn: {
    fontSize: '1rem',
    fontWeight: 600,
    marginRight: '5px',
    cursor: 'pointer',
    fontFamily: 'Inter',
    color: '#225DD1'
  },
  disableText: {
    fontSize: '1rem',
    fontWeight: 600,
    marginRight: '5px',
    cursor: 'not-allowed',
    fontFamily: 'Inter',
    color: 'rgb(96, 96, 96)'
  },
  selectedPageBtn: {
    background: '#225DD1',
    borderRadius: '7px',
    cursor: 'pointer',
    marginRight: '5px',
    padding: '5px 9px',
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: 600
  }
};
// Customizable Area End
