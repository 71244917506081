import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FooterWeb from "./Components/Footer.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});
const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
       fontWeight:"600",
       fontSize:"22px",
       textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
      fontWeight:"600",
      fontSize:"22px",
      textTransform: "inherit"
   },
   '& .PrivateTabIndicator-root-31' :{
    height:"4px"
   }
    
  })
  import FullWidthJobsTabController, {
    Props,
  } from "./FullWidthJobsTabController";
  import Freelance from './FreelancerOffers.web';
import FreelancerArchiveProposals from "./FreelancerArchiveProposals.web";
import FreelancerNavBar from "./FreelancerNavBar.web";
// Customizable Area End

export default class FullWidthTabProposals extends FullWidthJobsTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
        <FreelancerNavBar navigation={this.props.navigation} />
          <Grid container style={webStyles.mainConatiner}>
            <Grid item style={{ width: "100%" }}>
              <Box style={{ padding: "10px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box style={{ display: "inline-grid" }}>
                    <Typography style={webStyles.proposalHeading}>
                      My Proposals
                    </Typography>
                  </Box>
                </Grid>
                <ThemeProvider theme={theme}>
                  <CustomTabs
                    value={selectedTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ borderBottom: "1px solid #959595" }}
                  >
                    <Tab label="Active" />
                    <Tab label="Archived" />
                  </CustomTabs>
                </ThemeProvider>
                {selectedTab === 0 && (
                  <Freelance
                    navigation={this.props.navigation}
                    proposalType={"active"} activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={null}  />
                )}
                {selectedTab === 1 && (
                  <FreelancerArchiveProposals
                    navigation={this.props.navigation}
                    proposalType={"archived"} activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={null} />
                )}
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  mainConatiner: {
    padding: "20px",
  },
  jobBtnBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
  },
  jobText: {
    fontSize: "20px",
    fontFamily:"Inter"
  },
  myJobText: {
    color: "#206FC4",
    fontFamily:"Inter"
  },
  postBtn: {
    borderRadius: "7px",
    padding: "1% 2%",
    fontFamily:"Inter"
  },
  borderBox: {
    background: "#F5F7F9",
    padding: "15px",
    borderRadius: "5px",
    marginTop: "3%",
    overflow: "auto",
  },
  proposalHeading: {
    color: "#3F3F3F",
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    display: "flex",
    justifyContent: "center",
    marginBottom:"24px"
  },
};
// Customizable Area End
