import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { paymentFail } from "./assets"

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import PaymentFailedController, {
    Props,
    webConfigJSON
} from "./PaymentFailedController";

export default class PaymentFailed extends PaymentFailedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Grid container style={webStyles.gridContainer}>
                    <Grid item style={webStyles.gridInnerBox}>
                        <img src={paymentFail} alt="Payment failed" style={webStyles.failedImg} />
                        <Typography style={webStyles.failedMsg}>{webConfigJSON.paymentFailed}</Typography>
                        <Box style={webStyles.btnContainer}>
                            <Button variant="outlined" color="primary" style={webStyles.homeBtn}>{webConfigJSON.homeBtn}</Button>
                            <Button variant="contained" color="primary" style={webStyles.tryAgain}>{webConfigJSON.tryAgain}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    gridContainer: {
        height: '100vh',
        alignContent: 'center',
        justifyContent: 'center'
    },
    gridInnerBox: {
        textAlign: 'center' as 'center',
        borderRadius: '15px',
        padding: '4% 10%',
        boxShadow: '1px 9px 21px 7px #e9f3f4',
        width: 'max-content',
        height: 'max-content'
    },
    failedMsg: {
        fontSize: '1rem',
        fontWeight: 800,
        color: '#FF4A6D',
        width: '188px',
        margin: 'auto',
        padding: '10px 0'
    },
    btnContainer: {
        display: 'flex',
        gap: '25px'
    },
    homeBtn: {
        borderRadius: '10px',
        padding: '0 45px',
        width: '120px',
        borderColor: '#7746bd',
        fontSize: '0.8rem',
        fontWeight: 700
    },
    tryAgain: {
        borderRadius: '10px',
        fontSize: '0.8rem',
        fontWeight: 700,
        padding: '6px 25px'
    },
    failedImg: {
        width: '300px'
    }
};
// Customizable Area End
