//Customizable Area Start
import React from "react";
import {  Button, Grid, Typography,Box,CircularProgress } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import { TableContainer,RightBox } from "../../../components/src/ActiveStep0.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

export default class FreelancerInvitationToInterviews extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start
  render() {
    const { invitationToInterviewData,activeProposalTotalPages,activeProposalCurrentPage } = this.state;
    if (!invitationToInterviewData) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      );
    }

    const invitations = invitationToInterviewData?.data?.attributes?.invitation_to_interview?.invitation_to_interviews?.data || [];

    return (
      <>
        <RightBox>
        {invitations.length > 0 ? (
            invitations.map((item: any, index: number) => (
              <TableContainer key={index} data-test-id={`invitationItem-${index}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <Typography style={{ ...webStyle.date, fontWeight: 700 }}>
                        Received {item.attributes.post.data.attributes.created_at}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={webStyle.jobname as React.CSSProperties}
                  >
                    {item.attributes.post.data.attributes.name}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={webStyle.tableContent as React.CSSProperties}
                  >
                    <Button
                      style={{ ...webStyle.startDateText, textTransform: "capitalize" }}
                      data-test-id="handleVisitPage"
                      onClick={() => this.handleVisitPage(item.id)}
                    >
                      Visit Page
                    </Button>
                  </Grid>
                </Grid>
              </TableContainer>
            ))
          ) : (
            <Typography style={webStyle.noProfile}>
              No active data found
            </Typography>
          )}
        </RightBox>
        {invitations.length > 0 && (
        <Box style={webStyle.paginationContainer}>
          <Pagination
            count={activeProposalTotalPages}
            page={activeProposalCurrentPage}
            onChange={this.handleInvitationPageChange}
            color="primary"
          />
          </Box>
        )}
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const webStyle = {
  tableContent: {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    justifyContent: "end",
  },
  date: {
    fontFamily: "Inter",
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "18px",
    color: "#434343",
  },
  jobname: {
    fontWeight: 500,
    fontFamily: "Inter",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "18px",
    color: "#206FC4",
    alignItems: "center",
  },
  startDateText: {
    fontFamily: "Inter",
    color: "#434343",
    fontWeight: 500,
    fontSize: "18px",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
noProfile:{
  fontSize:'1.5rem',
  paddingTop:'5%',
  textAlign:'center' as 'center',
  color:'grey'
}
};
// Customizable Area End
